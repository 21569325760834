import moment from 'moment';
import { v4 } from 'uuid';

import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';

import { NeighborScheme } from '../../shared/entities/neighbor-scheme';

import { SidebarPadRigOperation, SidebarWellRigOperation } from './entities';

export function getPadTitle(
  directories: Directories,
  pad: UnplannedRigOperationsSidebar.PadObjectType,
  view: UnplannedRigOperationsSidebar.PadsView
): string {
  const { refQuery, refObjectAttrConcat, attrName, delimiter } = view;

  const refObject = directories.getJoinedObjectDeprecated(refQuery);
  const labelObject = refObject?.find((refObjectItem) => refObjectItem[attrName]?.toString() === pad.id?.toString());

  if (labelObject) {
    const labels = refObjectAttrConcat.map((refObjectAttr) => {
      return labelObject[refObjectAttr];
    });

    return labels.join(delimiter || ', ');
  }

  return '';
}

export function getWellTitle(
  directories: Directories,
  view: UnplannedRigOperationsSidebar.WellsView,
  wellData: Record<string, unknown>
): string {
  const { attrName, refObjectAttr, refObjectType } = view;

  const attrValue = wellData[attrName];

  if (refObjectAttr && refObjectType) {
    const refObject = directories.getObject(refObjectType);
    const labelObject = refObject?.find(({ id }) => id.toString() === attrValue);
    const label = labelObject?.data[refObjectAttr];

    return label ? label.toString() : '';
  }

  return attrValue?.toString() || '';
}

export function getWellId(wellData: Record<string, unknown>): number {
  // Hardcode-attr
  const wellId = wellData['GOplan_GeologicalTask.id'];

  assert(typeof wellId === 'number', 'Invalid ID of rig operation from field "GOplan_GeologicalTask.id"');

  return wellId;
}

export function getWellAttributes(
  view: UnplannedRigOperationsSidebar.WellsView,
  wellData: Record<string, unknown>
): string[] {
  const attributes: string[] = [];

  for (const attribute of view.attributes) {
    const attributeValue = wellData[attribute.objectName];

    if (!hasValue(attributeValue)) {
      continue;
    }

    if (attribute.type === 'DateTime') {
      if (typeof attributeValue === 'string') {
        attributes.push(moment.unix(parseInt(attributeValue)).format(DATE_FORMAT));
      }

      if (typeof attributeValue === 'number') {
        attributes.push(moment.unix(attributeValue).format(DATE_FORMAT));
      }
    }
  }

  return attributes;
}

export function getRigOperationIndex(
  view: UnplannedRigOperationsSidebar.WellsView,
  wellData: Record<string, unknown>
): number | null {
  const rigOperationIndex = wellData[view.rigOperationIndex];

  if (typeof rigOperationIndex === 'string' && !Number.isNaN(rigOperationIndex)) {
    return Number(rigOperationIndex) + 1;
  }

  if (typeof rigOperationIndex === 'number') {
    return rigOperationIndex + 1;
  }

  return null;
}

export function mapPads(
  directories: Directories,
  appSettings: AppSettingsStore,
  view: UnplannedRigOperationsSidebar.View,
  unplannedRigOperations: UnplannedRigOperationsSidebar.PadObjectType[],
  groupVariant: UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS
): SidebarPadRigOperation[] {
  const now = moment(new Date()).unix();
  let pads: SidebarPadRigOperation[] = [];

  for (const pad of unplannedRigOperations) {
    const uniquePadKey = v4();
    const padTitle = getPadTitle(directories, pad, view.items.pads);

    const wells = pad.items.map((well) => {
      const uniqueWellKey = v4();
      const uniqueCreationKey = `sidebar-well-${uniqueWellKey}`;
      const wellView = view.items.pads.wells;
      const wellId = getWellId(well);
      const wellColor = getWellColor(well, appSettings.wellColorRules);
      const wellTitle = getWellTitle(directories, wellView, well);
      const wellAttributes = getWellAttributes(wellView, well);
      const rigOperationIndex = getRigOperationIndex(wellView, well);
      const wellTypeIcon = appSettings.getWellIcon(well, addGOplanPrefix('GeologicalTask.wellTypeId'));

      return new SidebarWellRigOperation({
        uniqueWellKey,
        uniqueCreationKey,
        view: wellView,
        well,
        color: wellColor,
        title: wellTitle,
        wellId,
        attributes: wellAttributes,
        rigOperationIndex,
        wellTypeIcon,
      });
    });

    const filteredWells = wells.filter((well) => !well.start || well.start > now);

    if (!filteredWells.length) {
      continue;
    }

    const sidebarPad = new SidebarPadRigOperation({
      title: padTitle,
      pad,
      wells: filteredWells,
      uniqueCreationKey: uniquePadKey,
      groupVariant,
    });

    sidebarPad.wells.forEach((well) => well.setParentPad(sidebarPad));

    pads.push(sidebarPad);
  }

  for (let padIndex = 0; padIndex < pads.length; padIndex++) {
    const currentPad = pads[padIndex];

    const previousWell = pads[padIndex - 1]?.lastWell;
    const nextWell = pads[padIndex + 1]?.firstWell;

    if (currentPad) {
      const leftNeighbor: NeighborScheme.NeighborInfo<SidebarWellRigOperation> =
        previousWell?.end && currentPad.x.start
          ? {
              distance: currentPad.x.start - previousWell.end,
              neighbor: previousWell,
            }
          : {
              neighbor: undefined,
            };

      const rightNeighbor: NeighborScheme.NeighborInfo<SidebarWellRigOperation> =
        nextWell?.start && currentPad.x.end
          ? {
              distance: nextWell.start - currentPad.x.end,
              neighbor: nextWell,
            }
          : {
              neighbor: undefined,
            };

      currentPad.setNeighborScheme(new NeighborScheme(leftNeighbor, rightNeighbor));
    }
  }

  return pads;
}
