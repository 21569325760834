import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { DragHandler } from 'src/features/planning/views/plan-table/drag-handler';

export interface Props {
  children: React.ReactNode;
  'data-row-key': string;
  rowChanged: boolean;
  className: string;
}

export const SortableRow = observer(function SortableRow(props: Props) {
  const { children, rowChanged, className, ...restProps } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props['data-row-key'],
  });

  return (
    <tr
      ref={setNodeRef}
      {...attributes}
      {...restProps}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      className={clsx(className, rowChanged && 'Compare')}
    >
      {children instanceof Array
        ? children.map((child: any, index) => {
            return child.key === 'dragHandler' ? (
              <td {...listeners} key={index}>
                <DragHandler />
              </td>
            ) : (
              child
            );
          })
        : children}
    </tr>
  );
});
