import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { hasValue } from 'src/shared/utils/common';

import { DrillingElementColoredCard } from '../../../../drilling-element-colored-card';
import { WellTypeIcon } from '../../../../well-type-icon';
import { WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { DateHelper } from '../../../shared/date-helper';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useSortableGhost } from '../../sorting/hooks/use-sortable-ghost';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import styles from './data-items-full.module.scss';

interface Props {
  item: SortableGhostItem<WellRigOperation>;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  contentClassName?: string;
  headerClassName?: string;
}

export const SortableGhostWellRigOperationWrapper = observer(function SortableGhostWellRigOperationWrapper({
  item: ghostWrapper,
  viewSettingsProvider,
  contentClassName,
  headerClassName,
  verticalViewport,
  horizontalViewport,
}: Props) {
  const { transform } = useSortableGhost({
    id: ghostWrapper.activeKey,
  });

  const ref = useMovableElement({
    item: ghostWrapper,
    horizontalViewport,
    verticalViewport,
    transform,
  });

  if (!ghostWrapper.options?.isShown) {
    return null;
  }

  const wellIcon = viewSettingsProvider.getWellIcon(ghostWrapper.ghost.data);

  const isRemovableRigOperation =
    hasValue(ghostWrapper.ghost.start) && ghostWrapper.ghost.start > DateHelper.dateToUnix(new Date());

  return (
    <div ref={ref} className={clsx(styles.dataItem, styles.sortableDragging)}>
      <DrillingElementColoredCard
        title={viewSettingsProvider.getTitle(ghostWrapper.ghost)}
        color={viewSettingsProvider.getWellColor(ghostWrapper.ghost.data) || ''}
        attributes={viewSettingsProvider.getAttributes(ghostWrapper.ghost)}
        icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
        className={styles.wellCard}
        contentClassName={contentClassName}
        headerClassName={headerClassName}
        withDeleteButton={isRemovableRigOperation}
      />
    </div>
  );
});
