import { action, computed, makeObservable, observable } from 'mobx';

import { TDictObject } from 'src/api/directories/types';
import { equalsDeep } from 'src/shared/utils/equals-deep';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';

import { MultiComboboxSettings, TEnableIf, TFormula, TVisuallyDisabled } from '../../../api/new-well/types';
import { IRestrictions, TOnChangeComboboxInstructions, TOption } from '../../../features/well-form/types';
import { Combobox } from '../abstract-control-entities';

export type TMultiComboboxData = {
  directories: Directories;
  label?: string;
  placeholder?: string;
  formElementRefId: string;
  fieldId: string;
  objectName?: string;
  useInMainProgressBar: boolean;
  required: boolean;
  enableIf?: TEnableIf[];
  calculatedValue?: TFormula;
  visuallyDisabled?: TVisuallyDisabled[];
  refObjectType?: string;
  refObjectAttr?: string;
  refQuery?: TRefQuery;
  initialValue?: string | number;
  restrictions: IRestrictions;
  onChangeInstructions?: TOnChangeComboboxInstructions;
  refObjectFilterByFields?: {
    [key: string]: string;
  };
  directory: TDictObject[] | null;
  settings?: MultiComboboxSettings;
};

export class MultiCombobox extends Combobox<(string | number)[]> {
  readonly required: boolean;
  readonly settings?: MultiComboboxSettings;

  @observable initialValue: (string | number)[] = [];
  @observable value: (string | number)[] = [];
  @observable archivedValue: TOption[] = [];
  options: TOption[] = observable.array();

  constructor(data: TMultiComboboxData) {
    super(data);
    this.useInMainProgressBar = data.useInMainProgressBar;
    this.required = data.required;
    this.settings = data.settings;
    this.setOptions(this.serializeDataToOptions());

    makeObservable(this);
  }

  @action.bound
  setOptions(options: TOption[]): void {
    this.options = options;
  }

  @computed
  get optionsList(): Map<string | number, TOption> {
    const optionsList = new Map();
    this.options.forEach((option) => {
      optionsList.set(option.value, option);
    });
    this.archivedOptions.forEach((option) => {
      optionsList.set(option.value, option);
    });

    return optionsList;
  }

  @computed
  get labeledValues(): TOption[] {
    const labeledValues: TOption[] = [];

    this.value.forEach((value) => {
      const valueOption = this.optionsList.get(value);

      if (valueOption) {
        labeledValues.push(valueOption);
      }
    });

    return labeledValues;
  }

  @action.bound
  setArchivedValue(value: TOption[] | null): void {
    this.archivedValue = value ?? [];
  }

  @action.bound
  setValue(value: (string | number)[] | null, setValueAsInitial?: boolean) {
    if (!!value && equalsDeep(value, this.value)) {
      return;
    }
    this.clearError();
    if (!value) {
      this.value = [];
      return;
    }
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: (string | number)[]): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  clearItem(): void {
    this.value = [];
    this.invalidValue = null;
    this.clearError();
  }

  checkIsReady(): boolean {
    return !this.errorText;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (!value || value === 'null' || value === 'undefined') {
      this.setValue([], setValueAsInitial);
      return true;
    }
    if (Array.isArray(value)) {
      const parsedValue = [];
      for (const rawValue of value) {
        if (typeof rawValue === 'number' && !Number.isNaN(rawValue)) {
          parsedValue.push(rawValue);
          continue;
        }
        if (typeof rawValue === 'string' && !Number.isNaN(Number(rawValue))) {
          parsedValue.push(Number(rawValue));
          continue;
        }
        console.error('wrong value type', rawValue);
      }
      this.setValue(parsedValue, setValueAsInitial);
      return true;
    }

    return false;
  }

  @action.bound
  filterInvalidValues() {
    let filteredValues = [...this.value];

    for (const item of [...filteredValues]) {
      const isExists = this.options.find((option) => option.value.toString() === item.toString());

      if (!isExists) {
        filteredValues = filteredValues.filter((valueItem) => valueItem !== item);
      }
    }

    if (filteredValues.length !== this.value.length) {
      this.value = [...filteredValues];
    }
  }

  @action.bound
  hasErrors(): boolean {
    if (!this.value.length && this.restrictions?.required) {
      this.errorText = 'newWellForm:Errors.required';
    }
    return !!this.errorText;
  }

  init = (): VoidFunction => {
    const trackOptionsAndResetWrongValueDisposer = this.trackOptionsAndResetWrongValue();

    return () => {
      trackOptionsAndResetWrongValueDisposer();
    };
  };
}
