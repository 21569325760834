import { action, makeObservable, observable, flow } from 'mobx';

import { MatchingImportStore, TConflict } from 'src/pages/import-matching-page/import-matching-page.store';

export class ConflictController {
  private _matchingImport: MatchingImportStore;
  @observable isModalOpened: boolean;
  @observable errorMessage: string;
  @observable isLoading: boolean;
  @observable currentConflictIndex: number;
  @observable conflictsLength: number;

  constructor(matchingImport: MatchingImportStore) {
    this._matchingImport = matchingImport;
    this.isModalOpened = false;
    this.errorMessage = '';
    this.isLoading = false;
    this.currentConflictIndex = 0;
    this.conflictsLength = 0;

    makeObservable(this);
  }

  // TODO: remove this function when requests wil be available and the request's interface appears
  private async _sendFakeRequest(...args: any) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        Math.random() * 10 > 1 ? resolve('') : reject();
      }, 500);
    });
  }

  @flow.bound
  async *_resolveConflict(conflict: TConflict) {
    this.errorMessage = '';
    this.isLoading = true;
    try {
      await this._sendFakeRequest();
      yield;
      return { ok: true };
    } catch (error) {
      yield;
      if (error instanceof Error) {
        this.errorMessage = error.message || 'matching:resolveConflictError';
        return { ok: false };
      }
      this.errorMessage = 'matching:resolveConflictError';
      return { ok: false };
    } finally {
      yield;
      this.isLoading = false;
    }
  }

  @action.bound
  async handleReplaceValue(value: string | number, conflict: TConflict) {
    // TODO: add value and conflict processing when back will be available
    const res = await this._resolveConflict(conflict);
    if (res?.ok) this.nextConflict();
  }

  @action.bound
  async handleDontAdd(conflict: TConflict) {
    // TODO: add conflict processing when back will be available
    const res = await this._resolveConflict(conflict);
    if (res?.ok) this.nextConflict();
  }

  @action.bound
  async handleAddToSystem(conflict: TConflict, t: (value: string) => string) {
    // TODO: add conflict processing when back will be available
    const res = await this._resolveConflict(conflict);
    if (res?.ok) this.nextConflict();
  }

  @action.bound
  nextConflict() {
    if (this.currentConflictIndex === null) return;
    if (this.currentConflictIndex >= this.conflictsLength - 1) {
      this.isModalOpened = false;
      this._matchingImport.clearConflicts();
      return;
    }
    this.currentConflictIndex += 1;
  }

  @action.bound
  runConflictScenario() {
    this.conflictsLength = this._matchingImport.conflicts.length;
    this.isModalOpened = true;
    this.currentConflictIndex = 0;
  }
}
