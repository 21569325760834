import { makeObservable, observable, flow, action, computed } from 'mobx';

import { DraftApi } from 'src/api/draft';
import { createDraft, deleteCarpetVersion, publishVersion, saveVersion } from 'src/api/version';
import { BaseApiError } from 'src/errors';
import { getSub } from 'src/shared/utils/auth';
import { hasValue } from 'src/shared/utils/common';
import { ConflictResolver } from 'src/shared/utils/conflict-resolver';
import { PlanVersionType, TPlanVersion } from 'src/store/comparison/types';
import { DRAFT_ASSIGNMENT } from 'src/store/drafts/types';
import { RouterStore } from 'src/store/router/router-store';

import { JobsStore } from '../jobs/jobs-store';
import { NotificationsStore } from '../notifications-store/notifications-store';
import { PlanVersionStore } from '../plan-version';
import { RootStore } from '../root-store';

export class DraftsStore {
  private readonly api = new DraftApi();
  private readonly jobsStore: JobsStore;
  private readonly notifications: NotificationsStore;
  private readonly planVersionsStore: PlanVersionStore;

  readonly conflictResolver: ConflictResolver;
  readonly router: RouterStore;

  @observable draftVersionId: number | null = null;
  @observable parentVersionId: number | null = null;
  @observable draftsList: TPlanVersion[] = [];

  constructor(rootStore: RootStore) {
    this.jobsStore = rootStore.jobsStore;
    this.router = rootStore.router;
    this.notifications = rootStore.notifications;
    this.planVersionsStore = rootStore.planVersion;
    this.conflictResolver = new ConflictResolver(rootStore);

    makeObservable(this);
  }

  private checkIsThereBlockedPublicWithoutDraft(): void {
    const sub = getSub();
    const publicDraft = this.planVersionsStore.publicDraft;

    if (!publicDraft || !publicDraft.data.isBlocked || publicDraft.data.blockUserId !== sub) {
      return;
    }

    const existingPublicDraftDraft = this.draftsList.find((d) => d.data.parentVersionId === publicDraft.id);

    if (existingPublicDraftDraft) {
      return;
    }

    this.unblockPublicDraft();
  }

  init = async (): Promise<void> => {
    await this.fetchDraftsList();

    this.checkIsThereBlockedPublicWithoutDraft();
  };

  private generateDraftName(draftAssignment: DRAFT_ASSIGNMENT): string {
    return `${draftAssignment}_${(+new Date()).toString(36)}`;
  }

  @flow.bound
  private async *checkIsDeletingDraftPublicAndUnblockIt(parentVersionId: number) {
    const planVersion = this.planVersionsStore.actualVersions.find((pv) => pv.id === parentVersionId);

    if (!planVersion) {
      return;
    }

    if (planVersion.data.versionType === PlanVersionType.public) {
      try {
        await this.api.unblockPublicDraft();
      } catch (e) {
        yield;

        if (e instanceof BaseApiError && e.responseMessage) {
          this.notifications.showErrorMessage(e.responseMessage);
          return;
        }

        console.error(e);
      }
    }
  }

  @computed
  get draftName(): string | null {
    return this.draftsList.find((draft) => draft.id === this.draftVersionId)?.data.name || null;
  }

  @computed
  get draft(): TPlanVersion | null {
    return this.draftsList.find((d) => d.id === this.draftVersionId) ?? null;
  }

  @computed
  get hasDraft(): boolean {
    return hasValue(this.draftVersionId);
  }

  @action.bound
  setDraft(draftVersionId: number, parentVersionId?: number | null) {
    this.draftVersionId = draftVersionId;
    this.parentVersionId = parentVersionId ?? null;
  }

  @flow.bound
  async *fetchDraftsList() {
    try {
      const planVersions: TPlanVersion[] = await this.api.getVersions();
      yield;

      this.draftsList = planVersions.sort((firstDraft, secondDraft) => secondDraft.createdAt - firstDraft.createdAt);
    } catch (e) {
      yield;
      console.error(e);
      throw e;
    }
  }

  async blockPublicDraft() {
    await this.api.blockPublicDraft();
    this.planVersionsStore.reloadVersions();
  }

  async unblockPublicDraft() {
    await this.api.unblockPublicDraft();
    this.planVersionsStore.reloadVersions();
  }

  @flow.bound
  async *createDraft(planVersionId: number, draftAssignment: DRAFT_ASSIGNMENT) {
    const draftName = this.generateDraftName(draftAssignment);

    const draftVersionId = await createDraft(planVersionId, draftName);
    await this.fetchDraftsList();
    yield;
    this.draftVersionId = draftVersionId;
    this.parentVersionId = planVersionId;
  }

  @flow.bound
  async *deleteCurrentDraft() {
    if (hasValue(this.draftVersionId)) {
      await deleteCarpetVersion(this.draftVersionId);
      yield;

      if (hasValue(this.parentVersionId)) {
        this.checkIsDeletingDraftPublicAndUnblockIt(this.parentVersionId);
      }

      await this.fetchDraftsList();
      yield;

      this.draftVersionId = null;
      this.parentVersionId = null;
    }
  }

  @flow.bound
  async *deleteDraftById(draftVersionId: number) {
    try {
      if (this.jobsStore.activeImportJob?.result?.planVersionId === draftVersionId) {
        this.jobsStore.cancelJob(this.jobsStore.activeImportJob.id);
      }

      await deleteCarpetVersion(draftVersionId);
      yield;

      const draft = this.draftsList.find((draft) => draft.id === draftVersionId);

      if (draft && hasValue(draft.data.parentVersionId)) {
        this.checkIsDeletingDraftPublicAndUnblockIt(draft.data.parentVersionId);
      }

      await this.fetchDraftsList();
      yield;
    } catch (e) {
      yield;
      console.error(e);
      throw e;
    }
  }

  @flow.bound
  async *publishDraft() {
    if (hasValue(this.draftVersionId)) {
      await publishVersion(this.draftVersionId);
      yield;

      if (hasValue(this.parentVersionId)) {
        this.checkIsDeletingDraftPublicAndUnblockIt(this.parentVersionId);
      }

      this.draftVersionId = null;
      this.parentVersionId = null;
    }
  }

  @flow.bound
  async *saveDraft() {
    try {
      if (hasValue(this.draftVersionId) && hasValue(this.parentVersionId)) {
        await saveVersion(this.draftVersionId, this.parentVersionId);
        await this.deleteCurrentDraft();
        yield;

        this.draftVersionId = null;
        this.parentVersionId = null;
      }
    } catch (e) {
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }

      console.error(e);
    }
  }
}
