import Highcharts from 'highcharts';

import { THEMES } from 'src/shared/constants/themes';

import { Drawer } from './drawer';
import { LabelsController } from './labels-controller';

declare module 'highcharts' {
  export interface Options {
    id: string | number;
    theme?: THEMES;
    // Global labels visible option. Required to draw labels.
    isLabelsEnabled?: boolean;
  }

  export interface Point {
    // Point internal field calculated by Highcharts.
    // Required to calculate label positions for each column on column chart.
    barX?: number;
  }
}

export function configureHighchartsLabels(): void {
  /** Default theme for labels drawing. */
  const DEFAULT_THEME = THEMES.dark;

  const isHighchart = (chart: unknown): chart is Highcharts.Chart => chart instanceof Highcharts.Chart;

  Highcharts.addEvent(Highcharts.Chart, 'render', function (event) {
    const chart = event?.target;

    if (!isHighchart(chart)) {
      return;
    }

    const labelClass = `label-${chart.options.id}`;

    document.querySelectorAll(`.${labelClass}`).forEach((element) => {
      element.remove();
    });

    const { theme, isLabelsEnabled } = chart.userOptions;

    if (isLabelsEnabled) {
      const drawer = new Drawer(this.renderer, labelClass);

      LabelsController.drawLabels(chart, drawer, theme || DEFAULT_THEME);
    }
  });
}
