import { useCallback, useEffect, useState } from 'react';

interface UseZoomModeParams {
  activatorKey?: string;
  onZoomOut?: VoidFunction;
}

export const useZoomMode = ({ activatorKey = 'Shift', onZoomOut }: UseZoomModeParams) => {
  const [isZoomMode, setIsZoomMode] = useState(false);

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === activatorKey) {
        setIsZoomMode(true);
      }
    };

    const keyupListener = (e: KeyboardEvent) => {
      if (e.key === activatorKey) {
        setIsZoomMode(false);
        onZoomOut?.();
      }
    };

    window.addEventListener('keydown', keydownListener);
    window.addEventListener('keyup', keyupListener);

    return () => {
      window.removeEventListener('keydown', keydownListener);
      window.removeEventListener('keyup', keyupListener);
    };
  }, [activatorKey, onZoomOut]);

  const cancelZoomMode = useCallback(() => setIsZoomMode(false), []);

  return { isZoomMode, cancelZoomMode };
};
