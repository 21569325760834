import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import styles from './menu-subheader-layout.module.scss';

type Props = {
  children: ReactNode;
  classname?: string;
};

export const MenuSubheaderLayout = observer(function MenuSubheaderLayout({ children, classname }: Props) {
  return <div className={clsx(styles.wrapper, classname)}>{children}</div>;
});
