import { action, flow, makeObservable, observable } from 'mobx';

import { uploadFile } from 'src/api/import-file';
import { BaseApiError } from 'src/errors';
import { ErrorWithTranslation } from 'src/shared/utils/error-with-translation';
import { createPromiseController, TPromiseController } from 'src/shared/utils/promise-controller';
import { RootStore } from 'src/store';
import { PlanVersionType } from 'src/store/comparison/types';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore } from 'src/store/plan-version';
import { RouterStore } from 'src/store/router/router-store';

export class UploadButtonStore {
  private readonly notifications: NotificationsStore;
  private readonly i18: I18NextStore;
  private readonly planVersions: PlanVersionStore;
  private readonly drafts: DraftsStore;

  @observable isLoading: boolean = false;
  @observable isSelectPageModalOpened: boolean = false;
  @observable isUploadModalOpened: boolean = false;
  @observable sheets?: string[];
  @observable selectSheetControls?: TPromiseController<string | null>;

  private router: RouterStore;

  constructor(rootStore: RootStore) {
    this.router = rootStore.router;
    this.notifications = rootStore.notifications;
    this.i18 = rootStore.i18;
    this.planVersions = rootStore.planVersion;
    this.drafts = rootStore.drafts;

    makeObservable(this);
  }

  @action.bound
  openSelectPageModal(): void {
    this.isSelectPageModalOpened = true;
  }

  @action.bound
  closeSelectPageModal(): void {
    this.isSelectPageModalOpened = false;
    this.selectSheetControls?.resolve(null);
  }

  @action.bound
  openUploadModal(): void {
    this.isUploadModalOpened = true;
  }

  @action.bound
  closeUploadModal(): void {
    this.isUploadModalOpened = false;
  }

  @action.bound
  selectSheet(sheetName: string): void {
    this.selectSheetControls?.resolve(sheetName);
  }

  @flow.bound
  async *uploadFile(file: File) {
    this.isLoading = true;

    try {
      const currentPlanVersion = this.planVersions.version;

      if (!currentPlanVersion) {
        console.error('Current plan version is undefined.');
        this.notifications.showErrorMessageT('drillingCarpet:UploadPlanModal.uploadError');

        this.isLoading = false;

        return;
      }

      if (!this.planVersions.isVersionActualDraft(currentPlanVersion.id)) {
        console.error('Current plan version is not public or personal actual draft.');
        this.notifications.showErrorMessageT('drillingCarpet:UploadPlanModal.invalidPlanVersion');

        this.isLoading = false;

        return;
      }

      if (currentPlanVersion.data.versionType === PlanVersionType.public) {
        await this.drafts.blockPublicDraft();
      }

      const res = await uploadFile(file);
      yield;

      const { fileId, sheets } = res;

      if (!sheets) return;

      this.sheets = sheets;
      let selectSheetRes: string | null;

      if (sheets.length > 1) {
        try {
          this.isSelectPageModalOpened = true;
          this.selectSheetControls = createPromiseController<string | null>();
          selectSheetRes = await this.selectSheetControls;

          yield;

          if (selectSheetRes === null) {
            await this.drafts.unblockPublicDraft();
            return;
          }
        } finally {
          this.isSelectPageModalOpened = false;
          this.selectSheetControls = void 0;
        }
      } else {
        selectSheetRes = sheets[0];
      }

      this.router.push(
        `/carpet/matching?sheetName=${selectSheetRes}&fileId=${fileId}&targetPlanVersionId=${currentPlanVersion.id}`
      );
    } catch (error) {
      yield;

      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }

      if (error instanceof ErrorWithTranslation) {
        this.notifications.showErrorMessage(error.translateError(this.i18.t));
        return;
      }
      console.error(error);
      this.notifications.showErrorMessageT('drillingCarpet:UploadPlanModal.uploadError');
    } finally {
      this.isLoading = false;
    }
  }
}
