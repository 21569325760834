import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { ComparingIndicatorColumn } from 'src/features/drilling-chart/presets/comparing-indicators-table/entities';
import { LoadingIndicatorsColumn } from 'src/features/drilling-chart/presets/indicators-view-settings-sidebar/entities';
import { TrendGranularity } from 'src/shared/constants/trend-granularity';
import { TrendType } from 'src/shared/constants/trend-type';
import { LoadingField } from 'src/shared/utils/loading-field';

import { Indicators } from './indicators-api';

export namespace ComparingIndicators {
  export type TwoVersionsData<TItems> = { first: TItems; second: TItems };

  export type ColumnValues = TwoVersionsData<Partial<Record<TrendType, number>>>;

  export type LoadingColumnValues = TwoVersionsData<Partial<Record<TrendType, LoadingField>>>;

  export type Column = ColumnValues & {
    start: number;
    end: number;
  };

  export type ViewItems = {
    items: (ComparingIndicatorColumn | LoadingIndicatorsColumn)[];
    result: ColumnValues | LoadingColumnValues;
  };
}

export class ComparingIndicatorsApi {
  async getIndicators(
    /** Format: "2022-01-01". */
    from: string,
    /** Format: "2022-01-01". */
    to: string,
    trendGranularity: TrendGranularity,
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    filter?: Record<string, unknown>
  ): Promise<Indicators.RawIndicators> {
    try {
      const { data } = await agent.post<Indicators.RawIndicators>('/dashboard/trend', {
        ...filter,
        from,
        to,
        trendGranularity,
        // TODO: add filter by trend type.
        trendTypes: [],
        groupBy: 'date',
        planVersionId: [firstPlanVersionId.toString(), secondPlanVersionId.toString()],
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
