import { TFormulaArgument, TFormulaArgumentTypes } from 'src/api/new-well/types';
import { AssertionError } from 'src/errors/assert';
import { Directories } from 'src/store/directories/directories.store';

import { Item } from '../entities/abstract-control-entities';

import { hasValue } from './common';

export function getArgumentsValues(
  fields: Record<string, Item>,
  directoriesStore: Directories,
  rawArguments: TFormulaArgument[]
): Record<string, unknown> {
  const processedArguments: Record<string, unknown> = {};

  try {
    argsCycle: for (const rawArg of rawArguments) {
      if (['string[]', 'number[]', 'boolean[]'].includes(rawArg.type)) {
        if (rawArg.attr) {
          const [directoryName, directoryValueName] = rawArg.attr.split('.');

          if (rawArg.usesConstDirectory) {
            continue;
          }

          const control = fields[rawArg.control];

          const directoryIds = control?.value;

          if (!Array.isArray(directoryIds)) {
            continue;
          }

          const directories = directoriesStore.getObject(directoryName);

          if (!directories) {
            continue;
          }

          const values: unknown[] = [];

          for (const dirId of directoryIds) {
            const directory = directories.find((dir) => dir.id === dirId);

            if (!directory || !hasValue(directory.data[directoryValueName])) {
              processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, null);
              continue argsCycle;
            }

            values.push(directory.data[directoryValueName]);
          }

          processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, values);

          continue;
        } else {
          const value = fields[rawArg.control]?.value;
          processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, value);
        }
      } else {
        if (rawArg.attr) {
          const [directoryName, directoryValueName] = rawArg.attr.split('.');

          if (!rawArg.usesConstDirectory) {
            const control = fields[rawArg.control];

            const directoryId = control?.value;

            const directory = directoriesStore.getObject(directoryName)?.find((dir) => dir.id === directoryId);

            if (!directory || !hasValue(directory.data[directoryValueName])) {
              processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, null);
              continue;
            }

            const value = directory.data[directoryValueName];
            processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, value);

            continue;
          } else {
            const directory = directoriesStore.getObject(directoryName)?.[0];

            if (!directory || !hasValue(directory.data[directoryValueName])) {
              processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, null);
              continue;
            }

            const value = directory.data[directoryValueName];
            processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, value);

            continue;
          }
        }
        const value = fields[rawArg.control]?.value;
        processedArguments[rawArg.control] = checkValueAndConvertIfNeeded(rawArg.type, !!rawArg.nullable, value);
      }
    }
  } catch (e) {
    console.error(e);
    return {};
  }

  return processedArguments;
}

function checkValueAndConvertIfNeeded(type: TFormulaArgumentTypes, nullable: boolean, value: unknown): unknown {
  if (nullable && (value === null || value === undefined)) {
    return null;
  }

  switch (type) {
    case 'number': {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        return value;
      }

      throw new AssertionError('invalid value type');
    }
    case 'string': {
      if (typeof value === 'string') {
        return value;
      }

      throw new AssertionError('invalid value type');
    }
    case 'boolean': {
      if (typeof value === 'boolean') {
        return value;
      }

      throw new AssertionError('invalid value type');
    }

    case 'boolean[]': {
      if (!Array.isArray(value)) {
        throw new AssertionError('invalid value type');
      }

      return value.map((rawValue) => {
        if (typeof rawValue === 'boolean') {
          return rawValue;
        }

        throw new AssertionError('invalid value type');
      });
    }
    case 'number[]': {
      if (!Array.isArray(value)) {
        throw new AssertionError('invalid value type');
      }

      return value.map((rawValue) => {
        if (typeof rawValue === 'number' && !Number.isNaN(rawValue)) {
          return rawValue;
        }

        throw new AssertionError('invalid value type');
      });
    }
    case 'string[]': {
      if (!Array.isArray(value)) {
        throw new AssertionError('invalid value type');
      }

      return value.map((rawValue) => {
        if (typeof rawValue === 'string') {
          return rawValue;
        }

        throw new AssertionError('invalid value type');
      });
    }
  }
}
