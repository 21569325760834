import { observer } from 'mobx-react-lite';

import {
  GhostPadRigOperationWrapper as SortableGhostPadWrapper,
  GhostPadRigOperationWrapper,
} from 'src/features/drilling-chart/features/pad-rig-operation-wrapper';

import {
  InsertionPoint,
  InteractiveInsertionPoint,
  LoadingRigOperations,
  PadRigOperation,
  TemporaryPadRigOperation,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { RigOperationsDnd } from '../../../presets/rig-operations-dnd/rig-operations-dnd-module';
import { Viewport } from '../../../shared/viewport/viewport';
import { ShadowPadRigOperation } from '../../data-items-full/presenter/shadow-pad-rig-operation';
import { ShadowWellRigOperation } from '../../data-items-full/presenter/shadow-well-rig-operation';
import { DraggableGhostPadRigOperationWrapper } from '../../data-items-full/view/draggable-ghost-pad-rig-operation-wrapper';
import { GhostWellRigOperationWrapper } from '../../data-items-full/view/ghost-well-rig-operation-wrapper';
import { LoadingRigOperationsWrapper } from '../../data-items-full/view/loading-rig-operations-wrapper';
import { ShadowPadRigOperationWrapper } from '../../data-items-full/view/shadow-pad-rig-operation-wrapper';
import { ShadowWellRigOperationWrapper } from '../../data-items-full/view/shadow-well-rig-operation-wrapper';
import { SortableGhostWellRigOperationWrapper } from '../../data-items-full/view/sortable-ghost-well-rig-operation-wrapper';
import { TemporaryPadRigOperationWrapper } from '../../data-items-full/view/temporary-pad-rig-operation-wrapper';
import { InsertionPointNeighbor } from '../../editing/shared/entities/insertion-point-neighbor';
import {
  InsertionPoint as InsertionPointComponent,
  InteractiveInsertionPoint as InteractiveInsertionPointComponent,
} from '../../editing/view/components/insertion-point';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';

import { DraggablePadRigOperationWrapper } from './draggable-pad-rig-operation-wrapper';

interface Props {
  item: RigsChartStore.ViewItem;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick?(padId: number, rigId: number): void;
}

export const EditingRigsDataItemCompact = observer(function EditingRigsDataItemCompact({
  item,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  viewSettingsProvider,
}: Props) {
  if (item instanceof TemporaryPadRigOperation) {
    return (
      <TemporaryPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof PadRigOperation) {
    return (
      <DraggablePadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
        onClick={() => onPadClick?.(item.id, item.parentRig.id)}
      />
    );
  }

  if (item instanceof LoadingRigOperations) {
    return (
      <LoadingRigOperationsWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (item instanceof InsertionPointNeighbor && item.neighbor instanceof PadRigOperation) {
    return (
      <DraggablePadRigOperationWrapper
        item={item.neighbor}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (RigsSorting.isPadGhost(item)) {
    return (
      <GhostPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof InteractiveInsertionPoint) {
    return (
      <InteractiveInsertionPointComponent
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (item instanceof InsertionPoint) {
    return (
      <InsertionPointComponent
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (RigOperationsDnd.isWellDraggableGhost(item) || RigOperationsDnd.isConflictWellDraggableGhost(item)) {
    return <GhostWellRigOperationWrapper item={item} viewSettingsProvider={viewSettingsProvider} />;
  }

  if (RigOperationsDnd.isPadDraggableGhost(item)) {
    return <DraggableGhostPadRigOperationWrapper item={item} viewSettingsProvider={viewSettingsProvider} />;
  }

  if (item instanceof ShadowPadRigOperation) {
    return (
      <ShadowPadRigOperationWrapper
        item={item}
        verticalViewport={verticalViewport}
        horizontalViewport={horizontalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof ShadowWellRigOperation) {
    return (
      <ShadowWellRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (RigsSorting.isPadGhost(item)) {
    return (
      <SortableGhostPadWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (RigsSorting.isWellGhost(item)) {
    return (
      <SortableGhostWellRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  return null;
});
