import { action, makeObservable, observable, ObservableSet } from 'mobx';

import { Dnd } from '../../types';

import { DndContextStore } from './dnd-context.store';
import { DraggableTransform } from './draggable-transform';

export class DraggableActive<TDraggableItem, TRelatedItem = unknown, TDraggableShadow = unknown> {
  private readonly className?: string;

  readonly id: string;
  readonly element: HTMLElement;
  readonly dataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>;
  readonly transform: DraggableTransform;
  readonly initialTransformCoordinated: Dnd.Transform;

  @observable relatedItems: ObservableSet<DndContextStore.DraggingEntry<TRelatedItem>> = observable.set();

  constructor(
    id: string,
    element: HTMLElement,
    dataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>,
    initialTransform?: Dnd.Transform,
    relatedItems?: DndContextStore.DraggingEntry<TRelatedItem>[],
    className?: string
  ) {
    this.id = id;
    this.element = element;
    this.dataItem = dataItem;
    this.className = className;

    this.initialTransformCoordinated = initialTransform || { x: 0, y: 0 };
    this.transform = new DraggableTransform(this.initialTransformCoordinated.x, this.initialTransformCoordinated.y);

    relatedItems?.forEach((item) => this.relatedItems.add(item));

    makeObservable(this);
  }

  @action.bound
  addRelatedItem(item: DndContextStore.DraggingEntry<TRelatedItem>): void {
    const { className } = this;

    if (!className) {
      return;
    }

    item.element.current?.classList.add(className);
    this.relatedItems?.add(item);
  }

  @action.bound
  removeRelatedItem(item: DndContextStore.DraggingEntry<TRelatedItem>): void {
    const { className } = this;

    if (!className) {
      return;
    }

    item.element.current?.classList.remove(className);
    this.relatedItems?.delete(item);
  }

  addActiveClassName = (): void => {
    const { className } = this;

    if (!className) {
      return;
    }

    this.element.classList.add(className);
    this.relatedItems?.forEach(({ element }) => {
      element.current?.classList.add(className);
    });
  };

  removeActiveClassName = (): void => {
    const { className } = this;

    if (!className) {
      return;
    }

    this.element.classList.remove(className);
    this.relatedItems?.forEach(({ element }) => {
      element.current?.classList.remove(className);
    });
  };
}
