import { TOptions } from 'i18next';
import { TFuncKey } from 'react-i18next';

import { showErrorMessage, showSuccessMessage, showWarningMessage } from 'src/shared/utils/messages';

import { I18NextStore } from '../i18next/i18next-store';

export class NotificationsStore {
  private readonly i18: I18NextStore;

  showErrorMessage: (value: string) => void;
  showSuccessMessage: (value: string) => void;
  showWarningMessage: (value: string) => void;

  constructor(i18: I18NextStore) {
    this.showErrorMessage = showErrorMessage;
    this.showSuccessMessage = showSuccessMessage;
    this.showWarningMessage = showWarningMessage;

    this.i18 = i18;
  }

  showErrorMessageT(text: TFuncKey, options?: TOptions): void {
    const message = this.i18.t(text, options);

    this.showErrorMessage(message);
  }

  showWarningMessageT(text: TFuncKey, options?: TOptions): void {
    const message = this.i18.t(text, options);

    this.showWarningMessage(message);
  }

  showSuccessMessageT(text: TFuncKey, options?: TOptions): void {
    const message = this.i18.t(text, options);

    this.showSuccessMessage(message);
  }
}
