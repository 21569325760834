import { makeObservable, observable, action, computed, reaction } from 'mobx';

import { TDictObject } from 'src/api/directories/types';
import { Directories } from 'src/store/directories/directories.store';
import { TJoinResponse } from 'src/store/directories/types';

import { IRestrictions, TOnChangeComboboxInstructions, TOption } from '../../../features/well-form/types';
import { Combobox } from '../abstract-control-entities';

type TRegularComboBoxData = {
  directories: Directories;
  placeholder?: string;
  formElementRefId: string;
  fieldId: string;
  objectName?: string;
  required: boolean;
  refObjectType?: string;
  refObjectAttr?: string;
  initialValue?: string | number;
  restrictions: IRestrictions;
  onChangeInstructions?: TOnChangeComboboxInstructions;
  refObjectFilterByFields?: {
    [key: string]: string;
  };
  directory: TDictObject[] | TJoinResponse[] | null;
};

export class RegularComboBox extends Combobox<string | number | null> {
  readonly required: boolean;

  @observable initialValue: string | number | null = null;
  @observable value: string | number | null;
  @observable archivedValue: TOption | null = null;
  options: TOption[] = observable.array();

  constructor(data: TRegularComboBoxData) {
    super(data);
    this.required = data.required;
    this.options = this.serializeDataToOptions();
    this.value = data.initialValue || null;

    makeObservable(this);
  }

  private trackArchivedValue(): VoidFunction {
    const disposer = reaction(
      () => this.value,
      (value) => {
        const archivedOption = this.getArchivedValueOption(value);
        if (!!archivedOption) {
          this.setArchivedValue(archivedOption);
        } else {
          this.setArchivedValue(null);
        }
      },
      { fireImmediately: true }
    );

    return disposer;
  }

  @computed
  get optionsList(): Map<string | number, TOption> {
    const optionsList = new Map();
    this.options.forEach((option) => {
      optionsList.set(option.value, option);
    });
    this.archivedOptions.forEach((option) => {
      optionsList.set(option.value, option);
    });

    return optionsList;
  }

  @action.bound
  setArchivedValue(value: TOption | null): void {
    this.archivedValue = value;
  }

  @action.bound
  setValue(value: number | string | null, setValueAsInitial?: boolean): void {
    if (value === this.value) {
      return;
    }
    this.clearError();
    this.invalidValue = null;
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: string | number | null): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (typeof value === 'string' && !isNaN(Number(value))) {
      this.setValue(Number(value), setValueAsInitial);
      return true;
    }
    if (value === 'null' || value === null || value === undefined) {
      this.setValue(null, setValueAsInitial);
      return true;
    }
    if (typeof value === 'number' && !Number.isNaN(value)) {
      this.setValue(value, setValueAsInitial);
      return true;
    }
    if (value !== null && value !== undefined && !Number.isNaN(value)) {
      this.invalidValue = value.toString();
    }
    return false;
  }

  @action.bound
  clearItem(): void {
    this.value = null;
    this.invalidValue = null;
    this.clearError();
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return !!this.value;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  hasErrors = (): boolean => {
    if (!this.value && this.restrictions.required) {
      this.errorText = 'newWellForm:Errors.required';
    }
    return !!this.errorText;
  };

  init = (): VoidFunction => {
    const trackOptionsAndResetWrongValueDisposer = this.trackOptionsAndResetWrongValue();
    const trackArchivedValueDisposer = this.trackArchivedValue();

    return () => {
      trackOptionsAndResetWrongValueDisposer();
      trackArchivedValueDisposer();
    };
  };
}
