import { action, computed, flow, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { TAddWellToChartSidebarView } from 'src/api/add-well-to-chart-sidebar/types';
import {
  loadAutocompletedData,
  TComputedFormValues,
  TInsert,
  TRequestParams,
} from 'src/api/new-well/autocopleted-data-api';
import { getRigPads } from 'src/api/new-well/requests';
import { BaseApiError } from 'src/errors';
import { Combobox } from 'src/shared/entities/abstract-control-entities';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getConcatedName } from 'src/shared/utils/get-concated-name';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { View } from 'src/store/views/views-store';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import { Approach, ApproachesTab } from '../well-form/entities/approach-entities';
import { FormStore } from '../well-form/entities/form.entity';

import { AddToChartSidebarPadEntity } from './pads/pad/pad.entity';
import { AddToChartSidebarWellEntity } from './pads/well/well.entity';
import { mapPads } from './utils';

type TAddWellToChartSidebarStoreOptions = {
  draftStore: DraftsStore;
  directories: Directories;
  appSettings: AppSettingsStore;
  notifications: NotificationsStore;
  wellFormManager: WellFormManager | WellFormManagerWithGeoTasksHistory;
  view: View<TAddWellToChartSidebarView>;
};

export class AddWellToChartSidebarStore {
  private readonly draftStore: DraftsStore;
  private readonly directories: Directories;
  private readonly appSettings: AppSettingsStore;
  private readonly wellFormManager: WellFormManager | WellFormManagerWithGeoTasksHistory;
  private readonly notifications: NotificationsStore;

  @observable private viewStore: View<TAddWellToChartSidebarView>;

  @observable isOpen = false;
  @observable isLoading = false;
  @observable pads: AddToChartSidebarPadEntity[] = [];
  @observable isInitialized = false;
  @observable startDate: Moment | null = null;
  @observable isLoseChangesModalOpened = false;

  private valuesCreatedByServer = new Map<FormStore, TComputedFormValues>();

  constructor({
    draftStore,
    directories,
    notifications,
    appSettings,
    wellFormManager,
    view,
  }: TAddWellToChartSidebarStoreOptions) {
    this.draftStore = draftStore;
    this.directories = directories;
    this.appSettings = appSettings;
    this.wellFormManager = wellFormManager;
    this.notifications = notifications;
    this.viewStore = view;

    makeObservable(this);
  }

  @computed
  private get formStore(): FormStore | null {
    return this.wellFormManager.currentFormStore;
  }

  @computed
  private get approach(): Approach | null {
    return this.formStore?.approachesTab?.approachesList.approaches[0] || null;
  }

  private serializeRequestData(insertData?: TInsert): TRequestParams | null {
    const draftVersionId = this.draftStore.draftVersionId;

    if (!draftVersionId || (!this.startDate && !insertData)) {
      return null;
    }

    if (insertData) {
      const data: TRequestParams = {
        planVersionId: draftVersionId,
        ...insertData,
      };

      return data;
    }

    if (!!this.startDate) {
      const data = {
        planVersionId: draftVersionId,
        startDate: this.startDate.unix(),
      };
      return data;
    }

    return null;
  }

  private setFormValues(values: TComputedFormValues, cacheValues = true): void {
    if (!this.formStore) {
      return;
    }

    const { stageList, ...rest } = values;

    if (cacheValues) {
      this.valuesCreatedByServer.set(this.formStore, values);
    }

    for (const fieldId in rest) {
      const value = rest[fieldId];
      const control = this.formStore.fields[fieldId];

      if (hasValue(value) && control) {
        control.setValueFromDirectory(value);
      }
    }

    this.formStore?.setFormValues(rest);
    this.formStore?.approachesTab?.approachesList.setAutocreatedStages(stageList);
  }

  @flow.bound
  private async *fetchViewSidebar() {
    this.isLoading = true;

    try {
      await this.viewStore.loadView();
      const { wells } = this.viewStore.view.pad;

      await this.directories.loadObjects([wells.refObjectType]);
      yield;

      this.isInitialized = true;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('drawers:addToChart.failedToLoadSidebar');
    } finally {
      this.isLoading = false;
    }
  }

  init = (): void => {
    this.fetchViewSidebar();
  };

  @computed
  get isResetValuesButtonShown(): boolean {
    const stage = this.formStore?.approachesTab?.approachesList.approaches[0]?.stagesList.stages[0];

    return (
      this.formStore?.activeTab instanceof ApproachesTab &&
      !this.formStore.activeTab.isDisabled &&
      !this.formStore.activeTab.isVisuallyDisabled &&
      (!!stage?.dateField?.startDate || !!stage?.calculatedStartDate || this.valuesCreatedByServer.has(this.formStore))
    );
  }

  @computed
  get currentDrillingRig(): number | null {
    if (!this.approach) {
      return null;
    }

    const value = this.approach?.fieldsList[0].value;

    if (typeof value === 'number') {
      return value;
    }

    return null;
  }

  @computed
  get currentBrigade(): number | null {
    if (!this.approach) {
      return null;
    }

    const value = this.approach?.fieldsList[1].value;

    if (typeof value === 'number') {
      return value;
    }

    return null;
  }

  @computed
  get rigTitle(): string | null {
    if (!this.isInitialized) {
      return null;
    }

    const rigControl = this.approach?.fieldsList[0];

    if (!rigControl || !(rigControl instanceof Combobox)) {
      return null;
    }

    const rigDirectory = this.directories
      .getObject(rigControl.refObjectType)
      ?.find((dir) => dir.id === rigControl.value);

    if (!rigDirectory) {
      return null;
    }

    const titleView = this.viewStore.view.title;

    if (!titleView || !titleView.attrConcat) {
      return null;
    }

    return getConcatedName(this.directories, titleView, rigDirectory.data);
  }

  @action.bound
  openLoseChangesModal(): void {
    this.isLoseChangesModalOpened = true;
  }

  @action.bound
  closeLoseChangesModal(): void {
    this.isLoseChangesModalOpened = false;
  }

  @action.bound
  resetToDirectoriesValue(): void {
    if (!this.approach || !this.formStore) {
      return;
    }

    const currentFormValuesCreatedByServer = this.valuesCreatedByServer.get(this.formStore);

    if (currentFormValuesCreatedByServer) {
      const { stageList, ...rest } = currentFormValuesCreatedByServer;

      this.formStore?.setFormValues(rest);
      this.formStore?.approachesTab?.approachesList.setAutocreatedStages(stageList);
    } else if (!this.valuesCreatedByServer.size && !!this.approach?.stagesList?.stages[0]?.dateField?.startDate) {
      this.startDate = this.approach.stagesList.stages[0].dateField.startDate;
      this.loadAutocompletedDataByStartDate(false);
    }
  }

  @action.bound
  changeStartDate(startDate: Moment | null) {
    this.startDate = startDate;
  }

  @action.bound
  openSidebar(): void {
    this.isOpen = true;
  }

  @action.bound
  closeSidebar(): void {
    this.isOpen = false;
  }

  @flow.bound
  async *loadRigData() {
    if (!this.approach) {
      return;
    }

    const rigId = this.approach?.fieldsList[0].value;
    const draftId = this.draftStore.draftVersionId;

    if (typeof rigId !== 'number' || !draftId) {
      return;
    }
    this.isLoading = true;

    try {
      const pads = await getRigPads(rigId, draftId);
      yield;

      this.pads = mapPads(this.directories, this.appSettings, this.viewStore.view, pads);
      yield;
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('drawers:addToChart.failedToLoadPads');
    } finally {
      this.isLoading = false;
    }
  }

  locateWell(
    insertOnWell: AddToChartSidebarWellEntity | null,
    insertAfterWell: AddToChartSidebarWellEntity | null
  ): void {
    const tripleIdKey = 'GOplan_PlanWellTriple.id';
    let data: TInsert | undefined;

    const insertOnWellTripleId =
      typeof insertOnWell?.data[tripleIdKey] === 'number' ? insertOnWell?.data[tripleIdKey] : null;
    const insertAfterWellTripleId =
      typeof insertAfterWell?.data[tripleIdKey] === 'number' ? insertAfterWell?.data[tripleIdKey] : null;

    if (insertOnWellTripleId) {
      data = {
        insertOnPlace: insertOnWellTripleId,
      };
    }
    if (insertAfterWellTripleId) {
      if (!data) {
        data = {
          insertAfter: insertAfterWellTripleId,
        };
      } else {
        data.insertAfter = insertAfterWellTripleId;
      }
    }

    if (data) {
      this.loadAutocompletedDataByNeighborWells(data);
    }
  }

  @flow.bound
  async *loadAutocompletedDataByStartDate(cacheValues = true) {
    if (!this.formStore) {
      return;
    }

    this.isLoading = true;

    try {
      const requestData = this.serializeRequestData();
      assert(requestData, 'some of required properties is not presented');

      const res = await loadAutocompletedData(this.formStore.tabs, requestData);
      yield;

      this.closeSidebar();
      this.setFormValues(res, cacheValues);
    } catch (e) {
      yield;
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
      } else {
        console.error(e);
        this.notifications.showErrorMessageT('drawers:addToChart.failedToAutofillStages');
      }
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *loadAutocompletedDataByNeighborWells(insert: TInsert) {
    if (!this.formStore) {
      return;
    }

    this.isLoading = true;

    try {
      const requestData = this.serializeRequestData(insert);
      assert(requestData, 'some of required properties is not presented');

      const res = await loadAutocompletedData(this.formStore.tabs, requestData);
      yield;

      this.closeSidebar();
      this.setFormValues(res);
    } catch (e) {
      yield;
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
      } else {
        console.error(e);
        this.notifications.showErrorMessageT('drawers:addToChart.failedToAutofillStages');
      }
    } finally {
      this.isLoading = false;
    }
  }
}
