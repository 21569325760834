import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { DrillingElementCard } from 'src/features/drilling-element-card';
import { ReactComponent as DrillingRigIcon } from 'src/shared/assets/icons/drilling-rigs-icons/drilling-rig-icon.svg';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';
import { IndexField } from 'src/shared/utils/index-field';

import { ChartRig } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useSortableGhost } from '../../sorting/hooks/use-sortable-ghost';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import styles from './data-headers.module.scss';

interface Props {
  item: SortableGhostItem<ChartRig>;
  verticalViewport: Viewport;
  isCompactView: boolean;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const GhostRig = observer(function GhostRig({
  item: ghostWrapper,
  isCompactView,
  verticalViewport,
  viewSettingsProvider,
}: Props) {
  const { transform } = useSortableGhost({
    id: ghostWrapper.activeKey,
  });

  const ref = useMovableElement({
    item: ghostWrapper,
    verticalViewport,
    transform,
  });

  if (!ghostWrapper.options?.isShown) {
    return null;
  }

  const descriptionAttributes = viewSettingsProvider.getAttributes(ghostWrapper.ghost);

  const getDescription = (): ReactNode => {
    if (!descriptionAttributes) {
      return null;
    }

    const nodes: string[] = descriptionAttributes.map((attribute) => {
      if (attribute instanceof IndexField) {
        return ghostWrapper.ghost.index !== undefined ? (ghostWrapper.ghost.index + 1).toString() : '-';
      }

      return attribute;
    });

    const descriptionString = nodes.join(', ');

    return <p title={descriptionString}>{descriptionString}</p>;
  };

  return (
    <div ref={ref} className={clsx(styles.rowHeader, styles.sortableGhost)}>
      <DrillingElementCard
        title={<LoadingTitle field={viewSettingsProvider.getTitle(ghostWrapper.ghost)} />}
        description={isCompactView ? undefined : getDescription()}
        icon={<DrillingRigIcon className={styles.rigIcon} />}
      />
    </div>
  );
});
