import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LetterIcon } from 'src/shared/assets/img/mail.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';

import { AddNewValueToDirectoryModalController } from './add-new-value-to-directory-modal.controller';
import styles from './add-new-value-to-directory-modal.module.scss';

type Props = {
  modalController: AddNewValueToDirectoryModalController;
};

export const AddNewValueToDirectoryModal = observer(function AddNewValueToDirectoryModal({ modalController }: Props) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyData = (): void => {
    modalController.copyData();
    setIsCopied(true);
  };

  const handleSendLetter = (): void => {
    modalController.sendLetter(t);
  };

  return (
    <Modal className={styles.modal} isOpened={modalController.isOpened}>
      <LetterIcon className={styles.mailImg} />
      <h2 className={styles.title}>{t('newWellForm:Modals.AddNewValueToDirectory.title')}</h2>
      <p className={styles.text}>
        {t('newWellForm:Modals.AddNewValueToDirectory.desc', { directory: modalController.data.directory })}
      </p>
      <ul className={styles.dataList}>
        {modalController.data.values.map((value, index) => {
          return <p key={index} className={styles.dataListItem}>{`${index + 1}. ${value}`}</p>;
        })}
      </ul>
      <div className={styles.buttonsWrapper}>
        <Button className={styles.button} variant="primary" onClick={handleCopyData}>
          {t('newWellForm:Modals.AddNewValueToDirectory.copyData')}
        </Button>
        <Button className={styles.button} disabled={!isCopied} variant="success" onClick={handleSendLetter}>
          {t('newWellForm:Modals.AddNewValueToDirectory.sendLetter')}
        </Button>
        <Button className={styles.button} variant="danger" onClick={modalController.onCloseModal}>
          {t('common:Buttons.cancel')}
        </Button>
      </div>
    </Modal>
  );
});
