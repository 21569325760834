import { observer } from 'mobx-react-lite';

import styles from './table-group-header.module.scss';

interface Props {
  title: string;
  subTitles: string[];
}

export const TableGroupHeader = observer(function TableGroupHeader({ title, subTitles }: Props) {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      {subTitles.map((item, index) => (
        <p key={index} className={styles.subtitle}>
          {item}
        </p>
      ))}
    </div>
  );
});
