import { action, makeObservable, observable } from 'mobx';

import { AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';
import { addPrefixesToObjectKeys } from 'src/shared/utils/prefixes';

import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';

import { PadRigOperation } from './pad-rig-operation';

export class TemporaryChartRig implements RigsChartDataModel.IChartRig {
  readonly id: number;
  readonly uniqueCreationKey: string;
  readonly item: AddRigSidebar.Rig;
  readonly commonRigData: Record<string, unknown>;

  @observable y: Range<number> | undefined;
  @observable index: number | undefined;
  @observable items: PadRigOperation[] | undefined;

  constructor(id: number, uniqueCreationKey: string, item: AddRigSidebar.Rig) {
    this.id = id;
    this.uniqueCreationKey = uniqueCreationKey;
    this.item = item;
    this.commonRigData = addPrefixesToObjectKeys(item, 'Common_Rig.');

    makeObservable(this);
  }

  getFieldValue(fieldName: string): string | number | undefined {
    const value = this.commonRigData[fieldName];

    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-temporary-rig-${this.uniqueCreationKey}`;
    }

    return `temporary-rig-${this.uniqueCreationKey}`;
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }

  @action.bound
  setIndex(index: number): void {
    this.index = index;
  }
}
