import { flow, makeObservable, observable } from 'mobx';

import { PadSidebarApi } from 'src/api/pad-info-sidebar/pad-sidebar-api';
import { BaseApiError } from 'src/errors';
import { GraphInfoSidebarStore, TSidebarInfoView } from 'src/features/graph-info-sidebar/graph-info-sidebar';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { RootStore } from 'src/store';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { EditingStore } from 'src/store/editing/editing-store';
import { View } from 'src/store/views/views-store';

import { RigsChartStore } from '../drilling-chart/presets/drilling-rigs-chart/rigs-chart.store';
import { Well } from '../graph-info-sidebar/entities/tab-with-wells-entities/well.entity';

import { PadStore } from './pad.store';

export class PadInfoSidebarStore extends GraphInfoSidebarStore {
  protected readonly viewManager: View<TSidebarInfoView>;
  private readonly editing: EditingStore;
  private readonly drafts: DraftsStore;
  private readonly api = new PadSidebarApi();
  private readonly rigId: number;
  private readonly rigChartStore: RigsChartStore | null = null;

  @observable private onChange: PadSidebarStore.ChangeHandler | null = null;

  readonly padStore: PadStore;

  constructor(rootStore: RootStore, rigId: number, rigsChartStore: RigsChartStore | undefined) {
    super({ rootStore });
    this.viewManager = rootStore.views.padSidebarView;
    this.editing = rootStore.editing;
    this.drafts = rootStore.drafts;
    this.padStore = new PadStore(this.notifications);
    this.rigId = rigId;
    this.rigChartStore = rigsChartStore ?? null;

    makeObservable(this);
  }

  private debouncedReloadChartData = debounce(() => {
    const planVersionId = this.editing.actualPlanVersionId;
    assert(hasValue(planVersionId), 'Invalid plan version ID.');

    this.rigChartStore?.loadChartData(planVersionId);
  }, 1000);

  @flow.bound
  async *init() {
    await this.loadView();
    yield;
  }

  @flow.bound
  async *loadPad(padId: number, planVersion: number) {
    if (!this.viewProvider) return;

    this.isLoading = true;

    try {
      await this.padStore.loadPad(padId, planVersion);
      await this.viewProvider?.setValues(this.padStore.padWithWells);
      yield;
    } catch (e) {
      yield;
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *toggleWellSlider(well: Well, isSliderUsing: boolean) {
    well.setIsSliderUsed(isSliderUsing);
    well.setIsLoading(true);

    try {
      await this.api.toggleWellSlider(well.planWellTripleId, isSliderUsing);
      yield;

      this.debouncedReloadChartData();
    } catch (e) {
      yield;

      well.setIsSliderUsed(!isSliderUsing);

      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
    } finally {
      well.setIsLoading(false);
    }
  }
}

// Отключенный функционал перемещения и удаления кустов/скважин
// @action.bound
//   setChangeHandler(onChange: PadSidebarStore.ChangeHandler | null): void {
//     this.onChange = onChange;
//   }

//   @flow.bound
//   async *onWellsOrderChange(
//     wells: Well[],
//     activeId: UniqueIdentifier,
//     overId: UniqueIdentifier,
//     oldIndex: number,
//     newIndex: number
//   ) {
//     if (!hasValue(this.editing.actualPlanVersionId)) {
//       return;
//     }

//     if (oldIndex === newIndex) {
//       return;
//     }

//     try {
//       assert(typeof overId === 'number', 'Invalid over ID.');
//       assert(typeof activeId === 'number', 'Invalid active ID.');

//       this.padStore.wells = wells;

//       // Will be null if new element is placed first.
//       const insertAfter = wells[newIndex - 1]?.id ?? null;
//       // Take second element from new list if new element is placed first.
//       const insertOnPlace = insertAfter === null ? wells[newIndex + 1]?.id : null;

//       await this.api.changeWellsOrder(
//         this.editing.actualPlanVersionId,
//         this.rigId,
//         insertAfter,
//         activeId,
//         insertOnPlace
//       );

//       await this.onChange?.(this.editing.actualPlanVersionId);

//       await this.drafts.conflictResolver.checkConflicts(this.editing.actualPlanVersionId);
//     } catch (e) {
//       yield;

//       console.error(e);
//       if (e instanceof BaseApiError && e.responseMessage) {
//         this.notifications.showErrorMessage(e.responseMessage);
//         return;
//       }
//       this.notifications.showErrorMessageT('errors:failedToChangeWellsOrder');
//     }

//     if (this.padStore.pad?.id) {
//       this.padStore.loadWells(this.padStore.pad.id, this.editing.actualPlanVersionId);
//     }
//   }

export namespace PadSidebarStore {
  export type ChangeHandler = (planVersionId: number) => Promise<void>;
}
