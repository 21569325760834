import moment from 'moment';

import { TDictObject } from 'src/api/directories/types';
import { RestrictionsType } from 'src/api/wells-list';
import { tryGetCurrentTitle } from 'src/shared/components/table-cell/combo-box-table-cell/utils';
import { tryGetCurrentTitles } from 'src/shared/components/table-cell/multi-combo-box-table-cell/utils';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { hasValue } from 'src/shared/utils/common';
import { TPlainDictObject } from 'src/store/directories/types';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { COLUMN_CONTROL, ColumnType, TableRow, CellValueType, COLUMN_TYPE } from 'src/store/table/types';
import { getCellValue } from 'src/store/table/utils';

export function getNumberValueWithCertainNumberOfDecimalPlaces(
  value: CellValueType,
  column: ColumnType | Omit<ColumnType, 'width'>
): CellValueType {
  if (column.type === COLUMN_TYPE.Number && hasValue(column.numberOfDecimalPlaces) && !Number.isNaN(Number(value))) {
    return Number(Number(value).toFixed(column.numberOfDecimalPlaces));
  }

  return value;
}

export function renderCellData(
  value: CellValueType,
  column: ColumnType | Omit<ColumnType, 'width'>,
  object: TDictObject[] | TPlainDictObject[] | null
): string | number | null {
  if (typeof value === 'boolean' || !hasValue(value)) {
    return null;
  }

  if (Array.isArray(value)) {
    if (column.control === COLUMN_CONTROL.MultiComboBox) {
      return tryGetCurrentTitles(value, column, object);
    }

    return null;
  }

  switch (column.control) {
    case COLUMN_CONTROL.DateOnlyPicker:
      return moment.unix(Number(value)).format(DATE_FORMAT);

    case COLUMN_CONTROL.ComboBox:
      return tryGetCurrentTitle(String(value), column, object);

    case COLUMN_CONTROL.Progress:
      return tryGetCurrentTitle(String(value), column, object);

    case COLUMN_CONTROL.Field: {
      return value;
    }

    default:
      return value;
  }
}

export function getRowProgressPercent(row: TableRow, progressAttrNames: string[]): number {
  const filledFieldsNumber = progressAttrNames.reduce<number>((prev, curr) => {
    if (hasValue(row[curr])) {
      return prev + 1;
    }

    return prev;
  }, 0);

  return Math.floor((filledFieldsNumber / progressAttrNames.length) * 100);
}

export function isDependentColumnDisabled(column: ColumnType | Omit<ColumnType, 'width'>, row: TableRow): boolean {
  if (!column.enableIf) return false;

  for (const rule of column.enableIf) {
    const dependentCellValue = getCellValue(row[rule.attr]);

    if (!dependentCellValue) {
      return true;
    }

    if (dependentCellValue?.toString() !== rule.value.toString()) {
      return true;
    }
  }

  return false;
}

export function isValidationErrorDisabled(
  column: ColumnType | Omit<ColumnType, 'width'>,
  row: TableRow,
  isTableValidationError: boolean
): boolean {
  const currentCell = row[column.name];

  if (currentCell && 'isError' in currentCell && !currentCell.isError && isTableValidationError) {
    return true;
  }

  if (!currentCell && isTableValidationError) {
    return true;
  }

  return false;
}

export function isEditingDisabled(column: ColumnType | Omit<ColumnType, 'width'>, isEditing: boolean): boolean {
  return isEditing && !column.isChangable;
}

export function isDisabledCell(
  column: ColumnType | Omit<ColumnType, 'width'>,
  row: TableRow,
  isTableValidationError: boolean,
  isEditing: boolean
): boolean {
  if (column.control === COLUMN_CONTROL.Progress) {
    return false;
  }

  if (isEditingDisabled(column, isEditing)) {
    return true;
  }

  if (isValidationErrorDisabled(column, row, isTableValidationError)) {
    return true;
  }

  if (isDependentColumnDisabled(column, row)) {
    return true;
  }

  return false;
}

export function isValidNumberValue(
  value: CellValueType,
  notifications: NotificationsStore,
  restrictions?: RestrictionsType
): boolean {
  const numberValue = value !== 0 && !value ? null : Number(value);

  const higherThen = restrictions?.higherThen;
  const lowerThen = restrictions?.lowerThan;
  const required = restrictions?.required;

  if (Number.isNaN(numberValue)) {
    notifications.showErrorMessageT('errors:mustBeANumber');
    return false;
  }

  if (!hasValue(restrictions)) {
    return true;
  }

  if (!hasValue(numberValue)) {
    if (required) {
      notifications.showErrorMessageT('errors:required');
      return false;
    }

    return true;
  }

  if (hasValue(lowerThen)) {
    if (numberValue >= lowerThen) {
      notifications.showErrorMessageT('errors:lowerThenNumber', { maximum: lowerThen });
      return false;
    }
  }

  if (hasValue(higherThen)) {
    if (numberValue <= higherThen) {
      notifications.showErrorMessageT('errors:higherThenNumber', { minimum: higherThen });
      return false;
    }
  }

  return true;
}

export function isValidStringValue(
  value: CellValueType,
  notifications: NotificationsStore,
  restrictions?: RestrictionsType
): boolean {
  const stringValue = typeof value === 'string' ? value : null;

  const higherThen = restrictions?.higherThen;
  const lowerThen = restrictions?.lowerThan;
  const required = restrictions?.required;

  if (!hasValue(restrictions)) {
    return true;
  }

  if (required && !hasValue(stringValue)) {
    notifications.showErrorMessageT('errors:required');
    return false;
  }

  if (hasValue(lowerThen) && hasValue(stringValue)) {
    if (stringValue.length >= lowerThen) {
      notifications.showErrorMessageT('errors:lowerString', { maximum: lowerThen });
      return false;
    }
  }

  if (hasValue(higherThen) && hasValue(stringValue)) {
    if (stringValue.length <= higherThen) {
      notifications.showErrorMessageT('errors:higherThenString', { minimum: higherThen });
      return false;
    }
  }

  return true;
}
