import { TDictObject } from 'src/api/directories/types';
import { ApproachStage } from 'src/features/well-form/entities/approach-entities';
import { ItemContainer } from 'src/shared/entities/abstract-control-entities';
import { Directories } from 'src/store/directories/directories.store';

import {
  TSavedFormItems,
  TApproachStageRaw,
  TApproachStageSectionRaw,
  TItemRaw,
  TDateIntervalFieldRaw,
  TSerializedStage,
  TSerializedSection,
} from '../../types';
import { mapItems } from '../common-serializers';

type Options = {
  reference: TApproachStageRaw;
  directories: Directories;
  parentControl?: ItemContainer;
  wellLifeCycleStageId: TDictObject;
  fieldValues?: {
    name: string;
    value: unknown;
  }[];
};

export const mapApproachStage = (
  { reference, fieldValues, directories, wellLifeCycleStageId, parentControl }: Options,
  savedStage?: TSerializedStage
): ApproachStage => {
  const savedItems: TSavedFormItems = {};
  let savedSections: TSerializedSection[] | undefined = undefined;
  const fields: TItemRaw[] = [];
  const sectionReference: TApproachStageSectionRaw = reference.sectionTemplate.section;

  for (let item of reference.items) {
    if (!!item.refObjectAttrVisible) {
      if (wellLifeCycleStageId.data[item.refObjectAttrVisible]) {
        fields.push(item);
      }
      continue;
    }
    fields.push(item);
  }
  for (let key in savedStage) {
    if (!['type', 'sections', 'duration'].includes(key)) {
      savedItems[key] = savedStage[key];
    }
    const dateControl = reference.items.find(
      (item): item is TDateIntervalFieldRaw => item.control === 'DateTimeIntervalWithDuration'
    );
    if (['startDate', 'endDate'].includes(key) && dateControl) {
      savedItems[dateControl.fieldId] = {
        [dateControl.attrNames.startDate]: savedStage['startDate'],
        [dateControl.attrNames.endDate]: savedStage['endDate'],
      };
    }
    if (key.includes('sections')) {
      const sections = savedStage[key];
      if (sections && Array.isArray(sections)) {
        savedSections = sections;
      }
    }
  }

  fieldValues?.forEach((field) => {
    savedItems[field.name] = field.value;
  });

  const data = {
    fields: mapItems(fields, directories, savedItems),
    stageReference: reference,
    sectionReference,
    savedSections,
    directories,
    wellLifeCycleStageId,
    parentControl,
  };

  return new ApproachStage(data);
};
