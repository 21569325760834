import { AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';

export class EmptyRig {
  readonly dataItem: AddRigSidebar.Rig;
  readonly id: number;
  readonly groupId: number;
  readonly title: string;

  constructor(item: AddRigSidebar.Rig, title: string) {
    this.dataItem = item;
    this.id = item.id;
    this.groupId = item.modelId;
    this.title = title;
  }
}
