import { observer } from 'mobx-react-lite';

import styles from './well.module.scss';

interface Props {
  title: string;
  subtitle?: string;
}

export const WellTitle = observer(function WellTitle({ title, subtitle }: Props) {
  return (
    <>
      <span className={styles.titleColumn}>{title}</span>
      <span className={styles.titleColumn}>{subtitle}</span>
    </>
  );
});
