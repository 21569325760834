import { action, comparer, computed, makeObservable, observable, ObservableMap, ObservableSet } from 'mobx';

import { RigsChartDataStorage } from '../features/rigs-chart/data/rigs-chart-data-storage';
import { Range } from '../layers/model';

import { DateHelper } from './date-helper';
import { StorageKeyManager, StorageKeyManagerWithParser } from './storage-key-manager';

export class ViewFrameController<TElement> {
  private readonly frameHeight: number;
  private readonly keyManager = new StorageKeyManagerWithParser<ViewFrameController.Position, string>(
    ViewFrameController.generateFrameKey,
    ViewFrameController.parseFrameCoordinates
  );
  // Each element has a key like '{number};{number}_{number};{number}'
  private readonly framesMap: ObservableMap<string, ObservableSet<TElement>> = observable.map();

  /** Elements that do have not X position. */
  // Each element has a key like 'NONE;NONE_{number};{number}'
  private readonly elementsWithoutWidth: ObservableMap<string, ObservableSet<TElement>> = observable.map();

  /** Elements that do have not Y position. */
  // Each element has a key like '{number};{number}_NONE;NONE'
  private readonly elementsWithoutHeight: ObservableMap<string, ObservableSet<TElement>> = observable.map();

  @observable horizontalFrame?: Readonly<Range<number>>;
  @observable verticalFrame?: Readonly<Range<number>>;

  constructor(frameHeight: number) {
    this.frameHeight = frameHeight;

    makeObservable(this);
  }

  @action.bound
  private addElementToMap(
    element: TElement,
    position: ViewFrameController.Position,
    map: ObservableMap<string, ObservableSet<TElement>>
  ): void {
    const positionKey = this.keyManager.getKey(position);
    const frame = map.get(positionKey);

    if (frame) {
      frame.add(element);
    } else {
      map.set(positionKey, observable.set(new Set([element])));
    }
  }

  private getHorizontalFrame(viewRangeStart: number, viewRangeEnd: number): Range<number> {
    return {
      start: DateHelper.startOf(viewRangeStart, 'year'),
      end: DateHelper.endOf(viewRangeEnd, 'year'),
    };
  }

  private getVerticalFrame(viewRangeStart: number, viewRangeEnd: number): Range<number> {
    return {
      start: Math.floor(viewRangeStart / this.frameHeight) * this.frameHeight,
      end: Math.ceil(viewRangeEnd / this.frameHeight) * this.frameHeight,
    };
  }

  @action.bound
  setHorizontalViewRange(horizontalRange: Range<number>): void {
    const { start, end } = this.getHorizontalFrame(horizontalRange.start, horizontalRange.end);

    if (start !== this.horizontalFrame?.start || end !== this.horizontalFrame.end) {
      this.horizontalFrame = { start, end };
    }
  }

  @action.bound
  setVerticalViewRange(verticalRange: Range<number>): void {
    const { start, end } = this.getVerticalFrame(verticalRange.start, verticalRange.end);

    if (start !== this.verticalFrame?.start || end !== this.verticalFrame.end) {
      this.verticalFrame = { start, end };
    }
  }

  @action
  setElementToFrame: ViewFrameController.SetElementToFrameFn<TElement> = (element, position): void => {
    const { x: horizontalPosition, y: verticalPosition } = position;

    const hasZeroWidth = horizontalPosition && horizontalPosition.start === horizontalPosition.end;
    const hasZeroHeight = verticalPosition && verticalPosition.start === verticalPosition.end;

    if (hasZeroWidth || hasZeroHeight || (!horizontalPosition && !verticalPosition)) {
      return;
    }

    // Get frame the element is in.
    // Element can span multiple frames. In this case, clustered frame will be found.
    const horizontalFrameRange = horizontalPosition
      ? this.getHorizontalFrame(horizontalPosition.start, horizontalPosition.end)
      : undefined;
    const verticalFrameRange = verticalPosition
      ? this.getVerticalFrame(verticalPosition.start, verticalPosition.end)
      : undefined;

    const horizontalFrames = horizontalFrameRange
      ? RigsChartDataStorage.getChunksOfDateRange(horizontalFrameRange)
      : [undefined];
    const verticalFrames = verticalFrameRange
      ? ViewFrameController.getChunksOfVerticalRange(verticalFrameRange, this.frameHeight)
      : [undefined];

    for (const horizontalFrame of horizontalFrames) {
      for (const verticalFrame of verticalFrames) {
        if (horizontalFrame && verticalFrame) {
          this.addElementToMap(element, { x: horizontalFrame, y: verticalFrame }, this.framesMap);
        }

        if (!horizontalFrame && verticalFrame) {
          this.addElementToMap(element, { y: verticalFrame }, this.elementsWithoutWidth);
        }

        if (horizontalFrame && !verticalFrame) {
          this.addElementToMap(element, { x: horizontalFrame }, this.elementsWithoutHeight);
        }
      }
    }
  };

  @computed({ equals: comparer.shallow })
  get elements(): TElement[] | null {
    if (!this.horizontalFrame || !this.verticalFrame) {
      return null;
    }

    const horizontalFrames = RigsChartDataStorage.getChunksOfDateRange(this.horizontalFrame);
    const verticalFrames = ViewFrameController.getChunksOfVerticalRange(this.verticalFrame, this.frameHeight);

    const elements = new Set<TElement>();
    const addElementsToCommonSet = (frameKey: string, map: ObservableMap<string, ObservableSet<TElement>>): void => {
      const elementsFromFrame = map.get(frameKey);

      if (elementsFromFrame) {
        for (const element of elementsFromFrame) {
          elements.add(element);
        }
      }
    };

    for (const horizontalFrame of horizontalFrames) {
      for (const verticalFrame of verticalFrames) {
        const frameKey = this.keyManager.getKey({ x: horizontalFrame, y: verticalFrame });
        const verticalFrameKey = this.keyManager.getKey({ y: verticalFrame });
        const horizontalFrameKey = this.keyManager.getKey({ x: horizontalFrame });

        addElementsToCommonSet(frameKey, this.framesMap);
        addElementsToCommonSet(verticalFrameKey, this.elementsWithoutWidth);
        addElementsToCommonSet(horizontalFrameKey, this.elementsWithoutHeight);
      }
    }

    return [...elements];
  }

  @action.bound
  clearMap(): void {
    this.framesMap.clear();
    this.elementsWithoutWidth.clear();
    this.elementsWithoutHeight.clear();
  }
}

export namespace ViewFrameController {
  const emptyValue = 'NONE';

  /** [horizontalRange, verticalRange] */
  export type Position = { x?: Range<number>; y?: Range<number> };

  export type SetElementToFrameFn<TElement> = (element: TElement, position: ViewFrameController.Position) => void;

  export const generateFrameKey: StorageKeyManager.KeyGenerator<Position, string> = ({ x, y }) =>
    `${x?.start ?? emptyValue};${x?.end ?? emptyValue}_${y?.start ?? emptyValue};${y?.end ?? emptyValue}`;

  export const parseFrameCoordinates: StorageKeyManager.KeyParser<string, Position> = (key) => {
    const [horizontalRangeString, verticalRangeString] = key.split('_');
    const [horizontalStartString, horizontalEndString] = horizontalRangeString.split(';');
    const [verticalStartString, verticalEndString] = verticalRangeString.split(';');

    let horizontalRange: Range<number> | undefined;
    let verticalRange: Range<number> | undefined;

    if (horizontalStartString !== emptyValue && horizontalEndString !== emptyValue) {
      horizontalRange = { start: Number(horizontalStartString), end: Number(horizontalEndString) };
    }

    if (verticalStartString !== emptyValue && verticalEndString !== emptyValue) {
      verticalRange = { start: Number(verticalStartString), end: Number(verticalEndString) };
    }

    return { x: horizontalRange, y: verticalRange };
  };

  export const getChunksOfVerticalRange = (range: Range<number>, rangeSize: number): Range<number>[] => {
    const chunks: Range<number>[] = [];

    let start = range.start;

    do {
      chunks.push({
        start,
        end: start + rangeSize,
      });

      start += rangeSize;
    } while (start < range.end);

    return chunks;
  };
}
