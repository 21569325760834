import { useContext, useEffect } from 'react';

import { SortableTransform } from '../sortable-transform';
import { SortingContext } from '../sorting-context';

export function useSortableGhost({ id }: UseSortableGhost.Args): UseSortableGhost.Return {
  const { active, containerRect, sortableContainerElement } = useContext(SortingContext);

  useEffect(() => {
    if (sortableContainerElement) {
      sortableContainerElement.style.display = 'block';

      return () => {
        sortableContainerElement.style.display = 'none';
      };
    }
  }, [sortableContainerElement]);

  if (!active || !containerRect || id !== active.id || !sortableContainerElement) {
    return {};
  }

  return {
    transform: active.transform,
    sortableContainerElement,
  };
}

export namespace UseSortableGhost {
  export type Args = {
    /** ID of element that handles pointer events (parent). Required to get parent transform object. */
    id: string;
  };

  export type Return = {
    sortableContainerElement?: HTMLElement;
    transform?: SortableTransform;
    isPositionAvailable?: boolean;
  };
}
