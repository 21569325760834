import { autorun, computed, makeObservable } from 'mobx';

import { RootStore } from 'src/store';

import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';
import { ReadOnlyPlugin } from './read-only.plugin';

export class DrilledWellPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }

  connect(form: FormStore): VoidFunction | void {
    this.form = form;
    const readOnlyPlugin = form.plugins.find((plugin): plugin is ReadOnlyPlugin => plugin instanceof ReadOnlyPlugin);
    if (!readOnlyPlugin) {
      return;
    }
    return autorun(() => {
      readOnlyPlugin.setIsFormInReadOnlyMode(this.isWellDrilled);
    });
  }

  @computed
  private get isWellDrilled(): boolean {
    if (!this.form) {
      return false;
    }
    const drillingContStatusId = this.form.fields['drillingContinuationStatus']?.value;

    if (typeof drillingContStatusId === 'number') {
      const drilingContStatusDirectoryValue = this.rootStore?.directories
        .getObject('Common_DrillingContinuationStatus')
        ?.find((dirValue) => dirValue.id === drillingContStatusId);

      if (drilingContStatusDirectoryValue && drilingContStatusDirectoryValue.data['isDrilled']) {
        return true;
      }
    }

    return false;
  }
}
