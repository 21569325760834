import { observer } from 'mobx-react-lite';

import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';

interface Props {
  pageStore: WellsPageStore;
  className?: string;
}

export const WellsTabs = observer(function WellsTabs({ pageStore, className }: Props) {
  if (!pageStore.tabs || !pageStore.tab) {
    return null;
  }

  const tabsItems =
    pageStore.tabs &&
    Object.keys(pageStore.tabs).map((tab) => {
      const label = pageStore.directories.getFieldLabel(tab);

      return { key: tab, title: typeof label === 'string' ? label : tab };
    });

  return (
    <SwitchButtonGroup
      onChange={pageStore.setTab}
      defaultOptionKey={pageStore.tab}
      className={className}
      items={tabsItems}
    />
  );
});
