import { action, computed, flow, makeObservable, observable } from 'mobx';

import { AddRigSidebarApi, AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';
import { OperationAbortedError } from 'src/errors';
import { RadioGroup } from 'src/shared/components/radio-group/radio-group';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { ViewsStore } from 'src/store/views/views-store';

import { hasValue } from '../../../../shared/utils/common';

import { EmptyRig } from './entities/empty-rig';
import { mapRigs } from './utils';

export class AddRigSidebarStore {
  private readonly api = new AddRigSidebarApi();
  private readonly notifications: NotificationsStore;
  private readonly editing: EditingStore;
  private readonly views: ViewsStore;
  private readonly directories: Directories;

  private abortController: AbortController | null = null;

  @observable private view: AddRigSidebar.View | null = null;
  @observable private _rigs?: EmptyRig[];
  @observable isLoading = false;
  @observable searchString: string = '';
  @observable selectedRigId?: number;

  constructor(rootStore: RootStore) {
    this.notifications = rootStore.notifications;
    this.editing = rootStore.editing;
    this.views = rootStore.views;
    this.directories = rootStore.directories;

    makeObservable(this);
  }

  @flow.bound
  async *fetchEmptyRigs(): Promise<void> {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!hasValue(planVersionId)) {
      return;
    }

    this.abortController?.abort();

    try {
      this.isLoading = true;
      if (!this.view) {
        const view = await this.views.addRigSidebarView.loadView();
        yield;

        this.view = view;
      }

      this.abortController = new AbortController();

      const rigs = await this.api.getRigs(planVersionId, this.searchString, this.abortController.signal);
      yield;

      this._rigs = mapRigs(this.view, rigs, this.directories);
    } catch (e) {
      if (!(e instanceof OperationAbortedError)) {
        console.error(e);
        this.notifications.showErrorMessageT('errors:failedToLoadRigs');
      }
    } finally {
      this.isLoading = false;
    }
  }

  @computed
  get rigs(): RadioGroup.Item<number>[] {
    if (!this._rigs?.length) {
      return [];
    }

    return this._rigs.map((rig) => ({
      key: rig.id.toString(),
      value: rig.id,
      label: rig.title,
    }));
  }

  @action.bound
  setSearchString(searchString: string) {
    this.searchString = searchString;
  }

  @action.bound
  onRigChange(value: number): void {
    this.selectedRigId = value;
  }

  @computed
  get selectedRig(): EmptyRig | null {
    if (!hasValue(this.selectedRigId)) {
      return null;
    }

    const selectedRig = this._rigs?.find(({ id }) => id === this.selectedRigId);

    return selectedRig || null;
  }
}
