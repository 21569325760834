import { ReactNode } from 'react';

import { Item } from '../../../shared/entities/abstract-control-entities';
import { Label } from '../../../shared/entities/control-entities';
import { AddNewValueButton } from '../form-controls/new-well-label-control/add-new-value-button';

export const getAdditionalItemComponent = (item: Item, label: string): ReactNode | null => {
  if (item instanceof Label) {
    return <AddNewValueButton directoryName={label} />;
  }
  return null;
};
