import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { TimelineChart } from '../../../components/timeline-chart';
import { useMovableContainer } from '../../../shared/use-movable-container';
import { Viewport } from '../../../shared/viewport/viewport';
import { IHeaderColumn } from '../data/header-data-model.types';

import { CurrentDate } from './current-date';

import styles from './header.module.scss';

const { HeaderColumn } = TimelineChart;

interface Props {
  data: IHeaderColumn[];
  viewport: Viewport;
  onPointerDown(): void;
  onPointerMove(offsetX: number, offsetY?: number): void;
  onPointerUp(): void;
  onZoom?(offset: number, centerPosition: number): void;
  onZoomOut?: VoidFunction;
  className?: string;
  contentClassName?: string;
}

export const Header = observer(function Header({
  data,
  viewport,
  className,
  contentClassName,
  onPointerUp,
  onPointerMove,
  onPointerDown,
  onZoom,
  onZoomOut,
}: Props) {
  const { containerRef, handlePointerDown, handlePointerMove, handlePointerUp, handleScroll } = useMovableContainer({
    horizontalViewport: viewport,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onZoom,
    onZoomOut,
    onCancel: onPointerUp,
    onBlur: onPointerUp,
  });

  return (
    <div
      ref={containerRef}
      className={clsx(className, styles.columnsWrapper)}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onWheel={handleScroll}
    >
      <div className={clsx(contentClassName, styles.columns)}>
        {data.map((item) => (
          <HeaderColumn key={`${item.start}-${item.end}`} item={item} horizontalViewport={viewport} />
        ))}

        <CurrentDate viewport={viewport} />
      </div>
    </div>
  );
});
