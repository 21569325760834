import { action, flow, makeObservable, observable } from 'mobx';

import { RigSidebarApi } from 'src/api/rig-sidebar/rig-sidebar-api';
import { GraphInfoSidebarStore, TSidebarInfoView } from 'src/features/graph-info-sidebar/graph-info-sidebar';
import { RootStore } from 'src/store';
import { EditingStore } from 'src/store/editing/editing-store';
import { ENABLE_EDITING_VARIANTS } from 'src/store/editing/types';
import { View } from 'src/store/views/views-store';

import { DraftsStore } from '../../../store/drafts/drafts-store';

import { RigStore } from './rig.store';
import { SidebarMode } from './types';

export class RigSidebarStore extends GraphInfoSidebarStore {
  private readonly api = new RigSidebarApi();
  private readonly editing: EditingStore;
  private readonly drafts: DraftsStore;
  protected readonly viewManager: View<TSidebarInfoView>;

  // Отключенный функционал перемещения и удаления кустов/скважин
  // @observable private onChange: RigSidebarStore.ChangeHandler | null = null;

  readonly rigStore: RigStore;

  @observable sidebarMode: SidebarMode = SidebarMode.view;

  constructor(rootStore: RootStore) {
    super({ rootStore });
    this.viewManager = rootStore.views.rigSidebarView;
    this.editing = rootStore.editing;
    this.drafts = rootStore.drafts;
    this.rigStore = new RigStore(rootStore.notifications);

    makeObservable(this);
  }

  @flow.bound
  async *init() {
    await this.loadView();
    yield;
  }

  // Отключенный функционал перемещения и удаления кустов/скважин
  // @action.bound
  // setChangeHandler(onChange: RigSidebarStore.ChangeHandler | null): void {
  //   this.onChange = onChange;
  // }

  @flow.bound
  async *open(rigId: number, planVersionId?: number, sidebarMode?: SidebarMode): Promise<void> {
    if (!this.viewProvider) {
      return;
    }

    this.isLoading = true;

    try {
      await this.rigStore.loadRig(rigId, planVersionId);
      await this.viewProvider.setValues(this.rigStore.rigWithPads);
      yield;

      if (sidebarMode) {
        this.sidebarMode = sidebarMode;
      }
    } catch (e) {
      yield;
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  setSidebarMode(sidebarMode: SidebarMode) {
    this.sidebarMode = sidebarMode;
  }

  @action.bound
  async enableEditing() {
    const enableEditingVariant = await this.editing.enableEditing();

    if (enableEditingVariant === ENABLE_EDITING_VARIANTS.cancel) {
      return;
    }

    this.rigStore.padsStorage = this.rigStore.pads;
    this.sidebarMode = SidebarMode.edit;
  }

  // Отключенный функционал перемещения и удаления кустов/скважин
  // @flow.bound
  // async *removePad(padId: number) {
  //   if (!hasValue(this.editing.actualPlanVersionId)) return;
  //   this.isLoading = true;

  //   try {
  //     await removeRig(this.editing.actualPlanVersionId, OBJECT_TYPE.pad, [padId]);

  //     if (this.rigStore.rig) {
  //       this.rigStore.loadPads(this.rigStore.rig.id, this.editing.actualPlanVersionId);
  //     }
  //   } catch (error) {
  //     yield;
  //     console.error(error);
  //     if (error instanceof BaseApiError && error.responseMessage) {
  //       this.notifications.showErrorMessage(error.responseMessage);
  //       return;
  //     }
  //     this.notifications.showErrorMessageT('errors:failedToCopyWell');
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }

  // @flow.bound
  // async *onPadsOrderChange(
  //   activeId: UniqueIdentifier,
  //   overId: UniqueIdentifier,
  //   oldIndex: number,
  //   newIndex: number,
  //   pads: Pad[]
  // ) {
  //   if (!hasValue(this.editing.actualPlanVersionId) || oldIndex === newIndex) {
  //     return;
  //   }

  //   try {
  //     this.isLoading = true;

  //     // Hardcode-attr
  //     const wellTripleId = 'GOplan_PlanWellTriple.id';
  //     const draggablePad = this.rigStore.pads.find((pad) => activeId === pad.id);
  //     const padOnPlace = hasValue([newIndex]) ? this.rigStore.pads[newIndex] : null;
  //     const padAfter = hasValue(this.rigStore.pads[newIndex - 1]) ? this.rigStore.pads[newIndex] : null;

  //     const insert =
  //       draggablePad?.items.reduce<number[]>((prev, curr) => {
  //         const insert = curr.item[wellTripleId];

  //         if (typeof insert === 'number') {
  //           prev.push(insert);
  //         }

  //         return prev;
  //       }, []) ?? [];

  //     assert(padOnPlace?.items.length || padAfter?.items.length);

  //     const insertOnPlaceTripleId =
  //       newIndex + 1 !== this.rigStore.pads.length ? padOnPlace?.items[0].item[wellTripleId] : null;

  //     const insertAfterTripleId =
  //       newIndex + 1 === this.rigStore.pads.length
  //         ? padAfter?.items[padAfter.items.length - 1].item[wellTripleId]
  //         : null;

  //     const insertOnPlace = typeof insertOnPlaceTripleId === 'number' ? insertOnPlaceTripleId : null;
  //     const insertAfter = typeof insertAfterTripleId === 'number' ? insertAfterTripleId : null;

  //     await this.api.changeWellsListOrder(this.editing.actualPlanVersionId, insert, insertOnPlace, insertAfter);
  //     yield;

  //     await this.onChange?.(this.editing.actualPlanVersionId);
  //     await this.drafts.conflictResolver.checkConflicts(this.editing.actualPlanVersionId);
  //     yield;
  //   } catch (e) {
  //     yield;

  //     console.error(e);
  //     this.notifications.showErrorMessageT('errors:failedToChangePadsOrder');
  //   } finally {
  //     this.isLoading = false;
  //   }

  //   if (this.rigStore.rig) {
  //     this.rigStore.loadPads(this.rigStore.rig.id, this.editing.actualPlanVersionId);
  //   }
  // }

  // @flow.bound
  // async *onWellsOrderChange(
  //   padId: number,
  //   activeId: UniqueIdentifier,
  //   overId: UniqueIdentifier,
  //   oldIndex: number,
  //   newIndex: number,
  //   wells: Well[]
  // ) {
  //   if (!hasValue(this.editing.actualPlanVersionId) || !this.rigStore.rig) {
  //     return;
  //   }

  //   if (oldIndex === newIndex) {
  //     return;
  //   }

  //   const wellsData = wells.map((well) => well.item);

  //   if (hasRigOperationDateConflict(wellsData, newIndex)) {
  //     this.notifications.showErrorMessageT('errors:failedToChangeWellsOrder');
  //     return;
  //   }

  //   try {
  //     assert(typeof overId === 'number', 'Invalid over ID.');
  //     assert(typeof activeId === 'number', 'Invalid active ID.');

  //     const changedPad = this.rigStore.pads.find((pad) => pad.id === padId);

  //     assert(changedPad);

  //     changedPad.items = wells;

  //     // Will be null if new element is placed first.
  //     const insertAfter = wells[newIndex - 1]?.id ?? null;
  //     // Take second element from new list if new element is placed first.
  //     const insertOnPlace = insertAfter === null ? wells[newIndex + 1]?.id : null;

  //     await this.api.changeWellsOrder(
  //       this.editing.actualPlanVersionId,
  //       this.rigStore.rig.id,
  //       insertAfter,
  //       activeId,
  //       insertOnPlace
  //     );
  //     yield;

  //     await this.onChange?.(this.editing.actualPlanVersionId);

  //     await this.drafts.conflictResolver.checkConflicts(this.editing.actualPlanVersionId);
  //     yield;
  //   } catch (e) {
  //     yield;

  //     console.error(e);
  //     if (e instanceof BaseApiError && e.responseMessage) {
  //       this.notifications.showErrorMessage(e.responseMessage);
  //       return;
  //     }
  //     this.notifications.showErrorMessageT('errors:failedToChangeWellsOrder');
  //   }

  //   if (this.rigStore.rig) {
  //     this.rigStore.loadPads(this.rigStore.rig.id, this.editing.actualPlanVersionId);
  //   }
  // }
}

// export namespace RigSidebarStore {
//   export type ChangeHandler = (planVersionId: number) => Promise<void>;
// }
