import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode } from 'react';

import { Skeleton } from 'src/shared/components/skeleton';
import { LoadingField } from 'src/shared/utils/loading-field';

import { RegularAttribute } from '../../shared/entities/regular-attribute';
import { StageWellTooltipAttribute } from '../stage-well-tooltip-attribute';

import styles from './pad-rig-operation-wrapper.module.scss';

interface Props {
  title: ReactElement;
  subTitle?: string;
  attributes: RegularAttribute[] | LoadingField | null;
}

export const PadTooltipInner = observer(function PadTooltipInner({ title, subTitle, attributes }: Props) {
  const renderAttributes = (): ReactNode => {
    if (attributes instanceof LoadingField) {
      return <Skeleton rowsNumber={3} />;
    }

    return attributes?.map((attr) => <StageWellTooltipAttribute key={attr.label} item={attr} />);
  };

  return (
    <div className={styles.tooltipInner}>
      <h4 className={styles.tooltipHeading}>{title}</h4>
      {!!subTitle && <p className={styles.tooltipSubHeading}>{subTitle}</p>}

      {!!attributes && <Divider className={styles.tooltipDivider} />}

      <div className={styles.tooltipAttributesContainer}>{renderAttributes()}</div>
    </div>
  );
});
