import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useDraggable } from 'src/features/drilling-chart/features/editing/view/hooks/use-draggable';
import { WellTypeIcon } from 'src/features/well-type-icon';
import { ColoredCard } from 'src/shared/components/colored-card';
import { hasValue } from 'src/shared/utils/common';

import { SidebarWellRigOperation } from '../entities';

import { WellTitle } from './well-title';

import styles from './pad.module.scss';

type Props = {
  well: SidebarWellRigOperation;
  onWellClick(wellId: number | string): void;
};

export const WellComponent = observer(function WellComponent({ well, onWellClick }: Props) {
  const { listeners, isDragging } = useDraggable({
    id: well.getKey('draggable'),
    dataItem: well,
  });

  function onWellInfoSidebarOpen(wellId: number | null): void {
    if (hasValue(wellId)) {
      onWellClick(wellId);
    }
  }

  return (
    <ColoredCard
      cardColor={well.color}
      title={<WellTitle well={well} listeners={listeners} />}
      text={well.attributes.length ? well.attributes.join(' - ') : undefined}
      icons={<WellTypeIcon iconName={well.wellTypeIcon} className={styles.wellIcon} />}
      showExpandButton
      className={clsx(styles.well, isDragging && styles.wellDragging)}
      onShowInfo={() => onWellInfoSidebarOpen(well.wellId)}
    />
  );
});
