import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SaveIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as UploadIcon } from 'src/shared/assets/icons/cloud.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button/button';

import styles from './edit-mode-buttons.module.scss';

interface Props {
  onCancelEditing(): void;
  onSaveChanges(): void;
  onPublishChanges(): void;
  isDisabled: boolean;
  isLoading?: boolean;
}

export const EditModeButtons = observer(function EditModeButtons({
  onCancelEditing,
  onPublishChanges,
  onSaveChanges,
  isDisabled,
  isLoading,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        variant="primary"
        icon={<UploadIcon />}
        onClick={onPublishChanges}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        {t('common:Buttons.toPublish')}
      </Button>
      <Button
        className={styles.button}
        variant="danger"
        icon={<CloseIcon />}
        onClick={onCancelEditing}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        {t('common:Buttons.cancel')}
      </Button>
      <Button
        className={styles.button}
        variant="success"
        icon={<SaveIcon />}
        onClick={onSaveChanges}
        disabled={isDisabled}
        isLoading={isLoading}
      >
        {t('common:Buttons.save')}
      </Button>
    </div>
  );
});
