import { makeObservable, observable } from 'mobx';

import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { assert } from 'src/shared/utils/assert';
import { getNextIndex } from 'src/shared/utils/index-generator';
import { addCommonPrefix } from 'src/shared/utils/prefixes';

import { ComparingRigsChartDataModel } from '../features/comparing-rigs-chart/data';
import { RigsChartDataModel } from '../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../layers/model';
import { ComparingChartRig, MainComparingChartRig } from '../presets/comparing-drilling-rigs-chart/entities';
import { ChartRig, PadRigOperation, RigsGroup } from '../presets/drilling-rigs-chart/entities';

import { DataView } from './data-view/data-view';
import { ViewFrameController } from './view-frame-controller';

export class RigsDataPositionsCalculator {
  private readonly elementSizesGetters: RigsChartDataModel.IRigsChartElementSizesGetters;

  @observable dataVerticalRange: Range<number> | null = null;

  constructor(elementSizesGetters: RigsChartDataModel.IRigsChartElementSizesGetters) {
    this.elementSizesGetters = elementSizesGetters;

    makeObservable(this);
  }

  calculateRigsGroupPosition(
    previousItem: RigsChartDataModel.IChartRigsGroup | null,
    dataViewType: DataView.DataViewType,
    isCollapsed: boolean,
    shownWellAttributesNumber: number
  ) {
    if (!previousItem?.y) {
      return { start: 0, end: this.elementSizesGetters.groupHeader() };
    }

    if (isCollapsed) {
      const start = previousItem.y.end + this.elementSizesGetters.groupMargin();

      return {
        start: start,
        end: start + this.elementSizesGetters.groupHeader(),
      };
    }

    const previousGroupChildrenCount = previousItem.items.reduce((childrenCount: number, currentRig) => {
      if (currentRig instanceof MainComparingChartRig) {
        return childrenCount + 2;
      }

      return childrenCount + 1;
    }, 0);

    if (dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty) {
      const previousItemEnd =
        previousItem.rowsEnd ||
        previousItem.y.end + this.elementSizesGetters.rowHeaderCompact(previousGroupChildrenCount);

      const start = previousItemEnd + this.elementSizesGetters.groupMargin() + 1;

      return {
        start: start,
        end: start + this.elementSizesGetters.groupHeader(),
      };
    }

    const rowHeight =
      this.elementSizesGetters.card(shownWellAttributesNumber) +
      this.elementSizesGetters.cardGroupHeader() +
      this.elementSizesGetters.cardGroupMargin() * 2 +
      this.elementSizesGetters.cardGroupPadding() * 2;

    const previousItemEnd = previousItem.rowsEnd || previousItem.y.end + rowHeight * previousGroupChildrenCount;

    const start = previousItemEnd + this.elementSizesGetters.groupMargin() + 1;

    return {
      start: start,
      end: start + this.elementSizesGetters.groupHeader(),
    };
  }

  calculateDataRowPosition(
    parentY: Range<number>,
    dataViewType: DataView.DataViewType,
    isCollapsed: boolean,
    shownWellAttributesNumber: number,
    previousItemY?: Range<number>
  ) {
    if (isCollapsed) {
      return {
        start: parentY.start,
        end: parentY.start,
      };
    }

    const baseY = previousItemY || parentY;
    const start = baseY.end;

    if (dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty) {
      return {
        start: start,
        end: start + this.elementSizesGetters.rowHeaderCompact(),
      };
    }

    const rowHeight =
      this.elementSizesGetters.card(shownWellAttributesNumber) +
      this.elementSizesGetters.cardGroupHeader() +
      this.elementSizesGetters.cardGroupMargin() * 2 +
      this.elementSizesGetters.cardGroupPadding() * 2;

    return {
      start: start,
      end: start + Math.max(this.elementSizesGetters.rowHeaderMinHeight(), rowHeight),
    };
  }

  calculateRigRowPositionWithoutParent(
    dataViewType: DataView.DataViewType,
    shownWellAttributesNumber: number,
    previousItemY?: Range<number>
  ) {
    const start = previousItemY?.end ?? 0;

    if (dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty) {
      return {
        start: start,
        end: start + this.elementSizesGetters.rowHeaderCompact(),
      };
    }

    const rowHeight =
      this.elementSizesGetters.card(shownWellAttributesNumber) +
      this.elementSizesGetters.cardGroupHeader() +
      this.elementSizesGetters.cardGroupMargin() * 2 +
      this.elementSizesGetters.cardGroupPadding() * 2;

    return {
      start: start,
      end: start + Math.max(this.elementSizesGetters.rowHeaderMinHeight(), rowHeight),
    };
  }

  calculatePadPosition(parentY: Range<number>, dataViewType: DataView.DataViewType, isCollapsed: boolean) {
    const { start: parentStart, end: parentEnd } = parentY;

    if (isCollapsed) {
      return {
        start: parentStart,
        end: parentStart,
      };
    }

    if (dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty) {
      return {
        start: parentStart,
        end: parentStart + this.elementSizesGetters.cardGroupHeader() + this.elementSizesGetters.cardGroupPadding() * 2,
      };
    }

    return {
      start: parentStart + this.elementSizesGetters.cardGroupMargin(),
      end: parentEnd - this.elementSizesGetters.cardMargin(),
    };
  }

  calculateWellPosition(parentY: Range<number>, dataViewType: DataView.DataViewType, isCollapsed: boolean) {
    const { start: parentStart } = parentY;

    if (isCollapsed || dataViewType === DataView.DataViewType.compact) {
      return {
        start: parentStart,
        end: parentStart,
      };
    }

    return {
      start: parentStart + this.elementSizesGetters.cardGroupHeader() + this.elementSizesGetters.cardMargin(),
      end: parentY.end - this.elementSizesGetters.cardMargin(),
    };
  }

  calculateWellPositionByRigRow(rigRowY: Range<number>, dataViewType: DataView.DataViewType, isCollapsed: boolean) {
    const { start: parentStart } = rigRowY;

    if (isCollapsed || dataViewType === DataView.DataViewType.compact) {
      return {
        start: parentStart,
        end: parentStart,
      };
    }

    return {
      start: parentStart + this.elementSizesGetters.cardGroupHeader() + this.elementSizesGetters.cardMargin(),
      end: rigRowY.end - this.elementSizesGetters.cardMargin() - this.elementSizesGetters.cardGroupPadding(),
    };
  }

  calculatePositions(
    rigsGroups: RigsChartDataModel.IChartRigsGroup[],
    dataViewType: DataView.DataViewType,
    shownWellAttributesNumber: number,
    setElementToFrame: ViewFrameController.SetElementToFrameFn<RigsChartDataModel.ViewItem>
  ): void {
    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    let previousGroup: RigsChartDataModel.IChartRigsGroup | null = null;

    for (const group of rigsGroups) {
      const isPrevCollapsed = Boolean(previousGroup?.isCollapsed);
      const groupY = this.calculateRigsGroupPosition(
        previousGroup,
        dataViewType,
        isPrevCollapsed,
        shownWellAttributesNumber
      );

      group.setY(groupY);

      const items =
        group instanceof RigsGroup && group.temporaryRigsGroup
          ? [...group.items, ...group.temporaryRigsGroup.items]
          : group.items;

      let previousRig: RigsChartDataModel.IChartRig | null = null;
      let rowsStart = groupY.end;
      let rowsEnd = groupY.end;

      if (items) {
        for (let rigIndex = 0; rigIndex < items.length; rigIndex++) {
          const rig = items[rigIndex];
          const rigY = this.calculateDataRowPosition(
            groupY,
            dataViewType,
            group.isCollapsed,
            shownWellAttributesNumber,
            previousRig?.y
          );
          rig.setY(rigY);

          if (!rowsStart || rowsStart > rigY.start) {
            rowsStart = rigY.start;
          }

          if (rowsEnd < rigY.end) {
            rowsEnd = rigY.end;
          }

          const pads = rig.items;

          if (pads) {
            for (const pad of pads) {
              const padY = this.calculatePadPosition(rigY, dataViewType, group.isCollapsed);

              pad.setY(padY);

              if (!isCompactView && pad instanceof PadRigOperation) {
                for (const well of pad.wellRigOperations) {
                  const wellY = this.calculateWellPosition(pad.y, dataViewType, group.isCollapsed);

                  well.setY(wellY);

                  setElementToFrame(well, { x: well.x || undefined, y: wellY });
                }
              }

              setElementToFrame(pad, { x: pad.x, y: padY });
            }
          }

          setElementToFrame(rig, { y: rigY });

          previousRig = rig;
        }
      }

      group.setRowsY({
        start: rowsStart,
        end: rowsEnd,
      });

      setElementToFrame(group, { y: { start: groupY.start, end: rowsEnd } });

      previousGroup = group;
    }

    this.dataVerticalRange = {
      start: 0,
      end: previousGroup?.rowsEnd ?? previousGroup?.y?.end ?? 0,
    };
  }

  calculateComparingDataPositions(
    rigs: ChartRig[],
    dataViewType: DataView.DataViewType,
    shownWellAttributesNumber: number,
    setElementToFrame: ViewFrameController.SetElementToFrameFn<ComparingRigsChartDataModel.ViewItem>
  ): void {
    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    let previousRig: ChartRig = null!;

    for (let rigIndex = 0; rigIndex < rigs.length; rigIndex++) {
      const rig = rigs[rigIndex];

      // If only changes is shown and current rig is simple rig without changes (ChartRig).
      if (!(rig instanceof MainComparingChartRig) && !(rig instanceof ComparingChartRig)) {
        // Set zero positions to hide rigs without changes.
        rig.setY({ start: 0, end: 0 });

        const pads = rig.items;

        if (pads) {
          for (const pad of pads) {
            pad.setY({ start: 0, end: 0 });

            if (!isCompactView && pad instanceof PadRigOperation) {
              for (const well of pad.wellRigOperations) {
                well.setY({ start: 0, end: 0 });
              }
            }
          }
        }

        continue;
      }

      const rigY = this.calculateRigRowPositionWithoutParent(dataViewType, shownWellAttributesNumber, previousRig?.y);
      rig.setY(rigY);

      if (rig instanceof MainComparingChartRig) {
        const comparingRigY = this.calculateRigRowPositionWithoutParent(dataViewType, shownWellAttributesNumber, rigY);
        rig.comparingPair.setY(comparingRigY);

        const comparingRigPads = rig.comparingPair.items;

        if (comparingRigPads) {
          for (const pad of comparingRigPads) {
            const padY = this.calculatePadPosition(comparingRigY, dataViewType, false);

            pad.setY(padY);

            if (!isCompactView && pad instanceof PadRigOperation) {
              for (const well of pad.wellRigOperations) {
                const wellY = this.calculateWellPosition(pad.y, dataViewType, false);

                well.setY(wellY);

                setElementToFrame(well, { x: well.x || undefined, y: wellY });
              }
            }

            setElementToFrame(pad, { x: pad.x, y: padY });
          }
        }

        setElementToFrame(rig.comparingPair, { y: comparingRigY });

        previousRig = rig.comparingPair;
      } else {
        previousRig = rig;
      }

      setElementToFrame(rig, { y: rigY });

      const pads = rig.items;

      if (pads) {
        for (const pad of pads) {
          const padY = this.calculatePadPosition(rigY, dataViewType, false);

          pad.setY(padY);

          if (!isCompactView && pad instanceof PadRigOperation) {
            for (const well of pad.wellRigOperations) {
              const wellY = this.calculateWellPosition(pad.y, dataViewType, false);

              well.setY(wellY);

              setElementToFrame(well, { x: well.x || undefined, y: wellY });
            }
          }

          setElementToFrame(pad, { x: pad.x, y: padY });
        }
      }
    }
  }

  /** Calculate only wells and pads positions. */
  calculatePadsPositions(
    rigsGroups: RigsGroup[],
    dataViewType: DataView.DataViewType,
    setElementToFrame: (element: RigsChartDataModel.ViewItem, position: ViewFrameController.Position) => void
  ): void {
    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    for (const group of rigsGroups) {
      const { items } = group;
      const isCollapsed = group.isCollapsed;

      if (items) {
        for (let rigIndex = 0; rigIndex < items.length; rigIndex++) {
          const rig = items[rigIndex];
          const rigY = rig?.y;
          const pads = rig?.items;

          if (pads && rigY) {
            for (const pad of pads) {
              const padY = this.calculatePadPosition(rigY, dataViewType, isCollapsed);

              pad.setY(padY);

              if (!isCompactView && pad instanceof PadRigOperation) {
                for (const well of pad.wellRigOperations) {
                  const wellY = this.calculateWellPosition(pad.y, dataViewType, isCollapsed);

                  well.setY(wellY);

                  setElementToFrame(well, { x: well.x || undefined, y: wellY });
                }
              }

              setElementToFrame(pad, { x: pad.x, y: padY });
            }
          }
        }
      }
    }
  }

  /** Calculate only wells and pads positions on comparing chart. */
  calculateComparingPadsPositions(rigs: ChartRig[], dataViewType: DataView.DataViewType): void {
    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    for (let rigIndex = 0; rigIndex < rigs.length; rigIndex++) {
      const rig = rigs[rigIndex];
      const rigY = rig?.y;
      const pads = rig?.items;

      if (pads && rigY) {
        for (const pad of pads) {
          pad.setY(this.calculatePadPosition(rigY, dataViewType, false));

          if (!isCompactView && pad instanceof PadRigOperation) {
            for (const well of pad.wellRigOperations) {
              well.setY(this.calculateWellPosition(pad.y, dataViewType, false));
            }
          }
        }
      }

      if (rig instanceof MainComparingChartRig) {
        const comparingRigY = rig.comparingPair.y;
        const comparingRigPads = rig.comparingPair.items;

        if (comparingRigPads && comparingRigY) {
          for (const pad of comparingRigPads) {
            pad.setY(this.calculatePadPosition(comparingRigY, dataViewType, false));

            if (!isCompactView && pad instanceof PadRigOperation) {
              for (const well of pad.wellRigOperations) {
                well.setY(this.calculateWellPosition(pad.y, dataViewType, false));
              }
            }
          }
        }
      }
    }
  }

  calculateRigsAndGroupsPositions(
    rigsGroupsWithRigs: RigsGroup[],
    dataViewType: DataView.DataViewType,
    shownWellAttributesNumber: number,
    setElementToFrame: (element: RigsChartDataModel.ViewItem, position: ViewFrameController.Position) => void
  ): RigsGroup[] {
    let previousRigsGroup: RigsGroup | null = null;

    const nextIndex = getNextIndex();

    for (const rigsGroup of rigsGroupsWithRigs) {
      const groupId = rigsGroup.id;

      const rigsOfCurrentGroup = rigsGroupsWithRigs.find(({ id }) => id === groupId);
      const rigsGroupY = this.calculateRigsGroupPosition(
        previousRigsGroup,
        dataViewType,
        rigsGroup.isCollapsed,
        shownWellAttributesNumber
      );

      let rowsStart = rigsGroupY.end;
      let rowsEnd = rigsGroupY.end;

      let previousRig: ChartRig | null = null;

      if (rigsOfCurrentGroup?.items) {
        for (const rig of rigsOfCurrentGroup.items) {
          const rigY = this.calculateDataRowPosition(
            rigsGroupY,
            dataViewType,
            rigsGroup.isCollapsed,
            shownWellAttributesNumber,
            previousRig?.y
          );

          if (!rowsStart || rowsStart > rigY.start) {
            rowsStart = rigY.start;
          }

          if (rowsEnd < rigY.end) {
            rowsEnd = rigY.end;
          }

          rig.setY(rigY);
          rig.setIndex(nextIndex());

          setElementToFrame(rig, { y: rigY });

          previousRig = rig;
        }
      }

      setElementToFrame(rigsGroup, { y: { start: rigsGroupY.start, end: rowsEnd } });

      previousRigsGroup = rigsGroup;
    }

    this.dataVerticalRange = {
      start: 0,
      end: previousRigsGroup?.rowsEnd ?? previousRigsGroup?.y.end ?? 0,
    };

    return rigsGroupsWithRigs;
  }

  initRigsWithChanges(
    rigs: RigsChart.RawRig[],
    dataViewType: DataView.DataViewType,
    shownWellAttributesNumber: number,
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    setElementToFrame: ViewFrameController.SetElementToFrameFn<ChartRig>
  ): ChartRig[] {
    const nextIndex = getNextIndex();
    const rigsWithPosition: ChartRig[] = [];

    let previousRig: ChartRig | null = null;

    for (let rigIndex = 0; rigIndex < rigs.length; rigIndex++) {
      const rig: RigsChart.RawRig = rigs[rigIndex];
      const idFieldName = addCommonPrefix('Rig.id');
      const nameFieldName = addCommonPrefix('Rig.name');
      const rigId = rig[idFieldName];
      const nameFieldValue = rig[nameFieldName];

      assert(idFieldName in rig && typeof rigId == 'number', `Invalid id in element named ${nameFieldValue}`);

      const rigY = this.calculateRigRowPositionWithoutParent(dataViewType, shownWellAttributesNumber, previousRig?.y);
      const comparingRigY = this.calculateRigRowPositionWithoutParent(dataViewType, shownWellAttributesNumber, rigY);

      const comparingRigWithPosition = new ComparingChartRig(rig, rigId);
      comparingRigWithPosition.setY(comparingRigY);

      setElementToFrame(comparingRigWithPosition, { y: comparingRigY });

      const rigWithPosition = new MainComparingChartRig(rig, rigId, comparingRigWithPosition);
      rigWithPosition.setY(rigY);
      rigWithPosition.setIndex(nextIndex());
      rigsWithPosition.push(rigWithPosition);

      setElementToFrame(rigWithPosition, { y: rigY });

      previousRig = comparingRigWithPosition;
    }

    return rigsWithPosition;
  }
}

export namespace RigsDataPositionsCalculator {
  export const DEFAULT_IS_COLLAPSED = true;

  export const DEFAULT_ELEMENT_SIZES_GETTERS: RigsChartDataModel.IRigsChartElementSizesGetters = {
    groupHeader: () => 28,
    groupMargin: () => 2,
    rowHeaderCompact: (count = 1) => 40 * count,
    rowHeaderMinHeight: () => 76,
    cardGroupHeader: () => 20,
    cardGroupMargin: () => 4,
    cardGroupPadding: () => 2,
    card: (attributesNumber: number) => 22 + 18 * attributesNumber,
    cardMargin: () => 4,
  };

  export const EDITING_ELEMENT_SIZES_GETTERS: RigsChartDataModel.IRigsChartElementSizesGetters = {
    groupHeader: () => 36,
    groupMargin: () => 2,
    rowHeaderCompact: (count = 1) => 40 * count,
    rowHeaderMinHeight: () => 76,
    cardGroupHeader: () => 20,
    cardGroupMargin: () => 4,
    cardGroupPadding: () => 2,
    card: (attributesNumber: number) => 22 + 18 * attributesNumber,
    cardMargin: () => 4,
  };
}
