import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { HistorySidebar } from 'src/features/history-sidebar';
import { WellForm } from 'src/features/well-form';
import { GeoTasksList } from 'src/features/well-form/well-form-geo-tasks-list/well-form-geo-tasks-list';
import { WellFormSubheader } from 'src/features/well-form/well-form-subheader';
import { WellInfoSidebar } from 'src/features/well-info-sidebar';
import { BlankFieldSidebar } from 'src/features/wells-list/blank-field-sidebar';
import { EditBar } from 'src/features/wells-list/edit-bar';
import { ViewSettingsSidebar } from 'src/features/wells-list/view-settings-sidebar';
import { WellsControlBar } from 'src/features/wells-list/wells-control-bar';
import { WellsTable } from 'src/features/wells-list/wells-table';
import { PAGE_MODE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { useElementSize } from 'src/shared/hooks/use-element-size';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import styles from './wells-page.module.scss';

export const WellsPage: React.FC = observer(function WellsPage() {
  const rootStore = useStore();

  const [store] = useState(() => new WellsPageStore(rootStore));

  const { wellFormManager, setAvailableSpaceHeight } = store;

  const { ref, height } = useElementSize<HTMLDivElement>();

  function onCloseHistorySidebar(): void {
    store.setIsHistorySidebarOpened(false);
  }

  const pageEffect = store.pageEffect;

  useEffect(() => {
    if (hasValue(height)) {
      setAvailableSpaceHeight(height);
    }
  }, [height, setAvailableSpaceHeight]);

  useEffect(() => {
    wellFormManager.init();

    wellFormManager.onCloseForm = store.onFormClose;
  }, [wellFormManager, store]);

  useEffect(pageEffect, [pageEffect]);

  return (
    <>
      <div className={clsx(styles.mainContentWrapper, wellFormManager.isFormOpen && styles.mainContentWrapper_hidden)}>
        <WellsControlBar pageStore={store} wellFormManager={wellFormManager} />

        {store.mode === PAGE_MODE.edit && (
          <EditBar pageStore={store} makeWellIsPlanned={wellFormManager.makeWellIsPlanned} />
        )}

        <div
          className={clsx(styles.tableContainer, store.mode === PAGE_MODE.compare && styles.tableContainerComparison)}
          ref={ref}
        >
          <WellsTable pageStore={store} wellFormManager={wellFormManager} />
        </div>
      </div>
      <ViewSettingsSidebar pageStore={store} />
      <BlankFieldSidebar store={store} />
      {store.selectedWellId && (
        <WellInfoSidebar
          wellId={store.selectedWellId}
          isOpen={store.isWellInfoSidebarOpen}
          onClose={store.closeWellInfoSidebar}
          onEdit={wellFormManager.onWellEdit}
          disableEditing={!store.isCanEditing}
        />
      )}

      {wellFormManager.isFormOpen && (
        <WellForm
          isFormFetching={wellFormManager.isSaveUpdateLoading}
          isFormLoading={wellFormManager.isFormLoading}
          wellFormManager={wellFormManager}
          onSplitApproach={wellFormManager.splitApproach}
          onAddNewApproach={wellFormManager.addNewApproach}
          subheader={
            <WellFormSubheader
              title={wellFormManager.currentWellName}
              formStore={wellFormManager.currentFormStore}
              additionalHeaderComponent={<GeoTasksList wellFormManager={wellFormManager} />}
              onSave={wellFormManager.saveWell}
              onCancel={wellFormManager.cancelForm}
            />
          }
        />
      )}

      <HistorySidebar isOpened={store.isHistorySidebarOpened} onClose={onCloseHistorySidebar} />
    </>
  );
});
