import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { debounce } from 'src/shared/utils/debounce';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';

import { Range } from '../../../layers/model';
import {
  ChartWell,
  LoadingRigOperations,
  WellRigOperation,
  WellsGroup,
} from '../../../presets/drilling-wells-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';
import { StorageKeyManagerWithParser } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';
import { ViewFrameController } from '../../../shared/view-frame-controller';
import { ViewHelper } from '../../../shared/view-helper';

import { WellsChartDataModel } from './wells-chart-data-model';
import { IWellsChartDataStorage } from './wells-chart-data.types';
import { WellsDataPositionsCalculator } from './wells-data-positions-calculator';

export class WellsChartDataStorage implements IWellsChartDataStorage {
  private readonly keyManager: StorageKeyManagerWithParser<Range<number>, string>;
  private readonly viewFrameController = new ViewFrameController<WellsChartDataModel.ViewItemContent>(
    WellsChartDataStorage.FRAME_HEIGHT
  );
  private readonly sourceRefs: WellsChartDataStorage.SourceRefs;

  /*
   * Format:
   *
   * {
   *   wellsGroupId: {
   *     wellId: {
   *       range: RigOperation[]
   *     }
   *   }
   * }
   *
   * Range - the date range in which rig operations fall. Format: '${startDate}-${endDate}'.
   * */
  @observable private rigOperations: Map<number, Map<number, Map<string, WellRigOperation[]>>>;
  /*
   * Format:
   *
   * {
   *   wellsGroupId: {
   *     wellId: {
   *       range: EmptyRigOperation
   *     }
   *   }
   * }
   * */
  @observable private emptyRigOperationStages: Map<number, Map<number, Map<string, LoadingRigOperations>>>;
  @observable private dataView: DataView;
  @observable private horizontalDataRange: Range<number> | null = null;
  // Can be replaced with full chart view object if you need more chart view data here.
  @observable private shownStageAttributesNumber: number = 0;
  @observable private positionsCalculator: WellsDataPositionsCalculator;

  @observable verticalDataRange: Range<number> | null = null;
  @observable wellsGroups?: WellsGroup[];

  constructor(
    keyManager: StorageKeyManagerWithParser<Range<number>, string>,
    dataView: DataView,
    positionsCalculator: WellsDataPositionsCalculator,
    sourceRefs: WellsChartDataStorage.SourceRefs
  ) {
    this.keyManager = keyManager;
    this.dataView = dataView;
    this.sourceRefs = sourceRefs;
    this.positionsCalculator = positionsCalculator;
    this.rigOperations = observable.map();
    this.emptyRigOperationStages = observable.map();

    makeObservable(this);
  }

  @action.bound
  private setRigOperationStages(
    groupId: number,
    wellId: number,
    horizontalViewRange: Range<number>,
    rigOperations: WellRigOperation[]
  ): void {
    const rigOperationsInGroup = this.rigOperations.get(groupId);
    const rigOperationsRangeKey = this.keyManager.getKey(horizontalViewRange);

    if (rigOperationsInGroup) {
      const rigOperationsInWell = rigOperationsInGroup.get(wellId);

      if (rigOperationsInWell) {
        const rigOperationsInView = rigOperationsInWell.get(rigOperationsRangeKey);

        if (rigOperationsInView) {
          const existingRigOperationsIds = rigOperationsInView.map(({ id }) => id);
          const newRigOperations = rigOperations.filter(({ id }) =>
            existingRigOperationsIds.find((existingRigOperationId) => existingRigOperationId !== id)
          );

          // Set to Ranges Map: Map<'${startDate}-${endDate}', rigOperations[]>
          rigOperationsInWell.set(rigOperationsRangeKey, [...rigOperationsInView, ...newRigOperations]);
        } else {
          // Set to Ranges Map: Map<'${startDate}-${endDate}', rigOperations[]>
          rigOperationsInWell.set(rigOperationsRangeKey, rigOperations);
        }
      } else {
        // Set to Wells Map: Map<wellId, Ranges Map>
        rigOperationsInGroup.set(wellId, observable.map(new Map([[rigOperationsRangeKey, rigOperations]])));
      }
    } else {
      // Ranges Map: Map<'${startDate}-${endDate}', rigOperations[]>
      const rangesMap: Map<string, WellRigOperation[]> = observable.map(
        new Map([[rigOperationsRangeKey, rigOperations]])
      );
      // Wells Map: Map<wellId, Ranges Map>
      const wellsMap: Map<number, Map<string, WellRigOperation[]>> = observable.map(new Map([[wellId, rangesMap]]));

      // Set to Groups Map: Map<wellsGroupId, Wells Map>
      this.rigOperations.set(groupId, wellsMap);
    }
  }

  @action.bound
  private setEmptyRigOperations(
    groupId: number,
    wellId: number,
    horizontalViewRange: Range<number>,
    rigOperationStage: LoadingRigOperations
  ): void {
    const stagesRangeKey = this.keyManager.getKey(horizontalViewRange);
    const stagesInGroup = this.emptyRigOperationStages.get(groupId);

    if (stagesInGroup) {
      const stagesInWell = stagesInGroup.get(wellId);

      if (stagesInWell) {
        const rigOperationsInView = stagesInWell.get(stagesRangeKey);

        if (!rigOperationsInView) {
          // Set to Ranges Map: Map<'${startDate}-${endDate}', rigOperationStage>
          stagesInWell.set(stagesRangeKey, rigOperationStage);
        }
      } else {
        // Set to Wells Map: Map<wellId, Ranges Map>
        stagesInGroup.set(wellId, observable.map(new Map([[stagesRangeKey, rigOperationStage]])));
      }
    } else {
      // Ranges Map: Map<'${startDate}-${endDate}', rigOperationStage>
      const rangesMap = observable.map(new Map([[stagesRangeKey, rigOperationStage]]));
      // Wells Map: Map<wellId, Ranges Map>
      const wellsMap = observable.map(new Map([[wellId, rangesMap]]));

      // Set to Groups Map: Map<wellsGroupId, Wells Map>
      this.emptyRigOperationStages.set(groupId, wellsMap);
    }
  }

  @action.bound
  private fillInMissingRigOperations(
    verticalViewFrame: Range<number>,
    horizontalViewFrame: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    const wellsGroups = this.wellsGroups;

    if (!wellsGroups) {
      return;
    }

    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    for (const wellsGroup of wellsGroups) {
      const isWellsInView = ViewHelper.isInView(wellsGroup.rowsY, verticalViewFrame);

      if (!wellsGroup.isCollapsed && isWellsInView) {
        for (const well of wellsGroup.items) {
          const isWellInView = ViewHelper.isInView(well.y, verticalViewFrame);

          if (isWellInView) {
            const stagesInRigOperationRanges = this.rigOperations.get(wellsGroup.id)?.get(well.id)?.keys();
            const emptyStagesInRigOperationRanges = this.emptyRigOperationStages
              .get(wellsGroup.id)
              ?.get(well.id)
              ?.keys();
            const rangesOfAllStagesInRigOperation = [
              ...(stagesInRigOperationRanges || []),
              ...(emptyStagesInRigOperationRanges || []),
            ];

            const horizontalViewFrameChunks = WellsChartDataStorage.getChunksOfDataRange(horizontalViewFrame);
            const emptyRanges: Range<number>[] = [];

            // Iterate chunks of horizontal view frame to fill in empty ranges.
            for (const horizontalFrameChunk of horizontalViewFrameChunks) {
              // Add empty range if there is no chunk of horizontal view frame that contains data or empty range object.
              if (
                !rangesOfAllStagesInRigOperation.find((range) => {
                  const { start, end } = this.keyManager.parseKey(range);
                  return horizontalFrameChunk.start >= start && horizontalFrameChunk.end <= end;
                })
              ) {
                emptyRanges.push(horizontalFrameChunk);
              }
            }

            // Reduce found empty ranges to create empty pads objects and put into the ranges.
            const emptyStages = emptyRanges.reduce(
              (rigOperationStages: LoadingRigOperations[], currentRange: Range<number>) => {
                const wellY = well.y;

                if (wellY) {
                  const wellId = well.getFieldValue(addGOplanPrefix('WellPlacement.id')) || well.id;
                  const id = currentRange.start;
                  const emptyRigOperationY = this.positionsCalculator.calculateRigOperationStagePosition(
                    wellY,
                    wellsGroup.isCollapsed,
                    isCompactView,
                    this.shownStageAttributesNumber
                  );

                  const emptyRigOperation = new LoadingRigOperations(
                    id,
                    currentRange,
                    emptyRigOperationY,
                    `${wellsGroup.id}-${wellId}-${id}`
                  );

                  rigOperationStages.push(emptyRigOperation);
                }

                return rigOperationStages;
              },
              []
            );

            emptyStages.forEach((rigOperationStage) =>
              this.setEmptyRigOperations(wellsGroup.id, well.id, rigOperationStage.x, rigOperationStage)
            );
          }
        }
      }
    }
  }

  private fillInMissingRigOperationsDebounced = debounce(
    (verticalViewFrame: Range<number>, horizontalViewFrame: Range<number>, dataViewType: DataView.DataViewType) =>
      this.fillInMissingRigOperations(verticalViewFrame, horizontalViewFrame, dataViewType),
    300
  );

  /** Calculate the boundaries of empty data to load them.
   * Takes computed empty ranges and cluster them to rectangular blocks.
   * Returns list of these bounds.
   *
   * @return Boundaries that include horizontal time ranges and vertical well IDs. */
  @computed
  get missingDataBounds(): WellsChartDataStorage.BlockBounds[] | null {
    const wellsGroups = this.wellsGroups;

    if (!wellsGroups) {
      return null;
    }

    const missingDataBounds = new Map<string, WellsChartDataStorage.BlockBounds>();

    const sortedRigOperationStages = [...this.emptyRigOperationStages].sort(
      ([firstGroupId], [secondGroupId]) =>
        wellsGroups?.findIndex(({ id }) => id === firstGroupId) -
        wellsGroups?.findIndex(({ id }) => id === secondGroupId)
    );

    for (const [groupId, emptyStageInGroup] of sortedRigOperationStages) {
      const wellsGroup = wellsGroups.find(({ id }) => id === groupId);

      if (wellsGroup) {
        const sortedWellsInGroup = [...emptyStageInGroup].sort(
          ([firstWellId], [secondWellId]) =>
            wellsGroup.items.findIndex(({ id }) => id === firstWellId) -
            wellsGroup.items.findIndex(({ id }) => id === secondWellId)
        );

        for (const [wellId, stagesInWell] of sortedWellsInGroup) {
          let wellMissingDataRanges: Range<number>[] = [];

          const well = this.wellsMap?.get(groupId)?.get(wellId);
          const { wellIdFieldName } = this.sourceRefs;
          const wellIdFieldValue = well?.getFieldValue(wellIdFieldName);

          if (wellIdFieldValue !== undefined && stagesInWell.size) {
            const wellId = Number(wellIdFieldValue);

            for (const [emptyStageRangeKey, emptyStage] of stagesInWell) {
              // Continue if empty rig operation has already been put into missing data.
              if (!emptyStage || emptyStage.isInLoading) {
                continue;
              }

              const { start, end } = this.keyManager.parseKey(emptyStageRangeKey);

              emptyStage.isInLoading = true;

              // Add new current empty range if there are no missing ranges for current well.
              if (!wellMissingDataRanges.length) {
                wellMissingDataRanges.push({ start, end });
              } else {
                // Iterate the previously added missing ranges to expand them if they are nearby.
                for (const wellHorizontalRange of wellMissingDataRanges) {
                  if (start === wellHorizontalRange.end + 1) {
                    wellHorizontalRange.end = end;
                    break;
                  } else if (end === wellHorizontalRange.start - 1) {
                    wellHorizontalRange.start = start;
                    break;
                  } else if (wellMissingDataRanges.indexOf(wellHorizontalRange) === wellMissingDataRanges.length - 1) {
                    wellMissingDataRanges.push({ start, end });
                    break;
                  }
                }
              }
            }

            // Put previously calculated missing ranges for well to missing data map.
            for (const wellHorizontalRange of wellMissingDataRanges) {
              const currentRangeKey = this.keyManager.getKey(wellHorizontalRange);
              const sameRangeInExistingRanges = missingDataBounds.get(currentRangeKey);
              const group = wellsGroups.find(
                ({ id, items }) => id === groupId && items.find(({ id }) => id === wellId)
              );

              if (group) {
                if (sameRangeInExistingRanges) {
                  sameRangeInExistingRanges.wellsIds.add(wellId);
                  sameRangeInExistingRanges.groupIds.add(group.id);
                } else {
                  missingDataBounds.set(currentRangeKey, {
                    horizontalRange: { ...wellHorizontalRange },
                    wellsIds: new Set([wellId]),
                    groupIds: new Set([group.id]),
                  });
                }
              }
            }
          }
        }
      }
    }

    if (!missingDataBounds.size) {
      return null;
    } else {
      return [...missingDataBounds.values()];
    }
  }

  @computed
  get allDataVerticalRange(): Range<number> | null {
    const wellsGroups = this.wellsGroups;

    if (!wellsGroups) {
      return null;
    }

    const firstWellsGroup = wellsGroups.at(0);
    const lastWellsGroup = wellsGroups.at(-1);

    if (!firstWellsGroup || !lastWellsGroup?.rowsY) {
      return null;
    }

    return { start: firstWellsGroup.y.start, end: lastWellsGroup.rowsY.end };
  }

  init(): VoidFunction {
    const disposeEmptyStagesCalculating = reaction(
      () => ({
        verticalViewFrame: this.viewFrameController.verticalFrame,
        horizontalViewFrame: this.viewFrameController.horizontalFrame,
      }),
      ({ verticalViewFrame, horizontalViewFrame }) => {
        if (verticalViewFrame !== undefined && horizontalViewFrame !== undefined) {
          this.fillInMissingRigOperationsDebounced(verticalViewFrame, horizontalViewFrame, this.dataView.type);
        }
      }
    );

    const disposeRigOperationsSetter = autorun(() => {
      if (this.wellsGroups) {
        for (const wellsGroup of this.wellsGroups) {
          for (const well of wellsGroup.items) {
            const rigOperationsItems: (WellRigOperation | LoadingRigOperations)[] = [];

            for (const [groupId, rigOperationsInGroup] of this.rigOperations) {
              if (wellsGroup.id === groupId) {
                for (const [wellId, rigOperationsInWell] of rigOperationsInGroup) {
                  if (wellId === well.id) {
                    for (const [, rigOperationsChunk] of rigOperationsInWell) {
                      rigOperationsItems.push(...rigOperationsChunk);
                    }
                  }
                }
              }
            }

            for (const [groupId, rigOperationsInGroup] of this.emptyRigOperationStages) {
              if (wellsGroup.id === groupId) {
                for (const [wellId, rigOperationsInWell] of rigOperationsInGroup) {
                  if (wellId === well.id) {
                    for (const [, rigOperationsChunk] of rigOperationsInWell) {
                      rigOperationsItems.push(rigOperationsChunk);
                    }
                  }
                }
              }
            }

            well.setItems(rigOperationsItems);
          }
        }

        this.normalizeData();
      }
    });

    const disposeDataPositionsCalculator = reaction(
      () => ({ positionsCalculator: this.positionsCalculator }),
      ({ positionsCalculator }) => {
        if (this.wellsGroups) {
          this.viewFrameController.clearMap();

          positionsCalculator.calculatePositions(
            this.wellsGroups,
            this.dataView.type,
            this.shownStageAttributesNumber,
            this.viewFrameController.setElementToFrame
          );
        }
      }
    );

    return () => {
      disposeEmptyStagesCalculating();
      disposeRigOperationsSetter();
      disposeDataPositionsCalculator();
    };
  }

  @computed
  get data(): WellsChartDataModel.ViewItemContent[] | null {
    const data = this.wellsGroups;

    if (!data) {
      return null;
    }

    return this.viewFrameController.elements;
  }

  @computed
  get wellsMap(): Map<number, Map<number, ChartWell>> | null {
    if (!this.wellsGroups) {
      return null;
    }

    return this.wellsGroups.reduce((wells, currentWellsGroup) => {
      const wellsMap = new Map(currentWellsGroup.items.map((item) => [item.id, item]));
      wells.set(currentWellsGroup.id, wellsMap);

      return wells;
    }, new Map<number, Map<number, ChartWell>>());
  }

  @action.bound
  normalizeData(): void {
    const data = this.wellsGroups;

    if (data) {
      this.viewFrameController.clearMap();

      this.positionsCalculator.calculatePositions(
        data,
        this.dataView.type,
        this.shownStageAttributesNumber,
        this.viewFrameController.setElementToFrame
      );

      const { verticalFrame, horizontalFrame } = this.viewFrameController;

      if (verticalFrame && horizontalFrame) {
        this.fillInMissingRigOperationsDebounced(verticalFrame, horizontalFrame, this.dataView.type);
      }
    }
  }

  @action.bound
  setShownStageAttributesNumber(attributesNumber: number): void {
    this.shownStageAttributesNumber = attributesNumber;
  }

  @action.bound
  setWellStages(
    rigOperations: Map<number, Map<number, WellRigOperation[]>>,
    horizontalViewRange: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    const rangeChunks = WellsChartDataStorage.getChunksOfDataRange(horizontalViewRange);

    for (const [groupId, rawRigOperationsInGroup] of rigOperations) {
      for (const [wellId, rigOperations] of rawRigOperationsInGroup) {
        const rangeKey = this.keyManager.getKey(horizontalViewRange);
        const newRigOperationsOnly = rigOperations.filter(
          ({ id }) =>
            !this.rigOperations
              .get(groupId)
              ?.get(wellId)
              ?.get(rangeKey)
              ?.some((existingRigOperation) => id === existingRigOperation.id)
        );

        this.setRigOperationStages(groupId, wellId, horizontalViewRange, newRigOperationsOnly);
      }
    }

    for (const [groupId, rigOperationsInGroup] of rigOperations) {
      const emptyStagesInGroup = this.emptyRigOperationStages.get(groupId);

      if (emptyStagesInGroup) {
        for (const [wellId] of rigOperationsInGroup) {
          for (const range of rangeChunks) {
            const rangeKey = this.keyManager.getKey(range);
            emptyStagesInGroup.get(wellId)?.delete(rangeKey);
          }
        }
      }
    }

    this.normalizeData();
  }

  @action.bound
  setWellsGroups(wellsGroups: WellsGroup[]): void {
    this.rigOperations = observable.map();
    this.emptyRigOperationStages = observable.map();
    this.viewFrameController.clearMap();

    this.wellsGroups = this.positionsCalculator.initWellsGroupsWithPositions(
      wellsGroups,
      this.dataView.type,
      this.shownStageAttributesNumber,
      this.viewFrameController.setElementToFrame
    );
  }

  @action.bound
  setVerticalViewRange(range: Range<number>): void {
    this.viewFrameController.setVerticalViewRange(range);
  }

  @action.bound
  setHorizontalViewRange(range: Range<number>): void {
    this.viewFrameController.setHorizontalViewRange(range);
  }

  setPositionsCalculator(calculator: WellsDataPositionsCalculator): void {
    this.positionsCalculator = calculator;
  }
}

export namespace WellsChartDataStorage {
  export const FRAME_HEIGHT = 800;

  export type BlockBounds = { horizontalRange: Range<number>; wellsIds: Set<number>; groupIds: Set<number> };

  /** Split view range into small chunks. Chunk size is one year. */
  export const getChunksOfDataRange = (horizontalViewRange: Range<number>): Range<number>[] =>
    TimeRangeHelper.getIntermediateDates(horizontalViewRange, TimeUnit.year);

  export type SourceRefs = {
    wellIdFieldName: string;
  };
}
