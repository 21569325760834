import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { FormStore } from 'src/features/well-form/entities/form.entity';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { useStore } from 'src/store';

type Props = {
  formStore: FormStore;
};

export const WellFormTabs = observer(function WellFormTabs({ formStore }: Props) {
  const { directories } = useStore();
  const { t } = useTranslation();
  const unrecTab = t('newWellForm:Errors.unrecognizedTab');

  const items = formStore.tabs.map((tab) => ({
    key: tab.formElementRefId,
    title: directories.getFieldLabel(tab.formElementRefId) || unrecTab,
    count: tab.errorsCount,
    disabled: tab.isDisabled,
    tooltipText: tab.tooltipText,
    isTooltipVisible: tab.isDisabled ? undefined : false,
  }));

  if (!items.length) return null;

  return (
    <SwitchButtonGroup
      items={items}
      defaultOptionKey={items[0].key}
      value={formStore.activeTab.formElementRefId}
      onChange={formStore.setActiveTab}
    />
  );
});
