import { TooltipPlacement } from 'antd/lib/tooltip';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { Loader } from 'src/shared/components/loader';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './button-with-tooltip.module.scss';

interface Props {
  onClick: VoidFunction;
  icon: ReactNode;
  isDisabled?: boolean;
  tooltipText?: string;
  className?: string;
  placement?: TooltipPlacement;
  isLoading?: boolean;
}

export const ButtonWithTooltip = observer(function HeaderButton({
  isDisabled = false,
  tooltipText,
  icon,
  className,
  onClick,
  placement = 'bottomRight',
  isLoading,
}: Props) {
  if (isDisabled) {
    return (
      <button className={clsx(className, styles.button)} onClick={onClick} disabled={isDisabled}>
        {isLoading ? <Loader size="small" className={styles.loader} /> : icon}
      </button>
    );
  }

  return (
    <Tooltip text={tooltipText} placement={placement}>
      <button className={clsx(className, styles.button)} onClick={onClick} disabled={isDisabled}>
        {isLoading ? <Loader size="small" className={styles.loader} /> : icon}
      </button>
    </Tooltip>
  );
});
