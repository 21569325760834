import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { ComparingPadRigOperation } from '../../../presets/comparing-drilling-rigs-chart/entities';
import { LoadingRigOperations, PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { LoadingRigOperationsWrapper } from '../../data-items-full/view/loading-rig-operations-wrapper';
import { PadRigOperationWrapper } from '../../pad-rig-operation-wrapper';

import styles from './data-items-compact.module.scss';

interface Props {
  item: ComparingPadRigOperation | PadRigOperation | LoadingRigOperations;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const ComparingRigsDataItemCompact = observer(function ComparingRigsDataItemCompact({
  item,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
}: Props) {
  if (item instanceof ComparingPadRigOperation) {
    return (
      <PadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
        cardClassName={classNames(item.isChanged && styles.cardComparing)}
        headerClassName={classNames(item.isChanged && styles.cardHeaderComparing)}
      />
    );
  }

  if (item instanceof PadRigOperation) {
    return (
      <PadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof LoadingRigOperations) {
    return (
      <LoadingRigOperationsWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  return null;
});
