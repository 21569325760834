import { agent } from 'src/api/agent';
import { UserSettingsManager } from 'src/api/user-settings';
import { throwApiError } from 'src/api/utils';

export class UserSettingsApi<TSettings> implements UserSettingsManager.Api<TSettings> {
  private readonly settingsName: string;

  constructor(settingsName: string) {
    this.settingsName = settingsName;
  }

  async get(): Promise<TSettings> {
    try {
      const { data: userSettingsObject } = await agent.get<TSettings>(`userSettings/${this.settingsName}`);

      return userSettingsObject;
    } catch (e) {
      throwApiError(e);
    }
  }

  async update(settings: Partial<TSettings>): Promise<TSettings> {
    try {
      const { data: userSettings } = await agent.patch<TSettings>(`userSettings/${this.settingsName}`, {
        ...settings,
      });

      return userSettings;
    } catch (e) {
      throwApiError(e);
    }
  }

  async create(settings: TSettings): Promise<void> {
    try {
      await agent.post<TSettings>(`userSettings/${this.settingsName}/new`, {
        ...settings,
      });
    } catch (e) {
      throwApiError(e);
    }
  }
}
