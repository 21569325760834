export class Timer {
  private timerId: number | null = null;

  startTimer(callback: VoidFunction, ms?: number): void {
    this.cancelTimer();
    this.timerId = window.setTimeout(callback, ms);
  }

  cancelTimer(): void {
    if (this.timerId === null) {
      return;
    }

    clearTimeout(this.timerId);
    this.timerId = null;
  }
}
