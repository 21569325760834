import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as UploadIcon } from 'src/shared/assets/icons/cloud.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button/button';

import styles from './comparison-mode-buttons.module.scss';

interface Props {
  pageStore: WellsPageStore;
}

export const ComparisonModeButtons = observer(function ComparisonModeButtons({ pageStore }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {pageStore.comparison.isImportOccurred ? (
        <div className={styles.importOccurredButtons}>
          <Button
            disabled={pageStore.comparison.isLoading}
            className={styles.button}
            variant="primary"
            icon={<UploadIcon />}
            onClick={pageStore.comparison.publishImportVersion}
          >
            {t('common:Buttons.save')}
          </Button>
          <Button
            disabled={pageStore.comparison.isLoading}
            className={styles.button}
            variant="danger"
            icon={<CloseIcon />}
            onClick={pageStore.comparison.cancelImport}
          >
            {t('common:Buttons.cancel')}
          </Button>
        </div>
      ) : (
        <Button
          className={styles.button}
          variant="danger"
          icon={<CloseIcon />}
          onClick={pageStore.comparison.completeComparing}
        >
          {t('common:Buttons.finishComparison')}
        </Button>
      )}
    </div>
  );
});
