import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent } from 'react';

import styles from './radio.module.scss';

type Props = {
  id: string;
  isSelected: boolean;
  onChange: () => void;
};

export const Radio = observer(function Radio({ id, isSelected, onChange }: Props) {
  const handleKeyDown = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (['NumpadEnter', 'Enter'].includes(event.code)) onChange();
  };

  return (
    <label className={clsx(styles.label, isSelected && styles.label__selected)} tabIndex={1} onKeyDown={handleKeyDown}>
      <input id={id} className={styles.input} checked={isSelected} type="radio" onChange={onChange} aria-hidden />
    </label>
  );
});
