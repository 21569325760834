import { TPlanVersion } from 'src/store/comparison/types';
import { Directories } from 'src/store/directories/directories.store';

import { agent } from '../agent';
import { TDictObject } from '../directories/types';
import { mapItems } from '../new-well/serializers/common-serializers';
import { TItemRaw } from '../new-well/types';
import { throwApiError } from '../utils';

import { DashboardTrendResponse, FiltersResponse, FiltersViewResponse } from './types';

const loadAttributesAndDirectories = async (directoriesStore: Directories, rawItems: TItemRaw[]): Promise<void> => {
  const directoriesTypes: string[] = [];

  for (const item of rawItems) {
    if ('refObjectType' in item && !!item['refObjectType']) {
      directoriesTypes.push(item['refObjectType']);
    }
  }

  await directoriesStore.loadObjects(directoriesTypes);
};

export const getFiltersFormControlsAndItsDirectories = async (directories: Directories): Promise<FiltersResponse> => {
  try {
    const { data } = await agent.get<FiltersViewResponse>('view/Dashboard.Filter');

    await loadAttributesAndDirectories(directories, data.filter);

    return {
      fields: mapItems(data.filter, directories),
      grouping: data.grouping.values,
      trendType: data.trendType.values,
    };
  } catch (e) {
    throwApiError(e);
  }
};

export const fetchDashboardTrend = async (body: Record<string, unknown>): Promise<DashboardTrendResponse> => {
  try {
    const { data } = await agent.post<DashboardTrendResponse>('dashboard/trend', body);

    return data;
  } catch (error) {
    throwApiError(error);
  }
};

export const fetchDirectoryObject = async (dirName: string, id: number): Promise<TDictObject> => {
  try {
    const res = await agent.get<TDictObject>(`object/${dirName}/${id}`);
    return res.data;
  } catch (error) {
    throwApiError(error);
  }
};

export const fetchPlanVersionsByView = async (url: string): Promise<TPlanVersion[]> => {
  try {
    const { data } = await agent.get<TPlanVersion[]>(url);

    return data;
  } catch (e) {
    throwApiError(e);
  }
};
