import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

export abstract class InfoSidebarTab {
  fieldId: string;
  protected readonly directories: Directories;
  protected readonly notifications: NotificationsStore;

  constructor(fieldId: string, directories: Directories, notifications: NotificationsStore) {
    this.fieldId = fieldId;
    this.directories = directories;
    this.notifications = notifications;
  }

  abstract setValues(data: Record<string, unknown>): Promise<void> | void;
}
