import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WellTypeIcon as WellTypeIconComponent } from 'src/features/well-type-icon';
import { ColoredCard } from 'src/shared/components/colored-card';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';

import { useDraggable } from '../../../features/editing/view/hooks/use-draggable';
import { ConflictRigOperation } from '../entities/conflict-rig-operation';

import { DraggingElement } from './dragging-element';
import { WellTitle } from './well-title';

import styles from './well.module.scss';

type Variant = 'white' | 'gray';

interface Props {
  item: ConflictRigOperation;
  withNavigateArrow?: boolean;
  variant?: Variant;
  className?: string;
}

const variantClassNames: Record<Variant, string> = {
  gray: styles.gray,
  white: styles.white,
};

export const DraggableWell = observer(function DraggableWell({
  item,
  withNavigateArrow = false,
  variant = 'gray',
  className,
}: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'ConflictSidebar' });

  const { listeners } = useDraggable({
    id: item.getKey('draggable'),
    dataItem: item,
  });

  return (
    <ColoredCard
      cardColor={item.color}
      title={<WellTitle title={item.title} subtitle={t('rigOperationIndex', { index: item.rigOperationIndex })} />}
      text={item.attributes.join(' - ')}
      icons={<WellTypeIconComponent iconName={WellTypeIcon.vertical} className={styles.wellTypeIcon} />}
      showExpandButton={withNavigateArrow}
      extra={<DraggingElement {...listeners} />}
      className={clsx(className, styles.well, variantClassNames[variant])}
    />
  );
});
