import { computed, makeObservable, observable, ObservableSet } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { Dnd } from '../editing/types';

import { SortableGhostItem } from './sortable-ghost-item';
import { SortingContextStore } from './sorting-context.store';

export class SortableGhost<TSortable, TGhostItem extends Dnd.Dragging> {
  private readonly createSortableGhostItem: SortableGhost.CreateSortableGhostItemFn<TGhostItem>;

  @observable private _ghostItems: ObservableSet<SortingContextStore.DraggingEntry<TGhostItem>>;

  readonly parent: Dnd.Sortable<TSortable>;
  readonly uniqueCreationKey: string;

  constructor(
    parent: Dnd.Sortable<TSortable>,
    ghostItems: ObservableSet<SortingContextStore.DraggingEntry<TGhostItem>>,
    createSortableGhostItem: SortableGhost.CreateSortableGhostItemFn<TGhostItem>
  ) {
    this.parent = parent;
    this._ghostItems = ghostItems;
    this.uniqueCreationKey = uuidv4();
    this.createSortableGhostItem = createSortableGhostItem;

    makeObservable(this);
  }

  @computed
  get ghostItems(): SortableGhostItem<TGhostItem>[] {
    const uniqueGhostItems = new Set<TGhostItem>();

    for (const ghostItemEntry of this._ghostItems) {
      uniqueGhostItems.add(ghostItemEntry.dataItem);
    }

    return [...uniqueGhostItems].map((item) => this.createSortableGhostItem(item));
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-ghost-${this.uniqueCreationKey}`;
    }

    return `ghost-${this.uniqueCreationKey}`;
  }
}

export namespace SortableGhost {
  export type CreateSortableGhostItemFn<TGhostItem> = (draggingEntry: TGhostItem) => SortableGhostItem<TGhostItem>;
}
