import { useSortable } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';

import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/dots.svg';

import styles from './setting-field.module.scss';

interface Props {
  label: string;
  id: string;
}

export const SettingField = observer(function SettingField({ label, id }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  return (
    <div
      className={styles.wrapper}
      ref={setNodeRef}
      style={{ transform: `translateY(${transform?.y || 0}px)`, transition }}
      {...attributes}
    >
      <MoveIcon className={styles.moveIcon} {...listeners} />
      <p className={styles.columnName}>{label}</p>
    </div>
  );
});
