import { Radio, RadioChangeEvent } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ControlBar } from 'src/features/unplanned-rig-operations-sidebar/control-bar';
import { Pad } from 'src/features/unplanned-rig-operations-sidebar/pad';
import { Search } from 'src/features/unplanned-rig-operations-sidebar/search';
import styles from 'src/features/unplanned-rig-operations-sidebar/unplanned-rig-operations-sidebar.module.scss';
import { UnplannedRigOperationsSidebarStore } from 'src/features/unplanned-rig-operations-sidebar/unplanned-rig-operations-sidebar.store';
import { ReactComponent as ApplyIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { hasValue } from 'src/shared/utils/common';
import { useDebounce } from 'src/shared/utils/use-debounce';
import { useStore } from 'src/store';

interface Props {
  isOpened: boolean;
  padName?: string;
  onClose(): void;
  onEditWell(wellId: number | string): void;
  onNewWellClick(): void;
  onWellClick(wellId: number | string): void;
}

export const UnplannedRigOperationsSidebar = observer(function UnplannedRigOperationsSidebar({
  isOpened,
  onClose,
  padName,
  onNewWellClick,
  onWellClick,
  onEditWell,
}: Props) {
  const { t } = useTranslation();

  const rootStore = useStore();

  const [store] = useState(() => new UnplannedRigOperationsSidebarStore(rootStore));
  const [searchString, setSearchString] = useState<string>();
  const debouncedSearchTerm = useDebounce(searchString, 400);

  const draftVersionId = rootStore.drafts.draftVersionId;

  function onCloseSidebar(): void {
    store.setSelectedWellKey(null);
    onClose();
  }

  function onAssign(): void {
    const selectedWellId = store.getSelectedWellId();

    if (hasValue(selectedWellId)) {
      onEditWell(selectedWellId);
    }

    onCloseSidebar();
  }

  function onChangeWell(e: RadioChangeEvent): void {
    store.setSelectedWellKey(e.target.value);
  }

  function handleOpenNewWellForm(): void {
    onNewWellClick();
    onClose();
  }

  useEffect(() => {
    if (hasValue(draftVersionId) && isOpened) {
      store.fetchUnplannedRigOperations();
    }
  }, [padName, draftVersionId, store, isOpened, debouncedSearchTerm]);

  useEffect(() => {
    store.init();
  }, [store, store.init]);

  return (
    <Radio.Group onChange={onChangeWell} value={store.selectedWellKey}>
      <Sidebar
        isOpened={isOpened}
        onClose={onCloseSidebar}
        closeOnClickOutside={true}
        title={t('drawers:addPadDrawer.padOrWell')}
        size="l"
      >
        <ControlBar onNewWellClick={handleOpenNewWellForm} />

        <div className={styles.padsContainer}>
          <Search className={styles.search} value={searchString} onSearch={setSearchString} />

          {store.isLoading && (
            <div className={styles.loaderContainer}>
              <Loader className={styles.loader} />
            </div>
          )}

          {store.pads.map((pad) => (
            <Pad key={pad.id} pad={pad} onWellClick={onWellClick} />
          ))}
        </div>

        <div className={styles.buttonsGroup}>
          <Button
            onClick={onCloseSidebar}
            variant="danger"
            iconPosition="before"
            className={styles.button}
            icon={<CloseIcon />}
          >
            {t('common:Buttons.cancel')}
          </Button>

          <Button
            onClick={onAssign}
            variant="success"
            iconPosition="before"
            className={styles.button}
            disabled={store.selectedWellKey === null}
            icon={<ApplyIcon />}
          >
            {t('common:Buttons.apply')}
          </Button>
        </div>
      </Sidebar>
    </Radio.Group>
  );
});
