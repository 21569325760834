import { UserSettingsApi } from 'src/api/user-settings';
import { NotFoundApiError } from 'src/errors';
import { AuthError } from 'src/errors/auth';
import { UserService } from 'src/store/auth/user-service';

export class UserSettingsManager<TSettings> {
  private readonly api: UserSettingsManager.Api<TSettings>;
  private readonly defaultValue: TSettings;

  constructor(
    settingName: string,
    // Default settings object for create operation.
    defaultValue: TSettings
  ) {
    this.api = new UserSettingsApi<TSettings>(settingName);
    this.defaultValue = defaultValue;
  }

  async create(value?: TSettings): Promise<void> {
    await this.api.create(value ?? this.defaultValue);
  }

  async get(): Promise<TSettings> {
    return await this.api.get();
  }

  async getOrCreate(): Promise<TSettings> {
    try {
      return await this.api.get();
    } catch (e) {
      if (e instanceof NotFoundApiError) {
        await this.api.create(this.defaultValue);

        return await this.api.get();
      }

      if (e instanceof AuthError) {
        await UserService.login();
      }

      throw e;
    }
  }

  async update(settings: Partial<TSettings>): Promise<void> {
    await this.api.update(settings);
  }
}

export namespace UserSettingsManager {
  export type GetFn<TSettings> = () => Promise<TSettings>;

  export type UpdateFn<TSettings> = (userSettings: Partial<TSettings>) => void;

  export type CreateFn<TSettings> = (userSettings: TSettings) => void;

  export interface Api<TSettings> {
    get: GetFn<TSettings>;
    update: UpdateFn<TSettings>;
    create: CreateFn<TSettings>;
  }
}
