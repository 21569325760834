import moment from 'moment';

import { Range } from '../../../layers/model';

import { TimelineDataModel } from './timeline-data-model';

export class TimelineDataApi implements TimelineDataModel.ITimelineApi {
  getDataBoundaries(): Range<number> {
    return {
      start: moment('01.01.2012', 'DD.MM.YYYY').unix(),
      end: moment('31.12.2042', 'DD.MM.YYYY').unix(),
    };
  }
}
