import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';

export class RigSidebarApi {
  async changeWellsListOrder(
    planVersionId: number,
    insert: number[],
    insertOnPlace: number | null,
    insertAfter: number | null
  ): Promise<void> {
    try {
      await agent.post('compute/rigOperation/list', {
        planVersionId,
        insertAfter,
        insert,
        insertOnPlace,
        groupBy: 'RIG',
      });
    } catch (e) {
      throwApiError(e);
    }
  }

  // It's recommended to use only 'insertAfter' or 'insertOnPlace' parameter.
  async changeWellsOrder(
    planVersionId: number,
    rigId: number,
    insertAfter: number | null,
    insert: number,
    insertOnPlace: number | null
  ): Promise<void> {
    try {
      await agent.post('compute/rigOperation', {
        planVersionId,
        rigId,
        insertAfter,
        insert,
        insertOnPlace,
        groupBy: 'RIG',
      });
    } catch (e) {
      throwApiError(e);
    }
  }
}
