import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingField } from 'src/features/planning/views/view-settings-sidebar/setting-field';
import { SortableWrapper } from 'src/features/planning/views/view-settings-sidebar/sortable-wrapper';
import { PlanPageStore } from 'src/pages/plan-page/plan-page.store';
import { Button } from 'src/shared/components/button/button';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { serializeColumnsData, deSerializeColumnsData } from './utils';
import styles from './view-settings-sidebar.module.scss';

interface Props {
  store: PlanPageStore;
}

export const ViewSettingsSidebar = observer(function ViewSettingsSidebar({ store }: Props) {
  const { t } = useTranslation();

  const [columnsGroups, setColumnsGroup] = useState(() =>
    serializeColumnsData(store.planTableView, store.viewSettingsGroups, store.directories)
  );

  useEffect(() => {
    if (store.viewSettingsSidebar) {
      setColumnsGroup(serializeColumnsData(store.planTableView, store.viewSettingsGroups, store.directories));
    }
  }, [setColumnsGroup, store, store.viewSettingsSidebar]);

  function handleDragEnd({ active, over }: DragEndEvent): void {
    if (over && active.id !== over.id) {
      const newColumnsData = columnsGroups.map((group) => {
        if (group.items.some((column) => column.id === active.id)) {
          const oldIndex = group.items.findIndex((column) => column.id === active.id);
          const newIndex = group.items.findIndex((column) => column.id === over.id);
          return {
            ...group,
            items: arrayMove(group.items, oldIndex, newIndex).sort(
              (a, b) => Number(!!b.excludeFromSettings) - Number(!!a.excludeFromSettings)
            ),
          };
        }
        return group;
      });

      setColumnsGroup(newColumnsData);
    }
  }

  function onSaveViewSettings(): void {
    store.setTableView(deSerializeColumnsData(columnsGroups));
  }

  return (
    <Sidebar
      isOpened={store.viewSettingsSidebar}
      onClose={store.closeViewSettingSidebar}
      closeOnClickOutside={true}
      title={t('common:displaySettings')}
    >
      <div className={styles.wrapper}>
        {columnsGroups.map((group, index) => (
          <Collapse className={styles.collapse} key={index}>
            <Panel key={index} header={<p className={styles.groupTittle}>{group.title}</p>}>
              <div className={styles.settingGroup}>
                <SortableWrapper items={group.items} dragEnd={handleDragEnd}>
                  {group.items
                    .filter((column) => !column.excludeFromSettings)
                    .map((column) => (
                      <SettingField label={column.label} id={column.id} key={column.id} />
                    ))}
                </SortableWrapper>
              </div>
            </Panel>
          </Collapse>
        ))}
      </div>
      <Button onClick={onSaveViewSettings} variant="primary" className={styles.saveButton}>
        {t('common:Buttons.apply')}
      </Button>
    </Sidebar>
  );
});
