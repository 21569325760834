import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { debounce } from 'src/shared/utils/debounce';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { WellTypes } from 'src/types/well-types';

import { Range } from '../../../layers/model';
import { MainComparingChartWell } from '../../../presets/comparing-drilling-wells-chart/entities';
import { ChartWell, LoadingRigOperations, WellRigOperation } from '../../../presets/drilling-wells-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';
import { StorageKeyManagerWithParser } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';
import { ViewFrameController } from '../../../shared/view-frame-controller';
import { ViewHelper } from '../../../shared/view-helper';
import { WellsDataPositionsCalculator } from '../../wells-chart/data/wells-data-positions-calculator';

import { ComparingWellsData } from './comparing-wells-data.types';

export class ComparingWellsDataStorage implements ComparingWellsData.ComparingWellsChartDataStorage {
  private readonly keyManager: StorageKeyManagerWithParser<Range<number>, string>;
  private readonly viewFrameController = new ViewFrameController<ComparingWellsData.ViewItem>(
    ComparingWellsDataStorage.FRAME_HEIGHT
  );
  private readonly sourceRefs: ComparingWellsDataStorage.SourceRefs;

  @observable private wells: ChartWell[] | null = null;
  /*
   * Format:
   *
   * {
   *   wellId: {
   *     range: { first: RigOperation[], second: RigOperation[] }
   *   }
   * }
   *
   * Range - the date range. Format: '${startDate}-${endDate}'.
   * */
  @observable private rigOperations: Map<number, Map<string, ComparingWellsData.TwoVersionsData<WellRigOperation[]>>>;
  /*
   * Format:
   *
   * {
   *   wellId: {
   *     range: EmptyRigOperation
   *   }
   * }
   * */
  @observable private emptyRigOperationStages: Map<number, Map<string, LoadingRigOperations>>;
  @observable private dataView: DataView;
  @observable private horizontalDataRange: Range<number> | null = null;
  // Can be replaced with full chart view object if you need more chart view data here.
  @observable private shownStageAttributesNumber: number = 0;
  @observable private positionsCalculator: WellsDataPositionsCalculator;

  @observable verticalDataRange: Range<number> | null = null;

  constructor(
    keyManager: StorageKeyManagerWithParser<Range<number>, string>,
    dataView: DataView,
    positionsCalculator: WellsDataPositionsCalculator,
    sourceRefs: ComparingWellsDataStorage.SourceRefs
  ) {
    this.keyManager = keyManager;
    this.dataView = dataView;
    this.sourceRefs = sourceRefs;
    this.positionsCalculator = positionsCalculator;
    this.rigOperations = observable.map();
    this.emptyRigOperationStages = observable.map();

    makeObservable(this);
  }

  @action.bound
  private setRigOperationStages(
    wellId: number,
    horizontalViewRange: Range<number>,
    rigOperations: ComparingWellsData.TwoVersionsData<WellRigOperation[]>
  ): void {
    const rigOperationsRangeKey = this.keyManager.getKey(horizontalViewRange);
    const rigOperationsInWell = this.rigOperations.get(wellId);

    if (rigOperationsInWell) {
      const rigOperationsInView = rigOperationsInWell.get(rigOperationsRangeKey);

      if (rigOperationsInView) {
        const firstExistingRigOperationsIds = rigOperationsInView.first.map(({ id }) => id);
        const firstNewRigOperations = rigOperations.first.filter(({ id }) =>
          firstExistingRigOperationsIds.find((existingRigOperationId) => existingRigOperationId !== id)
        );
        const secondExistingRigOperationsIds = rigOperationsInView.second?.map(({ id }) => id);
        const secondNewRigOperations = rigOperations.second?.filter(({ id }) =>
          secondExistingRigOperationsIds?.find((existingRigOperationId) => existingRigOperationId !== id)
        );

        const newRigOperationsInWell = {
          first: [...rigOperationsInView.first, ...firstNewRigOperations],
          second: [...(rigOperationsInView?.second || []), ...(secondNewRigOperations || [])],
        };

        // Set to Ranges Map: Map<'${startDate}-${endDate}', { first: rigOperations[], second: rigOperations[] }>
        rigOperationsInWell.set(rigOperationsRangeKey, newRigOperationsInWell);
      } else {
        // Set to Ranges Map: Map<'${startDate}-${endDate}', { first: rigOperations[], second: rigOperations[] }>
        rigOperationsInWell.set(rigOperationsRangeKey, rigOperations);
      }
    } else {
      this.rigOperations.set(wellId, observable.map(new Map([[rigOperationsRangeKey, rigOperations]])));
    }
  }

  @action.bound
  private setEmptyRigOperations(
    wellId: number,
    horizontalViewRange: Range<number>,
    rigOperationStage: LoadingRigOperations
  ): void {
    const stagesRangeKey = this.keyManager.getKey(horizontalViewRange);
    const stagesInWell = this.emptyRigOperationStages.get(wellId);

    if (stagesInWell) {
      const rigOperationsInView = stagesInWell.get(stagesRangeKey);

      if (!rigOperationsInView) {
        // Set to Ranges Map: Map<'${startDate}-${endDate}', rigOperationStage>
        stagesInWell.set(stagesRangeKey, rigOperationStage);
      }
    } else {
      this.emptyRigOperationStages.set(wellId, observable.map(new Map([[stagesRangeKey, rigOperationStage]])));
    }
  }

  @action.bound
  private fillInMissingRigOperations(
    wells: ChartWell[],
    verticalViewFrame: Range<number>,
    horizontalViewFrame: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    const { rigOperations, emptyRigOperationStages } = this;

    if (!wells) {
      return;
    }

    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    for (const well of wells) {
      const isWellInView = ViewHelper.isInView(well.y, verticalViewFrame);

      if (isWellInView) {
        const rangesOfROInWell = rigOperations.get(well.id)?.keys();
        const emptyRangesOfROInWell = emptyRigOperationStages.get(well.id)?.keys();
        const allRangesOfROInWell = [...(rangesOfROInWell || []), ...(emptyRangesOfROInWell || [])];

        const horizontalViewFrameChunks = ComparingWellsDataStorage.getChunksOfDataRange(horizontalViewFrame);
        const emptyRanges: Range<number>[] = [];

        // Iterate chunks of horizontal view frame to fill in empty ranges.
        for (const horizontalFrameChunk of horizontalViewFrameChunks) {
          // Add empty range if there is no chunk of horizontal view frame that contains data or empty range object.
          if (
            !allRangesOfROInWell.find((range) => {
              const { start, end } = this.keyManager.parseKey(range);
              return horizontalFrameChunk.start >= start && horizontalFrameChunk.end <= end;
            })
          ) {
            emptyRanges.push(horizontalFrameChunk);
          }
        }

        // Reduce found empty ranges to create empty pads objects and put into the ranges.
        const emptyStages = emptyRanges.reduce(
          (rigOperationStages: LoadingRigOperations[], currentRange: Range<number>) => {
            const wellY = well.y;

            if (wellY) {
              const wellId = well.getFieldValue(addGOplanPrefix('WellPlacement.id')) || well.id;
              const id = currentRange.start;
              const emptyRigOperationY = this.positionsCalculator.calculateRigOperationStagePosition(
                wellY,
                false,
                isCompactView,
                this.shownStageAttributesNumber
              );

              const emptyRigOperation = new LoadingRigOperations(
                id,
                currentRange,
                emptyRigOperationY,
                `${wellId}-${id}`
              );

              rigOperationStages.push(emptyRigOperation);
            }

            return rigOperationStages;
          },
          []
        );

        emptyStages.forEach((rigOperationStage) =>
          this.setEmptyRigOperations(well.id, rigOperationStage.x, rigOperationStage)
        );
      }
    }
  }

  private fillInMissingRigOperationsDebounced = debounce(
    (
      data: ChartWell[],
      verticalViewFrame: Range<number>,
      horizontalViewFrame: Range<number>,
      dataViewType: DataView.DataViewType
    ) => this.fillInMissingRigOperations(data, verticalViewFrame, horizontalViewFrame, dataViewType),
    300
  );

  /**
   * @returns Format: { wellId: WellsChartWell }
   */
  @computed
  get wellsMap(): Map<number, ChartWell> | null {
    if (!this.wells) {
      return null;
    }

    return new Map<number, ChartWell>(this.wells.map((well) => [well.id, well]));
  }

  @computed
  get allDataVerticalRange(): Range<number> | null {
    const { wells } = this;

    if (!wells) {
      return null;
    }

    const firstWell = wells.at(0);
    const lastWell = wells.at(-1);

    if (!firstWell?.y || !lastWell?.y) {
      return null;
    }

    return { start: firstWell.y.start, end: lastWell.y.end };
  }

  @computed
  get data(): ComparingWellsData.ViewItem[] | null {
    const { horizontalFrame, verticalFrame, elements } = this.viewFrameController;

    if (!verticalFrame || !horizontalFrame || !elements) {
      return null;
    }

    return elements;
  }

  @computed
  get missingDataBounds(): ComparingWellsData.BlockBounds[] | null {
    const { wells, emptyRigOperationStages } = this;

    if (!wells || !emptyRigOperationStages) {
      return null;
    }

    const missingDataBounds = new Map<string, ComparingWellsData.BlockBounds>();

    const sortedEmptyStages = [...emptyRigOperationStages].sort(
      ([firstWellId], [secondWellId]) =>
        wells.findIndex(({ id }) => id === firstWellId) - wells.findIndex(({ id }) => id === secondWellId)
    );

    for (const [wellId, stagesInWell] of sortedEmptyStages) {
      let wellMissingDataRanges: Range<number>[] = [];

      const well = this.wellsMap?.get(wellId);
      const wellIdFieldValue = well?.getFieldValue(this.sourceRefs.wellIdFieldName);

      if (wellIdFieldValue !== undefined && stagesInWell.size) {
        const wellId = Number(wellIdFieldValue);

        for (const [emptyStageRangeKey, emptyStage] of stagesInWell) {
          // Continue if empty rig operation has already been put into missing data.
          if (!emptyStage || emptyStage.isInLoading) {
            continue;
          }

          const { start, end } = this.keyManager.parseKey(emptyStageRangeKey);

          // Add new current empty range if there are no missing ranges for current well.
          if (!wellMissingDataRanges.length) {
            wellMissingDataRanges.push({ start, end });
            continue;
          } else {
            // Iterate the previously added missing ranges to expand them if they are nearby.
            for (const wellHorizontalRange of wellMissingDataRanges) {
              if (start === wellHorizontalRange.end + 1) {
                wellHorizontalRange.end = end;
                break;
              } else if (end === wellHorizontalRange.start - 1) {
                wellHorizontalRange.start = start;
                break;
              } else if (wellMissingDataRanges.indexOf(wellHorizontalRange) === wellMissingDataRanges.length - 1) {
                wellMissingDataRanges.push({ start, end });
                break;
              }
            }
          }

          emptyStage.isInLoading = true;
        }

        // Put previously calculated missing ranges for well to missing data map.
        for (const wellHorizontalRange of wellMissingDataRanges) {
          const currentRangeKey = this.keyManager.getKey(wellHorizontalRange);
          const sameRangeInExistingRanges = missingDataBounds.get(currentRangeKey);

          if (sameRangeInExistingRanges) {
            sameRangeInExistingRanges.wellsIds.add(wellId);
          } else {
            missingDataBounds.set(currentRangeKey, {
              horizontalRange: { ...wellHorizontalRange },
              wellsIds: new Set([wellId]),
            });
          }
        }
      }
    }

    if (!missingDataBounds.size) {
      return null;
    } else {
      return [...missingDataBounds.values()];
    }
  }

  @action.bound
  init() {
    const disposeEmptyStagesCalculating = reaction(
      () => ({
        data: this.wells,
        verticalViewFrame: this.viewFrameController.verticalFrame,
        horizontalViewFrame: this.viewFrameController.horizontalFrame,
      }),
      ({ data, verticalViewFrame, horizontalViewFrame }) => {
        if (data?.length && verticalViewFrame && horizontalViewFrame) {
          this.fillInMissingRigOperationsDebounced(data, verticalViewFrame, horizontalViewFrame, this.dataView.type);
        }
      }
    );

    const disposeRigOperationsSetter = autorun(() => {
      const { wells, rigOperations, emptyRigOperationStages } = this;

      if (wells) {
        for (const well of wells) {
          const rigOperationsItemsFirst: (WellRigOperation | LoadingRigOperations)[] = [];
          const rigOperationsItemsSecond: (WellRigOperation | LoadingRigOperations)[] = [];

          for (const [wellId, rigOperationsInWell] of rigOperations) {
            if (wellId === well.id) {
              for (const [, rigOperationsChunk] of rigOperationsInWell) {
                rigOperationsItemsFirst.push(...rigOperationsChunk.first);

                if (rigOperationsChunk.second) {
                  rigOperationsItemsSecond.push(...rigOperationsChunk.second);
                }
              }
            }
          }

          for (const [wellId, rigOperationsInWell] of emptyRigOperationStages) {
            if (wellId === well.id) {
              for (const [, rigOperationsChunk] of rigOperationsInWell) {
                rigOperationsItemsFirst.push(rigOperationsChunk);
              }
            }
          }

          well.setItems(rigOperationsItemsFirst);

          if (well instanceof MainComparingChartWell) {
            well.comparingPair.setItems(rigOperationsItemsSecond);
          }
        }

        this.normalizeData();
      }
    });

    return () => {
      disposeEmptyStagesCalculating();
      disposeRigOperationsSetter();
    };
  }

  @action.bound
  normalizeData(): void {
    const data = this.wells;

    if (data) {
      this.viewFrameController.clearMap();
      this.positionsCalculator.calculatePositionsWithComparing(
        data,
        this.dataView.type,
        this.shownStageAttributesNumber,
        this.viewFrameController.setElementToFrame
      );
    }
  }

  @action.bound
  setShownStageAttributesNumber(attributesNumber: number): void {
    this.shownStageAttributesNumber = attributesNumber;
  }

  @action.bound
  setStages(
    wells: Map<number, ComparingWellsData.TwoVersionsData<WellRigOperation[]>>,
    horizontalViewRange: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    const rangeChunks = ComparingWellsDataStorage.getChunksOfDataRange(horizontalViewRange);

    for (const [wellId, rigOperations] of wells) {
      const rangeKey = this.keyManager.getKey(horizontalViewRange);
      const newFirstRigOperationsOnly = rigOperations.first.filter(
        ({ id }) =>
          !this.rigOperations
            .get(wellId)
            ?.get(rangeKey)
            ?.first?.some((existingRigOperation) => id === existingRigOperation.id)
      );
      const newSecondRigOperationsOnly = rigOperations.second?.filter(
        ({ id }) =>
          !this.rigOperations
            .get(wellId)
            ?.get(rangeKey)
            ?.second?.some((existingRigOperation) => id === existingRigOperation.id)
      );

      this.setRigOperationStages(wellId, horizontalViewRange, {
        first: newFirstRigOperationsOnly,
        second: newSecondRigOperationsOnly,
      });
    }

    for (const [wellId] of wells) {
      for (const range of rangeChunks) {
        const rangeKey = this.keyManager.getKey(range);
        this.emptyRigOperationStages.get(wellId)?.delete(rangeKey);
      }
    }

    this.normalizeData();
  }

  @action.bound
  setWells(wells: WellTypes.RawWell[], firstPlanVersionId: number, secondPlanVersionId: number): void {
    this.wells = this.positionsCalculator.initComparingWellsGroups(
      wells,
      firstPlanVersionId,
      secondPlanVersionId,
      this.dataView.type,
      this.shownStageAttributesNumber,
      this.viewFrameController.setElementToFrame,
      this.sourceRefs
    );
  }

  @action.bound
  setVerticalViewRange(range: Range<number>): void {
    this.viewFrameController.setVerticalViewRange(range);
  }

  @action.bound
  setHorizontalViewRange(range: Range<number>): void {
    this.viewFrameController.setHorizontalViewRange(range);
  }
}

export namespace ComparingWellsDataStorage {
  export const FRAME_HEIGHT = 800;

  /** Split view range into small chunks. Chunk size is one year. */
  export const getChunksOfDataRange = (horizontalViewRange: Range<number>): Range<number>[] =>
    TimeRangeHelper.getIntermediateDates(horizontalViewRange, TimeUnit.year);

  export type SourceRefs = {
    wellIdFieldName: string;
  };
}
