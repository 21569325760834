import { action, comparer, computed, makeObservable, observable } from 'mobx';

import { IChartDataModel } from '../../../layers/model';
import { IPresenter } from '../../../layers/presenter';
import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport, normalizeScrollOffset } from '../../../shared/viewport/viewport-calculator';

export class DataHeadersPresenter<TData> implements IPresenter<TData, number> {
  private readonly onGroupCollapsedStateChange?: DataHeadersPresenter.OnGroupCollapsedStateChange;

  protected readonly viewport: Viewport;
  protected readonly dataModel: IChartDataModel<TData>;

  @observable dataViewHeight?: number;

  constructor(viewport: Viewport, dataModel: IChartDataModel<TData>, handlers?: DataHeadersPresenter.Handlers) {
    this.viewport = viewport;
    this.dataModel = dataModel;
    this.onGroupCollapsedStateChange = handlers?.onGroupCollapsedStateChange;

    makeObservable(this);
  }

  @computed({ equals: comparer.shallow })
  get data(): TData {
    return this.dataModel.data;
  }

  onScroll = (offset: number): void => {
    const { movedStart, movedEnd } = moveViewport(this.viewport, normalizeScrollOffset(offset));

    this.viewport.setRange(movedStart, movedEnd);
  };

  @action.bound
  onHeightChange(height: number): void {
    const start = this.viewport.start || 0;

    this.dataViewHeight = height;
    this.viewport.setMaxLength(height);
    this.viewport.setRange(start, start + height);
  }

  @action.bound
  onGroupCollapseToggle = (item: { isCollapsed: boolean; setIsCollapsed: (isCollapsed: boolean) => void }): void => {
    item.setIsCollapsed(!item.isCollapsed);
    this.dataModel.recalculatePositions();

    this.onGroupCollapsedStateChange?.();
  };
}

export namespace DataHeadersPresenter {
  export type OnGroupCollapsedStateChange = VoidFunction;

  export type Handlers = Partial<{
    onGroupCollapsedStateChange: OnGroupCollapsedStateChange;
  }>;
}
