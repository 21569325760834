import { RootStore } from 'src/store';

import { FormPlugin } from '../abstract-form-plugin.entity';

import { PassingValueFormulaPlugin } from './passing-value-formula.plugin';
import { SpeedPerDayFormulaPlugin } from './speed-per-day-formula.plugin';

export function getPluginsWithFormulas(rootStore: RootStore): FormPlugin[] {
  return [new PassingValueFormulaPlugin(rootStore), new SpeedPerDayFormulaPlugin(rootStore)];
}
