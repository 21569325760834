import { observer } from 'mobx-react-lite';
import React from 'react';

import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';

import styles from './add-well-button.module.scss';

interface Props {
  onClick: VoidFunction;
}

export const AddWellButton = observer(function AddWellButton({ onClick }: Props) {
  return (
    <button className={styles.button} onClick={onClick}>
      <PlusIcon className={styles.icon} />
    </button>
  );
});
