import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { IndicatorsView } from 'src/api/chart/drilling-plan-charts-api';
import { Directories } from 'src/store/directories/directories.store';

import { EditingIndicator } from './editing-indicator';

export class IndicatorSettings {
  @observable isShown: boolean;

  readonly name: string;
  readonly label: string;
  readonly shortLabel: string;

  constructor(name: string, label: string, shortLabel: string, isShown: boolean) {
    this.name = name;
    this.label = label;
    this.shortLabel = shortLabel;
    this.isShown = isShown;

    makeObservable(this);
  }

  @action.bound
  setIsShown(value: boolean): void {
    this.isShown = value;
  }
}

export class IndicatorsSettings {
  private readonly settings: IndicatorsView.Settings;
  private readonly directories: Directories;

  @observable private settingsValues: IndicatorsView.SettingsValues;

  constructor(
    settings: IndicatorsView.Settings,
    settingsValues: IndicatorsView.SettingsValues,
    directories: Directories
  ) {
    this.settings = settings;
    this.settingsValues = settingsValues;
    this.directories = directories;

    makeObservable(this);
  }

  @computed
  get items(): IndicatorSettings[] {
    if (!this.settingsValues?.table?.items) {
      return [];
    }

    const initializedItems: IndicatorSettings[] = [];
    const settingsWithoutValues = new Set(this.settings.table.items.map(({ fieldId }) => fieldId));

    // First add settings that have a value in values object.
    for (let settingsValue of this.settingsValues.table.items) {
      const currentSettingsView = this.settings.table.items.find(({ fieldId }) => fieldId === settingsValue.fieldId);

      if (currentSettingsView) {
        const settingsLabel = this.directories.getFieldLabel(currentSettingsView.settingsFieldId) || '';
        const label = this.directories.getFieldLabel(currentSettingsView.fieldId) || '';

        initializedItems.push(
          new IndicatorSettings(currentSettingsView.fieldId, label, settingsLabel, !!settingsValue?.isShown)
        );
        settingsWithoutValues.delete(settingsValue.fieldId);
      }
    }

    // Then add settings that are not in values object (received from view object).
    for (const settingsItemName of settingsWithoutValues) {
      const settingsItemView = this.settings.table.items.find(({ fieldId }) => fieldId === settingsItemName);

      if (settingsItemView) {
        const settingsLabel = this.directories.getFieldLabel(settingsItemView.settingsFieldId) || '';
        const label = this.directories.getFieldLabel(settingsItemView.fieldId) || '';

        initializedItems.push(new IndicatorSettings(settingsItemView.fieldId, label, settingsLabel, true));
      }
    }

    return initializedItems;
  }

  @computed
  get shownItemsWithLabels(): IndicatorsView.TableSettingsItemWithLabels[] {
    if (!this.settingsValues?.table?.items) {
      return [];
    }

    return this.settingsValues.table.items.reduce(
      (shownSettings: IndicatorsView.TableSettingsItemWithLabels[], currentSettingsItem) => {
        if (currentSettingsItem.isShown) {
          const settingsItem = this.settings.table.items.find(({ fieldId }) => fieldId === currentSettingsItem.fieldId);

          if (settingsItem) {
            const settingsItemWithLabel = {
              ...toJS(settingsItem),
              label: this.directories.getFieldLabel(settingsItem.fieldId) || '',
              settingsLabel: this.directories.getFieldLabel(settingsItem.settingsFieldId) || '',
            };

            shownSettings.push(settingsItemWithLabel);
          }
        }

        return shownSettings;
      },
      []
    );
  }

  @action.bound
  setValues(settingsValues: IndicatorsView.SettingsValues): void {
    this.settingsValues = settingsValues;
  }

  @action.bound
  copyIndicators(): EditingIndicator[] {
    return this.items.map((indicator) => new EditingIndicator(indicator));
  }
}
