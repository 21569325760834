import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';

interface Props {
  size?: 'large' | 'default' | 'small';
  className?: string;
}

export const Loader = observer(function Loader({ size = 'large', className }: Props) {
  return <Spin size={size} className={className} />;
});
