import { makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import moment from 'moment';

import { Range } from '../../../layers/model';

import { TimelineDataModel } from './timeline-data-model';

export class TimelineYear implements TimelineDataModel.ITimelineItem {
  @observable x: Range<number>;

  constructor(x: Range<number>) {
    this.x = x;
    makeObservable(this);
  }

  toString = computedFn((): string => {
    return moment.unix(this.x.start).format('YYYY');
  });
}
