import { Tab } from 'src/shared/entities/abstract-control-entities';
import { RequireAtLeastOne } from 'src/shared/utils/require-at-least-one';

import { agent } from '../agent';
import { throwApiError } from '../utils';

import { serializeFormForRequest } from './serializers/serialize-form-for-request';
import { TSerializedStage } from './types';

export type TComputedFormValues = {
  [key: string]: unknown;
  stageList: TSerializedStage[];
};

export type TInsert = RequireAtLeastOne<{
  insertAfter: number;
  insertOnPlace: number;
}>;

export type TLoadAutocompletedDataByStartDate = { planVersionId: number; startDate: number };

export type TRequestParams = {
  planVersionId: number;
} & (TInsert | { startDate: number });

export const loadAutocompletedData = async (data: Tab[], requestData: TRequestParams): Promise<TComputedFormValues> => {
  const { planVersionId } = requestData;
  const startDate = 'startDate' in requestData ? requestData.startDate : undefined;
  const insertAfter = 'insertAfter' in requestData ? requestData.insertAfter : undefined;
  const insertOnPlace = 'insertOnPlace' in requestData ? requestData.insertOnPlace : undefined;

  try {
    const formData = serializeFormForRequest(data);

    const res = await agent.post('/well/getStageList', formData, {
      params: { planVersionId, insertAfter, insertOnPlace, startDate },
    });

    return res.data;
  } catch (e) {
    throwApiError(e);
  }
};
