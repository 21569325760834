export enum ChartType {
  column = 'column',
  line = 'line',
  bar = 'bar',
}

export const isChartType = (value: unknown): value is ChartType =>
  typeof value === 'string' && Object.values<string>(ChartType).includes(value);

export type SeriesData = {
  name: string;
  data: (number | null)[];
  type: ChartType;
};

export type ChartData = {
  id: string;
  name: string;
  labels: string[];
  series: SeriesData[];
};
