import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsGroup } from 'src/features/wells-list/view-settings-sidebar/columns-group';
import { SidebarSettingsColumnsGroupType } from 'src/features/wells-list/view-settings-sidebar/types';
import { mapSidebarSettingsData } from 'src/features/wells-list/view-settings-sidebar/utils';
import { mapColumnsData } from 'src/pages/wells-page/utils';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { Button } from 'src/shared/components/button/button';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { hasValue } from 'src/shared/utils/common';

import styles from './view-settings-sidebar.module.scss';
interface Props {
  pageStore: WellsPageStore;
}

export const ViewSettingsSidebar = observer(function ViewSettingsSidebar({ pageStore }: Props) {
  const { t } = useTranslation('common');

  const [columnsGroups, setColumnsGroups] = useState<SidebarSettingsColumnsGroupType[]>(() =>
    mapSidebarSettingsData(pageStore.table.columnsData)
  );

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  function onDragStart(): void {
    setIsDragging(true);
  }

  function onDragEnd({ active, over }: DragEndEvent): void {
    const isColumnsGroupDragging = active?.data.current?.isGroup;
    const movedColumnGroupId = active?.data.current?.groupId;

    if (over && active.id !== over.id && isColumnsGroupDragging) {
      setColumnsGroups(
        arrayMove(
          columnsGroups,
          columnsGroups.findIndex((column) => column.id === active.id),
          columnsGroups.findIndex((column) => column.id === over.id)
        )
      );
    }

    if (over && active.id !== over.id && hasValue(movedColumnGroupId) && !isColumnsGroupDragging) {
      setColumnsGroups((columnsGroups) => {
        return columnsGroups.map((group) => {
          return movedColumnGroupId === group.id
            ? {
                ...group,
                innerColumns: arrayMove(
                  group.innerColumns,
                  group.innerColumns.findIndex((column) => column.id === active.id),
                  group.innerColumns.findIndex((column) => column.id === over.id)
                ),
              }
            : group;
        });
      });
    }

    setIsDragging(false);
  }

  function setIsShownColumn(groupId: string, columnId: string, isShown: boolean): void {
    setColumnsGroups((columnsGroups) => {
      return columnsGroups.map((group) => {
        if (groupId === group.id) {
          const innerColumns = group.innerColumns.map((column) => {
            return column.id === columnId ? { ...column, isShown } : column;
          });

          const numberOfIsShownColumns = innerColumns.reduce((prev, curr) => {
            return curr.isShown ? prev + 1 : prev;
          }, 0);

          return {
            ...group,
            ...(isShown && { isShown }),
            ...(innerColumns.every((innerColumn) => !innerColumn.isShown) && { isShown: false }),
            ...(group?.width && { width: (group?.width / group.innerColumns.length) * numberOfIsShownColumns }),
            innerColumns,
            width: innerColumns.length * 200,
          };
        }
        return group;
      });
    });
  }

  function setIsFixedColumn(groupId: string, columnId: string): void {
    setColumnsGroups((columnsGroups) => {
      return columnsGroups.map((group) => {
        return groupId === group.id
          ? {
              ...group,
              innerColumns: group.innerColumns.map((column) => {
                return column.id === columnId ? { ...column, isFixed: !column.isFixed } : column;
              }),
            }
          : group;
      });
    });
  }

  function setIsFixedColumnsGroup(groupId: string, isFixed: boolean): void {
    setColumnsGroups((columnsGroups) => {
      return columnsGroups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            isFixed: isFixed,
            innerColumns: group.innerColumns.map((innerColumn) => ({ ...innerColumn, isFixed: isFixed })),
          };
        }
        return group;
      });
    });
  }

  function setIsShownColumnsGroup(groupId: string, isShown: boolean): void {
    setColumnsGroups((columnsGroups) => {
      return columnsGroups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            isShown: isShown,
            innerColumns: group.innerColumns.map((innerColumn) => ({ ...innerColumn, isShown: isShown })),
          };
        }
        return group;
      });
    });
  }

  function onSaveViewSettings(): void {
    const newColumnsData = columnsGroups.sort((prev, next) => Number(next.isFixed) - Number(prev.isFixed));

    pageStore.setColumnsSettings(mapColumnsData(pageStore.tab, pageStore.view, newColumnsData, pageStore.directories));
  }

  function onCloseViewSettingsSidebar(): void {
    pageStore.setIsViewSettingsSidebarOpened(false);
  }

  useEffect(() => {
    if (pageStore.isViewSettingsSidebarOpened) {
      setColumnsGroups(mapSidebarSettingsData(pageStore.table.columnsData));
    }
  }, [setColumnsGroups, pageStore, pageStore.isViewSettingsSidebarOpened]);

  return (
    <Sidebar
      isOpened={pageStore.isViewSettingsSidebarOpened}
      onClose={onCloseViewSettingsSidebar}
      title={t('displaySettings')}
      closeOnClickOutside={false}
    >
      <div className={styles.wrapper}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={columnsGroups} strategy={verticalListSortingStrategy}>
            {columnsGroups.map((group) => (
              <ColumnsGroup
                key={group.id}
                isWrapped={group.isWrapped}
                isShown={group.isShown}
                isFixed={group.isFixed}
                isSingleGroup={columnsGroups.length < 2}
                items={group.innerColumns}
                groupId={group.id}
                isDragging={isDragging}
                onDragEnd={onDragEnd}
                setIsFixedColumn={setIsFixedColumn}
                setIsShownColumn={setIsShownColumn}
                setIsFixedColumnsGroup={setIsFixedColumnsGroup}
                setIsShownColumnsGroup={setIsShownColumnsGroup}
                label={group.label}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>

      <Button onClick={onSaveViewSettings} variant="primary" className={styles.saveButton}>
        {t('Buttons.apply')}
      </Button>
    </Sidebar>
  );
});
