import { comparer, computed, makeObservable } from 'mobx';

import { IChartDataModel } from '../../../layers/model';
import { IPresenter } from '../../../layers/presenter';

export class DataItemsFullPresenter<TData> implements IPresenter<TData, number> {
  private readonly dataModel: IChartDataModel<TData>;

  constructor(dataModel: IChartDataModel<TData>) {
    this.dataModel = dataModel;

    makeObservable(this);
  }

  @computed({ equals: comparer.shallow })
  get data(): TData {
    return this.dataModel.data;
  }
}
