import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  HeaderColumn as HeaderColumnItem,
  MonthHeaderColumn,
  YearHeaderColumn,
} from '../../../../features/header/data/model/header-column';
import { useMovableElement } from '../../../../shared/use-movable-element';
import { Viewport } from '../../../../shared/viewport/viewport';

import styles from './header-column.module.scss';

interface Props {
  item: HeaderColumnItem;
  horizontalViewport: Viewport;
  className?: string;
}

export const HeaderColumn = observer(function Column({ className, item, horizontalViewport }: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Chart' });

  const ref = useMovableElement({ item: { x: { start: item.start, end: item.end } }, horizontalViewport });

  const renderColumn = (): ReactNode => {
    if (item instanceof MonthHeaderColumn) {
      return t(`months.${item.id}`);
    }

    if (item instanceof YearHeaderColumn) {
      return item.id;
    }

    return null;
  };

  return (
    <div ref={ref} className={clsx(className, styles.titleWrapper)}>
      <p className={styles.title}>{renderColumn()}</p>
    </div>
  );
});
