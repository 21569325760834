import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button';

import styles from './control-bar.module.scss';

interface Props {
  onNewWellClick?(): void;
}

export const ControlBar = observer(function ControlBar({ onNewWellClick }: Props) {
  const { t } = useTranslation('drawers');

  return (
    <div className={styles.container}>
      <Button variant="primary" icon={<PlusIcon />} onClick={onNewWellClick}>
        {t('addPadDrawer.newWell')}
      </Button>
    </div>
  );
});
