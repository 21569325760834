import {
  ComparingPadRigOperation,
  MainComparingChartRig,
} from 'src/features/drilling-chart/presets/comparing-drilling-rigs-chart/entities';
import { assert } from 'src/shared/utils/assert';

import {
  ChartRig,
  PadRigOperation,
  WellRigOperation,
  WellRigOperationCreator,
} from '../../features/drilling-chart/presets/drilling-rigs-chart/entities';

import { ComparingRigsChart } from './comparing-rigs-chart-data-api';

export class ComparingRigsChartDataAdapter {
  initializeWells(
    rawRigs: ComparingRigsChart.RawRigWithTwoVersionsData[],
    firstVersionId: number,
    secondVersionId: number,
    rigIdsList: number[],
    rigs: ChartRig[]
  ): Map<number, ComparingRigsChart.TwoVersionsData<PadRigOperation[]>> | undefined {
    const initializedPadsMap = new Map<number, ComparingRigsChart.TwoVersionsData<PadRigOperation[]>>();
    const rigsWithEmptyData = new Set<number>(rigIdsList);

    if (rawRigs.length) {
      for (const rawRig of rawRigs) {
        const { id, first: firstVersionPads, second: secondVersionPads } = rawRig;

        const parentRigObject: ChartRig | undefined = rigs.find((rig) => rig.id === id);

        if (parentRigObject) {
          const currentRigHasChanges = secondVersionPads?.some(({ items: wells }) =>
            wells.some(({ hasChanges }) => hasChanges)
          );

          const firstVersionInitializedPads: PadRigOperation[] = [];

          for (const firstVersionPad of firstVersionPads) {
            const initializedWells: WellRigOperation[] = [];
            const initializedPad = new PadRigOperation(firstVersionPad, parentRigObject);

            for (const well of firstVersionPad.items) {
              const chartWell = WellRigOperationCreator.create({
                type: WellRigOperationCreator.WellType.well,
                rawWell: well,
                parentRig: parentRigObject,
                parentPad: initializedPad,
              });

              if (chartWell) {
                initializedWells.push(chartWell);
              }
            }

            initializedPad.setItems(initializedWells);

            firstVersionInitializedPads.push(initializedPad);
          }

          const secondVersionInitializedPads: PadRigOperation[] = [];

          if (secondVersionPads && parentRigObject instanceof MainComparingChartRig && parentRigObject.comparingPair) {
            for (const secondVersionPad of secondVersionPads) {
              if (currentRigHasChanges) {
                let currentPadHasChanges = false;
                const initializedWells: WellRigOperation[] = [];
                const initializedPad = new ComparingPadRigOperation(
                  secondVersionPad,
                  initializedWells,
                  parentRigObject.comparingPair
                );

                for (const rawWell of secondVersionPad.items) {
                  if (rawWell?.hasChanges) {
                    if (!currentPadHasChanges) {
                      currentPadHasChanges = true;
                    }

                    const chartWell = WellRigOperationCreator.create({
                      type: WellRigOperationCreator.WellType.comparing,
                      rawWell,
                      parentRig: parentRigObject.comparingPair,
                      parentPad: initializedPad,
                    });

                    if (chartWell) {
                      initializedWells.push(chartWell);
                    }
                  } else {
                    const chartWell = WellRigOperationCreator.create({
                      type: WellRigOperationCreator.WellType.well,
                      rawWell,
                      parentRig: parentRigObject.comparingPair,
                      parentPad: initializedPad,
                    });

                    if (chartWell) {
                      initializedWells.push(chartWell);
                    }
                  }
                }

                initializedPad.setItems(initializedWells);
                secondVersionInitializedPads.push(initializedPad);
              } else {
                const initializedWells: WellRigOperation[] = [];
                const initializedPad = new ComparingPadRigOperation(
                  secondVersionPad,
                  initializedWells,
                  parentRigObject.comparingPair
                );

                for (const rawWell of secondVersionPad.items) {
                  const chartWell = WellRigOperationCreator.create({
                    type: WellRigOperationCreator.WellType.well,
                    rawWell,
                    parentRig: parentRigObject.comparingPair,
                    parentPad: initializedPad,
                  });

                  if (chartWell) {
                    initializedWells.push(chartWell);
                  }
                }

                initializedPad.setItems(initializedWells);
                secondVersionInitializedPads.push(initializedPad);
              }
            }
          }

          const rigId = Number(id);

          assert(!Number.isNaN(rigId));

          initializedPadsMap.set(Number(id), {
            first: firstVersionInitializedPads,
            second: secondVersionInitializedPads,
          });
          rigsWithEmptyData.delete(rigId);
        }
      }
    }

    rigsWithEmptyData.forEach((rigId) => {
      initializedPadsMap.set(Number(rigId), { first: [], second: [] });
    });

    return initializedPadsMap;
  }
}
