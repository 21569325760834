import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WellTypeIcon as WellTypeIconComponent } from 'src/features/well-type-icon';
import { ColoredCard } from 'src/shared/components/colored-card';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';

import { RigOperation } from '../../entities/rig-operation';

import styles from './well.module.scss';

type Variant = 'white' | 'gray';

interface Props {
  item: RigOperation;
  withNavigateArrow?: boolean;
  draggable?: boolean;
  variant?: Variant;
  className?: string;
}

const variantClassNames: Record<Variant, string> = {
  gray: styles.gray,
  white: styles.white,
};

export const Well = observer(function Well({ item, variant = 'gray', className }: Props) {
  const { t } = useTranslation('drawers', { keyPrefix: 'confirmationRemoveSidebar' });

  return (
    <ColoredCard
      cardColor={item.color}
      title={
        <>
          <span className={styles.titleColumn}>{item.title}</span>
          <span className={styles.titleColumn}>{t('rigOperationIndex', { index: item.rigOperationIndex })}</span>
        </>
      }
      text={item.attributes.join(' - ')}
      icons={<WellTypeIconComponent iconName={WellTypeIcon.vertical} className={styles.wellTypeIcon} />}
      showExpandButton={false}
      className={clsx(className, styles.well, variantClassNames[variant])}
    />
  );
});
