import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';

/**
 * Если переданное значение является справочным значением, то извлекает и возвращает поле data
 * @example
 * value = {
 *  id: 123,
 *  status: "ACTIVE",
 *  objectType: 'Common_Object',
 *  data: {...}
 * }
 * return value.data
 */
export const flatDirectoryValue = (data: Record<string, unknown>): Record<string, unknown> | null => {
  if (!('data' in data)) {
    return data;
  }

  if (!isObjectWithKeys(data.data)) {
    return null;
  }

  return data.data;
};
