import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as HorizontalWithPilotWellIcon } from 'src/shared/assets/icons/well-icons/horizontal-with-pilot.svg';
import { ReactComponent as HorizontalWellIcon } from 'src/shared/assets/icons/well-icons/horizontal.svg';
import { ReactComponent as InclinedWithPilotWellIcon } from 'src/shared/assets/icons/well-icons/inclined-with-pilot.svg';
import { ReactComponent as InclinedWellIcon } from 'src/shared/assets/icons/well-icons/inclined.svg';
import { ReactComponent as MultiboreWithPilotWellIcon } from 'src/shared/assets/icons/well-icons/multibore-with-pilot.svg';
import { ReactComponent as MultiboreWellIcon } from 'src/shared/assets/icons/well-icons/multibore.svg';
import { ReactComponent as MultiholeWithPilotWellIcon } from 'src/shared/assets/icons/well-icons/multihole-with-pilot.svg';
import { ReactComponent as MultiholeWellIcon } from 'src/shared/assets/icons/well-icons/multihole.svg';
import { ReactComponent as VerticalWellIcon } from 'src/shared/assets/icons/well-icons/vertical.svg';
import { WellTypeIcon as WellTypeIcons } from 'src/shared/constants/well-type-icon';

import styles from './well-type-icon.module.scss';

interface Props {
  iconName?: WellTypeIcons;
  className?: string;
}

export const WellTypeIcon = observer(function WellTypeIcon({ iconName, className }: Props) {
  switch (iconName) {
    case WellTypeIcons.horizontal:
      return <HorizontalWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.vertical:
      return <VerticalWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.inclined:
      return <InclinedWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.multibore:
      return <MultiboreWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.multihole:
      return <MultiholeWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.inclinedWithPilot:
      return <InclinedWithPilotWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.horizontalWithPilot:
      return <HorizontalWithPilotWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.multiboreWithPilot:
      return <MultiboreWithPilotWellIcon className={clsx(className, styles.icon)} />;

    case WellTypeIcons.multiholeWithPilot:
      return <MultiholeWithPilotWellIcon className={clsx(className, styles.icon)} />;

    default:
      return null;
  }
});
