import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import { Item } from '../../../../shared/entities/abstract-control-entities';
import { ApproachStage } from '../../entities/approach-entities';
import { FormStore } from '../../entities/form.entity';
import { FormElement } from '../../form-element';

type Props = {
  className?: string;
  item: Item;
  formStore: FormStore;
  stage: ApproachStage;
};

export const StageField = observer(function StageField({ className, item, formStore }: Props) {
  const { directories, i18 } = useStore();
  const errorLabel = i18.t('errors:unrecognizedField');
  const label = directories.getFieldLabel(item.fieldId) || errorLabel;

  return <FormElement className={className} item={item} formStore={formStore} label={label} />;
});
