import { CompareWellType, WellType } from 'src/api/wells-list';
import { PAGE_MODE } from 'src/pages/wells-page/types';
import { omit, hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { CompareCellType, CellValueType, TableRow, CellType } from 'src/store/table/types';

export function mapTableData(tableData: CompareWellType[] | WellType[]): TableRow[] {
  return tableData.map((row) => {
    const tableRow: TableRow = {
      selected: false,
      ...(hasValue(row.rowChanged) && { rowChanged: row.rowChanged }),
    } as TableRow;

    for (const key in row) {
      const cell = row[key];

      if (key === 'rowChanged') {
        continue;
      }

      if (isObjectWithKeys(cell) && 'first' in cell && 'second' in cell) {
        tableRow[key] = cell;
        continue;
      }

      if (!isObjectWithKeys(cell) || Array.isArray(cell)) {
        tableRow[key] = { value: cell, isError: false };
      }
    }

    return tableRow;
  });
}

export function serializeTableData(tableData: TableRow[], mode: PAGE_MODE): CompareWellType[] | WellType[] {
  if (mode === PAGE_MODE.compare) {
    return tableData.map((row) => {
      const tableRow: CompareWellType = { ...(hasValue(row.rowChanged) && { rowChanged: row.rowChanged }) };

      for (const key in omit(row, 'selected')) {
        const cell = row[key];

        if (isObjectWithKeys(cell) && 'first' in cell && 'second' in cell) {
          tableRow[key] = cell;
          continue;
        }

        if (isObjectWithKeys(cell) && 'value' in cell) {
          tableRow[key] = cell.value;
        }
      }
      return tableRow;
    });
  }

  return tableData.map((row) => {
    const tableRow: WellType = {};

    for (const key in omit(row, 'selected')) {
      const cell = row[key];

      if (isObjectWithKeys(cell) && 'value' in cell) {
        tableRow[key] = cell.value;
      }
    }

    return tableRow;
  });
}

export function getCellValue(cell?: CompareCellType | CellType): CellValueType {
  return isObjectWithKeys(cell) && 'value' in cell ? cell.value : null;
}
