import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { RigsChartViewTypes } from 'src/api/chart/rigs-chart-api';
import i18n from 'src/i18n';
import { Controls } from 'src/shared/constants/controls';
import { isPadAttributeIcon, PadAttributeIcon } from 'src/shared/constants/pad-attribute-icon';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { hasValue } from 'src/shared/utils/common';
import { formatValueWithUnit } from 'src/shared/utils/format-value-with-unit';
import { getConcatedName } from 'src/shared/utils/get-concated-name';
import { getNumberWithNumberOfDecimalPlaces } from 'src/shared/utils/get-number-with-number-of-decimal-places';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { IndexField } from 'src/shared/utils/index-field';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';
import { LoadingField } from 'src/shared/utils/loading-field';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';

import { ShadowPadRigOperation } from '../../features/data-items-full/presenter/shadow-pad-rig-operation';
import { ShadowWellRigOperation } from '../../features/data-items-full/presenter/shadow-well-rig-operation';
import { DateHelper } from '../../shared/date-helper';
import { ColorAttribute } from '../../shared/entities/color-attribute';
import { IconAttribute } from '../../shared/entities/icon-attribute';
import { RegularAttribute } from '../../shared/entities/regular-attribute';
import { TitleAttribute } from '../../shared/entities/title-attribute';
import { isValueStringOrNumber } from '../../shared/is-valid-text-value';
import { ConflictRigOperation } from '../conflict-sidebar/entities/conflict-rig-operation';
import { SidebarPadRigOperation, SidebarWellRigOperation } from '../pads-and-wells-sidebar/entities';
import { RigOperationsDnd } from '../rig-operations-dnd/rig-operations-dnd-module';

import {
  ChartRig,
  PadRigOperation,
  RigsGroup,
  TemporaryChartRig,
  TemporaryPadRigOperation,
  TemporaryWellRigOperation,
  WellRigOperation,
} from './entities';
import PAD_TOOLTIP_VIEW from './pad-tooltip-view.json';
import { RigsChartView } from './rigs-chart-view';

export class RigsViewSettingsProvider {
  private readonly chartView: RigsChartView;
  private readonly directories: Directories;
  private readonly appSettings: AppSettingsStore;

  @observable private isLoading = false;

  constructor(chartView: RigsChartView, directories: Directories, appSettings: AppSettingsStore) {
    this.chartView = chartView;
    this.directories = directories;
    this.appSettings = appSettings;

    makeObservable(this);
  }

  private receiveWellAttributeValue(
    item: RigsViewSettingsProvider.ItemWithFieldNameGetter,
    settingsItemView: Omit<ViewSettings.SettingsControlView, 'control' | 'fieldId'> & {
      control?: string;
      fieldId?: string;
    }
  ): string | null {
    const {
      attrName,
      refObjectAttr,
      refObjectType,
      refQuery,
      numberOfDecimalPlaces = 2,
      attrConcat,
      type,
      delimiter,
    } = settingsItemView;

    const attrValue = ((): unknown => {
      if (attrName) {
        const itemValue = item.getFieldValue(attrName);
        const stringifiedValue = itemValue?.toString();

        if (hasValue(stringifiedValue) && !Number.isNaN(Number(itemValue)) && stringifiedValue.includes('.')) {
          return getNumberWithNumberOfDecimalPlaces(stringifiedValue, false, numberOfDecimalPlaces);
        } else {
          return itemValue;
        }
      }

      return null;
    })();

    if (attrName && refQuery && refObjectAttr && refObjectType) {
      const refObject = this.directories.getJoinedObject(refQuery);

      const attributeObject = refObject?.find((refObjectItem) => {
        const idFieldValue = refObjectItem[refQuery.objectType]?.id;

        return idFieldValue?.toString() === attrValue?.toString();
      });

      const attribute = attributeObject?.[refObjectType]?.data?.[refObjectAttr];

      return hasValue(attribute) ? attribute.toString() : '-';
    } else if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const attributeObject = refObject?.find(({ id }) => id === attrValue);
      const attribute = attributeObject?.data[refObjectAttr];

      return hasValue(attribute) ? attribute.toString() : null;
    } else if (attrConcat) {
      return attrConcat
        .reduce<string[]>((result, attr) => {
          const value = item.getFieldValue(attr);
          const validatedValue = (() => {
            if (type !== 'Date' || (typeof value !== 'number' && typeof value !== 'string')) {
              return null;
            }

            if (typeof value === 'number') {
              return DateHelper.unixToDateFormat(value);
            }

            if (Number.isNaN(Number(value))) {
              return DateHelper.unixToDateFormat(value);
            }

            return DateHelper.unixToDateFormat(Number(value));
          })();

          if (validatedValue) {
            result.push(validatedValue);
          }

          return result;
        }, [])
        .join(delimiter);
    } else {
      if (settingsItemView.type === 'Date') {
        const validatedValue = (() => {
          if (type !== 'Date' || (typeof attrValue !== 'number' && typeof attrValue !== 'string')) {
            return null;
          }

          if (typeof attrValue === 'number') {
            return DateHelper.unixToDateFormat(attrValue);
          }

          if (Number.isNaN(Number(attrValue))) {
            return DateHelper.unixToDateFormat(attrValue);
          }

          return DateHelper.unixToDateFormat(Number(attrValue));
        })();

        return validatedValue;
      }

      if (attrValue !== 'null' && isStringNumberOrBoolean(attrValue)) {
        return attrValue.toString();
      } else {
        return null;
      }
    }
  }

  private receivePadAttributeValue(
    item: PadRigOperation | SidebarPadRigOperation,
    view: ViewSettings.SettingsControlView
  ): string {
    const { attrName, fieldId, refObjectAttr, refObjectType, refQuery } = view;

    const attrValue = item.id;

    if (attrName && refQuery && refObjectAttr) {
      const refObject = this.directories.getJoinedObjectDeprecated(refQuery);

      const attributeObject = refObject?.find((refObjectItem) => {
        const idFieldValue = refObjectItem[attrName];

        return idFieldValue.toString() === attrValue.toString();
      });

      const attributeName = `${refObjectType}.${refObjectAttr}`;
      const attribute = attributeObject?.[attributeName];

      return attribute ? attribute.toString() : '-';
    } else if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const attributeObject = refObject?.find(({ id }) => id === attrValue);
      const attribute = attributeObject?.data[refObjectAttr];

      return attribute ? attribute.toString() : '-';
    } else if (hasValue(fieldId)) {
      const value = item.getFieldValue(fieldId);

      if (!hasValue(value)) {
        return '-';
      }

      if (fieldId === 'rseWellCount') {
        return i18n.t('common:WellsCount.wellCount', { count: Number(value) });
      }

      return value.toString() ?? '-';
    } else {
      return attrValue ? attrValue.toString() : '-';
    }
  }

  private receiveRigAttributeValue(
    item: ChartRig | PadRigOperation,
    settingsItem: ViewSettings.SettingsControl
  ): string | IndexField {
    if (settingsItem.view.fieldId === 'index') {
      return new IndexField();
    }

    const { attrName, fieldId, refObjectAttr, refObjectType, refQuery } = settingsItem.view;

    const attrValue = attrName ? item.getFieldValue(attrName) : null;

    if (attrName && refQuery && refObjectAttr) {
      const refObject = this.directories.getJoinedObjectDeprecated(refQuery);

      const attributeObject = refObject?.find((refObjectItem) => {
        const idFieldValue = refObjectItem[attrName];

        return idFieldValue?.toString() === attrValue;
      });

      const attributeName = `${refObjectType}.${refObjectAttr}`;
      const attribute = attributeObject?.[attributeName];

      const valueInterpretation = this.directories.getValueInterpretation(fieldId, attribute, true);

      if (hasValue(valueInterpretation)) {
        return valueInterpretation;
      }

      return attribute ? attribute.toString() : '-';
    } else if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const attributeObject = refObject?.find(({ id }) => id.toString() === attrValue);
      const attribute = attributeObject?.data[refObjectAttr];

      const valueInterpretation = this.directories.getValueInterpretation(fieldId, attribute, true);

      if (hasValue(valueInterpretation)) {
        return valueInterpretation;
      }

      return attribute ? attribute.toString() : '-';
    } else {
      const valueInterpretation = this.directories.getValueInterpretation(fieldId, attrValue, true);

      if (hasValue(valueInterpretation)) {
        return valueInterpretation;
      }

      return attrValue ? attrValue.toString() : '-';
    }
  }

  private receiveWellTitle(item: WellRigOperation | ShadowWellRigOperation | TemporaryWellRigOperation): string | null {
    const { attrName, refObjectAttr, refObjectType } = this.chartView.view.carpet.wells;

    const refObject = this.directories.getObject(refObjectType);
    const labelObject = refObject?.find(({ id }) => id === item.getFieldValue(attrName));
    const label = labelObject?.data[refObjectAttr];

    return label ? label.toString() : null;
  }

  private receiveGroupTitle(item: RigsGroup): string | null {
    const label = item.getFieldValue('name');

    return isValueStringOrNumber(label) ? label.toString() : null;
  }

  @flow.bound
  private async *loadCarpetDictionaries(view: RigsChartViewTypes.RigsChartView) {
    this.isLoading = true;

    const { refObjectType, pads, wells } = view.carpet;
    const refObjectTypes = [
      refObjectType,
      wells.refObjectType,
      ...pads.headers.reduce((objects: string[], { refObjectType }) => {
        if (refObjectType) {
          objects.push(refObjectType);
        }

        return objects;
      }, []),
    ];

    const refQueries: TRefQuery[] = [];

    wells.tooltip.defaultAttributes.forEach((attributeList) => {
      attributeList.forEach((attribute) => {
        if (attribute.type === 'Formula') {
          attribute.arguments.forEach(({ refObjectType, refQuery }) => {
            if (refObjectType) {
              refObjectTypes.push(refObjectType);
            }

            if (refQuery) {
              refQueries.push(refQuery);
            }
          });
        } else {
          if (attribute.refObjectType) {
            refObjectTypes.push(attribute.refObjectType);
          }
        }
      });
    }, []);

    await this.directories.loadObjects(refObjectTypes);
    await this.directories.loadJoinedObjects(refQueries);
    yield;

    this.isLoading = false;
  }

  @flow.bound
  private async *loadDictionaries(shownWellAttributesFields: Record<string, ViewSettings.SettingsControl[]>) {
    this.isLoading = true;

    const refObjects: string[] = [];
    const joinedObjects: TRefQuery[] = [];

    for (const shownAttributes of Object.values(shownWellAttributesFields)) {
      for (const shownWellAttributesField of shownAttributes) {
        const { view } = shownWellAttributesField;
        const { refObjectAttr, refObjectType, refQuery } = view;

        if (refQuery) {
          joinedObjects.push(refQuery);
        } else if (refObjectAttr && refObjectType) {
          refObjects.push(refObjectType);
        }
      }
    }

    await Promise.all([
      this.directories.loadObjects(refObjects),
      this.directories.loadJoinedObjectsDeprecated(joinedObjects),
    ]);
    yield;

    this.isLoading = false;
  }

  @action.bound
  init(): VoidFunction {
    this.loadCarpetDictionaries(this.chartView.view);
    this.loadDictionaries(this.shownAttributesFields);

    const disposeCarpetDictionariesLoading = reaction(
      () => this.chartView.view,
      (view) => {
        this.loadCarpetDictionaries(view);
      }
    );

    const disposeWellDictionariesLoading = reaction(
      () => this.shownAttributesFields,
      (shownAttributesFields) => {
        this.loadDictionaries(shownAttributesFields);
      }
    );

    return () => {
      disposeCarpetDictionariesLoading();
      disposeWellDictionariesLoading();
    };
  }

  @computed
  get shownAttributesFields(): Record<string, ViewSettings.SettingsControl[]> {
    const attributes: Record<string, ViewSettings.SettingsControl[]> = {};

    function findSettingViewInGroups(
      settingFieldId: string,
      viewSettingGroups: ViewSettings.RawSettingsGroup[]
    ): ViewSettings.SettingsControlView | undefined {
      for (const group of viewSettingGroups) {
        const viewSetting = group.items.find((viewSetting) => viewSetting.fieldId === settingFieldId);

        if (viewSetting) {
          return viewSetting;
        }
      }
    }

    for (const [settingKey, settingValues] of Object.entries(this.chartView.infoSettingsValues)) {
      const settingAttributes: ViewSettings.SettingsControl[] = [];

      if (!isObjectWithKeys(settingValues)) {
        continue;
      }

      const viewSettings = this.chartView.settings[settingKey];

      if (settingValues.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
        for (const settingValue of settingValues.settings) {
          if (!settingValue.isShown) {
            continue;
          }

          if (viewSettings.type !== settingValues.type) {
            continue;
          }

          const settingView = viewSettings.items.find((viewSetting) => viewSetting.fieldId === settingValue.fieldId);

          if (settingView) {
            settingAttributes.push({ view: settingView, values: settingValue });
          }
        }
      } else {
        for (const settingsGroup of settingValues.settings) {
          for (const settingValue of settingsGroup.items) {
            if (!settingValue.isShown) {
              continue;
            }

            if (viewSettings.type !== settingValues.type) {
              continue;
            }

            const settingView = findSettingViewInGroups(settingValue.fieldId, viewSettings.groups);

            if (settingView) {
              settingAttributes.push({ view: settingView, values: settingValue });
            }
          }
        }
      }

      attributes[viewSettings.fieldId] = settingAttributes;
    }

    return attributes;
  }

  getAttributes = computedFn(
    (
      item:
        | WellRigOperation
        | ChartRig
        | PadRigOperation
        | SidebarPadRigOperation
        | SidebarWellRigOperation
        | ShadowWellRigOperation
        | TemporaryPadRigOperation
        | TemporaryWellRigOperation
    ): (string | IndexField)[] | null => {
      if (
        item instanceof WellRigOperation ||
        item instanceof SidebarWellRigOperation ||
        item instanceof ShadowWellRigOperation ||
        item instanceof TemporaryWellRigOperation
      ) {
        return this.shownAttributesFields['wellSettings']?.map(
          (shownAttributesField) => this.receiveWellAttributeValue(item, shownAttributesField.view) ?? '-'
        );
      }
      if (item instanceof ChartRig) {
        return this.shownAttributesFields['rigSettings']?.map((shownAttributesFields) =>
          this.receiveRigAttributeValue(item, shownAttributesFields)
        );
      }
      if (item instanceof PadRigOperation || item instanceof SidebarPadRigOperation) {
        return this.shownAttributesFields['padSettings']?.map((shownAttributesFields) =>
          this.receivePadAttributeValue(item, shownAttributesFields.view)
        );
      }

      return null;
    }
  );

  private getChartRigTitle(item: ChartRig | TemporaryChartRig): string {
    const titleView = this.chartView.view.carpet.rows;

    const rigItem = item instanceof TemporaryChartRig ? item.commonRigData : item.item;

    return getConcatedName(this.directories, titleView, rigItem) || '';
  }

  getTitle(
    item:
      | RigOperationsDnd.ViewItem
      | SidebarWellRigOperation
      | SidebarPadRigOperation
      | TemporaryChartRig
      | ShadowPadRigOperation
      | ShadowWellRigOperation
      | TemporaryWellRigOperation
  ): string | LoadingField {
    if (this.isLoading) {
      return new LoadingField();
    }

    if (item instanceof RigsGroup) {
      const fieldValue = this.receiveGroupTitle(item);

      if (isValueStringOrNumber(fieldValue)) {
        return fieldValue.toString();
      }

      return '';
    }

    if (item instanceof TemporaryChartRig) {
      return this.getChartRigTitle(item);
    }

    if (item instanceof ChartRig) {
      return this.getChartRigTitle(item);
    }

    if (item instanceof PadRigOperation || item instanceof TemporaryPadRigOperation) {
      const padHeaders = this.chartView.view.carpet.pads.headers.filter(({ control }) => control === Controls.title);
      const padHeadersValues: string[] = [];

      for (const header of padHeaders) {
        if (header.refObjectAttr && header.refObjectType === 'Common_Pad') {
          const headerValue = item.getFieldValue(header.refObjectAttr);

          if (headerValue !== undefined) {
            padHeadersValues.push(headerValue.toString());
          }
        }
      }

      return padHeadersValues.join(', ');
    }

    if (item instanceof WellRigOperation || item instanceof TemporaryWellRigOperation) {
      return this.receiveWellTitle(item) || '';
    }

    if (item instanceof SidebarWellRigOperation) {
      return item.title;
    }

    if (item instanceof SidebarPadRigOperation) {
      return item.title;
    }

    if (item instanceof ShadowPadRigOperation) {
      return item.pad.title;
    }

    if (item instanceof ShadowWellRigOperation) {
      return this.receiveWellTitle(item) || '';
    }

    if (item instanceof ConflictRigOperation) {
      return 'Conflict Well';
    }

    return '';
  }

  getDescription(item: ChartRig | TemporaryChartRig): (string | IndexField)[] {
    if (item instanceof TemporaryChartRig) {
      return ['-'];
    }

    return this.shownAttributesFields['rigSettings'].map((shownRigAttributesFields) =>
      this.receiveRigAttributeValue(item, shownRigAttributesFields)
    );
  }

  getPadTooltipAttributes(item: PadRigOperation): RegularAttribute[] | LoadingField | null {
    if (this.isLoading) {
      return new LoadingField();
    }

    const visibilitySettings = this.shownAttributesFields['padSettings'];
    const tooltipAttributes =
      this.chartView.view.carpet.pads.tooltip?.additionalAttributes.filter((a) =>
        visibilitySettings.find((s) => s.values.fieldId === a.fieldId)
      ) ?? [];

    const parsedAdditionalAttributes: RegularAttribute[] = [];

    tooltipAttributes?.forEach((rawTooltipAttr) => {
      const attribute = this.processTooltipAttribute(item, rawTooltipAttr);
      if (attribute && attribute instanceof RegularAttribute) {
        parsedAdditionalAttributes.push(attribute);
      }
    });

    return parsedAdditionalAttributes;
  }

  getPadTooltipDate(item: PadRigOperation): string {
    const startDate = DateHelper.unixToDateFormat(item.x.start);
    const endDate = DateHelper.unixToDateFormat(item.x.end);

    return `${startDate} - ${endDate}`;
  }

  private processTooltipAttribute(
    item: WellRigOperation | SidebarWellRigOperation | PadRigOperation,
    view: ViewSettings.SettingsControlView
  ): ColorAttribute | IconAttribute<WellTypeIcon> | RegularAttribute | null {
    const label = view.fieldId ? this.directories.getFieldLabel(view.fieldId) : null;
    const unit = view.fieldId ? this.directories.getFieldUnit(view.fieldId) : null;

    if (!hasValue(label)) {
      return null;
    }

    if (item instanceof WellRigOperation || item instanceof SidebarWellRigOperation) {
      const value = this.receiveWellAttributeValue(item, view) ?? '-';

      const valueWithUnit = formatValueWithUnit(value, unit);

      return new RegularAttribute(label, valueWithUnit);
    }

    if (item instanceof PadRigOperation) {
      const value = this.receivePadAttributeValue(item, view);

      const valueWithUnit = formatValueWithUnit(value, unit);

      return new RegularAttribute(label, valueWithUnit);
    }

    return null;
  }

  private getDefaultWellTooltipAttribute(
    well: WellRigOperation | SidebarWellRigOperation,
    defaultAttr: RigsChartViewTypes.DefaultWellTooltipAttribute
  ): RigsViewSettingsProvider.WellTooltipAttribute | null {
    switch (defaultAttr.type) {
      case 'Color': {
        const wellPurposeValue = this.receiveWellAttributeValue(well, defaultAttr) ?? '-';

        if (!hasValue(wellPurposeValue)) {
          return null;
        }

        const wellTypeColor = this.getWellColor(well.data);

        return new ColorAttribute(wellTypeColor, this.isLoading ? new LoadingField() : wellPurposeValue);
      }

      case 'Icon': {
        const wellTypeIconName = this.getWellIcon(well.data);

        if (!hasValue(wellTypeIconName)) {
          return null;
        }

        const wellTypeValue = this.receiveWellAttributeValue(well, defaultAttr) ?? '-';

        return new IconAttribute<WellTypeIcon>(
          wellTypeIconName,
          this.isLoading ? new LoadingField() : wellTypeValue ?? ''
        );
      }

      case 'Title': {
        const title = this.receiveWellAttributeValue(well, defaultAttr) ?? '-';

        return new TitleAttribute(this.isLoading ? new LoadingField() : title);
      }

      case 'Date': {
        const { fieldId } = defaultAttr;
        const label = this.directories.getFieldLabel(fieldId) ?? '';
        const attrValue = this.receiveWellAttributeValue(well, defaultAttr) ?? '-';

        return new RegularAttribute(label, attrValue);
      }

      case 'Formula': {
        const { body, resultType, arguments: args, fieldId } = defaultAttr;

        const label = this.directories.getFieldLabel(fieldId) ?? '';
        const nullValue = this.directories.getFieldNullValue(fieldId) ?? '-';
        const controls = args.map(({ control }) => control);

        // eslint-disable-next-line no-new-func
        const formulaFunction = new Function(...controls, body);

        const argValues = args.map((arg) => {
          return this.receiveWellAttributeValue(well, arg);
        });

        try {
          const value = formulaFunction(...argValues);
          const validatedValue =
            hasValue(value) && (typeof value === 'string' || typeof value === 'number') && resultType === 'Date'
              ? DateHelper.unixToDateFormat(value)
              : value ?? null;

          return new RegularAttribute(label, validatedValue ?? nullValue);
        } catch (e) {
          console.error(e);

          return new RegularAttribute(label, nullValue);
        }
      }

      default:
        return null;
    }
  }

  getWellTooltip(well: WellRigOperation | SidebarWellRigOperation): RigsViewSettingsProvider.WellTooltip | undefined {
    try {
      const rawAttributes = this.shownAttributesFields['wellSettings'];
      const parsedAttributesLists: RigsViewSettingsProvider.WellTooltipAttribute[][] = [];

      this.chartView.view.carpet.wells.tooltip?.defaultAttributes.forEach((attrList) => {
        const parsedAttributes: RigsViewSettingsProvider.WellTooltipAttribute[] = [];

        attrList.forEach((rawDefaultAttr) => {
          const defaultAttr = this.getDefaultWellTooltipAttribute(well, rawDefaultAttr);

          if (defaultAttr) {
            parsedAttributes.push(defaultAttr);
          }
        });

        parsedAttributesLists.push(parsedAttributes);
      });

      const lastAttributesList = parsedAttributesLists.at(-1);

      rawAttributes?.forEach((rawTooltipAttr) => {
        const attribute = this.processTooltipAttribute(well, rawTooltipAttr.view);

        if (attribute) {
          lastAttributesList?.push(attribute);
        }
      });

      return {
        attributes: parsedAttributesLists,
      };
    } catch (e) {
      console.error(e);
    }
  }

  getPadAttributes(
    item: PadRigOperation | SidebarPadRigOperation | ShadowPadRigOperation | TemporaryPadRigOperation
  ): PadAttributeIcon[] | null {
    if (this.isLoading) {
      return null;
    }

    const view: PadTooltipView.Item[] = PAD_TOOLTIP_VIEW;

    return view.reduce<PadAttributeIcon[]>((attributeNames, attributesGroup) => {
      const hasViewPadAttributes = attributesGroup.attributes.some((attribute) =>
        attribute.values.some((value) => {
          const fieldName = value.value;

          return item.getFieldValue(fieldName);
        })
      );

      if (hasViewPadAttributes && isPadAttributeIcon(attributesGroup.icon)) {
        attributeNames.push(attributesGroup.icon);
      }

      return attributeNames;
    }, []);
  }

  getWellColor(item: Record<string, unknown>): string {
    return getWellColor(item, this.appSettings.wellColorRules);
  }

  getWellIcon(item: Record<string, unknown>): WellTypeIcon | undefined {
    return this.appSettings.getWellIcon(item, addGOplanPrefix('GeologicalTask.wellTypeId'));
  }

  /** Get start of first stage in rig operation. */
  getRigOperationStartUnix(item: WellRigOperation): number | null {
    try {
      const { attrName } = this.chartView.view.carpet.wells.rigOperationStart;

      const startDate = item.getFieldValue(attrName);

      if (startDate) {
        return Number.parseInt(startDate.toString()) ?? null;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }
}

export namespace RigsViewSettingsProvider {
  export interface ItemWithFieldNameGetter {
    getFieldValue(fieldName: string): unknown;
  }

  export type WellTooltip = {
    attributes: WellTooltipAttribute[][];
  };

  export type WellTooltipAttribute = ColorAttribute | IconAttribute<WellTypeIcon> | RegularAttribute | TitleAttribute;

  export type PadTooltipAttributeValue = {
    value: string;
    rawTitle?: string;
  };

  export type PadTooltipAttribute = {
    rawTitle: string;
    values: PadTooltipAttributeValue[];
    icon: PadAttributeIcon | null;
  };
}

export namespace PadTooltipView {
  export type AttributeValue = {
    value: string;
    type: string;
  };

  export type Attribute = {
    values: AttributeValue[];
    title?: string;
    delimiter?: string;
  };

  export type Item = {
    title: string;
    attributes: Attribute[];
    icon: string;
  };
}
