import { observer } from 'mobx-react-lite';
import { PropsWithChildren, ReactNode } from 'react';

import { ComboboxDropdownButton } from './combobox-dropdown-button';

import styles from './combobox-dropdown.module.scss';

type Props = PropsWithChildren<{
  children: ReactNode;
  fieldId?: string;
  showButton?: boolean;
}>;

export const ComboboxDropdown = observer(function ComboboxDropdown({ children, fieldId, showButton = true }: Props) {
  return (
    <div className={styles.wrapper}>
      {children}
      {showButton && <ComboboxDropdownButton fieldId={fieldId} />}
    </div>
  );
});
