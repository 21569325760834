import { assert } from 'src/shared/utils/assert';

import { RigSummary } from '../../features/drilling-chart/presets/summary-data-sidebar/entities';

import { Summary } from './summary-data-api';

export class SummaryDataAdapter {
  private parseDateKey(dateKey: string): keyof Summary.RowValues | undefined {
    const [year, quarter] = dateKey.split(/-Q/);
    const yearNumber = Number.parseInt(year);
    const quarterNumber = Number.parseInt(quarter);

    assert(!Number.isNaN(yearNumber) && !Number.isNaN(quarterNumber), 'Parsing error.');

    const quarterFieldNamesMap: Record<number, keyof Summary.RowValues> = {
      1: 'firstQuarter',
      2: 'secondQuarter',
      3: 'thirdQuarter',
      4: 'fourthQuarter',
    };

    return quarterFieldNamesMap[quarterNumber];
  }

  initializeSummary(data: Summary.RawData, rigIds: number[]): Map<number, RigSummary | null> {
    const initializedItems: Map<number, RigSummary | null> = new Map();
    /*
     * Format:
     *
     * Map<{
     *   rigId: {
     *     DRILLING_COMPLETE_WELLS_COUNT: {
     *       firstQuarter: 1,
     *       secondQuarter: 2,
     *       thirdQuarter: 3,
     *       fourthQuarter: 4,
     *       total: 5,
     *     },
     *     ...
     *   },
     * }>
     * */
    const rawRigsSummary: Map<
      number,
      Partial<Record<Summary.Indicator, Record<keyof Summary.RowValues, number>>>
    > = new Map();

    for (const indicator of Object.values(Summary.Indicator)) {
      const { points: quarters } = data[indicator];

      for (const dateKey of Object.keys(quarters)) {
        const { points: rigs } = quarters[dateKey];

        const quarterFieldName = this.parseDateKey(dateKey);

        if (quarterFieldName) {
          for (const rigId of Object.keys(rigs)) {
            const rigIdNumber = Number(rigId);
            const value = rigs[rigId];

            const currentRigData:
              | Partial<Record<Summary.Indicator, Partial<Record<Summary.RowValuesField, number>>>>
              | undefined = rawRigsSummary.get(rigIdNumber) || rawRigsSummary.set(rigIdNumber, {}).get(rigIdNumber);

            if (currentRigData) {
              const currentIndicatorData = currentRigData[indicator];

              if (currentIndicatorData) {
                currentIndicatorData[quarterFieldName] = value ?? 0;
              } else {
                currentRigData[indicator] = {
                  [quarterFieldName]: value ?? 0,
                };
              }
            }
          }
        }
      }
    }

    for (const rigId of rigIds) {
      const rawRigSummary = rawRigsSummary.get(rigId);

      if (rawRigSummary) {
        initializedItems.set(rigId, new RigSummary(rigId, rawRigSummary));
      } else {
        initializedItems.set(rigId, null);
      }
    }

    return initializedItems;
  }
}
