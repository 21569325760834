import { action, makeObservable, observable } from 'mobx';

import { TRequiredIf } from 'src/api/new-well/types';

import { IPassingIntervalRestrictions } from '../../../features/well-form/types';
import { ValidatableItem } from '../abstract-control-entities';

type PassingIntervalFieldData = {
  fieldId: string;
  unit: string;
  placeholderMin: string;
  placeholderMax: string;
  placeholderTotal: string;
  labels: Record<string, string>;
  initialMin: number | null;
  initialMax: number | null;
  initialPassingValue: number | null;
  restrictions: IPassingIntervalRestrictions;
  requiredIf?: TRequiredIf[];
  attrNames: {
    passingValue: string;
    passingValueMax: string;
    passingValueMin: string;
  };
};

type TPassingValue = {
  passingValueMin: number | null;
  passingValueMax: number | null;
  passingValue: number | null;
};

export class PassingIntervalField extends ValidatableItem<TPassingValue> {
  readonly unit: string;
  readonly placeholderMax: string;
  readonly placeholderMin: string;
  readonly placeholderTotal: string;
  readonly labels: Record<string, string>;
  readonly attrNames: {
    passingValue: string;
    passingValueMax: string;
    passingValueMin: string;
  };

  @observable initialValue: TPassingValue = {
    passingValueMin: null,
    passingValueMax: null,
    passingValue: null,
  };
  @observable passingValueMin: number | null;
  @observable passingValueMax: number | null;
  @observable passingValue: number | null;
  @observable fieldsErrors: {
    passingValueMinError?: string;
    passingValueMaxError?: string;
    passingValueError?: string;
  };

  constructor(data: PassingIntervalFieldData) {
    super(data);

    this.placeholderMin = data.placeholderMin;
    this.placeholderMax = data.placeholderMax;
    this.placeholderTotal = data.placeholderTotal;
    this.labels = data.labels;
    this.unit = data.unit;
    this.attrNames = data.attrNames;

    this.passingValueMin = data.initialMin;
    this.passingValueMax = data.initialMax;
    this.fieldsErrors = {};
    this.passingValue = data.initialPassingValue;

    makeObservable(this);
  }

  get value(): TPassingValue {
    return {
      passingValueMin: this.passingValueMin,
      passingValueMax: this.passingValueMax,
      passingValue: this.passingValue,
    };
  }

  @action.bound
  setValue(value: TPassingValue, setValueAsInitial?: boolean): void {
    if (
      value.passingValue === this.value.passingValue &&
      value.passingValueMax === this.value.passingValueMax &&
      value.passingValueMin === this.value.passingValueMin
    ) {
      return;
    }
    this.clearError();
    this.passingValueMin = value.passingValueMin;
    this.passingValueMax = value.passingValueMax;
    this.passingValue = value.passingValue;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: TPassingValue): void {
    this.initialValue = {
      passingValueMin: value.passingValueMin,
      passingValueMax: value.passingValueMax,
      passingValue: value.passingValue,
    };
  }

  @action.bound
  returnInitialValue(): void {
    this.passingValueMin = this.initialValue.passingValueMin;
    this.passingValueMax = this.initialValue.passingValueMax;
    this.passingValue = this.initialValue.passingValue;
    this.clearError();
  }

  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    const objectValue: { passingValue?: unknown; passingValueMax?: unknown; passingValueMin?: unknown } = value;
    const { passingValue, passingValueMax, passingValueMin } = objectValue;

    if (
      ((typeof passingValue === 'number' && !isNaN(passingValue)) ||
        (typeof passingValue === 'string' && !isNaN(Number(passingValue))) ||
        passingValue === null) &&
      ((typeof passingValueMax === 'number' && !isNaN(passingValueMax)) ||
        (typeof passingValueMax === 'string' && !isNaN(Number(passingValueMax))) ||
        passingValueMax === null) &&
      ((typeof passingValueMin === 'number' && !isNaN(passingValueMin)) ||
        (typeof passingValueMin === 'string' && !isNaN(Number(passingValueMin))) ||
        passingValueMin === null)
    ) {
      const newValue = {
        passingValue: passingValue === null ? null : Number(passingValue),
        passingValueMax: passingValueMax === null ? null : Number(passingValueMax),
        passingValueMin: passingValueMin === null ? null : Number(passingValueMin),
      };

      this.setValue(newValue, setValueAsInitial);
      return true;
    }
    return false;
  }

  @action.bound
  clearItem(): void {
    this.passingValueMax = null;
    this.passingValueMin = null;
    this.passingValue = null;
  }

  @action.bound
  handleChangePassingValueMin(value: number | null): void {
    this.passingValueMin = value;
  }

  @action.bound
  handleChangePassingValueMax(value: number | null): void {
    this.passingValueMax = value;
  }

  @action.bound
  handleChangePassingValue(value: number | null): void {
    this.passingValue = value;
    this.clearDurationError();
  }

  @action.bound
  handleBlurPassingValueMin(): void {
    if (
      this.passingValueMin !== null &&
      isFinite(this.passingValueMin) &&
      this.passingValueMax === null &&
      this.passingValue !== null &&
      isFinite(this.passingValue)
    ) {
      this.passingValueMax = this.passingValue + this.passingValueMin;
    }

    if (
      this.passingValueMin !== null &&
      isFinite(this.passingValueMin) &&
      this.passingValueMax !== null &&
      isFinite(this.passingValueMax)
    ) {
      this.passingValue = this.passingValueMax - this.passingValueMin;
    }
  }

  @action.bound
  handleBlurPassingValueMax(): void {
    if (
      this.passingValueMax !== null &&
      isFinite(this.passingValueMax) &&
      this.passingValueMin === null &&
      this.passingValue !== null &&
      isFinite(this.passingValue)
    ) {
      this.passingValueMin = this.passingValueMax - this.passingValue;
    }

    if (
      this.passingValueMax !== null &&
      isFinite(this.passingValueMax) &&
      this.passingValueMin !== null &&
      isFinite(this.passingValueMin)
    ) {
      this.passingValue = this.passingValueMax - this.passingValueMin;
    }
  }

  @action.bound
  handleBlurPassingValue(): void {
    if (this.passingValue !== null && isFinite(this.passingValue)) {
      if (this.passingValueMin !== null && isFinite(this.passingValueMin) && this.passingValueMax === null) {
        this.passingValueMax = this.passingValue + this.passingValueMin;
        return;
      }
      if (this.passingValueMax !== null && isFinite(this.passingValueMax) && this.passingValueMin === null) {
        this.passingValueMin = this.passingValueMax - this.passingValue;
        return;
      }
    }
    this.validatePassingValue();
  }

  checkIsReady(): boolean {
    const { passingValueMinError, passingValueMaxError, passingValueError } = this.fieldsErrors;
    return (
      [passingValueMinError, passingValueMaxError, passingValueError].every((error) => !error) &&
      this.passingValue !== null
    );
  }

  @action.bound
  clearError(): void {
    this.fieldsErrors = {};
  }

  @action.bound
  validatePassingValue() {
    if (this.restrictions.required && this.passingValue === null) {
      this.fieldsErrors.passingValueError = 'newWellForm:Errors.required';
    }
  }

  @action.bound
  clearDurationError() {
    this.fieldsErrors.passingValueError = undefined;
  }

  @action.bound
  hasErrors(): boolean {
    this.validatePassingValue();
    return !!this.fieldsErrors.passingValueError;
  }
}
