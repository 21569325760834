import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';

import { FormSelect } from 'src/shared/components/form-components';

import { RegularComboBox } from '../../../../shared/entities/control-entities';
import { TOption } from '../../types';
import { ComboboxDropdown } from '../combobox-dropdown/combobox-dropdown';

type Props = {
  className?: string;
  label?: string;
  item: RegularComboBox;
  isDisabled: boolean;
  onBlur?: VoidFunction;
  onChange?: VoidFunction;
};

export const NewWellRegularComboBox = observer(function NewWellRegularComboBox({
  className,
  label,
  item,
  isDisabled,
  onBlur,
  onChange,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const { init } = item;

  const renderDropdown = (origin: ReactElement): ReactElement => {
    return <ComboboxDropdown fieldId={item.fieldId}>{origin}</ComboboxDropdown>;
  };

  const handleChange = (value: string | number | TOption | TOption[] | null): void => {
    item.tryToSetRawValue(value);
    item.clearError();
    setIsOpened(false);
    onChange?.();
  };

  const handleBlur = () => {
    onBlur?.();
  };

  const onDropdownVisibleChange = (open: boolean): void => {
    setIsOpened(open);
  };

  useEffect(init, [init]);

  return (
    <FormSelect<string | number | TOption>
      placeholder={item.placeholder}
      open={isOpened}
      value={item.invalidValue || item.archivedValue || item.value}
      label={label}
      className={className}
      errorText={item.errorText}
      options={item.options}
      isDisabled={isDisabled}
      dropdownStyle={{
        zIndex: 3,
      }}
      dataTestId={`combobox-${item.fieldId}`}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={renderDropdown}
      onBlur={handleBlur}
      onClear={item.clearItem}
      onChange={handleChange}
    />
  );
});
