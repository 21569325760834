import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { UserSettingsManager } from 'src/api/user-settings';
import { SwitchButtonGroupItem } from 'src/shared/components/switch-button-group/switch-button-group';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { EditingSettingsItem } from '../../shared/editing-settings-item';
import { ViewSettingsHelper } from '../../shared/view-settings-helper';
import { WellsChartView } from '../drilling-wells-chart/wells-chart-view';

export class WellViewSettingsSidebarStore {
  private readonly directories: Directories;
  private readonly settingsValuesManager: UserSettingsManager<ViewSettings.RawSettingsValues>;

  readonly notifications: NotificationsStore;

  @observable private chartView?: WellsChartView;

  @observable isLoading = false;
  @observable settings: Record<string, ViewSettings.GroupedSettingsControls | ViewSettings.FlatSettingsControls> = {};

  constructor(
    rootStore: RootStore,
    chartView: WellsChartView,
    settingsValuesManager: UserSettingsManager<ViewSettings.RawSettingsValues>
  ) {
    this.chartView = chartView;
    this.directories = rootStore.directories;
    this.settingsValuesManager = settingsValuesManager;
    this.notifications = rootStore.notifications;

    makeObservable(this);
  }

  @flow.bound
  private async *updateChartViewSettings(settingsValues: Record<string, ViewSettings.RawSettingValue>) {
    if (!this.isLoading) {
      try {
        this.isLoading = true;

        const allSettingsValues: ViewSettings.RawSettingsValues = {
          info: settingsValues,
        };

        await this.settingsValuesManager.update(allSettingsValues);
        yield;

        this.chartView?.setSettingsValues(allSettingsValues);
      } catch (e) {
        yield;

        console.error(e);
        this.notifications.showErrorMessageT('errors:failedToUpdateChartSettings');
      } finally {
        this.isLoading = false;
      }
    }
  }

  @computed
  get tabs(): SwitchButtonGroupItem<string>[] {
    if (!this.chartView?.settings) {
      return [];
    }

    return Object.entries(this.chartView.view.settings).map(([_, settingObject]) => {
      return {
        key: settingObject.fieldId,
        title: this.directories.getFieldLabel(settingObject.fieldId) || '',
      };
    });
  }

  @action.bound
  init(): VoidFunction {
    const disposeSettingsValuesUpdate = reaction(
      () => ({ viewSettingsValues: this.chartView?.infoSettingsValues, viewSettings: this.chartView?.settings }),
      ({ viewSettings, viewSettingsValues }) => {
        if (viewSettings && viewSettingsValues) {
          this.settings = ViewSettingsHelper.mapViewAndValuesToSettingsControls(
            viewSettings,
            viewSettingsValues || {},
            this.directories
          );
        }
      },
      { fireImmediately: true }
    );

    return () => {
      disposeSettingsValuesUpdate();
    };
  }

  @action.bound
  onFlatTabSortEnd(items: EditingSettingsItem[], fieldId: string): void {
    this.settings[fieldId].items = items;
  }

  @action.bound
  onGroupedTabSortEnd(items: EditingSettingsItem[], group: ViewSettings.GroupedSettingsControlsGroupData): void {
    group.items = items;
  }

  @action.bound
  onSettingsSave(): void {
    const updatedSettings = ViewSettingsHelper.getUpdatedSettings(this.settings);

    this.updateChartViewSettings(updatedSettings);
  }
}
