import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { assert } from 'src/shared/utils/assert';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { PadTypes } from 'src/types/pad-types';
import { WellTypes } from 'src/types/well-types';

export class PadApi {
  async getPad(padId: number): Promise<PadTypes.RawPad> {
    try {
      const { data } = await agent.get<PadTypes.RawPad>(`object/Common_Pad/${padId}`);

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async getWells(padId: number, planVersionId: number): Promise<WellTypes.RawRigOperation[]> {
    try {
      const { data: wells } = await agent.get<WellTypes.RawRigOperation[]>('pad/rigOperation/list', {
        params: { padId, planVersionId },
      });

      return wells.map((rawWell) => {
        const idFieldName = addGOplanPrefix('PlanWellTriple.id');
        const idFieldValue = rawWell[idFieldName];

        assert(typeof idFieldValue === 'number', `Invalid id in element (id: ${idFieldValue})`);

        return rawWell;
      });
    } catch (e) {
      throwApiError(e);
    }
  }
}
