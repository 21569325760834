import { observer } from 'mobx-react-lite';

import { IndicatorsView } from 'src/api/chart/drilling-plan-charts-api';
import { hasValue } from 'src/shared/utils/common';

import {
  IndicatorColumn,
  IndicatorsSettings,
  LoadingIndicatorsColumn,
} from '../../../presets/indicators-view-settings-sidebar/entities';
import { indicatorNameToTrendType } from '../../../shared/indicators';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { IndicatorCell } from '../../indicator-cell';

import styles from './indicators-table.module.scss';

interface Props {
  item: IndicatorColumn | LoadingIndicatorsColumn;
  isExpanded: boolean;
  horizontalViewport: Viewport;
  settings: IndicatorsSettings;
}

export const IndicatorsColumn = observer(function IndicatorsColumn({
  item,
  isExpanded,
  horizontalViewport,
  settings,
}: Props) {
  const ref = useMovableElement({ item: { x: { start: item.start, end: item.end } }, horizontalViewport });

  const shownSettingsItems = settings.shownItemsWithLabels;

  const topIndicator = shownSettingsItems.at(0);
  const restIndicators = shownSettingsItems.slice(1);

  const getValue = (indicator: IndicatorsView.TableSettingsItem): number | string | undefined => {
    if (item instanceof IndicatorColumn) {
      const fieldName = indicatorNameToTrendType(indicator.fieldId);

      if (fieldName) {
        const value = item.getValue(fieldName);

        return !hasValue(value) ? '-' : value;
      }
    }
  };

  return (
    <div ref={ref} className={styles.dataColumnWrapper}>
      <div className={styles.dataColumn}>
        {item instanceof LoadingIndicatorsColumn ? (
          <>
            {!!topIndicator && <IndicatorCell className={styles.dataColumnEmpty} />}

            {isExpanded &&
              restIndicators?.map((indicator) => (
                <IndicatorCell key={indicator.fieldId} className={styles.dataColumnEmpty} />
              ))}
          </>
        ) : (
          <>
            {!!topIndicator && <IndicatorCell value={getValue(topIndicator)} className={styles.dataColumnInner} />}

            {isExpanded &&
              restIndicators?.map((indicator) => (
                <IndicatorCell key={indicator.fieldId} value={getValue(indicator)} className={styles.dataColumnInner} />
              ))}
          </>
        )}
      </div>
    </div>
  );
});
