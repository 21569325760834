import { BaseApiError } from 'src/errors';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { IComparingRigsChartDataApi } from '../../features/drilling-chart/features/comparing-rigs-chart/data/comparing-rigs-chart-data.types';
import { ChartRig, PadRigOperation } from '../../features/drilling-chart/presets/drilling-rigs-chart/entities';
import { agent } from '../agent';

import { ComparingRigsChartDataAdapter } from './comparing-rigs-chart-data-adapter';
import { RigsChart } from './rigs-chart-api';

export class ComparingRigsChartDataApi implements IComparingRigsChartDataApi {
  private readonly notifications: NotificationsStore;

  private adapter: ComparingRigsChartDataAdapter = new ComparingRigsChartDataAdapter();

  constructor(notifications: NotificationsStore) {
    this.notifications = notifications;
  }

  /** Get pads with wells. */
  async getWells(
    startDate: number,
    endDate: number,
    rigIdsList: number[],
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    rigs: ChartRig[]
  ): Promise<Map<number, ComparingRigsChart.TwoVersionsData<PadRigOperation[]>> | undefined> {
    if (rigIdsList.length) {
      let rawRigs;

      try {
        const { data } = await agent.post(
          'graph/rig/rigOperation/list/compare',
          {},
          {
            params: {
              firstPlanVersionId,
              secondPlanVersionId,
              startDate,
              endDate,
              rigIdList: rigIdsList.join(),
            },
          }
        );

        rawRigs = data;
      } catch (e) {
        console.error(e);
        if (e instanceof BaseApiError && e.responseMessage) {
          this.notifications.showErrorMessage(e.responseMessage);
          return;
        }
        this.notifications.showErrorMessageT('errors:failedToLoadData');
      }

      if (rawRigs) {
        return this.adapter.initializeWells(rawRigs, firstPlanVersionId, secondPlanVersionId, rigIdsList, rigs);
      }
    }
  }
}

export namespace ComparingRigsChart {
  export type TwoVersionsData<TData> = { first: TData; second?: TData };

  export type RawRigWithTwoVersionsData = { id: number } & TwoVersionsData<
    RigsChart.RawPad<RigsChart.ComparingRawWell>[]
  >;
}
