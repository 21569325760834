import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MutableRefObject, RefObject } from 'react';

import { ReactComponent as DragIcon } from 'src/shared/assets/icons/drag-icon.svg';

import { Range } from '../../../../layers/model';
import { useMovableContainer } from '../../../../shared/use-movable-container';
import { TimelinePresenter } from '../../presenter/timeline-presenter';

import styles from './timeline-select.module.scss';

interface Props {
  timelineViewport: Range<number>;
  timelineElement: MutableRefObject<HTMLDivElement | null>;
  defaultSelectAreaRef: RefObject<HTMLDivElement>;
  defaultRightDragRef: RefObject<HTMLDivElement>;
  defaultLeftDragRef: RefObject<HTMLDivElement>;
  onDragDown(): void;
  onDragMove(x: number, direction: TimelinePresenter.DragDirection): void;
  onRightDragUp(): void;
  onLeftDragUp(): void;
  onDragCancel(): void;
  onSelectAreaDown(): void;
  onSelectAreaMove(x: number): void;
  onSelectAreaUp(): void;
  onSelectAreaCancel(): void;
}

export const TimelineSelect = observer(function TimelineSelect({
  timelineViewport,
  timelineElement,
  defaultSelectAreaRef,
  defaultRightDragRef,
  defaultLeftDragRef,
  onDragDown,
  onDragMove,
  onRightDragUp,
  onLeftDragUp,
  onDragCancel,
  onSelectAreaDown,
  onSelectAreaMove,
  onSelectAreaUp,
  onSelectAreaCancel,
}: Props) {
  const {
    containerRef: selectAreaRef,
    handlePointerDown: handleSelectAreaDown,
    handlePointerMove: handleSelectAreaMove,
    handlePointerUp: handleSelectAreaUp,
  } = useMovableContainer({
    horizontalViewport: timelineViewport,
    containerElement: timelineElement.current || undefined,
    defaultElementRef: defaultSelectAreaRef,
    onPointerDown: onSelectAreaDown,
    onPointerMove: onSelectAreaMove,
    onPointerUp: onSelectAreaUp,
    onCancel: onSelectAreaUp,
    onBlur: onSelectAreaUp,
  });

  const {
    containerRef: leftDragRef,
    handlePointerDown: handleLeftDragDown,
    handlePointerMove: handleLeftDragMove,
    handlePointerUp: handleLeftDragUp,
  } = useMovableContainer({
    horizontalViewport: timelineViewport,
    containerElement: timelineElement.current || undefined,
    defaultElementRef: defaultLeftDragRef,
    onPointerDown: onDragDown,
    onPointerMove: (offsetX) => onDragMove(offsetX, TimelinePresenter.DragDirection.left),
    onPointerUp: onLeftDragUp,
    onCancel: onLeftDragUp,
    onBlur: onLeftDragUp,
  });

  const {
    containerRef: rightDragRef,
    handlePointerDown: handleRightDragDown,
    handlePointerMove: handleRightDragMove,
    handlePointerUp: handleRightDragUp,
  } = useMovableContainer({
    horizontalViewport: timelineViewport,
    containerElement: timelineElement.current || undefined,
    defaultElementRef: defaultRightDragRef,
    onPointerDown: onDragDown,
    onPointerMove: (offsetX) => onDragMove(offsetX, TimelinePresenter.DragDirection.right),
    onPointerUp: onRightDragUp,
    onCancel: onRightDragUp,
    onBlur: onRightDragUp,
  });

  return (
    <>
      <div
        ref={selectAreaRef}
        className={styles.selectContainer}
        onPointerDown={handleSelectAreaDown}
        onPointerMove={handleSelectAreaMove}
        onPointerUp={handleSelectAreaUp}
        onPointerCancel={onSelectAreaCancel}
      />

      <div
        ref={leftDragRef}
        className={clsx(styles.selectDragElement, styles.selectDragElementLeft)}
        onPointerDown={handleLeftDragDown}
        onPointerMove={handleLeftDragMove}
        onPointerUp={handleLeftDragUp}
        onPointerCancel={onDragCancel}
      >
        <div className={styles.selectDragElementInner}>
          <DragIcon className={styles.selectDragIcon} />
        </div>
      </div>

      <div
        ref={rightDragRef}
        className={clsx(styles.selectDragElement, styles.selectDragElementRight)}
        onPointerDown={handleRightDragDown}
        onPointerMove={handleRightDragMove}
        onPointerUp={handleRightDragUp}
        onPointerCancel={onDragCancel}
      >
        <div className={styles.selectDragElementInner}>
          <DragIcon className={styles.selectDragIcon} />
        </div>
      </div>
    </>
  );
});
