import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/trash.svg';
import { ReactComponent as DeleteImg } from 'src/shared/assets/img/delete.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';

import styles from './delete-wells-modal.module.scss';

type Props = {
  pageStore: WellsPageStore;
};

export const DeleteWellsModal = observer(function DeleteWellsModal({ pageStore }: Props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  function onCloseDeleteWellsModal(): void {
    pageStore.setIsDeleteWellsModalOpened(false);
  }

  async function onConfirmDeleteWells(): Promise<void> {
    setIsLoading(true);

    await pageStore.deleteWells();

    setIsLoading(false);
  }

  return (
    <Modal isOpened={pageStore.isDeleteWellsModalOpened} className={styles.modal} closeOnClickOutside={false}>
      <DeleteImg className={styles.image} />
      <h2 className={styles.title}>{t('modals:DeleteWells.title')}</h2>
      <div className={styles.buttonsWrapper}>
        <Button variant="secondary" disabled={isLoading} onClick={onCloseDeleteWellsModal} className={styles.button}>
          {t('common:Buttons.cancel')}
        </Button>
        <Button
          variant="danger"
          prefixIcon={<TrashIcon />}
          isLoading={isLoading}
          onClick={onConfirmDeleteWells}
          className={styles.button}
        >
          {t('common:Buttons.delete')}
        </Button>
      </div>
    </Modal>
  );
});
