import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'src/shared/assets/icons/info-i.svg';
import { Button } from 'src/shared/components/button';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './resotre-approaches-to-directory-values-button.module.scss';

type Props = {
  onRestoreToDirectoryValues: VoidFunction;
};

export const RestoreApproachesToDirectoryValuesButton = observer(function RestoreApproachesToDirectoryValuesButton({
  onRestoreToDirectoryValues,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Button variant="primary" onClick={onRestoreToDirectoryValues}>
        {t('newWellForm:Approaches.restoreToDirectoryValues')}
      </Button>
      <Tooltip text={t('newWellForm:Approaches.restoreToDirectoryValuesTooltipText')}>
        <button className={styles.infoIconButton}>
          <InfoIcon width={20} height={20} className={styles.infoIcon} />
        </button>
      </Tooltip>
    </div>
  );
});
