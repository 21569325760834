import { Field } from 'src/shared/entities/abstract-control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TFieldRaw, TSavedFormValue } from '../../types';

import { mapIntegerFieldItem } from './map-integer-field-item';
import { mapNumberFieldItem } from './map-number-field-item';
import { mapTextFieldItem } from './map-text-field-item';

type TMapFieldItemOprions = {
  item: TFieldRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapFieldItem = ({
  item,
  directories,
  savedValue,
}: TMapFieldItemOprions): Field<string | number | null> => {
  switch (item.type) {
    case 'String':
      return mapTextFieldItem({ item, directories, savedValue });
    case 'Number':
      return mapNumberFieldItem({ item, directories, savedValue });
    case 'Integer':
      return mapIntegerFieldItem({ item, directories, savedValue });
    default: {
      console.error('Unable to recognize the item', item);
      throw new Error('Unable to recognize the item');
    }
  }
};
