import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { Range } from '../../../../layers/model';
import { useMovableElement } from '../../../../shared/use-movable-element';
import { Viewport } from '../../../../shared/viewport/viewport';
import { getPositiveOffsetPx } from '../../../../shared/viewport/viewport-calculator';

import styles from './data-header.module.scss';

export interface Props {
  item: { x?: Range<number | null> | null; y?: Range<number>; isCollapsed?: boolean };
  verticalViewport: Viewport;
  className?: string;
}

export const EmptyDataHeader = observer(function EmptyDataHeader({
  item,
  verticalViewport,
  className,
  children,
}: PropsWithChildren<Props>) {
  const ref = useMovableElement({
    item,
    verticalViewport,
    getOffsetY: item.isCollapsed === false ? getPositiveOffsetPx : undefined,
  });

  return <div ref={ref} className={clsx(className, styles.rowHeaderWrapper)} children={children} />;
});
