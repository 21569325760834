import { WellRigOperation } from '../../drilling-rigs-chart/entities';

export class ComparingWellRigOperation extends WellRigOperation {
  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-comparing-well-${this.id}`;
    }

    return `comparing-well-${this.id}`;
  }

  get hasChanges(): boolean {
    const hasChanges = this.data?.hasChanges;

    return typeof hasChanges === 'boolean' && hasChanges;
  }
}
