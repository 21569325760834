import { observer } from 'mobx-react-lite';

import { ChartRig } from '../../../presets/drilling-rigs-chart/entities';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';
import { SummaryViewProvider } from '../../../shared/summary-view-provider';
import { useMovableContainer } from '../../../shared/use-movable-container';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingRigsChartDataModel } from '../../comparing-rigs-chart/data';

import { SummaryDataHeader } from './summary-data-header';
import { SummaryDataItem } from './summary-data-item';

import styles from './summary-data.module.scss';

interface Props {
  data: (IRigSummary | null)[] | null;
  backgroundItems: ComparingRigsChartDataModel.ViewItem[] | null;
  viewport: Viewport;
  viewProvider: SummaryViewProvider;
  onScroll(offset: number): void;
}

export const ComparingSummaryData = observer(function ComparingSummaryData({
  data,
  backgroundItems,
  viewport,
  onScroll,
  viewProvider,
}: Props) {
  const { containerRef, handleScroll } = useMovableContainer({ onScroll });

  return (
    <div
      ref={containerRef}
      style={{
        '--summary-columns-number': viewProvider.headerColumns.length,
        '--summary-columns-width': viewProvider.columnsWidthPercentage.toString().concat('%'),
      }}
      className={styles.container_comparing}
      onWheel={handleScroll}
    >
      <SummaryDataHeader titles={viewProvider.headerColumns} />

      {backgroundItems?.map((item) => {
        if (item instanceof ChartRig) {
          return (
            <SummaryDataItem
              key={item.getKey()}
              data={data}
              backgroundItem={item}
              viewport={viewport}
              viewProvider={viewProvider}
            />
          );
        }

        return null;
      })}
    </div>
  );
});
