import { TFunction } from 'i18next';
import React from 'react';

import { TableCell } from 'src/features/planning/views/plan-table/table-cell';
import { GROUP_TYPE } from 'src/pages/plan-page/types';
import { ButtonTableCell } from 'src/shared/components/table-cell/button-table-cell';
import { assert } from 'src/shared/utils/assert';
import { Directories } from 'src/store/directories/directories.store';
import { TableRow, ColumnType, AntColumnsType } from 'src/store/table/types';
import { getCellValue } from 'src/store/table/utils';

export function getColumns(columns: ColumnType[], isEditing: boolean, groupType: GROUP_TYPE): AntColumnsType[] {
  const isDraggable = isEditing && groupType === GROUP_TYPE.rig;

  return [
    ...(isDraggable ? [{ title: '', dataIndex: 'dragHandler', key: 'dragHandler', width: 40 }] : []),
    ...columns
      .filter((column) => column.isShown)
      .map((column, index) => {
        return {
          index: index,
          title: column.label,
          dataIndex: column.id,
          key: column.id,
          width: column.width,
          ellipsis: true,
          control: column.control,
          attrName: column.name,
          objectType: column.objectType,
          objectField: column.objectField,
          excludeFromSettings: column.excludeFromSettings,
        };
      }),
    {
      title: '',
      dataIndex: 'detailed',
      key: 'detailed',
      width: 100,
    },
  ];
}

export function getTableData(
  columnsDataSource: ColumnType[],
  tableDataSource: TableRow[],
  directories: Directories,
  isResize: boolean,
  onMoreInfoClick: (wellId: number) => void,
  rowIdKey: string | null,
  t: TFunction,
  isImportOccurred: boolean
): Record<string, React.ReactNode>[] {
  return tableDataSource.map((row, index): Record<string, React.ReactNode> => {
    const handleMoreInfoClick = (): void => {
      // Hardcode-attr
      const geologicalTaskId = getCellValue(row['GOplan_GeologicalTask.id']);

      assert(typeof geologicalTaskId === 'number');

      onMoreInfoClick(geologicalTaskId);
    };

    const uniqueRowKey = rowIdKey ? row[rowIdKey] : index;

    return {
      key: uniqueRowKey?.toString(),
      ...columnsDataSource.reduce<Record<string, React.ReactNode>>(function (target, column) {
        target[column.id] = (
          <TableCell
            row={row}
            column={column}
            directories={directories}
            isResize={isResize}
            isImportOccurred={isImportOccurred}
          />
        );
        return target;
      }, {}),
      detailed: <ButtonTableCell buttonText={t('common:Buttons.moreDetailed')} onClick={handleMoreInfoClick} />,
    };
  });
}

export function isFunctionalColumn(columnName: string): boolean {
  return columnName !== 'dragHandler' && columnName !== 'detailed';
}

export function getTableWidth(columns: AntColumnsType[], containerWidth?: number): string {
  const sumColumnWidth = columns.reduce((sum, currentColumn) => sum + currentColumn.width, 0);

  if (containerWidth && containerWidth > sumColumnWidth) {
    return `${sumColumnWidth}px`;
  }

  return '100%';
}
