import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as PinIcon } from 'src/shared/assets/icons/pin.svg';

import styles from './collapse-header.module.scss';

interface Props {
  title: string;
  groupId: string;
  setIsFixed(groupId: string, isFixed: boolean): void;
  isWrapped?: boolean;
  isFixed?: boolean;
}

export const CollapseHeader = observer(function CollapseHeader({
  title,
  isWrapped,
  isFixed,
  setIsFixed,
  groupId,
}: Props) {
  function handleFixColumn(event: React.MouseEvent): void {
    event.stopPropagation();
    setIsFixed(groupId, !isFixed);
  }

  return (
    <div className={styles.header}>
      <p className={styles.headerText}>{title}</p>
      {isWrapped && (
        <button
          className={clsx(styles.headerFixButton, isFixed && styles.headerFixButtonFixed)}
          onClick={handleFixColumn}
        >
          <PinIcon />
        </button>
      )}
    </div>
  );
});
