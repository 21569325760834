import { toJS } from 'mobx';
import moment from 'moment';

import { TPlanVersion, PlanVersionType, TActualPlansGroups, TYearPlanGroup } from './types';

export const processActualData = (data: TPlanVersion[]): [TActualPlansGroups, TActualPlansGroups] => {
  const publicPlans: TActualPlansGroups = { type: 'actual', name: 'Compare.actualPublic', plans: [] };
  const personalPlans: TActualPlansGroups = { type: 'actual', name: 'Compare.actualPersonal', plans: [] };

  data.forEach((plan) => {
    if (PlanVersionType.personal === plan.data.versionType) {
      personalPlans.plans.push(plan);
    }

    if ([PlanVersionType.public, PlanVersionType.current].includes(plan.data.versionType)) {
      publicPlans.plans.push(plan);
    }
  });

  return [publicPlans, personalPlans];
};

export const addCreationDateToPlanName = (plan: TPlanVersion): TPlanVersion => {
  const dateString = moment(plan.createdAt).format('DD.MM.YYYY HH:mm');
  const cleanPlan = toJS(plan);
  cleanPlan.data.name = `${plan.data.name} (${dateString})`;
  return cleanPlan;
};

export const getVersionsYearGroupsWithMonths = <T extends 'archived' | 'analytics'>(
  type: T,
  data: TPlanVersion[]
): TYearPlanGroup<T>[] => {
  const processedData: TYearPlanGroup<T>[] = [];

  for (const plan of data) {
    const date = new Date(plan.createdAt);
    const year = date.getFullYear().toString();
    const month = `common:Months.month${date.getMonth()}`;
    if (!processedData.some((elem) => elem?.year === year)) {
      processedData.push({
        type,
        year,
        months: [
          {
            month,
            plans: [plan],
          },
        ],
      });
      continue;
    }

    const yearIndex = processedData.findIndex((elem) => elem.year === year);
    if (yearIndex === -1) {
      console.error('Unable to find an element by the specified index');
      continue;
    }

    if (!processedData[yearIndex]?.months.some((monthElem) => monthElem.month === month)) {
      processedData[yearIndex].months.push({
        month,
        plans: [plan],
      });
      continue;
    }

    const monthIndex = processedData[yearIndex].months.findIndex((elem) => elem.month === month);
    if (monthIndex === -1) {
      console.error('Unable to find an element by the specified index');
      continue;
    }

    processedData[yearIndex].months[monthIndex].plans.push(plan);
  }
  return processedData;
};
