import { flow, makeObservable } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { UserSettingsManager } from 'src/api/user-settings';
import { throttleFn } from 'src/shared/utils/throttle-fn';
import { RootStore } from 'src/store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { ChartGrouping } from '../../shared/chart-grouping';
import { RigsViewSettingsSidebarStore } from '../rigs-view-settings-sidebar';

import { RigsGroup } from './entities';
import { RigsChartView } from './rigs-chart-view';
import { rigGroupsToOrderSettings } from './rigs-chart.utils';
import { RigsViewSettingsProvider } from './rigs-view-settings-provider';

export class RigsViewSettingsStore {
  private readonly notifications: NotificationsStore;

  readonly view: RigsChartView;
  readonly sidebar: RigsViewSettingsSidebarStore;
  readonly manager: UserSettingsManager<ViewSettings.RigsChartRawSettingsValues>;
  readonly provider: RigsViewSettingsProvider;

  constructor(
    rootStore: RootStore,
    view: RigsChartView,
    manager: UserSettingsManager<ViewSettings.RigsChartRawSettingsValues>
  ) {
    this.notifications = rootStore.notifications;
    this.view = view;
    this.manager = manager;
    this.sidebar = new RigsViewSettingsSidebarStore(rootStore, this.manager, (sidebarSettingsValues) =>
      this.updateSidebarViewSettings(sidebarSettingsValues)
    );
    this.sidebar.setChartView(this.view);
    this.provider = new RigsViewSettingsProvider(this.view, rootStore.directories, rootStore.appSettings);

    makeObservable(this);
  }

  @flow.bound
  private async *updateSidebarViewSettings(infoSettingsValues: ViewSettings.InfoSettingsValues) {
    try {
      const allViewSettings: ViewSettings.RawSettingsValues = {
        ...this.view.ownSettingsValues,
        info: infoSettingsValues,
      };

      if (allViewSettings) {
        await this.manager.update(allViewSettings);
        yield;

        this.view.setSettingsValues(allViewSettings);
      }
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToUpdateChartSettings');
    }
  }

  @flow.bound
  private async *updateRigsAndGroupsSettings(rigGroups: RigsGroup[], grouping: ChartGrouping) {
    try {
      const rigsOrderSettings: ViewSettings.RigsOrderSettingsValues = rigGroupsToOrderSettings(rigGroups);

      const collectAllRigsOrderSettings = (): ViewSettings.RigsOrderSettingsValuesByGrouping[] => {
        if (!this.view.ownSettingsValues.rigsOrder?.length) {
          return [
            {
              grouping: grouping.value,
              settings: rigsOrderSettings,
            },
          ];
        }

        const allSettingsEntries: ViewSettings.RigsOrderSettingsValuesByGrouping[] =
          this.view.ownSettingsValues.rigsOrder.filter((entry) => entry.grouping !== grouping.value);

        allSettingsEntries.push({
          grouping: grouping.value,
          settings: rigsOrderSettings,
        });

        return allSettingsEntries;
      };

      const allViewSettings: ViewSettings.RigsChartRawSettingsValues = {
        info: this.view.infoSettingsValues,
        rigsOrder: collectAllRigsOrderSettings(),
      };

      if (allViewSettings) {
        await this.manager.update(allViewSettings);
        yield;

        this.view.setSettingsValues(allViewSettings);
      }
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToUpdateChartSettings');
    }
  }

  updateRigsAndGroupsSettingsThrottled = throttleFn((rigGroups: RigsGroup[], grouping: ChartGrouping) => {
    this.updateRigsAndGroupsSettings(rigGroups, grouping);
  }, 1000);

  init(): VoidFunction {
    const disposeProvider = this.provider.init();
    const disposeSidebar = this.sidebar.init();

    return () => {
      disposeProvider();
      disposeSidebar();
    };
  }
}
