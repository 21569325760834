import { action, computed, makeObservable } from 'mobx';

import { Tab, ValidatableItem } from '../../../shared/entities/abstract-control-entities';

import { TColumn, TColumnTabProps } from './types';

export class ColumnTab extends Tab {
  columns: TColumn[];

  constructor({ formElementRefId, columns }: TColumnTabProps) {
    super({ formElementRefId });
    this.columns = columns;

    makeObservable(this);
  }

  @computed
  get errorsCount(): number {
    let count = 0;
    for (let column of this.columns) {
      for (let item of column.items) {
        if (item instanceof ValidatableItem) {
          if (!!item.errorText) {
            count += 1;
          }
        }
      }
    }
    return count;
  }

  @action.bound
  validate() {
    this.columns.forEach((column) =>
      column.items.forEach((field) => {
        if (field instanceof ValidatableItem) {
          field.hasErrors();
        }
      })
    );
  }
}
