import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FullIcon } from 'src/shared/assets/icons/expand-icon.svg';
import { Button } from 'src/shared/components/button/button';

import styles from './comparing-indicators-table.module.scss';

interface Props {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

export const ComparingIndicatorsTableFooter = observer(function ComparingIndicatorsTableFooter({
  isExpanded,
  setIsExpanded,
}: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Indicators' });

  return (
    <div className={clsx(styles.buttonsContainer, isExpanded && styles.buttonsContainerExpanded)}>
      <Button
        variant="text"
        className={styles.button}
        icon={<FullIcon className={clsx(styles.collapseIcon, isExpanded && styles.collapseIconDown)} />}
        onClick={() => setIsExpanded((actual) => !actual)}
      >
        {isExpanded ? t('closeTable') : t('openTable')}
      </Button>
    </div>
  );
});
