import { action, computed, makeObservable, observable } from 'mobx';

import { TDictObject } from 'src/api/directories/types';
import { hasValue } from 'src/shared/utils/common';
import { getNumberWithNumberOfDecimalPlaces } from 'src/shared/utils/get-number-with-number-of-decimal-places';
import { isNumberArray } from 'src/shared/utils/is-number-array';
import { TRefQuery } from 'src/store/directories/types';

import { IRestrictions } from '../../../features/well-form/types';
import { TLabelValue, ValidatableItem } from '../abstract-control-entities';

type TLabelData = {
  formElementRefId?: string;
  label?: string;
  fieldId: string;
  placeholder?: string;
  nullValue?: string;
  directory: TDictObject[] | null;
  restrictions: IRestrictions;
  initialValue?: TLabelValue;
  refObjectAttr?: string;
  numberOfDecimalPlaces?: number;
  refObjectType?: string;
  refQuery?: TRefQuery;
  type: 'String' | 'Number' | 'Integer';
};

export class Label extends ValidatableItem<TLabelValue | number | number[] | null> {
  @observable initialValue: TLabelValue;
  @observable controlValue: TLabelValue;
  @observable noPlaceholders: boolean = false;

  nullValue: TLabelValue;
  readonly directory: TDictObject[] | null;
  readonly refObjectAttr?: string;
  readonly refObjectType?: string;
  readonly numberOfDecimalPlaces?: number;
  readonly refQuery?: TRefQuery;
  readonly type: 'String' | 'Number' | 'Integer';

  constructor(data: TLabelData) {
    super(data);
    this.type = data.type;
    this.directory = data.directory;
    this.refObjectAttr = data.refObjectAttr;
    this.refObjectType = data.refObjectType;
    this.refQuery = data.refQuery;
    this.numberOfDecimalPlaces = data.numberOfDecimalPlaces;
    this.initialValue = {
      label: data.placeholder || data.nullValue || '-',
      value: null,
    };
    this.controlValue = data.initialValue || this.initialValue;
    this.nullValue = {
      label: data.nullValue || data.placeholder || '-',
      value: null,
    };
    makeObservable(this);
  }

  private preprocessValue(value: TLabelValue | null): TLabelValue | null {
    if (!value) {
      return null;
    }

    const isValueInteger = this.type === 'Integer';

    if ((hasValue(this.numberOfDecimalPlaces) || isValueInteger) && !Number.isNaN(Number(value.label))) {
      const labelValue = getNumberWithNumberOfDecimalPlaces(
        value.label,
        isValueInteger,
        this.numberOfDecimalPlaces
      )?.toString();

      if (!labelValue) {
        return value;
      }

      return {
        label: labelValue,
        value: value.value,
      };
    }

    return value;
  }

  @action.bound
  setNoPlaceholders(): void {
    this.noPlaceholders = true;

    if (this.controlValue.label === this.initialValue.label) {
      this.setValue({
        label: '',
        value: null,
      });
    }

    this.nullValue = {
      label: '',
      value: null,
    };
  }

  @computed
  get value(): number[] | number | null {
    return this.controlValue.value;
  }

  @action.bound
  clearItem(): void {
    this.controlValue = this.initialValue;
  }

  @action.bound
  setValue(value: TLabelValue | null, setValueAsInitial?: boolean): void {
    this.clearError();

    const preprocessedValue = this.preprocessValue(value);

    if (!preprocessedValue) {
      this.controlValue = this.nullValue;
      return;
    }
    this.controlValue = preprocessedValue;

    if (setValueAsInitial) {
      this.setInitialValue(preprocessedValue);
    }
  }

  @action.bound
  setInitialValue(value: TLabelValue | null): void {
    if (value === null) {
      return;
    }
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.controlValue = this.initialValue;
  }

  @action.bound
  private trySetDirectoryValue(value: number, setValueAsInitial?: boolean): boolean {
    if (!this.directory || !this.refObjectAttr) {
      return false;
    }

    const directory = this.directory.find((dir) => dir.id === value);

    if (!directory) {
      return false;
    }

    const dirValue = directory.data[this.refObjectAttr];

    if (!dirValue) {
      return false;
    }

    this.setValue(
      {
        label: dirValue.toString(),
        value: value,
      },
      setValueAsInitial
    );

    return true;
  }

  @action.bound
  trySetDirectoryValuesListValue(values: unknown[], setValueAsInitial?: boolean): boolean {
    const refObjAttr = this.refObjectAttr;
    const directory = this.directory;

    if (!isNumberArray(values) || !directory || !refObjAttr) {
      return false;
    }

    const directories = values.map((value) => directory.find((dir) => dir.id === value) || null);

    const dirValues = directories.map((dir) => dir?.data[refObjAttr] || '-');

    this.setValue({
      label: dirValues.join(', '),
      value: values,
    });

    return true;
  }

  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (!hasValue<NonNullable<unknown>>(value)) {
      this.setValue(null, setValueAsInitial);
      return true;
    }

    if (Array.isArray(value)) {
      const isDirectoryValuesListSat = this.trySetDirectoryValuesListValue(value, setValueAsInitial);

      if (isDirectoryValuesListSat) {
        return true;
      } else {
        return false;
      }
    }

    if (typeof value === 'number' || (typeof value === 'string' && !Number.isNaN(Number(value)))) {
      const isDirectoryValueSat = this.trySetDirectoryValue(Number(value), setValueAsInitial);

      if (!isDirectoryValueSat) {
        const newValue = {
          label: value.toString(),
          value: Number(value),
        };

        this.setValue(newValue, setValueAsInitial);
      }
      return true;
    }

    if (typeof value === 'string' && Number.isNaN(Number(value))) {
      const newValue = {
        label: value,
        value: null,
      };

      this.setValue(newValue, setValueAsInitial);
      return true;
    }

    if (typeof value !== 'object') {
      return false;
    }

    const objectValue: { label?: unknown; value?: unknown } = value;
    const RawValue = objectValue.value;

    if (typeof objectValue.label === 'string') {
      if (typeof RawValue === 'string' && !isNaN(Number(RawValue))) {
        const parsedValue = { label: objectValue.label, value: Number(RawValue) };
        this.setValue(parsedValue, setValueAsInitial);
        return true;
      }
      if (typeof RawValue === 'number' || RawValue === null) {
        const newValue = { label: objectValue.label, value: RawValue };

        this.setValue(newValue, setValueAsInitial);
        return true;
      }
    }
    return false;
  }

  checkIsReady(): boolean {
    return !!this.errorText;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  hasErrors(): boolean {
    if (!this.value && this.restrictions.required) {
      this.errorText = 'newWellForm:Errors.required';
    }
    return !!this.errorText;
  }

  @action.bound
  setDirectoryValue(directoryValueId: number | null) {
    const directoryValue = this.directory?.find((value) => value.id === directoryValueId);
    if (!directoryValue || !this.refObjectAttr) return;
    this.controlValue = {
      label: directoryValue.data[this.refObjectAttr].toString(),
      value: directoryValueId,
    };
  }
}
