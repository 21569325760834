import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { PadAttributeIcon as PadAttributeIconType } from 'src/shared/constants/pad-attribute-icon';

import styles from './pad-collapse-header.module.scss';

interface Props {
  title: string;
  attributeIcons: PadAttributeIconType[] | null;
}

export const PadCollapseHeader = observer(function PadCollapseHeader({ title, attributeIcons }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{title}</p>

        <div className={styles.attributes}>
          {attributeIcons?.map((attribute) => (
            <PadAttributeIcon key={attribute} iconName={attribute} className={styles.attributeIcon} />
          ))}
        </div>
      </div>
    </div>
  );
});
