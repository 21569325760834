import { isAxiosError } from 'src/api/utils';
import { BaseApiError, ValidationError } from 'src/errors';

const IGNOREED_REQUESTS_URL_BY_SENTRY: string[] = ['/validate'];

export const shouldWeSendThisErrorToSentry = (error: unknown): boolean => {
  if (isAxiosError(error)) {
    if (error.request instanceof XMLHttpRequest) {
      for (const url of IGNOREED_REQUESTS_URL_BY_SENTRY) {
        if (error.request.responseURL.includes(url)) {
          return false;
        }
      }
    }
  }
  if (error instanceof ValidationError) {
    return false;
  }
  if (error instanceof BaseApiError && error.axiosError) {
    const axiosError = error.axiosError;
    if (axiosError instanceof XMLHttpRequest) {
      for (const url of IGNOREED_REQUESTS_URL_BY_SENTRY) {
        if (axiosError.responseURL.includes(url)) {
          return false;
        }
      }
    }
  }
  return true;
};
