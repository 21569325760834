import * as Sentry from '@sentry/react';
import axios from 'axios';

import { API_URL } from 'src/shared/constants/api';
import { getLocale } from 'src/shared/utils/languages';

import { getAccessToken } from '../shared/utils/auth';
import { UserService } from '../store/auth/user-service';

const agent = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

agent.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      const accessToken = await UserService.updateToken();

      if (error?.config && accessToken) {
        return axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

agent.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  config.headers = { ...config.headers };
  config.headers['accept-language'] = getLocale();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

export { agent };
