import { hasValue } from './common';

export class RequestsQueueController {
  private intervalId: number | null = null;
  private requests: (() => Promise<unknown>)[] = [];
  private _isExecutionBlocked = false;

  constructor() {
    this.intervalId = window.setInterval(() => {
      if (this.requestsPresented && !this.isExecutionBlocked) {
        this.executeRequests();
      }
    }, 100);
  }

  private get requestsPresented(): boolean {
    return !!this.requests.length;
  }

  private get isExecutionBlocked(): boolean {
    return this._isExecutionBlocked;
  }

  private async executeRequests(): Promise<void> {
    this._isExecutionBlocked = true;
    const requests = [...this.requests];
    this.requests = [];

    for (const callback of requests) {
      await callback();
    }

    this._isExecutionBlocked = false;
  }

  async makeRequest<T>(request: () => Promise<T>): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      const callback = async () => {
        try {
          const res = await request();
          resolve(res);
        } catch (e) {
          reject(e);
        }
      };

      this.requests.push(callback);
    });

    return promise;
  }

  killController() {
    if (hasValue(this.intervalId)) {
      clearInterval(this.intervalId);
    }
  }
}
