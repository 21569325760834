import { action, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { IModel, Range } from '../../../layers/model';

import { TimelineDataSource } from './timeline-data-source';

export class TimelineDataModel implements IModel<TimelineDataModel.ITimelineItem[]> {
  @observable private dataRange: Range<number>;

  @observable data?: TimelineDataModel.ITimelineItem[];

  constructor(initialViewRange: Range<number>) {
    this.data = TimelineDataSource.generateTimelineYears(initialViewRange.start, initialViewRange.end);

    const start = this.data[0]?.x.start;
    const end = this.data[this.data.length - 1]?.x.end;

    this.dataRange = {
      start,
      end,
    };

    makeObservable(this);
  }

  private downloadData({ start, end }: Range<number>) {
    if (start === 0 && end === 0) {
      return;
    }

    this.data = TimelineDataSource.generateTimelineYears(start, end);

    const dataStart = this.data[0]?.x.start;
    const dataEnd = this.data[this.data.length - 1]?.x.end;

    if (!dataStart || !dataEnd) {
      throw new Error('Error generating timeline data: No start or end');
    }

    this.dataRange = {
      start: dataStart,
      end: dataEnd,
    };
  }

  @action.bound
  setRange(start: number, end: number) {
    this.downloadData({ start, end });
  }

  getData = computedFn((): TimelineDataModel.ITimelineItem[] => {
    return this.data || [];
  });
}

export namespace TimelineDataModel {
  export interface ITimelineItem {
    x: Range<number>;
    toString(): string;
  }

  export interface ITimelineApi {
    getDataBoundaries(): Range<number>;
  }
}
