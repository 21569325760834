import { SidebarSettingsColumnsGroupType } from 'src/features/wells-list/view-settings-sidebar/types';
import { UserSettingsColumnsGroupType } from 'src/pages/wells-page/types';
import { hasValue } from 'src/shared/utils/common';
import { ColumnType } from 'src/store/table/types';

export function mapSidebarSettingsData(columnsData: ColumnType[]): SidebarSettingsColumnsGroupType[] {
  const userSettings: SidebarSettingsColumnsGroupType[] = [];

  columnsData.forEach((column) => {
    const currentColumnIndex = userSettings.findIndex((settingsColumn) => settingsColumn.id === column.columnsGroupId);

    if (currentColumnIndex === -1 && column.columnsGroupId) {
      userSettings.push({
        id: column.columnsGroupId,
        label: column.columnsGroupLabel || column.columnsGroupId,
        isShown: column.isShown,
        isWrapped: hasValue(column.children),
        ...(column.children && { isFixed: column.fixed, width: column.width }),
        innerColumns: column.children
          ? column.children.map((childColumn) => ({
              id: childColumn.id,
              label: childColumn.label,
              isFixed: childColumn.fixed,
              isShown: childColumn.isShown,
              excludeFromSettings: !!childColumn.excludeFromSettings,
            }))
          : [
              {
                id: column.id,
                label: column.label,
                isFixed: column.fixed,
                isShown: column.isShown,
                width: column.width,
                excludeFromSettings: !!column.excludeFromSettings,
              },
            ],
      });
    }

    if (currentColumnIndex !== -1 && column.columnsGroupId) {
      userSettings[currentColumnIndex].innerColumns.push({
        id: column.id,
        label: column.label,
        isFixed: column.fixed,
        isShown: column.isShown,
        width: column.width,
        excludeFromSettings: !!column.excludeFromSettings,
      });
    }
  });

  return userSettings;
}

export function serializeSidebarSettingsData(
  settingsData: SidebarSettingsColumnsGroupType[]
): UserSettingsColumnsGroupType[] {
  return settingsData.map((columnsGroup) => {
    return {
      id: columnsGroup.id,
      isShown: columnsGroup.isShown,
      isWrapped: columnsGroup.isWrapped,
      isFixed: columnsGroup.isFixed,
      width: columnsGroup.width,
      innerColumns: columnsGroup.innerColumns.map((innerColumn) => {
        return {
          id: innerColumn.id,
          isFixed: innerColumn.isFixed,
          isShown: innerColumn.isShown,
          width: innerColumn.width,
        };
      }),
    };
  });
}
