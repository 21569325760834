export enum PlanVersionType {
  public = 'PUBLIC_DRAFT',
  personal = 'PRIVATE_DRAFT',
  draft = 'TEMP_DRAFT',
  current = 'CURRENT_VERSION',
}

export type TPlanVersionData = {
  name: string;
  availableForDashboardLite?: boolean;
  versionType: PlanVersionType;
  isBlocked: boolean;
  blockUserId: string | null;
  blockedAt: number | null;
  parentVersionId: number | null;
  isArchive?: boolean;
};

export type TPlanVersion = {
  id: number;
  objectType: string;
  data: TPlanVersionData;
  createdAt: number;
};

export type TActualPlansGroups = {
  type: 'actual';
  name: string;
  plans: TPlanVersion[];
};

export type TYearPlanGroup<T extends 'archived' | 'analytics'> = {
  type: T;
  year: string;
  months: {
    month: string;
    plans: TPlanVersion[];
  }[];
};

export type TArchivedPlansGroup = TYearPlanGroup<'archived'> & {
  type: 'archived';
};

export type TAnalyticsPlansGroup = TYearPlanGroup<'analytics'> & {
  type: 'analytics';
};
