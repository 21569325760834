import { Chart as Highchart, Point, Series } from 'highcharts';

import i18n from 'src/i18n';
import { THEMES } from 'src/shared/constants/themes';
import { showErrorMessage } from 'src/shared/utils/messages';

import { Chart } from '../../features/chart/chart';

import { ColumnLabelsDrawer } from './column-labels-drawer';
import LabelsMatrix from './labels-matrix';
import { LabelsPlacement } from './types';

export namespace LabelsController {
  const chartTopMargin = 40;

  function sortPointsVertically<T extends { position: { y: number } }>(points: T[]) {
    return points.sort((first, second) => Number(first.position.y) - Number(second.position.y));
  }

  function groupSeriesByType(series: Series[]): Record<string, Series[]> {
    return series.reduce((groups: Record<string, Series[]>, singleSeries: Series) => {
      const type = singleSeries.type;

      return {
        ...(groups || {}),
        [type]: [...(groups[type] || []), singleSeries],
      };
    }, {});
  }

  function groupPointsByCategory(
    series: Series[],
    range?: { min: number; max: number }
  ): LabelsPlacement.GroupedPoints {
    return series.reduce((groups: LabelsPlacement.GroupedPoints, currentSeries: Series) => {
      if (currentSeries?.visible && currentSeries.xAxis && currentSeries.yAxis) {
        currentSeries.data
          .slice(Math.round(range?.min || 0), Math.ceil((range?.max || currentSeries.data.length) + 1))
          .forEach((currentData: Point) => {
            const point: LabelsPlacement.PointWithPosition = {
              point: currentData,
              position: {
                x: currentSeries.xAxis.toPixels(currentData.x, true),
                y: currentSeries.yAxis.toPixels(Number(currentData.y), true),
              },
            };

            if (groups?.[currentData.category]) {
              groups[currentData.category].push(point);
            } else {
              groups[currentData.category] = [point];
            }
          });
      }

      return groups;
    }, {});
  }

  function drawLabelsAround(
    groupedSeries: LabelsPlacement.GroupedPoints,
    plotLeft: number,
    plotWidth: number,
    plotHeight: number,
    plotTop: number,
    drawer: LabelsPlacement.Drawer,
    unavailablePositions?: LabelsPlacement.Position[]
  ): void {
    const labelOffsetTop = 40;
    const labelOffsetBottom = 20;

    const isSingleSeries = Number(Object.values(groupedSeries)[0]?.length) === 1;
    const allPoints = Object.values(groupedSeries).flat();
    const categoryGroups = isSingleSeries ? [allPoints] : Object.values(groupedSeries);

    const matrix = new LabelsMatrix([plotTop, plotWidth + plotLeft, plotHeight + plotTop, plotLeft]);

    if (unavailablePositions) {
      matrix.setUnavailablePositions(
        unavailablePositions.map((point) => ({ ...point, x: point.x + chartTopMargin })),
        [
          [false, false, false, false],
          [false, false, false, false],
          [true, true, true, true],
          [true, true, true, true],
          [true, true, true, true],
          [true, true, true, true],
        ]
      );
    }

    // TODO: set line coordinates as unavailable
    // matrix.setUnavailablePositions();

    categoryGroups.forEach((points) => {
      const sortedPoints = sortPointsVertically(points);

      sortedPoints.forEach((positionedPoint, index) => {
        if (!positionedPoint.point.y?.toString()) return;

        const simplePoint = {
          position: positionedPoint.position,
          label: positionedPoint.point.y.toString() || '',
          color: positionedPoint.point.color?.toString(),
          isTop: isSingleSeries ? false : index === 0,
          isBottom: isSingleSeries ? false : index === sortedPoints.length - 1,
        };

        const yPosition = simplePoint.isBottom
          ? simplePoint.position.y + labelOffsetBottom
          : simplePoint.position.y - labelOffsetTop;

        const labelPoint = {
          ...simplePoint,
          position: {
            ...simplePoint.position,
            y: yPosition > plotHeight ? plotHeight : yPosition < 0 ? 0 : yPosition,
          },
          parentPosition: simplePoint.position,
        };

        matrix.setUnavailablePositions(simplePoint.position);
        matrix.addPoint(labelPoint);
      });
    });

    matrix.matrix.forEach((row, rowNumber) => {
      row.forEach((cell, columnNumber) => {
        if (cell.isBusy && cell.point) {
          const { x, y } = cell.alignedPosition || matrix.toRealCoordinates(columnNumber, rowNumber);

          drawer.drawLine(
            x,
            y,
            cell.point.parentPosition.x + plotLeft,
            cell.point.parentPosition.y + plotTop,
            cell.point.color
          );
          drawer.drawLabel(
            cell.point.label || '',
            x,
            y,
            cell.point.color,
            LabelsPlacement.LINE_DATA_LABELS_FONT_SIZE,
            true
          );
        }
      });
    });
  }

  function drawLabelsByBars(
    groupedSeries: LabelsPlacement.GroupedPoints,
    plotLeft: number,
    plotWidth: number,
    drawer: LabelsPlacement.Drawer,
    theme: THEMES,
    plotHeight: number
  ): LabelsPlacement.PointWithParent[] {
    const placedLabels = ColumnLabelsDrawer.placeLabelsInStackedBars(groupedSeries, plotLeft, plotWidth, plotHeight);

    placedLabels.forEach(({ point, parent }) => {
      const {
        position: { x },
        style,
      } = point;
      const { shapeArgs } = point.point;

      const y1 = point.position.y - LabelsPlacement.BAR_LABEL_OFFSET;
      const y2 = parent.position.y;

      const label = `${point.point.y?.toString()}%`;
      const defaultColor =
        theme === THEMES.dark ? LabelsPlacement.DEFAULT_LABEL_COLOR_LIGHT : LabelsPlacement.DEFAULT_LABEL_COLOR_DARK;
      const color = typeof point?.point?.color === 'string' ? point?.point?.color : defaultColor;
      const newY2 = shapeArgs
        ? plotHeight - (shapeArgs.x + shapeArgs.width) + LabelsPlacement.BAR_VERTICAL_LINE_OFFSET
        : y2;

      drawer.drawVerticalLine(x, y1, newY2, color);
      drawer.drawLabel(label || '', x, y1, color, style?.fontSize);
    });

    return placedLabels;
  }

  function drawLabelsByRows(
    groupedSeries: LabelsPlacement.GroupedPoints,
    plotLeft: number,
    plotWidth: number,
    drawer: LabelsPlacement.Drawer,
    theme: THEMES
  ): LabelsPlacement.PointWithParent[] {
    const placedLabels = ColumnLabelsDrawer.placeLabelsInRows(groupedSeries, plotLeft, plotWidth);

    placedLabels.forEach(({ point, parent }) => {
      const {
        position: { x },
        style,
      } = point;

      const y1 = point.position.y;
      const y2 = parent.position.y;

      const label = point.point.y?.toString();
      const defaultColor =
        theme === THEMES.dark ? LabelsPlacement.DEFAULT_LABEL_COLOR_LIGHT : LabelsPlacement.DEFAULT_LABEL_COLOR_DARK;
      const color = typeof point?.point?.color === 'string' ? point?.point?.color : defaultColor;

      drawer.drawVerticalLine(x, y1, y2, color);
      drawer.drawLabel(label || '', x, Math.min(y1, y2), color, style?.fontSize);
    });

    return placedLabels;
  }

  export function drawLabels(chart: Highchart, drawer: LabelsPlacement.Drawer, theme: THEMES): void {
    const chartTypesWeight: Partial<{ [key in Chart.Type]: number }> = {
      [Chart.Type.bar]: 2,
      [Chart.Type.column]: 1,
      [Chart.Type.line]: 0,
    };

    const sortedTypeGroups = Object.entries(groupSeriesByType(chart.series)).sort(
      ([first], [second]) =>
        (chartTypesWeight[second as Chart.Type] || 0) - (chartTypesWeight[first as Chart.Type] || 0)
    );

    const previouslyPlacedPoints: LabelsPlacement.PointWithParent[] = [];

    try {
      for (const [type, typeGroup] of sortedTypeGroups) {
        const groupedSeries = groupPointsByCategory(typeGroup, {
          min: chart.xAxis[0]?.min || 0,
          max: chart.xAxis[0]?.max || 0,
        });

        switch (type) {
          case Chart.Type.bar:
            drawLabelsByBars(groupedSeries, chart.plotLeft, chart.plotWidth, drawer, theme, chart.plotHeight);

            break;
          case Chart.Type.column:
            const placedPoints = drawLabelsByRows(groupedSeries, chart.plotLeft, chart.plotWidth, drawer, theme);

            previouslyPlacedPoints.push(...placedPoints);
            break;
          case Chart.Type.line:
            const unavailablePositions: LabelsPlacement.Position[] = [];

            previouslyPlacedPoints.forEach(({ point, parent }) => {
              unavailablePositions.push(
                { x: point.position.x - chart.plotLeft, y: point.position.y - chart.plotTop },
                {
                  ...parent.position,
                  x: parent.position.x - chart.plotLeft,
                }
              );
            });

            drawLabelsAround(
              groupedSeries,
              chart.plotLeft,
              chart.plotWidth,
              chart.plotHeight,
              chart.plotTop,
              drawer,
              unavailablePositions
            );
        }
      }
    } catch (e) {
      console.error(e);
      showErrorMessage(i18n.t('errors:failedToRenderLabels'));
    }
  }
}
