import { observer } from 'mobx-react-lite';

import {
  DraggingWellRigOperationWrapper,
  GhostWellRigOperationWrapper,
} from '../../../components/well-rig-operation-wrapper';
import { LoadingRigOperations, PadRigOperation, WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { DraggingPadRigOperationWrapper, GhostPadRigOperationWrapper } from '../../pad-rig-operation-wrapper';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';

import { LoadingRigOperationsWrapper } from './loading-rig-operations-wrapper';

interface Props {
  item: RigsChartStore.ViewItem;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick(padId: number, rigId: number): void;
  onWellClick(wellId: string | number): void;
}

export const RigsDataItem = observer(function RigsDataItem({
  item,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  onWellClick,
  viewSettingsProvider,
}: Props) {
  if (item instanceof PadRigOperation) {
    return (
      <DraggingPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        onClick={() => onPadClick(item.id, item.parentRig.id)}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof WellRigOperation) {
    return (
      <DraggingWellRigOperationWrapper
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        item={item}
        viewSettingsProvider={viewSettingsProvider}
        onClick={() => onWellClick(item.id)}
      />
    );
  }

  if (item instanceof LoadingRigOperations) {
    return (
      <LoadingRigOperationsWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (RigsSorting.isPadGhost(item)) {
    return (
      <GhostPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (RigsSorting.isWellGhost(item)) {
    return (
      <GhostWellRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  return null;
});
