import { observer } from 'mobx-react-lite';

import { GroupCollapseHeader } from 'src/features/drilling-chart/features/group-collapse-header';
import { DraggingGroupCollapseHeader } from 'src/features/drilling-chart/features/group-collapse-header/dragging-group-collapse-header';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import {
  ChartRig,
  RigsGroup,
  TemporaryChartRig,
  TemporaryRigsGroup,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { GhostGroupCollapseHeader } from '../../group-collapse-header/ghost-group-collapse-header';
import { RigsChartDataModel } from '../../rigs-chart/data/rigs-chart-data-model';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';

import { GhostRig } from './ghost-rig';
import { Rig } from './rig';
import { RowHeaderWrapper } from './row-header-wrapper';

import styles from './data-headers.module.scss';

interface Props {
  item: RigsChartStore.ViewItem;
  viewport: Viewport;
  isCompactView: boolean;
  viewSettingsProvider: RigsViewSettingsProvider;
  onGroupClick(item: RigsChartDataModel.IChartRigsGroup): void;
  onRowHeaderClick?(id: number): void;
}

export const RigsDataHeader = observer(function RigsDataHeader({
  item,
  viewport,
  isCompactView,
  onGroupClick,
  onRowHeaderClick,
  viewSettingsProvider,
}: Props) {
  if (item instanceof ChartRig) {
    return (
      <Rig
        item={item}
        verticalViewport={viewport}
        isCompactView={isCompactView}
        viewSettingsProvider={viewSettingsProvider}
        onClick={onRowHeaderClick}
      />
    );
  }

  if (item instanceof TemporaryChartRig) {
    return (
      <RowHeaderWrapper
        item={item}
        viewport={viewport}
        isCompactView={isCompactView}
        title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
        descriptionAttributes={viewSettingsProvider.getDescription(item)}
        className={styles.temporaryRigHeader}
        onClick={onRowHeaderClick}
      />
    );
  }

  if (item instanceof TemporaryRigsGroup) {
    return (
      <GroupCollapseHeader
        item={item}
        isCollapsed={item.isCollapsed}
        title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
        viewport={viewport}
        onClick={() => onGroupClick(item)}
        withArrow
      />
    );
  }

  if (item instanceof RigsGroup) {
    return (
      <DraggingGroupCollapseHeader
        item={item}
        isCollapsed={item.isCollapsed}
        title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
        viewport={viewport}
        onIsCollapsedToggle={() => onGroupClick(item)}
        withArrow
      />
    );
  }

  if (RigsSorting.isRigsGroupGhost(item)) {
    const ghostWrapper = item;

    return (
      <GhostGroupCollapseHeader
        item={ghostWrapper}
        isCollapsed={ghostWrapper.ghost.isCollapsed}
        title={<LoadingTitle field={viewSettingsProvider.getTitle(ghostWrapper.ghost)} />}
        viewport={viewport}
      />
    );
  }

  if (RigsSorting.isRigGhost(item)) {
    return (
      <GhostRig
        item={item}
        verticalViewport={viewport}
        isCompactView={isCompactView}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  return null;
});
