import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import moment from 'moment/moment';

import ComparingIndicatorsAdapter from 'src/api/chart/comparing-indicators-adapter';
import { ComparingIndicators, ComparingIndicatorsApi } from 'src/api/chart/comparing-indicators-api';
import { timeUnitToGranularity } from 'src/features/drilling-chart/shared/data-view/trend-granularity';
import { DATE_SERVER_FORMAT } from 'src/shared/constants/date';
import { isTrendType, TrendType } from 'src/shared/constants/trend-type';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { LoadingField } from '../../../../../shared/utils/loading-field';
import { Range } from '../../../layers/model';
import { ComparingIndicatorColumn } from '../../../presets/comparing-indicators-table/entities';
import { generateKeyFromRange, StorageKeyManager } from '../../../shared/storage-key-manager';
import { TimeUnit } from '../../../shared/time-unit';
import { getTimeUnit } from '../../../shared/viewport/viewport-calculator';

import { ComparingIndicatorsStorage } from './comparing-indicators-storage';

export class ComparingIndicatorsDataModel {
  private readonly adapter = new ComparingIndicatorsAdapter();
  private readonly api = new ComparingIndicatorsApi();
  private readonly firstPlanVersionId: number;
  private readonly secondPlanVersionId: number;
  private readonly dataStorage: ComparingIndicatorsStorage;
  private readonly notifications: NotificationsStore;

  @observable private viewRange: Range<number>;
  @observable private filter?: Record<string, unknown>;

  constructor(
    initialViewRange: Range<number>,
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    notifications: NotificationsStore
  ) {
    this.viewRange = initialViewRange;
    this.firstPlanVersionId = firstPlanVersionId;
    this.secondPlanVersionId = secondPlanVersionId;
    this.notifications = notifications;

    const storageKeyManager = new StorageKeyManager<Range<number>, string>(generateKeyFromRange);
    this.dataStorage = new ComparingIndicatorsStorage(storageKeyManager);

    makeObservable(this);
  }

  @flow.bound
  private async *fetchData({ start, end }: Range<number>, timeUnit: TimeUnit, filters?: Record<string, unknown>) {
    try {
      const firstPlanVersionId = this.firstPlanVersionId;
      const secondPlanVersionId = this.secondPlanVersionId;

      const startString = moment.unix(start).format(DATE_SERVER_FORMAT);
      const endString = moment.unix(end).format(DATE_SERVER_FORMAT);

      const trendGranularity = timeUnitToGranularity(timeUnit);

      assert(hasValue(trendGranularity), 'Invalid trend granularity.');

      const rawIndicators = await this.api.getIndicators(
        startString,
        endString,
        trendGranularity,
        firstPlanVersionId,
        secondPlanVersionId,
        filters
      );
      yield;

      if (!rawIndicators) {
        return;
      }

      const indicators = this.adapter.initializeIndicators(
        rawIndicators,
        { start, end },
        timeUnit,
        [
          TrendType.passing,
          TrendType.commercialSpeed,
          TrendType.developmentCompleteWellsCount,
          TrendType.drillingCompleteWellsCount,
        ],
        firstPlanVersionId,
        secondPlanVersionId
      );

      this.dataStorage.set(indicators);
    } catch (e) {
      yield;
      console.error(e);

      this.notifications.showErrorMessageT('errors:failedToLoadIndicators');
    }
  }

  private fetchDataDebounced = debounce(
    (range: Range<number>, timeUnit: TimeUnit, filter?: Record<string, unknown>) => {
      this.fetchData(range, timeUnit, filter);
    },
    500
  );

  @computed
  private get indicatorsResult():
    | ComparingIndicators.ColumnValues
    | ComparingIndicators.LoadingColumnValues
    | undefined {
    const columns = this.dataStorage.data?.columns;
    const emptyRanges = this.dataStorage.data?.emptyRanges;

    if (emptyRanges?.length) {
      const getLoadingFieldsByTrendTypes = () =>
        Object.fromEntries(Object.entries(TrendType).map(([, indicatorName]) => [indicatorName, new LoadingField()]));

      return {
        first: getLoadingFieldsByTrendTypes(),
        second: getLoadingFieldsByTrendTypes(),
      };
    } else {
      return {
        first:
          columns?.reduce((totalColumn: Partial<Record<TrendType, number>>, column) => {
            for (const indicatorName of Object.values(TrendType)) {
              if (column instanceof ComparingIndicatorColumn && isTrendType(indicatorName)) {
                const value = column.firstVersionData[indicatorName] ?? 0;

                totalColumn[indicatorName] = this.adapter.roundValue(value + (totalColumn[indicatorName] ?? 0));
              }
            }

            return totalColumn;
          }, {}) || {},
        second:
          columns?.reduce((totalColumn: Partial<Record<TrendType, number>>, column) => {
            for (const indicatorName of Object.values(TrendType)) {
              if (column instanceof ComparingIndicatorColumn && isTrendType(indicatorName)) {
                const value = column.secondVersionData[indicatorName] ?? 0;

                totalColumn[indicatorName] = this.adapter.roundValue(value + (totalColumn[indicatorName] ?? 0));
              }
            }

            return totalColumn;
          }, {}) || {},
      };
    }
  }

  @action.bound
  init(): VoidFunction {
    const disposeEmptyDataFetching = reaction(
      () => ({
        emptyRanges: this.dataStorage.data?.emptyRanges,
        filter: this.filter,
      }),
      ({ emptyRanges, filter }) => {
        if (emptyRanges?.length) {
          for (const emptyColumn of emptyRanges) {
            this.fetchDataDebounced(emptyColumn, getTimeUnit(this.viewRange), filter);
          }
        }
      },
      { fireImmediately: true }
    );

    return () => {
      disposeEmptyDataFetching();
    };
  }

  getData = computedFn((): ComparingIndicators.ViewItems | null => {
    const columns = this.dataStorage.data;

    if (!this.indicatorsResult || !columns?.columns) {
      return null;
    }

    return {
      result: this.indicatorsResult,
      items: columns.columns,
    };
  });

  @action.bound
  async setRange(start: number, end: number) {
    this.viewRange = {
      start,
      end,
    };
    this.dataStorage.setRange(this.viewRange.start, this.viewRange.end, getTimeUnit(this.viewRange));
  }

  @action.bound
  setFilters(filters: Record<string, unknown>): void {
    this.filter = filters;
    this.dataStorage.clear();
  }
}
