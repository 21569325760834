import { autorun } from 'mobx';

import { Item } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class CopyModePlugin extends FormPlugin {
  CONTROLS_THAT_SHOULD_BE_VISUALLY_DISABLED_IF_COPY_MODE_IS_ON: string[] = [
    'wellName',
    'pad',
    'coordinatesT2X',
    'coordinatesT2Y',
    'coordinatesT3X',
    'coordinatesT3Y',
  ];

  connect(form: FormStore): VoidFunction {
    form.approachesTab?.approachesList.clear();
    return autorun(() => {
      const setIsControlVisuallyDisabled = (control: Item) => {
        if (this.CONTROLS_THAT_SHOULD_BE_VISUALLY_DISABLED_IF_COPY_MODE_IS_ON.includes(control.fieldId)) {
          control.setIsVisuallyDisabled({ flagId: this.pluginId, value: true });
        }
      };

      form.processFormFields(setIsControlVisuallyDisabled);
    });
  }
}
