import { TFunction } from 'i18next';

import { TimelinePeriodsTogglePresenter } from '../presenter/timeline-periods-toggle-presenter';

export const getTimePeriods = (t: TFunction): { key: TimelinePeriodsTogglePresenter.Periods; title: string }[] => [
  { key: TimelinePeriodsTogglePresenter.Periods.monthOne, title: t('month') },
  { key: TimelinePeriodsTogglePresenter.Periods.monthThree, title: t('month_short', { count: 3 }) },
  { key: TimelinePeriodsTogglePresenter.Periods.monthSix, title: t('month_short', { count: 6 }) },
  { key: TimelinePeriodsTogglePresenter.Periods.yearOne, title: t('year') },
  { key: TimelinePeriodsTogglePresenter.Periods.all, title: t('allPeriod') },
];
