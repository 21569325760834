import { Collapse } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Pad } from 'src/features/planning/store/pad';
import { TableCollapseHandlers } from 'src/features/planning/views/plan-table/table-collapse/table-collapse-handlers';
import { TableCollapseHeader } from 'src/features/planning/views/plan-table/table-collapse/table-collapse-header';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';
import { PadAttributeIcon } from 'src/shared/constants/pad-attribute-icon';

import styles from './table-collapse.module.scss';

interface Props {
  children: React.ReactNode;
  editing: boolean;
  dragListeners: import('@dnd-kit/core/dist/hooks/utilities').SyntheticListenerMap | undefined;
  draggable: boolean;
  attributeNames: PadAttributeIcon[] | null;
  onOpenAddPadSidebar(padName: string): void;
  onOpenPad(padKey: string): void;
  onClosePad(padKey: string): void;
  onRemovePad(): void;
  isPadOpened: boolean;
  isDraggable: boolean;
  pad: Pad;
}

export const TableCollapse = observer(function TableCollapse({
  children,
  dragListeners,
  draggable,
  editing,
  attributeNames,
  onOpenAddPadSidebar,
  isPadOpened,
  onOpenPad,
  onClosePad,
  isDraggable,
  onRemovePad,
  pad,
}: Props) {
  const { t } = useTranslation('plan');

  function handleChange(event: string | string[]) {
    if (!event.length) {
      onClosePad(pad.key);
      return;
    }

    onOpenPad(pad.key);
  }

  function onAddWell(): void {
    onOpenAddPadSidebar(pad.name);
  }

  return (
    <div className={clsx(styles.container, editing && styles.containerEditing)}>
      <Collapse
        destroyInactivePanel={true}
        onChange={handleChange}
        activeKey={!draggable && isPadOpened ? pad.key : undefined}
        expandIcon={({ isActive }) => (
          <DropdownIcon className={clsx(styles.expandIcon, isActive && styles.expandIconActive)} />
        )}
      >
        <Collapse.Panel
          header={
            editing && (
              <TableCollapseHandlers dragListeners={dragListeners} isDraggable={isDraggable} onRemove={onRemovePad} />
            )
          }
          key={pad.key}
          extra={
            <TableCollapseHeader
              title={pad.name}
              buttonText={t('addWell')}
              editing={editing}
              subTitles={pad.subTitles}
              addItem={onAddWell}
              attributeNames={attributeNames}
            />
          }
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
