import { observer } from 'mobx-react-lite';

import { ReactComponent as InfoIcon } from '../../assets/icons/info-i.svg';
import { Tooltip } from '../tooltip';

import styles from './info-button.module.scss';

type Props = {
  text: string;
  trigger?: 'click' | 'focus' | 'hover' | 'contextMenu' | ('click' | 'focus' | 'hover' | 'contextMenu')[];
  width?: number;
  height?: number;
};

export const InfoButton = observer(function InfoButton({
  text,
  trigger = ['click', 'focus', 'hover'],
  width = 24,
  height = 24,
}: Props) {
  return (
    <Tooltip text={text} trigger={trigger}>
      <button
        className={styles.button}
        style={{
          width,
          height,
        }}
      >
        <InfoIcon width={width} height={height} />
      </button>
    </Tooltip>
  );
});
