import { observer } from 'mobx-react-lite';

import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { Viewport } from '../../../shared/viewport/viewport';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';

import { GhostRow } from './ghost-row';
import { RigRow } from './rig-row';
import { RigsGroupRow } from './rigs-group-row';

import styles from './data-items.module.scss';

interface Props {
  item: RigsChartStore.ViewItem;
  verticalViewport: Viewport;
}

export const RigsDataBackgroundItem = observer(function RigsDataBackgroundItem({ item, verticalViewport }: Props) {
  if (item instanceof ChartRig) {
    return <RigRow item={item} verticalViewport={verticalViewport} className={styles.row} />;
  }

  if (item instanceof RigsGroup) {
    return <RigsGroupRow item={item} verticalViewport={verticalViewport} className={styles.rowHeader} />;
  }

  if (RigsSorting.isRigGhost(item)) {
    return <GhostRow item={item} verticalViewport={verticalViewport} className={styles.row} />;
  }

  if (RigsSorting.isRigsGroupGhost(item)) {
    return <GhostRow item={item} verticalViewport={verticalViewport} className={styles.rowHeader} />;
  }

  return null;
});
