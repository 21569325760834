import { observer } from 'mobx-react-lite';
import { useState, ReactElement, useEffect } from 'react';

import { ValidationOptions } from 'src/shared/components/form-components/form-multiselect/form-multiselect';
import { FormTreeMultiSelect } from 'src/shared/components/form-components/form-treemultiselect/form-treemultiselect';

import { TreeMultiCombobox } from '../../../../shared/entities/control-entities/treemulticombobox.entity';
import { ComboboxDropdown } from '../combobox-dropdown/combobox-dropdown';

type Props = {
  className?: string;
  label?: string;
  item: TreeMultiCombobox;
  isDisabled?: boolean;
  onBlur?: VoidFunction;
  validationOptions?: ValidationOptions;
};

export const TreeMulticombobox = observer(function TreeMulticombobox({
  className,
  label,
  item,
  isDisabled,
  onBlur,
  validationOptions,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);

  const { init } = item;

  const renderDropdown = (origin: ReactElement): ReactElement => {
    return (
      <ComboboxDropdown showButton={false} fieldId={item.fieldId}>
        {origin}
      </ComboboxDropdown>
    );
  };

  const onChange = (value: (string | number)[]): void => {
    item.tryToSetRawValue(value);
    item.clearError();
  };

  const onDropdownVisibleChange = (open: boolean): void => {
    setIsOpened(open);
  };

  useEffect(init, [init]);

  return (
    <FormTreeMultiSelect
      placeholder={item.placeholder}
      open={isOpened}
      value={item.value}
      label={label}
      className={className}
      errorText={item.errorText}
      treeData={item.options}
      treeNodeFilterProp="Title"
      isDisabled={isDisabled}
      dropdownStyle={{
        zIndex: 3,
      }}
      dataTestId={`combobox-${item.fieldId}`}
      validationOptions={validationOptions}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={renderDropdown}
      onBlur={onBlur}
      onDeselect={item.hasErrors}
      onClear={item.clearItem}
      onChange={onChange}
    />
  );
});
