import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ComparingIndicators } from 'src/api/chart/comparing-indicators-api';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';
import { TrendType } from 'src/shared/constants/trend-type';
import { LoadingField } from 'src/shared/utils/loading-field';

import { IndicatorsSettings } from '../../../presets/indicators-view-settings-sidebar/entities';
import { trendTypeToIndicatorName } from '../../../shared/indicators';

import styles from './comparing-indicators-table.module.scss';

interface Props {
  isExpanded: boolean;
  indicatorsResult: ComparingIndicators.ColumnValues | ComparingIndicators.LoadingColumnValues;
  indicatorsSettings: IndicatorsSettings;
}

const Total = observer(function Total({ value }: { value?: number | LoadingField }) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Indicators' });

  if (value instanceof LoadingField) {
    return <LoadingTitle field={value} loadingClassName={styles.rowHeaderTotalLoading} />;
  }

  return (
    <p className={styles.rowHeaderTotal}>
      {t('total')}: {value ?? 0}
    </p>
  );
});

const Title = observer(function Title({
  trendType,
  indicatorsSettings,
}: {
  trendType: TrendType;
  indicatorsSettings: IndicatorsSettings;
}) {
  const indicatorName = trendTypeToIndicatorName(trendType);

  if (!indicatorName) {
    return null;
  }

  const indicatorTitle = indicatorsSettings.items.find(({ name }) => name === indicatorName)?.label;

  return (
    <p className={styles.rowHeaderTitle} title={indicatorTitle}>
      {indicatorTitle}
    </p>
  );
});

export const ComparingIndicatorsHeaderColumn = observer(function ComparingIndicatorsHeaderColumn({
  isExpanded,
  indicatorsResult,
  indicatorsSettings,
}: Props) {
  return (
    <div className={styles.rowHeaderWrapper}>
      <div className={styles.rowHeader}>
        <Title trendType={TrendType.passing} indicatorsSettings={indicatorsSettings} />
        <Total value={indicatorsResult.first[TrendType.passing]} />
      </div>

      <div className={styles.rowHeaderComparing}>
        <Title trendType={TrendType.passing} indicatorsSettings={indicatorsSettings} />
        <Total value={indicatorsResult.second?.[TrendType.passing]} />
      </div>

      {isExpanded &&
        [TrendType.commercialSpeed, TrendType.developmentCompleteWellsCount, TrendType.drillingCompleteWellsCount].map(
          (indicatorName) => (
            <Fragment key={indicatorName}>
              <div className={styles.rowHeader}>
                <Title trendType={indicatorName} indicatorsSettings={indicatorsSettings} />
                <Total value={indicatorsResult.first[indicatorName]} />
              </div>

              <div className={styles.rowHeaderComparing}>
                <Title trendType={indicatorName} indicatorsSettings={indicatorsSettings} />
                <Total value={indicatorsResult.second?.[indicatorName]} />
              </div>
            </Fragment>
          )
        )}
    </div>
  );
});
