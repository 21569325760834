import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { EmptyDataHeader } from '../../../components/timeline-chart/components/data/empty-data-header';
import { ComparingChartWell } from '../../../presets/comparing-drilling-wells-chart/entities';
import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { Viewport } from '../../../shared/viewport/viewport';
import { WellsChartDataModel } from '../../wells-chart/data/wells-chart-data-model';

import styles from './data-items.module.scss';

interface Props {
  item: WellsChartDataModel.ViewItemContent;
  verticalViewport: Viewport;
}

export const ComparingWellsDataBackgroundItem = observer(function ComparingWellsDataBackgroundItem({
  item,
  verticalViewport,
}: Props) {
  if (item instanceof ChartWell) {
    const isChangedComparingWell = item instanceof ComparingChartWell && item.hasChanges;

    return (
      <EmptyDataHeader
        key={item.getKey('background')}
        item={item}
        verticalViewport={verticalViewport}
        className={clsx(styles.row, isChangedComparingWell && styles.rowComparing)}
      />
    );
  }

  if (item instanceof WellsGroup) {
    return (
      <EmptyDataHeader
        key={item.getKey('background')}
        item={item}
        verticalViewport={verticalViewport}
        className={styles.rowHeader}
      />
    );
  }

  return null;
});
