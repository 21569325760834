import { Select } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';

import { TDictObject } from 'src/api/directories/types';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';
import { getOptionText } from 'src/shared/components/table-cell/multi-combo-box-table-cell/utils';
import { hasValue } from 'src/shared/utils/common';
import { TPlainDictObject } from 'src/store/directories/types';
import { ColumnType } from 'src/store/table/types';

import styles from './multi-combo-box-table-cell.module.scss';

interface Props {
  fieldValues: string[] | number[];
  column: ColumnType | Omit<ColumnType, 'width'>;
  object: TDictObject[] | TPlainDictObject[] | null;
  onEditCell: (value: string[] | number[]) => void;
}

export const MultiComboBoxTableCell = observer(function MultiComboBoxTableCell({
  fieldValues,
  onEditCell,
  column,
  object,
}: Props) {
  const getActiveOptionIndex = (dirValueId: number | string): number => {
    return fieldValues.findIndex((value) => value === dirValueId);
  };

  function handleChange(value: DefaultOptionType[]): void {
    const filteredValues: string[] | number[] = [];
    value.forEach((_value) => {
      if (typeof _value === 'number' || typeof _value === 'string') {
        filteredValues.push(_value);
      }
    });

    hasValue(value) ? onEditCell(filteredValues) : onEditCell([]);
  }

  function onFilterValue(inputValue: string, option?: BaseOptionType | DefaultOptionType): boolean {
    return option?.children.toLowerCase().includes(inputValue.toLowerCase());
  }

  const options: DefaultOptionType[] = [];
  const chosenOptions: DefaultOptionType[] = [];

  object?.forEach((dirValue) => {
    if (typeof dirValue.id === 'number' || typeof dirValue.id === 'string') {
      const option = {
        label: getOptionText(column, dirValue),
        value: dirValue.id,
      };

      const index = getActiveOptionIndex(dirValue.id);

      if (index >= 0) {
        chosenOptions[index] = option;
      }

      if (dirValue.status === 'ACTIVE') {
        options.push(option);
      }
    }
  });

  return (
    <div className={styles.wrapper}>
      <Select
        mode="multiple"
        options={options}
        maxTagCount="responsive"
        onChange={handleChange}
        value={chosenOptions}
        suffixIcon={<DropdownIcon />}
        filterOption={onFilterValue}
        showSearch
        allowClear
      />
    </div>
  );
});
