import { TFunction } from 'i18next';

import { EMAIL } from '../constants/email';

export const getMailtoHref = (directoryLabel: string, t: TFunction): string => {
  if (EMAIL) {
    return `mailto:${EMAIL}?subject=${t('newWellForm:AddNewValueToDirectory.subject', { directoryLabel })}&body=${t(
      'newWellForm:AddNewValueToDirectory.body',
      { directoryLabel }
    )}`;
  }
  return '';
};
