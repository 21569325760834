import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { CompareTableCell } from 'src/shared/components/table-cell/compare-table-cell';
import { EllipsisTableCell } from 'src/shared/components/table-cell/ellipsis-table-cell';
import { NoDataTableCell } from 'src/shared/components/table-cell/no-data-table-cell';
import { hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { Directories } from 'src/store/directories/directories.store';
import { TableRow, ColumnType, CHANGED_VARIANT } from 'src/store/table/types';

import styles from './table-cell.module.scss';
import { renderCellData } from './utils';

interface Props {
  row: TableRow;
  column: ColumnType;
  isResize: boolean;
  directories: Directories;
  isImportOccurred: boolean;
}

export const TableCell = observer(function TableCell({ row, isResize, column, directories, isImportOccurred }: Props) {
  const cell = row[column.name];

  const isCompareCell = isObjectWithKeys(cell) && 'first' in cell && 'second' in cell;

  const isCommonCell = isObjectWithKeys(cell) && 'value' in cell;

  const cellStyle = clsx(styles.cellWrapper, {
    [styles.cellWrapperResizable]: isResize,
    [styles.cellWrapperCompare]: isCompareCell && !row.rowChanged,
  });

  function renderCell(): React.ReactNode {
    if (!hasValue(cell)) {
      return <NoDataTableCell />;
    }

    if (isCompareCell) {
      return (
        <CompareTableCell
          firstValue={renderCellData(cell.first, column, directories)}
          secondValue={renderCellData(cell.second, column, directories)}
          maxRows={2}
          isImportOccurred={isImportOccurred}
        />
      );
    }

    if (isCommonCell) {
      return (
        <EllipsisTableCell
          maxRows={2}
          isDeleted={row.rowChanged === CHANGED_VARIANT.deleted}
          value={renderCellData(cell.value, column, directories)}
        />
      );
    }

    return null;
  }

  return <div className={cellStyle}>{renderCell()}</div>;
});
