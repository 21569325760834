import { action, makeObservable, observable } from 'mobx';

import { WellTypes } from 'src/types/well-types';

import { ChartWell } from '../../drilling-wells-chart/entities';

export class ComparingChartWell extends ChartWell {
  @observable hasChanges = false;

  constructor(item: WellTypes.RawWell, id: number, hasChanges?: boolean) {
    super(item, id);

    this.hasChanges = hasChanges || false;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-comparing-well-${this.uniqueCreationKey}`;
    }

    return `comparing-well-${this.uniqueCreationKey}`;
  }

  @action.bound
  setHasChanges(hasChanges: boolean): void {
    this.hasChanges = hasChanges;
  }
}

export class MainComparingChartWell extends ChartWell {
  readonly comparingPair: ComparingChartWell;

  constructor(item: WellTypes.RawWell, id: number, comparingPair: ComparingChartWell) {
    super(item, id);

    this.comparingPair = comparingPair;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-main-comparing-well-${this.uniqueCreationKey}`;
    }

    return `main-comparing-well-${this.uniqueCreationKey}`;
  }
}
