import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';

import { Tooltip } from '../tooltip';

import styles from './switch-button-group.module.scss';

export type SwitchButtonGroupItem<TKey> = {
  key: TKey;
  title: string | ReactElement;
  disabled?: boolean;
  count?: number;
  tooltipText?: string;
  isTooltipVisible?: boolean;
};

interface Props<TKey> {
  items: SwitchButtonGroupItem<TKey>[];
  defaultOptionKey: TKey;
  className?: string;
  buttonClassName?: string;
  countIndicatorClassName?: string;
  disabled?: boolean;
  value?: TKey;
  variant?: 'default' | 'underlined';
  onChange: (key: TKey) => void;
}

export const SwitchButtonGroup = observer(function SwitchButtonGroup<TKey = string>({
  items,
  defaultOptionKey,
  className,
  buttonClassName,
  countIndicatorClassName,
  disabled,
  value,
  variant = 'default',
  onChange,
}: Props<TKey>) {
  const [option, setOption] = useState<TKey>(value || defaultOptionKey);

  function handleSelectOption(key: TKey): void {
    !value && setOption(key);

    onChange(key);
  }

  useEffect(() => {
    if (value) {
      setOption(value);
    }
  }, [value]);

  const getButton = (item: SwitchButtonGroupItem<TKey>) => (
    <button
      className={clsx(
        styles[`switchButton_${variant}`],
        option === item.key && styles.active,
        !!item.count && styles.buttonWithIndicator,
        buttonClassName
      )}
      onClick={() => handleSelectOption(item.key)}
      disabled={disabled || item.disabled}
      type="button"
    >
      {item.title}
      {!!item.count && (
        <div className={clsx(styles.countIndicator, countIndicatorClassName)}>
          {item.count > 99 ? '99+' : item.count}
        </div>
      )}
    </button>
  );

  return (
    <div className={clsx(styles[`container_${variant}`], className)}>
      {items.map((item, index) => (
        <Tooltip key={index} visible={item.isTooltipVisible} text={item.tooltipText}>
          {getButton(item)}
        </Tooltip>
      ))}
    </div>
  );
});
