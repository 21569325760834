import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { ComparingChartRig } from '../../../presets/comparing-drilling-rigs-chart/entities';
import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingRigsChartDataModel } from '../../comparing-rigs-chart/data';

import { ComparingRigsDataHeader } from './comparing-rigs-data-header';

import styles from './data-headers.module.scss';

interface Props {
  data: ComparingRigsChartDataModel.ViewItem[] | null;
  viewport: Viewport;
  viewProvider: RigsViewSettingsProvider;
  isCompactView: boolean;
  onGroupCollapseToggle(item: RigsGroup): void;
  onRigClick(rigId: number): void;
  containerWidth?: number;
  containerHeight?: number;
  className?: string;
  onScroll?(offset: number): void;
  onHeightChange?(height: number): void;
}

export const ComparingRigsDataHeaders = observer(function ComparingRigsDataHeaders({
  data,
  viewport,
  className,
  isCompactView,
  containerWidth,
  containerHeight,
  onScroll,
  onHeightChange,
  onGroupCollapseToggle,
  viewProvider,
  onRigClick,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = ref.current;

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        if (container) {
          const { height } = container.getBoundingClientRect();
          onHeightChange?.(height);
        }
      });

      resizeObserver.observe(container);

      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, [containerHeight, containerWidth, onHeightChange, ref]);

  useEffect(() => {
    const container = ref.current;

    if (container && onScroll) {
      const handleScroll = (e: WheelEvent) => {
        e.preventDefault();
        onScroll(e.deltaY);
      };

      container.addEventListener('wheel', handleScroll, { passive: false, capture: true });

      return () => container.removeEventListener('wheel', handleScroll);
    }
  }, [onScroll]);

  return (
    <div className={clsx(className, styles.wrapper)} ref={ref}>
      <div className={styles.headers}>
        {data?.map((item) => {
          if (item instanceof RigsGroup || item instanceof ChartRig || item instanceof ComparingChartRig) {
            return (
              <ComparingRigsDataHeader
                key={item.getKey()}
                item={item}
                viewport={viewport}
                viewProvider={viewProvider}
                isCompactView={isCompactView}
                onIsCollapseToggle={onGroupCollapseToggle}
                onRigClick={onRigClick}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});
