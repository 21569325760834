import { observable, makeObservable } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { WellsItem } from '../../graph-info-sidebar';
import { InfoSidebarTab } from '../info-sidebar-tab.entity';

import { Well } from './well.entity';

type TTabWithWellsOptions = {
  fieldId: string;
  appSettings: AppSettingsStore;
  directories: Directories;
  notifications: NotificationsStore;
  wells: WellsItem;
};
export class TabWithWells extends InfoSidebarTab {
  @observable wells: Well[] = [];

  private readonly wellsView: WellsItem;
  private readonly appSettings: AppSettingsStore;

  constructor({ fieldId, directories, notifications, appSettings, wells }: TTabWithWellsOptions) {
    super(fieldId, directories, notifications);
    this.wellsView = wells;
    this.appSettings = appSettings;

    makeObservable(this);
  }

  setValues(data: Record<string, unknown>): void {
    if (!('wells' in data) || !this.isWells(data.wells)) {
      return;
    }

    data.wells.forEach((rawWell) => {
      const geologicalTaskId = rawWell['GOplan_GeologicalTask.id'];
      const planWellTripleId = rawWell['GOplan_PlanWellTriple.id'];

      assert(
        typeof geologicalTaskId === 'number' ||
          (typeof geologicalTaskId === 'string' && !Number.isNaN(Number(geologicalTaskId))),
        'id is not presented in well'
      );

      assert(typeof planWellTripleId === 'number', 'planWellTripleId is not a number');

      const well = new Well(
        this.wellsView,
        this.directories,
        this.appSettings,
        rawWell,
        this.notifications,
        Number(geologicalTaskId),
        planWellTripleId
      );

      this.wells.push(well);
    });
  }

  private isWells(rawWells: unknown): rawWells is Record<string, unknown>[] {
    if (!Array.isArray(rawWells) || rawWells.some((rawWell) => typeof rawWell !== 'object' || rawWell === null)) {
      return false;
    }

    return true;
  }
}
