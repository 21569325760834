import { useEffect } from 'react';

export function useDraggablePortalRoot(
  containerId: string,
  onElementChange: (element: HTMLDivElement | null) => void,
  portalElementClassName?: string
) {
  useEffect(() => {
    const container = document.createElement('div');

    container.id = containerId;

    if (portalElementClassName) {
      container.classList.add(portalElementClassName);
    }

    document.body.appendChild(container);

    onElementChange(container);

    return () => {
      container.remove();
    };
  }, [containerId, onElementChange, portalElementClassName]);
}
