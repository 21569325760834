import { ViewSettingItem } from 'src/api/planning';
import { Directories } from 'src/store/directories/directories.store';
import { ColumnType } from 'src/store/table/types';

import { GroupColumnsType } from './types';

export function serializeColumnsData(
  columns: ColumnType[],
  viewSettingsGroups: ViewSettingItem[],
  directories: Directories
): GroupColumnsType[] {
  return viewSettingsGroups.map((group) => {
    const groupItems: ColumnType[] = [];

    group.items.forEach((item) => {
      const currentColumn = columns.find((column) => column.id === item.fieldId);
      if (currentColumn) {
        groupItems.push(currentColumn);
      }
    });

    return {
      title: directories.getFieldLabel(group.fieldId) || '',
      items: groupItems.sort(
        (a, b) => columns.findIndex((item) => a.id === item.id) - columns.findIndex((item) => b.id === item.id)
      ),
    };
  });
}

export function deSerializeColumnsData(columns: GroupColumnsType[]): ColumnType[] {
  return columns.reduce<ColumnType[]>((prev, curr) => {
    return prev.concat(curr.items);
  }, []);
}
