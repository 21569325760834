import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArchivedVersionsGroup } from 'src/features/plan-version-sidebar/archived-versions-group';
import { PlanVersionSidebarStore } from 'src/features/plan-version-sidebar/plan-version-sidebar.store';
import { PLAN_VERSION_TYPE } from 'src/features/plan-version-sidebar/types';
import { VersionsGroup } from 'src/features/plan-version-sidebar/versions-group';
import { ReactComponent as SearchIcon } from 'src/shared/assets/icons/search.svg';
import { Input } from 'src/shared/components/input/input';
import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group/switch-button-group';
import { useStore } from 'src/store';
import { TPlanVersion } from 'src/store/comparison/types';

import { AnalyticsVersionGroup } from './analytics-version-group';

import styles from './plan-version-sidebar.module.scss';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const PlanVersionSidebar = observer(function PlanVersionSidebar({ isOpen, onClose }: Props) {
  const { t } = useTranslation('drillingCarpet');

  const rootStore = useStore();

  const [store] = useState(() => new PlanVersionSidebarStore(rootStore));

  const planVersionTypeTabs = [
    {
      key: PLAN_VERSION_TYPE.actual,
      title: t('Compare.actual'),
    },
    {
      key: PLAN_VERSION_TYPE.archive,
      title: t('Compare.archive'),
    },
    {
      key: PLAN_VERSION_TYPE.analytics,
      title: t('Compare.analytics'),
    },
  ];

  function onSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    store.setSearchValue(e.currentTarget.value.toLowerCase());
  }

  function onVersionChange(planVersion: TPlanVersion): void {
    store.changeVersion(planVersion);
    onClose();
  }

  function onDeleteDraftVersion(draftVersion: TPlanVersion): void {
    store.drafts.deleteDraftById(draftVersion.id);
  }

  function onDeletePlanVersion(planVersion: TPlanVersion): void {
    store.planVersion.deleteVersionById(planVersion.id);
  }

  function getDescriptionText(): string {
    if (!store.isEmptyList) {
      return t('PlanVersions.chooseVersion');
    }

    if (store.planVersionType === PLAN_VERSION_TYPE.analytics) {
      return t('PlanVersions.analytics');
    }

    return t(!!store.searchValue ? 'Compare.noSearchItems' : 'Compare.noItems');
  }

  return (
    <Sidebar
      title={t('PlanVersions.title')}
      isOpened={isOpen}
      closeOnClickOutside={false}
      onClose={onClose}
      className={styles.container}
    >
      {store.planVersion.isLoading && (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} />
        </div>
      )}

      <div className={styles.switcherWrapper}>
        <SwitchButtonGroup
          className={styles.overwriteSwitcherBgColor}
          items={planVersionTypeTabs}
          defaultOptionKey={store.planVersionType}
          onChange={store.setPlanVersionType}
        />
      </div>
      <div className={styles.contentWrapper}>
        <Input
          prefix={<SearchIcon className={styles.icon} />}
          value={store.searchValue}
          placeholder={t('common:search')}
          onChange={onSearch}
        />

        <div className={styles.container}>
          <p className={clsx(styles.description, store.isEmptyList && styles.descriptionEmpty)}>
            {getDescriptionText()}
          </p>

          {store.versionsList.map((versionsGroup) => {
            if (versionsGroup.type === 'actual') {
              return (
                <VersionsGroup
                  key={versionsGroup.name}
                  versionsGroup={versionsGroup}
                  onVersionChange={onVersionChange}
                  onDeletePlanVersion={onDeletePlanVersion}
                  onDeleteDraftVersion={onDeleteDraftVersion}
                />
              );
            }

            if (versionsGroup.type === 'archived') {
              return (
                <ArchivedVersionsGroup
                  key={versionsGroup.year}
                  versionsGroup={versionsGroup}
                  onDeletePlanVersion={onDeletePlanVersion}
                  onVersionChange={onVersionChange}
                />
              );
            }

            if (versionsGroup.type === 'analytics') {
              return <AnalyticsVersionGroup key={versionsGroup.year} versionsGroup={versionsGroup} />;
            }

            throw new Error('unknown version group type');
          })}
        </div>
      </div>
    </Sidebar>
  );
});
