import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { WellTypes } from 'src/types/well-types';

import { Range } from '../../../layers/model';

import { LoadingRigOperations } from './loading-rig-operations';
import { WellRigOperation } from './well-rig-operation';

export class ChartWell {
  readonly item: WellTypes.RawWell;
  readonly id: number;
  readonly uniqueCreationKey: string;

  @observable y: Range<number> = { start: 0, end: 0 };
  @observable items?: (WellRigOperation | LoadingRigOperations)[];
  @observable isDeleting = false;

  constructor(item: WellTypes.RawWell, id: number) {
    this.item = item;
    this.id = id;
    this.uniqueCreationKey = uuidv4();

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-well-${this.uniqueCreationKey}`;
    }

    return `well-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): string | number | boolean | undefined {
    return this.item[fieldName];
  }

  @action.bound
  setItems(rigOperations: (WellRigOperation | LoadingRigOperations)[]): void {
    this.items = rigOperations;
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }

  @action.bound
  startDeleting(): void {
    this.isDeleting = true;
  }
}
