import { TDictObject } from 'src/api/directories/types';
import { isDictObject } from 'src/shared/utils/is-dict-object';
import { TPlainDictObject } from 'src/store/directories/types';
import { ColumnType } from 'src/store/table/types';

export function tryGetCurrentTitles(
  fieldValues: string[] | number[],
  column: ColumnType | Omit<ColumnType, 'width'>,
  objects?: TDictObject[] | TPlainDictObject[] | null
): string | null {
  const titles: string[] = [];

  if (!fieldValues) {
    return null;
  }

  fieldValues.forEach((fieldValue) => {
    if (objects && column.objectField) {
      // number comparison

      let currentObject: TDictObject | TPlainDictObject | null = null;

      for (const object of objects) {
        if (isDictObject(object)) {
          if (String(object.id) === String(fieldValue)) {
            currentObject = object;
            break;
          }
        } else if (column.attrName) {
          if (object[column.attrName]?.toString() === fieldValue?.toString()) {
            currentObject = object;
            break;
          }
        }
      }

      let currentValue: string | null = null;

      if (currentObject) {
        if (isDictObject(currentObject)) {
          currentValue = currentObject?.data[column?.objectField]?.toString();
        } else {
          const key = `${column.objectType}.${column.objectField}`;
          currentValue = currentObject[key]?.toString();
        }
      }

      const title = currentValue ?? fieldValue ?? null;

      if (title) {
        titles.push(String(title));
      }
    }
  });

  return titles.join(', ');
}

export function getOptionText(
  column: ColumnType | Omit<ColumnType, 'width'>,
  object: TDictObject | TPlainDictObject
): string | null {
  if (object && column.objectField) {
    if (isDictObject(object)) {
      return object.data[column?.objectField] ? object.data[column?.objectField].toString() : null;
    }
    const key = `${column.objectType}.${column.objectField}`;
    return object[key] ? object[key].toString() : null;
  }
  return null;
}
