import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import {
  ChartRig,
  RigsGroup,
  TemporaryChartRig,
  TemporaryRigsGroup,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { useSortingContext } from '../../sorting/hooks/use-sorting-context';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import { RigsDataHeader } from './rigs-data-header';

import styles from './data-headers.module.scss';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  viewport: Viewport;
  isCompactView: boolean;
  viewSettingsProvider: RigsViewSettingsProvider;
  onGroupClick(item: RigsGroup): void;
  containerWidth?: number;
  containerHeight?: number;
  className?: string;
  onScroll?(offset: number): void;
  onHeightChange?(height: number): void;
  onRowHeaderClick?(id: number): void;
}

export const RigsDataHeaders = observer(function RigsDataHeaders({
  data,
  viewport,
  className,
  isCompactView,
  containerWidth,
  containerHeight,
  onScroll,
  onHeightChange,
  onGroupClick,
  onRowHeaderClick,
  viewSettingsProvider,
}: Props) {
  const { ref, containerRect } = useSortingContext();

  useEffect(() => {
    onHeightChange?.(containerRect?.height ?? 0);
  }, [containerHeight, containerRect, containerWidth, onHeightChange, ref]);

  useEffect(() => {
    const container = ref.current;

    if (container && onScroll) {
      const handleScroll = (e: WheelEvent) => {
        e.preventDefault();
        onScroll(e.deltaY);
      };

      container.addEventListener('wheel', handleScroll, { passive: false, capture: true });

      return () => container.removeEventListener('wheel', handleScroll);
    }
  }, [onScroll, ref]);

  return (
    <div className={clsx(className, styles.wrapper)} ref={ref}>
      <div className={styles.headers}>
        {data?.map((item) => {
          if (
            item instanceof RigsGroup ||
            item instanceof ChartRig ||
            item instanceof TemporaryChartRig ||
            item instanceof TemporaryRigsGroup ||
            item instanceof SortableGhostItem
          ) {
            return (
              <RigsDataHeader
                key={item.getKey()}
                item={item}
                viewport={viewport}
                isCompactView={isCompactView}
                viewSettingsProvider={viewSettingsProvider}
                onGroupClick={onGroupClick}
                onRowHeaderClick={onRowHeaderClick}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});
