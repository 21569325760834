import { observer } from 'mobx-react-lite';

import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { GroupCollapseHeader } from '../../group-collapse-header';
import { WellsChartDataModel } from '../../wells-chart/data/wells-chart-data-model';

import { WellsRowHeaderWrapper } from './wells-row-header-wrapper';

interface Props {
  item: WellsChartDataModel.ViewItemContent;
  viewport: Viewport;
  isCompactView: boolean;
  isEditing: boolean;
  isLoading: boolean;
  viewProvider: WellsViewSettingsProvider;
  onIsCollapseToggle(item: WellsGroup): void;
  onWellDelete?(well: ChartWell): void;
}

export const WellsDataHeader = observer(function WellsDataHeader({
  item,
  viewport,
  isCompactView,
  isEditing,
  onIsCollapseToggle,
  viewProvider,
  onWellDelete,
  isLoading,
}: Props) {
  if (item instanceof ChartWell) {
    return (
      <WellsRowHeaderWrapper
        item={item}
        title={<LoadingTitle field={viewProvider.getWellTitle(item)} />}
        descriptionAttributes={viewProvider.getAttributes(item)}
        viewport={viewport}
        isCompactView={isCompactView}
        isEditing={isEditing}
        isLoading={isLoading}
        onDelete={onWellDelete}
      />
    );
  }

  if (item instanceof WellsGroup) {
    return (
      <GroupCollapseHeader
        key={item.id}
        item={item}
        isCollapsed={item.isCollapsed}
        title={<LoadingTitle field={viewProvider.getGroupTitle(item)} />}
        viewport={viewport}
        onClick={() => onIsCollapseToggle(item)}
        withArrow={true}
      />
    );
  }

  return null;
});
