import { autorun } from 'mobx';

import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class BlockCommSpeedPlugin extends FormPlugin<FormStore> {
  connect(form: FormStore): VoidFunction | void {
    const commSpeedField = form.fields['commercialSpeed'];

    if (!commSpeedField) {
      return;
    }

    return autorun(() => {
      const isVisuallyDisabledCommSpeedField = (() => {
        if (form.approaches.length > 1) {
          return true;
        }

        return form.approaches.some((a) => a.stagesList.stages.some((s) => !!s.sectionsList.sections.length));
      })();

      commSpeedField.setIsVisuallyDisabled({ flagId: 'BlockCommSpeedPlugin', value: isVisuallyDisabledCommSpeedField });
    });
  }
}
