import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { getSizeString } from '../../../../components/timeline-chart/utils';
import { Viewport } from '../../../../shared/viewport/viewport';
import { calculateItemOffsetParams } from '../../../../shared/viewport/viewport-calculator';
import { TimelineDataModel } from '../../data/timeline-data-model';
import { TimelinePresenter } from '../../presenter/timeline-presenter';
import { TimelineSelect } from '../timeline-select/timeline-select';
import { TimelineYear } from '../timeline-year/timeline-year';
import { getMonths } from '../timeline-year/timeline-year.utils';

import { getIsPointLocatedOnElements } from './timeline.utils';

import styles from './timeline.module.scss';

interface Props {
  data: TimelineDataModel.ITimelineItem[];
  viewport: Viewport;
  timelineViewport: Viewport;
  onDragDown(): void;
  onDragMove(offsetX: number, direction: TimelinePresenter.DragDirection): void;
  onRightDragUp(): void;
  onLeftDragUp(): void;
  onDragCancel(): void;
  onSelectAreaDown(): void;
  onSelectAreaMove(offsetX: number): void;
  onSelectAreaUp(): void;
  onSelectAreaCancel(): void;
}

export const Timeline = observer(function Timeline({
  data,
  viewport,
  timelineViewport,
  onDragDown,
  onDragMove,
  onRightDragUp,
  onLeftDragUp,
  onDragCancel,
  onSelectAreaDown,
  onSelectAreaMove,
  onSelectAreaUp,
  onSelectAreaCancel,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollableWrapperRef = useRef<HTMLDivElement>(null);
  const selectAreaRef = useRef<HTMLDivElement>(null);
  const rightDragRef = useRef<HTMLDivElement>(null);
  const leftDragRef = useRef<HTMLDivElement>(null);

  const [startLeftPosition, setStartLeftPosition] = useState(0);
  const { width: selectAreaWidth, offset: selectAreaOffset } = calculateItemOffsetParams(timelineViewport, viewport);

  useEffect(() => {
    document.fonts.ready.then(() => {
      if (containerRef.current && scrollableWrapperRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const { width: scrollableWrapperWidth } = scrollableWrapperRef.current.getBoundingClientRect();

        const monthsCount = data.reduce((acc, currValue) => {
          return acc + getMonths(currValue.x.start, currValue.x.end).monthsCount;
        }, 0);

        if (width && scrollableWrapperWidth && monthsCount) {
          const timelineYearWidth = (width / monthsCount) * 12;
          const msInPixel = Math.trunc(((timelineViewport.end - timelineViewport.start) * 1000) / width);

          const currentYearMs = new Date(new Date().getFullYear(), 0).valueOf();

          const currentYearStartPx = Math.trunc(
            (currentYearMs - timelineViewport.start * 1000) / msInPixel -
              scrollableWrapperWidth / 2 +
              timelineYearWidth / 2
          );

          scrollableWrapperRef.current.scrollTo({
            left: currentYearStartPx,
            behavior: 'smooth',
          });

          setStartLeftPosition(currentYearStartPx);
        }
      }
    });
  }, [timelineViewport, viewport, data, setStartLeftPosition]);

  const getIsTargetIncludedToSelectArea = (e: PointerEvent): boolean => {
    return e.target === rightDragRef.current || e.target === leftDragRef.current || e.target === selectAreaRef.current;
  };

  useEffect(() => {
    const current = scrollableWrapperRef.current;
    let prevPosition = startLeftPosition;
    let startPosition = 0;
    let isActive = false;

    if (!containerRef.current || !current) return;

    const pointerDownHandler = (e: PointerEvent) => {
      if (selectAreaRef.current && leftDragRef.current && rightDragRef.current) {
        const isPointLocatedOnSelectArea = getIsPointLocatedOnElements(e, [selectAreaRef, leftDragRef, rightDragRef]);

        if (getIsTargetIncludedToSelectArea(e) || isPointLocatedOnSelectArea) return;

        isActive = true;
        startPosition = e.clientX;
      }
    };

    const pointerUpHandler = (e: PointerEvent) => {
      if (!isActive) return;

      current.setPointerCapture(e.pointerId);

      const newPosition = prevPosition - (e.clientX - startPosition);
      isActive = false;

      if (newPosition + current.clientWidth > current.scrollWidth) {
        prevPosition = current.scrollWidth - current.clientWidth;
      } else if (newPosition < 0) {
        prevPosition = 0;
      } else {
        prevPosition = prevPosition - (e.clientX - startPosition);
      }
    };

    const pointerMoveHandler = (e: PointerEvent) => {
      if (!isActive) return;
      current.setPointerCapture(e.pointerId);

      current.scrollTo({ left: prevPosition - (e.clientX - startPosition) });
    };

    const wheelHandler = (e: WheelEvent | TouchEvent) => {
      e.preventDefault();
    };

    current.addEventListener('wheel', wheelHandler);
    current.addEventListener('touchmove', wheelHandler);
    current.addEventListener('pointerdown', pointerDownHandler);
    current.addEventListener('pointermove', pointerMoveHandler);
    document.addEventListener('pointerup', pointerUpHandler);

    return () => {
      current.removeEventListener('wheel', wheelHandler);
      current.removeEventListener('touchmove', wheelHandler);
      current.removeEventListener('pointerdown', pointerDownHandler);
      current.removeEventListener('pointermove', pointerMoveHandler);
      document.removeEventListener('pointerup', pointerUpHandler);
    };
  }, [startLeftPosition, timelineViewport]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner} ref={scrollableWrapperRef}>
        <div
          className={styles.dataContainer}
          ref={containerRef}
          style={{
            '--select-area-width': getSizeString(selectAreaWidth),
            '--select-area-left': getSizeString(selectAreaOffset),
            '--select-area-right': getSizeString(selectAreaOffset + selectAreaWidth),
          }}
        >
          {data.map((year) => (
            <TimelineYear key={year.toString()} data={year} timelineViewport={timelineViewport} />
          ))}

          {!!containerRef.current && (
            <TimelineSelect
              defaultSelectAreaRef={selectAreaRef}
              defaultRightDragRef={rightDragRef}
              defaultLeftDragRef={leftDragRef}
              timelineViewport={timelineViewport}
              timelineElement={containerRef}
              onDragDown={onDragDown}
              onDragMove={onDragMove}
              onRightDragUp={onRightDragUp}
              onLeftDragUp={onLeftDragUp}
              onDragCancel={onDragCancel}
              onSelectAreaDown={onSelectAreaDown}
              onSelectAreaMove={onSelectAreaMove}
              onSelectAreaUp={onSelectAreaUp}
              onSelectAreaCancel={onSelectAreaCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
});
