import clsx from 'clsx';
import Highcharts, { Chart as HighchartsChart } from 'highcharts';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { Switcher } from 'src/shared/components/switcher/switcher';
import { THEMES } from 'src/shared/constants/themes';
import { range } from 'src/shared/utils/range';

import { labelSwitcherItems } from '../dashboard/dashboard.consts';
import { ChartType } from '../dashboard/types';
import { LabelButton } from '../label-button/label-button';

import { getRepeatedColorByIndex } from './chart.utils';
import { ChartEntity } from './entity/chart.entity';

import styles from './chart.module.scss';

interface Props {
  chartEntity: ChartEntity;
  theme: THEMES;
}

export const Chart = observer(function Chart({ chartEntity }: Props) {
  const chartRef = useRef<HighchartsChart | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { name, options, setChart, areDataLabelsVisible, updateDataLabelsVisibility } = chartEntity;

  useEffect(() => {
    if (containerRef.current && !chartRef.current) {
      const { areDataLabelsVisible } = chartEntity;
      chartRef.current = Highcharts.chart(containerRef.current, options, (chart) => {
        setChart(chart);
      });
      chartRef.current.update({ ...options, isLabelsEnabled: areDataLabelsVisible });
    }
  }, [setChart, options, chartEntity]);

  useEffect(() => {
    return () => {
      chartRef.current?.destroy();
      chartRef.current = null;
    };
  }, []);

  useEffect(() => {
    chartRef.current?.reflow();
  }, [chartEntity.options, chartRef.current?.series]);

  return (
    <article className={clsx(styles.chart, chartEntity.isBarChart && styles.chartBarType)}>
      <div className={styles.chartHeader}>
        <h3>{name}</h3>

        <div className={styles.labelWrapper}>
          {chartEntity.chart && (
            <>
              <div className={styles.legend}>
                {range(chartEntity.seriesCount).map((ind) => (
                  <LabelButton
                    key={ind}
                    onClick={() => chartEntity.toggleSeriesVisibility(ind)}
                    label={chartEntity.getSeriesLabelByIndex(ind)}
                    isEnabled={chartEntity.getIsSeriesEnabledByIndex(ind)}
                    color={chartEntity.getSeriesColorByIndex(ind) ?? '#28313F'}
                  />
                ))}
              </div>
              <Switcher
                isActive={areDataLabelsVisible}
                onToggle={() => updateDataLabelsVisibility(!areDataLabelsVisible)}
                className={styles.switcher}
                items={labelSwitcherItems}
                isWithoutStroke={true}
              />
            </>
          )}
        </div>
      </div>

      <div ref={containerRef} />
      {chartEntity.chartType === ChartType.bar && (
        <div className={styles.bottomLegend}>
          {chartEntity.series.map((item, ind) => (
            <div key={item.name} className={styles.bottomLabelItem}>
              <div className={styles.labelCircleColor} style={{ backgroundColor: getRepeatedColorByIndex(ind) }} />
              <div className={styles.bottomLabel}>{chartEntity.getLabelByKey(item.name)}</div>
            </div>
          ))}
        </div>
      )}
    </article>
  );
});
