export const ROUTES = {
  root: '/',
  test: 'test',
  graph: 'graph',
  carpet: '/carpet/*',
  table: 'table',
  dashboard: '/dashboard',
  handbook: '/handbook',
  wells: 'wells',
  schedule: 'schedule',
  planning: 'planning',
  matching: 'matching',
  newWell: 'newWell',
  conflicts: 'conflicts',
};
