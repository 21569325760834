import { observer } from 'mobx-react-lite';
import { ChangeEvent } from 'react';

import { FormTextArea } from 'src/shared/components/form-components/form-text-area/form-text-area';

import { TextArea } from '../../../../shared/entities/control-entities';

type Props = {
  className?: string;
  label?: string;
  item: TextArea;
  isDisabled: boolean;
  onBlur?: VoidFunction;
};

export const NewWellTextArea = observer(function NewWellTextArea({
  className,
  label,
  item,
  isDisabled,
  onBlur,
}: Props) {
  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    item.setValue(event.target.value);
  };

  const handleBlur = () => {
    onBlur?.();
  };

  return (
    <FormTextArea
      value={item.value}
      label={label}
      className={className}
      isError={!!item.errorText}
      errorText={item.errorText}
      placeholder={item.placeholder}
      isDisabled={isDisabled}
      onChange={onChange}
      onFocus={item.clearError}
      onBlur={handleBlur}
    />
  );
});
