export const navItems = [
  {
    name: 'Nav.wells',
    to: 'wells',
  },
  {
    name: 'Nav.schedule',
    to: 'schedule',
  },
];
