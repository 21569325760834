import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { PlanPageStore } from 'src/pages/plan-page/plan-page.store';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil.svg';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './view-mode-buttons.module.scss';

interface Props {
  pageStore: PlanPageStore;
}

export const ViewModeButtons = observer(function ViewModeButtons({ pageStore }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {pageStore.editing.isCanEditing && (
        <Tooltip text={t('common:edit')} placement="bottomRight">
          <button className={styles.button} onClick={pageStore.enableEditing}>
            <EditIcon />
          </button>
        </Tooltip>
      )}
      {/* Временно отключенный функционал */}
      {/* <Tooltip text={t('common:historyOfChange')} placement="bottomRight">
        <button className={styles.button}>
          <HistoryIcon />
        </button>
      </Tooltip> */}
    </div>
  );
});
