import { Dnd } from '../../types';

export class DroppableActive<TDroppableItem> {
  readonly id: string;
  readonly element: HTMLElement;
  readonly dataItem: Dnd.Droppable & TDroppableItem;

  constructor(id: string, element: HTMLElement, dataItem: Dnd.Droppable & TDroppableItem) {
    this.id = id;
    this.element = element;
    this.dataItem = dataItem;
  }
}
