// import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';

import {
  BaseApiError,
  NetworkApiError,
  Server500ApiError,
  Unauthorized401ApiError,
  UnknownApiError,
  OperationAbortedError,
  Validation400ApiErrorWithCause,
  NotFoundApiError,
} from 'src/errors';

export function isAxiosError(e: unknown): e is AxiosError<any> {
  return axios.isAxiosError(e);
}

export function isCancelError(e: unknown) {
  return axios.isCancel(e);
}

export function throwApiError(e: unknown): never {
  if (e instanceof BaseApiError) {
    throw e;
  }

  if (isAxiosError(e)) {
    if (isCancelError(e)) {
      throw new OperationAbortedError();
    }

    if (!e.response) {
      throw new NetworkApiError(e);
    }
    if (e.response.status >= 500) {
      throw new Server500ApiError(e);
    }
    if (e.response.status === 401) {
      throw new Unauthorized401ApiError(e);
    }
    if (e.response.status === 400) {
      const messageRaw = e.response.data?.message;
      let parsedMessage = undefined;
      if (Array.isArray(messageRaw)) {
        if (typeof messageRaw[0] === 'string') {
          parsedMessage = messageRaw[0];
        }
      }
      if (typeof messageRaw === 'string') {
        parsedMessage = messageRaw;
      }
      throw new Validation400ApiErrorWithCause<unknown>(e, e.response.data, parsedMessage);
    }
    if (e.response.status === 404) {
      throw new NotFoundApiError(e);
    }
    throw new UnknownApiError(e);
  }
  throw e;
}
