import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'moment/locale/ru';
import 'antd/dist/antd.dark.css';
import 'src/styles/index.scss';
import 'src/declarations';
import 'src/configure';

import { App } from 'src/app';
import { configureHighchartsLabels } from 'src/pages/dashboard-page/utils/data-labels-placement';
import 'src/i18n';
import reportWebVitals from 'src/reportWebVitals';

import 'src/polyfills';
import { initializeSentry } from './api/sentry/sentry';

initializeSentry();
configureHighchartsLabels();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
