import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { mergeRefs } from 'src/shared/utils/merge-refs';

import { ChartRig } from '../../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useDragging } from '../../sorting/hooks/use-dragging';
import { SortingContextStore } from '../../sorting/sorting-context.store';

import styles from './data-items.module.scss';

export interface Props {
  item: ChartRig;
  verticalViewport: Viewport;
  className?: string;
}

const draggingOptions: SortingContextStore.DraggingOptions = {
  draggingClassName: styles.ghostRigRow,
};

export const RigRow = observer(function RigRow({
  item,
  verticalViewport,
  className,
  children,
}: PropsWithChildren<Props>) {
  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging-row'),
    item,
    options: draggingOptions,
  });

  const ref = useMovableElement({
    item,
    verticalViewport,
  });

  return <div ref={mergeRefs(ref, draggingRef)} className={clsx(className, styles.rigRow)} children={children} />;
});
