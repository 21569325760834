import { Dnd } from '../../../features/editing/types';
import { Range } from '../../../layers/model';

export class InsertionPoint<TNeighbor> implements Dnd.Droppable {
  private readonly options?: InsertionPoint.Options;

  readonly uniqueCreationKey: string;
  readonly x: Dnd.PositionRange;
  readonly y: Range<number>;
  readonly isAvailable: boolean;
  readonly left?: TNeighbor;
  readonly right?: TNeighbor;

  constructor(
    x: Dnd.PositionRange,
    y: Range<number>,
    uniqueCreationKey: string,
    isAvailable: boolean,
    left?: TNeighbor,
    right?: TNeighbor,
    options?: InsertionPoint.Options
  ) {
    this.uniqueCreationKey = uniqueCreationKey;
    this.x = x;
    this.y = y;
    this.isAvailable = isAvailable;
    this.options = options;
    this.left = left;
    this.right = right;
  }

  get expandLeft(): boolean {
    return this.options?.expand === 'left';
  }

  get expandRight(): boolean {
    return this.options?.expand === 'right';
  }

  get minStartX(): number | undefined {
    return this.options?.minStartX ?? this.x?.start ?? undefined;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-insertion-point-${this.uniqueCreationKey}`;
    }

    return `insertion-point-${this.uniqueCreationKey}`;
  }
}

export namespace InsertionPoint {
  export type Options = Partial<{
    expand: 'left' | 'right';
    minStartX: number;
  }>;
}
