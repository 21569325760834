import { observer } from 'mobx-react-lite';

import { FormMultiSelect } from 'src/shared/components/form-components/form-multiselect/form-multiselect';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { MultiCombobox } from 'src/shared/entities/control-entities';

import styles from './filters-modal.module.scss';

interface Props {
  item: Item;
  label?: string;
  container?: HTMLDivElement | null;
}

export const FiltersItem = observer(function FiltersItem({ item, label, container }: Props) {
  if (item instanceof MultiCombobox) {
    return (
      <div className={styles.select}>
        <FormMultiSelect
          className={styles.multiselect}
          options={item.options}
          value={item.value}
          onChange={item.setValue}
          label={label}
          getPopupContainer={container ? () => container : undefined}
        />
      </div>
    );
  }

  return null;
});
