import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { PadCard } from '../../../pad-card';
import { PadRigOperation } from '../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';
import { useSortableGhost } from '../sorting/hooks/use-sortable-ghost';
import { SortableGhostItem } from '../sorting/sortable-ghost-item';

import styles from './pad-rig-operation-wrapper.module.scss';

interface Props {
  item: SortableGhostItem<PadRigOperation>;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  className?: string;
  cardClassName?: string;
  headerClassName?: string;
}

export const GhostPadRigOperationWrapper = observer(function GhostPadRigOperationWrapper({
  item: ghostWrapper,
  horizontalViewport,
  verticalViewport,
  className,
  cardClassName,
  headerClassName,
  viewSettingsProvider,
}: Props) {
  const { transform } = useSortableGhost({
    id: ghostWrapper.activeKey,
  });

  const ref = useMovableElement({
    item: ghostWrapper,
    horizontalViewport,
    verticalViewport,
    transform: transform || undefined,
  });

  if (!ghostWrapper.options?.isShown) {
    return null;
  }

  const padAttributes = viewSettingsProvider.getPadAttributes(ghostWrapper.ghost);

  const padIcons = padAttributes?.length && (
    <div className={styles.iconsContainer}>
      {padAttributes?.map((attributeName) => (
        <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
      ))}
    </div>
  );

  return (
    <div ref={ref} className={clsx(className, styles.container, styles.sortableDragging)}>
      <PadCard
        title={<LoadingTitle field={viewSettingsProvider.getTitle(ghostWrapper.ghost)} />}
        extra={padIcons}
        className={classNames(cardClassName, styles.card)}
        attributes={viewSettingsProvider.getAttributes(ghostWrapper.ghost)}
        headerClassName={headerClassName}
      />
    </div>
  );
});
