import { action, computed, makeObservable, observable } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { RigsChartViewTypes } from 'src/api/chart/rigs-chart-api';

import { ViewSettingsHelper } from '../../shared/view-settings-helper';

type TRigsChartViewWithProcessedSettings = Omit<RigsChartViewTypes.RigsChartView, 'settings'> & {
  settings: ViewSettings.TypedSettingsControls;
};

export class RigsChartView {
  @observable view: TRigsChartViewWithProcessedSettings;
  /** Rig/well/pad attributes display settings. */
  @observable infoSettingsValues: ViewSettings.InfoSettingsValues = null!;
  /** Settings that not depend on view object. */
  @observable ownSettingsValues: ViewSettings.RigsOwnSettingsValues = null!;

  constructor(view: RigsChartViewTypes.RigsChartView, settingsValues: ViewSettings.RigsChartRawSettingsValues) {
    this.view = this.processViewSettings(view);

    this.setSettingsValues(settingsValues);

    makeObservable(this);
  }

  private processViewSettings(view: RigsChartViewTypes.RigsChartView): TRigsChartViewWithProcessedSettings {
    const keys = Object.keys(view.settings).sort(
      (first, second) => (view.settings[first]?.order ?? 0) - (view.settings[second]?.order ?? 0)
    );

    return {
      ...view,
      settings: keys.reduce((settings: TRigsChartViewWithProcessedSettings['settings'], settingKey) => {
        settings[settingKey] = ViewSettingsHelper.getTypedSetting(view.settings[settingKey]);

        return settings;
      }, {}),
    };
  }

  @action
  setSettingsValues = (settingsValues: ViewSettings.RigsChartRawSettingsValues): void => {
    this.infoSettingsValues =
      settingsValues.info && ViewSettingsHelper.checkSettingsValues(settingsValues.info) ? settingsValues.info : {};

    this.ownSettingsValues = {
      rigsOrder: settingsValues.rigsOrder,
    };
  };

  @action.bound
  setView(view: RigsChartViewTypes.RigsChartView): void {
    this.view = this.processViewSettings(view);
  }

  @computed
  get settings(): ViewSettings.TypedSettingsControls {
    return this.view.settings;
  }

  @computed
  get shownWellAttributesNumber(): number {
    const wellsSettings = this.infoSettingsValues['wellTab'];

    if (!wellsSettings) {
      return 0;
    }

    if (wellsSettings.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
      return wellsSettings?.settings.reduce((shownAttributesNumber, currentAttribute) => {
        return shownAttributesNumber + Number(currentAttribute.isShown);
      }, 0);
    } else {
      return wellsSettings?.settings.reduce((shownAttributesNumber, group) => {
        return (
          shownAttributesNumber +
          group.items.reduce((shownAttributesNumber, currentAttribute) => {
            return shownAttributesNumber + Number(currentAttribute.isShown);
          }, 0)
        );
      }, 0);
    }
  }
}
