import { assert } from 'src/shared/utils/assert';

export function tryParseNumber(value: unknown, isFloat: boolean = false): number | undefined {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    const parseFn = isFloat ? Number.parseFloat : Number.parseInt;
    const valueNumber = parseFn(value);

    assert(!Number.isNaN(valueNumber), `Failed to parse value. Value: ${value}`);

    return valueNumber;
  }

  throw new Error('Failed to parse value: type of value is not string or number.');
}
