import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from 'src/shared/components/table-cell/compare-table-cell/compare-table-cell.module.scss';
import { hasValue } from 'src/shared/utils/common';

interface Props {
  firstValue: string | number | null;
  secondValue: string | number | null;
  maxRows?: number;
  isImportOccurred?: boolean;
}

export const CompareTableCell = observer(function CompareTableCell({
  firstValue,
  secondValue,
  maxRows,
  isImportOccurred,
}: Props) {
  function renderCompareValue(): React.ReactNode | null {
    if (hasValue(firstValue) && hasValue(secondValue)) {
      return (
        <Typography.Paragraph ellipsis={{ rows: maxRows }}>
          {isImportOccurred ? <del>{firstValue}</del> : <span>{firstValue}</span>} / <span>{secondValue}</span>
        </Typography.Paragraph>
      );
    }

    if (!hasValue(firstValue) && hasValue(secondValue)) {
      return <Typography.Paragraph ellipsis={{ rows: maxRows }}>{secondValue}</Typography.Paragraph>;
    }

    if (hasValue(firstValue) && !hasValue(secondValue)) {
      return (
        <Typography.Paragraph ellipsis={{ rows: maxRows }} delete>
          {secondValue}
        </Typography.Paragraph>
      );
    }

    return null;
  }

  return (
    <div className={styles.cellContainer} style={{ WebkitLineClamp: maxRows }}>
      {renderCompareValue()}
    </div>
  );
});
