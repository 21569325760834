import { action, comparer, computed, makeObservable } from 'mobx';

import { IChartDataModel } from '../../../layers/model';
import { IPresenter } from '../../../layers/presenter';
import { TimelineController } from '../../../shared/timeline-controller';
import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport, normalizeScrollOffset, zoomViewport } from '../../../shared/viewport/viewport-calculator';

export class DataItemsBackgroundPresenter<TData> implements IPresenter<TData, number> {
  private isMoveMode = false;

  private readonly verticalViewport: Viewport;
  private readonly horizontalViewport: Viewport;
  private readonly dataModel: IChartDataModel<TData>;
  private readonly viewportController: TimelineController;

  constructor(
    verticalViewport: Viewport,
    horizontalViewport: Viewport,
    dataModel: IChartDataModel<TData>,
    viewportController: TimelineController
  ) {
    this.verticalViewport = verticalViewport;
    this.horizontalViewport = horizontalViewport;
    this.dataModel = dataModel;
    this.viewportController = viewportController;

    makeObservable(this);
  }

  @computed({ equals: comparer.shallow })
  get data(): TData {
    return this.dataModel.data;
  }

  @action.bound
  onPointerDown(): void {
    this.isMoveMode = true;
  }

  @action.bound
  onPointerMove(offsetX: number, offsetY?: number): void {
    if (!this.isMoveMode) {
      return;
    }

    const { movedStart, movedEnd } = moveViewport(this.horizontalViewport, offsetX);
    this.horizontalViewport.setRange(movedStart, movedEnd);

    if (offsetY) {
      const { movedStart, movedEnd } = moveViewport(this.verticalViewport, offsetY);
      this.verticalViewport.setRange(movedStart, movedEnd);
    }
  }

  @action.bound
  onPointerUp(): void {
    this.isMoveMode = false;

    const { start: viewportStart, end: viewportEnd } = this.horizontalViewport;
    const startNearestPoint = this.viewportController.getNearestPoint(viewportStart);
    const endNearestPoint = this.viewportController.getNearestPoint(viewportEnd);

    this.horizontalViewport.setRange(startNearestPoint, endNearestPoint - 1, { animate: true });
  }

  @action.bound
  onScroll(offset: number): void {
    const { movedStart, movedEnd } = moveViewport(this.verticalViewport, normalizeScrollOffset(offset));

    this.verticalViewport.setRange(movedStart, movedEnd);
  }

  @action.bound
  onZoom(offset: number, centerPosition: number): void {
    const { zoomedStart, zoomedEnd } = zoomViewport(this.horizontalViewport, offset, centerPosition);

    this.horizontalViewport.setRange(zoomedStart, zoomedEnd);
  }

  @action.bound
  onZoomOut(): void {
    const { start: viewportStart, end: viewportEnd } = this.horizontalViewport;
    const startNearestPoint = this.viewportController.getNearestPoint(viewportStart);
    const endNearestPoint = this.viewportController.getNearestPoint(viewportEnd);

    this.horizontalViewport.setRange(startNearestPoint, endNearestPoint - 1, { animate: true });
  }
}
