import { action, computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { hasValue } from '../../../../../../shared/utils/common';
import { Dnd } from '../../types';

export class DraggableTransform {
  @observable private x: number;
  @observable private y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;

    makeObservable(this);
  }

  @computed
  get value(): Dnd.Transform {
    return { x: this.x, y: this.y };
  }

  @action.bound
  setValue(value: Partial<Dnd.Transform>): void {
    if (hasValue(value.x)) {
      this.x = value.x;
    }

    if (hasValue(value.y)) {
      this.y = value.y;
    }
  }

  @action.bound
  add(value: Partial<Dnd.Transform>): void {
    if (hasValue(value.x)) {
      this.x += value.x;
    }

    if (hasValue(value.y)) {
      this.y += value.y;
    }
  }

  toCSSString = computedFn((fixY?: boolean, initialOffsetX?: number): string => {
    const getY = (): number => {
      if (!fixY) {
        return this.value.y ?? 0;
      }

      return 0;
    };

    const getX = (): number => {
      return (initialOffsetX ?? 0) + this.value.x;
    };

    return `translate(${getX()}px, ${getY()}px)`;
  });
}
