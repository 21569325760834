import { action, computed, makeObservable, observable } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { WellsChartViewTypes } from 'src/api/chart/wells-chart-api';

import { ViewSettingsHelper } from '../../shared/view-settings-helper';

type TWellsChartViewWithProcessedSettings = Omit<WellsChartViewTypes.View, 'settings'> & {
  settings: ViewSettings.TypedSettingsControls;
};

export class WellsChartView {
  @observable view: TWellsChartViewWithProcessedSettings;
  @observable infoSettingsValues: ViewSettings.InfoSettingsValues;
  @observable ownSettingsValues: ViewSettings.WellsOwnSettingsValues;

  constructor(view: WellsChartViewTypes.View, settingsValues: ViewSettings.WellsChartRawSettingsValues) {
    this.view = this.processViewSettings(view);
    this.infoSettingsValues =
      settingsValues.info && ViewSettingsHelper.checkSettingsValues(settingsValues.info) ? settingsValues.info : {};
    this.ownSettingsValues = {
      wellsOrder: settingsValues.wellsOrder,
    };

    makeObservable(this);
  }

  private processViewSettings(view: WellsChartViewTypes.View): TWellsChartViewWithProcessedSettings {
    const keys = Object.keys(view.settings);

    return {
      ...view,
      settings: keys.reduce((settings: TWellsChartViewWithProcessedSettings['settings'], settingKey) => {
        return {
          ...settings,
          [settingKey]: ViewSettingsHelper.getTypedSetting(view.settings[settingKey]),
        };
      }, {}),
    };
  }

  @action.bound
  setView(view: WellsChartViewTypes.View): void {
    this.view = this.processViewSettings(view);
  }

  @action.bound
  setSettingsValues(settingsValues: ViewSettings.RawSettingsValues): void {
    if (settingsValues.info) {
      this.infoSettingsValues = settingsValues.info;
    }
  }

  @computed
  get settings(): ViewSettings.TypedSettingsControls {
    return this.view.settings;
  }

  @computed
  get shownStageAttributesNumber(): number {
    const stageSettings = this.infoSettingsValues?.['stageTab'];

    if (!stageSettings) {
      return 0;
    }

    if (stageSettings.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
      return stageSettings?.settings.reduce((shownAttributesNumber, currentAttribute) => {
        return shownAttributesNumber + Number(currentAttribute.isShown);
      }, 0);
    } else {
      return stageSettings?.settings.reduce((shownAttributesNumber, group) => {
        return (
          shownAttributesNumber +
          group.items.reduce((shownAttributesNumber, currentAttribute) => {
            return shownAttributesNumber + Number(currentAttribute.isShown);
          }, 0)
        );
      }, 0);
    }
  }
}
