import { flow, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { AppSettings, AppSettingsApi } from 'src/api/app/app-settings-api';
import { isWellTypeIconName, WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { hasValue } from 'src/shared/utils/common';

export class AppSettingsStore {
  private readonly api = new AppSettingsApi();

  @observable wellColorRules?: AppSettings.ColorRule[];
  @observable wellIconRules?: AppSettings.WellIconRules;

  constructor() {
    makeObservable(this);
  }

  @flow.bound
  private async *loadWellColorRules(): Promise<void> {
    const colorRules = await this.api.getWellColorRules();
    yield;

    if (colorRules) {
      this.wellColorRules = colorRules;
    }
  }

  @flow.bound
  private async *loadWellIconRules(): Promise<void> {
    const wellIconRules = await this.api.getWellIconRules();
    yield;

    if (wellIconRules) {
      this.wellIconRules = wellIconRules;
    }
  }

  init(): void {
    this.loadWellColorRules();
    this.loadWellIconRules();
  }

  getWellIcon = computedFn((well: Record<string, unknown>, wellTypeFieldName: string): WellTypeIcon | undefined => {
    const wellTypeFieldValue = well[wellTypeFieldName];

    if (this.wellIconRules && hasValue(wellTypeFieldValue)) {
      const wellTypeId = Number(wellTypeFieldValue);
      const iconName = this.wellIconRules[wellTypeId];

      if (isWellTypeIconName(iconName)) {
        return iconName;
      }
    }
  });
}
