import { agent } from 'src/api/agent';
import { ObjectResponse } from 'src/api/types';
import { throwApiError } from 'src/api/utils';

export class AppSettingsApi {
  async getWellColorRules(): Promise<AppSettings.ColorRule[] | undefined> {
    try {
      const { data } = await agent.get<ObjectResponse<AppSettings.ResponseValue<AppSettings.ColorRule[]>>[]>(
        'object/list/GOplan_AppSettings/',
        {
          params: { key: 'colors' },
        }
      );

      return data.at(0)?.data.value;
    } catch (e) {
      throwApiError(e);
    }
  }

  async getWellIconRules(): Promise<AppSettings.WellIconRules | undefined> {
    try {
      const { data } = await agent.get<ObjectResponse<AppSettings.ResponseValue<AppSettings.WellIconRules>>[]>(
        'object/list/GOplan_AppSettings/',
        {
          params: { key: 'wellTypeIcons' },
        }
      );

      return data.at(0)?.data.value;
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace AppSettings {
  export const Colors: Record<string, string> = {
    blue: '#7DA8FF',
    green: '#219653',
    yellow: '#F2C94C',
    purple: '#BD69FF',
    gray: '#808080',
    white: '#FFFFFF',
  };

  export const DEFAULT_COLOR = Colors.white;

  export const isColor = (color: unknown): color is string =>
    typeof color === 'string' && Object.keys(Colors).includes(color);

  export type ColorRuleBaseCondition = {
    attr: string;
    value: unknown;
  };

  export type ColorRuleCondition = ColorRuleBaseCondition & {
    or?: ColorRuleBaseCondition[];
  };

  export type ColorRule = {
    color: string;
    conditions: ColorRuleCondition[];
  };

  export type WellIconRules = Record<string, string>;

  export type ResponseValue<TValue> = {
    key: string;
    value: TValue;
  };
}
