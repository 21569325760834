import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import styles from './data-header.module.scss';

interface Props {
  className?: string;
}

export const DataItem = observer(function DataItem({ children, className }: PropsWithChildren<Props>) {
  return <div className={clsx(className, styles.rowDataItem)}>{children}</div>;
});
