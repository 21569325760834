import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { getSub } from 'src/shared/utils/auth';
import { TPlanVersion } from 'src/store/comparison/types';

export class DraftApi {
  async getVersions(): Promise<TPlanVersion[]> {
    try {
      const { data } = await agent.get<TPlanVersion[]>('versions', {
        params: {
          ownerUserId: getSub(),
          isDraft: true,
        },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async blockPublicDraft(): Promise<void> {
    try {
      await agent.post('versions/blockPublicDraft');
    } catch (e) {
      throwApiError(e);
    }
  }

  async unblockPublicDraft(): Promise<void> {
    try {
      await agent.post('versions/unblockPublicDraft');
    } catch (e) {
      throwApiError(e);
    }
  }
}
