import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { DrillingElementColoredCard } from '../../../../drilling-element-colored-card';
import { WellTypeIcon } from '../../../../well-type-icon';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useClickHandler } from '../../../shared/use-click-handler';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { DndContext } from '../../editing/view/context/dnd-context';
import { useDraggableGhostShadow } from '../../editing/view/hooks/use-draggable-ghost-shadow';
import { ShadowWellRigOperation } from '../presenter/shadow-well-rig-operation';

import styles from './data-items-full.module.scss';

interface Props {
  item: ShadowWellRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onClick?: VoidFunction;
  className?: string;
  cardClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
}

export const ShadowWellRigOperationWrapper = observer(function ShadowWellRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  onClick,
  viewSettingsProvider,
  className,
  cardClassName,
  contentClassName,
  headerClassName,
}: Props) {
  const { transform, getRelativeCoordinates } = useDraggableGhostShadow({
    parentId: item.draggableParentKey,
  });

  const { containerRect } = useContext(DndContext);

  const ref = useMovableElement({
    item,
    transform,
    verticalViewport,
    horizontalViewport,
    options: {
      marginLeft: !transform,
      fixY: true,
      initialOffsetX: item.x.start - (item.parentPad?.x.start ?? 0),
    },
    containerWidth: containerRect?.width,
    getRelativeTransform: getRelativeCoordinates,
  });

  const { onPointerDown, onPointerUp } = useClickHandler(onClick);

  const wellIcon = viewSettingsProvider.getWellIcon(item.data);

  return (
    <div ref={ref} className={clsx(className, styles.dataItem, styles.draggableWellShadow)}>
      <DrillingElementColoredCard
        title={viewSettingsProvider.getTitle(item)}
        color={viewSettingsProvider.getWellColor(item.data) || ''}
        attributes={viewSettingsProvider.getAttributes(item)}
        icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
        className={classNames(cardClassName, styles.wellCard)}
        contentClassName={contentClassName}
        headerClassName={headerClassName}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
      />
    </div>
  );
});
