import { action, flow, makeObservable, observable } from 'mobx';

import { ConflictApi } from 'src/api/draft/conflict-api';
import { ConflictWell } from 'src/api/draft/conflict-well';
import { BaseApiError } from 'src/errors';
import { ConflictResolvingError } from 'src/errors/conflicts';
import { RigConflictsSidebarStore } from 'src/features/drilling-rig-conflicts-sidebar/store';
import { Pad } from 'src/features/drilling-rig-conflicts-sidebar/store/pad';
import { RigStore } from 'src/features/drilling-rig-conflicts-sidebar/store/rig.store';
import { Well } from 'src/features/drilling-rig-conflicts-sidebar/store/well';
import { ViewMode } from 'src/features/drilling-rig-sidebar/store/types';
import { ViewPadsSidebarStore } from 'src/features/drilling-rig-sidebar/view-pads-sidebar/view-pads-sidebar.store';
import { assert } from 'src/shared/utils/assert';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { createPromiseController, TPromiseController } from 'src/shared/utils/promise-controller';
import { RootStore } from 'src/store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { ConflictsTypes } from 'src/types/conflicts-types';

export class ConflictResolver {
  private readonly api = new ConflictApi();
  private readonly rigConflictSidebar: RigConflictsSidebarStore;
  private readonly notifications: NotificationsStore;

  private resolvingControls?: TPromiseController<null>;

  readonly viewPadsSidebar: ViewPadsSidebarStore;

  @observable isConflictResolving = false;
  @observable isConflictsChecking = false;

  constructor(rootStore: RootStore) {
    this.rigConflictSidebar = rootStore.rigConflictSidebar;
    this.viewPadsSidebar = new ViewPadsSidebarStore(rootStore);
    this.notifications = rootStore.notifications;

    makeObservable(this);
  }

  private async placeConflictRigOperation(
    planVersionId: number,
    rigId: number,
    conflictPosition: RigStore.ConflictPosition
  ): Promise<void> {
    const { insert, insertAfter, insertOnPlace } = conflictPosition;

    const isConflictPlacesBetweenPads =
      (insertAfter || insertOnPlace) &&
      (!insertAfter || insertAfter instanceof Pad) &&
      (!insertOnPlace || insertOnPlace instanceof Pad);
    const isConflictPlacedBetweenWells =
      (insertAfter || insertOnPlace) &&
      (!insertAfter || insertAfter instanceof Well) &&
      (!insertOnPlace || insertOnPlace instanceof Well);

    if (!isConflictPlacesBetweenPads && !isConflictPlacedBetweenWells) {
      throw new Error('Conflict rig operation is not placed.');
    }

    if (isConflictPlacedBetweenWells) {
      const insertId = insert.id;
      const insertAfterId = insertAfter?.id;
      const insertOnPlaceId = !insertAfter ? insertOnPlace?.id : undefined;

      await this.api.placeConflictRigOperation(planVersionId, insertId, rigId, insertAfterId, insertOnPlaceId);
    } else if (isConflictPlacesBetweenPads) {
      const insertId = insert.id;
      const insertAfterId = insertAfter?.items.at(-1)?.id;
      const insertOnPlaceId = !insertAfter ? insertOnPlace?.items.at(0)?.id : undefined;

      await this.api.placeConflictRigOperation(planVersionId, insertId, rigId, insertAfterId, insertOnPlaceId);
    }
  }

  @action.bound
  private async onConflictSidebarApply(planVersionId: number, rigId: number): Promise<void> {
    const conflictPosition = this.rigConflictSidebar.rigStore?.conflictWellPosition;

    assert(conflictPosition, 'Conflict rig operation is not placed.');

    await this.placeConflictRigOperation(planVersionId, rigId, conflictPosition);

    const viewPadsControls = createPromiseController<null>();

    await this.viewPadsSidebar.open(planVersionId, rigId, ViewMode.pads, () => viewPadsControls.resolve(null));

    await viewPadsControls;
  }

  @flow.bound
  async *resolveConflict(
    conflictRigOperation: ConflictsTypes.RawRigOperation,
    planVersionId: number,
    withoutPreloader = false
  ): Promise<boolean> {
    if (!conflictRigOperation) {
      return true;
    }

    try {
      if (!withoutPreloader) {
        this.isConflictResolving = true;
      }

      const rigIdFieldName = addGOplanPrefix('RigOperation.rigId');
      const rigIdNumber = Number(conflictRigOperation[rigIdFieldName]);

      assert(!Number.isNaN(rigIdNumber), 'Invalid rig ID.');

      const idFieldName = addGOplanPrefix('PlanWellTriple.id');
      const idFieldValue = conflictRigOperation[idFieldName];

      assert(typeof idFieldValue === 'number', `Invalid rig operation ID (field: ${idFieldName}).`);

      this.resolvingControls = createPromiseController();

      await this.rigConflictSidebar.open(
        planVersionId,
        rigIdNumber,
        new ConflictWell(conflictRigOperation, idFieldValue),
        async () => {
          await this.onConflictSidebarApply(planVersionId, rigIdNumber);

          this.resolvingControls?.resolve(null);
        },
        async () => {
          this.resolvingControls?.reject(new ConflictResolvingError('The conflict resolution process was rejected.'));
        }
      );

      await this.resolvingControls;

      await this.checkConflicts(planVersionId, withoutPreloader);
      yield;

      return true;
    } catch (e) {
      yield;

      console.error(e);

      if (e instanceof ConflictResolvingError) {
        throw e;
      } else if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      } else {
        this.notifications.showErrorMessageT('errors:failedToResolveConflict');
        return false;
      }
    } finally {
      if (!withoutPreloader) {
        this.isConflictResolving = false;
      }
    }
  }

  @flow.bound
  async *checkConflicts(planVersionId: number, withoutPreloader: boolean = false): Promise<void> {
    let conflictRigOperation: ConflictsTypes.RawRigOperation | undefined;

    try {
      if (!withoutPreloader) {
        this.isConflictsChecking = true;
      }

      conflictRigOperation = await this.api.checkConflicts(planVersionId);
    } catch (e) {
      yield;

      console.error(e);

      if (e instanceof ConflictResolvingError) {
        throw e;
      } else {
        this.notifications.showErrorMessageT('errors:failedToCheckConflicts');
      }
    } finally {
      if (!withoutPreloader) {
        this.isConflictsChecking = false;
      }
    }

    if (conflictRigOperation) {
      await this.resolveConflict(conflictRigOperation, planVersionId, withoutPreloader);
    }
  }
}
