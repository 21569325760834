import { reaction } from 'mobx';

import { hasValue } from 'src/shared/utils/common';
import { getArgumentsValues } from 'src/shared/utils/formula-calculation-tools';

import { Item } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class CalculatedValuePlugin extends FormPlugin {
  connect(form: FormStore): void | VoidFunction {
    this.form = form;

    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.calculatedValue) {
        const rawFormula = item.calculatedValue;
        const disposer = reaction(
          () => ({
            args: getArgumentsValues(form.fields, this.rootStore.directories, rawFormula.arguments),
            isItemDisabled: item.isDisabled,
          }),
          ({ args, isItemDisabled }) => {
            const argsNames = Object.keys(args).join(', ');
            const argsValues = Object.values(args);

            // eslint-disable-next-line no-new-func
            const getControlValue = new Function(argsNames, rawFormula.body);

            const calculatedValue: unknown = getControlValue(...argsValues);

            if (hasValue(item.initialValue) && !hasValue(item.valueFromDirectory)) {
              if (item.initialValue !== calculatedValue) {
                item.setValueFromDirectory(calculatedValue);

                return;
              }
            }

            if (!isItemDisabled) {
              item.tryToSetRawValue(calculatedValue);
            }
            item.setValueFromDirectory(calculatedValue);
          },
          { fireImmediately: true }
        );

        disposers.push(disposer);
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
