import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { IEqual } from 'src/types/common';

import { Dnd } from '../../../features/editing/types';
import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';
import { RigsDataPositionsCalculator } from '../../../shared/rigs-data-positions-calculator';

import { ChartRig } from './chart-rig';
import { TemporaryRigsGroup } from './temporary-rigs-group';

export class RigsGroup
  implements RigsChartDataModel.IChartRigsGroup<ChartRig>, IEqual<RigsGroup>, Dnd.Draggable<RigsGroup>
{
  private readonly item: RigsChart.RawGroupData;

  readonly id: number;
  readonly uniqueCreationKey: string;
  readonly x = null;

  temporaryRigsGroup: TemporaryRigsGroup | null = null;

  @observable items: ChartRig[];
  @observable isCollapsed = RigsDataPositionsCalculator.DEFAULT_IS_COLLAPSED;
  @observable y: Range<number> = { start: 0, end: 0 };
  @observable rowsStart: number | null = null;
  @observable rowsEnd: number | null = null;

  constructor(item: RigsChart.RawGroupData, id: number, rigs: ChartRig[]) {
    this.item = item;
    this.id = id;
    this.uniqueCreationKey = uuidv4();
    this.items = rigs;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-rigs-group-${this.uniqueCreationKey}`;
    }

    return `rigs-group-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): unknown {
    return this.item.data[fieldName];
  }

  getState(): RigsGroup.State {
    return {
      isCollapsed: this.isCollapsed,
    };
  }

  isEqual(rigsGroup: RigsGroup): boolean {
    return this.id === rigsGroup.id;
  }

  @action.bound
  setY(value: Range<number>): void {
    this.y = value;
  }

  @action.bound
  setRowsY(rowsY: Range<number | null>): void {
    this.rowsStart = rowsY.start;
    this.rowsEnd = rowsY.end;
  }

  @action.bound
  setIsCollapsed(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;
  }

  setRelatedTemporaryGroup(group: TemporaryRigsGroup): void {
    this.temporaryRigsGroup = group;
  }

  removeRelatedTemporaryRigsGroup(): void {
    this.temporaryRigsGroup = null;
  }

  setRigs(rigs: ChartRig[]): void {
    this.items = rigs;
  }

  clone(): RigsGroup {
    const rigsGroupClone = new RigsGroup(this.item, this.id, [...this.items]);

    rigsGroupClone.setY({ ...this.y });
    rigsGroupClone.setIsCollapsed(this.isCollapsed);
    rigsGroupClone.setRowsY({ start: this.rowsStart, end: this.rowsEnd });

    return rigsGroupClone;
  }
}

export namespace RigsGroup {
  export type State = {
    isCollapsed: boolean;
  };
}
