const formatNumberString = (numberStr: string): string => {
  const [integer, fraction] = numberStr.split('.');

  const formattedInteger = integer
    .split('')
    .reverse()
    .map((symb, index, arr) => {
      if ((index + 1) % 3 === 0 && index + 1 !== arr.length) {
        return ` ${symb}`;
      }
      return symb;
    })
    .reverse()
    .join('');

  if (fraction !== undefined) {
    return `${formattedInteger}.${fraction}`;
  }

  return formattedInteger;
};

export const processNumberValue = (
  value: string | number | null | undefined,
  isInteger: boolean,
  numberOfDecimalPlaces: number
): string | null => {
  if ((!value && value !== 0) || Number.isNaN(Number(value))) return null;

  //Данные преобразования нужны для сокращения больших дробей до 6 знаков и отбрасывания ненужных нулей в конце
  if (isInteger) {
    return formatNumberString(Number(Math.trunc(Number(value)).toFixed(0)).toString());
  }
  return formatNumberString(Number(Number(value).toFixed(numberOfDecimalPlaces)).toString());
};

export const processInputValue = (value: string | null): string | null => {
  if (value === null) return null;
  return value
    .replaceAll(',', '.')
    .split('')
    .filter((letter) => letter.match(/[0-9-.]/))
    .join('');
};
