import { Indicators } from 'src/api/chart/indicators-api';

export class IndicatorColumn {
  private readonly item: Indicators.Column;

  constructor(item: Indicators.Column) {
    this.item = item;
  }

  get start(): number {
    return this.item.start;
  }

  get end(): number {
    return this.item.end;
  }

  getValue(fieldName: string): number | undefined {
    if (Indicators.isColumnKey(fieldName)) {
      return this.item[fieldName];
    }
  }
}
