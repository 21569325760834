import { TDirectoriesAttributesDictionary } from 'src/store/directories/types';

import { agent } from '../agent';
import { throwApiError } from '../utils';

import { mapDirectoryTypeAttributes } from './serializers/map-directory-type-attributes';
import { TDirectoryTypeAttributeRaw } from './types';

export const fetchDirectoryTypeAttributes = async (
  type: string,
  attr: string
): Promise<TDirectoriesAttributesDictionary> => {
  try {
    const res = await agent.get<TDirectoryTypeAttributeRaw[]>(`/type/${type}`);
    return mapDirectoryTypeAttributes(attr, res.data);
  } catch (error) {
    throwApiError(error);
  }
};
