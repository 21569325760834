import { IntegerField } from 'src/shared/entities/control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TIntegerFieldRaw, TSavedFormValue } from '../../types';

type TMapIntegerFieldItemOptions = {
  item: TIntegerFieldRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapIntegerFieldItem = ({ item, directories, savedValue }: TMapIntegerFieldItemOptions): IntegerField => {
  const fieldProps = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    unit: directories.getFieldUnit(item.fieldId) || item?.item || undefined,
    validate: item.validate,
    enableIf: item.enableIf,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    calculatedValue: item.calculatedValue,
    visuallyDisabled: item.visuallyDisabled,
    validationTags: item.validationTags,
    useInMainProgressBar: !!item?.useInMainProgressBar,
    restrictions: { required: !!item.required, ...item.restrictions },
    initialValue: typeof savedValue === 'number' ? savedValue : undefined,
  };

  return new IntegerField(fieldProps);
};
