import { action, makeObservable, observable } from 'mobx';

import { DEFAULT_TIME_RANGE } from 'src/features/drilling-chart/features/header/data/header-data-model.constants';
import { TimelineDataApi } from 'src/features/drilling-chart/features/timeline/data/timeline-data-api';
import { TimelineDataModel } from 'src/features/drilling-chart/features/timeline/data/timeline-data-model';
import { TimelinePresenter } from 'src/features/drilling-chart/features/timeline/presenter/timeline-presenter';
import { TimeUnit } from 'src/features/drilling-chart/shared/time-unit';
import { Viewport } from 'src/features/drilling-chart/shared/viewport/viewport';

import { TimelineController } from '../../../features/drilling-chart/shared/timeline-controller';
import { getPeriodRange } from '../features/dashboard/utils';

export class TimelineStore {
  private readonly timelineDataModel: TimelineDataModel;
  private readonly timelineApi: TimelineDataApi;
  private readonly timelineController: TimelineController;

  readonly horizontalViewport: Viewport;
  readonly timelineViewport: Viewport;
  readonly timelineDataRange: Viewport;
  readonly timelinePresenter: TimelinePresenter;

  @observable selectedPeriod: TimeUnit;

  constructor() {
    const { start, end } = DEFAULT_TIME_RANGE;

    this.horizontalViewport = new Viewport(start.unix(), end.unix());

    this.timelineDataRange = new Viewport(0, 0);
    this.timelineApi = new TimelineDataApi();
    this.timelineDataModel = new TimelineDataModel(this.timelineDataRange);

    this.timelineViewport = new Viewport(0, 0);
    this.selectedPeriod = TimeUnit.month;
    this.timelineController = new TimelineController(this.selectedPeriod);
    this.timelinePresenter = new TimelinePresenter(
      this.horizontalViewport,
      this.timelineViewport,
      this.timelineDataRange,
      this.timelineDataModel,
      this.selectedPeriod,
      this.timelineController
    );

    makeObservable(this);
  }

  @action.bound
  onPeriodToggle(period: TimeUnit) {
    this.selectedPeriod = period;
    this.timelinePresenter.setUnit(period);
    this.timelineController.setTimeUnit(period);
    const { start, end } = getPeriodRange(period);
    this.horizontalViewport.setRange(start, end);
  }

  @action.bound
  loadDataBoundaries() {
    const { start, end } = this.timelineApi.getDataBoundaries();

    this.timelineDataRange.setRange(start, end);
  }
}
