import { agent } from 'src/api/agent';
import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { throwApiError } from 'src/api/utils';

export async function getRigOperationsList(
  planVersionId: number,
  groupBy: UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS,
  requestType: UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_REQUEST_TYPES,
  searchString?: string,
  abortSignal?: AbortSignal
): Promise<UnplannedRigOperationsSidebar.PadObjectType[]> {
  try {
    const { data } = await agent.get<UnplannedRigOperationsSidebar.PadObjectType[]>('well/rigOperation/list', {
      signal: abortSignal,
      params: { planVersionId, groupBy, requestType, searchString },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function getUnplannedRigOperationsView(): Promise<UnplannedRigOperationsSidebar.View> {
  try {
    const { data } = await agent.get<UnplannedRigOperationsSidebar.View>('view/SideBar_UnplannedRigOperations');
    return data;
  } catch (e) {
    throwApiError(e);
  }
}
