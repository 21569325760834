import { observer } from 'mobx-react-lite';

import { ReactComponent as AddWellIcon } from 'src/shared/assets/icons/add-well.svg';
import { Button } from 'src/shared/components/button';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { ChartRig, TemporaryChartRig } from '../../../presets/drilling-rigs-chart/entities';
import { useClickHandler } from '../../../shared/use-click-handler';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useDragging } from '../../sorting/hooks/use-dragging';

import styles from './data-items.module.scss';

interface Props {
  item: ChartRig | TemporaryChartRig;
  verticalViewport: Viewport;
  onWellAdd(): void;
}

export const EditingRigRow = observer(function EditingRigRow({ item, verticalViewport, onWellAdd }: Props) {
  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging-row'),
    item,
  });

  const ref = useMovableElement({
    item,
    verticalViewport,
  });
  const buttonRef = useMovableElement({
    item,
    verticalViewport,
  });

  const { onPointerUp, onPointerDown } = useClickHandler(onWellAdd);

  return (
    <>
      <div ref={mergeRefs(ref, draggingRef)} className={styles.rigRow} />

      <div ref={buttonRef} className={styles.addWellButtonWrapper}>
        <Button
          variant="primary"
          icon={<AddWellIcon className={styles.addRigIcon} />}
          iconPosition="before"
          className={styles.addWellButton}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
        />
      </div>
    </>
  );
});
