import { observer } from 'mobx-react-lite';

import { EmptyDataHeader } from '../../../components/timeline-chart/components/data/empty-data-header';
import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { Viewport } from '../../../shared/viewport/viewport';
import { WellsChartDataModel } from '../../wells-chart/data/wells-chart-data-model';

import { EditingWellsDataHeader } from './editing-wells-data-header';

import styles from './data-items.module.scss';

interface Props {
  item: WellsChartDataModel.ViewItemContent;
  verticalViewport: Viewport;
  isEditing: boolean;
  isLoading: boolean;
  onWellAdd(groupId: number): void;
}

export const WellsDataBackgroundItem = observer(function WellsDataBackgroundItem({
  item,
  verticalViewport,
  isEditing,
  isLoading,
  onWellAdd,
}: Props) {
  if (item instanceof ChartWell) {
    return (
      <EmptyDataHeader
        key={item.getKey('background')}
        item={item}
        verticalViewport={verticalViewport}
        className={styles.row}
      />
    );
  }

  if (item instanceof WellsGroup) {
    if (isEditing) {
      return (
        <EditingWellsDataHeader
          key={item.getKey('background')}
          item={item}
          verticalViewport={verticalViewport}
          isLoading={isLoading}
          onWellAdd={onWellAdd}
        />
      );
    }

    return (
      <EmptyDataHeader
        key={item.getKey('background')}
        item={item}
        verticalViewport={verticalViewport}
        className={styles.rowHeader}
      />
    );
  }

  return null;
});
