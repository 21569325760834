import { RigsChart } from 'src/api/chart/rigs-chart-api';

import { ChartRig, PadRigOperation, WellRigOperation } from '../../drilling-rigs-chart/entities';

export class ComparingPadRigOperation extends PadRigOperation {
  readonly isChanged?: boolean;

  constructor(item: RigsChart.RawPad, wells: WellRigOperation[], parentRig: ChartRig, isChanged?: boolean) {
    super(item, parentRig);

    this.isChanged = isChanged;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-comparing-pad-${this.uniqueCreationKey}`;
    }

    return `comparing-pad-${this.uniqueCreationKey}`;
  }
}
