import { observer } from 'mobx-react-lite';
import React from 'react';

import { ReactComponent as SaveIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';

import styles from './control-bar.module.scss';

interface Props {
  isLoading: boolean;
  onApply: VoidFunction;
  onCancel: VoidFunction;
}

export const ControlBar = observer(function ControlBar({ isLoading, onApply, onCancel }: Props) {
  return (
    <div className={styles.controlBar}>
      <div className={styles.editButtonsContainer}>
        <button className={styles.closeButton} disabled={isLoading} onClick={onCancel}>
          <CloseIcon />
        </button>

        <button className={styles.saveButton} disabled={isLoading} onClick={onApply}>
          <SaveIcon />
        </button>
      </div>
    </div>
  );
});
