import { action, flow, makeObservable, observable, reaction } from 'mobx';

import {
  getUnplannedRigOperationsView,
  getRigOperationsList,
  UnplannedRigOperationsSidebar,
} from 'src/api/unplanned-rig-operations-sidebar';
import { BaseApiError, OperationAbortedError } from 'src/errors';
import { RootStore } from 'src/store';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { Timer } from '../../shared/timer';
import { RigOperationsDnd } from '../rig-operations-dnd/rig-operations-dnd-module';

import { SidebarPadRigOperation } from './entities';
import { WELLS_TYPE } from './types';
import { mapPads } from './utils';

export class PadsAndWellsSidebarStore {
  @observable isLoading: boolean = false;
  @observable chosenWellType: WELLS_TYPE = WELLS_TYPE.planned;
  @observable pads: SidebarPadRigOperation[] = [];
  @observable selectedWellKey: number | null = null;
  @observable view: null | UnplannedRigOperationsSidebar.View = null;
  @observable isSidebarHidden: boolean = false;
  @observable isPointerOutOfSidebar: boolean = false;
  @observable searchString: string = '';

  private readonly appSettings: AppSettingsStore;
  private readonly directories: Directories;
  private readonly editing: EditingStore;
  private readonly notifications: NotificationsStore;
  private readonly dndContext: RigOperationsDnd.DndContext;
  private readonly autoCloseTimer = new Timer();
  private abortController: AbortController | null = null;

  constructor(store: RootStore, dndContext: RigOperationsDnd.DndContext) {
    this.directories = store.directories;
    this.notifications = store.notifications;
    this.appSettings = store.appSettings;
    this.dndContext = dndContext;
    this.editing = store.editing;

    makeObservable(this);
  }

  init = (): VoidFunction => {
    this.fetchViewSidebar();

    const disposeSidebarAutoClosing = reaction(
      () => ({ active: this.dndContext.active, isPointerOutOfSidebar: this.isPointerOutOfSidebar }),
      ({ active, isPointerOutOfSidebar }) => {
        if (active && isPointerOutOfSidebar) {
          this.autoCloseTimer.startTimer(() => this.setIsSidebarHidden(true), 500);
        } else {
          this.autoCloseTimer.cancelTimer();

          this.setIsSidebarHidden(false);
        }
      }
    );

    return () => {
      disposeSidebarAutoClosing();

      this.autoCloseTimer.cancelTimer();
    };
  };

  @action.bound
  toggleWellsType(key: WELLS_TYPE): void {
    this.chosenWellType = key;
  }

  @action.bound
  fetchPads() {
    this.abortController?.abort();

    switch (this.chosenWellType) {
      case WELLS_TYPE.planned:
        this.fetchPlannedPads();
        return;

      case WELLS_TYPE.unplanned:
        this.fetchUnplannedPads();
        return;
    }
  }

  @action.bound
  setSearchString(searchString: string) {
    this.searchString = searchString;
  }

  @action.bound
  setIsSidebarHidden(is: boolean) {
    this.isSidebarHidden = is;
  }

  @action.bound
  setIsPointerOutOfSidebar(is: boolean) {
    this.isPointerOutOfSidebar = is;
  }

  @action.bound
  setIsPointerOutOfSidebarAndCheckOnActiveElement(isActiveElementPresented: boolean) {
    this.setIsPointerOutOfSidebar(true);

    if (isActiveElementPresented) {
      this.setIsSidebarHidden(true);
    }
  }

  @flow.bound
  private async *fetchViewSidebar() {
    this.isLoading = true;

    try {
      const view = await getUnplannedRigOperationsView();
      const { refQuery, wells } = view.items.pads;

      await Promise.all([
        this.directories.loadObjects([wells.refObjectType]),
        this.directories.loadJoinedObjectsDeprecated([refQuery]),
      ]);
      yield;

      this.view = view;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *fetchPlannedPads() {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!this.view || !planVersionId) {
      return;
    }

    this.isLoading = true;

    try {
      this.abortController = new AbortController();

      const plannedPads = await getRigOperationsList(
        planVersionId,
        UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pro,
        UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_REQUEST_TYPES.planned,
        this.searchString,
        this.abortController.signal
      );
      yield;

      this.pads = mapPads(
        this.directories,
        this.appSettings,
        this.view,
        plannedPads,
        UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pro
      );
    } catch (e) {
      yield;
      if (!(e instanceof OperationAbortedError)) {
        console.error(e);
        this.notifications.showErrorMessageT('errors:failedToGetPlannedWells');
      }
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *fetchUnplannedPads() {
    const planVersionId = this.editing.actualPlanVersionId;

    if (!this.view || !planVersionId) {
      return;
    }

    this.isLoading = true;

    try {
      this.abortController = new AbortController();

      const unplannedPads = await getRigOperationsList(
        planVersionId,
        UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pad,
        UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_REQUEST_TYPES.unplanned,
        this.searchString,
        this.abortController.signal
      );
      yield;

      this.pads = mapPads(
        this.directories,
        this.appSettings,
        this.view,
        unplannedPads,
        UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS.pad
      );
    } catch (e) {
      yield;

      if (!(e instanceof OperationAbortedError)) {
        console.error(e);
        if (e instanceof BaseApiError && e.responseMessage) {
          this.notifications.showErrorMessage(e.responseMessage);
          return;
        }
        this.notifications.showErrorMessageT('errors:failedToGetUnplannedWells');
      }
    } finally {
      this.isLoading = false;
    }
  }
}
