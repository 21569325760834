export enum Prefixes {
  goplan = 'GOplan_',
  common = 'Common_',
}

const addPrefix = (string: string, prefix: Prefixes): string => prefix.concat(string);

export const addGOplanPrefix = (string: string): string => addPrefix(string, Prefixes.goplan);

export const addCommonPrefix = (string: string): string => addPrefix(string, Prefixes.common);

export const addPrefixesToObjectKeys = (obj: Record<string, unknown>, prefix: string): Record<string, unknown> => {
  return Object.entries(obj).reduce<Record<string, unknown>>((acc, [key, value]) => {
    acc[`${prefix}${key}`] = value;
    return acc;
  }, {});
};
