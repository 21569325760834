import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';

import { TimelinePeriodsTogglePresenter } from '../presenter/timeline-periods-toggle-presenter';

import { getTimePeriods } from './timeline-periods-toggle.constants';
import styles from './timeline-periods-toggle.module.scss';

interface Props {
  selectedPeriod: TimelinePeriodsTogglePresenter.Periods;
  onPeriodChange(period: TimelinePeriodsTogglePresenter.Periods): void;
  className?: string;
}

export const TimelinePeriodsToggle = observer(function Timeline({ className, selectedPeriod, onPeriodChange }: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Periods' });

  return (
    <SwitchButtonGroup
      className={className}
      buttonClassName={styles.timelinePeriodButton}
      items={getTimePeriods(t)}
      defaultOptionKey={selectedPeriod}
      onChange={onPeriodChange}
    />
  );
});
