import { observer } from 'mobx-react-lite';

import styles from './button-table-cell.module.scss';

interface Props {
  buttonText: string;
  onClick?: () => void;
}

export const ButtonTableCell = observer(function ButtonTableCell({ buttonText, onClick }: Props) {
  return (
    <div className={styles.container}>
      <button onClick={onClick} className={styles.button}>
        {buttonText}
      </button>
    </div>
  );
});
