import { computed, makeObservable } from 'mobx';

import { RigsChart } from 'src/api/chart/rigs-chart-api';

import { ChartRig, PadRigOperation } from '../../drilling-rigs-chart/entities';

import { ComparingWellRigOperation } from './comparing-well-rig-operation';

export class ComparingChartRig extends ChartRig {
  constructor(item: RigsChart.RawRig, id: number) {
    super(item, id);

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-comparing-rig-${this.uniqueCreationKey}`;
    }

    return `comparing-rig-${this.uniqueCreationKey}`;
  }

  @computed
  get hasChanges(): boolean {
    if (!this.items?.length) {
      return false;
    }

    return this.items.some(
      (pad) =>
        pad instanceof PadRigOperation &&
        pad.wellRigOperations.some((well) => well instanceof ComparingWellRigOperation && well.hasChanges)
    );
  }
}

export class MainComparingChartRig extends ChartRig {
  readonly comparingPair: ComparingChartRig;

  constructor(item: RigsChart.RawRig, id: number, comparingPair: ComparingChartRig) {
    super(item, id);

    this.comparingPair = comparingPair;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-main-comparing-rig-${this.uniqueCreationKey}`;
    }

    return `main-comparing-rig-${this.uniqueCreationKey}`;
  }
}
