import { fetchDirectoryObject } from 'src/api/dashboard/dashboard-api';
import { DashboardTrendResponse, GroupingValue } from 'src/api/dashboard/types';
import { TDictObject } from 'src/api/directories/types';
import { throwApiError } from 'src/api/utils';
import { getConcatedName } from 'src/shared/utils/get-concated-name';
import { Directories } from 'src/store/directories/directories.store';

import { getLabels } from './getLabels';
import { ILabelResolver } from './types';

export class IDResolver implements ILabelResolver {
  private readonly directories: Directories;

  readonly cache: Partial<Record<string, string>> = {};
  readonly fieldId: string;

  constructor(directories: Directories, fieldId: string) {
    this.directories = directories;
    this.fieldId = fieldId;
  }

  tryGetLabelByKey(key: string): string | null {
    return this.cache[key] ?? null;
  }

  async loadMissingLabels(rawData: DashboardTrendResponse, groupingValue: GroupingValue): Promise<void> {
    const rawIdLabels = getLabels(rawData, this.cache);
    const refObjectType = groupingValue.refObjectType;

    if (!rawIdLabels || !refObjectType) {
      return;
    }

    await this.directories.loadObjects([refObjectType]);

    const objects = this.directories.getObject(refObjectType);

    if (!objects) {
      return;
    }

    const deletedObjectIds: number[] = [];

    for (const idLabel of rawIdLabels) {
      const id = Number(idLabel);
      const object = objects.find((obj) => obj.id === id);

      if (object) {
        let newLabel: string = '';

        const objectValue = groupingValue.refObjectAttr && object.data[groupingValue.refObjectAttr];

        if (objectValue && typeof objectValue === 'string') {
          newLabel = objectValue;
        }

        if (groupingValue.attrConcat && groupingValue.attrConcatRefs) {
          const newRigName = getConcatedName(this.directories, groupingValue, object.data);

          if (newRigName) {
            newLabel = newRigName;
          }
        }

        if (newLabel) {
          this.cache[idLabel] = newLabel;
        }
      } else {
        deletedObjectIds.push(id);
      }
    }

    if (deletedObjectIds.length === 0) return;

    try {
      const promises: Promise<TDictObject>[] = deletedObjectIds.map((id) => {
        return fetchDirectoryObject(refObjectType, id);
      });

      const deletedObjects = await Promise.all(promises);

      for (const id of deletedObjectIds) {
        const object = deletedObjects.find((object) => object.id === id);

        this.cache[id] = String(object ? object.data.name : id);
      }
    } catch (error) {
      throwApiError(error);
    }
  }
}
