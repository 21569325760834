import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { UserSettingsManager } from 'src/api/user-settings';
import { SwitchButtonGroupItem } from 'src/shared/components/switch-button-group/switch-button-group';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { EditingSettingsItem } from '../../shared/editing-settings-item';
import { ViewSettingsHelper } from '../../shared/view-settings-helper';
import { RigsChartView } from '../drilling-rigs-chart/rigs-chart-view';

export class RigsViewSettingsSidebarStore {
  private readonly directories: Directories;
  private readonly settingsManager: UserSettingsManager<ViewSettings.RawSettingsValues>;

  readonly notifications: NotificationsStore;
  private readonly onSettingsUpdate: RigsViewSettingsSidebar.OnUpdateFn;

  @observable private chartView?: RigsChartView;

  @observable isLoading = false;
  @observable settings: Record<string, ViewSettings.GroupedSettingsControls | ViewSettings.FlatSettingsControls> = {};

  constructor(
    rootStore: RootStore,
    settingsManager: UserSettingsManager<ViewSettings.RawSettingsValues>,
    onSettingsUpdate: RigsViewSettingsSidebar.OnUpdateFn
  ) {
    this.directories = rootStore.directories;
    this.settingsManager = settingsManager;
    this.notifications = rootStore.notifications;
    this.onSettingsUpdate = onSettingsUpdate;

    makeObservable(this);
  }

  @computed
  get tabs(): SwitchButtonGroupItem<string>[] {
    if (!this.chartView?.settings) {
      return [];
    }

    return Object.entries(this.chartView.view.settings).map(([_, settingObject]) => {
      return {
        key: settingObject.fieldId,
        title: this.directories.getFieldLabel(settingObject.fieldId) || '',
      };
    });
  }

  @action.bound
  init(): VoidFunction {
    const disposeSettingsValuesUpdate = reaction(
      () => ({ viewSettingsValues: this.chartView?.infoSettingsValues, viewSettings: this.chartView?.settings }),
      ({ viewSettings, viewSettingsValues }) => {
        if (viewSettings && viewSettingsValues) {
          this.settings = ViewSettingsHelper.mapViewAndValuesToSettingsControls(
            viewSettings,
            viewSettingsValues,
            this.directories
          );
        }
      },
      { fireImmediately: true }
    );

    return () => {
      disposeSettingsValuesUpdate();
    };
  }

  @action.bound
  setChartView(chartView: RigsChartView): void {
    this.chartView = chartView;
  }

  @action.bound
  onFlatTabSortEnd(items: EditingSettingsItem[], fieldId: string): void {
    this.settings[fieldId].items = items;
  }

  @action.bound
  onGroupedTabSortEnd(items: EditingSettingsItem[], group: ViewSettings.GroupedSettingsControlsGroupData): void {
    group.items = items;
  }

  @action.bound
  onSettingsSave(): void {
    const updatedSettings = ViewSettingsHelper.getUpdatedSettings(this.settings);

    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.onSettingsUpdate(updatedSettings);
    this.isLoading = false;
  }
}

export namespace RigsViewSettingsSidebar {
  export type OnUpdateFn = (sidebarSettingsValues: ViewSettings.InfoSettingsValues) => void;
}
