import { action, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import moment from 'moment';

import { Indicators } from 'src/api/chart/indicators-api';
import IndicatorsDataAdapter from 'src/api/chart/indicators-data-adapter';
import { DATE_SERVER_FORMAT } from 'src/shared/constants/date';
import { TrendType } from 'src/shared/constants/trend-type';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { IModel, Range } from '../../../layers/model';
import { LoadingIndicatorsColumn } from '../../../presets/indicators-view-settings-sidebar/entities';
import { timeUnitToGranularity } from '../../../shared/data-view/trend-granularity';
import { generateKeyFromRange, StorageKeyManager } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';
import { getTimeUnit } from '../../../shared/viewport/viewport-calculator';

import { IIndicatorsDataApi } from './indicators-data-model.types';
import { IndicatorsStorage } from './indicators-storage';

export class IndicatorsDataModel implements IModel<Indicators.ViewIndicators | null> {
  private readonly editing: EditingStore;
  private readonly api: IIndicatorsDataApi;
  private readonly adapter = new IndicatorsDataAdapter();
  private readonly notifications: NotificationsStore;

  @observable private dataStorage: IndicatorsStorage;
  @observable private viewRange: Range<number>;
  @observable private data?: Indicators.ViewIndicatorsColumn[];
  @observable private filters?: Record<string, unknown>;

  constructor(
    initialViewRange: Range<number>,
    api: IIndicatorsDataApi,
    editing: EditingStore,
    notifications: NotificationsStore
  ) {
    this.editing = editing;
    this.api = api;
    this.viewRange = initialViewRange;
    this.notifications = notifications;

    const storageKeyManager = new StorageKeyManager<Range<number>, string>(generateKeyFromRange);
    this.dataStorage = new IndicatorsStorage(storageKeyManager);

    makeObservable(this);
  }

  @flow.bound
  private async *fetchData(range: Range<number>, timeUnit: TimeUnit): Promise<void> {
    try {
      const startString = moment.unix(range.start).format(DATE_SERVER_FORMAT);
      const endString = moment.unix(range.end).format(DATE_SERVER_FORMAT);

      const trendGranularity = timeUnitToGranularity(timeUnit);

      assert(hasValue(trendGranularity), 'Invalid trend granularity.');

      const planVersionId = this.editing.actualPlanVersionId;

      assert(hasValue(planVersionId), 'Invalid plan version ID.');

      const rawIndicators = await this.api.getIndicators(
        startString,
        endString,
        trendGranularity,
        planVersionId,
        this.filters
      );
      yield;

      const indicators = this.adapter.initializeIndicators(
        rawIndicators,
        range,
        timeUnit,
        [
          TrendType.passing,
          TrendType.commercialSpeed,
          TrendType.developmentCompleteWellsCount,
          TrendType.drillingCompleteWellsCount,
        ],
        planVersionId
      );

      this.dataStorage.set(indicators, range, timeUnit);
    } catch (e) {
      yield;
      console.error(e);

      this.notifications.showErrorMessageT('errors:failedToLoadIndicators');
    }
  }

  private fetchDataDebounced = debounce((range: Range<number>, timeUnit: TimeUnit) => {
    this.fetchData(range, timeUnit);
  }, 500);

  @computed({ equals: comparer.structural })
  private get timeUnit(): TimeUnit {
    return getTimeUnit(this.viewRange);
  }

  @computed({ equals: comparer.structural })
  private get viewFrame(): Range<number> {
    return TimeRangeHelper.expandRangeInUnix({ start: this.viewRange.start, end: this.viewRange.end }, this.timeUnit);
  }

  @action.bound
  init(): VoidFunction {
    const disposeDataStorage = this.dataStorage.init();

    const disposeDataFetching = reaction(
      () => this.dataStorage.get(this.viewFrame.start, this.viewFrame.end, this.timeUnit),
      (indicators) => {
        if (!indicators) {
          const intermediateColumns = TimeRangeHelper.getIntermediateDates(this.viewFrame, this.timeUnit);
          const emptyColumns = intermediateColumns.map((range) => new LoadingIndicatorsColumn(range));

          this.dataStorage.set(
            { columns: emptyColumns, total: new LoadingIndicatorsColumn(this.viewFrame) },
            this.viewFrame,
            this.timeUnit
          );

          this.fetchDataDebounced(this.viewFrame, this.timeUnit);
        }
      },
      { fireImmediately: true }
    );

    return () => {
      disposeDataFetching();
      disposeDataStorage();
    };
  }

  @flow.bound
  async *reloadIndiatorsData() {
    await this.fetchData(this.viewFrame, this.timeUnit);
    yield;
  }

  getData = computedFn(
    (): Indicators.ViewIndicators | null => {
      const indicators = this.dataStorage.get(this.viewFrame.start, this.viewFrame.end, this.timeUnit);

      if (!indicators) {
        return null;
      }

      return {
        result: indicators.total,
        items: indicators.columns,
      };
    },
    { equals: comparer.shallow }
  );

  @action.bound
  setRange(start: number, end: number) {
    this.viewRange.start = start;
    this.viewRange.end = end;
  }

  @action.bound
  setFilters(filters: Record<string, unknown>): void {
    this.filters = filters;
    this.clearStorage();
  }

  @action.bound
  clearStorage(): void {
    this.dataStorage.clear();
  }
}
