import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import styles from './collapse-table.module.scss';

interface Props {
  isCollapsed: boolean;
  className?: string;
}

export const CollapseTable = observer(function CollapseTable({
  isCollapsed,
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className={clsx(className, styles.table, !isCollapsed && styles.tableExpanded)}>
      <div className={clsx(styles.tableDataContainer)}>{children}</div>
    </div>
  );
});
