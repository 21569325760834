import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

export class PlanRigOperationSidebarStore {
  @observable workStartDate: Moment | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get workStartDateSeconds(): number | null {
    return this.workStartDate ? this.workStartDate.unix() : this.workStartDate;
  }

  @action.bound
  setWorkStartDate(date: Moment | number | null): void {
    this.workStartDate = typeof date === 'number' ? moment.unix(date) : date;
  }
}
