import { action, computed, makeObservable, observable } from 'mobx';

import { ViewSettings } from '../../../api/chart/drilling-plan-charts-api';

export class EditingSettingsItem {
  readonly item: EditingSettings.Item;
  readonly title: string | null;
  readonly id: string;

  @observable isShown: boolean;

  constructor(item: EditingSettings.Item, values: ViewSettings.SettingsControlValue) {
    this.item = item;
    this.title = item.label;
    this.isShown = values.isShown;
    this.id = item.fieldId;

    makeObservable(this);
  }

  @computed
  get settingsValue(): ViewSettings.SettingsControlValue {
    return {
      fieldId: this.item.fieldId,
      isShown: this.isShown,
    };
  }

  @action.bound
  setIsShown(value: boolean): void {
    this.isShown = value;
  }
}

export namespace EditingSettings {
  export type Item = {
    fieldId: string;
    label: string | null;
  };
}
