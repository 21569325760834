import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarMode } from 'src/features/drilling-rig-sidebar/store/types';
import { InfoSidebarTabContent } from 'src/features/graph-info-sidebar/info-sidebar-tab-component/info-sidebar-tab-component';
import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import { RigSidebarStore } from '../store/rig-sidebar.store';

import { ControlBar } from './control-bar';

import styles from './drilling-rig-sidebar.module.scss';

interface Props {
  rigId: number;
  isOpened: boolean;
  onClose: VoidFunction;
  sidebarMode: SidebarMode;
  onWellClick?(wellId: number): void;
  onPadClick?(padId: number, rigId: number): void;
  disableEditing?: boolean;
  onWellAdd?: VoidFunction;
  onEditingWellClick?(wellId: number): void;
  // onChange?: RigSidebarStore.ChangeHandler;
}

export const DrillingRigSidebar = observer(function DrillingRigSidebar({
  rigId,
  isOpened,
  sidebarMode,
  disableEditing,
  onClose,
  onWellClick,
  onPadClick,
  onEditingWellClick,
  onWellAdd,
}: Props) {
  const { t } = useTranslation('drawers');

  const rootStore = useStore();

  const { editing } = rootStore;

  const [store] = useState(() => new RigSidebarStore(rootStore));

  const [activeKey, setActiveKey] = useState(store.viewProvider?.tabsSwitchButtonsGroup[0]?.key);

  const activeTab = store.viewProvider?.tabs.find((tab) => tab.fieldId === activeKey);

  useEffect(() => {
    store.init();
  }, [store]);

  useEffect(() => {
    if (store.viewProvider) {
      setActiveKey(store.viewProvider.tabsSwitchButtonsGroup[0]?.key);
    }
  }, [store.viewProvider]);

  useEffect(() => {
    if (hasValue(store.viewProvider)) {
      store.open(rigId, editing.actualPlanVersionId, sidebarMode);
    }
  }, [editing.actualPlanVersionId, rigId, sidebarMode, store, store.rigStore, store.viewProvider]);

  // Отключенный функционал перемещения и удаления кустов/скважин
  // useEffect(() => {
  //   store.setChangeHandler(onChange || null);
  // }, [onChange, store]);

  const isLoading = store.isLoading || store.rigStore.isLoading;

  const getTitle = (): string | undefined => {
    if (isLoading) {
      return;
    }

    if (store.rigStore.rig && store.viewProvider) {
      return store.getSidebarTitle(store.rigStore.rig.data) || t('drillingRigDrawer.drillingRig');
    }

    return t('drillingRigDrawer.drillingRig');
  };

  return (
    <Sidebar isOpened={isOpened} onClose={onClose} closeOnClickOutside title={getTitle()} size="l">
      <ControlBar
        store={store}
        disableEditing={disableEditing}
        activeKey={activeKey}
        activeTab={activeTab}
        setActiveKey={setActiveKey}
      />

      <div className={styles.contentContainer}>
        {isLoading ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <InfoSidebarTabContent
            activeTab={activeTab}
            onWellClick={onWellClick}
            onPadClick={(padId: number) => onPadClick?.(padId, rigId)}
            onWellAdd={onWellAdd}
            onEditingWellClick={onEditingWellClick}
          />
        )}
      </div>
    </Sidebar>
  );
});
