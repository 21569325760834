import { action, makeObservable, observable, reaction } from 'mobx';
import { createRef, RefObject } from 'react';

import { hasValue } from 'src/shared/utils/common';

import { Range } from '../../../../layers/model';
import { Viewport } from '../../../../shared/viewport/viewport';
import { pixelsToMilliseconds } from '../../../../shared/viewport/viewport-calculator';
import { AutoScrollController } from '../../../auto-scroll/auto-scroll-controller';
import { AutoScroll } from '../../../auto-scroll/auto-scroll.utils';
import { Dnd } from '../../types';

import { DraggableActive } from './draggable-active';
import { DraggingInteractive } from './dragging-interactive';
import { DroppableActive } from './droppable-active';

/** Default distance between pointer and droppable container. */
const DEFAULT_DROP_DISTANCE = 20;

export class DndContextStore<TDraggableItem, TDroppableItem, TInteractiveItem, TDraggingItem, TDraggableShadow> {
  private readonly horizontalViewport: Viewport;
  private readonly verticalViewport: Viewport;
  private readonly onMovingStart?: DndContextStore.OnMovingStartFn<TDraggableItem>;
  private readonly onMovingFinish?: DndContextStore.OnMovingFinishFn;

  private readonly droppableElements: Map<string, DndContextStore.DroppableEntry<TDroppableItem>> = new Map();
  private readonly draggingInteractiveElements: Map<string, DraggingInteractive<TInteractiveItem>> = new Map();
  private readonly draggingElements: Map<string, DndContextStore.DraggingEntry<TDraggingItem>> = new Map();

  private readonly handleInteractiveOver?: DndContextStore.OnInteractiveOverFn<TInteractiveItem>;
  private readonly handleDrop?: DndContextStore.OnDropFn<TDraggableItem, TDroppableItem>;
  private readonly handleDropError?: DndContextStore.OnDropErrorFn<TDraggableItem, TDroppableItem>;
  private readonly handleDragMove?: DndContextStore.onDragMoveFn<
    TDraggableItem,
    TDroppableItem,
    TInteractiveItem,
    TDraggableShadow
  >;
  private readonly getIsDraggingPositionAvailable?: DndContextStore.GetIsDraggingPositionAvailableFn<TDroppableItem>;

  readonly containerRef = createRef<HTMLDivElement>();
  readonly autoScrollController?: AutoScrollController;
  readonly getIsDraggable: DndContextStore.GetIsDraggable<TDraggableItem, TDraggableShadow>;
  readonly getIsElementDragging: DndContextStore.GetIsElementDragging<TDraggableItem, TDraggingItem, TDraggableShadow>;

  /** Rect of existing container element that contains draggable/droppable/interactive/etc elements.  */
  containerRect: DOMRect | null = null;
  /** Overlay where active draggable elements can be appended and moved. */
  draggableContainerElement: HTMLElement | null = null;

  @observable active: DraggableActive<TDraggableItem, unknown, TDraggableShadow> | null = null;
  @observable over: DroppableActive<TDroppableItem> | null = null;
  @observable draggingInteractiveOver: DraggingInteractive<TInteractiveItem> | null = null;
  /** Whether current pointer position is available for element dropping.
   * Will be null if no active draggable item exists. */
  @observable isDraggingPositionAvailable: boolean | null = null;

  constructor(
    horizontalViewport: Viewport,
    verticalViewport: Viewport,
    autoScrollController?: AutoScrollController,
    handlers?: DndContextStore.Handlers<
      TDraggableItem,
      TDroppableItem,
      TInteractiveItem,
      TDraggingItem,
      TDraggableShadow
    >
  ) {
    this.horizontalViewport = horizontalViewport;
    this.verticalViewport = verticalViewport;
    this.autoScrollController = autoScrollController;
    this.onMovingStart = handlers?.onMovingStart;
    this.onMovingFinish = handlers?.onMovingFinish;
    this.handleDrop = handlers?.onDrop;
    this.handleDropError = handlers?.onDropError;
    this.handleInteractiveOver = handlers?.onInteractiveOver;
    this.handleDragMove = handlers?.onDragMove;
    this.getIsDraggingPositionAvailable = handlers?.getIsDraggingPositionAvailable;
    this.getIsDraggable = handlers?.getIsDraggable || (() => true);
    this.getIsElementDragging = handlers?.getIsElementDragging || (() => false);

    makeObservable(this);
  }

  private validateStart(value: number | null, replacement: number, minValue?: number): number {
    const valueNumber: number = value ?? replacement;

    if (hasValue(minValue)) {
      return Math.max(valueNumber, minValue);
    }

    return valueNumber;
  }

  private validateEnd(value: number | null, replacement: number, maxValue?: number): number {
    const valueNumber: number = value ?? replacement;

    if (hasValue(maxValue)) {
      return Math.min(valueNumber, maxValue);
    }

    return valueNumber;
  }

  /** Check if pointer is over the droppable element and set active droppable element. */
  private updateActiveDroppable(
    x: number,
    y: number,
    containerWidth: number,
    droppableDistance: number
  ): DroppableActive<TDroppableItem> | null {
    if (!this.droppableElements.size) {
      return null;
    }

    const xInMilliseconds =
      this.horizontalViewport.start + pixelsToMilliseconds(x, this.horizontalViewport, containerWidth);
    const distanceInMilliseconds = pixelsToMilliseconds(droppableDistance, this.horizontalViewport, containerWidth);

    for (const [id, droppable] of this.droppableElements) {
      const { x: droppableX, y: droppableY, minStartX } = droppable.dataItem;

      const expandedX: Range<number> = {
        start:
          this.validateStart(droppableX?.start ?? null, this.horizontalViewport.start, minStartX) -
          distanceInMilliseconds,
        end: (droppableX?.end ?? this.horizontalViewport.end) + distanceInMilliseconds,
      };

      const expandedY: Range<number> = {
        start: droppableY.start - droppableDistance,
        end: droppableY.end + droppableDistance,
      };

      if (
        xInMilliseconds >= expandedX.start &&
        xInMilliseconds <= expandedX.end &&
        y >= expandedY.start &&
        y <= expandedY.end
      ) {
        if (id !== this.over?.id) {
          this.over = new DroppableActive(id, droppable.element, droppable.dataItem);
        }

        return this.over;
      }
    }

    this.over = null;

    return this.over;
  }

  private updateDraggingInteractiveFromPoint(
    pointXMs: number,
    pointY: number
  ): DraggingInteractive<TInteractiveItem> | null {
    if (!this.handleInteractiveOver) {
      return null;
    }

    for (const [, interactive] of this.draggingInteractiveElements) {
      const {
        dataItem: { x: xRange, y: yRange, minStartX },
      } = interactive;

      const xStart = xRange ? this.validateStart(xRange.start, this.horizontalViewport.start, minStartX) : undefined;
      const xEnd = xRange ? this.validateEnd(xRange.end || null, this.horizontalViewport.end) : undefined;

      const yStart = yRange ? this.validateStart(yRange.start, this.verticalViewport.start) : undefined;
      const yEnd = yRange ? this.validateEnd(yRange.end, this.verticalViewport.end) : undefined;

      const isElementFromPointHorizontally = (): boolean => {
        if (hasValue(xStart) && hasValue(xEnd)) {
          return pointXMs >= xStart && pointXMs <= xEnd;
        }

        return true;
      };
      const isElementFromPointVertically = (): boolean => {
        if (hasValue(yStart) && hasValue(yEnd)) {
          return pointY >= yStart && pointY <= yEnd;
        }

        return true;
      };

      if ((xRange || yRange) && isElementFromPointHorizontally() && isElementFromPointVertically()) {
        this.draggingInteractiveOver = interactive;

        return this.draggingInteractiveOver;
      }
    }

    this.draggingInteractiveOver = null;

    return this.draggingInteractiveOver;
  }

  private updateAutoScrolling(x: number, y: number, containerWidth: number, scrollDistance: number): void {
    if (!this.autoScrollController) {
      return;
    }

    const validX = AutoScroll.validateCoordinate(x, 0, containerWidth);
    const validY = AutoScroll.validateCoordinate(y, this.verticalViewport.start, this.verticalViewport.end);

    const isLeft = validX >= 0 && validX <= scrollDistance;
    const isRight = !isLeft && validX >= containerWidth - scrollDistance && validX <= containerWidth;
    const isTop = validY >= this.verticalViewport.start && validY <= this.verticalViewport.start + scrollDistance;
    const isBottom =
      !isTop && validY >= this.verticalViewport.end - scrollDistance && validY <= this.verticalViewport.end;

    if (!isLeft && !isRight && !isTop && !isBottom) {
      this.autoScrollController.finishScrolling();
      return;
    }

    const direction = AutoScroll.getDirection(isLeft, isRight, isTop, isBottom);

    if (!direction) {
      this.autoScrollController.finishScrolling();
      return;
    }

    const horizontalVelocity =
      isLeft || isRight
        ? AutoScroll.getScrollAcceleration(isLeft ? validX : containerWidth - validX, scrollDistance)
        : 0;

    const verticalVelocity =
      isTop || isBottom
        ? AutoScroll.getScrollAcceleration(
            isTop ? validY - this.verticalViewport.start : this.verticalViewport.end - validY,
            scrollDistance
          )
        : 0;

    if (horizontalVelocity <= 0 && verticalVelocity <= 0) {
      this.autoScrollController.finishScrolling();
      return;
    }

    if (direction && this.containerRect) {
      const horizontalVelocityMs = pixelsToMilliseconds(
        horizontalVelocity,
        this.horizontalViewport,
        this.containerRect.width
      );

      this.autoScrollController.startScrolling(direction, [horizontalVelocityMs, verticalVelocity]);
    }
  }

  init(): VoidFunction {
    const disposeDraggingPosition = reaction(
      () => this.active,
      (active) => {
        this.isDraggingPositionAvailable = active ? true : null;
      }
    );

    return () => {
      disposeDraggingPosition();
    };
  }

  getRelativeCoordinates = ({ x, y }: Dnd.Coordinates): Dnd.Coordinates => {
    if (!this.containerRect) {
      return { x, y };
    }

    return {
      x: x - this.containerRect.left,
      y: y - this.containerRect.top,
    };
  };

  setDraggableContainer = (element: HTMLElement | null): void => {
    this.draggableContainerElement = element;
  };

  setContainerRect = (rect: DOMRect | null): void => {
    this.containerRect = rect;
  };

  @action.bound
  setActive(
    id: string,
    element: HTMLElement,
    dataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>,
    pointerCoordinates: Dnd.Coordinates,
    options?: DndContextStore.DraggingOptions
  ): DraggableActive<TDraggableItem> | undefined {
    if (!this.containerRef.current || !this.containerRef.current) {
      return;
    }

    const relatedItems: DndContextStore.DraggingEntry<TDraggingItem>[] = [];

    for (const [, draggingItem] of this.draggingElements) {
      if (this.getIsElementDragging(id, element, dataItem, draggingItem)) {
        relatedItems.push(draggingItem);
      }
    }

    this.active = new DraggableActive<TDraggableItem, TDraggingItem, TDraggableShadow>(
      id,
      element,
      dataItem,
      {
        x: pointerCoordinates.x,
        y: pointerCoordinates.y,
      },
      relatedItems,
      options?.draggableClassName
    );

    this.onMovingStart?.(this.active);

    return this.active;
  }

  @action.bound
  removeActive(beforeRemoving?: (active: DraggableActive<TDraggableItem>) => void): void {
    if (beforeRemoving && this.active) {
      beforeRemoving(this.active);
    }

    this.active = null;

    this.autoScrollController?.finishScrolling();
    this.onMovingFinish?.();
  }

  @action.bound
  setOver(id: string, element: HTMLElement, dataItem: Dnd.Droppable & TDroppableItem): void {
    this.over = new DroppableActive(id, element, dataItem);
  }

  @action.bound
  removeOver(): void {
    this.over = null;
  }

  registerDragging = (id: string, element: RefObject<HTMLElement>, dataItem: TDraggingItem): void => {
    const draggingEntry = { element, dataItem };

    this.draggingElements.set(id, draggingEntry);

    if (
      this.active &&
      this.getIsElementDragging(this.active.id, this.active.element, this.active.dataItem, draggingEntry)
    ) {
      this.active.addRelatedItem(draggingEntry);
    }
  };

  unregisterDragging = (id: string): void => {
    const draggingEntry = this.draggingElements.get(id);

    if (
      draggingEntry &&
      this.active &&
      this.getIsElementDragging(this.active.id, this.active.element, this.active.dataItem, draggingEntry)
    ) {
      this.active.removeRelatedItem(draggingEntry);
    }

    this.draggingElements.delete(id);
  };

  registerDroppable = (id: string, element: HTMLElement, dataItem: Dnd.Droppable & TDroppableItem): void => {
    this.droppableElements.set(id, { element, dataItem });
  };

  unregisterDroppable = (id: string): void => {
    this.droppableElements.delete(id);
  };

  registerInteractive = (id: string, element: HTMLElement, dataItem: Dnd.Interactive & TInteractiveItem): void => {
    this.draggingInteractiveElements.set(id, new DraggingInteractive(id, element, dataItem));
  };

  unregisterInteractive = (id: string): void => {
    this.draggingInteractiveElements.delete(id);
  };

  @action.bound
  onDragMove(pointerClientX: number, pointerClientY: number, options: DndContextStore.Options = {}): void {
    const { containerRect } = this;

    if (!this.active || !containerRect) {
      return;
    }

    const dropDistance = options.dropDistance ?? DEFAULT_DROP_DISTANCE;
    const scrollDistance =
      options.scrollDistance ?? AutoScroll.DEFAULT_CONTAINER_SCROLL_DISTANCE / window.devicePixelRatio;

    const relativeX = pointerClientX - containerRect.left;
    const relativeY = pointerClientY - containerRect.top + this.verticalViewport.start;

    const xInMilliseconds =
      this.horizontalViewport.start + pixelsToMilliseconds(relativeX, this.horizontalViewport, containerRect.width);

    this.isDraggingPositionAvailable =
      this.getIsDraggingPositionAvailable?.({ x: xInMilliseconds, y: relativeY }, this.over) ?? true;

    this.updateAutoScrolling(relativeX, relativeY, containerRect.width, scrollDistance);

    const over = this.updateActiveDroppable(relativeX, relativeY, containerRect.width, dropDistance);
    const interactiveOver = this.updateDraggingInteractiveFromPoint(xInMilliseconds, relativeY);

    if (this.handleDragMove && this.active) {
      this.handleDragMove(this.active, over, interactiveOver);
    }
  }

  @action.bound
  onInteractiveOver(): void {
    if (
      !this.draggingInteractiveOver ||
      !this.handleInteractiveOver ||
      !this.draggingInteractiveElements.size ||
      !this.containerRef.current
    ) {
      return;
    }

    this.handleInteractiveOver(this.draggingInteractiveOver);
  }

  @action.bound
  onDrop(active: DraggableActive<TDraggableItem>, over: DroppableActive<TDroppableItem>): void {
    if (this.isDraggingPositionAvailable) {
      if (!this.containerRect) {
        console.error('[DndContextStore]: Rect of drag-n-drop container is not defined.');
        return;
      }

      const { top: activeY } = active.element.getBoundingClientRect();

      const relativeActiveX = active.transform.value.x - this.containerRect.left;

      const activeXInMilliseconds =
        this.horizontalViewport.start +
        pixelsToMilliseconds(relativeActiveX, this.horizontalViewport, this.containerRect.width);

      this.handleDrop?.(active, over, {
        x: activeXInMilliseconds,
        y: activeY,
      });
    } else {
      this.handleDropError?.(active, over);
    }

    this.over = null;
  }
}

export namespace DndContextStore {
  export type OnMovingStartFn<TDraggableItem> = (active: DraggableActive<TDraggableItem>) => void;

  export type OnMovingFinishFn = VoidFunction;

  export type onDragMoveFn<TDraggableItem, TDroppableItem, TInteractiveItem, TDraggableShadow> = (
    active: DraggableActive<TDraggableItem, unknown, TDraggableShadow>,
    over: DroppableActive<TDroppableItem> | null,
    interactive: DraggingInteractive<TInteractiveItem> | null
  ) => void;

  export type OnDropFn<TDraggableItem, TDroppableItem> = (
    active: DraggableActive<TDraggableItem>,
    over: DroppableActive<TDroppableItem>,
    pointerPosition: Dnd.Coordinates
  ) => void;

  export type OnDropErrorFn<TDraggableItem, TDroppableItem> = (
    active: DraggableActive<TDraggableItem>,
    over: DroppableActive<TDroppableItem>
  ) => void;

  export type OnInteractiveOverFn<TInteractiveItem> = (active: DraggingInteractive<TInteractiveItem>) => void;

  export type GetIsDraggingPositionAvailableFn<TDroppableItem> = (
    pointerPosition: Dnd.Coordinates,
    over: DroppableActive<TDroppableItem> | null
  ) => boolean;

  export type GetIsDraggable<TDraggableItem, TDraggableShadow> = (
    dataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>
  ) => boolean;

  export type GetIsElementDragging<TDraggableItem, TDraggingItem, TDraggableShadow> = (
    activeId: string,
    activeElement: HTMLElement,
    activeDataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>,
    draggingItem: DraggingEntry<TDraggingItem>
  ) => boolean;

  export type Handlers<TDraggableItem, TDroppableItem, TInteractiveItem, TDraggingItem, TDraggableShadow> = Partial<{
    onMovingStart: OnMovingStartFn<TDraggableItem>;
    onMovingFinish: OnMovingFinishFn;
    onDragMove: onDragMoveFn<TDraggableItem, TDroppableItem, TInteractiveItem, TDraggableShadow>;
    onDrop: OnDropFn<TDraggableItem, TDroppableItem>;
    /** Will be called instead of onDrop method when pointerup event fires and current pointer position is not available. */
    onDropError: OnDropErrorFn<TDraggableItem, TDroppableItem>;
    onInteractiveOver: OnInteractiveOverFn<TInteractiveItem>;
    getIsDraggingPositionAvailable: GetIsDraggingPositionAvailableFn<TDroppableItem>;
    getIsDraggable: GetIsDraggable<TDraggableItem, TDraggableShadow>;
    getIsElementDragging: GetIsElementDragging<TDraggableItem, TDraggingItem, TDraggableShadow>;
  }>;

  export type DroppableEntry<TDroppableItem> = {
    element: HTMLElement;
    dataItem: Dnd.Droppable & TDroppableItem;
  };

  export type DraggingEntry<TDraggingItem> = {
    element: RefObject<HTMLElement>;
    dataItem: TDraggingItem;
  };

  export type Options = Partial<{
    /** Distance between pointer and droppable container. */
    dropDistance: number;
    scrollDistance: number;
  }>;

  export type DraggingOptions = Partial<{
    draggableClassName: string;
  }>;
}
