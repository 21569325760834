import { action, autorun, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { WellTypes } from 'src/types/well-types';

import type { IChartDataModel, Range } from '../../../layers/model';
import { DataView } from '../../../shared/data-view/data-view';

import { ComparingWellsData } from './comparing-wells-data.types';

export class ComparingWellsDataModel implements IChartDataModel<ComparingWellsData.ViewItem[] | null> {
  private readonly notifications: NotificationsStore;
  private readonly firstPlanVersionId: number;
  private readonly secondPlanVersionId: number;
  private readonly dataView: DataView;
  private readonly storage: ComparingWellsData.ComparingWellsChartDataStorage;
  private readonly api: ComparingWellsData.ComparingWellsChartDataApi;

  @observable private filter?: Record<string, unknown>;

  constructor(
    notifications: NotificationsStore,
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    initialVerticalViewRange: Range<number>,
    initialHorizontalViewRange: Range<number>,
    dataView: DataView,
    storage: ComparingWellsData.ComparingWellsChartDataStorage,
    api: ComparingWellsData.ComparingWellsChartDataApi
  ) {
    this.notifications = notifications;

    this.firstPlanVersionId = firstPlanVersionId;
    this.secondPlanVersionId = secondPlanVersionId;
    this.dataView = dataView;
    this.api = api;

    this.storage = storage;
    this.storage.setVerticalViewRange(initialVerticalViewRange);
    this.storage.setHorizontalViewRange(initialHorizontalViewRange);

    makeObservable(this);
  }

  @flow.bound
  private async *loadStages(wellIds: number[], horizontalRange: Range<number>) {
    try {
      const stages = await this.api.getStages(
        this.firstPlanVersionId,
        this.secondPlanVersionId,
        wellIds,
        horizontalRange.start,
        horizontalRange.end,
        this.filter
      );
      yield;

      if (stages) {
        this.storage.setStages(stages, horizontalRange, this.dataView.type);
      }
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToLoadData');
    }
  }

  @computed
  get verticalDataRange(): Range<number> | null {
    return this.storage.allDataVerticalRange;
  }

  @computed
  get data(): ComparingWellsData.ViewItem[] | null {
    return this.storage.data;
  }

  @action.bound
  init(): VoidFunction {
    const disposeStorage = this.storage.init?.();

    const disposePositionsCalculating = reaction(
      () => ({
        dataViewType: this.dataView.type,
      }),
      () => {
        this.storage.normalizeData();
      },
      { equals: comparer.shallow, fireImmediately: true }
    );

    const disposeMissingDataProcessing = autorun(() => {
      const missingData = this.storage.missingDataBounds;

      if (missingData?.length) {
        for (const missingDataRange of missingData) {
          this.loadStages([...missingDataRange.wellsIds], missingDataRange.horizontalRange);
        }
      }
    });

    return () => {
      disposePositionsCalculating();
      disposeMissingDataProcessing();
      disposeStorage?.();
    };
  }

  @action.bound
  setWells(wells: WellTypes.RawWell[]): void {
    this.storage.setWells(wells, this.firstPlanVersionId, this.secondPlanVersionId);
  }

  @action.bound
  setShownStageAttributesNumber(shownStageAttributeNumber: number): void {
    this.storage.setShownStageAttributesNumber(shownStageAttributeNumber);
  }

  @action.bound
  setHorizontalRange(start: number, end: number): void {
    this.storage.setHorizontalViewRange({ start, end });
  }

  @action.bound
  setVerticalRange(start: number, end: number): void {
    this.storage.setVerticalViewRange({ start, end });
  }

  @action.bound
  recalculatePositions(): void {
    this.storage.normalizeData();
  }

  @action.bound
  setFilters(filter: Record<string, unknown>): void {
    this.filter = filter;
  }
}
