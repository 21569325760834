import { Collapse, Spin } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableGroupStore } from 'src/features/planning/store/table-group';
import { PlanTable } from 'src/features/planning/views/plan-table';
import { SortableWrapper } from 'src/features/planning/views/table-group/sortable-wrapper';
import { TableGroupHeader } from 'src/features/planning/views/table-group/table-group-header';
import { PlanPageStore } from 'src/pages/plan-page/plan-page.store';
import { SortingOptionType, FilterType, PAGE_MODE } from 'src/pages/plan-page/types';
import { ReactComponent as AddIcon } from 'src/shared/assets/icons/plus.svg';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';
import { Button } from 'src/shared/components/button/button';

import styles from './table-group.module.scss';

interface Props {
  tableGroup: TableGroupStore;
  title: string;
  subTitles: string[];
  pageStore: PlanPageStore;
  isEditing: boolean;
}

export const TableGroup = observer(function TableGroup({ tableGroup, title, subTitles, pageStore, isEditing }: Props) {
  const [draggable, setDraggable] = useState<boolean>(false);

  const { t } = useTranslation('plan');

  function onOpenAddPadSidebar(event: React.MouseEvent): void {
    event.stopPropagation();
    pageStore.openAddPadSidebar();
  }

  function handleChange(event: string | string[]) {
    if (!event.length) {
      pageStore.closeTableGroup(tableGroup);
      return;
    }

    pageStore.openTableGroup(tableGroup);
  }

  function onSortTableData(sortingOption: SortingOptionType | null): void {
    pageStore.setSorting(tableGroup, sortingOption);
  }

  function onFiltersChange(filters: FilterType[]): void {
    pageStore.setFilters(tableGroup, filters);
  }

  function onComputeRigOperations(insert: number, insertOnPlace: number | null, insertAfter: number | null): void {
    pageStore.computeRigOperations(tableGroup, insert, insertOnPlace, insertAfter);
  }

  function onComputeRigOperationsList(
    insert: number[],
    insertOnPlace: number | null,
    insertAfter: number | null
  ): void {
    pageStore.computeRigOperationsList(tableGroup, insert, insertOnPlace, insertAfter);
  }

  function onRemovePad(padId: number): void {
    pageStore.removePad(tableGroup, padId);
  }

  const isOpened = pageStore.activeTableGroupsIndexes.includes(tableGroup.indexKey);

  return (
    <div className={styles.container}>
      <Collapse
        onChange={handleChange}
        destroyInactivePanel={true}
        activeKey={isOpened ? tableGroup.indexKey : undefined}
        expandIcon={({ isActive }) => (
          <DropdownIcon className={clsx(styles.expandIcon, isActive && styles.expandIconActive)} />
        )}
      >
        <Collapse.Panel
          header={<TableGroupHeader title={title} subTitles={subTitles} />}
          key={tableGroup.indexKey}
          extra={
            pageStore.mode === PAGE_MODE.edit && (
              <Button onClick={onOpenAddPadSidebar} variant="secondary" icon={<AddIcon />}>
                {t('addBushOrWell')}
              </Button>
            )
          }
        >
          {tableGroup.loading ? (
            <div className={styles.preloaderContainer}>
              <Spin size="large" />
            </div>
          ) : (
            <SortableWrapper setDraggable={setDraggable} pads={tableGroup.pads} onCompute={onComputeRigOperationsList}>
              {tableGroup.pads.map((pad) => (
                <PlanTable
                  notifications={pageStore.notifications}
                  key={pad.key}
                  id={pad.key}
                  pad={pad}
                  draggable={draggable}
                  isEditing={isEditing}
                  onSortTableData={onSortTableData}
                  onFiltersChange={onFiltersChange}
                  setTableView={pageStore.setTableView}
                  directories={pageStore.directories}
                  onWellClick={pageStore.openWellInfoSidebarWithWell}
                  onOpenAddPadSidebar={pageStore.openAddPadSidebar}
                  sorting={tableGroup.sorting}
                  filters={tableGroup.filters}
                  onOpenPad={tableGroup.openPad}
                  onClosePad={tableGroup.closePad}
                  isPadOpened={tableGroup.activePads.includes(pad.key)}
                  rowIdKey={pageStore.rowIdKey}
                  onComputeRigOperations={onComputeRigOperations}
                  isImportOccurred={pageStore.comparison.isImportOccurred}
                  groupType={pageStore.groupType}
                  onRemovePad={() => onRemovePad(pad.id)}
                />
              ))}
            </SortableWrapper>
          )}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
