import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { AddWellToChartSidebarStore } from './add-well-to-chart-sidebar.store';
import { Pads } from './pads';

import styles from './add-well-to-chart-sidebar.module.scss';

type Props = {
  store: AddWellToChartSidebarStore;
};

export const AddWellToChartSidebar = observer(function AddWellToChartSidebar({ store }: Props) {
  const { t } = useTranslation('drawers');
  const { isInitialized, currentBrigade, currentDrillingRig, isLoading, loadRigData, init, changeStartDate } = store;

  useEffect(() => {
    if (currentBrigade && currentDrillingRig && isInitialized) {
      loadRigData();
      changeStartDate(null);
    }
  }, [isInitialized, currentDrillingRig, currentBrigade, loadRigData, changeStartDate]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Sidebar
      isOpened={store.isOpen}
      onClose={store.closeSidebar}
      closeOnClickOutside
      title={store.rigTitle || t('drawers:addPadDrawer.padOrWell')}
      description={t(`drawers:addToChart.${store.pads.length ? 'descriptionWithWells' : 'descriptionWithoutWells'}`)}
      size="l"
    >
      <div className={styles.contentContainer}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
        {!!store.pads.length && <Pads store={store} />}
      </div>
    </Sidebar>
  );
});
