import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as WellIcon } from 'src/shared/assets/icons/well-icons/vertical.svg';
import { Button } from 'src/shared/components/button/button';
import { Modal } from 'src/shared/components/modal/modal';
import { ROUTES } from 'src/shared/constants/routes';

import { DragArea } from './drag-area/drag-area';

import styles from './upload-modal.module.scss';
import './upload-bg.scss';

type Props = {
  isUploadModalOpened: boolean;
  isLoading: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
};

export const UploadModal = observer(function UploadModal({ isUploadModalOpened, onClose, isLoading, onUpload }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  function handleUpload(file: File): void {
    onUpload(file);
  }

  function handleGoToNewWellForm(): void {
    navigate(ROUTES.newWell, {
      state: {
        from: location.pathname,
      },
    });
  }

  return (
    <Modal title={t('drillingCarpet:UploadPlanModal.title')} isOpened={isUploadModalOpened} handleClose={onClose}>
      <p className={styles.text}>{t('drillingCarpet:UploadPlanModal.description')}</p>
      <DragArea isLoading={isLoading} onFile={handleUpload} />
      <p className={styles.text}>{t('drillingCarpet:UploadPlanModal.or')}</p>
      <div className={styles.createArea}>
        <div className={styles.infoWrapper}>
          <div className={styles.iconWrapper}>
            <WellIcon stroke="white" />
          </div>
          <p className={styles.text}>{t('drillingCarpet:UploadPlanModal.createWell')}</p>
        </div>
        <Button prefixIcon={<div className={styles.plus} />} variant="primary" onClick={handleGoToNewWellForm}>
          <p className={styles.buttonText}>{t('common:Buttons.create')}</p>
        </Button>
      </div>
    </Modal>
  );
});
