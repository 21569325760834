import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/trash.svg';
import { Button } from 'src/shared/components/button';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { ConflictSidebarStore } from './conflict-sidebar.store';
import { ConflictRigOperation } from './entities/conflict-rig-operation';
import { DraggableWell } from './well/draggable-well';

import styles from './conflict-sidebar.module.scss';

interface Props {
  sidebarStore: ConflictSidebarStore;
  onRemoveRigOperation(rigOperation: ConflictRigOperation): void;
}

export const ConflictSidebar = observer(function ConflictSidebar({ sidebarStore, onRemoveRigOperation }: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'ConflictSidebar' });

  const { isOpen, isHidden, conflictRigOperation, onPointerOutOfSidebar, onPointerInSidebar } = sidebarStore;

  function onDeleteRigOperation(): void {
    if (sidebarStore.conflictRigOperation) {
      onRemoveRigOperation(sidebarStore.conflictRigOperation);
      sidebarStore.onClose();
    }
  }

  return (
    <Sidebar
      isOpened={isOpen}
      isHidden={isHidden}
      onLeave={onPointerOutOfSidebar}
      onEnter={onPointerInSidebar}
      title={t('title')}
      size="l"
      closeOnClickOutside={false}
    >
      <div className={styles.container}>
        <p className={styles.descriptionText}>{t('description')}</p>
        {/* TODO Вернуть, когда бэкэнд добавит БУ в ответ */}
        {/* <p className={styles.rigTitle}>{conflictRigOperation?.parentRigTitle}</p> */}
        {conflictRigOperation && <DraggableWell item={conflictRigOperation} variant="white" className={styles.well} />}
      </div>

      <div className={styles.footer}>
        <Button
          variant="danger"
          icon={<TrashIcon className={styles.deleteIcon} />}
          iconPosition="before"
          className={styles.deleteButton}
          onClick={onDeleteRigOperation}
        >
          {t('deleteSet')}
        </Button>
      </div>
    </Sidebar>
  );
});
