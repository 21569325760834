import { Range } from '../../../layers/model';

export class LoadingIndicatorsColumn {
  range: Range<number>;

  constructor(range: Range<number>) {
    this.range = range;
  }

  get start(): number {
    return this.range.start;
  }

  get end(): number {
    return this.range.end;
  }

  get key(): string {
    return `empty-column-${this.start}-${this.end}`;
  }
}
