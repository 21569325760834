import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button';

import { EmptyDataHeader } from '../../../components/timeline-chart/components/data/empty-data-header';
import { WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { useClickHandler } from '../../../shared/use-click-handler';
import { Viewport } from '../../../shared/viewport/viewport';

import styles from './data-items.module.scss';

interface Props {
  item: WellsGroup;
  verticalViewport: Viewport;
  isLoading: boolean;
  onWellAdd(groupId: number): void;
}

export const EditingWellsDataHeader = observer(function EditingWellsDataHeader({
  item,
  verticalViewport,
  onWellAdd,
  isLoading,
}: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Editing' });

  const { onPointerUp, onPointerDown } = useClickHandler(() => onWellAdd(item.id));

  return (
    <EmptyDataHeader item={item} verticalViewport={verticalViewport} className={styles.rowEditing}>
      <Button
        variant="primary"
        icon={<PlusIcon />}
        iconPosition="before"
        className={styles.addButton}
        disabled={isLoading}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
      >
        {t('addWell')}
      </Button>
    </EmptyDataHeader>
  );
});
