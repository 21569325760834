import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';

import { AddWellInUnplannedRigSidebar } from 'src/features/add-well-to-unplanned-rig-sidebar/add-well-to-unplanned-rig-sidebar';
import { TimelineChart } from 'src/features/drilling-chart/components/timeline-chart/timeline-chart';
import { SidebarMode } from 'src/features/drilling-rig-sidebar/store/types';
import { DrillingRigSidebar } from 'src/features/drilling-rig-sidebar/views';
import { PadInfoSidebar } from 'src/features/pad-info-sidebar';
import { WellInfoSidebar } from 'src/features/well-info-sidebar';
import { Loader } from 'src/shared/components/loader';
import { useModal } from 'src/shared/hooks/use-modal';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import { DataContainer } from '../../components/data-container/data-container';
import { ChartCollapseButton } from '../../features/chart-collapse-button';
import { ChartSearch } from '../../features/chart-search';
import { RigsDataHeaders } from '../../features/data-headers/view/rigs-data-headers';
import { RigsDataItemsBackground } from '../../features/data-items-background/view';
import { EditingRigsDataItemsBackground } from '../../features/data-items-background/view/editing-rigs-data-items-background';
import { EditingRigsDataItemsCompact } from '../../features/data-items-compact/view/editing-rigs-data-items-compact';
import { RigsDataItemsCompact } from '../../features/data-items-compact/view/rigs-data-items-compact';
import { EditingRigsDataItemsFull } from '../../features/data-items-full/view/editing-rigs-data-items-full';
import { RigsDataItemsFull } from '../../features/data-items-full/view/rigs-data-items-full';
import { DndContext } from '../../features/editing/view/context/dnd-context';
import { FiltersModal } from '../../features/filters-modal';
import { FullScreenButtons } from '../../features/full-screen-buttons';
import { HeaderPresenter } from '../../features/header/presenter/header-presenter';
import { Header } from '../../features/header/view/header';
import { IndicatorsTable } from '../../features/indicators-table/view/indicators-table';
import { SortingContext } from '../../features/sorting/sorting-context';
import { DataView } from '../../shared/data-view/data-view';
import { RigsChartSidebarsStore } from '../../shared/rigs-chart-sidebars.store';
import { AddRigSidebar } from '../add-rig-sidebar/add-rig-sidebar';
import { ConfirmationRemoveSidebar } from '../confirmation-remove-sidebar';
import { ConflictSidebar } from '../conflict-sidebar';
import { IndicatorsSettings } from '../indicators-view-settings-sidebar/entities';
import { PadsAndWellsSidebar } from '../pads-and-wells-sidebar';
import { SummaryDataSidebar } from '../summary-data-sidebar';

import { RigsChartStore } from './rigs-chart.store';

import styles from './drilling-rigs-chart.module.scss';

interface Props {
  store: RigsChartStore;
  openIndicatorsViewSettings: VoidFunction;
  headerPresenter: HeaderPresenter;
  dataView: DataView;
  isEditing: boolean;
  onWellEdit(wellId: number | string): void;
  onMakeWellIsPlanned(wellId: number | string): void;
  onWellAdd: VoidFunction;
  onChange(planVersionId: number): Promise<void>;
  containerWidth?: number;
  containerHeight?: number;
  indicatorsSettings?: IndicatorsSettings;
  isFullScreen?: boolean;
  onFullScreenExit?: VoidFunction;
}

export const DrillingRigsChart = observer(function DrillingRigsChart({
  store,
  containerWidth,
  containerHeight,
  openIndicatorsViewSettings,
  headerPresenter,
  dataView,
  isFullScreen,
  onFullScreenExit,
  indicatorsSettings,
  onWellEdit,
  onMakeWellIsPlanned,
  isEditing,
  onWellAdd,
  onChange,
}: Props) {
  const {
    isLoading,
    isDataUpdating,
    dataHeadersPresenter,
    dataItemsBackgroundPresenter,
    summaryDataPresenter,
    horizontalViewport,
    verticalViewport,
    summaryViewProvider,
    summaryDataSidebar,
    filtersForm,
    viewSettings,
    indicators,
    data,
    rigsSortingModule,
    rigOperationsDndModule,
  } = store;

  const { isOpen: isSummaryDataOpen, open: openSummaryData, close: closeSummaryData } = summaryDataSidebar;
  const { notifications } = useStore();

  const { isOpen: isAddRigSidebarOpen, openModal: openAddRigSidebar, closeModal: closeAddRigSidebar } = useModal();

  const [sidebars] = useState(() => new RigsChartSidebarsStore());

  const dataViewType = dataView.type;

  const isCompactView = useMemo(
    () => dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty,
    [dataViewType]
  );

  const handleWellFormOpen = () => {
    onWellAdd();
    sidebars.onPadInfoClose();
  };

  useEffect(() => {
    if (isCompactView) {
      closeSummaryData();
    }
  }, [closeSummaryData, isCompactView]);

  useEffect(() => {
    const disposeRigsSorting = rigsSortingModule.init();

    return () => {
      disposeRigsSorting();
    };
  }, [rigsSortingModule]);

  useEffect(() => {
    const disposeRigOperationsDnd = rigOperationsDndModule.init();

    return () => {
      disposeRigOperationsDnd();
    };
  }, [rigOperationsDndModule]);

  if (isLoading) {
    return <Loader className={styles.loader} />;
  }

  return (
    <div className={styles.chartWrapper}>
      {isDataUpdating && (
        <div className={styles.dataUpdatingLoaderContainer}>
          <Loader className={styles.dataUpdatingLoader} />
        </div>
      )}

      <TimelineChart className={styles.chart}>
        <SortingContext.Provider value={rigsSortingModule.dndContext}>
          <DndContext.Provider value={rigOperationsDndModule.dndContext}>
            <DataContainer
              className={styles.chartDataContainer}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              onPointerDown={dataItemsBackgroundPresenter.onPointerDown}
              onPointerMove={dataItemsBackgroundPresenter.onPointerMove}
              onPointerUp={dataItemsBackgroundPresenter.onPointerUp}
              onScroll={dataItemsBackgroundPresenter.onScroll}
              onZoom={dataItemsBackgroundPresenter.onZoom}
              onZoomOut={dataItemsBackgroundPresenter.onZoomOut}
            >
              {!!rigOperationsDndModule && isEditing ? (
                <>
                  {rigOperationsDndModule.isLoading && (
                    <div className={styles.dataUpdatingLoaderContainer}>
                      <Loader className={styles.dataUpdatingLoader} />
                    </div>
                  )}

                  <EditingRigsDataItemsBackground
                    data={data}
                    verticalViewport={verticalViewport}
                    onWellAdd={rigOperationsDndModule.padsAndWellsSidebarVisibilityController.open}
                    onRigAdd={openAddRigSidebar}
                  />

                  <PadsAndWellsSidebar
                    dndStore={rigOperationsDndModule.dndContext}
                    store={rigOperationsDndModule.padsAndWellsSidebarStore}
                    isOpened={rigOperationsDndModule.padsAndWellsSidebarVisibilityController.isOpen}
                    onClose={rigOperationsDndModule.padsAndWellsSidebarVisibilityController.close}
                    onWellClick={sidebars.onWellClick}
                    onNewWellClick={() => {
                      sidebars.onPadInfoClose();
                      sidebars.onRigInfoClose();
                      onWellAdd();
                    }}
                  />

                  <ConflictSidebar
                    sidebarStore={rigOperationsDndModule.conflictSidebar}
                    onRemoveRigOperation={rigOperationsDndModule.removeRigOperation}
                  />

                  <ConfirmationRemoveSidebar
                    sidebarStore={rigOperationsDndModule.confirmationRemoveSidebar}
                    onRemoveRigOperation={rigOperationsDndModule.removeRigOperation}
                    onRemoveRigOperationsList={rigOperationsDndModule.removeRigOperationsList}
                  />

                  <AddRigSidebar
                    isOpen={isAddRigSidebarOpen}
                    onClose={closeAddRigSidebar}
                    onApply={rigOperationsDndModule.addTemporaryRig}
                  />

                  <AddWellInUnplannedRigSidebar
                    isOpened={rigOperationsDndModule.planRigOperationSidebarVisibilityController.isOpen}
                    datePickerValue={rigOperationsDndModule.planRigOperationSidebar.workStartDate}
                    onWorkStartChange={rigOperationsDndModule.planRigOperationSidebar.setWorkStartDate}
                    onSuccess={rigOperationsDndModule.confirmAddingRigStartDate}
                    onCancel={rigOperationsDndModule.planRigOperationSidebarVisibilityController.close}
                    onCloseSidebar={rigOperationsDndModule.planRigOperationSidebarVisibilityController.close}
                  />
                </>
              ) : (
                <RigsDataItemsBackground data={data} verticalViewport={verticalViewport} />
              )}

              {dataViewType === DataView.DataViewType.full && (
                <>
                  {!!rigOperationsDndModule && isEditing ? (
                    <EditingRigsDataItemsFull
                      data={data}
                      rigOperationDndModule={rigOperationsDndModule}
                      horizontalViewport={horizontalViewport}
                      verticalViewport={verticalViewport}
                      viewSettingsProvider={viewSettings.provider}
                      onPadClick={sidebars.onPadClick}
                      onWellClick={sidebars.onWellClick}
                    />
                  ) : (
                    <RigsDataItemsFull
                      data={data}
                      horizontalViewport={horizontalViewport}
                      verticalViewport={verticalViewport}
                      viewSettingsProvider={viewSettings.provider}
                      onPadClick={sidebars.onPadClick}
                      onWellClick={sidebars.onWellClick}
                    />
                  )}
                </>
              )}

              {isCompactView && (
                <>
                  {!!rigOperationsDndModule && isEditing ? (
                    <EditingRigsDataItemsCompact
                      data={data}
                      horizontalViewport={horizontalViewport}
                      verticalViewport={verticalViewport}
                      viewSettingsProvider={viewSettings.provider}
                      onPadClick={sidebars.onPadClick}
                    />
                  ) : (
                    <RigsDataItemsCompact
                      data={data}
                      horizontalViewport={horizontalViewport}
                      verticalViewport={verticalViewport}
                      viewSettingsProvider={viewSettings.provider}
                      onPadClick={sidebars.onPadClick}
                    />
                  )}
                </>
              )}
            </DataContainer>
          </DndContext.Provider>

          <RigsDataHeaders
            data={data}
            viewport={verticalViewport}
            className={styles.chartDataHeaders}
            isCompactView={isCompactView}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            viewSettingsProvider={viewSettings.provider}
            onScroll={dataHeadersPresenter.onScroll}
            onHeightChange={dataHeadersPresenter.onHeightChange}
            onGroupClick={dataHeadersPresenter.onGroupCollapseToggle}
            onRowHeaderClick={sidebars.onRigClick}
          />
        </SortingContext.Provider>

        <Header
          data={headerPresenter.data}
          viewport={horizontalViewport}
          className={styles.chartHeader}
          onPointerDown={headerPresenter.onPointerDown}
          onPointerMove={headerPresenter.onPointerMove}
          onPointerUp={headerPresenter.onPointerUp}
          onZoom={headerPresenter.onZoom}
          onZoomOut={headerPresenter.onZoomOut}
        />

        <ChartCollapseButton
          isCollapsed={store.isGroupsCollapsed}
          toggleIsCollapsed={store.toggleGroupsIsCollapsed}
          className={styles.collapseToggle}
        />

        <ChartSearch
          className={styles.chartSearch}
          value={store.searchTerm}
          onChange={store.onSearchChange}
          onFiltersButtonClick={
            filtersForm?.onOpen || (() => notifications.showErrorMessageT('errors:failedToLoadFilters'))
          }
          highlightFiltersButton={store.hasSelectedFilters}
        />

        {!!indicatorsSettings && (
          <IndicatorsTable
            data={indicators.presenter.data}
            settings={indicatorsSettings}
            viewport={horizontalViewport}
            className={styles.chartIndicators}
            onPointerDown={indicators.presenter.onPointerDown}
            onPointerMove={indicators.presenter.onPointerMove}
            onPointerUp={indicators.presenter.onPointerUp}
            onZoom={indicators.presenter.onZoom}
            openViewSettings={openIndicatorsViewSettings}
          />
        )}

        {isFullScreen && !!onFullScreenExit && (
          <div className={styles.chartSummaryWrapper}>
            <div className={styles.chartSummary}>
              <FullScreenButtons
                onFullScreenExit={onFullScreenExit}
                onSummaryOpen={openSummaryData}
                summaryButtonDisabled={isCompactView || !summaryViewProvider}
              />
              {!!summaryViewProvider && (
                <SummaryDataSidebar
                  isOpen={isSummaryDataOpen}
                  onClose={closeSummaryData}
                  data={summaryDataPresenter.data}
                  backgroundItems={dataItemsBackgroundPresenter.data}
                  viewport={verticalViewport}
                  viewProvider={summaryViewProvider}
                  selectedYear={summaryDataPresenter.year}
                  onYearChange={summaryDataPresenter.onYearChange}
                  onScroll={summaryDataPresenter.onScroll}
                />
              )}
            </div>
          </div>
        )}
      </TimelineChart>

      {!!filtersForm && <FiltersModal formStore={filtersForm} />}

      {hasValue(sidebars.selectedPadId) && hasValue(sidebars.rigOfSelectedPadId) && (
        <PadInfoSidebar
          isOpen={sidebars.padInfo.isOpen}
          onClose={sidebars.onPadInfoClose}
          padId={sidebars.selectedPadId}
          rigId={sidebars.rigOfSelectedPadId}
          rigsChartStore={store}
          onWellClick={sidebars.onWellClick}
          onWellAdd={handleWellFormOpen}
          onWellEditingClick={(wellId: number) => {
            sidebars.onPadInfoClose();
            sidebars.onRigInfoClose();
            onWellEdit(wellId);
          }}
          onChange={onChange}
        />
      )}

      {hasValue(sidebars.selectedWellId) && (
        <WellInfoSidebar
          wellId={sidebars.selectedWellId}
          isOpen={sidebars.wellInfo.isOpen}
          onClose={sidebars.onWellInfoClose}
          onEdit={(wellId: number) => {
            sidebars.onPadInfoClose();
            sidebars.onRigInfoClose();

            rigOperationsDndModule.padsAndWellsSidebarVisibilityController.close();

            onWellEdit(wellId);
          }}
          initialActiveTab={sidebars.initialWellSidebarTab}
        />
      )}

      {hasValue(sidebars.selectedRigId) && (
        <DrillingRigSidebar
          isOpened={sidebars.rigInfo.isOpen}
          onClose={sidebars.onRigInfoClose}
          sidebarMode={isEditing ? SidebarMode.edit : SidebarMode.view}
          rigId={sidebars.selectedRigId}
          onPadClick={sidebars.onPadClick}
          onWellClick={sidebars.onWellClick}
          onWellAdd={() => {
            sidebars.onPadInfoClose();
            sidebars.onRigInfoClose();
            onWellAdd();
          }}
          onEditingWellClick={(wellId: number) => {
            sidebars.onPadInfoClose();
            sidebars.onRigInfoClose();
            onWellEdit(wellId);
          }}
        />
      )}
    </div>
  );
});
