import { Input } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode, ChangeEvent } from 'react';

import { InputError } from '../input-error';
import { InputLabel } from '../input-label';

import styles from './form-text-area.module.scss';

const TextArea = Input.TextArea;

type Props = {
  className?: string;
  value?: string | null;
  label?: string | ReactElement;
  isError?: boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  prefix?: ReactNode;
  unit?: string;
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const FormTextArea = observer(function FormTextArea({
  className,
  value,
  label,
  isError,
  errorText,
  placeholder,
  isDisabled,
  onChange,
  onFocus,
  onBlur,
}: Props) {
  const labelRender = (): JSX.Element | undefined => {
    if (typeof label === 'string') {
      return <InputLabel text={label} />;
    }

    return label;
  };

  return (
    <div className={clsx(styles.textAreaWrapper, className)}>
      {labelRender()}

      <TextArea
        value={value === null ? '' : value}
        placeholder={placeholder}
        className={clsx(styles.textArea, isDisabled && styles.textArea__disabled)}
        allowClear
        status={isError ? 'error' : undefined}
        disabled={isDisabled}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
