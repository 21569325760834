import { observer } from 'mobx-react-lite';
import React from 'react';

import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/dots.svg';

import styles from './drag-handler.module.scss';

export const DragHandler = observer(function DragHandler() {
  return (
    <div className={styles.container}>
      <MoveIcon />
    </div>
  );
});
