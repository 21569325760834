import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { EllipsisTableCell } from 'src/shared/components/table-cell/ellipsis-table-cell';
import styles from 'src/shared/components/table-cell/text-table-cell/text-table-cell.module.scss';
import { mapFieldValue, serializeFieldValue } from 'src/shared/components/table-cell/text-table-cell/utils';
import { CellValueType } from 'src/store/table/types';

interface Props {
  fieldValue: CellValueType;
  onEditCell: (value: string | number | null) => void;
}

export const TextTableCell = observer(function TextTableCell({ fieldValue, onEditCell }: Props) {
  const [isEditingCell, setIsEditingCell] = useState<boolean>(false);

  const [value, setValue] = useState<string | number>(() => mapFieldValue(fieldValue));

  const inputRef = useRef<HTMLInputElement>(null);

  function onChangeValue(e: React.ChangeEvent<HTMLInputElement>): void {
    setValue(e.target.value);
  }

  function onSaveValue(): void {
    const serializedValue = serializeFieldValue(value);

    if (serializedValue !== fieldValue) {
      onEditCell(serializedValue);
    }

    setIsEditingCell(false);
  }

  function onEnableEditingMode(): void {
    setIsEditingCell(true);
  }

  useEffect(() => {
    if (isEditingCell) {
      inputRef.current!.focus();
    }
  }, [isEditingCell]);

  useEffect(() => {
    setValue(mapFieldValue(fieldValue));
  }, [fieldValue]);

  return (
    <form className={styles.wrapper} onClick={onEnableEditingMode}>
      {isEditingCell ? (
        <input value={value} onChange={onChangeValue} onBlur={onSaveValue} ref={inputRef} />
      ) : (
        <EllipsisTableCell value={value} />
      )}
    </form>
  );
});
