import { observer } from 'mobx-react-lite';
import React from 'react';

import { IWell } from 'src/api/draft/types';
import { ColoredCard } from 'src/shared/components/colored-card';

import { RigConflictsSidebarViewProvider } from '../../store/rig-conflicts-sidebar-view-provider';

import styles from './well.module.scss';

interface Props {
  well: IWell;
  viewProvider: RigConflictsSidebarViewProvider;
  className?: string;
}

export const Well = observer(function Well({ well, viewProvider, className }: Props) {
  return (
    <div className={styles.container}>
      <ColoredCard
        cardColor={viewProvider.getWellColor()}
        title={viewProvider.getWellTitle(well)}
        text={viewProvider.getWellAttributes(well)}
        showExpandButton={false}
        className={className}
      />
    </div>
  );
});
