import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReturnIcon } from 'src/shared/assets/icons/round-arrow-left.svg';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/trash.svg';
import { Button } from 'src/shared/components/button';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { ConfirmationRemoveSidebarStore } from './confirmation-remove-sidebar.store';
import { RigOperation } from './entities/rig-operation';
import { Pad } from './pad/pad';
import { Well } from './pad/well/well';
import { DELETING_OBJECT } from './types';

import styles from './confirmation-remove-sidebar.module.scss';

interface Props {
  sidebarStore: ConfirmationRemoveSidebarStore;
  onRemoveRigOperation(rigOperation: RigOperation, forceDelete?: boolean): void;
  onRemoveRigOperationsList(rigOperation: RigOperation[], forceDelete?: boolean): void;
}

export const ConfirmationRemoveSidebar = observer(function ConfirmationRemoveSidebar({
  sidebarStore,
  onRemoveRigOperation,
  onRemoveRigOperationsList,
}: Props) {
  const { t } = useTranslation('drawers', { keyPrefix: 'confirmationRemoveSidebar' });

  const {
    isOpen,
    affectedRigOperations,
    init,
    deletingRigOperations,
    onClose,
    deletingObject,
    deletingRigOperationsParentRigName,
    deletingPadName,
  } = sidebarStore;

  function onConfirmRemove() {
    switch (deletingObject) {
      case DELETING_OBJECT.rigOperation:
        const someRigOperation = deletingRigOperations.at(0);
        someRigOperation && onRemoveRigOperation(someRigOperation, true);
        onClose();
        return;

      case DELETING_OBJECT.rigOperationsList:
        onRemoveRigOperationsList(deletingRigOperations);
        onClose();
        return;

      default:
        onClose();
    }
  }

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Sidebar
      isOpened={isOpen}
      onLeave={() => {}}
      onEnter={() => {}}
      title={t('title')}
      size="l"
      closeOnClickOutside={false}
    >
      <div className={styles.container}>
        <p className={styles.descriptionText}>
          {t('descriptionReason', { drillingRig: deletingRigOperationsParentRigName })}
        </p>

        <Pad rigOperations={deletingRigOperations} title={deletingPadName} />

        <p className={styles.descriptionText}>{t('descriptionConsequence')}</p>

        <div className={styles.affectedRigOperationsListContainer}>
          {affectedRigOperations.map((rigOperation) => (
            <div key={rigOperation.uniqueCreationKey}>
              <p className={styles.rigTitle}>{rigOperation.parentRigTitle}</p>
              <Well item={rigOperation} className={styles.well} variant="white" />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          variant="success"
          icon={<ReturnIcon />}
          iconPosition="before"
          className={styles.button}
          onClick={onClose}
        >
          {t('cancelButton')}
        </Button>

        <Button
          variant="danger"
          icon={<TrashIcon />}
          iconPosition="before"
          className={styles.button}
          onClick={onConfirmRemove}
        >
          {t('confirmButton')}
        </Button>
      </div>
    </Sidebar>
  );
});
