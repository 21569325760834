import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { PlanControlBar } from 'src/features/planning/views/plan-control-bar';
import { TableGroup } from 'src/features/planning/views/table-group';
import { ViewSettingsSidebar } from 'src/features/planning/views/view-settings-sidebar';
import { UnplannedRigOperationsSidebar } from 'src/features/unplanned-rig-operations-sidebar/unplanned-rig-operations-sidebar';
import { WellForm } from 'src/features/well-form';
import { GeoTasksList } from 'src/features/well-form/well-form-geo-tasks-list/well-form-geo-tasks-list';
import { WellFormSubheader } from 'src/features/well-form/well-form-subheader';
import { WellInfoSidebar } from 'src/features/well-info-sidebar';
import { PlanPageStore } from 'src/pages/plan-page/plan-page.store';
import { PAGE_MODE } from 'src/pages/plan-page/types';
import { Loader } from 'src/shared/components/loader';
import { useStore } from 'src/store';

import styles from './plan-page.module.scss';

export const PlanPage: React.FC = observer(function PlanPage() {
  const rootStore = useStore();

  const [store] = useState(() => new PlanPageStore(rootStore));
  const { wellFormManager } = store;

  const pageEffect = store.pageEffect;

  useEffect(pageEffect, [pageEffect]);

  useEffect(() => {
    wellFormManager.init();

    wellFormManager.onCloseForm = store.onFormClose;
  }, [wellFormManager, store]);

  return (
    <>
      <div className={clsx(styles.mainContentWrapper, wellFormManager.isFormOpen && styles.mainContentWrapper_hidden)}>
        <PlanControlBar pageStore={store} />
        <div className={styles.container}>
          {store.isLoading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            store.planData.map((tableGroup) => (
              <TableGroup
                pageStore={store}
                title={tableGroup.title}
                subTitles={tableGroup.subTitles}
                tableGroup={tableGroup}
                key={tableGroup.indexKey}
                isEditing={store.mode === PAGE_MODE.edit}
              />
            ))
          )}
        </div>
        <ViewSettingsSidebar store={store} />
        <UnplannedRigOperationsSidebar
          isOpened={store.isAddPadSidebarOpened}
          onClose={store.closeAddPadSidebar}
          padName={store.selectedPadName}
          onEditWell={wellFormManager.makeWellIsPlanned}
          onNewWellClick={wellFormManager.onWellAdd}
          onWellClick={store.openWellInfoSidebarWithWell}
        />
      </div>
      {wellFormManager.isFormOpen && (
        <WellForm
          isFormFetching={wellFormManager.isSaveUpdateLoading}
          isFormLoading={wellFormManager.isFormLoading}
          wellFormManager={wellFormManager}
          onSplitApproach={wellFormManager.splitApproach}
          onAddNewApproach={wellFormManager.addNewApproach}
          subheader={
            <WellFormSubheader
              title={wellFormManager.currentWellName}
              formStore={wellFormManager.currentFormStore}
              additionalHeaderComponent={<GeoTasksList wellFormManager={wellFormManager} />}
              onSave={wellFormManager.saveWell}
              onCancel={wellFormManager.cancelForm}
            />
          }
        />
      )}
      {store.selectedWellId && (
        <WellInfoSidebar
          wellId={store.selectedWellId}
          isOpen={store.isWellInfoSidebarOpen}
          onClose={store.closeWellInfoSidebar}
          onEdit={wellFormManager.onWellEdit}
          disableEditing={!store.editing.isCanEditing}
        />
      )}
    </>
  );
});
