import { TrendType } from 'src/shared/constants/trend-type';

export const indicatorNameToTrendType = (indicatorName: string): TrendType | undefined => {
  switch (indicatorName) {
    case 'passingValue':
      return TrendType.passing;

    case 'drillingEnding':
      return TrendType.drillingCompleteWellsCount;

    case 'explorationEnding':
      return TrendType.developmentCompleteWellsCount;

    case 'commercialSpeed':
      return TrendType.commercialSpeed;
  }
};

export const trendTypeToIndicatorName = (trendType: TrendType): string | undefined => {
  switch (trendType) {
    case TrendType.passing:
      return 'passingValue';

    case TrendType.drillingCompleteWellsCount:
      return 'drillingEnding';

    case TrendType.developmentCompleteWellsCount:
      return 'explorationEnding';

    case TrendType.commercialSpeed:
      return 'commercialSpeed';
  }
};
