import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { IPresenter } from 'src/features/drilling-chart/layers/presenter';

import { TimeUnit } from '../../../shared/time-unit';
import { TimelineController } from '../../../shared/timeline-controller';
import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport } from '../../../shared/viewport/viewport-calculator';
import { TimelineDataModel } from '../data/timeline-data-model';

export class TimelinePresenter implements TimelinePresenter.ITimelinePresenter {
  private isMoveMode = false;

  private readonly horizontalViewport: Viewport;
  private readonly timelineViewport: Viewport;
  private readonly timelineDataRange: Viewport;
  private readonly dataModel: TimelineDataModel;
  private readonly timelineController: TimelineController;

  @observable private timeUnit: TimeUnit;

  constructor(
    horizontalViewport: Viewport,
    timelineViewport: Viewport,
    timelineDataRage: Viewport,
    dataModel: TimelineDataModel,
    timeUnit: TimeUnit,
    timelineController: TimelineController
  ) {
    this.horizontalViewport = horizontalViewport;
    this.timelineViewport = timelineViewport;
    this.timelineDataRange = timelineDataRage;
    this.dataModel = dataModel;
    this.timeUnit = timeUnit;
    this.timelineController = timelineController;

    makeObservable(this);
  }

  @action.bound
  init(): VoidFunction {
    const disposeRangeSetter = autorun(() => {
      const { start, end } = this.timelineDataRange;

      this.timelineViewport.setRange(start, end);
      this.dataModel.setRange(start, end);
    });

    return () => {
      disposeRangeSetter();
    };
  }

  @action.bound
  setUnit(unit: TimeUnit) {
    this.timeUnit = unit;
  }

  @computed
  get data(): TimelineDataModel.ITimelineItem[] {
    return this.dataModel.getData();
  }

  @action.bound
  onDragDown = (): void => {
    this.isMoveMode = true;
  };

  @action.bound
  onDragMove = (offsetX: number, direction: TimelinePresenter.DragDirection): void => {
    if (!this.isMoveMode) {
      return;
    }

    const { movedStart, movedEnd } = moveViewport(this.horizontalViewport, -offsetX);

    if (direction === TimelinePresenter.DragDirection.left && movedStart < this.horizontalViewport.end) {
      const validStart = Math.max(movedStart, this.timelineViewport.start);

      this.horizontalViewport.setRange(validStart, this.horizontalViewport.end);
    }

    if (direction === TimelinePresenter.DragDirection.right && movedEnd > this.horizontalViewport.start) {
      const validEnd = Math.min(movedEnd, this.timelineViewport.end);
      this.horizontalViewport.setRange(this.horizontalViewport.start, validEnd);
    }
  };

  @action.bound
  onRightDragUp(): void {
    this.isMoveMode = false;

    const { end: viewportEnd } = this.horizontalViewport;
    const nearestPoint = this.timelineController.getNearestPoint(viewportEnd);

    this.horizontalViewport.setRange(this.horizontalViewport.start, nearestPoint - 1, { animate: true });
  }

  @action.bound
  onLeftDragUp(): void {
    this.isMoveMode = false;

    const { start: viewportStart } = this.horizontalViewport;
    const nearestPoint = this.timelineController.getNearestPoint(viewportStart);

    this.horizontalViewport.setRange(nearestPoint, this.horizontalViewport.end, { animate: true });
  }

  @action.bound
  onSelectAreaUp(): void {
    this.isMoveMode = false;

    const { start: viewportStart, end: viewportEnd } = this.horizontalViewport;
    const startNearestPoint = this.timelineController.getNearestPoint(viewportStart);
    const endNearestPoint = this.timelineController.getNearestPoint(viewportEnd);

    this.horizontalViewport.setRange(startNearestPoint, endNearestPoint - 1, { animate: true });
  }

  @action.bound
  disableMove() {
    this.isMoveMode = false;
  }

  @action.bound
  onSelectAreaMove = (offsetX: number): void => {
    if (!this.isMoveMode) {
      return;
    }

    const { movedStart, movedEnd } = moveViewport(this.horizontalViewport, -offsetX);

    const isViewportInDataRange = movedStart >= this.timelineViewport.start && movedEnd <= this.timelineViewport.end;
    const isViewportMovedFromLeftBoundToRight =
      this.horizontalViewport.start < movedStart && movedEnd <= this.timelineViewport.end;
    const isViewportMovedFromRightBoundToLeft =
      this.horizontalViewport.end > movedEnd && movedStart >= this.timelineViewport.start;

    if (isViewportInDataRange || isViewportMovedFromLeftBoundToRight || isViewportMovedFromRightBoundToLeft) {
      this.horizontalViewport.setRange(movedStart, movedEnd);
    }
  };
}

export namespace TimelinePresenter {
  export enum DragDirection {
    left = 'left',
    right = 'right',
  }

  export interface ITimelinePresenter extends IPresenter<TimelineDataModel.ITimelineItem[], number> {
    onDragDown(x: number): void;
    onDragMove(x: number, direction: DragDirection): void;
    onRightDragUp(x: number): void;
    onSelectAreaMove(x: number): void;
  }
}
