import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SortDownIcon } from 'src/shared/assets/icons/sort-down.svg';
import { ReactComponent as SortUpIcon } from 'src/shared/assets/icons/sort-up.svg';
import { Button } from 'src/shared/components/button';

import styles from './tooltip-buttons-group.module.scss';

interface Props {
  onSortInAscendingOrder?(): void;
  onSortInDescendingOrder?(): void;
  onResetSorting?(): void;
}

export const TooltipButtonsGroup = observer(function TooltipButtonsGroup({
  onSortInAscendingOrder,
  onSortInDescendingOrder,
  onResetSorting,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      {onSortInAscendingOrder && (
        <Button
          variant="secondary"
          icon={<SortUpIcon />}
          className={styles.tooltipButton}
          onClick={onSortInAscendingOrder}
        >
          {t('common:Buttons.sortInAscendingOrder')}
        </Button>
      )}

      {onSortInDescendingOrder && (
        <Button
          variant="secondary"
          icon={<SortDownIcon />}
          className={styles.tooltipButton}
          onClick={onSortInDescendingOrder}
        >
          {t('common:Buttons.sortInDescendingOrder')}
        </Button>
      )}

      {onResetSorting && (
        <Button
          variant="text"
          className={clsx(styles.tooltipButton, styles.tooltipButtonCenter)}
          onClick={onResetSorting}
        >
          {t('common:Buttons.resetSorting')}
        </Button>
      )}
    </>
  );
});
