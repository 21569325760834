import { assert } from 'src/shared/utils/assert';
import { WellTypes } from 'src/types/well-types';

import { ViewSettings } from '../../../../api/chart/drilling-plan-charts-api';

import { ChartWell, WellsGroup } from './entities';

export namespace WellsGroupsAdapter {
  type SourceRefs = {
    wellIdFieldName: string;
  };

  export function init(
    wellsGroups: WellTypes.RawWellsGroupWithItems[],
    previousWellGroups: WellsGroup[] | null,
    { wellIdFieldName }: SourceRefs
  ): WellsGroup[] {
    const initializedWellGroups: WellsGroup[] = [];

    for (const rawWellsGroup of wellsGroups) {
      const initializedWells: ChartWell[] = [];

      for (const rawWell of rawWellsGroup.items) {
        const wellIdFieldValue = rawWell[wellIdFieldName];

        assert(typeof wellIdFieldValue === 'number', `Invalid id in well data with ID: ${wellIdFieldValue}`);

        const well = new ChartWell(rawWell, wellIdFieldValue);

        initializedWells.push(well);
      }

      const wellsGroup = new WellsGroup(rawWellsGroup, rawWellsGroup.id, initializedWells);

      const samePrevGroup = previousWellGroups?.find((group) => group.isEqual(wellsGroup));

      if (samePrevGroup) {
        wellsGroup.setIsCollapsed(samePrevGroup.isCollapsed);
      }

      initializedWellGroups.push(wellsGroup);
    }

    return initializedWellGroups;
  }

  export function applyWellsGroupsSettings(
    wellsGroups: WellsGroup[],
    settings: ViewSettings.WellsOrderSettingsValues | undefined
  ): WellsGroup[] {
    if (settings) {
      for (const wellsGroup of wellsGroups) {
        const groupSettings = settings.groups.find(({ groupId }) => groupId === wellsGroup.id);

        if (groupSettings) {
          wellsGroup.setIsCollapsed(groupSettings.isCollapsed);
        }
      }
    }

    return wellsGroups;
  }
}
