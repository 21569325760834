import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { AssertionError } from 'src/errors/assert';
import { WellTypes } from 'src/types/well-types';

export class WellApi {
  async getWell(geologicalTaskId: number | string, planVersionId: number): Promise<WellTypes.RawWellWithStages> {
    try {
      const { data: well } = await agent.get<WellTypes.RawWellWithStages>(`well/${geologicalTaskId}`, {
        params: { planVersionId },
      });

      return well;
    } catch (e) {
      if (e instanceof AssertionError) {
        throw e;
      }

      throwApiError(e);
    }
  }
}
