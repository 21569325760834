import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';

import { TrendType } from '../../../../../shared/constants/trend-type';
import { ComparingIndicatorColumn } from '../../../presets/comparing-indicators-table/entities';
import { LoadingIndicatorsColumn } from '../../../presets/indicators-view-settings-sidebar/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { IndicatorCell } from '../../indicator-cell';

import styles from './comparing-indicators-table.module.scss';

interface Props {
  item: ComparingIndicatorColumn | LoadingIndicatorsColumn;
  isExpanded: boolean;
  horizontalViewport: Viewport;
}

export const ComparingIndicatorsColumn = observer(function ComparingIndicatorsColumn({
  item,
  isExpanded,
  horizontalViewport,
}: Props) {
  const ref = useMovableElement({ item: { x: { start: item.start, end: item.end } }, horizontalViewport });

  return (
    <div ref={ref} className={styles.dataColumnWrapper}>
      <div className={styles.dataColumn}>
        {item instanceof ComparingIndicatorColumn ? (
          <>
            <IndicatorCell value={item.firstVersionData[TrendType.passing]} />
            <IndicatorCell
              value={item.secondVersionData?.[TrendType.passing]}
              className={styles.indicatorCellComparing}
            />

            {isExpanded &&
              [
                TrendType.commercialSpeed,
                TrendType.developmentCompleteWellsCount,
                TrendType.drillingCompleteWellsCount,
              ].map((indicatorName) => (
                <Fragment key={indicatorName}>
                  <IndicatorCell value={item.firstVersionData[indicatorName]} />
                  <IndicatorCell
                    value={item.secondVersionData?.[indicatorName]}
                    className={styles.indicatorCellComparing}
                  />
                </Fragment>
              ))}
          </>
        ) : (
          <>
            <IndicatorCell className={styles.dataColumnEmpty} />
            <IndicatorCell className={clsx(styles.indicatorCellComparing, styles.dataColumnEmpty)} />

            {isExpanded &&
              [
                TrendType.commercialSpeed,
                TrendType.developmentCompleteWellsCount,
                TrendType.drillingCompleteWellsCount,
              ].map((indicatorName) => (
                <Fragment key={indicatorName}>
                  <IndicatorCell className={styles.dataColumnEmpty} />
                  <IndicatorCell className={clsx(styles.indicatorCellComparing, styles.dataColumnEmpty)} />
                </Fragment>
              ))}
          </>
        )}
      </div>
    </div>
  );
});
