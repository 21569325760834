import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { Dnd } from '../../types';

import { DraggableGhostShadow } from './draggable-ghost-shadow';

export class DraggableGhost<TParentGhost, TShadow = unknown, TViewItem = unknown> {
  readonly parent: Dnd.Draggable<TParentGhost, TShadow>;
  readonly ghost: TParentGhost;
  readonly uniqueCreationKey: string;
  readonly activeKey: string;

  @observable shadow: DraggableGhostShadow<TParentGhost, TShadow, TViewItem> | null = null;

  constructor(parent: Dnd.Draggable<TParentGhost, TShadow>, ghost: TParentGhost, activeDraggableKey: string) {
    this.parent = parent;
    this.ghost = ghost;
    this.uniqueCreationKey = uuidv4();
    this.activeKey = activeDraggableKey;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-ghost-${this.uniqueCreationKey}`;
    }

    return `ghost-${this.uniqueCreationKey}`;
  }

  @action.bound
  addShadow(shadow: TShadow, getViewItem?: DraggableGhostShadow.GetViewItemsFn<TViewItem>): void {
    this.shadow = new DraggableGhostShadow<TParentGhost, TShadow, TViewItem>(shadow, this, getViewItem);
  }

  @action.bound
  removeShadow(): void {
    this.shadow = null;
  }
}
