import { Collapse } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { ConflictWell } from 'src/api/draft/conflict-well';
import { IWell } from 'src/api/draft/types';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';

import { AddWellButton } from '../../add-well-button';
import { Pad as PadStore } from '../../store/pad';
import { RigConflictsSidebarViewProvider } from '../../store/rig-conflicts-sidebar-view-provider';
import { Well as WellElement } from '../well';

import { PadCollapseHeader } from './pad-collapse-header';
import styles from './pad.module.scss';

interface Props {
  store: PadStore;
  viewProvider: RigConflictsSidebarViewProvider;
  placeWell(padId: number, index: number): void;
  isExpanded?: boolean;
  isCollapsed?: boolean;
}

export const Pad = observer(function PadCollapse({ store, isCollapsed, isExpanded, viewProvider, placeWell }: Props) {
  const [activeKey, setActiveKey] = useState<string[] | string>([store.id.toString()]);

  const attributeNames = getPadAttributeIcons(store.item);

  function handleChange(event: string[] | string) {
    setActiveKey(event);
  }

  function getCollapsedStatus(): string[] | string {
    if (isCollapsed) {
      return [];
    }

    if (isExpanded) {
      return [store.id.toString()];
    }

    return activeKey;
  }

  function onWellPlace(index: number): void {
    placeWell(store.id, index);
  }

  function isAddButtonShown(well: IWell, index: number): boolean {
    if (well instanceof ConflictWell) {
      return true;
    }

    const isTopWell = index === 0;
    const previousWell = store.items.at(index - 1);

    if (!isTopWell && previousWell instanceof ConflictWell) {
      return true;
    }

    return false;
  }

  return (
    <div className={clsx(styles.container, styles.containerEditing)}>
      <Collapse
        destroyInactivePanel
        onChange={handleChange}
        activeKey={getCollapsedStatus()}
        expandIcon={({ isActive }) => (
          <DropdownIcon
            className={clsx(styles.expandIcon, isActive && styles.expandIconActive, styles.expandIconDisabled)}
          />
        )}
      >
        <Collapse.Panel
          header={<PadCollapseHeader title={viewProvider.getPadTitle(store)} attributeIcons={attributeNames} />}
          key={store.id}
        >
          {store.items.map((well, index) => (
            <React.Fragment key={well.id}>
              {!isAddButtonShown(well, index) && <AddWellButton onClick={() => onWellPlace(index)} />}

              <WellElement well={well} viewProvider={viewProvider} />
            </React.Fragment>
          ))}

          {!(store.items.at(-1) instanceof ConflictWell) && (
            <AddWellButton onClick={() => onWellPlace(store.items.length)} />
          )}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
