import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { InputError } from '../input-error';

import styles from './new-well-label.module.scss';

type Props = {
  text: string;
  children?: ReactNode;
  className?: string;
  errorText?: string | [string, { [locKey: string]: string | number }];
};

export const NewWellLabel = observer(function NewWellLabel({ className, children, text, errorText }: Props) {
  return (
    <div className={clsx(className)}>
      <p className={styles.text}>{text}</p>
      {children}
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
