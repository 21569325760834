import { agent } from '../agent';
import { throwApiError } from '../utils';

export async function exportPlanVersion(
  format: 'xlsx',
  planVersionId: number,
  planVersionName: string,
  signal: AbortSignal
): Promise<File> {
  try {
    const { data } = await agent.get(`/export/${planVersionId}`, {
      params: {
        format,
      },
      responseType: 'blob',
      signal,
    });

    const rawFile = new File([data], planVersionName, { type: format });

    return rawFile;
  } catch (e) {
    throwApiError(e);
  }
}
