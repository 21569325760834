import { useContext, useEffect } from 'react';

import { DraggableTransform } from '../../shared/entities/draggable-transform';
import { DndContext } from '../context/dnd-context';

export function useDraggableGhost({ id }: UseDraggableGhost.Args): UseDraggableGhost.Return {
  const { active, containerRect, isDraggingPositionAvailable, draggableContainerElement } = useContext(DndContext);

  useEffect(() => {
    if (draggableContainerElement) {
      draggableContainerElement.style.display = 'block';

      return () => {
        draggableContainerElement.style.display = 'none';
      };
    }
  }, [draggableContainerElement]);

  if (!active || !containerRect || id !== active.id || !draggableContainerElement) {
    return {};
  }

  return {
    transform: active.transform,
    isPositionAvailable: isDraggingPositionAvailable ?? true,
    draggableContainerElement,
  };
}

export namespace UseDraggableGhost {
  export type Args = {
    /** ID of element that handles pointer events (parent). Required to get parent transform object. */
    id: string;
  };

  export type Return = {
    draggableContainerElement?: HTMLElement;
    transform?: DraggableTransform;
    isPositionAvailable?: boolean;
  };
}
