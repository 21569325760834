import { ILabelResolver } from './types';

export class QuarterResolver implements ILabelResolver {
  tryGetLabelByKey(key: string): string | null {
    const quarterNumber = Number(key.split('Q').at(-1));

    if (Number.isNaN(quarterNumber)) {
      return null;
    }

    const year = key.slice(2, 4);

    return `${quarterNumber} кв \`${year}`;
  }
}
