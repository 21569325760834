import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { PadAttributeIcon } from '../../../../pad-attribute-icon';
import { PadCard } from '../../../../pad-card';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useDraggableGhostShadow } from '../../editing/view/hooks/use-draggable-ghost-shadow';
import { ShadowPadRigOperation } from '../presenter/shadow-pad-rig-operation';

import styles from './data-items-full.module.scss';

interface Props {
  item: ShadowPadRigOperation;
  verticalViewport: Viewport;
  horizontalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const ShadowPadRigOperationWrapper = observer(function ShadowPadRigOperationWrapper({
  item,
  verticalViewport,
  horizontalViewport,
  viewSettingsProvider,
}: Props) {
  const { transform, getRelativeCoordinates } = useDraggableGhostShadow({
    parentId: item.parentKey,
  });

  const ref = useMovableElement({
    item,
    transform,
    verticalViewport,
    horizontalViewport,
    options: {
      marginLeft: !transform,
      fixY: true,
    },
    getRelativeTransform: getRelativeCoordinates,
  });

  const padAttributes = viewSettingsProvider.getPadAttributes(item);

  const padIcons = padAttributes?.length && (
    <div className={styles.iconsContainer}>
      {padAttributes?.map((attributeName) => (
        <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
      ))}
    </div>
  );

  return (
    <>
      <div ref={ref} className={clsx(styles.dataItem, styles.draggablePadShadow)}>
        <PadCard
          title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
          extra={padIcons}
          className={styles.dataItem}
        />
      </div>
    </>
  );
});
