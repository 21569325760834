import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Radio } from '../radio';

import styles from './radio-group.module.scss';

interface Props<TItemValue> {
  items: RadioGroup.Item<TItemValue>[];
  value?: TItemValue;
  className?: string;
  onChange?(value: TItemValue): void;
}

export const RadioGroup = observer(function RadioGroup<TItem>({ items, value, className, onChange }: Props<TItem>) {
  const [selectedItem, setSelectedItem] = useState<TItem>(value ?? null!);

  const handleChange = (item: TItem) => {
    setSelectedItem(item);
    onChange?.(item);
  };

  return (
    <div className={className}>
      {items.map((item) => (
        <div key={item.key} className={styles.row}>
          <Radio id={item.key} isSelected={item.value === selectedItem} onChange={() => handleChange(item.value)} />

          {!!item?.label && (
            <label htmlFor={item.key} className={styles.label}>
              {item.label}
            </label>
          )}
        </div>
      ))}
    </div>
  );
});

export namespace RadioGroup {
  export type Item<TItemValue> = {
    key: string;
    value: TItemValue;
    label?: string;
  };
}
