import { autorun } from 'mobx';

import { ApproachesTab } from '../entities/approach-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class DisableApproachesTabPlugin extends FormPlugin {
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    form.tabs.forEach((tab) => {
      if (tab instanceof ApproachesTab) {
        const disposer = autorun(() => {
          const isDisabled = !form.validationManager.isAllNecessaryMainFieldsAreFilled;
          tab.setIsDisabled(isDisabled);
          tab.setTooltipText(
            isDisabled ? this.rootStore?.i18.t('newWellForm:Tooltips.approachesTabDisablingReason') : undefined
          );
        });

        disposers.push(disposer);
      }
    });

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
