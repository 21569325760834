import { useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { HeaderType } from '../../import-matching-page.store';

import styles from './invalid-item.module.scss';

type Props = {
  header: HeaderType;
};

export const InvalidItem = observer(function InvalidItem({ header }: Props) {
  const { active, listeners, attributes, setNodeRef } = useDraggable({
    id: header.columnIndex + 1,
    data: {
      header,
    },
  });

  return (
    <>
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        className={clsx(styles.wrapper, active?.id === header.columnIndex && styles.wrapper__active)}
      >
        <p className={styles.text}>{header.title}</p>
      </div>
    </>
  );
});
