import { AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';
import { hasValue } from 'src/shared/utils/common';
import { Directories } from 'src/store/directories/directories.store';

import { isValueStringOrNumber } from '../../shared/is-valid-text-value';

import { EmptyRig } from './entities/empty-rig';

export function mapRigs(
  view: AddRigSidebar.View,
  rigs: AddRigSidebar.RigObject[],
  directories: Directories
): EmptyRig[] {
  return rigs.map((rigObject) => {
    const rig = { ...rigObject.data, id: rigObject.id };
    const rigTitle = getRigTitle(rig, view, directories);

    return new EmptyRig(rig, rigTitle);
  });
}

export function getRigTitle(
  rigData: Record<string, unknown>,
  view: AddRigSidebar.View,
  directoriesStore: Directories
): string {
  const attrsSource = view.rig;

  if (attrsSource.attrConcat && attrsSource.delimiter) {
    const values = attrsSource.attrConcat.reduce((values: string[], attr) => {
      // Обрабатывает строки типа {Common_Rig.name || ${Common_Rig.modelId}}
      if (attr.includes('||')) {
        const attrs = attr.split('||').map((attr) => attr.trim());

        for (const attr of attrs) {
          if (attr.includes('$')) {
            const cleanAttr = attr.replaceAll(/[${}]/g, '');

            const refObjectType = attrsSource.attrConcatRefs?.[cleanAttr]?.refObjectType;
            const refObjectAttr = attrsSource.attrConcatRefs?.[cleanAttr]?.refObjectAttr;
            const directoryId = rigData[cleanAttr];

            if (!refObjectType || !refObjectAttr || !directoryId) {
              continue;
            }

            const directories = directoriesStore.getObject(refObjectType);

            if (!directories) continue;

            const directory = directories.find((dir) => dir.id.toString() === directoryId?.toString());

            if (!directory) continue;

            const dirValue = directory.data[refObjectAttr];

            if (hasValue(dirValue) && dirValue !== '' && dirValue !== 'null') {
              values.push(dirValue.toString());
              break;
            }
          }

          const value = rigData[attr];

          if (hasValue(value) && value !== '' && isValueStringOrNumber(value)) {
            values.push(value.toString());
            break;
          }
        }

        return values;
      }

      const value = rigData[attr];

      if (hasValue(value) && isValueStringOrNumber(value)) {
        values.push(value.toString());
      }

      return values;
    }, []);

    return values.join(`${attrsSource.delimiter}` ?? ' ');
  }

  return '';
}
