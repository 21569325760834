import { action, autorun, makeObservable } from 'mobx';

import { RootStore } from 'src/store';

import { ValidatableItem } from '../../../shared/entities/abstract-control-entities';
import { ApproachStageSection } from '../entities/approach-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class ExpandSectionsFunctionalityPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound
  connect(form: FormStore): VoidFunction {
    let disposersFromPrevStep: VoidFunction[] = [];
    const requiredIf =
      form.approachesTab?.approachesList.approachReference.stageTemplate.stage.sectionTemplate.requiredIf;

    const disposer = autorun(() => {
      if (requiredIf) {
        requiredIf.forEach((condition) => {
          const control = form.fields[condition.control];
          form.approachesTab?.approachesList.approaches.forEach((approach) => {
            approach.stagesList.stages.forEach((stage) =>
              stage.sectionsList.setIsRequired(control.value === condition.value)
            );
          });
        });
      }
      const sections = form.sections;
      disposersFromPrevStep.forEach((disposerFromPrevStep) => disposerFromPrevStep());
      disposersFromPrevStep = sections.map((section) => this.initializeSection(section, form));
    });

    return disposer;
  }

  private initializeSection(section: ApproachStageSection, form: FormStore): () => void {
    let disposersFromPrevStep: VoidFunction[] = [];
    const disposer = autorun(() => {
      disposersFromPrevStep.forEach((disposerFromPrevStep) => disposerFromPrevStep());
      disposersFromPrevStep = [];

      section.fieldsList.forEach((field) => {
        if (field instanceof ValidatableItem && field.requiredIf) {
          const trackControlRequirednessChangingDisposer = autorun(() => {
            if (field.requiredIf) {
              for (const requiredIf of field.requiredIf) {
                const control = form.fields[requiredIf.control];
                field.setIsRequired(control.value === requiredIf.value);
              }
            }
          });

          disposersFromPrevStep.push(trackControlRequirednessChangingDisposer);
        }
      });
    });

    return disposer;
  }
}
