import { action, computed, makeObservable, observable } from 'mobx';

import { LoadingRigSummary } from '../../../presets/summary-data-sidebar/entities';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';

import { ISummaryDataStorage } from './summary-data.types';

export class SummaryDataStorage implements ISummaryDataStorage {
  /*
   * Format:
   *
   * {
   *   year: {
   *     rigId: SummaryRow | null
   *   }
   * }
   * */
  @observable private _data: Map<number, Map<number, IRigSummary | null>> = observable.map();
  @observable private emptyRows: Map<number, LoadingRigSummary> = observable.map();
  @observable private parentIds: number[] = [];
  @observable private selectedYear: number;

  constructor(initialSelectedYear: number) {
    this.selectedYear = initialSelectedYear;

    makeObservable(this);
  }

  @action.bound
  setParentIdsList(parentIdsList: number[]): void {
    this.parentIds = parentIdsList;
  }

  @action.bound
  setYear(year: number): void {
    this.selectedYear = year;
  }

  @action.bound
  setData(year: number, data: Map<number, IRigSummary>): void {
    const currentYearData = this._data.get(year);

    if (currentYearData) {
      currentYearData.merge(data);
    } else {
      this._data.set(year, data);
    }
  }

  @computed
  get data(): { rows: (IRigSummary | null)[]; emptyRows: number[] } {
    const summaryRows: (IRigSummary | null)[] = [];
    const emptyParentsIds: number[] = [];

    const dataByYear = this._data.get(this.selectedYear);

    for (const parentId of this.parentIds) {
      const rowData = dataByYear?.get(parentId);

      if (rowData !== undefined) {
        summaryRows.push(rowData);
      } else {
        summaryRows.push(new LoadingRigSummary(parentId));

        if (!this.emptyRows.has(parentId)) {
          emptyParentsIds.push(parentId);
        }
      }
    }

    return { rows: summaryRows, emptyRows: emptyParentsIds };
  }

  @action.bound
  clear(): void {
    this._data.clear();
    this.emptyRows.clear();
    this.parentIds = [];
  }
}
