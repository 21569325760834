import { TRefQuery } from 'src/store/directories/types';

export enum PAGE_MODE {
  view = 'view',
  edit = 'edit',
  compare = 'compare',
}

export enum WELLS_TYPE {
  drilled = 'DRILLED',
  planned = 'PLANNED',
  unplanned = 'UNPLANNED',
}

export enum SORTING_DIRECTION {
  asc = 'ASC',
  desc = 'DESC',
}

export type UserSettingsColumnType = {
  id: string;
  isFixed: boolean;
  isShown: boolean;
  width?: number;
};

export type UserSettingsColumnsGroupType = {
  id: string;
  innerColumns: UserSettingsColumnType[];
  isShown: boolean;
  isWrapped: boolean;
  isFixed?: boolean;
  width?: number;
};

export type SortingOptionType = {
  fieldId: string;
  attrName: string;
  direction: SORTING_DIRECTION;
};

export type SortingObjectType = {
  attrName: string;
  direction: SORTING_DIRECTION;
  refObjectAttr?: string;
  refObjectType?: string;
  refQuery?: TRefQuery;
  cast?: string;
};

export type FilterType = {
  fieldId: string;
  attrName: string;
  valueArray?: Array<string | number>;
  valueMin?: string | number;
  valueMax?: string | number;
};

export type FilterObjectType = {
  attrName: string;
  valueArray?: Array<string | number>;
  valueMin?: string | number;
  valueMax?: string | number;
  refObjectAttr?: string;
  refObjectType?: string;
  refQuery?: TRefQuery;
};
