import { action, computed, makeObservable, observable } from 'mobx';

import { hasValue } from 'src/shared/utils/common';

import { Dnd } from '../editing/types';

export class SortableTransform {
  @observable private y: number;

  constructor(y: number) {
    this.y = y;

    makeObservable(this);
  }

  @computed
  get value(): Dnd.Transform {
    return { y: this.y, x: 0 };
  }

  @action.bound
  setValue(value: Partial<Dnd.YTransform>): void {
    if (hasValue(value.y)) {
      this.y = value.y;
    }
  }

  @action.bound
  add(value: Partial<Dnd.YTransform>): void {
    if (hasValue(value.y)) {
      this.y += value.y;
    }
  }
}
