import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { SortingOptionType, SORTING_DIRECTION } from 'src/pages/wells-page/types';
import { ReactComponent as HideIcon } from 'src/shared/assets/icons/closed-eye.svg';
import { ReactComponent as PinIcon } from 'src/shared/assets/icons/pin.svg';
import { ReactComponent as SortDownIcon } from 'src/shared/assets/icons/sort-down.svg';
import { ReactComponent as SortUpIcon } from 'src/shared/assets/icons/sort-up.svg';
import { Button } from 'src/shared/components/button';

import styles from './complex-tooltip.module.scss';

interface Props {
  fieldId: string;
  sorting: SortingOptionType | null;
  isFixed: boolean;
  attrName?: string;
  onShownColumnsChange?(): void;
  onFixedColumnsChange?(): void;
  onSortInAscendingOrder?(): void;
  onSortInDescendingOrder?(): void;
  onResetSorting?(): void;
}

export const ComplexTooltip = observer(function ComplexTooltip({
  fieldId,
  onFixedColumnsChange,
  onShownColumnsChange,
  onSortInAscendingOrder,
  onSortInDescendingOrder,
  onResetSorting,
  sorting,
  children,
  attrName,
  isFixed,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.topButtonsContainer}>
        {onShownColumnsChange && (
          <button className={styles.iconButton} onClick={onShownColumnsChange}>
            <HideIcon className={styles.icon} />
          </button>
        )}

        {onFixedColumnsChange && (
          <button
            className={clsx(styles.iconButton, isFixed && styles.iconButtonActive)}
            onClick={onFixedColumnsChange}
          >
            <PinIcon className={styles.icon} />
          </button>
        )}

        {onSortInDescendingOrder && (
          <button
            onClick={onSortInDescendingOrder}
            className={clsx(
              styles.iconButton,
              sorting?.direction === SORTING_DIRECTION.desc &&
                sorting.fieldId === fieldId &&
                sorting.attrName === attrName &&
                styles.iconButtonActive
            )}
          >
            <SortDownIcon className={styles.icon} />
          </button>
        )}

        {onSortInAscendingOrder && (
          <button
            className={clsx(
              styles.iconButton,
              sorting?.direction === SORTING_DIRECTION.asc &&
                sorting.fieldId === fieldId &&
                sorting.attrName === attrName &&
                styles.iconButtonActive
            )}
            onClick={onSortInAscendingOrder}
          >
            <SortUpIcon className={styles.icon} />
          </button>
        )}

        {sorting?.fieldId === fieldId && (
          <Button variant="text" onClick={onResetSorting} className={styles.resetButton} disabled={!onResetSorting}>
            {t('common:Buttons.resetSorting')}
          </Button>
        )}
      </div>

      {children}
    </>
  );
});
