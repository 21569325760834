import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { Switcher } from 'src/shared/components/switcher/switcher';

import { PLAN_VERSION_GROUP } from '../dashboard/dashboard.consts';
import { DashboardStore } from '../dashboard/dashboard.store';
import { ChartType } from '../dashboard/types';

import { chartTypeItems, defaultChartTypeItems } from './dashboard-header.consts';

import styles from './dashboard-header.module.scss';

interface Props {
  displayChartType: ChartType;
  grouping: DashboardStore.GroupingValueWithLabel[];
  selectedGroup: string;
  onChartTypeChange(type: ChartType): void;
  onGroupingChange(key: string): void;
  onAccumulateChange(): void;
  isAccumulate: boolean;
}

export const DashboardHeader = observer(function DashboardHeader({
  displayChartType,
  grouping,
  selectedGroup,
  isAccumulate,
  onAccumulateChange,
  onChartTypeChange,
  onGroupingChange,
}: Props) {
  const { t } = useTranslation('dashboard');

  const isPlanVersion = selectedGroup === PLAN_VERSION_GROUP;

  return (
    <div className={styles.container}>
      <SwitchButtonGroup
        items={grouping.map((item) => ({
          key: item.value,
          title: item.label,
        }))}
        defaultOptionKey={selectedGroup}
        className={styles.switchContainer}
        buttonClassName={styles.switchButton}
        onChange={(key) => onGroupingChange(key)}
      />

      <div className={styles.controlsWrapper}>
        {isPlanVersion && (
          <label className={styles.accumulateSwitcher}>
            <span>{t('accumulatively')}</span>
            <Switcher
              className={clsx(styles.switcher, isAccumulate && styles.switcherActive)}
              isActive={isAccumulate}
              onToggle={onAccumulateChange}
            />
          </label>
        )}

        <SwitchButtonGroup
          items={isPlanVersion ? defaultChartTypeItems : chartTypeItems}
          defaultOptionKey={displayChartType}
          value={displayChartType}
          className={styles.switchContainer}
          buttonClassName={styles.switchButton}
          onChange={(key) => onChartTypeChange(key)}
        />
      </div>
    </div>
  );
});
