import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import styles from './descriptions.module.scss';

interface Props {
  label: ReactNode;
  level?: 0 | 1 | 2;
  direction?: 'horizontal' | 'vertical';
  children?: ReactNode;
  className?: string;
}

export const Description = observer(function Description({
  label,
  level = 0,
  direction = 'horizontal',
  children,
  className,
}: Props) {
  return (
    <div className={clsx(className, styles.description, direction === 'vertical' && styles.descriptionVertical)}>
      <p className={styles.descriptionLabel} style={{ '--descriptions-label-level': level }}>
        {label}
      </p>
      <p className={styles.descriptionValue}>{children}</p>
    </div>
  );
});
