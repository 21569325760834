import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { IndicatorSettings, IndicatorsSettings } from './entities';
import { IndicatorsViewSettings } from './indicators-view-settings/indicators-view-settings';

import styles from './indicators-view-settings-sidebar.module.scss';

interface Props {
  settings: IndicatorsSettings | null;
  isOpen: boolean;
  isLoading: boolean;
  onClose(): void;
  onApply(indicators: IndicatorSettings[]): void;
}

export const IndicatorsViewSettingsSidebar = observer(function IndicatorsViewSettingsSidebar({
  settings,
  isOpen,
  onClose,
  onApply,
  isLoading,
}: Props) {
  const { t } = useTranslation();

  return (
    <Sidebar isOpened={isOpen} onClose={onClose} title={t('displaySettings')} className={styles.container}>
      {isLoading && <Spin size="large" className={styles.loader} />}

      {!!settings && <IndicatorsViewSettings settings={settings} isLoading={isLoading} onViewSettingsApply={onApply} />}
    </Sidebar>
  );
});
