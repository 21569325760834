import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ChangeEventHandler, ReactNode, useRef, useState } from 'react';

import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross-mini.svg';
import { ReactComponent as SearchIcon } from 'src/shared/assets/icons/magnifier.svg';

import { IconButton } from '../icon-button';

import styles from './search.module.scss';

interface Props {
  onSearch(searchTerm: string): void;
  value?: string;
  prefix?: ReactNode | null;
  suffix?: ReactNode;
  placeholder?: string;
  className?: string;
}

export const Search = observer(function Search({
  onSearch,
  value,
  prefix = <SearchIcon className={styles.icon} />,
  suffix,
  placeholder,
  className,
}: Props) {
  const [_value, setValue] = useState(value ?? '');
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null!);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchTerm = e.currentTarget.value;

    onSearch(searchTerm);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.currentTarget.value);
  };

  const handleClearClick = () => {
    setValue('');
    onSearch('');
  };

  const isEmptyValue = !_value.length;

  return (
    <div className={clsx(className, styles.container, isFocused && styles.containerFocused)}>
      {prefix && <div className={styles.prefix}>{prefix}</div>}

      <div className={styles.inputWrapper}>
        <input
          ref={inputRef}
          className={styles.input}
          value={value}
          onInput={handleSearch}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        {isEmptyValue && (
          <div className={styles.placeholderWrapper}>
            <p className={styles.placeholder}>{placeholder}</p>
          </div>
        )}
      </div>

      <div className={styles.suffix}>
        {suffix}

        {!isEmptyValue && (
          <IconButton
            icon={<CrossIcon className={styles.icon} />}
            className={styles.clearButton}
            onClick={handleClearClick}
          />
        )}
      </div>
    </div>
  );
});
