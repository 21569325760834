import { PassingIntervalField } from 'src/shared/entities/control-entities';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { Directories } from 'src/store/directories/directories.store';

import { TPassingIntervalRaw, TSavedFormValue } from '../../types';

type TMapPassingIntervalFieldItemOptions = {
  item: TPassingIntervalRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapPassingIntervalFieldItem = ({
  item,
  directories,
  savedValue,
}: TMapPassingIntervalFieldItemOptions): PassingIntervalField => {
  const savedValues: { [key: string]: number | null } = {};

  if (isObjectWithKeys<number>(savedValue)) {
    for (let key in savedValue) {
      const value = savedValue[key];
      if (typeof value === 'number') {
        savedValues[key] = value;
        continue;
      }
    }
  }

  const initialMin = savedValues[item.attrNames.passingValueMin] ?? null;
  const initialMax = savedValues[item.attrNames.passingValueMax] ?? null;
  const initialPassingValue = savedValues[item.attrNames.passingValue] ?? null;

  const data = {
    fieldId: item.fieldId,
    validate: item.validate,
    validationTags: item.validationTags,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    placeholderMin: directories.getFieldPlaceholder('passingValueMin') || '',
    placeholderMax: directories.getFieldPlaceholder('passingValueMax') || '',
    placeholderTotal: directories.getFieldPlaceholder('passingValue') || '',
    labels: {
      passingValue: directories.getFieldLabel('passingValue') || '',
      passingValueInterval: directories.getFieldLabel('passingValueMin') || '',
    },
    attrNames: item.attrNames,
    restrictions: { required: !!item.required },
    requiredIf: item.requiredIf,
    calculatedValue: item.calculatedValue,
    initialMin,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    initialMax,
    initialPassingValue,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    unit: directories.getFieldUnit('passingValue') || '',
  };

  return new PassingIntervalField(data);
};
