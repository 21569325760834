export const throttleFn = <TArgs extends any[], TResult>(fn: (...args: TArgs) => TResult, ms: number) => {
  let lastArgs: TArgs | null = null;
  let timer: number | undefined;

  const call = () => {
    if (lastArgs) {
      fn(...lastArgs);
      lastArgs = null;
    }
  };

  return (...args: TArgs): void => {
    const shouldWait = !!lastArgs;

    lastArgs = args;

    if (!shouldWait) {
      window.clearTimeout(timer);

      timer = window.setTimeout(call, ms);
    }

    return;
  };
};
