import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { UnplannedRigOperationsSidebar } from 'src/features/unplanned-rig-operations-sidebar';
import { WellInfoSidebar } from 'src/features/well-info-sidebar';
import { WellInfoSidebar as WellInfoSidebarStore } from 'src/features/well-info-sidebar/well-info-sidebar.store';
import { Loader } from 'src/shared/components/loader';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import { DataContainer } from '../../components/data-container/data-container';
import { TimelineChart } from '../../components/timeline-chart/timeline-chart';
import { ChartCollapseButton } from '../../features/chart-collapse-button';
import { ChartSearch } from '../../features/chart-search';
import { WellsDataHeaders } from '../../features/data-headers/view/wells-data-headers';
import { WellsDataItemsBackground } from '../../features/data-items-background/view';
import { WellsDataItemsEmpty } from '../../features/data-items-empty/view/wells-data-items-empty';
import { WellsDataItemsFull } from '../../features/data-items-full/view/wells-data-items-full';
import { FiltersModal } from '../../features/filters-modal';
import { FullScreenButtons } from '../../features/full-screen-buttons';
import { HeaderPresenter } from '../../features/header/presenter/header-presenter';
import { Header } from '../../features/header/view/header';
import { IndicatorsTable } from '../../features/indicators-table/view/indicators-table';
import { DataView } from '../../shared/data-view/data-view';
import { WellsChartSidebarsStore } from '../../shared/wells-chart-sidebars.store';
import { IndicatorsSettings } from '../indicators-view-settings-sidebar/entities';

import { WellsChartStore } from './wells-chart.store';

import styles from './drilling-wells-chart.module.scss';

interface Props {
  store: WellsChartStore;
  openIndicatorsViewSettings: VoidFunction;
  headerPresenter: HeaderPresenter;
  dataView: DataView;
  isEditing: boolean;
  onWellEdit(wellId: number | string): void;
  onWellAdd: VoidFunction;
  containerWidth?: number;
  containerHeight?: number;
  indicatorsSettings?: IndicatorsSettings;
  isFullScreen?: boolean;
  onFullScreenExit?: VoidFunction;
}

export const DrillingWellsChart = observer(function DrillingWellsChart({
  store,
  containerWidth,
  containerHeight,
  openIndicatorsViewSettings,
  headerPresenter,
  dataView,
  isEditing,
  isFullScreen,
  onFullScreenExit,
  indicatorsSettings,
  onWellEdit,
  onWellAdd,
}: Props) {
  const {
    dataHeadersPresenter,
    dataItemsBackgroundPresenter,
    dataItemsFullPresenter,
    dataItemsEmptyPresenter,
    horizontalViewport,
    verticalViewport,
    isLoading,
    isDataUpdating,
    onWellDelete,
    filtersForm,
    viewSettings,
    hasSelectedFilters,
    indicators,
  } = store;

  const [sidebars] = useState(() => new WellsChartSidebarsStore());
  const { notifications } = useStore();

  const isCompactView =
    dataView.type === DataView.DataViewType.compact || dataView.type === DataView.DataViewType.empty;

  const onWellStageClick = useCallback(
    (wellId: number) => sidebars.onWellInfoOpen(wellId, WellInfoSidebarStore.Tabs.stages),
    [sidebars]
  );

  if (isLoading) {
    return <Loader className={styles.loader} />;
  }

  return (
    <div className={styles.chartWrapper}>
      {store.isDataLoading && (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} />
        </div>
      )}

      <TimelineChart className={styles.chart}>
        <DataContainer
          className={styles.chartDataContainer}
          horizontalViewport={horizontalViewport}
          verticalViewport={verticalViewport}
          onPointerDown={dataItemsBackgroundPresenter.onPointerDown}
          onPointerMove={dataItemsBackgroundPresenter.onPointerMove}
          onPointerUp={dataItemsBackgroundPresenter.onPointerUp}
          onScroll={dataItemsBackgroundPresenter.onScroll}
          onZoom={dataItemsBackgroundPresenter.onZoom}
          onZoomOut={dataItemsBackgroundPresenter.onZoomOut}
        >
          {!!dataItemsBackgroundPresenter.data && (
            <WellsDataItemsBackground
              data={dataItemsBackgroundPresenter.data}
              verticalViewport={verticalViewport}
              isEditing={isEditing}
              isLoading={isDataUpdating}
              onWellAdd={sidebars.onWellAdd}
            />
          )}

          {dataView.type === DataView.DataViewType.full && !!dataItemsFullPresenter.data && (
            <WellsDataItemsFull
              data={dataItemsFullPresenter.data}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewProvider={viewSettings.provider}
              onWellStageClick={onWellStageClick}
            />
          )}

          {isCompactView && !!dataItemsEmptyPresenter.data && (
            <WellsDataItemsEmpty
              data={dataItemsEmptyPresenter.data}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewProvider={viewSettings.provider}
              onWellStageClick={onWellStageClick}
            />
          )}
        </DataContainer>

        {!!dataHeadersPresenter.data && (
          <WellsDataHeaders
            data={dataHeadersPresenter.data}
            viewport={verticalViewport}
            viewProvider={viewSettings.provider}
            className={styles.chartDataHeaders}
            isCompactView={isCompactView}
            isEditing={isEditing}
            isLoading={isDataUpdating}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            onScroll={dataHeadersPresenter.onScroll}
            onHeightChange={dataHeadersPresenter.onHeightChange}
            onIsCollapseToggle={dataHeadersPresenter.onGroupCollapseToggle}
            onWellDelete={onWellDelete}
          />
        )}

        <ChartCollapseButton
          isCollapsed={store.isGroupsCollapsed}
          toggleIsCollapsed={store.toggleGroupsIsCollapsed}
          className={styles.collapseToggle}
        />

        <ChartSearch
          className={styles.chartSearch}
          value={store.searchTerm}
          onChange={store.onSearchChange}
          onFiltersButtonClick={
            filtersForm?.onOpen || (() => notifications.showErrorMessageT('errors:failedToLoadFilters'))
          }
          highlightFiltersButton={hasSelectedFilters}
        />

        <Header
          data={headerPresenter.data}
          viewport={horizontalViewport}
          className={styles.chartHeader}
          onPointerDown={headerPresenter.onPointerDown}
          onPointerMove={headerPresenter.onPointerMove}
          onPointerUp={headerPresenter.onPointerUp}
          onZoom={headerPresenter.onZoom}
          onZoomOut={headerPresenter.onZoomOut}
        />

        {!!indicatorsSettings && (
          <IndicatorsTable
            data={indicators.presenter.data}
            settings={indicatorsSettings}
            viewport={horizontalViewport}
            className={styles.chartIndicators}
            onPointerDown={indicators.presenter.onPointerDown}
            onPointerMove={indicators.presenter.onPointerMove}
            onPointerUp={indicators.presenter.onPointerUp}
            onZoom={indicators.presenter.onZoom}
            openViewSettings={openIndicatorsViewSettings}
          />
        )}

        {isFullScreen && onFullScreenExit && (
          <div className={styles.chartFullScreenButtons}>
            <FullScreenButtons onFullScreenExit={onFullScreenExit} />
          </div>
        )}
      </TimelineChart>

      {!!filtersForm && <FiltersModal formStore={filtersForm} />}

      {hasValue(sidebars.selectedWellId) && (
        <WellInfoSidebar
          key={sidebars.selectedWellId}
          wellId={sidebars.selectedWellId}
          isOpen={sidebars.wellInfo.isOpen}
          onClose={sidebars.onWellInfoClose}
          onEdit={onWellEdit}
          initialActiveTab={sidebars.initialWellSidebarTab}
        />
      )}

      <UnplannedRigOperationsSidebar
        isOpened={sidebars.addWell.isOpen}
        onClose={sidebars.onAddWellSidebarClose}
        onEditWell={onWellEdit}
        onNewWellClick={onWellAdd}
        onWellClick={sidebars.onWellInfoOpen}
      />
    </div>
  );
});
