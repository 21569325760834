import { ComparingWellsChart } from 'src/api/chart/comparing-wells-data-api';

import { ChartWell, WellRigOperation, WellRigOperationStage } from '../../drilling-wells-chart/entities';

export class ComparingWellRigOperationStage extends WellRigOperationStage {
  protected readonly item: ComparingWellsChart.RawRigOperationStage;

  constructor(
    rigOperationStage: ComparingWellsChart.RawRigOperationStage,
    id: number,
    uniqueCreationKey: string,
    parentRigOperation: WellRigOperation,
    parentWellId?: number,
    parentWell?: ChartWell
  ) {
    super(rigOperationStage, id, uniqueCreationKey, parentRigOperation, parentWellId, parentWell);

    this.item = rigOperationStage;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-comparing-rig-operation-stage-${this.uniqueCreationKey}`;
    }

    return `comparing-rig-operation-stage-${this.uniqueCreationKey}`;
  }

  get hasChanges(): boolean {
    return this.item.hasChanges || false;
  }
}
