import { Spin } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as UploadBgImg } from 'src/shared/assets/img/uploadAreaBg.svg';
import { Button } from 'src/shared/components/button/button';
import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';
import { checkIsNode } from 'src/shared/utils/check-is-node';
import { useStore } from 'src/store';

import { ALLOWED_FILE_TYPES } from './constants';

import styles from './drag-area.module.scss';

type Props = {
  isLoading?: boolean;
  onFile: (file: File) => void;
};

export const DragArea = observer(function DragArea({ isLoading = false, onFile }: Props) {
  const { t } = useTranslation();
  const dragContainerRef = useRef<HTMLDivElement>(null);
  const [isOverArea, setIsOverArea] = useState<boolean>(false);
  const [isWrongExtension, setIsWrongExtension] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { notifications } = useStore();

  function isValidFileExtension(fileExtension?: string): boolean {
    if (!fileExtension) return false;
    return ALLOWED_FILE_TYPES.includes(fileExtension);
  }

  function handleChoseFile(event: ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files?.[0];

    if (!isValidFileExtension(file?.type)) {
      notifications.showErrorMessageT('drillingCarpet:UploadPlanModal.wrongFileExt');
      return;
    }

    if (file) onFile(file);
  }

  useEffect(() => {
    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      if (!checkIsNode(event.target) || !dragContainerRef.current?.contains(event.target)) return;
      const file = event.dataTransfer?.files[0];
      if (!isValidFileExtension(file?.type)) {
        notifications.showErrorMessageT('drillingCarpet:UploadPlanModal.wrongFileExt');
        setIsWrongExtension(false);
        setIsOverArea(false);
        return;
      }
      if (file) onFile(file);
      setIsOverArea(false);
      setIsWrongExtension(false);
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      if (checkIsNode(event.target) && dragContainerRef.current?.contains(event.target)) {
        setIsOverArea(true);
        if (!isValidFileExtension(event.dataTransfer?.items[0].type)) {
          setIsWrongExtension(true);
        } else setIsWrongExtension(false);
        return;
      }

      setIsWrongExtension(false);
      setIsOverArea(false);
    };

    document.body.addEventListener('dragover', handleDragOver);
    document.body.addEventListener('drop', handleDrop);

    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, [onFile, notifications]);

  return (
    <div ref={dragContainerRef} className={clsx(styles.uploadArea, isWrongExtension && styles.uploadArea__error)}>
      <UploadBgImg className={clsx(styles.uploadImg, isOverArea && styles.uploadImg__active)} />
      <div className={styles.contentWrapper}>
        <UnmountWithTransition
          noAnimationDelayOnFirstRender
          className={styles.uploadButtonAndTextWrapper}
          unmountStylesClass={styles.uploadButtonAndTextWrapper__hidden}
          mountStylesClass={styles.uploadButtonAndTextWrapper__visible}
          isShown={!isOverArea && !isLoading}
          mountDelay={100}
        >
          <div className={styles.uploadButtonWrapper}>
            <Button as="label" prefixIcon={<div className={styles.plus} />} type={undefined} variant="primary">
              {t('common:Buttons.chooseFile')}
              <input
                ref={fileInputRef}
                accept=".xlsx, .xlsm"
                type="file"
                className={styles.hiddenInput}
                aria-hidden
                onChange={handleChoseFile}
              />
            </Button>
          </div>
        </UnmountWithTransition>
        <UnmountWithTransition
          className={styles.uploadButtonAndTextWrapper}
          unmountStylesClass={styles.uploadButtonAndTextWrapper__hidden}
          mountStylesClass={styles.uploadButtonAndTextWrapper__visible}
          isShown={isOverArea && isWrongExtension}
          mountDelay={100}
        >
          <p className={clsx(styles.text, styles.text__error)}>{t('drillingCarpet:UploadPlanModal.wrongFileExt')}</p>
        </UnmountWithTransition>
        <UnmountWithTransition
          className={styles.uploadButtonAndTextWrapper}
          unmountStylesClass={styles.uploadButtonAndTextWrapper__hidden}
          mountStylesClass={styles.uploadButtonAndTextWrapper__visible}
          isShown={isOverArea && !isWrongExtension}
          mountDelay={100}
        >
          <p className={clsx(styles.text)}>{t('drillingCarpet:UploadPlanModal.upload')}</p>
        </UnmountWithTransition>
        <UnmountWithTransition
          className={styles.uploadButtonAndTextWrapper}
          unmountStylesClass={styles.uploadButtonAndTextWrapper__hidden}
          mountStylesClass={styles.uploadButtonAndTextWrapper__visible}
          isShown={isLoading}
          mountDelay={100}
        >
          <Spin spinning={isLoading} />
        </UnmountWithTransition>
      </div>
    </div>
  );
});
