import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { DrillingElementCard } from 'src/features/drilling-element-card';
import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { IconButton } from 'src/shared/components/icon-button';
import { Skeleton } from 'src/shared/components/skeleton';
import { LoadingField } from 'src/shared/utils/loading-field';

import { ChartWell } from '../../../presets/drilling-wells-chart/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';

import styles from './data-headers.module.scss';

interface Props {
  item: ChartWell;
  viewport: Viewport;
  isCompactView: boolean;
  isEditing: boolean;
  isLoading: boolean;
  title?: ReactNode;
  descriptionAttributes?: string[] | LoadingField | null;
  isLast?: boolean;
  onClick?(id: number): void;
  onDelete?(well: ChartWell): void;
  className?: string;
}

export const WellsRowHeaderWrapper = observer(function WellsRowHeaderWrapper({
  item,
  isLast,
  viewport,
  isCompactView,
  isEditing,
  onClick,
  onDelete,
  title,
  descriptionAttributes,
  className,
  isLoading,
}: Props) {
  const ref = useMovableElement({
    item,
    verticalViewport: viewport,
  });

  const handleClick = () => onClick?.(item.id);

  const handleDelete = () => onDelete?.(item);

  const getDescription = (): ReactNode => {
    if (!descriptionAttributes) {
      return null;
    }

    if (descriptionAttributes instanceof LoadingField) {
      return (
        <div className={styles.descriptionContainer}>
          <Skeleton rowsNumber={2} />
        </div>
      );
    }

    const descriptionString = descriptionAttributes.join(', ');

    return <p title={descriptionString}>{descriptionString}</p>;
  };

  const allowDeleting = isEditing && onDelete;

  return (
    <div
      ref={ref}
      className={clsx(
        className,
        styles.rowHeader,
        isLast && styles.rowHeaderLast,
        !!onClick && styles.rowHeaderInteractive
      )}
      onClick={handleClick}
    >
      {allowDeleting && (
        <IconButton
          icon={<DeleteIcon />}
          onClick={handleDelete}
          className={styles.deleteButton}
          isLoading={item.isDeleting}
          isDisabled={isLoading}
        />
      )}

      <DrillingElementCard
        title={title}
        description={isCompactView ? undefined : getDescription()}
        className={clsx(allowDeleting && styles.wellCardEditing)}
      />
    </div>
  );
});
