import { TRefQuery, TRefRest } from 'src/store/directories/types';

import { REF_QUERY_REG_EXP } from '../constants/ref-query-reg-exp';

type TDeps = {
  fieldId: string[];
  attrs: string[];
  outer: string[];
};

export const getDynamicJoinDependencies = (refQuery: TRefQuery | TRefRest): TDeps => {
  const dependencies: TDeps = {
    fieldId: [],
    attrs: [],
    outer: [],
  };

  const stringifiedRefQuery = JSON.stringify(refQuery);

  const values = stringifiedRefQuery.matchAll(REF_QUERY_REG_EXP);
  for (const value of values) {
    const type = value[1];
    const valueKey = value[2];
    switch (type) {
      // Values are obtained from controls
      case '$': {
        dependencies.attrs.push(`$${valueKey}`);
        break;
      }
      // Values are obtained from directories
      case '%': {
        dependencies.fieldId.push(`%${valueKey}`);
        break;
      }
      //  Values are obtained from outer object of global variables
      case '@': {
        dependencies.outer.push(`@${valueKey}`);
        break;
      }
    }
  }

  return dependencies;
};
