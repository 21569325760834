import { getRandomNumber } from 'src/shared/utils/get-random-number';

import { TPadObjectType } from '../../utils';
import { AddToChartSidebarWellEntity } from '../well/well.entity';

type TAddToChartSidebarPadEntityOptions = {
  padTitle: string;
  rawPad: TPadObjectType;
  wells: AddToChartSidebarWellEntity[];
};

export class AddToChartSidebarPadEntity {
  readonly id: number;
  readonly pad: TPadObjectType;
  readonly title: string;
  readonly wells: AddToChartSidebarWellEntity[];

  constructor({ padTitle, rawPad, wells }: TAddToChartSidebarPadEntityOptions) {
    this.id = getRandomNumber();
    this.pad = rawPad;
    this.title = padTitle;
    this.wells = wells;
  }

  get firstWell(): AddToChartSidebarWellEntity | null {
    return this.wells[0] || null;
  }

  get lastWell(): AddToChartSidebarWellEntity | null {
    return this.wells.at(-1) || null;
  }
}
