import { action, computed, makeObservable } from 'mobx';
import { PointerEvent } from 'react';

import { SelectAbilityManager } from 'src/shared/hooks/use-disable-safari-touch-selection-fix';

import { Animator } from '../../../../shared/animator/animator';
import { Dnd } from '../../types';

import { DndContextStore } from './dnd-context.store';
import { DraggableTransform } from './draggable-transform';

const DROP_DISTANCE_FOR_TOUCH = 50;
const HOLD_MOVE_DISTANCE_SQUARED = Math.pow(15, 2);

export class DraggableStore<TDraggableItem, TDroppableItem, TInteractiveItem, TDraggingItem, TDraggableShadow> {
  private readonly selectAbilityManager = new SelectAbilityManager();
  private readonly animationDisposers: VoidFunction[] = [];
  private readonly dndStore: DndContextStore<
    TDraggableItem,
    TDroppableItem,
    TInteractiveItem,
    TDraggingItem,
    TDraggableShadow
  >;
  private readonly id: string;
  private readonly dataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>;
  private readonly options: DndContextStore.DraggingOptions;
  private readonly handleClick?: VoidFunction;
  private readonly handleMove?: VoidFunction;

  private interactionState: DraggableStore.InteractionState | null = null;

  constructor(
    { id, dataItem, onClick, onMove, options }: DraggableStore.Args<TDraggableItem, TDraggableShadow>,
    dndStore: DndContextStore<TDraggableItem, TDroppableItem, TInteractiveItem, TDraggingItem, TDraggableShadow>
  ) {
    this.dndStore = dndStore;
    this.id = id;
    this.dataItem = dataItem;
    this.options = options || {};
    this.handleClick = onClick;
    this.handleMove = onMove;

    makeObservable(this);
  }

  @computed
  get transform(): DraggableTransform | undefined {
    if (this.dndStore.active && this.dndStore.active?.id === this.id) {
      return this.dndStore.active.transform;
    }

    return undefined;
  }

  @computed
  get isDragging(): boolean {
    return !!this.dndStore.active && this.dndStore.active?.id === this.id;
  }

  init = (): VoidFunction => {
    const disposeAnimations = () => this.animationDisposers.map((dispose) => dispose());

    return () => {
      disposeAnimations();
    };
  };

  cancelDragging = (): void => {
    if (this.options?.draggableClassName) {
      this.dndStore.active?.element.classList.remove(this.options.draggableClassName);
    }

    if (this.interactionState) {
      const { isMoving } = this.interactionState;

      if (isMoving && this.dndStore.active) {
        this.dndStore.active.transform.setValue({ x: 0, y: 0 });

        this.handleMove?.();
      }
    }

    this.dndStore.removeActive();
    this.selectAbilityManager.returnSelectAbility();

    this.interactionState = null;
  };

  @action.bound
  onPointerDown(e: PointerEvent<HTMLElement>) {
    if (!e.isPrimary) {
      return;
    }

    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);

    this.interactionState = {
      initialClientX: e.clientX,
      initialClientY: e.clientY,
      isMoving: false,
    };
  }

  @action.bound
  onPointerMove(e: PointerEvent<HTMLElement>): void {
    if (!e.isPrimary) {
      return;
    }

    e.stopPropagation();
    if (!this.interactionState) {
      return;
    }

    this.selectAbilityManager.disableSelectAbility();

    const { isMoving, initialClientX, initialClientY } = this.interactionState;

    if (!isMoving) {
      const distance = Math.pow(e.clientX - initialClientX, 2) + Math.pow(e.clientY - initialClientY, 2);

      if (distance >= HOLD_MOVE_DISTANCE_SQUARED) {
        this.interactionState.isMoving = true;

        if (this.dndStore.getIsDraggable(this.dataItem)) {
          this.dndStore
            .setActive(this.id, e.currentTarget, this.dataItem, { x: e.clientX, y: e.clientY }, this.options)
            ?.addActiveClassName();

          this.handleMove?.();
          return;
        }
      }
    }

    if (this.dndStore.active?.id !== this.id) {
      return;
    }

    if (isMoving) {
      const { prevClientX, prevClientY } = this.interactionState;

      const offsetX = e.clientX - (prevClientX ?? initialClientX);
      const offsetY = e.clientY - (prevClientY ?? initialClientY);

      const { transform } = this.dndStore.active;

      transform.add({ x: offsetX, y: offsetY });

      this.handleMove?.();

      this.interactionState.prevClientX = e.clientX;
      this.interactionState.prevClientY = e.clientY;

      const isTouch = e.pointerType === 'touch';

      this.dndStore.onDragMove(e.clientX, e.clientY, {
        dropDistance: isTouch ? DROP_DISTANCE_FOR_TOUCH : undefined,
      });
    }
  }

  @action.bound
  onPointerCancel() {
    this.cancelDragging();
  }

  @action.bound
  onPointerUp(e: PointerEvent<HTMLElement>) {
    if (!e.isPrimary || !e.currentTarget) {
      return;
    }

    e.stopPropagation();

    const { active, over, onDrop } = this.dndStore;

    const clearActive = this.dndStore.removeActive.bind(this.dndStore, (active) => active.removeActiveClassName());

    if (this.interactionState) {
      const { isMoving } = this.interactionState;

      if (!isMoving) {
        this.handleClick?.();
        clearActive();
      }

      if (isMoving && active) {
        if (over) {
          onDrop?.(active, over);
          clearActive();
        } else {
          const onAnimationFinish = clearActive;

          const activeParentRect = active.element.getBoundingClientRect();
          const { x: activeTransformX, y: activeTransformY } = active.transform.value;

          const disposeAnimation = Animator.animateMultiple(
            [
              {
                start: activeTransformX,
                end: activeParentRect.x + activeParentRect.width / 2,
                onChange: (value) => {
                  active.transform.setValue({ x: value });
                  this.handleMove?.();
                },
              },
              {
                start: activeTransformY,
                end: activeParentRect.y + activeParentRect.height / 2,
                onChange: (value) => {
                  active.transform.setValue({ y: value });
                  this.handleMove?.();
                },
              },
            ],
            onAnimationFinish
          );

          this.animationDisposers.push(disposeAnimation);
        }
      }
    }

    this.selectAbilityManager.returnSelectAbility();

    this.interactionState = null;
  }
}

namespace DraggableStore {
  export type InteractionState = {
    isMoving: boolean;
    initialClientX: number;
    initialClientY: number;
    prevClientX?: number;
    prevClientY?: number;
  };

  export interface Args<TDraggableItem, TDraggableShadow> {
    id: string;
    dataItem: Dnd.Draggable<TDraggableItem, TDraggableShadow>;
    onClick?: VoidFunction;
    onMove?: VoidFunction;
    options?: DndContextStore.DraggingOptions;
  }
}
