import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

import { ReactComponent as ArrowDrop } from 'src/shared/assets/icons/arrow-drop-icon.svg';
import { TPlanVersion } from 'src/store/comparison/types';

import styles from './styles.module.scss';

type Props = {
  currentPlan: TPlanVersion | null;
  placeholder: string;
  handleOpenSidebar: () => void;
  isDisabled?: boolean;
};

export const CompareButton: React.FC<Props> = observer(function CompareButton({
  currentPlan,
  placeholder,
  handleOpenSidebar,
  isDisabled,
}) {
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <button ref={ref} className={styles.compareButton} onClick={handleOpenSidebar} disabled={isDisabled}>
        <p className={styles.compareButtonText}>{currentPlan?.data.name || placeholder}</p>
        <div className={styles.iconWrapper}>
          <ArrowDrop className={styles.icon} />
        </div>
      </button>
    </>
  );
});
