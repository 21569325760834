import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';

import { Range } from '../../layers/model';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';
import { getOffsetPx, getPositiveOffsetPx } from '../../shared/viewport/viewport-calculator';

import styles from './group-collapse-header.module.scss';

interface Props {
  item: { y?: Range<number> };
  isCollapsed: boolean;
  viewport: Viewport;
  onClick: VoidFunction;
  title?: ReactNode;
  withArrow?: boolean;
  className?: string;
}

export const GroupCollapseHeader = observer(function GroupCollapseHeader({
  item,
  viewport,
  isCollapsed,
  title,
  withArrow = false,
  className,
  onClick,
}: Props) {
  const ref = useMovableElement({
    item,
    verticalViewport: viewport,
    getOffsetY: isCollapsed ? getOffsetPx : getPositiveOffsetPx,
  });

  return (
    <div ref={ref} className={clsx(styles.collapseHeaderWrapper, isCollapsed && styles.collapseHeaderCollapsedWrapper)}>
      <button className={clsx(className, styles.collapseHeader)} onClick={onClick}>
        {withArrow && <ArrowIcon className={clsx(styles.arrowIcon, !isCollapsed && styles.arrowIconUp)} />}

        {title}
      </button>
    </div>
  );
});
