import { action, computed, makeObservable, observable } from 'mobx';

import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { ChartRig, PadRigOperation, RigsGroup, WellRigOperation } from '../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../presets/drilling-rigs-chart/rigs-chart.store';
import { DataView } from '../../shared/data-view/data-view';
import { RigsDataPositionsCalculator } from '../../shared/rigs-data-positions-calculator';
import { Viewport } from '../../shared/viewport/viewport';
import { IChartDndModule, IModuleDataSource } from '../../types';
import { AutoScrollController } from '../auto-scroll/auto-scroll-controller';
import { Dnd } from '../editing/types';
import { RigsChartDataModel } from '../rigs-chart/data/rigs-chart-data-model';
import { SortableGhost } from '../sorting/sortable-ghost';
import { SortableGhostItem } from '../sorting/sortable-ghost-item';
import { SortingContextStore } from '../sorting/sorting-context.store';

export class RigsSortingModule implements IChartDndModule<RigsChartStore.ViewItem[] | null, RigsSorting.DndContext> {
  private readonly notifications: NotificationsStore;
  private readonly dataSource: IModuleDataSource<RigsChartStore.ViewItem[] | null>;
  private readonly verticalViewport: Viewport;
  private readonly dataView: DataView;
  private readonly rigsPositionCalculator: RigsDataPositionsCalculator;
  private readonly onRigsSort: RigsSorting.OnRigsSortFn;
  private readonly onRigGroupsSort: RigsSorting.OnRigGroupsSortFn;
  private readonly onDrop: RigsSorting.OnDrop;

  @observable.ref private activeGhost: SortableGhost<RigsSorting.Sortable, RigsChartDataModel.ViewItem> | null = null;
  @observable private isDisabled = false;

  readonly dndContext: RigsSorting.DndContext;

  constructor(
    dataSource: IModuleDataSource<RigsChartStore.ViewItem[] | null>,
    notification: NotificationsStore,
    verticalViewport: Viewport,
    rigsDataPositionCalculator: RigsDataPositionsCalculator,
    dataView: DataView,
    autoScrollController: AutoScrollController,
    onRigsSort: RigsSorting.OnRigsSortFn,
    onRigGroupsSort: RigsSorting.OnRigGroupsSortFn,
    onDrop: RigsSorting.OnDrop
  ) {
    this.notifications = notification;

    this.dataSource = dataSource;
    this.verticalViewport = verticalViewport;
    this.rigsPositionCalculator = rigsDataPositionCalculator;
    this.dataView = dataView;

    this.onRigsSort = onRigsSort;
    this.onRigGroupsSort = onRigGroupsSort;
    this.onDrop = onDrop;

    this.dndContext = new SortingContextStore(this.verticalViewport, autoScrollController, {
      getIsSortable: (dataItem) => this.getIsSortable(dataItem),
      getIsElementDragging: (activeId, activeElement, activeDataItem, draggingItem) =>
        this.getIsElementDragging(activeId, activeElement, activeDataItem, draggingItem),
      getIsElementInteractive: (active, interactive) => this.getIsElementInteractive(active, interactive),
      onSortEnd: (active, interactive, placement) => this.onSortEnd(active, interactive, placement),
      onMovingStart: (active) => this.onMovingStart(active),
      onMovingFinish: () => this.onMovingFinish(),
    });

    makeObservable(this);
  }

  private getIsSortable: SortingContextStore.GetIsSortableFn<RigsSorting.Sortable> = (dataItem): boolean => {
    if (dataItem instanceof ChartRig) {
      return true;
    }

    if (dataItem instanceof RigsGroup) {
      return true;
    }

    return false;
  };

  private getIsElementDragging: SortingContextStore.GetIsElementDraggingFn<RigsSorting.Sortable, RigsSorting.Dragging> =
    (activeId, activeElement, activeDataItem, draggingItem): boolean => {
      const { dataItem: dragging } = draggingItem;

      if (activeDataItem instanceof ChartRig) {
        if (dragging === activeDataItem) {
          return true;
        }

        if (dragging instanceof PadRigOperation || dragging instanceof WellRigOperation) {
          return dragging.parentRig === activeDataItem;
        }
      }

      if (activeDataItem instanceof RigsGroup) {
        if (dragging === activeDataItem) {
          return true;
        }

        if (dragging instanceof ChartRig) {
          return dragging.parentGroup === activeDataItem;
        }

        if (dragging instanceof PadRigOperation || dragging instanceof WellRigOperation) {
          return dragging.parentRig.parentGroup === activeDataItem;
        }
      }

      return false;
    };

  private getIsElementInteractive: SortingContextStore.GetIsElementInteractiveFn<RigsSorting.Sortable> = (
    active,
    interactive
  ): boolean => {
    if (active === interactive) {
      return false;
    }

    if (active instanceof ChartRig && interactive instanceof ChartRig) {
      return !!active.parentGroup && !!interactive.parentGroup && active.parentGroup.id === interactive.parentGroup.id;
    }

    if (active instanceof RigsGroup && interactive instanceof RigsGroup) {
      return true;
    }

    return false;
  };

  private onSortEnd: SortingContextStore.OnSortEndFn<RigsSorting.Sortable> = (active, interactive, placement): void => {
    if (active instanceof ChartRig && interactive instanceof ChartRig) {
      this.onRigsSort(active, interactive, placement);

      return;
    }

    if (active instanceof RigsGroup && interactive instanceof RigsGroup) {
      this.onRigGroupsSort(active, interactive, placement);

      return;
    }

    this.notifications.showErrorMessageT('errors:failedToChangeItemsOrder');
  };

  @action
  private onMovingStart: SortingContextStore.OnMovingStartFn<RigsSorting.Sortable, RigsChartDataModel.ViewItem> = (
    active
  ): void => {
    const activeSortableKey = active.dataItem.getKey('sortable');

    const activeYPosition = { ...active.dataItem.y };

    const getXPosition = (item: RigsSorting.Dragging): Dnd.PositionRange => {
      if ('x' in item && item.x) {
        return item.x;
      }

      if (active.dataItem instanceof ChartRig) {
        if (item instanceof ChartRig || item instanceof PadRigOperation || item instanceof WellRigOperation) {
          return item.x || null;
        }
      }

      if (active.dataItem instanceof RigsGroup) {
        if (item instanceof RigsGroup) {
          return item.x || null;
        }
      }

      return null;
    };

    const getYPosition = (item: RigsSorting.Dragging): Dnd.PositionRange => {
      if (active.dataItem instanceof ChartRig) {
        if (item instanceof ChartRig) {
          return activeYPosition;
        }

        if (item instanceof PadRigOperation) {
          return this.rigsPositionCalculator.calculatePadPosition(activeYPosition, this.dataView.type, false);
        }

        if (item instanceof WellRigOperation) {
          return this.rigsPositionCalculator.calculateWellPositionByRigRow(activeYPosition, this.dataView.type, false);
        }
      }

      if (active.dataItem instanceof RigsGroup) {
        if (active.dataItem === item) {
          return activeYPosition;
        }

        if (item instanceof ChartRig) {
          return null;
        }

        if (item instanceof PadRigOperation) {
          return null;
        }

        if (item instanceof WellRigOperation) {
          return null;
        }
      }

      return null;
    };

    const createSortableGhostItem: SortableGhost.CreateSortableGhostItemFn<RigsSorting.Dragging> = (dragging) => {
      return new SortableGhostItem(dragging, activeSortableKey, getXPosition(dragging), getYPosition(dragging), {
        isShown: active.dataItem instanceof RigsGroup ? dragging instanceof RigsGroup : true,
      });
    };

    this.activeGhost = new SortableGhost<RigsSorting.Sortable, RigsChartDataModel.ViewItem>(
      active.dataItem,
      active.relatedItems,
      createSortableGhostItem
    );
  };

  @action
  private onMovingFinish: SortingContextStore.OnMovingFinishFn = (): void => {
    this.activeGhost = null;

    this.onDrop();
  };

  init = (): VoidFunction => {
    const disposeSortingContext = this.dndContext.init();

    return () => {
      disposeSortingContext();
    };
  };

  @computed
  get data(): RigsChartStore.ViewItem[] | null {
    const { data } = this.dataSource;

    if (this.activeGhost && data) {
      const { parent: activeSortable, ghostItems } = this.activeGhost;

      const dataWithoutActiveElement = data.filter((item) => item !== activeSortable);

      return [activeSortable, ...ghostItems, ...dataWithoutActiveElement];
    } else {
      return data;
    }
  }

  @action.bound
  disable(): void {
    this.isDisabled = true;
  }

  @action.bound
  enable(): void {
    this.isDisabled = false;
  }
}

export namespace RigsSorting {
  export type Sortable = ChartRig | RigsGroup;

  export type Dragging = RigsChartDataModel.ViewItem;

  export type DndContext = SortingContextStore<Sortable, Dragging>;

  export type OnRigsSortFn = (current: ChartRig, placeOn: ChartRig, placement: 'before' | 'after') => void;

  export type OnRigGroupsSortFn = (current: RigsGroup, placeOn: RigsGroup, placement: 'before' | 'after') => void;

  export type OnDrop = VoidFunction;

  export type ViewItem = RigsChartDataModel.ViewItem | SortableGhostItem<Dragging>;

  export function isRigsGroupGhost(item: unknown): item is SortableGhostItem<RigsGroup> {
    return item instanceof SortableGhostItem && item.ghost instanceof RigsGroup;
  }

  export function isRigGhost(item: unknown): item is SortableGhostItem<ChartRig> {
    return item instanceof SortableGhostItem && item.ghost instanceof ChartRig;
  }

  export function isPadGhost(item: unknown): item is SortableGhostItem<PadRigOperation> {
    return item instanceof SortableGhostItem && item.ghost instanceof PadRigOperation;
  }

  export function isWellGhost(item: unknown): item is SortableGhostItem<WellRigOperation> {
    return item instanceof SortableGhostItem && item.ghost instanceof WellRigOperation;
  }
}
