import { observer } from 'mobx-react-lite';

import { RigsViewSettingsProvider } from 'src/features/drilling-chart/presets/drilling-rigs-chart/rigs-view-settings-provider';
import { WellsViewSettings } from 'src/features/drilling-chart/presets/drilling-wells-chart/wells-view-settings-provider';
import { ColorAttribute } from 'src/features/drilling-chart/shared/entities/color-attribute';
import { IconAttribute } from 'src/features/drilling-chart/shared/entities/icon-attribute';
import { RegularAttribute } from 'src/features/drilling-chart/shared/entities/regular-attribute';
import { WellTypeIcon } from 'src/features/well-type-icon';
import { ReactComponent as CircleIcon } from 'src/shared/assets/icons/circle.svg';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { TitleAttribute } from '../../shared/entities/title-attribute';

import styles from './stage-well-tooltip-attribute.module.scss';

interface Props {
  item: WellsViewSettings.StageTooltipAttribute | RigsViewSettingsProvider.WellTooltipAttribute;
}

export const StageWellTooltipAttribute = observer(function StageWellTooltipAttribute({ item }: Props) {
  if (item instanceof IconAttribute) {
    return (
      <div className={styles.tooltipRow}>
        <WellTypeIcon iconName={item.iconName} className={styles.tooltipIcon} />
        <LoadingTitle field={item.label} />
      </div>
    );
  }

  if (item instanceof ColorAttribute) {
    return (
      <div className={styles.tooltipRow}>
        <CircleIcon className={styles.tooltipIcon} style={{ fill: item.color }} />
        <LoadingTitle field={item.label} />
      </div>
    );
  }

  if (item instanceof RegularAttribute) {
    return (
      <p className={styles.tooltipRow}>
        {!!item.label && <span className={styles.regularAttrLabel}>{item.label}</span>}
        <span className={styles.regularAttrValue}>{item.value}</span>
      </p>
    );
  }

  if (item instanceof TitleAttribute) {
    return (
      <div className={styles.tooltipRow}>
        <LoadingTitle field={item.value} as="h4" className={styles.titleAttrValue} />
      </div>
    );
  }

  return null;
});
