import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ChartTooltip } from 'src/features/drilling-chart/features/chart-tooltip/chart-tooltip';
import { useIsOutOfViewport } from 'src/shared/hooks/use-is-out-of-viewport';

import { DrillingElementColoredCard } from '../../../drilling-element-colored-card';
import { WellTypeIcon } from '../../../well-type-icon';
import { WellRigOperation } from '../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { SidebarWellRigOperation } from '../../presets/pads-and-wells-sidebar/entities/well-rig-operation.entity';
import { useClickHandler } from '../../shared/use-click-handler';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';

import { WellTooltipInner } from './well-tooltip-inner';

import styles from './well-rig-operation-wrapper.module.scss';

interface Props {
  item: WellRigOperation | SidebarWellRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  className?: string;
  cardClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
  onClick?: VoidFunction;
}

export const WellRigOperationWrapper = observer(function WellRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
  className,
  cardClassName,
  contentClassName,
  headerClassName,
  onClick,
}: Props) {
  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });

  const isOutOfViewport = useIsOutOfViewport(item, horizontalViewport);

  const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

  const { onPointerDown, onPointerUp } = useClickHandler(onClick);

  const wellIcon = viewSettingsProvider.getWellIcon(item.data);

  return (
    <ChartTooltip
      content={<WellTooltipInner well={item} viewProvider={viewSettingsProvider} />}
      placement={tooltipPlacement}
    >
      {({ handleMouseOver, handleMouseLeave }) => {
        return (
          <div key={item.id} ref={ref} className={clsx(className, styles.dataItem)}>
            <DrillingElementColoredCard
              title={viewSettingsProvider.getTitle(item)}
              color={viewSettingsProvider.getWellColor(item.data) || ''}
              attributes={viewSettingsProvider.getAttributes(item)}
              icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
              className={classNames(cardClassName, styles.wellCard)}
              contentClassName={contentClassName}
              headerClassName={headerClassName}
              onMouseLeave={handleMouseLeave}
              onMouseOver={handleMouseOver}
              onPointerDown={onPointerDown}
              onPointerUp={onPointerUp}
            />
          </div>
        );
      }}
    </ChartTooltip>
  );
});
