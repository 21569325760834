import { AxisLabelsFormatterCallbackFunction, Chart, Options, TooltipFormatterContextObject } from 'highcharts';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { DashboardTrendResponse, TrendTypeSettings } from 'src/api/dashboard/types';
import { ColumnLabelsDrawer } from 'src/pages/dashboard-page/utils/data-labels-placement/column-labels-drawer';
import { LabelsPlacement } from 'src/pages/dashboard-page/utils/data-labels-placement/types';
import { ILabelResolver } from 'src/pages/dashboard-page/utils/types';
import { THEMES } from 'src/shared/constants/themes';
import { assert } from 'src/shared/utils/assert';
import { range } from 'src/shared/utils/range';
import { ThemeStore } from 'src/store/theme/theme-store';

import { ChartType, SeriesData } from '../../dashboard/types';
import { getPercentageValue } from '../../dashboard/utils';
import { CHART_COLORS, SERIES_COLORS } from '../chart.consts';

type ChartParams = {
  chartType: ChartType;
  name: string;
  chartKey: string;
  id: string;
  rawData: DashboardTrendResponse;
  planVersions: Record<string, string>;
  theme: ThemeStore;
  resolver?: ILabelResolver | null;
  trendTypeSettings?: TrendTypeSettings;
};

type InitData = {
  options: Partial<Options>;
  series: SeriesData[];
  labels: string[];
};

type NewOptions = {
  series?: SeriesData[];
  categories?: string[];
  chart?: Chart;
  theme?: THEMES;
  type?: ChartType;
  isLabelsEnabled?: boolean;
  planVersions?: Record<string, string>;
};

type UpdateParams = {
  rawData?: DashboardTrendResponse;
  planVersions?: Record<string, string>;
  type?: ChartType;
  withRerender?: boolean;
  resolver?: ILabelResolver | null;
};

export class ChartEntity {
  private readonly chartKey: string;
  private readonly theme: ThemeStore;

  readonly trendTypeSettings?: TrendTypeSettings;

  @observable private _options: Partial<Options>;
  @observable private rawData: DashboardTrendResponse;
  @observable private planVersions?: Record<string, string>;
  @observable private resolver?: ILabelResolver | null;
  @observable id: string;

  @observable name: string;
  @observable series: SeriesData[];
  @observable labels: string[];
  @observable areDataLabelsVisible: boolean = false;
  @observable visiblePlanVersionIndexes: Set<number>;
  @observable chart?: Chart;
  @observable chartType: ChartType;

  constructor({
    chartType,
    name,
    id,
    rawData,
    planVersions,
    resolver,
    chartKey,
    theme,
    trendTypeSettings,
  }: ChartParams) {
    this.chartType = chartType;
    this.name = name;
    this.chartKey = chartKey;
    this.planVersions = planVersions;
    this.resolver = resolver;
    this.rawData = rawData;
    this.theme = theme;
    this.id = id;
    const { series, options, labels } = this.calcData();
    this.series = series;
    this._options = options;
    this.labels = labels;
    this.visiblePlanVersionIndexes = observable.set(
      range(chartType === ChartType.bar ? this.labels.length : this.series.length)
    );
    this.trendTypeSettings = trendTypeSettings;

    makeObservable(this);
  }

  @computed
  get options(): Options {
    return { ...this._options, id: this.id };
  }

  @computed
  get seriesCount(): number {
    if (this.isBarChart) {
      return this.labels.length;
    }

    return this.series.length;
  }

  @computed
  get isBarChart(): boolean {
    return this.chartType === ChartType.bar;
  }

  getIsSeriesEnabledByIndex(index: number): boolean {
    return this.visiblePlanVersionIndexes.has(index);
  }

  getSeriesColorByIndex(index: number): string | null {
    if (this.isBarChart || !this.chart) {
      return null;
    }

    const seriesColor = this.chart.series[index]?.color;

    return seriesColor ? String(seriesColor) : null;
  }

  getSeriesLabelByIndex(index: number): string {
    const key = this.isBarChart ? this.labels[index] : this.series[index].name;

    return this.planVersions ? this.planVersions[key] : key;
  }

  @action.bound
  setOptions(options: Partial<Options>) {
    this._options = options;
  }

  getNewOptions({
    series = this.series,
    categories = this.labels,
    isLabelsEnabled = this.areDataLabelsVisible,
    theme = this.theme.theme,
    type = this.chartType,
    chart = this.chart,
    planVersions = this.planVersions,
  }: NewOptions): Partial<Options> {
    const isDarkTheme = theme === THEMES.dark;
    const isBarChart = type === ChartType.bar;
    const isPaddingRequired = type === ChartType.column && isLabelsEnabled;
    const xAxisLineColor = isDarkTheme ? CHART_COLORS.lightGray : CHART_COLORS.lightBlue;
    const spacingTop = isBarChart && isLabelsEnabled ? LabelsPlacement.BAR_SPACING_TOP : undefined;
    const maxPadding =
      isPaddingRequired && chart
        ? ColumnLabelsDrawer.precalcMaxPadding(series, chart.plotWidth, chart.plotHeight)
        : void 0;

    const formatter: AxisLabelsFormatterCallbackFunction | undefined = isBarChart
      ? ({ value }) => (planVersions ? planVersions[value] : String(value))
      : ({ value }) => {
          const label = this.resolver && typeof value === 'string' ? this.resolver?.tryGetLabelByKey(value) : value;

          return String(label ?? value);
        };
    const that = this;

    return {
      chart: {
        height: 300,
        backgroundColor: isDarkTheme ? CHART_COLORS.darkBlue : CHART_COLORS.white,
        reflow: true,
        spacingTop,
      },
      colors: SERIES_COLORS,
      title: {
        text: void 0,
      },
      legend: {
        reversed: true,
        enabled: false,
      },
      xAxis: {
        crosshair: true,
        categories,
        labels: {
          enabled: true,
          padding: 30,
          style: {
            color: isDarkTheme ? CHART_COLORS.white : CHART_COLORS.black,
          },
          formatter,
        },
        lineColor: isBarChart ? 'transparent' : xAxisLineColor,
        tickLength: isBarChart ? 0 : undefined,
      },
      yAxis: {
        min: 0,
        tickAmount: 3,
        gridLineWidth: isBarChart ? 0 : 1,
        gridLineDashStyle: 'LongDash',
        gridLineColor: isDarkTheme ? CHART_COLORS.gray : CHART_COLORS.lightBlue,
        title: {
          text: null,
        },
        labels: {
          enabled: !isBarChart,
          style: {
            color: isDarkTheme ? CHART_COLORS.white : CHART_COLORS.black,
          },
        },
        maxPadding,
      },
      tooltip: {
        enabled: !isLabelsEnabled,
        formatter: function (this: TooltipFormatterContextObject) {
          const suffix = isBarChart ? '%' : '';
          const seriesName = that.getLabelByKey(this.series.name);

          const name = this.key && isBarChart ? planVersions?.[this.key] : this.key;
          return `${name} <br /> ${seriesName}: ${this.y}${suffix}`;
        },
      },
      plotOptions: {
        bar: {
          borderWidth: 0,
          pointWidth: 16,
        },
        series: {
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            lineColor: 'transparent',
            lineWidth: 0,
          },
          dataLabels: {
            style: {
              fontSize: '12px',
            },
          },
          stacking: isBarChart ? 'percent' : undefined,
        },
        column: {
          showInLegend: true,
          pointPadding: 0.05,
          borderWidth: 0,
          groupPadding: 0.05,
          minPointLength: 3,
          dataLabels: {
            enabled: false,
          },
        },
        columnrange: {
          pointPadding: 0.1,
          pointRange: 1,
        },
      },
      credits: {
        enabled: false,
      },
      series,
    };
  }

  getLabelByKey(key: string): string {
    if (this.isBarChart) {
      return this.resolver ? this.resolver.tryGetLabelByKey(key) ?? key : key;
    }

    return this.planVersions ? this.planVersions[key] : key;
  }

  private calcData(): InitData {
    const rawChartData = this.rawData[this.chartKey];
    const parsedPoints = Object.entries(rawChartData.points);
    const labels: string[] = [];
    const rawSeries: Record<string, number>[] = [];

    if (this.chartType === ChartType.bar) {
      const { total } = rawChartData;

      const data: SeriesData[] = [];
      const planVersionIds = Object.keys(rawChartData.total);

      for (const [label, pointItem] of parsedPoints) {
        const newData = {
          data: planVersionIds.map((id) => getPercentageValue(pointItem[id] / total[id]) ?? null),
          name: label,
          type: this.chartType,
        };

        data.push(newData);
      }

      const options = this.getNewOptions({
        categories: planVersionIds,
        series: data,
      });

      return {
        options,
        series: data,
        labels: planVersionIds,
      };
    } else {
      for (const [label, pointItem] of parsedPoints) {
        labels.push(label);
        rawSeries.push(pointItem);
      }

      const dataItems: Record<string, number[]> = {};
      const planVersionIds = Object.keys(rawChartData.total);

      for (const rawDataItem of rawSeries) {
        for (const planVersion of planVersionIds) {
          const value = rawDataItem[planVersion] ?? null;
          if (planVersion in dataItems) {
            dataItems[planVersion].push(value);
          } else {
            dataItems[planVersion] = [value];
          }
        }
      }

      const data: SeriesData[] = [];
      const dataItemsEntries = Object.entries(dataItems);

      for (const [dataItemKey, dataItemValues] of dataItemsEntries) {
        data.push({
          name: dataItemKey,
          data: dataItemValues,
          type: this.chartType,
        });
      }

      const options = this.getNewOptions({
        categories: labels,
        series: data,
      });

      return {
        options,
        series: data,
        labels,
      };
    }
  }

  @action.bound
  private setChartType(type: ChartType) {
    this.chartType = type;
  }

  @action.bound
  setId(id: string) {
    this.id = id;
  }

  @action.bound
  toggleSeriesVisibility(index: number) {
    assert(this.chart);

    if (this.isBarChart) {
      const isEnabled = this.visiblePlanVersionIndexes.has(index);

      if (isEnabled) {
        this.visiblePlanVersionIndexes.delete(index);
      } else {
        this.visiblePlanVersionIndexes.add(index);
      }
      const categories = this.labels.filter((_, ind) => this.visiblePlanVersionIndexes.has(ind));
      const series = this.series.map((series) => ({
        ...series,
        data: series.data.filter((_, ind) => this.visiblePlanVersionIndexes.has(ind)),
      }));

      const options = this.getNewOptions({
        categories: categories,
        series: series,
      });
      this._options = options;
      this.chart.update({ ...this.options }, true, true);
    } else {
      const isEnabled = this.visiblePlanVersionIndexes.has(index);
      this.chart.series[index].setVisible(!isEnabled);

      if (isEnabled) {
        this.visiblePlanVersionIndexes.delete(index);
      } else {
        this.visiblePlanVersionIndexes.add(index);
      }
    }
  }

  @action.bound
  setChartData() {
    const { series, options: newOptions, labels } = this.calcData();

    this.series = series;
    this.labels = labels;
    this._options = newOptions;

    this.visiblePlanVersionIndexes.clear();
    range(this.chartType === ChartType.bar ? this.labels.length : this.series.length).forEach((index) => {
      this.visiblePlanVersionIndexes.add(index);
    });
  }

  @action.bound
  updateChartData({ rawData, planVersions, resolver, withRerender, type }: UpdateParams) {
    if (!this.chart) {
      return;
    }
    if (type) {
      this.setChartType(type);
    }
    if (rawData) {
      this.rawData = rawData;
    }
    if (planVersions) {
      this.planVersions = planVersions;
    }
    if (resolver || resolver === null) {
      this.resolver = resolver;
    }

    this.setChartData();

    if (withRerender) {
      this.setId(uuidv4());
    } else {
      this.chart.update({ ...this.options }, true, true);
    }
  }

  @action.bound
  setDataLabelVisible(value: boolean) {
    this.areDataLabelsVisible = value;

    if (!this.chart) {
      return;
    }

    if (this.isBarChart) {
      const categories = this.labels.filter((_, ind) => this.visiblePlanVersionIndexes.has(ind));
      const series = this.series.map((series) => ({
        ...series,
        data: series.data.filter((_, ind) => this.visiblePlanVersionIndexes.has(ind)),
      }));

      const options = this.getNewOptions({
        categories: categories,
        series: series,
      });

      this._options = options;
    } else {
      const options = this.getNewOptions({});
      this._options = options;
    }

    this.chart.update({ ...this.options, isLabelsEnabled: this.areDataLabelsVisible }, true, true);
  }

  @action.bound
  setLabels(labels: string[]) {
    this.labels = labels;
  }

  @action.bound
  setSeries(series: SeriesData[]) {
    this.series = series;
  }

  @action.bound
  setChart(chart: Chart) {
    this.chart = chart;
  }

  @action.bound
  updateDataLabelsVisibility(visible: boolean) {
    if (!this.chart) return;

    this.setDataLabelVisible(visible);
  }
}
