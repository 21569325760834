import { agent } from 'src/api/agent';
import { TPlainDictObject } from 'src/store/directories/types';

import { throwApiError } from '../utils';

type TRecalculateGraphParams = {
  planVersionId: number;
  startDate: number;
  endDate?: number;
  computeTags: string[];
  rigIds: number[];
};

export const recalculateGraph = async (params: TRecalculateGraphParams): Promise<void> => {
  try {
    await agent.post(
      'compute/well-stages',
      {},
      {
        params: {
          planVersionId: params.planVersionId,
          startDate: params.startDate,
          endDate: params.endDate,
          computeTags: params.computeTags.join(','),
          rigIds: params.rigIds.join(','),
        },
      }
    );
  } catch (e) {
    throwApiError(e);
  }
};

export const fetchRighs = async (planVersionId: number) => {
  try {
    const { data } = await agent.post<TPlainDictObject[]>(
      'graph/rig/list',
      {},
      {
        params: {
          planVersionId,
        },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
};
