import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AddWellToChartSidebar } from 'src/features/add-well-to-chart-sidebar/add-well-to-chart-sidebar';
import { AddWellToChartSidebarStore } from 'src/features/add-well-to-chart-sidebar/add-well-to-chart-sidebar.store';
import { AddWellInUnplannedRigSidebar } from 'src/features/add-well-to-unplanned-rig-sidebar/add-well-to-unplanned-rig-sidebar';
import { LoseChangesModal } from 'src/features/modals/lose-changes-modal';

import { FormStore } from '../entities/form.entity';
import { RestoreApproachesToDirectoryValuesButton } from '../restore-approaches-to-directory-values-button';
import { WellFormTabs } from '../well-form-tabs';

import styles from './tabs-subheader.module.scss';

type Props = {
  formStore: FormStore;
  addWellToChartStore: AddWellToChartSidebarStore;
  additionalTabComponent?: ReactNode | ReactNode[];
};

export const TabsSubheader = observer(function TabsSubheader({
  formStore,
  addWellToChartStore,
  additionalTabComponent,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.tabsWrapper}>
        <WellFormTabs formStore={formStore} />
        <div className={styles.additionalTabComponentWrapper}>
          {additionalTabComponent}
          {addWellToChartStore.isResetValuesButtonShown && (
            <RestoreApproachesToDirectoryValuesButton
              onRestoreToDirectoryValues={addWellToChartStore.openLoseChangesModal}
            />
          )}
        </div>
      </div>
      <LoseChangesModal
        text={t('newWellForm:Approaches.stagesLoseChanges')}
        isOpened={addWellToChartStore.isLoseChangesModalOpened}
        confirmCallback={addWellToChartStore.resetToDirectoriesValue}
        handleClose={addWellToChartStore.closeLoseChangesModal}
      />
      <AddWellToChartSidebar store={addWellToChartStore} />
      <AddWellInUnplannedRigSidebar
        title={addWellToChartStore.rigTitle}
        isOpened={addWellToChartStore.isOpen && !addWellToChartStore.pads.length}
        datePickerValue={addWellToChartStore.startDate}
        onCloseSidebar={addWellToChartStore.closeSidebar}
        onCancel={addWellToChartStore.closeSidebar}
        onSuccess={addWellToChartStore.loadAutocompletedDataByStartDate}
        onWorkStartChange={addWellToChartStore.changeStartDate}
      />
    </>
  );
});
