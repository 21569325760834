import { observer } from 'mobx-react-lite';

import { Item } from '../../../../shared/entities/abstract-control-entities';
import { DateIntervalField, PassingIntervalField } from '../../../../shared/entities/control-entities';
import { FormStore } from '../../entities/form.entity';
import { NewWellIntervalDateField, NewWellIntervalPassingField } from '../../form-controls';
import { FormElement } from '../../form-element';

type Props = {
  className?: string;
  item: Item;
  formStore: FormStore;
};

export const SectionField = observer(function SectionField({ className, item, formStore }: Props) {
  if (item instanceof DateIntervalField) {
    return <NewWellIntervalDateField item={item} isDisabled={item.isVisuallyDisabled} />;
  }

  if (item instanceof PassingIntervalField) {
    return <NewWellIntervalPassingField item={item} isDisabled={item.isVisuallyDisabled} />;
  }

  return <FormElement className={className} item={item} formStore={formStore} />;
});
