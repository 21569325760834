import { action, computed, flow, makeObservable, observable } from 'mobx';

import { ConflictWell } from 'src/api/draft/conflict-well';
import { BaseApiError } from 'src/errors';
import { getConcatedName } from 'src/shared/utils/get-concated-name';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { ViewsStore } from 'src/store/views';
import { PadTypes } from 'src/types/pad-types';

import { Pad } from './pad';
import { RigConflictsSidebarViewProvider } from './rig-conflicts-sidebar-view-provider';
import { RigStore } from './rig.store';

export class RigConflictsSidebarStore {
  private readonly directories: Directories;
  private readonly views: ViewsStore;
  private readonly notifications: NotificationsStore;

  @observable private handleCancel?: RigConflictsSidebar.CancelHandler;
  @observable private handleApply?: RigConflictsSidebar.ApplyHandler;

  @observable isLoading = false;
  @observable isOpen = false;
  // New provider will be created on every time the sidebar view is loaded.
  @observable viewProvider?: RigConflictsSidebarViewProvider;
  @observable rigStore?: RigStore;

  constructor(directories: Directories, views: ViewsStore, notifications: NotificationsStore) {
    this.directories = directories;
    this.views = views;
    this.notifications = notifications;

    makeObservable(this);
  }

  @flow.bound
  private async *loadView() {
    this.isLoading = true;

    const view = await this.views.conflictSidebarView.loadView();
    yield;

    const attrNames = new Set<string>();
    const refObjects = new Set<string>();
    const joinedObjects = new Set<TRefQuery>();

    const { attrName, refQuery, wells } = view.items.pads;

    if (refQuery) {
      joinedObjects.add(refQuery);
    } else {
      attrNames.add(attrName);
    }

    if (wells.refObjectType) {
      refObjects.add(wells.refObjectType);
    } else {
      attrNames.add(wells.attrName);
    }

    await Promise.all([
      this.directories.loadAttrNames([...attrNames]),
      this.directories.loadObjects([...refObjects]),
      this.directories.loadJoinedObjectsDeprecated([...joinedObjects]),
    ]);
    yield;

    this.viewProvider = new RigConflictsSidebarViewProvider(view, this.directories);
    this.isLoading = false;
  }

  @computed
  get rigTitle(): string | null {
    const titleView = this.viewProvider?.view.title;
    const rig = this.rigStore?.rig?.item;

    if (!rig || !titleView) {
      return null;
    }

    return getConcatedName(this.directories, titleView, rig.data);
  }

  @action.bound
  async open(
    planVersionId: number,
    rigId: number | string,
    conflictRigOperation: ConflictWell,
    onApply?: RigConflictsSidebar.ApplyHandler,
    onCancel?: RigConflictsSidebar.CancelHandler
  ): Promise<void> {
    this.handleCancel = onCancel;
    this.handleApply = onApply;

    if (!this.viewProvider) {
      this.loadView();
    }

    this.rigStore = new RigStore(conflictRigOperation, this.notifications);
    this.rigStore.loadRig(planVersionId, rigId);
    this.rigStore.padsStorage = this.rigStore.pads.reduce<PadTypes.RawPad[]>((pads, padOrWell) => {
      if (this.rigStore && padOrWell instanceof Pad) {
        pads.push(this.rigStore.serializePad(padOrWell));
      }

      return pads;
    }, []);

    this.isOpen = true;
  }

  @action.bound
  async onCancel() {
    this.isLoading = true;

    await this.handleCancel?.();

    this.isLoading = false;
    this.isOpen = false;
  }

  @action.bound
  async onApply() {
    try {
      this.isLoading = true;

      await this.handleApply?.();

      this.isOpen = false;
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToSetApproach');
    } finally {
      this.isLoading = false;
    }
  }
}

export namespace RigConflictsSidebar {
  export type CancelHandler = () => Promise<void>;
  export type ApplyHandler = () => void;
}
