import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { LoseChangesModal as CancelEditingConfirmationModal } from 'src/features/modals/lose-changes-modal';
import { ComparisonModeButtons } from 'src/features/wells-list/wells-control-bar/comparison-mode-buttons';
import { EditModeButtons } from 'src/features/wells-list/wells-control-bar/edit-mode-buttons';
import { ViewModeButtons } from 'src/features/wells-list/wells-control-bar/view-mode-buttons';
import { WellsTabs } from 'src/features/wells-list/wells-tabs';
import { PAGE_MODE, WELLS_TYPE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';

import styles from './wells-control-bar.module.scss';

interface Props {
  pageStore: WellsPageStore;
  wellFormManager: WellFormManager;
}

export const WellsControlBar = observer(function WellsControlBar({ pageStore, wellFormManager }: Props) {
  const { t } = useTranslation('wells');

  function onSelectWellsType(wellsType: WELLS_TYPE): void {
    if (wellsType === WELLS_TYPE.drilled && pageStore.mode === PAGE_MODE.edit) {
      onOpenCancelEditingConfirmationModal();
      return;
    }

    pageStore.setWellsType(wellsType);
  }

  function onOpenCancelEditingConfirmationModal(): void {
    pageStore.setIsConfirmCancelEditingModalOpened(true);
  }

  function onCloseCancelEditingConfirmationModal(): void {
    pageStore.setIsConfirmCancelEditingModalOpened(false);
  }

  function onConfirmCancelEditing(): void {
    pageStore.setWellsType(WELLS_TYPE.drilled);
    pageStore.cancelEditing();
    onCloseCancelEditingConfirmationModal();
  }

  function renderControlButtons(): React.ReactNode {
    switch (pageStore.mode) {
      case PAGE_MODE.view:
        return <ViewModeButtons pageStore={pageStore} />;

      case PAGE_MODE.edit:
        return <EditModeButtons pageStore={pageStore} wellFormManager={wellFormManager} />;

      case PAGE_MODE.compare:
        return <ComparisonModeButtons pageStore={pageStore} />;

      default: {
        return <ViewModeButtons pageStore={pageStore} />;
      }
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.switchersBlock}>
          <SwitchButtonGroup
            onChange={onSelectWellsType}
            defaultOptionKey={pageStore.wellsType}
            value={pageStore.wellsType}
            items={[
              {
                key: WELLS_TYPE.drilled,
                title: t('drilled'),
              },
              {
                key: WELLS_TYPE.planned,
                title: t('planned'),
              },
              {
                key: WELLS_TYPE.unplanned,
                title: t('unplanned'),
              },
            ]}
          />

          {pageStore.mode !== PAGE_MODE.edit && <WellsTabs className={styles.wellsTabs} pageStore={pageStore} />}
        </div>

        <div className={styles.rightControlBlock}>{renderControlButtons()}</div>
      </div>

      <CancelEditingConfirmationModal
        isOpened={pageStore.isConfirmCancelEditingModalOpened}
        handleClose={onCloseCancelEditingConfirmationModal}
        confirmCallback={onConfirmCancelEditing}
      />
    </>
  );
});
