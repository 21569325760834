import { autorun } from 'mobx';

import { Item, ValidatableItem } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class RequiredIfPlugin extends FormPlugin {
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item instanceof ValidatableItem && item.requiredIf) {
        const disposer = autorun(() => {
          if (item.requiredIf) {
            for (const requiredIf of item.requiredIf) {
              const control = form.fields[requiredIf.control];
              item.setIsRequired(control.value === requiredIf.value);
            }
          }
        });

        disposers.push(disposer);
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
