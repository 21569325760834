import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import styles from './descriptions.module.scss';

interface Props {
  className?: string;
  /** Columns width, unit required.
   *
   * @example
   * ['20%', '115px']
   * */
  columnsWidth?: string[];
}

export const Descriptions = observer(function Descriptions({
  className,
  children,
  columnsWidth,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(className, styles.container)}
      style={{
        '--descriptions-label-column-width': columnsWidth?.[0] || '206px',
        '--descriptions-value-column-width': columnsWidth?.[1] || 'auto',
      }}
    >
      {children}
    </div>
  );
});
