import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';

import { StageWellTooltipAttribute } from 'src/features/drilling-chart/features/stage-well-tooltip-attribute';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { WellRigOperationStage } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';

import styles from './data-items-empty.module.scss';

interface Props {
  stage: WellRigOperationStage;
  viewProvider: WellsViewSettingsProvider;
}

export const DrillingStageTooltipInner = observer(function DrillingStageTooltipInner({ stage, viewProvider }: Props) {
  const tooltip = viewProvider.getStageTooltip(stage);

  if (!tooltip) {
    return null;
  }

  return (
    <div className={styles.tooltipInner}>
      <LoadingTitle field={tooltip.title} as="h4" className={styles.tooltipHeading} />

      <p className={styles.tooltipRow}>{tooltip.subTitle}</p>

      <Divider className={styles.tooltipDivider} />

      {tooltip.attributes.map((attribute, index) => (
        <StageWellTooltipAttribute key={index} item={attribute} />
      ))}
    </div>
  );
});
