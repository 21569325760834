import { TJoinResponse } from 'src/store/directories/types';

import { isObjectWithKeys } from './is-object-with-keys';

export const checkIsJoinResponseDirectory = (directory: unknown): directory is TJoinResponse => {
  if (!isObjectWithKeys(directory)) {
    return false;
  }

  if (Object.values(directory).some((dir) => !isObjectWithKeys(dir))) {
    return false;
  }

  return true;
};
