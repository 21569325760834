import { MultiCombobox } from 'src/shared/entities/control-entities/multicombobox.entity';
import { Directories } from 'src/store/directories/directories.store';

import { TMultiComboboxRaw } from '../../types';

type TMulticomboboxSerializerOptions = {
  item: TMultiComboboxRaw;
  directories: Directories;
};

export const mapMulticombobox = ({ item, directories }: TMulticomboboxSerializerOptions): MultiCombobox => {
  const data = {
    directories,
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    useInMainProgressBar: !!item.useInMainProgressBar,
    required: !!item.required,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    calculatedValue: item.calculatedValue,
    updateDictValue: item.updateDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    checkDictValue: item.checkDictValue,
    refObjectAttr: item.refObjectAttr,
    refObjectType: item.refObjectType,
    onChangeInstructions: item.onChange,
    refObjectFilterByFields: item.refObjectFilterByFields,
    restrictions: { required: !!item.required },
    directory: directories.getObject(item.refObjectType),
    refQuery: 'refQuery' in item ? item.refQuery : undefined,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    settings: item.settings,
  };

  return new MultiCombobox(data);
};
