export namespace AutoScroll {
  export const DEFAULT_CONTAINER_SCROLL_DISTANCE = 150;

  export enum Direction {
    left = 'left',
    right = 'right',
    top = 'top',
    bottom = 'bottom',
    topLeft = 'topLeft',
    topRight = 'topRight',
    bottomLeft = 'bottomLeft',
    bottomRight = 'bottomRight',
  }

  /** Returns value in range 1..2. */
  export function getScrollAcceleration(x: number, scrollDistance: number): number {
    if (x > scrollDistance || scrollDistance <= 0) {
      return 1;
    }

    if (x < scrollDistance / 6) {
      return 1 + (scrollDistance - x) / scrollDistance;
    }

    if (x < scrollDistance * 0.7) {
      return 1 + (scrollDistance - x) / scrollDistance / 2;
    }

    if (x < scrollDistance / 10) {
      return 1 + (scrollDistance - x) / scrollDistance / 3;
    }

    return 1 + (scrollDistance - x) / scrollDistance;
  }

  export function validateCoordinate(coordinate: number, min: number, max: number): number {
    if (coordinate < min) {
      return min;
    }

    if (coordinate > max) {
      return max;
    }

    return coordinate;
  }

  export function getDirection(
    isLeft: boolean,
    isRight: boolean,
    isTop: boolean,
    isBottom: boolean
  ): Direction | undefined {
    switch (true) {
      case isLeft && isTop:
        return Direction.topLeft;

      case isLeft && isBottom:
        return Direction.bottomLeft;

      case isRight && isTop:
        return Direction.topRight;

      case isRight && isBottom:
        return Direction.bottomRight;

      case isLeft:
        return Direction.left;

      case isRight:
        return Direction.right;

      case isTop:
        return Direction.top;

      case isBottom:
        return Direction.bottom;

      default:
        return undefined;
    }
  }
}
