import { ComparingSummary } from 'src/api/chart/comparing-summary-data-api';
import { Summary } from 'src/api/chart/summary-data-api';

export class ComparingRigSummaryResult {
  private readonly result?: ComparingSummary.Result;

  constructor(result?: ComparingSummary.Result) {
    this.result = result;
  }

  getCellResult(rowName: string, columnName: string): ComparingSummary.ResultEntry | undefined {
    if (!this.result || !Summary.isIndicator(rowName) || !Summary.isColumn(columnName)) {
      return;
    }

    const row = this.result[rowName];

    return row?.[columnName];
  }
}
