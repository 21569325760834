import { v4 as uuidv4 } from 'uuid';

import { DraggableGhost } from './draggable-ghost';

export class DraggableGhostShadow<TParentGhost, TShadow, TViewItem> {
  readonly shadow: TShadow;
  readonly uniqueCreationKey: string;
  readonly parent: DraggableGhost<TParentGhost, TShadow>;
  readonly getViewItems: DraggableGhostShadow.GetViewItemsFn<TViewItem>;

  constructor(
    item: TShadow,
    parent: DraggableGhost<TParentGhost, TShadow>,
    getViewItem?: DraggableGhostShadow.GetViewItemsFn<TViewItem>
  ) {
    this.shadow = item;
    this.uniqueCreationKey = uuidv4();
    this.parent = parent;
    this.getViewItems = getViewItem || (() => []);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-shadow-${this.uniqueCreationKey}`;
    }

    return `shadow-${this.uniqueCreationKey}`;
  }
}

export namespace DraggableGhostShadow {
  export type GetViewItemsFn<TViewItem> = () => TViewItem[];
}
