import { action, makeObservable, observable } from 'mobx';

import { mapApproach } from 'src/api/new-well/serializers/approaches-serializers';
import {
  TApproachesListRaw,
  TApproachRaw,
  TRequiredIf,
  TSerializedApproach,
  TSerializedStage,
} from 'src/api/new-well/types';
import { hasValue } from 'src/shared/utils/common';
import { Directories } from 'src/store/directories/directories.store';

import { Approach } from './approach.entity';
import { ApproachesTab } from './approaches-tab.entity';

type TApproachesListData = {
  directories: Directories;
  approachesTab: ApproachesTab;
  approachTemplate: TApproachesListRaw;
  savedApproaches?: TSerializedApproach[];
};

export class ApproachesList {
  readonly directories: Directories;
  readonly requiredIf: TRequiredIf[];
  readonly approachesTab: ApproachesTab;
  readonly attrName: string;
  readonly approachReference: TApproachRaw;

  @observable approaches: Approach[];
  @observable activeApproach: number | null = null;
  @observable isRequired: boolean;

  constructor(data: TApproachesListData) {
    this.directories = data.directories;
    this.approachesTab = data.approachesTab;
    this.isRequired = !!data.approachTemplate.required;
    this.requiredIf = data.approachTemplate.requiredIf;
    this.attrName = data.approachTemplate.attrName;
    this.approaches = this.getSavedApproaches(data.savedApproaches) || [];
    this.approachReference = data.approachTemplate.approach;

    makeObservable(this);
  }

  private getSavedApproaches(savedApproaches?: TSerializedApproach[]): Approach[] | undefined {
    if (!this.approachReference || !savedApproaches) return;
    const approachReference = this.approachReference;
    const approachesControls: Approach[] = [];

    savedApproaches.forEach((savedApproach) => {
      const approachControl = mapApproach({ approachReference, directories: this.directories }, savedApproach);
      if (approachControl) {
        approachesControls.push(approachControl);
      }
    });
    return approachesControls;
  }

  isAddToChartButtonVisible(approach: Approach): boolean {
    return this.approaches[0] === approach;
  }

  @action.bound
  setAutocreatedStages(stages: TSerializedStage[]): void {
    if (!this.approaches[0]) {
      return;
    }

    this.approaches[0].stagesList.setStagesByData(stages);
    this.approaches = [this.approaches[0]];
  }

  @action.bound
  addNewApproach(): void {
    const newApproach = mapApproach({ approachReference: this.approachReference, directories: this.directories });
    if (newApproach) {
      this.approaches.push(newApproach);
      this.activeApproach = newApproach.id;
    }
  }

  @action.bound
  deleteApproach(id: number): void {
    const targetApproach = this.approaches.find((approach) => approach.id === id);

    targetApproach?.onDeleteCallbacks.forEach((clb) => clb());

    this.approaches = this.approaches.filter((item) => item.id !== id);
    if (id === this.activeApproach) {
      this.setActiveApproach(null);
    }
  }

  @action.bound
  setApproaches(approaches: Approach[]): void {
    this.approaches = approaches;

    if (hasValue(this.activeApproach)) {
      if (this.approaches.length) {
        this.setActiveApproach(this.approaches[0].id);
      } else {
        this.setActiveApproach(null);
      }
    }
  }

  @action.bound
  setActiveApproach(id: number | null): void {
    this.activeApproach = id;
  }

  @action.bound
  setIsRequired(is: boolean): void {
    this.isRequired = is;
  }

  @action.bound
  validate(): void {
    this.approaches.forEach((approach) => approach.validate());
  }

  @action.bound
  clear(): void {
    this.approaches = [];
  }
}
