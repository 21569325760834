import { assert } from 'src/shared/utils/assert';

import {
  ComparingRigSummary,
  MainComparingRigSummary,
  ComparingRigSummaryResult,
} from '../../features/drilling-chart/presets/summary-data-sidebar/entities';
import { IRigSummary } from '../../features/drilling-chart/presets/summary-data-sidebar/entities/rig-summary';

import { ComparingSummary } from './comparing-summary-data-api';
import { Summary } from './summary-data-api';

export class ComparingSummaryDataAdapter {
  private parseDateKey(dateKey: string): keyof Summary.RowValues | undefined {
    const [year, quarter] = dateKey.split(/-Q/);
    const yearNumber = Number.parseInt(year);
    const quarterNumber = Number.parseInt(quarter);

    assert(!Number.isNaN(yearNumber) && !Number.isNaN(quarterNumber), 'Parsing error.');

    const quarterFieldNamesMap: Record<number, keyof Summary.RowValues> = {
      1: 'firstQuarter',
      2: 'secondQuarter',
      3: 'thirdQuarter',
      4: 'fourthQuarter',
    };

    return quarterFieldNamesMap[quarterNumber];
  }

  initializeSummary(data: ComparingSummary.RawData, rigIds: number[]): Map<number, IRigSummary | null> {
    const initializedItems: Map<number, IRigSummary | null> = new Map();
    /*
     * Example:
     *
     * Map<{
     *   rigId: {
     *     first: {
     *       DRILLING_COMPLETE_WELLS_COUNT: {
     *         firstQuarter: 1,
     *         secondQuarter: 2,
     *         thirdQuarter: 3,
     *         fourthQuarter: 4,
     *         total: 5,
     *       },
     *       ...
     *     },
     *     second: {
     *       DRILLING_COMPLETE_WELLS_COUNT: {
     *         firstQuarter: 6,
     *         secondQuarter: 7,
     *         thirdQuarter: 8,
     *         fourthQuarter: 9,
     *         total: 10,
     *       },
     *       ...
     *     }
     *   },
     * }>
     * */
    const rawRigsSummary: Map<number, ComparingSummaryDataAdapter.TwoVersionsValues> = new Map();
    /*
     * Example:
     *
     * Map<{
     *   rigId: {
     *     DRILLING_COMPLETE_WELLS_COUNT: {
     *         firstQuarter: 'CHANGED',
     *         secondQuarter: 'CHANGED',
     *         thirdQuarter: 'CHANGED',
     *         fourthQuarter: 'CHANGED',
     *         total: 'CHANGED',
     *      },
     *      ...
     *    }
     * }>
     * */
    const comparingResult: Map<number, ComparingSummary.Result> = new Map();
    const rigsSummaryChanges: Map<number, boolean> = new Map();

    for (const indicator of Object.values(Summary.Indicator)) {
      const { points: quarters } = data[indicator];

      for (const dateKey of Object.keys(quarters)) {
        const { points: rigs } = quarters[dateKey];

        const quarterFieldName = this.parseDateKey(dateKey);

        if (quarterFieldName) {
          for (const rigId of Object.keys(rigs)) {
            const rigIdNumber = Number(rigId);
            const firstValue: number = rigs[rigId]?.oldValue ?? 0;
            const secondValue: number = rigs[rigId]?.newValue ?? 0;
            const resultCode: ComparingSummary.ResultCode | null = rigs[rigId]?.result ?? null;

            const currentRigData: ComparingSummaryDataAdapter.PartialTwoVersionsValues | undefined =
              rawRigsSummary.get(rigIdNumber) ||
              rawRigsSummary.set(rigIdNumber, { first: {}, second: {} }).get(rigIdNumber);

            if (currentRigData) {
              const { first, second } = currentRigData;

              const firstVersionIndicatorValues = first?.[indicator];

              if (firstVersionIndicatorValues) {
                firstVersionIndicatorValues[quarterFieldName] = firstValue;
              } else {
                if (first) {
                  first[indicator] = {
                    [quarterFieldName]: firstValue,
                  };
                } else {
                  currentRigData.first = {
                    [indicator]: {
                      [quarterFieldName]: firstValue,
                    },
                  };
                }
              }

              const secondVersionIndicatorValues = second?.[indicator];

              if (secondVersionIndicatorValues) {
                secondVersionIndicatorValues[quarterFieldName] = secondValue;
              } else {
                if (second) {
                  second[indicator] = {
                    [quarterFieldName]: secondValue,
                  };
                } else {
                  currentRigData.second = {
                    [indicator]: {
                      [quarterFieldName]: secondValue,
                    },
                  };
                }
              }
            }

            const currentRigComparingResult =
              comparingResult.get(rigIdNumber) || comparingResult.set(rigIdNumber, {}).get(rigIdNumber);

            if (currentRigComparingResult) {
              const indicatorResult = currentRigComparingResult[indicator];

              if (indicatorResult) {
                indicatorResult[quarterFieldName] = {
                  code: resultCode,
                  change: secondValue - firstValue,
                };
              } else {
                currentRigComparingResult[indicator] = {
                  [quarterFieldName]: {
                    code: resultCode,
                    change: secondValue - firstValue,
                  },
                };
              }
            }

            const prevRigHasChanges = rigsSummaryChanges.get(rigIdNumber) ?? false;
            rigsSummaryChanges.set(
              rigIdNumber,
              prevRigHasChanges || resultCode !== ComparingSummary.ResultCode.noChanges
            );
          }
        }
      }
    }

    for (const rigId of rigIds) {
      const rawRigSummary = rawRigsSummary.get(rigId);
      const rigComparingResult = comparingResult.get(rigId);

      for (const indicator of Object.values(Summary.Indicator)) {
        const firstValuesOfIndicator = rawRigSummary?.first[indicator];

        if (firstValuesOfIndicator) {
          firstValuesOfIndicator.total =
            (firstValuesOfIndicator.firstQuarter ?? 0) +
            (firstValuesOfIndicator.secondQuarter ?? 0) +
            (firstValuesOfIndicator.thirdQuarter ?? 0) +
            (firstValuesOfIndicator.fourthQuarter ?? 0);
        }

        const secondValuesOfIndicator = rawRigSummary?.second[indicator];

        if (secondValuesOfIndicator) {
          secondValuesOfIndicator.total =
            (secondValuesOfIndicator.firstQuarter ?? 0) +
            (secondValuesOfIndicator.secondQuarter ?? 0) +
            (secondValuesOfIndicator.thirdQuarter ?? 0) +
            (secondValuesOfIndicator.fourthQuarter ?? 0);
        }

        const comparingResultOfIndicator = rigComparingResult?.[indicator];

        if (comparingResultOfIndicator) {
          const change = (secondValuesOfIndicator?.total ?? 0) - (firstValuesOfIndicator?.total ?? 0);

          comparingResultOfIndicator.total = {
            change,
            code: !!change ? ComparingSummary.ResultCode.changed : ComparingSummary.ResultCode.noChanges,
          };
        }
      }

      if (rawRigSummary?.first && rawRigSummary.second) {
        const rigSummaryResult = new ComparingRigSummaryResult(rigComparingResult);
        const comparingSummaryRow = new ComparingRigSummary(
          rigId,
          rawRigSummary.second,
          rigsSummaryChanges.get(rigId) ?? false,
          rigSummaryResult
        );
        const summaryRow = new MainComparingRigSummary(rigId, rawRigSummary.first, comparingSummaryRow);
        initializedItems.set(rigId, summaryRow);
      } else {
        initializedItems.set(rigId, null);
      }
    }

    return initializedItems;
  }
}

export namespace ComparingSummaryDataAdapter {
  export type TwoVersionsData<TValues> = { first: TValues; second: TValues };

  export type TwoVersionsValues = TwoVersionsData<Partial<Summary.Rows>>;

  export type PartialTwoVersionsValues = TwoVersionsData<
    Partial<Record<Summary.Indicator, Partial<Summary.RowValues>>>
  >;
}
