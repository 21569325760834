export class NeighborScheme<TNeighbor> {
  readonly left: NeighborScheme.NeighborInfo<TNeighbor>;
  readonly right: NeighborScheme.NeighborInfo<TNeighbor>;

  constructor(left: NeighborScheme.NeighborInfo<TNeighbor>, right: NeighborScheme.NeighborInfo<TNeighbor>) {
    this.left = left;
    this.right = right;
  }
}

export namespace NeighborScheme {
  export type NeighborInfo<TNeighbor> =
    | {
        distance: number;
        neighbor: TNeighbor;
      }
    | {
        neighbor: undefined;
      };
}
