import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Indicators } from 'src/api/chart/indicators-api';
import { IndicatorsSettings } from 'src/features/drilling-chart/presets/indicators-view-settings-sidebar/entities';

import { CollapseTable } from '../../../components/collapse-table/collapse-table';
import { useMovableContainer } from '../../../shared/use-movable-container';
import { Viewport } from '../../../shared/viewport/viewport';

import { IndicatorsColumn } from './indicators-column';
import { IndicatorsHeaderColumn } from './indicators-header-column';
import { IndicatorsTableFooter } from './indicators-table-footer';

import styles from './indicators-table.module.scss';

interface Props {
  data: Indicators.ViewIndicators | null;
  settings: IndicatorsSettings;
  viewport: Viewport;
  onPointerDown(): void;
  onPointerMove(offsetX: number, offsetY?: number): void;
  onPointerUp(): void;
  openViewSettings: VoidFunction;
  onZoom?(offset: number, centerPosition: number): void;
  className?: string;
}

export const IndicatorsTable = observer(function IndicatorsTable({
  data,
  settings,
  viewport,
  className,
  onPointerUp,
  onPointerMove,
  onPointerDown,
  onZoom,
  openViewSettings,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { containerRef, handlePointerDown, handlePointerMove, handlePointerUp, handleScroll } = useMovableContainer({
    horizontalViewport: viewport,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onZoom,
    onCancel: onPointerUp,
    onBlur: onPointerUp,
  });

  if (!data || !settings) {
    return null;
  }

  return (
    <CollapseTable isCollapsed={!isExpanded} className={className}>
      <div className={clsx(styles.table, !isExpanded && styles.tableCollapsed)}>
        <div
          ref={containerRef}
          className={styles.dataContainer}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          onWheel={handleScroll}
        >
          <div className={styles.data}>
            {data.items.map((item) => (
              <IndicatorsColumn
                key={`${item.start}-${item.end}`}
                item={item}
                horizontalViewport={viewport}
                isExpanded={isExpanded}
                settings={settings}
              />
            ))}
          </div>
        </div>

        <IndicatorsHeaderColumn isExpanded={isExpanded} indicatorsTotal={data.result} settings={settings} />

        <IndicatorsTableFooter
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          openViewSettings={openViewSettings}
        />
      </div>
    </CollapseTable>
  );
});
