import { action, makeObservable, observable, computed } from 'mobx';

import { PlanningViewType, PlanningGroupDataType, HeaderViewType, TableItemViewType } from 'src/api/planning/types';
import { Pad } from 'src/features/planning/store/pad';
import { SortingOptionType, FilterType } from 'src/pages/plan-page/types';
import { ColumnType } from 'src/store/table/types';

export class TableGroupStore {
  readonly indexKey: string;

  @observable loading: boolean = false;
  @observable editing: boolean = false;
  @observable headerView: HeaderViewType[] = [];
  @observable tableView: TableItemViewType[] = [];
  @observable dataSource: PlanningGroupDataType;
  @observable pads: Pad[] = [];
  @observable sorting: SortingOptionType | null = null;
  @observable filters: FilterType[] = [];
  @observable activePads: string[] = [];

  constructor(indexKey: string, view: PlanningViewType, dataSource: PlanningGroupDataType) {
    this.headerView = view.header;
    this.tableView = view.pad.tableItems;
    this.dataSource = dataSource;
    this.indexKey = indexKey;

    makeObservable(this);
  }

  @computed
  get title(): string {
    const title = this.headerView.find((headerItem) => headerItem.control === 'Title')?.attrName;

    return title ? String(this.dataSource[title]) : '';
  }

  @computed
  get subTitles(): string[] {
    const subTitles = this.headerView.filter((headerItem) => headerItem.control === 'Label');

    return subTitles.map((subTitle) => (this.dataSource[subTitle.attrName] as string) || '');
  }

  getGroupDataValue(dataKey: string): number | null {
    const groupId = this.dataSource[dataKey];

    return typeof groupId === 'number' ? groupId : null;
  }

  @action.bound
  openPad(padKey: string): void {
    this.activePads.push(padKey);
  }

  @action.bound
  closePad(padKey: string): void {
    this.activePads.remove(padKey);
  }

  @action.bound
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @action.bound
  setPads(pads: Pad[]): void {
    this.pads = pads;
  }

  @action.bound
  setTableColumns(tableView: ColumnType[]): void {
    this.pads.forEach((pad) => pad.setTableView(tableView));
  }

  @action.bound
  setSorting(sortingOption: SortingOptionType | null): void {
    this.sorting = sortingOption;
  }

  @action.bound
  setFilters(filters: FilterType[]): void {
    this.filters = filters;
  }

  @action.bound
  resetSorting(): void {
    this.sorting = null;
  }

  @action.bound
  resetFilters(): void {
    this.filters = [];
  }
}
