import { TFunction } from 'i18next';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { DashboardTrendResponse, GroupingValue, TrendTypeSettings, TrendTypeValue } from 'src/api/dashboard/types';
import { Range } from 'src/features/drilling-chart/layers/model';
import { TimeUnit } from 'src/features/drilling-chart/shared/time-unit';
import { hasValue } from 'src/shared/utils/common';
import { Directories } from 'src/store/directories/directories.store';
import { ThemeStore } from 'src/store/theme/theme-store';

import { ILabelResolver } from '../../utils/types';
import { ChartEntity } from '../chart/entity/chart.entity';

import { DashboardStore } from './dashboard.store';
import { ChartType, isChartType } from './types';

export const getPercentageValue = (value: number): number | null => {
  const result = Math.round(value * 100);
  if (isNaN(result)) return null;

  return result;
};

export const getPeriodRange = (period: TimeUnit): Range<number> => {
  switch (period) {
    case TimeUnit.month: {
      const start = moment().startOf('month').unix();
      const end = moment().endOf('month').unix();

      return { start, end };
    }
    case TimeUnit.quarter: {
      const start = moment().startOf('quarter').unix();
      const end = moment().endOf('quarter').unix();

      return { start, end };
    }
    case TimeUnit.year: {
      const start = moment().startOf('year').unix();
      const end = moment().endOf('year').unix();

      return { start, end };
    }
  }
};

export const getTimePeriods = (t: TFunction): { key: TimeUnit; title: string }[] => [
  { key: TimeUnit.month, title: t('dashboard:periods.month') },
  { key: TimeUnit.quarter, title: t('dashboard:periods.quarter') },
  { key: TimeUnit.year, title: t('dashboard:periods.year') },
];

export const validateChartType = (
  defaultChartType: ChartType,
  trendTypeSettings?: TrendTypeSettings,
  selectedGroup?: string
): ChartType => {
  if (trendTypeSettings?.chartType?.conditions) {
    const { conditions } = trendTypeSettings.chartType;

    for (const condition of conditions) {
      if (condition.grouping?.value?.length) {
        if (selectedGroup && condition.grouping.value.includes(selectedGroup)) {
          const currentTypeInAvailableTypes = condition.available.find((chartType) => chartType === defaultChartType);

          if (isChartType(currentTypeInAvailableTypes)) {
            return currentTypeInAvailableTypes;
          }

          for (const availableValue of condition.available) {
            if (isChartType(availableValue)) {
              return availableValue;
            }
          }
        }
      }
    }
  }

  if (trendTypeSettings?.chartType?.defaultAvailable) {
    const { defaultAvailable } = trendTypeSettings.chartType;
    const currentTypeInDefaultAvailableTypes = defaultAvailable.find((chartType) => chartType === defaultChartType);

    if (isChartType(currentTypeInDefaultAvailableTypes)) {
      return currentTypeInDefaultAvailableTypes;
    }

    for (const defaultAvailableValue of defaultAvailable) {
      if (isChartType(defaultAvailableValue)) {
        return defaultAvailableValue;
      }
    }
  }

  return defaultChartType;
};

export const mapTrendData = (
  rawData: DashboardTrendResponse,
  chartType: ChartType,
  planVersions: Record<string, string>,
  theme: ThemeStore,
  directories: Directories,
  chartNames: TrendTypeValue[],
  resolver?: ILabelResolver | null
): ChartEntity[] => {
  const chartData: ChartEntity[] = [];

  const availableChartNames = Object.keys(rawData);

  for (const chartView of chartNames) {
    const chartKey = chartView.value;

    if (!availableChartNames.includes(chartKey)) {
      continue;
    }

    const chartName = chartView.fieldId && directories.getFieldLabel(chartView.fieldId);

    const chartEntity = new ChartEntity({
      chartType: chartView.settings ? validateChartType(chartType, chartView.settings) : chartType,
      planVersions,
      resolver,
      rawData,
      name: chartName ?? chartKey,
      chartKey,
      id: uuidv4(),
      theme,
      trendTypeSettings: chartView.settings,
    });
    chartData.push(chartEntity);
  }

  return chartData;
};

export const mapGroupingValues = (
  grouping: GroupingValue[],
  getLabelByFieldId: (fieldId: string) => string | null
): DashboardStore.GroupingValueWithLabel[] => {
  return grouping.map(({ value, fieldId }) => {
    const label = getLabelByFieldId(fieldId);

    if (!hasValue(label)) {
      console.error(`Label for fieldId "${fieldId}" is undefined.`);
    }

    return {
      value,
      fieldId,
      label: label ?? fieldId,
    };
  });
};
