import { action, computed, makeObservable, observable } from 'mobx';

import { Range } from '../../layers/model';
import { Animator } from '../animator/animator';

export class Viewport {
  private minStart: number | null = null;
  private maxEnd: number | null = null;
  private _start: number;
  private _end: number;

  @observable private maxLength: number | null = null;

  readonly animationDisposers: VoidFunction[] = [];

  @observable start: number;
  @observable end: number;

  constructor(start: number, end: number, limits?: Range<number | undefined>, maxLength?: number) {
    this._start = start;
    this._end = end;

    const validRange = this.validateRange(start, end);
    this.start = validRange.start;
    this.end = validRange.end;

    this.minStart = limits?.start ?? null;
    this.maxEnd = limits?.end ?? null;
    this.maxLength = maxLength ?? null;

    makeObservable(this);
  }

  private validateRange = (start: number, end: number): Range<number> => {
    const minStart = this.minStart;
    const maxEnd = this.maxEnd;

    const rangeLength = this.maxLength ?? Math.abs(end - start);

    if (minStart !== null && start < minStart) {
      if (maxEnd === null) {
        return {
          start: minStart,
          end: minStart + rangeLength,
        };
      } else {
        const end = Math.min(minStart + rangeLength, maxEnd);

        return {
          start: Math.max(minStart, end - rangeLength),
          end,
        };
      }
    } else if (maxEnd !== null && end > maxEnd) {
      if (minStart === null) {
        return {
          start: maxEnd - rangeLength,
          end: maxEnd,
        };
      } else {
        return {
          start: Math.max(maxEnd - rangeLength, minStart),
          end: maxEnd,
        };
      }
    } else {
      return { start, end };
    }
  };

  @action.bound
  private setStart(value: number): void {
    this.start = value;
  }

  @action.bound
  private setEnd(value: number): void {
    this.end = value;
  }

  // Initialize viewport if you use 'animate' options in setRange method.
  init(): VoidFunction {
    const disposeAnimations = () => this.animationDisposers.forEach((callback) => callback());

    return () => {
      disposeAnimations();
    };
  }

  @computed
  get length(): number {
    return this.end - this.start;
  }

  @action.bound
  setRange(start: number, end: number, options?: Viewport.RangeSetterOptions): void {
    const validRange = this.validateRange(start, end);

    if (options?.animate) {
      this.animationDisposers.push(
        Animator.animateMultiple(
          [
            { start: this.start, end: validRange.start, onChange: this.setStart },
            { start: this.end, end: validRange.end, onChange: this.setEnd },
          ],
          undefined,
          { ...Viewport.AnimationOptions, ...options }
        )
      );
    } else {
      this.start = validRange.start;
      this.end = validRange.end;
    }
  }

  @action.bound
  setLimits(start: number | undefined | null, end: number | undefined | null): void {
    if (start !== undefined) {
      this.minStart = start;
    }

    if (end !== undefined) {
      this.maxEnd = end;
    }

    const validRange = this.validateRange(this.start, this.end);
    this.start = validRange.start;
    this.end = validRange.end;
  }

  @action.bound
  setMaxLength(length: number | null): void {
    this.maxLength = length;

    const end = this.start + (this.maxLength ?? 0);
    const start = this.end - (this.maxLength ?? 0);

    this.setRange(start, end);
  }

  @action.bound
  add(value: number): void {
    this.setRange(this.start + value, this.end + value);
  }
}

export namespace Viewport {
  export type RangeSetterOptions = Animator.AnimationOptions &
    Partial<{
      animate: boolean;
    }>;

  export const AnimationOptions: Animator.AnimationOptions = { duration: 250 };

  export const updateVerticalViewportLimits = (
    viewport: Viewport,
    allDataRange: Range<number> | null,
    dataViewHeight?: number
  ): void => {
    viewport.setLimits(0, Math.max(allDataRange?.end || 0, dataViewHeight || 0, viewport.end));

    if (dataViewHeight) {
      viewport.setMaxLength(dataViewHeight);
    }
  };

  export const moveViewportToPercentagePosition = (
    verticalViewport: Viewport,
    verticalDataRange: Range<number> | null,
    prevVerticalDataRange: Range<number> | null,
    dataViewHeight?: number
  ): void => {
    let newStart;
    let newEnd;

    // Calculate previous viewport position before updating viewport limits
    // because updating viewport limits can affect viewport position.
    if (verticalDataRange && prevVerticalDataRange) {
      const prevDataRangeLength = prevVerticalDataRange.end - prevVerticalDataRange.start;
      const dataRangeLength = verticalDataRange.end - verticalDataRange.start;
      const viewportLength = verticalViewport.end - verticalViewport.start;
      const verticalViewportPosition = verticalViewport.start / prevDataRangeLength || 0;

      newStart = dataRangeLength * verticalViewportPosition;
      newEnd = newStart + viewportLength;
    }

    // Then set viewport limits...
    Viewport.updateVerticalViewportLimits(verticalViewport, verticalDataRange, dataViewHeight);

    // ...and set right viewport position.
    if (newStart && newEnd) {
      verticalViewport.setRange(newStart, newEnd);
    }
  };
}
