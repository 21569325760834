import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

export const TimelineChart = observer(function TimelineChart({ children, className }: PropsWithChildren<Props>) {
  return <div className={clsx(className, styles.tableWrapper)}>{children}</div>;
});
