import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';
import { useIsOutOfViewport } from 'src/shared/hooks/use-is-out-of-viewport';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { PadCard } from '../../../pad-card';
import { PadRigOperation } from '../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useClickHandler } from '../../shared/use-click-handler';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';
import { ChartTooltip } from '../chart-tooltip/chart-tooltip';
import { useDragging } from '../sorting/hooks/use-dragging';

import { PadTooltipInner } from './pad-tooltip-inner';

import styles from './pad-rig-operation-wrapper.module.scss';

interface Props {
  item: PadRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onClick?: VoidFunction;
  className?: string;
  cardClassName?: string;
  headerClassName?: string;
}

export const DraggingPadRigOperationWrapper = observer(function DraggingPadRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  onClick,
  className,
  cardClassName,
  headerClassName,
  viewSettingsProvider,
}: Props) {
  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging'),
    item,
  });

  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });

  const { onPointerDown, onPointerUp } = useClickHandler(onClick);

  const isOutOfViewport = useIsOutOfViewport(item, horizontalViewport);

  const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

  const padAttributes = viewSettingsProvider.getPadAttributes(item);

  const padIcons = padAttributes?.length && (
    <div className={styles.iconsContainer}>
      {padAttributes?.map((attributeName) => (
        <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
      ))}
    </div>
  );

  return (
    <ChartTooltip
      content={
        <PadTooltipInner
          title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
          subTitle={viewSettingsProvider.getPadTooltipDate(item)}
          attributes={viewSettingsProvider.getPadTooltipAttributes(item)}
        />
      }
      placement={tooltipPlacement}
    >
      {({ handleMouseOver, handleMouseLeave }) => (
        <div ref={mergeRefs(ref, draggingRef)} className={clsx(className, styles.container)}>
          <PadCard
            title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
            extra={padIcons}
            className={classNames(cardClassName, styles.card)}
            attributes={viewSettingsProvider.getAttributes(item)}
            headerClassName={headerClassName}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      )}
    </ChartTooltip>
  );
});
