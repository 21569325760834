import { Progress } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './well-form-progress-bar.module.scss';

type Props = {
  useInProgressberFieldsCount: number;
  filledFieldsCount: number;
};

export const WellFormProgressBar = observer(function WellFormProgressBar({
  useInProgressberFieldsCount,
  filledFieldsCount,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Progress
        className={styles.progressBar}
        type="line"
        strokeColor="var(--green-color)"
        trailColor="var(--new-well-progress-bar-bg-color)"
        percent={(filledFieldsCount / useInProgressberFieldsCount) * 100}
        showInfo={false}
      />
      <p className={styles.text}>
        {t('newWellForm:Subheader.progressBar', {
          current: filledFieldsCount,
          target: useInProgressberFieldsCount,
        })}
      </p>
    </div>
  );
});
