import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react';

import { ModalCore } from 'src/shared/components/core/modal-core/modal-core';
import { Loader } from 'src/shared/components/loader';
import { checkIsNode } from 'src/shared/utils/check-is-node';

import styles from './modal.module.scss';

type Props = PropsWithChildren<{
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  isOpened: boolean;
  closeOnClickOutside?: boolean;
  title?: string | ReactNode;
  showCloseCross?: boolean;
  handleClose?: () => void;
  className?: string;
  loading?: boolean;
}>;

export const Modal = observer(function Modal({
  maxWidth,
  width,
  minWidth,
  top = 64,
  right,
  bottom,
  left,
  isOpened,
  closeOnClickOutside = true,
  title,
  showCloseCross = true,
  children,
  handleClose: onClose,
  className,
  loading,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!closeOnClickOutside || !isOpened || !onClose) return;

    const current = containerRef.current!;

    const handleClick = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (!current.contains(event.target)) onClose();
    };

    document.body.addEventListener('pointerdown', handleClick);

    return () => document.body.removeEventListener('pointerdown', handleClick);
  }, [isOpened, closeOnClickOutside, onClose]);

  return (
    <ModalCore isOpened={isOpened}>
      <div
        ref={containerRef}
        className={clsx(styles.wrapper, className)}
        style={{ width, maxWidth, minWidth, top, right, bottom, left }}
      >
        {!!title && typeof title === 'string' ? <h2 className={styles.title}>{title}</h2> : title}
        {!!onClose && showCloseCross && <button className={styles.closeButton} onClick={onClose} />}
        {children}

        <div className={clsx(styles.loaderContainer, loading && styles.loaderContainer_visible)}>
          <Loader className={styles.loader} />
        </div>
      </div>
    </ModalCore>
  );
});
