import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusInBox } from 'src/shared/assets/icons/plus-in-box.svg';
import { Button } from 'src/shared/components/button';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import styles from './well-form-geo-tasks-list.module.scss';

type Props = {
  wellFormManager: WellFormManagerWithGeoTasksHistory;
};

export const GeoTasksList = observer(function GeoTasksList({ wellFormManager }: Props) {
  const { t } = useTranslation();

  const handleChange = (id: number) => {
    wellFormManager.changeForm(id);
  };

  return (
    <div className={clsx(styles.wrapper)}>
      <SwitchButtonGroup
        value={wellFormManager.currentFormStore?.id}
        variant="underlined"
        defaultOptionKey={wellFormManager.formList[0]?.id ?? 0}
        items={wellFormManager.formList.map((wellForm, index) => ({
          key: wellForm.id,
          title: t('newWellForm:GeaoTasks.geotask', { index: index + 1 }),
          value: wellForm.id,
        }))}
        onChange={handleChange}
      />
      <Button
        variant="tertiary"
        width={24}
        height={24}
        icon={<PlusInBox />}
        onClick={wellFormManager.copyGeologicalTask}
      />
    </div>
  );
});
