import moment from 'moment';

import { tryGetCurrentTitle } from 'src/shared/components/table-cell/combo-box-table-cell/utils';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { hasValue } from 'src/shared/utils/common';
import { Directories } from 'src/store/directories/directories.store';
import { COLUMN_CONTROL, ColumnType, CellValueType } from 'src/store/table/types';

export function renderCellData(
  value: CellValueType,
  column: ColumnType,
  directories: Directories
): string | number | null {
  if (typeof value === 'boolean' || !hasValue(value)) {
    return null;
  }

  switch (column.control) {
    case COLUMN_CONTROL.DateOnlyPicker:
      return moment.unix(Number(value)).format(DATE_FORMAT);

    case COLUMN_CONTROL.ComboBox:
      const object = column.refQuery
        ? directories.getJoinedObjectDeprecated(column.refQuery)
        : directories.getObject(column.objectType);

      return tryGetCurrentTitle(String(value), column, object);

    default:
      // TODO Временная мера пока не появится спец контрол для multiComboBox
      return Array.isArray(value) ? value.join(', ') : value;
  }
}
