import { action, makeObservable, observable } from 'mobx';

import { IWell } from 'src/api/draft/types';
import { assert } from 'src/shared/utils/assert';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { PadTypes } from 'src/types/pad-types';
import { WellTypes } from 'src/types/well-types';

import { Well } from './well';

export class Pad {
  readonly item: PadTypes.RawPad;
  readonly id: number;

  @observable items: IWell[];

  constructor(item: PadTypes.RawPad, id: number, wells: Well[]) {
    this.item = item;
    this.id = id;
    this.items = wells;

    makeObservable(this);
  }

  getFieldValue(fieldName: string): unknown | undefined {
    if (fieldName === 'id') {
      return this.id;
    }

    if (fieldName in this.item.data) {
      return this.item.data[fieldName];
    }
  }

  @action.bound
  setChildren(wells: IWell[]) {
    this.items = wells;
  }

  @action.bound
  deleteChild(wellId: number) {
    const deletingWellIndex = this.items.findIndex((well) => well.id === wellId);

    if (deletingWellIndex !== -1) {
      this.items.splice(deletingWellIndex, 1);
    }
  }

  @action.bound
  addChild(well: WellTypes.RawRigOperation) {
    const idFieldName = addGOplanPrefix('PlanWellTriple.id');
    const idFieldValue = well[idFieldName];

    assert(typeof idFieldValue === 'number', `Invalid ID in well object with ID ${idFieldValue}.`);

    this.items.push(new Well(well, idFieldValue));
  }
}
