import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { assert } from 'src/shared/utils/assert';

import {
  PadRigOperation,
  RigsGroup,
  WellRigOperation,
  WellRigOperationCreator,
} from '../../features/drilling-chart/presets/drilling-rigs-chart/entities';

export class RigsChartDataAdapter {
  initializeWells(
    rawRigs: RigsChart.RawRigsGroupWithItems[],
    planVersionId: number,
    groupIds: number[],
    rigIds: number[],
    groupsWithRigs: RigsGroup[]
  ): Map<number, Map<number, PadRigOperation[]>> | undefined {
    const initializedRigsMap = new Map<number, Map<number, PadRigOperation[]>>();
    const groupsWithEmptyData = new Set<number>(groupIds);

    if (rawRigs.length) {
      for (const rigsGroup of rawRigs) {
        const initializedPadsMap = new Map<number, PadRigOperation[]>();
        const rigsWithEmptyData = new Set<number>(rigIds);

        for (const rig of rigsGroup.items) {
          const { id: rigId, items: pads } = rig;

          assert(typeof rigId === 'number');

          const parentRigsGroup = groupsWithRigs.find(({ id }) => id === rigsGroup.id);
          const parentRig = parentRigsGroup?.items.find(({ id }) => id === rigId);

          const initializedPads: PadRigOperation[] = [];

          if (parentRig) {
            for (const rawPad of pads) {
              const initializedWells: WellRigOperation[] = [];
              const initializedPad = new PadRigOperation(rawPad, parentRig);

              for (const rawWell of rawPad.items) {
                const chartWell = WellRigOperationCreator.create({
                  type: WellRigOperationCreator.WellType.well,
                  rawWell,
                  parentRig,
                  parentPad: initializedPad,
                });

                if (chartWell) {
                  initializedWells.push(chartWell);
                }
              }

              initializedPad.setItems(initializedWells);
              initializedPads.push(initializedPad);
            }
          }

          initializedPadsMap.set(rigId, initializedPads);
          rigsWithEmptyData.delete(rigId);
        }

        for (const rigId of rigsWithEmptyData) {
          initializedPadsMap.set(rigId, []);
        }

        initializedRigsMap.set(rigsGroup.id, initializedPadsMap);
        groupsWithEmptyData.delete(rigsGroup.id);
      }
    }

    for (const groupId of groupsWithEmptyData) {
      const emptyRigMap = new Map(rigIds.map((id) => [id, []]));

      initializedRigsMap.set(groupId, emptyRigMap);
    }

    return initializedRigsMap;
  }
}
