import { action, autorun, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { EditingStore } from 'src/store/editing/editing-store';

import { IChartDataModel, Range } from '../../../layers/model';
import {
  ChartWell,
  LoadingRigOperations,
  WellRigOperationStage,
  WellsGroup,
} from '../../../presets/drilling-wells-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';
import { ChartFiltersForm } from '../../../shared/filters-form.store';

import { IWellsChartDataApi, IWellsChartDataStorage } from './wells-chart-data.types';
import { WellsDataPositionsCalculator } from './wells-data-positions-calculator';

export class WellsChartDataModel implements IChartDataModel<WellsChartDataModel.ViewItemContent[] | null> {
  private readonly dataView: DataView;
  private readonly api: IWellsChartDataApi;
  private readonly storage: IWellsChartDataStorage;
  private readonly editing: EditingStore;

  @observable private verticalViewRange: Range<number>;
  @observable private horizontalViewRange: Range<number>;
  @observable private grouping?: string;
  @observable private filter?: ChartFiltersForm.FilterValues;

  constructor(
    initialVerticalViewRange: Range<number>,
    initialHorizontalViewRange: Range<number>,
    dataView: DataView,
    api: IWellsChartDataApi,
    storage: IWellsChartDataStorage,
    editing: EditingStore
  ) {
    this.verticalViewRange = initialVerticalViewRange;
    this.horizontalViewRange = initialHorizontalViewRange;
    this.dataView = dataView;
    this.api = api;
    this.storage = storage;
    this.editing = editing;

    makeObservable(this);
  }

  @flow.bound
  private async *loadStages(
    planVersionId: number,
    wellIds: number[],
    groupIds: number[],
    groupType: string,
    horizontalRange: Range<number>
  ) {
    const wellsWithStages = await this.api.getWellsStages(
      planVersionId,
      wellIds,
      groupIds,
      groupType,
      horizontalRange.start,
      horizontalRange.end,
      this.storage.wellsMap,
      this.filter
    );
    yield;

    this.storage.setWellStages(wellsWithStages, horizontalRange, this.dataView.type);
  }

  @computed
  get verticalDataRange(): Range<number> | null {
    return this.storage.allDataVerticalRange || null;
  }

  @computed({ equals: comparer.shallow })
  get data(): WellsChartDataModel.ViewItemContent[] | null {
    return this.storage.data;
  }

  @flow.bound
  async *deleteWell(planVersionId: number, well: ChartWell): Promise<void> {
    well.startDeleting();

    await this.api.deleteWell(planVersionId, well.id);
    yield;
  }

  @action.bound
  init() {
    const disposeStorage = this.storage.init?.();

    const disposePositionsCalculating = reaction(
      () => ({
        dataViewType: this.dataView.type,
      }),
      ({ dataViewType }) => {
        this.storage.normalizeData(dataViewType);
      },
      { equals: comparer.shallow, fireImmediately: true }
    );

    const disposeMissingDataProcess = autorun(() => {
      const planVersionId = this.editing.actualPlanVersionId;

      assert(hasValue(planVersionId), 'Invalid plan version ID.');

      const missingData = this.storage.missingDataBounds;

      if (missingData?.length && this.grouping) {
        for (const missingDataRange of missingData) {
          this.loadStages(
            planVersionId,
            [...missingDataRange.wellsIds],
            [...missingDataRange.groupIds],
            this.grouping,
            missingDataRange.horizontalRange
          );
        }
      }
    });

    const disposeVerticalRangeSetter = autorun(() => {
      this.storage.setVerticalViewRange(this.verticalViewRange);
    });

    const disposeHorizontalRangeSetter = autorun(() => {
      this.storage.setHorizontalViewRange(this.horizontalViewRange);
    });

    return () => {
      disposePositionsCalculating();
      disposeMissingDataProcess();
      disposeVerticalRangeSetter();
      disposeHorizontalRangeSetter();
      disposeStorage?.();
    };
  }

  @action.bound
  setVerticalRange(start: number, end: number): void {
    this.verticalViewRange = {
      start,
      end,
    };
  }

  @action.bound
  setHorizontalRange(start: number, end: number): void {
    this.horizontalViewRange = {
      start,
      end,
    };
  }

  @action.bound
  setWellsGroups(wellsGroups: WellsGroup[] | null): void {
    this.storage.setWellsGroups(wellsGroups);
  }

  @action.bound
  setShownStageAttributesNumber(shownStageAttributeNumber: number): void {
    this.storage.setShownStageAttributesNumber(shownStageAttributeNumber);
  }

  @action.bound
  recalculatePositions() {
    this.storage.normalizeData(this.dataView.type);
  }

  /**
   * Set settings that do not depend on the user and can be changed when the mode is changed.
   * For example, elements sizes. */
  @action.bound
  setChartSettings(settings: WellsChartDataModel.ChartSettings): void {
    this.storage.setPositionsCalculator(new WellsDataPositionsCalculator(settings.calculation));
  }

  @action.bound
  setFiltersAndGrouping(filter: ChartFiltersForm.FilterValues, grouping: string): void {
    this.grouping = grouping;
    this.filter = filter;
  }

  @computed
  get wellGroups(): WellsGroup[] | undefined {
    return this.storage.wellsGroups;
  }
}

export namespace WellsChartDataModel {
  export type ViewItemContent = WellsGroup | ChartWell | WellRigOperationStage | LoadingRigOperations;

  export interface IRigsChartElementSizesGetters {
    wellsGroup(): number;
    wellCompact(): number;
    wellFullMin(): number;
    drillingStageCard(attributesCount: number): number;
    rowPadding(): number;
    rowHeight(drillingStepFieldsCount: number): number;
    wellsGroupsMargin(): number;
  }

  export type ChartSettings = {
    calculation: IRigsChartElementSizesGetters;
  };
}
