import { RegularComboBox } from 'src/shared/entities/control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TRegularComboboxRaw, TSavedFormValue } from '../../types';

type TComboboxesSerializerOptions = {
  item: TRegularComboboxRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

const isValidInitialValue = (value: unknown): value is number => {
  return typeof value === 'number' || !isNaN(Number(value));
};

export const mapComboBoxItem = ({ item, savedValue, directories }: TComboboxesSerializerOptions): RegularComboBox => {
  const data = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    useInMainProgressBar: !!item?.useInMainProgressBar,
    required: !!item?.required,
    refObjectAttr: item?.refObjectAttr,
    refObjectType: item?.refObjectType,
    validate: item.validate,
    validationTags: item.validationTags,
    calculatedValue: item.calculatedValue,
    onChangeInstructions: item.onChange,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    directories: directories,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    refObjectFilterByFields: item.refObjectFilterByFields,
    initialValue: isValidInitialValue(savedValue) ? Number(savedValue) : undefined,
    restrictions: { required: !!item.required },
    directory: item.refQuery ? directories.getJoinedObject(item.refQuery) : directories.getObject(item.refObjectType),
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    nullValue: directories.getFieldNullValue(item.fieldId) || undefined,
    refQuery: item.refQuery,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    attrConcat: item.attrConcat,
    computeTags: item.computeTags,
    delimiter: item.delimiter,
    attrConcatRefs: item.attrConcatRefs,
  };

  return new RegularComboBox(data);
};
