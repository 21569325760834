import { observer } from 'mobx-react-lite';

import { Item } from '../../shared/entities/abstract-control-entities';
import {
  TextField,
  NumberField,
  IntegerField,
  RegularComboBox,
  Switcher,
  Label,
  MultiCombobox,
  DateIntervalField,
  TextArea,
} from '../../shared/entities/control-entities';

import { FormStore } from './entities/form.entity';
import {
  NewWellTextField,
  NewWellNumberField,
  NewWellRegularComboBox,
  NewWellSwitcherFeature,
  NewWellLabelControl,
  NewWellMulticombobox,
  NewWellIntervalDateField,
} from './form-controls';
import { NewWellIntegerField } from './form-controls/new-well-integer-field/new-well-integer-field';
import { NewWellTextArea } from './form-controls/new-well-text-area/new-well-text-area';

type Props = {
  className?: string;
  item: Item;
  label?: string;
  formStore: FormStore;
  isDisabled?: boolean;
  directoryName?: string;
};

export const FormElement = observer(function FormElement({ className, item, label, formStore }: Props) {
  const isControlInactive = item.isDisabled;
  const isControlVisuallyDisabled = item.isVisuallyDisabled;
  const isControlDisabled = isControlVisuallyDisabled || isControlInactive;

  const onBlur = () => {
    formStore.validationManager.validateControl(item);
  };

  const validateOnChange = () => {
    formStore.validationManager.validateControl(item);
  };

  return (
    <>
      {item instanceof TextField && (
        <NewWellTextField
          className={className}
          item={item}
          isDisabled={isControlDisabled}
          label={label}
          onBlur={onBlur}
        />
      )}
      {item instanceof TextArea && (
        <NewWellTextArea
          className={className}
          item={item}
          isDisabled={isControlDisabled}
          label={label}
          onBlur={onBlur}
        />
      )}
      {item instanceof NumberField && (
        <NewWellNumberField
          className={className}
          item={item}
          isDisabled={isControlDisabled}
          label={label}
          onBlur={onBlur}
        />
      )}
      {item instanceof IntegerField && (
        <NewWellIntegerField
          className={className}
          item={item}
          isDisabled={isControlDisabled}
          label={label}
          onBlur={onBlur}
        />
      )}
      {item instanceof RegularComboBox && (
        <>
          <NewWellRegularComboBox
            className={className}
            item={item}
            isDisabled={isControlDisabled}
            label={label}
            onBlur={onBlur}
            onChange={validateOnChange}
          />
        </>
      )}
      {item instanceof MultiCombobox && (
        <>
          <NewWellMulticombobox
            className={className}
            item={item}
            isDisabled={isControlDisabled}
            label={label}
            onBlur={onBlur}
            onChange={validateOnChange}
          />
        </>
      )}
      {item instanceof DateIntervalField && <NewWellIntervalDateField item={item} isDisabled={isControlDisabled} />}
      {item instanceof Switcher && <NewWellSwitcherFeature item={item} isDisabled={isControlDisabled} />}
      {item instanceof Label && <NewWellLabelControl item={item} />}
    </>
  );
});
