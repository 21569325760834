import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { FormDatePicker } from 'src/shared/components/form-components';

import styles from './work-start-date-picker.module.scss';

type Props = {
  value: Moment | null;
  onChange: (value: Moment | null) => void;
};

export const WorkStartDatePicker = observer(function WorkStartDatePicker({ value, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <FormDatePicker
      withTime
      allowClear
      className={styles.picker}
      value={value}
      placeholder={t('drawers:addToChart.startDatePlaceholder')}
      label={t('drawers:addToChart.startDateLabel')}
      onChange={onChange}
    />
  );
});
