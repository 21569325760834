export const getSizeString = (size?: number | string) => {
  if (!size && size !== 0) {
    return '';
  }

  if (typeof size === 'number') {
    return `${size}%`;
  }

  return size;
};
