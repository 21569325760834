import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import { MatchedItemType, MatchingImportStore } from '../../import-matching-page.store';
import { MatchingItem } from '../matching-item';
import { DraggableMatchingItem } from '../matching-item/draggable-matching-item';

import { Icons } from './icons';

import styles from './matching-row.module.scss';

type Props = {
  item: MatchedItemType;
  matchingImport: MatchingImportStore;
};

export const MatchingRow = observer(function MatchingRow({ item, matchingImport }: Props) {
  const { directories } = useStore();

  const value = matchingImport.data.find((dataItem) => dataItem.name === item.name)?.value;

  const { isOver, setNodeRef } = useDroppable({
    id: item.name,
    data: {
      header: value,
    },
  });

  return (
    <div ref={setNodeRef} className={clsx(styles.matchingRow)}>
      <div className={styles.systemDataFieldWrapper}>
        <MatchingItem
          className={styles.systemItem}
          name={directories.getFieldLabel(item.name) || item.name}
          icon={<Icons isEmpty={!value} isOver={isOver} />}
        />
      </div>
      <DraggableMatchingItem isOver={isOver} isEmpty={!value} name={item.name} header={value || null} />
    </div>
  );
});
