import { autorun } from 'mobx';

import { Item } from '../../../shared/entities/abstract-control-entities';
import { RegularComboBox } from '../../../shared/entities/control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class FilterRegularComboboxOptionsPlugin extends FormPlugin {
  isFilterValueValid(value: unknown): value is string | number | boolean | null {
    if (['string', 'number', 'boolean'].includes(typeof value) || value === null) {
      return true;
    }
    return false;
  }

  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item instanceof RegularComboBox && item.refObjectFilterByFields) {
        const disposer = autorun(() => {
          const filterValues: { [key: string]: string | number | boolean | null } = {};

          for (const key in item.refObjectFilterByFields) {
            const filterValue = form.fields[item.refObjectFilterByFields[key]].value;
            if (this.isFilterValueValid(filterValue)) {
              filterValues[key] = filterValue;
            }
          }
          item.setFilteringValues(filterValues);
        });

        disposers.push(disposer);
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
