import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';

import { ReactComponent as HideIcon } from 'src/shared/assets/icons/closed-eye.svg';
import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/dots.svg';
import { ReactComponent as ShowIcon } from 'src/shared/assets/icons/eye.svg';

import styles from './collapse-handlers.module.scss';

interface Props {
  groupId: string;
  dragListeners?: import('@dnd-kit/core/dist/hooks/utilities').SyntheticListenerMap;
  isShown?: boolean;
  setIsShownColumnsGroup: (groupId: string, isShown: boolean) => void;
}

export const CollapseHandlers = observer(function CollapseHandlers({
  groupId,
  dragListeners,
  isShown,
  setIsShownColumnsGroup,
}: Props) {
  function handleChange(isShown: boolean): void {
    setIsShownColumnsGroup(groupId, isShown);
  }

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <MoveIcon className={styles.dragHandler} {...dragListeners} />
      <Switch
        checkedChildren={<ShowIcon />}
        unCheckedChildren={<HideIcon />}
        checked={isShown}
        onChange={handleChange}
        className={styles.showHandler}
      />
    </div>
  );
});
