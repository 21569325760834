import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { CollapseHandlers } from 'src/features/wells-list/view-settings-sidebar/columns-group/collapse-handlers';
import { CollapseHeader } from 'src/features/wells-list/view-settings-sidebar/columns-group/collapse-header';
import { SettingField } from 'src/features/wells-list/view-settings-sidebar/columns-group/setting-field';
import { SidebarSettingsColumnType } from 'src/features/wells-list/view-settings-sidebar/types';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';

import styles from './columns-group.module.scss';

interface Props {
  items: SidebarSettingsColumnType[];
  groupId: string;
  label: string;
  isSingleGroup: boolean;
  isWrapped: boolean;
  isDragging?: boolean;
  isShown?: boolean;
  isFixed?: boolean;
  onDragEnd(event: DragEndEvent): void;
  setIsShownColumn(groupId: string, columnId: string, isShown: boolean): void;
  setIsFixedColumn(groupId: string, columnId: string): void;
  setIsFixedColumnsGroup(groupId: string, isFixed: boolean): void;
  setIsShownColumnsGroup(groupId: string, isShown: boolean): void;
}

export const ColumnsGroup = observer(function ColumnsGroup({
  onDragEnd,
  items,
  groupId,
  setIsShownColumn,
  setIsFixedColumn,
  isSingleGroup,
  isDragging,
  isWrapped,
  isShown,
  isFixed,
  setIsFixedColumnsGroup,
  setIsShownColumnsGroup,
  label,
}: Props) {
  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: groupId,
    data: {
      isGroup: true,
    },
  });

  const [activeKey, setActiveKey] = useState<string[] | string>([groupId]);

  function handleChange(activeKey: string[] | string) {
    !isSingleGroup && setActiveKey(activeKey);
  }

  return (
    <div ref={setNodeRef} style={{ transform: `translateY(${transform?.y || 0}px)`, transition }} {...attributes}>
      <Collapse
        className={styles.collapse}
        onChange={handleChange}
        activeKey={isDragging ? [] : activeKey}
        destroyInactivePanel
      >
        <Panel
          key={groupId}
          showArrow={!isSingleGroup}
          header={
            !isSingleGroup && (
              <CollapseHandlers
                groupId={groupId}
                dragListeners={listeners}
                setIsShownColumnsGroup={setIsShownColumnsGroup}
                isShown={isShown && items.some((column) => column.isShown)}
              />
            )
          }
          extra={
            <CollapseHeader
              title={label}
              isWrapped={isWrapped}
              isFixed={isFixed}
              setIsFixed={setIsFixedColumnsGroup}
              groupId={groupId}
            />
          }
        >
          <div className={styles.settingGroup}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={onDragEnd}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items
                  .filter((column) => !column.excludeFromSettings)
                  .map((column) => (
                    <SettingField
                      label={column.label}
                      columnName={column.id}
                      id={column.id}
                      key={`${column.label}_${column.id}`}
                      isShown={column.isShown}
                      isFixed={column.isFixed}
                      setIsShown={setIsShownColumn}
                      setIsFixed={setIsFixedColumn}
                      groupId={groupId}
                      isChildColumn={isWrapped}
                    />
                  ))}
              </SortableContext>
            </DndContext>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
});
