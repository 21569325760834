import { action, makeObservable, observable } from 'mobx';

import { DEFAULT_WELL_SIDEBAR_TAB } from '../../well-info-sidebar/well-info-sidebar';
import { WellInfoSidebar } from '../../well-info-sidebar/well-info-sidebar.store';

import { ModalStore } from './modal.store';

export class WellsChartSidebarsStore {
  readonly wellInfo = new ModalStore();
  readonly addWell = new ModalStore();

  @observable selectedWellId: number | null = null;
  @observable initialWellSidebarTab: WellInfoSidebar.Tabs = DEFAULT_WELL_SIDEBAR_TAB;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  onWellInfoOpen(wellId: number, tab = WellInfoSidebar.Tabs.mainInfo): void {
    this.selectedWellId = wellId;
    this.initialWellSidebarTab = tab;

    this.wellInfo.open();
  }

  @action.bound
  onWellInfoClose(): void {
    this.selectedWellId = null;
    this.initialWellSidebarTab = DEFAULT_WELL_SIDEBAR_TAB;

    this.wellInfo.close();
  }

  @action.bound
  onWellAdd(): void {
    this.addWell.open();
  }

  @action.bound
  onAddWellSidebarClose(): void {
    this.addWell.close();
  }
}
