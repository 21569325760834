import { agent } from 'src/api/agent';
import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { RigsChartDataAdapter } from 'src/api/chart/rigs-chart-data-adapter';
import { throwApiError } from 'src/api/utils';
import { BaseApiError } from 'src/errors';
import { RawWell } from 'src/features/drilling-chart/types';
import { RootStore } from 'src/store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { IRigsChartDataApi } from '../../features/drilling-chart/features/rigs-chart/data/rigs-chart-data.types';
import { PadRigOperation, RigsGroup } from '../../features/drilling-chart/presets/drilling-rigs-chart/entities';

export class RigsChartDataApi implements IRigsChartDataApi {
  private readonly i18: I18NextStore;
  private readonly notifications: NotificationsStore;

  private adapter = new RigsChartDataAdapter();

  constructor(rootStore: RootStore) {
    this.i18 = rootStore.i18;
    this.notifications = rootStore.notifications;
  }

  async removeRigOperation(
    planVersionId: number,
    tripleId: number,
    forceDelete?: boolean
  ): Promise<RigsChartDataTypes.RemoveResponse> {
    try {
      const { data } = await agent.delete<RigsChartDataTypes.RemoveResponse>('compute/rigOperation', {
        params: { planVersionId, tripleId, forceDelete },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async removeRigOperationList(
    planVersionId: number,
    tripleId: number,
    forceDelete?: boolean
  ): Promise<RigsChartDataTypes.RemoveResponse> {
    try {
      const { data } = await agent.delete<RigsChartDataTypes.RemoveResponse>('compute/rigOperation/list', {
        params: { planVersionId, tripleId, forceDelete },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async removePad(
    planVersionId: number,
    padId: number,
    forceDelete?: boolean
  ): Promise<RigsChartDataTypes.RemoveResponse> {
    try {
      const { data } = await agent.delete<RigsChartDataTypes.RemoveResponse>('compute/pad', {
        params: { planVersionId, padId, forceDelete },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  /** Get pads with wells. */
  async getWells(
    planVersionId: number,
    startDate: number,
    endDate: number,
    rigIds: number[],
    groupType: string,
    groupIds: number[],
    groupsWithRigs: RigsGroup[],
    filter: Record<string, unknown> = {}
  ): Promise<Map<number, Map<number, PadRigOperation[]>> | undefined> {
    if (rigIds.length) {
      let rawRigs: RigsChart.RawRigsGroupWithItems[] = [];

      try {
        const { data } = await agent.post<RigsChart.RawRigsGroupWithItems[]>('graph/rig/rigOperation/list/', filter, {
          params: {
            planVersionId,
            startDate,
            endDate,
            groupType,
            groupIdList: groupIds.join(),
            rigIdList: rigIds.join(),
          },
        });

        rawRigs = data;
      } catch (e) {
        console.error(e);
        if (e instanceof BaseApiError && e.responseMessage) {
          this.notifications.showErrorMessage(e.responseMessage);
          return;
        }
        this.notifications.showErrorMessageT('errors:failedToLoadData');
      }

      if (rawRigs) {
        return this.adapter.initializeWells(rawRigs, planVersionId, groupIds, rigIds, groupsWithRigs);
      }
    }
  }
  async changeRigOperationsOrder(
    planVersionId: number,
    rigId: number,
    insertion: number,
    insertAfter?: number,
    insertOnPlace?: number,
    startDate?: number
  ): Promise<RawWell | undefined> {
    try {
      const { data } = await agent.post<RawWell | undefined>('compute/rigOperation', {
        planVersionId,
        rigId,
        insert: insertion,
        insertAfter,
        insertOnPlace,
        startDate,
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async changePadsOrder(
    planVersionId: number,
    rigId: number,
    insertion: number,
    insertAfter?: number | undefined,
    insertOnPlace?: number | undefined,
    startDate?: number
  ): Promise<RawWell | undefined> {
    try {
      const { data } = await agent.post<RawWell | undefined>('compute/pad', {
        planVersionId,
        rigId,
        insert: insertion,
        insertAfter,
        insertOnPlace,
        startDate,
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async changeRigOperationsListOrder(
    planVersionId: number,
    rigId: number,
    insertion: number,
    insertAfter?: number | undefined,
    insertOnPlace?: number | undefined,
    startDate?: number
  ): Promise<RawWell | undefined> {
    try {
      const { data } = await agent.post<RawWell | undefined>('compute/rigOperation/list', {
        planVersionId,
        rigId,
        insert: insertion,
        insertAfter,
        insertOnPlace,
        startDate,
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace RigsChartDataTypes {
  export type RemoveResponse = {
    deletingRigOperationList: RawWell[] | null;
    affectedRigOperationList: RawWell[] | null;
    conflictList: RawWell[] | null;
    needConfirmation: boolean;
  };
}
