import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { AddWellToChartSubheader } from './add-well-to-chart-subheader/add-well-to-chart-subheader';
import { WorkStartDatePicker } from './start-date-picker';

import styles from './add-well-to-unplanned-rig-sidebar.module.scss';

type Props = {
  title?: string | null;
  isOpened: boolean;
  datePickerValue: Moment | null;
  onWorkStartChange(e: Moment | null): void;
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
  onCloseSidebar: VoidFunction;
};

export const AddWellInUnplannedRigSidebar = observer(function AddWellInUnplannedRigSidebar({
  title,
  isOpened,
  datePickerValue,
  onWorkStartChange,
  onSuccess,
  onCancel,
  onCloseSidebar,
}: Props) {
  const { t } = useTranslation();

  return (
    <Sidebar
      isOpened={isOpened}
      subheader={
        <AddWellToChartSubheader isSuccessDisabled={!datePickerValue} onSuccess={onSuccess} onCancel={onCancel} />
      }
      onClose={onCloseSidebar}
      closeOnClickOutside
      title={title || t('drawers:addPadDrawer.padOrWell')}
      description={t('drawers:addToChart.descriptionWithoutWells')}
      size="l"
    >
      <div className={styles.contentContainer}>
        <WorkStartDatePicker value={datePickerValue} onChange={onWorkStartChange} />
      </div>
    </Sidebar>
  );
});
