import { action, makeObservable, observable } from 'mobx';

import { ValidationError } from 'src/errors';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { RootStore } from 'src/store';

import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class MakeWellIsPlannedPlugin extends FormPlugin {
  @observable isRequiredFilledApproach = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  connect(form: FormStore): void | VoidFunction {
    form.validationManager.addExtraFormValidation({
      id: getRandomNumber(),
      validate: () => {
        if (!this.isRequiredFilledApproach) {
          return;
        }
        if (!form.approachesTab?.approachesList.approaches.length) {
          throw new ValidationError(this.rootStore?.i18.t('newWellForm:Errors.requiredApproach'));
        }

        const isValidApproachPresented = !!form.approachesTab?.approachesList.approaches.some((approach) => {
          const isRigSelected = !!approach.fieldsList.find((field) => field.fieldId === 'rig')?.value;
          const isBrigadeSelected = !!approach.fieldsList.find((field) => field.fieldId === 'brigade')?.value;
          return (
            isRigSelected &&
            isBrigadeSelected &&
            approach.stagesList.stages.some((stage) => {
              return stage.dateField?.startDate && stage.dateField?.endDate;
            })
          );
        });

        if (!isValidApproachPresented) {
          throw new ValidationError(this.rootStore?.i18.t('errors:requiredValidApproach'));
        }
      },
    });
  }

  @action.bound
  setIsRequiredFilledApproach(is: boolean) {
    this.isRequiredFilledApproach = is;
  }
}
