import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { WellInfoSidebar } from 'src/features/well-info-sidebar';
import { WellInfoSidebar as WellInfoSidebarStore } from 'src/features/well-info-sidebar/well-info-sidebar.store';
import { Loader } from 'src/shared/components/loader';
import { useStore } from 'src/store';

import { DataContainer } from '../../components/data-container/data-container';
import { TimelineChart } from '../../components/timeline-chart/timeline-chart';
import { ChartSearch } from '../../features/chart-search';
import { ComparingIndicatorsTable } from '../../features/comparing-indicators-table/view/comparing-indicators-table';
import { ComparingWellsDataHeaders } from '../../features/data-headers/view/comparing-wells-data-headers';
import { ComparingWellsDataItemsBackground } from '../../features/data-items-background/view/comparing-wells-data-items-background';
import { ComparingWellsDataItemsEmpty } from '../../features/data-items-empty/view/comparing-wells-data-items-empty';
import { ComparingWellsDataItemsFull } from '../../features/data-items-full/view/comparing-wells-data-items-full';
import { FiltersModal } from '../../features/filters-modal';
import { FullScreenButtons } from '../../features/full-screen-buttons';
import { HeaderPresenter } from '../../features/header/presenter/header-presenter';
import { Header } from '../../features/header/view/header';
import { DataView } from '../../shared/data-view/data-view';
import { Viewport } from '../../shared/viewport/viewport';
import { WellsChartSidebarsStore } from '../../shared/wells-chart-sidebars.store';
import { IndicatorsSettings } from '../indicators-view-settings-sidebar/entities';

import { ComparingWellsChartStore } from './comparing-wells-chart.store';

import styles from './comparing-drilling-wells-chart.module.scss';

interface Props {
  chartStore: ComparingWellsChartStore;
  horizontalViewport: Viewport;
  headerPresenter: HeaderPresenter;
  containerWidth?: number;
  containerHeight?: number;
  indicatorsSettings?: IndicatorsSettings;
  isFullScreen?: boolean;
  onFullScreenExit?: VoidFunction;
}

export const ComparingWellsChart = observer(function ComparingDrillingWellsChart({
  chartStore,
  horizontalViewport,
  headerPresenter,
  indicatorsSettings,
  isFullScreen,
  onFullScreenExit,
  containerWidth,
  containerHeight,
}: Props) {
  const {
    verticalViewport,
    dataItemsBackgroundPresenter,
    dataHeadersPresenter,
    dataItemsFullPresenter,
    dataItemsEmptyPresenter,
    dataView,
    filtersForm,
    isDataUpdating,
    viewSettings,
    hasSelectedFilters,
    indicators,
  } = chartStore;

  const [sidebars] = useState(() => new WellsChartSidebarsStore());

  const { notifications } = useStore();

  const isCompactView =
    dataView.type === DataView.DataViewType.compact || dataView.type === DataView.DataViewType.empty;

  const onWellStageClick = useCallback(
    (wellId: number) => sidebars.onWellInfoOpen(wellId, WellInfoSidebarStore.Tabs.stages),
    [sidebars]
  );

  return (
    <div className={styles.chartWrapper}>
      {isDataUpdating && (
        <div className={styles.dataUpdatingLoaderContainer}>
          <Loader className={styles.dataUpdatingLoader} />
        </div>
      )}

      <TimelineChart className={styles.chart}>
        <DataContainer
          className={styles.chartDataContainer}
          horizontalViewport={horizontalViewport}
          verticalViewport={verticalViewport}
          onPointerDown={dataItemsBackgroundPresenter.onPointerDown}
          onPointerMove={dataItemsBackgroundPresenter.onPointerMove}
          onPointerUp={dataItemsBackgroundPresenter.onPointerUp}
          onScroll={dataItemsBackgroundPresenter.onScroll}
          onZoom={dataItemsBackgroundPresenter.onZoom}
          onZoomOut={dataItemsBackgroundPresenter.onZoomOut}
        >
          {!!dataItemsBackgroundPresenter.data && (
            <ComparingWellsDataItemsBackground
              data={dataItemsBackgroundPresenter.data}
              verticalViewport={verticalViewport}
            />
          )}

          {!isCompactView && dataItemsFullPresenter.data && (
            <ComparingWellsDataItemsFull
              data={dataItemsFullPresenter.data}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewProvider={viewSettings.provider}
              onWellStageClick={onWellStageClick}
            />
          )}

          {isCompactView && dataItemsEmptyPresenter.data && (
            <ComparingWellsDataItemsEmpty
              data={dataItemsEmptyPresenter.data}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewProvider={viewSettings.provider}
              onWellStageClick={onWellStageClick}
            />
          )}
        </DataContainer>

        {!!dataHeadersPresenter.data && (
          <ComparingWellsDataHeaders
            data={dataHeadersPresenter.data}
            viewport={verticalViewport}
            viewProvider={viewSettings.provider}
            className={styles.chartDataHeaders}
            isCompactView={isCompactView}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            onScroll={dataHeadersPresenter.onScroll}
            onHeightChange={dataHeadersPresenter.onHeightChange}
            onIsCollapseToggle={dataHeadersPresenter.onGroupCollapseToggle}
          />
        )}

        <Header
          data={headerPresenter.data}
          viewport={horizontalViewport}
          className={styles.chartHeader}
          onPointerDown={headerPresenter.onPointerDown}
          onPointerMove={headerPresenter.onPointerMove}
          onPointerUp={headerPresenter.onPointerUp}
          onZoom={headerPresenter.onZoom}
          onZoomOut={headerPresenter.onZoomOut}
        />

        <ChartSearch
          className={styles.chartSearch}
          value={chartStore.searchTerm}
          onChange={chartStore.onSearchChange}
          onFiltersButtonClick={
            filtersForm?.onOpen || (() => notifications.showErrorMessageT('errors:failedToLoadFilters'))
          }
          highlightFiltersButton={hasSelectedFilters}
        />

        {!!indicators && (
          <ComparingIndicatorsTable
            data={indicators.presenter.data}
            viewport={horizontalViewport}
            indicatorsSettings={indicatorsSettings}
            className={styles.chartIndicators}
            onPointerDown={indicators.presenter.onPointerDown}
            onPointerMove={indicators.presenter.onPointerMove}
            onPointerUp={indicators.presenter.onPointerDown}
          />
        )}

        {isFullScreen && onFullScreenExit && (
          <div className={styles.chartFullScreenButtons}>
            <FullScreenButtons onFullScreenExit={onFullScreenExit} />
          </div>
        )}
      </TimelineChart>

      {!!filtersForm && <FiltersModal formStore={filtersForm} className={styles.filtersModal} />}

      {sidebars.selectedWellId && (
        <WellInfoSidebar
          key={sidebars.selectedWellId}
          wellId={sidebars.selectedWellId}
          isOpen={sidebars.wellInfo.isOpen}
          onClose={sidebars.onWellInfoClose}
          initialActiveTab={sidebars.initialWellSidebarTab}
          disableEditing={false}
        />
      )}
    </div>
  );
});
