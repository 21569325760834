import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import {
  getRowProgressPercent,
  renderCellData,
  isValidNumberValue,
  isValidStringValue,
  isDisabledCell,
  getNumberValueWithCertainNumberOfDecimalPlaces,
} from 'src/features/wells-list/wells-table/table-cell/utils';
import { PAGE_MODE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ComboBoxTableCell } from 'src/shared/components/table-cell/combo-box-table-cell';
import { CompareTableCell } from 'src/shared/components/table-cell/compare-table-cell';
import { DateTableCell } from 'src/shared/components/table-cell/date-table-cell';
import { EllipsisTableCell } from 'src/shared/components/table-cell/ellipsis-table-cell';
import { MultiComboBoxTableCell } from 'src/shared/components/table-cell/multi-combo-box-table-cell';
import { ProgressTableCell } from 'src/shared/components/table-cell/progress-table-cell';
import { TextTableCell } from 'src/shared/components/table-cell/text-table-cell';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import {
  CellValueType,
  CHANGED_VARIANT,
  ColumnType,
  COLUMN_CONTROL,
  COLUMN_TYPE,
  TableRow,
} from 'src/store/table/types';
import { getCellValue } from 'src/store/table/utils';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import styles from './table-cell.module.scss';

interface Props {
  row: TableRow;
  rowIndex: number;
  column: ColumnType | Omit<ColumnType, 'width'>;
  pageStore: WellsPageStore;
  wellFormManager: WellFormManagerWithGeoTasksHistory;
}

export const TableCell = observer(function TableCell({ row, rowIndex, column, pageStore, wellFormManager }: Props) {
  const cell = row[column.name];

  const isEditing = pageStore.mode === PAGE_MODE.edit;

  const isCompareCell = isObjectWithKeys(cell) && 'first' in cell && 'second' in cell;

  const isCellError = isObjectWithKeys(cell) && 'isError' in cell && cell.isError;

  const isDisabled = isDisabledCell(column, row, pageStore.table.isValidationError, isEditing);

  const object = column.refQuery
    ? pageStore.directories.getJoinedObjectDeprecated(column.refQuery)
    : pageStore.directories.getObject(column.objectType);

  const cellStyle = clsx(styles.cellWrapper, {
    [styles.cellWrapperError]: isCellError,
    [styles.cellWrapperCompare]: isCompareCell && row.rowChanged === CHANGED_VARIANT.changed,
    [styles.cellWrapperDisabled]: isDisabled,
  });

  function onEditCellValue(value: CellValueType): void {
    const cellValue = renderCellData(value, column, object);

    if (column.type === COLUMN_TYPE.Number) {
      const numberValue = hasValue(value) ? getNumberValueWithCertainNumberOfDecimalPlaces(value, column) : null;

      if (isValidNumberValue(cellValue, pageStore.notifications, column.restrictions)) {
        pageStore.editTableData(rowIndex, column.name, numberValue, column, column.isMassEditable);
        pageStore.table.isValidationError && pageStore.table.setIsValidationError(false);
        return;
      }

      pageStore.table.setInvalidCellValue(rowIndex, column.name, value);
      return;
    }

    if (column.type === COLUMN_TYPE.String) {
      if (isValidStringValue(cellValue, pageStore.notifications, column.restrictions)) {
        pageStore.editTableData(rowIndex, column.name, value, column, column.isMassEditable);
        pageStore.table.isValidationError && pageStore.table.setIsValidationError(false);
        return;
      }

      pageStore.table.setInvalidCellValue(rowIndex, column.name, value);
      return;
    }

    // TODO При расширении представления таблицы добавить валидацию для остальных контролов
    pageStore.editTableData(rowIndex, column.name, value, column, column.isMassEditable);
  }

  function onWellIdClick(): void {
    // Hardcode-attr
    const wellId =
      'value' in row['GOplan_GeologicalTask.id']
        ? row['GOplan_GeologicalTask.id'].value
        : row['GOplan_GeologicalTask.id'].first ?? row['GOplan_GeologicalTask.id'].second;

    if (!hasValue(wellId) || (typeof wellId !== 'number' && typeof wellId !== 'string')) {
      return;
    }

    if (isEditing) {
      wellFormManager.onWellEdit(wellId);
      return;
    }

    pageStore.openWellInfoSidebar(wellId);
  }

  function renderCell(): React.ReactNode {
    if (isCompareCell) {
      return (
        <CompareTableCell
          firstValue={renderCellData(cell.first, column, object)}
          secondValue={renderCellData(cell.second, column, object)}
          isImportOccurred={pageStore.comparison.isImportOccurred}
        />
      );
    }

    const cellValue = getNumberValueWithCertainNumberOfDecimalPlaces(getCellValue(cell), column);
    if ((!isEditing || isDisabled) && column.control !== COLUMN_CONTROL.Progress) {
      return (
        <EllipsisTableCell
          value={renderCellData(cellValue, column, object)}
          isDeleted={row.rowChanged === CHANGED_VARIANT.deleted}
        />
      );
    }

    switch (column.control) {
      case COLUMN_CONTROL.Field:
        return <TextTableCell fieldValue={cellValue} onEditCell={onEditCellValue} />;
      case COLUMN_CONTROL.DateOnlyPicker:
        assert(typeof cellValue === 'number' || typeof cellValue === 'string' || cellValue === null);
        return <DateTableCell fieldValue={cellValue} onEditCell={onEditCellValue} />;
      case COLUMN_CONTROL.ComboBox:
        assert(typeof cellValue === 'string' || typeof cellValue === 'number' || cellValue === null);
        return (
          <ComboBoxTableCell fieldValue={cellValue} onEditCell={onEditCellValue} column={column} object={object} />
        );
      case COLUMN_CONTROL.MultiComboBox:
        assert(Array.isArray(cellValue));
        return (
          <MultiComboBoxTableCell
            fieldValues={cellValue}
            onEditCell={onEditCellValue}
            column={column}
            object={object}
          />
        );
      case COLUMN_CONTROL.Progress:
        return (
          <ProgressTableCell
            fieldValue={renderCellData(cellValue, column, object)}
            progressPercent={getRowProgressPercent(row, pageStore.progressAttrNames)}
            onCellClick={onWellIdClick}
            onProgressLineClick={onWellIdClick}
          />
        );
      default: {
        return <EllipsisTableCell value={renderCellData(cellValue, column, object)} />;
      }
    }
  }

  return <div className={cellStyle}>{renderCell()}</div>;
});
