import { computed, makeObservable, observable } from 'mobx';

import { Range } from '../../../layers/model';
import { SidebarWellRigOperation } from '../../../presets/pads-and-wells-sidebar/entities';
import { WithKey } from '../../../types';

import { IShadowViewItem, ShadowPadRigOperation } from './shadow-pad-rig-operation';

export class ShadowWellRigOperation implements IShadowViewItem, WithKey {
  readonly well: SidebarWellRigOperation;
  readonly yBindingPosition: { y: Range<number> };
  readonly draggableParentKey: string;

  parentPad?: ShadowPadRigOperation;

  @observable x: Range<number>;

  constructor(
    well: SidebarWellRigOperation,
    x: Range<number>,
    yBindingPosition: { y: Range<number> },
    draggableParentKey: string
  ) {
    this.well = well;
    this.x = x;
    this.yBindingPosition = yBindingPosition;
    this.draggableParentKey = draggableParentKey;

    makeObservable(this);
  }

  @computed
  get y(): Range<number> {
    return this.yBindingPosition.y;
  }

  get data(): Record<string, unknown> {
    return this.well.data;
  }

  getKey(prefix?: string): string {
    return `shadow-${this.well.getKey(prefix)}`;
  }

  getFieldValue(fieldName: string): unknown {
    if (fieldName in this.data) {
      return this.data[fieldName];
    }
  }

  setParentPad(pad: ShadowPadRigOperation): void {
    this.parentPad = pad;
  }
}
