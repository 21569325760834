import { observer } from 'mobx-react-lite';

import { LoadingRigOperations, PadRigOperation, WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingRigsChartDataModel } from '../../comparing-rigs-chart/data';

import { ComparingRigsDataItem } from './comparing-rigs-data-item';

interface Props {
  data: ComparingRigsChartDataModel.ViewItem[] | null;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick(padId: number, rigId: number): void;
  onWellClick(wellId: number): void;
}

export const ComparingRigsDataItemsFull = observer(function ComparingRigsDataItemsFull({
  data,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
  onPadClick,
  onWellClick,
}: Props) {
  return (
    <>
      {data?.map((item) => {
        if (
          item instanceof PadRigOperation ||
          item instanceof WellRigOperation ||
          item instanceof LoadingRigOperations
        ) {
          return (
            <ComparingRigsDataItem
              key={item.getKey('full')}
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewSettingsProvider={viewSettingsProvider}
              onPadClick={onPadClick}
              onWellClick={onWellClick}
            />
          );
        }

        return null;
      })}
    </>
  );
});
