import { v4 as uuidv4 } from 'uuid';

import { ComparingWellsChart } from 'src/api/chart/comparing-wells-data-api';
import { assert } from 'src/shared/utils/assert';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { WellTypes } from 'src/types/well-types';

import {
  ComparingWellRigOperation,
  ComparingWellRigOperationStage,
} from '../../comparing-drilling-wells-chart/entities';

import { ChartWell } from './chart-well';
import { WellRigOperation } from './well-rig-operation';
import { WellRigOperationStage } from './well-rig-operation-stage';

export namespace WellRigOperationsCreator {
  const getId = (item: WellTypes.RawRigOperation | ComparingWellsChart.RawRigOperation): number => {
    const idFieldName = addGOplanPrefix('RigOperation.id');
    const idFieldValue = item[idFieldName];

    assert(typeof idFieldValue === 'number', `Invalid ID in rig operation object with ID ${idFieldValue}.`);

    return idFieldValue;
  };

  const getParentWellId = (rawRigOperation: WellTypes.RawRigOperation): number | undefined => {
    // This ID will be used to open sidebar with this well data.
    const wellIdFieldName = addGOplanPrefix('GeologicalTask.id');
    const wellIdFieldValue = rawRigOperation[wellIdFieldName];

    assert(
      wellIdFieldValue === undefined || typeof wellIdFieldValue === 'number',
      `Invalid ID in well object with ID ${wellIdFieldValue}.`
    );

    return wellIdFieldValue;
  };

  export const create = (
    wellId: number,
    rawRigOperation: WellTypes.RawRigOperation,
    planVersionId: number,
    parentWell?: ChartWell
  ): WellRigOperation => {
    const id = getId(rawRigOperation);

    const stagesFieldName = addGOplanPrefix('RigOperation.stages');
    const stagesFieldValue = rawRigOperation[stagesFieldName];

    assert(Array.isArray(stagesFieldValue), 'Invalid or empty stages array field in rig operation object.');

    const parentWellId = getParentWellId(rawRigOperation);

    const rigOperation = new WellRigOperation(rawRigOperation, id, uuidv4());

    const initializedRigOperationStages = stagesFieldValue.map((stage) => {
      return new WellRigOperationStage(
        stage,
        stage.wellLifeCycleStageId,
        uuidv4(),
        rigOperation,
        parentWellId,
        parentWell
      );
    });

    rigOperation.setItems(initializedRigOperationStages);

    return rigOperation;
  };

  export const createComparing = (
    wellId: number,
    rawRigOperation: ComparingWellsChart.RawRigOperation,
    planVersionId: number,
    parentWell?: ChartWell
  ): WellRigOperation => {
    const id = getId(rawRigOperation);

    const stagesFieldName = addGOplanPrefix('RigOperation.stages');
    const stagesFieldValue = rawRigOperation[stagesFieldName];

    assert(Array.isArray(stagesFieldValue), 'Invalid or empty stages array field in rig operation object.');

    const parentWellId = getParentWellId(rawRigOperation);

    const rigOperation = new ComparingWellRigOperation(rawRigOperation, id, uuidv4());

    const initializedRigOperationStages = stagesFieldValue.map((stage) => {
      if (stage?.hasChanges) {
        return new ComparingWellRigOperationStage(
          stage,
          stage.wellLifeCycleStageId,
          uuidv4(),
          rigOperation,
          parentWellId,
          parentWell
        );
      }

      return new WellRigOperationStage(
        stage,
        stage.wellLifeCycleStageId,
        uuidv4(),
        rigOperation,
        parentWellId,
        parentWell
      );
    });

    rigOperation.setItems(initializedRigOperationStages);

    return rigOperation;
  };
}
