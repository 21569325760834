import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FormDatePicker } from '../form-date-picker';

import styles from './form-range-date-picker.module.scss';

interface Props {
  start?: Moment;
  end?: Moment;
  onStartChange?(value: Moment | null, dateString: string): void;
  onEndChange?(value: Moment | null, dateString: string): void;
  /**
   * To set the container of the floating layer, while the default is to create a div element in body.
   * Attach dropdown to input element.
   * */
  containerElement?: HTMLElement | null;
  className?: string;
  startLabel?: ReactNode;
  endLabel?: ReactNode;
  placeholder?: string;
  startDisabled?: boolean;
  endDisabled?: boolean;
  withTime?: boolean;
}

export const FormRangeDatePicker = observer(function FormRangeDatePicker({
  start,
  end,
  containerElement,
  onStartChange,
  onEndChange,
  className,
  startLabel,
  endLabel,
  withTime = true,
  placeholder,
  startDisabled,
  endDisabled,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <FormDatePicker
        value={start}
        label={startLabel || t('form:startDate')}
        onChange={onStartChange}
        withTime={withTime}
        className={className}
        getPopupContainer={containerElement ? () => containerElement : undefined}
        placeholder={placeholder}
        disabled={startDisabled}
      />
      <span className={styles.dash}>–</span>
      <FormDatePicker
        value={end}
        label={endLabel || t('form:endDate')}
        onChange={onEndChange}
        withTime={withTime}
        className={className}
        getPopupContainer={containerElement ? () => containerElement : undefined}
        placeholder={placeholder}
        disabled={endDisabled}
      />
    </>
  );
});
