import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { useClickHandler } from 'src/features/drilling-chart/shared/use-click-handler';
import { ColoredCard } from 'src/shared/components/colored-card';

import styles from './well-colored-card.module.scss';

interface Props {
  onClick: VoidFunction;
  headerNextExtraComponent?: ReactNode;
  title?: string;
  text?: string;
  color?: string;
  icons?: ReactNode;
  className?: string;
}

export const WellColoredCard = observer(function WellColoredCard({
  title = '',
  text = '',
  color = '',
  headerNextExtraComponent,
  onClick,
  icons,
  className,
}: Props) {
  const { onPointerDown, onPointerUp } = useClickHandler(onClick);

  return (
    <ColoredCard
      title={title}
      headerNextExtraComponent={headerNextExtraComponent}
      text={text}
      cardColor={color}
      icons={icons}
      className={clsx(className, styles.card)}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      showExpandButton
      isInteractive
    />
  );
});
