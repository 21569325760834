import { Spin, Table } from 'antd';
import { ColumnType as AntdColumnType } from 'antd/lib/table';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ResizeObserver from 'rc-resize-observer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeGrid } from 'react-window';

import { ResizableTableTitle } from 'src/features/wells-list/wells-table/resizable-table-title';
import { PAGE_MODE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ResizeTableLine } from 'src/shared/components/resize-table-line';
import { TableRow, CHANGED_VARIANT } from 'src/store/table/types';

import styles from './virtual-table.module.scss';

interface Props {
  columns: AntdColumnType<TableRow>[];
  dataSource: Record<string, React.ReactNode>[];
  cellHeight: number;
  pageStore: WellsPageStore;
  tableHeight?: number;
  isSideScrollbar?: boolean;
}

export const VirtualTable = observer(function VirtualTable({
  columns,
  dataSource,
  cellHeight,
  tableHeight = 500,
  pageStore,
  isSideScrollbar,
}: Props) {
  const [tableWidth, setTableWidth] = useState<number>(0);

  const [connectObject] = useState(() =>
    Object.defineProperty({}, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    })
  );

  const tableRef = useRef<HTMLDivElement>(null);

  const gridRef = useRef<any>(null);

  const fixColumnRef = useRef<HTMLDivElement>(null);

  const fixedColumns = useMemo(() => columns?.filter((column) => column.fixed === 'left'), [columns]);

  const mergedColumns = columns!.map((column) => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / columns!.filter(({ width }) => !width).length),
    };
  });

  function resetVirtualGrid(): void {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      rowIndex: 0,
      shouldForceUpdate: true,
    });
  }

  function renderVirtualList(rowData: TableRow[], { ref, onScroll }: any) {
    ref.current = connectObject;

    function getColumnWidth(index: number) {
      const { width, fixed } = mergedColumns[index];

      return fixed ? 0 : (width as number);
    }

    function getFixedColumnsWidth(): number {
      return fixedColumns?.reduce((sum, { width }) => sum + Number(width), 0) || 0;
    }

    return (
      <div className={styles.tableBodyContainer} ref={fixColumnRef}>
        {fixedColumns?.map((column, index) => (
          <div
            key={index}
            className={clsx(styles.fixedColumn, index + 1 === fixedColumns.length && styles.fixedColumnLast)}
            style={{ height: `${tableHeight - 4}px`, width: `${column.width}px` }}
          >
            {dataSource?.map((item, index) => {
              const isComparingRow =
                item.rowChanged === CHANGED_VARIANT.added || item.rowChanged === CHANGED_VARIANT.deleted;

              return (
                <div
                  key={index}
                  className={clsx(styles.fixedTableCell, isComparingRow && styles.fixedTableCellComparing)}
                  style={{ height: `${cellHeight}px` }}
                >
                  {/* @ts-ignore */}
                  {item[column?.dataIndex]}
                </div>
              );
            })}
          </div>
        ))}

        <VariableSizeGrid
          ref={gridRef}
          columnCount={mergedColumns.length}
          columnWidth={getColumnWidth}
          height={tableHeight}
          rowCount={rowData.length}
          rowHeight={() => cellHeight}
          width={tableWidth - getFixedColumnsWidth()}
          onScroll={({ scrollLeft }) => onScroll({ scrollLeft })}
          className={styles.virtualTableGrid}
        >
          {({ columnIndex, rowIndex, style }) => {
            const isComparingRow =
              rowData[rowIndex]?.rowChanged === CHANGED_VARIANT.added ||
              rowData[rowIndex]?.rowChanged === CHANGED_VARIANT.deleted;

            if (mergedColumns[columnIndex].fixed === 'left') {
              return null;
            }

            return (
              <div
                className={clsx('virtualTableCell', {
                  virtualTableCellLast: columnIndex === mergedColumns.length - 1,
                  virtualTableCellComparing: isComparingRow,
                })}
                style={style}
              >
                {(rowData[rowIndex] as Record<string, React.ReactNode>)[(mergedColumns as any)[columnIndex].dataIndex]}
              </div>
            );
          }}
        </VariableSizeGrid>
      </div>
    );
  }

  useEffect(() => {
    return () => resetVirtualGrid();
  }, [tableWidth, columns]);

  useEffect(() => {
    tableRef.current && pageStore.table.setLeftOffset(tableRef.current.getBoundingClientRect().x);
  }, [pageStore.table]);

  useEffect(() => {
    const node = fixColumnRef.current;

    if (!node) return;

    const callback = (event: Event): void => {
      const scrollTop = (event.currentTarget as HTMLElement).scrollTop;
      const clientHeight = (event.currentTarget as HTMLElement).clientHeight;
      const scrollHeight = (event.currentTarget as HTMLElement).scrollHeight;

      if (pageStore.isCanLoadMore) {
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
          pageStore.loadMoreWells();
        }
      }
    };

    if (!pageStore.isLoading) {
      for (let i = 0; i < node.childElementCount; i++) {
        node.children[i].addEventListener('scroll', callback);
      }

      return () => {
        for (let i = 0; i < node.childElementCount; i++) {
          node.children[i].removeEventListener('scroll', callback);
        }
      };
    }
  }, [pageStore]);

  useEffect(() => {
    const node = fixColumnRef.current;

    if (!node) return;

    const callback = (event: Event): void => {
      const scrollTop = (event.currentTarget as HTMLElement).scrollTop;

      for (let i = 0; i < node.childElementCount; i++) {
        node.children[i].scrollTop = scrollTop;
      }
    };

    for (let i = 0; i < node.childElementCount; i++) {
      node.children[i].addEventListener('scroll', callback);
    }

    return () => {
      for (let i = 0; i < node.childElementCount; i++) {
        node.children[i].removeEventListener('scroll', callback);
      }
    };
  }, [fixedColumns]);

  const tableStyle = clsx(styles.tableContainer, {
    [styles.tableContainerCompare]: pageStore.mode === PAGE_MODE.compare,
    [styles.tableContainerScroll]: isSideScrollbar,
  });

  return (
    <div ref={tableRef} className={styles.wrapper}>
      <ResizeTableLine resizeParams={pageStore.table.resizeParams} action="static" />
      <ResizeObserver
        onResize={({ width }) => {
          setTableWidth(width);
        }}
      >
        <Table
          className={tableStyle}
          columns={mergedColumns as any}
          dataSource={dataSource as TableRow[]}
          showSorterTooltip={false}
          pagination={false}
          scroll={{ y: '100%', x: '100%' }}
          loading={{ indicator: <Spin size="large" />, spinning: pageStore.isLoading }}
          components={{
            // @ts-ignore
            body: renderVirtualList,
            header: { cell: ResizableTableTitle },
          }}
        />
      </ResizeObserver>
      <ResizeTableLine resizeParams={pageStore.table.resizeParams} action="dynamic" />
    </div>
  );
});
