import { observer } from 'mobx-react-lite';

import {
  InsertionPoint,
  LoadingRigOperations,
  PadRigOperation,
  TemporaryPadRigOperation,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ShadowPadRigOperation } from '../../data-items-full/presenter/shadow-pad-rig-operation';
import { DraggableGhost } from '../../editing/shared/entities/draggable-ghost';
import { DraggableGhostShadow } from '../../editing/shared/entities/draggable-ghost-shadow';
import { InsertionPointNeighbor } from '../../editing/shared/entities/insertion-point-neighbor';
import { useDndContext } from '../../editing/view/hooks/use-dnd-context';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import { EditingRigsDataItemCompact } from './editing-rigs-data-item-compact';

import styles from './data-items-compact.module.scss';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick?(padId: number, rigId: number): void;
}

export const EditingRigsDataItemsCompact = observer(function EditingRigsDataItemsCompact({
  data,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  viewSettingsProvider,
}: Props) {
  const containerRef = useDndContext({ draggableContainerClassName: styles.draggableContainer });

  return (
    <div ref={containerRef}>
      {data?.map((item) => {
        if (
          item instanceof PadRigOperation ||
          item instanceof LoadingRigOperations ||
          item instanceof SortableGhostItem ||
          item instanceof InsertionPoint ||
          item instanceof DraggableGhost ||
          item instanceof InsertionPointNeighbor ||
          item instanceof DraggableGhostShadow ||
          item instanceof ShadowPadRigOperation ||
          item instanceof SortableGhostItem ||
          item instanceof TemporaryPadRigOperation
        ) {
          return (
            <EditingRigsDataItemCompact
              key={item.getKey('editing-compact')}
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewSettingsProvider={viewSettingsProvider}
              onPadClick={onPadClick}
            />
          );
        }

        return null;
      })}
    </div>
  );
});
