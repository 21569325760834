import { action, makeObservable, observable } from 'mobx';

import { DEFAULT_WELL_SIDEBAR_TAB } from '../../well-info-sidebar/well-info-sidebar';
import { WellInfoSidebar } from '../../well-info-sidebar/well-info-sidebar.store';

import { ModalStore } from './modal.store';

export class RigsChartSidebarsStore {
  readonly rigInfo = new ModalStore();
  readonly padInfo = new ModalStore();
  readonly wellInfo = new ModalStore();

  @observable selectedRigId: number | null = null;
  @observable selectedPadId: number | null = null;
  @observable rigOfSelectedPadId: number | null = null;
  @observable selectedWellId: number | null = null;
  @observable initialWellSidebarTab: WellInfoSidebar.Tabs = DEFAULT_WELL_SIDEBAR_TAB;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  onPadInfoClose(): void {
    this.selectedPadId = null;
    this.rigOfSelectedPadId = null;

    this.padInfo.close();
  }

  @action.bound
  onWellInfoClose(): void {
    this.selectedWellId = null;
    this.initialWellSidebarTab = DEFAULT_WELL_SIDEBAR_TAB;

    this.wellInfo.close();
  }

  @action.bound
  onRigInfoClose(): void {
    this.selectedRigId = null;

    this.rigInfo.close();
  }

  @action.bound
  onWellClick(wellId: number): void {
    this.selectedWellId = wellId;
    this.initialWellSidebarTab = WellInfoSidebar.Tabs.mainInfo;

    this.wellInfo.open();
  }

  @action.bound
  onPadClick(padId: number, rigId: number): void {
    this.selectedPadId = padId;
    this.rigOfSelectedPadId = rigId;

    this.padInfo.open();
  }

  @action.bound
  onRigClick(rigId: number): void {
    this.selectedRigId = rigId;

    this.rigInfo.open();
  }
}
