import { action, computed, makeObservable, observable } from 'mobx';

import { Range } from '../../../layers/model';
import { SidebarPadRigOperation } from '../../../presets/pads-and-wells-sidebar/entities';

export interface IShadowViewItem {
  x: Range<number>;
}

export class ShadowPadRigOperation implements IShadowViewItem {
  readonly pad: SidebarPadRigOperation;
  readonly yBindingPosition: { y: Range<number | null> };
  readonly parentKey: string;

  @observable x: Range<number>;

  constructor(
    pad: SidebarPadRigOperation,
    x: Range<number>,
    yBindingPosition: { y: Range<number | null> },
    parentKey: string
  ) {
    this.pad = pad;
    this.x = x;
    this.yBindingPosition = yBindingPosition;
    this.parentKey = parentKey;

    makeObservable(this);
  }

  @computed
  get y(): Range<number | null> {
    return this.yBindingPosition.y;
  }

  getKey(prefix?: string): string {
    return `shadow-${this.pad.getKey(prefix)}`;
  }

  getFieldValue(fieldName: string): unknown {
    if (fieldName in this.pad.data) {
      return this.pad.data[fieldName];
    }
  }

  @action.bound
  setX(start: number, end: number): void {
    this.x = { start, end };
  }
}
