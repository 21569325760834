import { action, autorun, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { assert } from 'src/shared/utils/assert';
import { showErrorMessage } from 'src/shared/utils/messages';
import { ComparisonStore } from 'src/store/comparison/comparison-store';

import { ISummaryDataModel } from '../../../layers/model';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';
import { SummaryDataStorage } from '../../summary-data/data/summary-data-storage';
import { IComparingSummaryDataApi, ISummaryDataStorage } from '../../summary-data/data/summary-data.types';

export class ComparingSummaryDataModel implements ISummaryDataModel {
  private readonly storage: ISummaryDataStorage;
  private readonly api: IComparingSummaryDataApi;
  private readonly comparison: ComparisonStore;

  @observable selectedYear: number = new Date().getFullYear();

  constructor(api: IComparingSummaryDataApi, comparison: ComparisonStore) {
    this.storage = new SummaryDataStorage(this.selectedYear);
    this.api = api;
    this.comparison = comparison;

    makeObservable(this);
  }

  @flow.bound
  private async *loadSummary(parentIdsList: number[], year: number, planVersions: number[]) {
    try {
      const summaryData = await this.api.getSummary(parentIdsList, year, planVersions);
      yield;

      this.storage.setData(year, summaryData);
    } catch (e) {
      yield;

      console.error(e);
      showErrorMessage('Не удалось загрузить данные.');
    }
  }

  init(): VoidFunction {
    const disposeDataLoading = reaction(
      () => {
        const { emptyRows } = this.storage.data;

        return {
          emptyRows,
          year: this.selectedYear,
          firstPlanVersion: this.comparison.firstTargetPlan,
          secondPlanVersion: this.comparison.secondTargetPlan,
        };
      },
      ({ emptyRows, year, firstPlanVersion, secondPlanVersion }) => {
        if (emptyRows.length && firstPlanVersion && secondPlanVersion) {
          this.loadSummary(emptyRows, year, [firstPlanVersion.id, secondPlanVersion.id]);
        }
      }
    );

    const disposeYearSetter = autorun(() => {
      this.storage.setYear(this.selectedYear);
    });

    return () => {
      disposeDataLoading();
      disposeYearSetter();
    };
  }

  getData = computedFn((): (IRigSummary | null)[] => {
    const { rows } = this.storage.data;

    return rows;
  });

  @action.bound
  setParentIdsList(idsList: number[]): void {
    this.storage.setParentIdsList(idsList);
  }

  @action.bound
  setYear(year: number): void {
    assert(/\d{4}/.test(year.toString()), 'Invalid year.');

    this.selectedYear = year;
  }
}
