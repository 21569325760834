import { TRefQuery } from 'src/store/directories/types';

export enum SORTING_DIRECTION {
  asc = 'ASC',
  desc = 'DESC',
}

export enum GROUP_TYPE {
  field = 'field',
  rig = 'rig',
}

export enum PAGE_MODE {
  view = 'view',
  edit = 'edit',
  compare = 'compare',
}

export enum RIG_OPERATIONS_GROUP {
  pad = 'PAD',
  rig = 'RIG',
}

export type SortingOptionType = {
  fieldId: string;
  direction: SORTING_DIRECTION;
};

export type SortingObjectType = {
  attrName: string;
  direction: SORTING_DIRECTION;
  refObjectAttr?: string;
  refObjectType?: string;
  refQuery?: unknown;
  cast?: string;
};

export type FilterType = {
  fieldId: string;
  valueArray?: Array<string | number>;
  valueMin?: string | number;
  valueMax?: string | number;
};

export type FilterObjectType = {
  attrName: string;
  valueArray?: Array<string | number>;
  valueMin?: string | number;
  valueMax?: string | number;
  refObjectAttr?: string;
  refObjectType?: string;
  refQuery?: TRefQuery;
};
