import { observer } from 'mobx-react-lite';

import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { Viewport } from '../../../shared/viewport/viewport';
import { WellsChartDataModel } from '../../wells-chart/data/wells-chart-data-model';

import { WellsDataBackgroundItem } from './wells-data-background-item';

interface Props {
  data: WellsChartDataModel.ViewItemContent[];
  verticalViewport: Viewport;
  isEditing: boolean;
  isLoading: boolean;
  onWellAdd(groupId: number): void;
}

export const WellsDataItemsBackground = observer(function WellsDataItemsBackground({
  data,
  verticalViewport,
  isEditing,
  onWellAdd,
  isLoading,
}: Props) {
  return (
    <>
      {data.map((item) => {
        if (item instanceof WellsGroup || item instanceof ChartWell) {
          return (
            <WellsDataBackgroundItem
              key={item.getKey()}
              item={item}
              verticalViewport={verticalViewport}
              isEditing={isEditing}
              isLoading={isLoading}
              onWellAdd={onWellAdd}
            />
          );
        }

        return null;
      })}
    </>
  );
});
