import { assert } from 'src/shared/utils/assert';

type KeyReplacer = (originalKey: string, valueKey: string) => string;

export const getKeyReplacer = (
  values: Record<string, string | number | boolean>,
  isStartsWithSign?: boolean
): KeyReplacer => {
  return (originalKey, valueKey) => {
    const signChar = originalKey[isStartsWithSign ? 0 : 1];
    const key = `${signChar}${valueKey}`;
    const value = values[key] ?? values[valueKey];

    assert(value !== void 0, 'refQuery key is undefined');

    if (typeof value === 'number' || typeof value === 'boolean' || isStartsWithSign) {
      return value.toString();
    }

    return JSON.stringify(value);
  };
};
