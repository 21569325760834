import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';

import { TRefQuery } from '../../store/directories/types';
import { TAttrConcatRefs } from '../types';

import { ViewSettings } from './drilling-plan-charts-api';
import { RigsChartAdapter } from './rigs-chart-adapter';

/** API for rigs chart (rigs chart store). Contains methods outside rigs data model. */
export class RigsChartApi {
  private readonly adapter = new RigsChartAdapter();

  /** Get rigs groups with rigs. */
  async getRigs(
    planVersionId: string | number,
    groupType: string,
    filter: Record<string, unknown>,
    rigName?: string,
    options?: {
      cancellationSignal?: AbortSignal;
    }
  ): Promise<RigsChart.RawRigsGroupWithRigs[] | undefined> {
    try {
      const { data: rawGroups } = await agent.post<RigsChart.RawGroup[]>('graph/grouping', filter, {
        params: { planVersionId, groupType },
        signal: options?.cancellationSignal,
      });

      const groupsIds = this.adapter.receiveRigGroupIds(rawGroups);

      if (!groupsIds.length) {
        return;
      }

      // Get all rigs from all rigs groups.
      const { data: rigsGroups } = await agent.post<RigsChart.RawRigsGroupWithRigs[]>('graph/rig/list/', filter, {
        params: { planVersionId, groupType, groupIdList: groupsIds.join(), rigName },
        signal: options?.cancellationSignal,
      });

      return rigsGroups;
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace RigsChartViewTypes {
  export type Join = {
    joinedAlias: string;
    mainAttribute: string;
    joinedAttribute: string;
    mainObjectAlias: string;
    joinedObjectType: string;
    hideInResult?: boolean;
  };

  export type RefQuery = {
    objectType: string;
    joinedAlias: string;
    join: Join;
  };

  export type Attribute = {
    objectName: string;
  };

  export type CarpetRows = {
    attrName?: string;
    attrConcat?: string[];
    attrConcatRefs?: TAttrConcatRefs;
    additionalAttributes?: Attribute[];
    delimiter?: string;
  };

  export type CarpetPadHeader = {
    attrName: string;
    control: string;
    refObjectAttr?: string;
    refObjectType?: string;
  };

  export type CarpetPads = {
    tooltip: {
      additionalAttributes: {
        fieldId: string;
        attr?: string;
        attrName?: string;
        objectType?: string;
        refObjectAttr?: string;
        refObjectType?: string;
        refQuery?: TRefQuery;
      }[];
    };
    headers: CarpetPadHeader[];
  };

  export type CarpetWellsAttribute = {
    attrName: string;
    displayAttrName: string;
    objectName: string;
  };

  export type FormulaArgument = {
    attrName: string;
    control: string;
    type: 'number';
    refObjectAttr?: string;
    refObjectType?: string;
    refQuery?: TRefQuery;
  };

  export type FormulaAttribute = {
    type: 'Formula';
    resultType: 'Date';
    body: string;
    arguments: FormulaArgument[];
    fieldId: string;
  };

  export type DefaultWellTooltipAttribute =
    | {
        type: 'Color' | 'Icon' | 'Regular' | 'Title' | 'Date';
        attrName: string;
        fieldId: string;
        refObjectAttr?: string;
        refObjectType?: string;
      }
    | FormulaAttribute;

  export type CarpetWells = {
    tooltip: {
      defaultAttributes: DefaultWellTooltipAttribute[][];
    };
    attrName: string;
    refObjectAttr: string;
    refObjectType: string;
    additionalAttributes: CarpetWellsAttribute[];
    rigOperationStart: {
      attrName: string;
    };
  };

  export type Carpet = {
    attrName: string;
    control: string;
    objectName: string;
    refObjectAttr: string;
    refObjectType: string;
    rows: CarpetRows;
    pads: CarpetPads;
    wells: CarpetWells;
    defaultGrouping: string;
  };

  export type SummaryHeader = {
    name: string;
    label: string;
  };

  export type SummaryTableItem = {
    label: string;
    fieldId: string;
    attrName: string;
  };

  export type SummaryTable = {
    items: SummaryTableItem[];
  };

  export type SummaryRigsGroup = {
    attrName: string;
    table: SummaryTable;
  };

  export type Summary = {
    rigGroup: SummaryRigsGroup;
  };

  export type RigsChartView = {
    settings: ViewSettings.RawSettingsControls;
    carpet: Carpet;
    summary: Summary;
  };
}

export namespace RigsChartRemoveConfirmationSidebarViewTypes {
  export type AttrConcatRefs = Record<
    string,
    {
      refObjectAttr: string;
      refObjectType: string;
    }
  >;

  export type Attribute = {
    type: string;
    objectName: string;
  };

  export type RigName = {
    refObjectAttrConcat: string[];
    attrConcatRefs: AttrConcatRefs;
    delimiter: string;
  };

  export type WellView = {
    attrName: string;
    refObjectAttr: string;
    refObjectType: string;
    rigOperationIndex: string;
    attributes: Attribute[];
  };

  export type PadHeader = {
    control: string;
    attrName: string;
    refObjectAttr: string;
    refObjectType: string;
  };

  export type PadView = {
    headers: PadHeader[];
  };

  export type RigsChartRemoveConfirmationSidebarView = {
    rigName: RigName;
    well: WellView;
    pad: PadView;
  };
}

export namespace RigsChartConflictSidebarViewTypes {
  export type Attribute = {
    type: string;
    objectName: string;
  };

  export type ConflictWell = {
    attrName: string;
    refObjectAttr: string;
    refObjectType: string;
    rigOperationIndex: string;
    attributes: Attribute[];
  };

  export type RigsChartConflictSidebarView = {
    conflictWell: ConflictWell;
  };
}

export namespace RigsChart {
  export type PadData = Record<string, string | number | undefined>;

  export type ComparingRawWell = Record<string, string | number | boolean> & { hasChanges?: boolean };

  export type RawPad<TItem = Record<string, string | number | boolean>> = {
    id: number;
    data: PadData;
    items: TItem[];
  };

  export type RigData = Record<string, unknown>;

  export type RawRig = Record<string, string | number>;

  export type RawRigsGroup = Record<string, unknown>;

  export type RawRigsGroupWithRigs = {
    id: number;
    data: RawRigsGroup;
    items: RawRig[];
    objectType: string;
  };

  export type RawRigWithItems = RigsChart.RawRig & {
    id: unknown;
    items: RigsChart.RawPad[];
  };

  export type RawRigsGroupWithItems = {
    id: number;
    data: RawRigsGroup;
    items: RawRigWithItems[];
    objectType: string;
  };

  export type RawGroupData = {
    id: number;
    data: Record<string, unknown>;
  };

  export type RawGroup = Record<string, RawGroupData>;
}
