import PAD_TOOLTIP_VIEW from '../../features/drilling-chart/presets/drilling-rigs-chart/pad-tooltip-view.json';
import { PadTooltipView } from '../../features/drilling-chart/presets/drilling-rigs-chart/rigs-view-settings-provider';
import { isPadAttributeIcon, PadAttributeIcon } from '../constants/pad-attribute-icon';

export function getPadAttributeIcons(padData: Record<string, unknown>): PadAttributeIcon[] | null {
  const view: PadTooltipView.Item[] = PAD_TOOLTIP_VIEW;

  const attributes = view.reduce<PadAttributeIcon[]>((attributeNames, attributesGroup) => {
    const hasViewPadAttributes = attributesGroup.attributes.some((attribute) =>
      attribute.values.some((value) => {
        const fieldName = value.value;

        return padData[fieldName];
      })
    );

    if (hasViewPadAttributes && isPadAttributeIcon(attributesGroup.icon)) {
      attributeNames.push(attributesGroup.icon);
    }

    return attributeNames;
  }, []);

  if (!attributes.length) {
    return null;
  }

  return attributes;
}
