import { TIdTriple } from 'src/api/new-well/types';

export const getInitialTripleIdsByWell = (well: Record<string, unknown>): TIdTriple[] => {
  const tripleIds: TIdTriple[] = [];

  Object.entries(well).forEach((pair) => {
    const key = pair[0];
    const value = pair[1];

    if (key.match(/\.id$/) && typeof value === 'number') {
      const objectType = key.split('.')[0];
      tripleIds.push({
        id: value,
        oldId: value,
        objectType,
      });
    }
    if (Array.isArray(pair[1])) {
      pair[1].forEach((approach) => {
        Object.entries(approach).forEach((approachPair) => {
          const approachKey = approachPair[0];
          const approachValue = approachPair[1];

          if (approachKey.match(/\.id$/) && typeof approachValue === 'number') {
            const objectType = approachKey.split('.')[0];
            tripleIds.push({
              id: approachValue,
              objectType,
            });
          }
        });
      });
    }
  });

  return tripleIds;
};
