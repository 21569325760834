import { ISummaryDataApi } from '../../features/drilling-chart/features/summary-data/data/summary-data.types';
import { RigSummary } from '../../features/drilling-chart/presets/summary-data-sidebar/entities';
import { agent } from '../agent';
import { throwApiError } from '../utils';

import { SummaryDataAdapter } from './summary-data-adapter';

export class SummaryDataApi implements ISummaryDataApi {
  private readonly adapter = new SummaryDataAdapter();

  async getSummary(rigIdsList: number[], year: number, planVersion: number): Promise<Map<number, RigSummary | null>> {
    try {
      const { data } = await agent.post('/dashboard/table', { version: planVersion, year, rigs: rigIdsList });

      return this.adapter.initializeSummary(data, rigIdsList);
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace Summary {
  export enum Indicator {
    drillingCompleteWellsCount = 'DRILLING_COMPLETE_WELLS_COUNT',
    passingExp = 'PASSING_EXP',
    passingPrb = 'PASSING_PRB',
  }

  export const isIndicator = (value: unknown): value is Indicator =>
    typeof value === 'string' && Object.values<string>(Indicator).includes(value);

  export type Points<TValue> = Record<string, TValue>;

  export type Values<TValue, TTotal = number> = {
    points: Points<TValue>;
    total: TTotal;
  };

  export type RawData = Record<Indicator, Values<Values<number>>>;

  export const RowValuesFieldNames = ['firstQuarter', 'secondQuarter', 'thirdQuarter', 'fourthQuarter', 'total'];

  export type RowValuesField = 'firstQuarter' | 'secondQuarter' | 'thirdQuarter' | 'fourthQuarter' | 'total';

  export type RowValues = Record<RowValuesField, number>;

  export const isColumn = (value: unknown): value is keyof RowValues =>
    typeof value === 'string' && RowValuesFieldNames.includes(value);

  export type Rows = Record<Indicator, RowValues>;
}
