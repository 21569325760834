import { observer } from 'mobx-react-lite';

import { LoadingRigOperations, PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingRigsChartDataModel } from '../../comparing-rigs-chart/data';

import { ComparingRigsDataItemCompact } from './comparing-rigs-data-item-compact';

interface Props {
  data: ComparingRigsChartDataModel.ViewItem[] | null;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const ComparingRigsDataItemsCompact = observer(function ComparingRigsDataItemsCompact({
  data,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
}: Props) {
  return (
    <>
      {data?.map((item) => {
        if (item instanceof PadRigOperation || item instanceof LoadingRigOperations) {
          return (
            <ComparingRigsDataItemCompact
              key={item.getKey('compact')}
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewSettingsProvider={viewSettingsProvider}
            />
          );
        }

        return null;
      })}
    </>
  );
});
