import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';
import { SummaryViewProvider } from '../../../shared/summary-view-provider';
import { useMovableContainer } from '../../../shared/use-movable-container';
import { Viewport } from '../../../shared/viewport/viewport';
import { getSizePx } from '../../../shared/viewport/viewport-calculator';
import { RigsChartDataModel } from '../../rigs-chart/data/rigs-chart-data-model';

import { SummaryDataHeader } from './summary-data-header';
import { SummaryDataItem } from './summary-data-item';

import styles from './summary-data.module.scss';

interface Props {
  data: (IRigSummary | null)[] | null;
  backgroundItems: RigsChartDataModel.ViewItem[] | null;
  viewport: Viewport;
  viewProvider: SummaryViewProvider;
  onScroll(offset: number): void;
}

export const SummaryData = observer(function SummaryData({
  data,
  backgroundItems,
  viewport,
  onScroll,
  viewProvider,
}: Props) {
  const { containerRef, handleScroll } = useMovableContainer({ onScroll });
  const {
    editing: { isEditing },
  } = useStore();

  return (
    <div
      ref={containerRef}
      style={{
        height: getSizePx(viewport),
        '--summary-columns-number': viewProvider.headerColumns.length,
        '--summary-columns-width': viewProvider.columnsWidthPercentage.toString().concat('%'),
      }}
      className={styles.container}
      onWheel={handleScroll}
    >
      <SummaryDataHeader titles={viewProvider.headerColumns} className={isEditing ? styles.wideHeader : undefined} />

      {backgroundItems?.map((item) => {
        if (item instanceof RigsGroup || item instanceof ChartRig) {
          return (
            <SummaryDataItem
              key={item.getKey()}
              data={data}
              backgroundItem={item}
              viewport={viewport}
              viewProvider={viewProvider}
            />
          );
        }

        return null;
      })}
    </div>
  );
});
