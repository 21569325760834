import { RigTypes } from 'src/types/rig-types';

export class Rig {
  readonly item: RigTypes.RawRig;

  constructor(item: RigTypes.RawRig) {
    this.item = item;
  }

  getFieldValue(fieldName: string): RigTypes.RawRigDataValue | undefined {
    if (fieldName in this.item.data) {
      return this.item.data[fieldName];
    }
  }
}
