import { action, flow, makeObservable, observable } from 'mobx';

import { getGeologicalTaskHistory, getWellByWellId } from 'src/api/new-well/requests';
import { copyGeologicalTask } from 'src/api/wells-list';
import { BaseApiError, ValidationError } from 'src/errors';
import { FormPlugin } from 'src/features/well-form/plugins';
import { CopyModePlugin } from 'src/features/well-form/plugins/copy-mode.plugin';
import { MakeWellIsPlannedPlugin } from 'src/features/well-form/plugins/make-well-is-planned-validation.plugin';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getInitialTripleIdsByWell } from 'src/shared/utils/get-triple-ids';

import { ENABLE_EDITING_VARIANTS } from '../editing/types';
import { RootStore } from '../root-store';

import { WellFormManager } from './well-form-manager';

export class WellFormManagerWithGeoTasksHistory extends WellFormManager {
  @observable isCopying = false;

  constructor(rootStore: RootStore, formPlugins: FormPlugin[] = []) {
    super(rootStore, formPlugins);
    makeObservable(this);
  }

  @flow.bound
  async *getGeologicalTaskHistory(
    wellPlacementId: number,
    mode: 'editing' | 'copying' = 'editing',
    geoTaskId?: number
  ) {
    if (!this.drafts.draftVersionId) {
      return;
    }
    assert(
      wellPlacementId && typeof wellPlacementId === 'number',
      'well placement id is not presented or its format is invalid'
    );
    try {
      const history = await getGeologicalTaskHistory(this.drafts.draftVersionId, wellPlacementId);
      yield;

      await this.createFormEntitiesByHistory(history, mode, geoTaskId);
      yield;
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('newWellForm:Errors.failedLoadGeotaskHistory');
    }
  }

  @flow.bound
  private async *createFormEntitiesByHistory(
    history: Record<string, unknown>[],
    mode: 'editing' | 'copying' = 'editing',
    geotaskId?: number
  ) {
    const draftId = this.drafts.draftVersionId;
    if (!draftId) {
      return;
    }
    try {
      const requests: Promise<Record<string, unknown>>[] = [];
      history.forEach((geoTask) => {
        const geoTaskId = geoTask['GOplan_GeologicalTask.id'];
        if (typeof geoTaskId === 'number') {
          requests.push(getWellByWellId(geoTaskId, draftId));
        }
      });

      const wells = await Promise.all(requests);
      yield;

      this.formList = wells
        .sort((currWell, prevWell) => {
          const firstCreatedAt = currWell['createdAt'];
          const secondCreatedAt = prevWell['createdAt'];

          if (typeof firstCreatedAt !== 'number' || typeof secondCreatedAt !== 'number') {
            return 0;
          }

          return firstCreatedAt - secondCreatedAt;
        })
        .map((well) => {
          assert(this.formView, 'form view is not presented');
          const conditionalPlugins: FormPlugin[] = [];

          const wellGeoTaskId = well['GOplan_GeologicalTask.id'];
          if (mode === 'copying' && wellGeoTaskId && geotaskId && wellGeoTaskId === geotaskId) {
            conditionalPlugins.push(new CopyModePlugin(this.rootStore));
          }

          const form = this.createForm({ additionalPlugins: conditionalPlugins });

          assert(form, 'form was not created');

          form.setFormValues(well);
          form.setIsEditingMode(true);
          this.tripleIdsList[form.id] = getInitialTripleIdsByWell(well);

          return form;
        });
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:geoTasksHistoryFailed');
    }
  }

  @flow.bound
  private async *copyWell(geoTaskId: number, wellPlacementId: number) {
    if (!this.formView) {
      return;
    }

    try {
      if (!this.editing.isEditing) {
        const enableEditingVariant = await this.editing.enableEditing();
        yield;

        if (enableEditingVariant === ENABLE_EDITING_VARIANTS.cancel) {
          return;
        }
      }

      await this.getGeologicalTaskHistory(wellPlacementId, 'copying', geoTaskId);
      yield;

      this.currentFormStore = this.formList.at(-1) || null;

      if (!this.currentFormStore) {
        return;
      }

      this.currentTripleIds = this.tripleIdsList[this.currentFormStore.id];
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
    }
  }

  @flow.bound
  async *onWellEdit(wellId: string | number, isRequiredFilledApproach: boolean = false) {
    if (!this.formView || Number.isNaN(wellId) || Number.isNaN(Number(wellId))) {
      this.notifications.showErrorMessageT('newWellForm:Errors.failedOpenEditForm');
      return;
    }

    try {
      if (!this.editing.isEditing) {
        const enableEditingVariant = await this.editing.enableEditing();
        yield;

        if (enableEditingVariant === ENABLE_EDITING_VARIANTS.cancel) {
          return;
        }
      }

      if (!hasValue(this.drafts.draftVersionId)) {
        return;
      }

      this.isFormOpen = true;
      this.isFormLoading = true;

      const rawWell = await getWellByWellId(Number(wellId), this.drafts.draftVersionId);
      yield;

      const wellPlacementId = rawWell['GOplan_WellPlacement.id'];
      assert(
        wellPlacementId && typeof wellPlacementId === 'number',
        'wellPlacementId is not presented or its type is not a number'
      );
      await this.getGeologicalTaskHistory(wellPlacementId);
      yield;

      this.currentFormStore = this.formList[0];
      if (this.currentFormStore) {
        this.currentTripleIds = this.tripleIdsList[this.currentFormStore.id];
        this.currentFormStore.setIsEditingMode(true);

        // Не забыть отрефакторить это место при рефакторинге валидации
        if (isRequiredFilledApproach) {
          const moveToPlannedValidationPlugin = this.currentFormStore.plugins.find(
            (plugin): plugin is MakeWellIsPlannedPlugin => plugin instanceof MakeWellIsPlannedPlugin
          );
          if (!moveToPlannedValidationPlugin) {
            console.warn('MakeWellIsPlannedPlugin is not presented, so its validation wont be enabled');
          } else {
            moveToPlannedValidationPlugin.setIsRequiredFilledApproach(true);
          }
        }
        yield;
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
      }
      this.isFormOpen = false;
    } finally {
      this.isFormLoading = false;
    }
  }

  @flow.bound
  async *copyGeologicalTask() {
    if (!this.currentFormStore || !this.currentTripleIds) {
      return;
    }

    try {
      if (!this.editing.isEditing) {
        await this.editing.enableEditing();
        yield;
      }

      if (!hasValue(this.drafts.draftVersionId)) {
        return;
      }

      const { geologicalTaskId, wellPlacementId } = this.currentTripleIds.reduce(
        (acc: { geologicalTaskId: number | null; wellPlacementId: number | null }, curr) => {
          if (curr.objectType === 'GOplan_GeologicalTask') {
            acc.geologicalTaskId = curr.id;
          }

          if (curr.objectType === 'GOplan_WellPlacement') {
            acc.wellPlacementId = curr.id;
          }

          return acc;
        },
        {
          geologicalTaskId: null,
          wellPlacementId: null,
        }
      );

      if (!hasValue(geologicalTaskId) || !hasValue(wellPlacementId)) {
        return;
      }

      this.isCopying = true;

      const copiedWellData = await copyGeologicalTask(this.drafts.draftVersionId, geologicalTaskId, wellPlacementId);
      yield;

      const copiedGeologicalTaskId = copiedWellData['GOplan_GeologicalTask.id'];
      const copiedWellPlacementId = copiedWellData['GOplan_WellPlacement.id'];

      assert(typeof copiedGeologicalTaskId === 'number' && typeof copiedWellPlacementId === 'number');

      await this.copyWell(copiedGeologicalTaskId, copiedWellPlacementId);
      yield;
    } catch (error) {
      yield;

      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }

      if (error instanceof ValidationError) {
        this.notifications.showErrorMessage(error.message);
        return;
      }

      console.error(error);
      this.notifications.showErrorMessageT('newWellForm:Errors.failedCopyTheWell');
    } finally {
      this.isCopying = false;
    }
  }

  @action.bound
  makeWellIsPlanned(wellId: string | number) {
    this.onWellEdit(wellId, true);
  }
}
