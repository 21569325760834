import { observer } from 'mobx-react-lite';
import React from 'react';

import { AddWellButton } from 'src/features/drilling-rig-conflicts-sidebar/add-well-button';

import { AddWellToChartSidebarStore } from '../add-well-to-chart-sidebar.store';

import { Pad as PadComponent } from './pad';

interface Props {
  store: AddWellToChartSidebarStore;
}

export const Pads = observer(function Pads({ store }: Props) {
  return (
    <>
      {store.pads.map((pad, index) => {
        const locateWellInBegginingOrBetweenPads = (): void => {
          const prevWell = store.pads[index - 1]?.lastWell || null;
          const nextWell = pad.firstWell;

          store.locateWell(nextWell, prevWell);
        };

        const locateWellAfterLastPad = (): void => {
          const lastWell = pad.lastWell;

          store.locateWell(null, lastWell);
        };

        return (
          <React.Fragment key={pad.id}>
            <AddWellButton onClick={locateWellInBegginingOrBetweenPads} />

            <PadComponent pad={pad} locateWell={store.locateWell.bind(store)} />

            {index === store.pads.length - 1 && <AddWellButton onClick={locateWellAfterLastPad} />}
          </React.Fragment>
        );
      })}
    </>
  );
});
