import { action, computed, makeObservable, observable } from 'mobx';

import { Range } from '../../../layers/model';
import { IPresenter } from '../../../layers/presenter';
import { Viewport } from '../../../shared/viewport/viewport';

import { TimelinePeriodsAdapter } from './timeline-periods-adapter';

export class TimelinePeriodsTogglePresenter implements IPresenter<TimelinePeriodsTogglePresenter.Periods, number> {
  @observable selectedPeriod;
  @observable dataRange: Range<number>;

  private readonly viewport: Viewport;

  constructor(viewport: Viewport, dataRange: Range<number>) {
    this.viewport = viewport;
    this.selectedPeriod = TimelinePeriodsTogglePresenter.Periods.yearOne;
    this.dataRange = dataRange;

    makeObservable(this);
  }

  @computed
  get data(): TimelinePeriodsTogglePresenter.Periods {
    return this.selectedPeriod;
  }

  @action.bound
  onPeriodToggle(period: TimelinePeriodsTogglePresenter.Periods) {
    this.selectedPeriod = period;

    const { start, end } = TimelinePeriodsAdapter.getPeriodRange(period, this.dataRange);
    this.viewport.setRange(start, end);
  }
}

export namespace TimelinePeriodsTogglePresenter {
  export enum Periods {
    monthOne = 'monthOne',
    monthThree = 'monthThree',
    monthSix = 'monthSix',
    yearOne = 'yearOne',
    all = 'all',
  }
}
