import { observer } from 'mobx-react-lite';

import { hasValue } from 'src/shared/utils/common';
import { LoadingField } from 'src/shared/utils/loading-field';

import { LoadingTitle } from '../../../../../shared/components/loading-title/loading-title';
import {
  IndicatorColumn,
  IndicatorsSettings,
  LoadingIndicatorsColumn,
} from '../../../presets/indicators-view-settings-sidebar/entities';
import { indicatorNameToTrendType } from '../../../shared/indicators';

import { IndicatorsHeaderColumnRow } from './indicators-header-column-row';

import styles from './indicators-table.module.scss';

interface Props {
  isExpanded: boolean;
  settings: IndicatorsSettings;
  indicatorsTotal?: IndicatorColumn | LoadingIndicatorsColumn;
}

export const IndicatorsHeaderColumn = observer(function IndicatorsHeaderColumn({
  isExpanded,
  indicatorsTotal,
  settings,
}: Props) {
  const shownSettingsItems = settings.shownItemsWithLabels;

  const topIndicator = shownSettingsItems.at(0);
  const restIndicators = shownSettingsItems.slice(1);

  if (!topIndicator || !restIndicators) {
    return null;
  }

  const getTotal = (indicatorName: string): string | LoadingField => {
    const trendType = indicatorNameToTrendType(indicatorName);

    if (trendType) {
      if (indicatorsTotal instanceof IndicatorColumn) {
        const value = indicatorsTotal.getValue(trendType);

        if (hasValue(value)) {
          return value;
        }
      }

      if (indicatorsTotal instanceof LoadingIndicatorsColumn) {
        return <LoadingTitle loadingClassName={styles.rowHeaderTotalLoading} />;
      }
    }

    return '0';
  };

  return (
    <div className={styles.rowHeaderWrapper}>
      <IndicatorsHeaderColumnRow title={topIndicator.settingsLabel} total={getTotal(topIndicator.fieldId)} />

      {isExpanded &&
        restIndicators?.map((indicator) => (
          <IndicatorsHeaderColumnRow
            key={indicator.fieldId}
            title={indicator.settingsLabel}
            total={getTotal(indicator.fieldId)}
          />
        ))}
    </div>
  );
});
