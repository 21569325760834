import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { VerticalColoredCard } from 'src/shared/components/vertical-colored-card';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import { ChartColors } from '../drilling-chart/shared/chart-colors';
import { DateHelper } from '../drilling-chart/shared/date-helper';
import { Stage } from '../graph-info-sidebar/entities/tab-with-stages-entities/stage.entity';

import { DrillingStageSections } from './drilling-stage-sections';

import styles from './well-info-stage-card.module.scss';

interface Props {
  stage: Stage;
  className?: string;
}

export const WellInfoStageCard = observer(function WellInfoStageCard({ stage, className }: Props) {
  const { t } = useTranslation();
  const { directories } = useStore();

  const hasSections = !!stage.sections?.length;

  return (
    <VerticalColoredCard
      color={ChartColors.yellow}
      className={clsx(className, styles.container, hasSections && styles.containerWithBody)}
    >
      <div className={styles.header}>
        <p className={styles.title}>{stage.title}</p>

        <p className={styles.text}>
          <span>{!!stage.start ? DateHelper.unixToDateFormat(stage.start) : null}</span>&nbsp;
          <span className={styles.subText}>{!!stage.start ? DateHelper.unixToTimeFormat(stage.start) : null}</span>
          {' - '}
          <span>{!!stage.end ? DateHelper.unixToDateFormat(stage.end) : null}</span>&nbsp;
          <span className={styles.subText}>{!!stage.end ? DateHelper.unixToTimeFormat(stage.end) : null}</span>
        </p>

        {hasValue(stage.duration) && (
          <p className={styles.text}>
            {stage.duration} {directories.getFieldUnit('duration')}
          </p>
        )}
      </div>

      {hasSections && <DrillingStageSections sections={stage.sections} />}
    </VerticalColoredCard>
  );
});
