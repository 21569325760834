import { action, makeObservable, observable } from 'mobx';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

import { TDictObject } from 'src/api/directories/types';
import { Directories } from 'src/store/directories/directories.store';
import { TRefRest } from 'src/store/directories/types';

import { MultiComboboxSettings, TEnableIf, TFormula, TVisuallyDisabled } from '../../../api/new-well/types';
import { IRestrictions } from '../../../features/well-form/types';
import { Combobox } from '../abstract-control-entities';

export type TTreeMultiComboboxData = {
  directories: Directories;
  label?: string;
  placeholder?: string;
  formElementRefId: string;
  fieldId: string;
  objectName?: string;
  required: boolean;
  enableIf?: TEnableIf[];
  calculatedValue?: TFormula;
  visuallyDisabled?: TVisuallyDisabled[];
  refObjectType?: string;
  refObjectAttr?: string;
  refRest?: TRefRest;
  initialValue?: string | number;
  restrictions: IRestrictions;
  directory: TDictObject[] | null;
  settings?: MultiComboboxSettings;
};

export class TreeMultiCombobox extends Combobox<(string | number)[], DefaultOptionType> {
  readonly required: boolean;
  readonly settings?: MultiComboboxSettings;

  options: DefaultOptionType[] = observable.array();
  @observable initialValue: (string | number)[] = [];
  @observable value: (string | number)[] = [];

  constructor(data: TTreeMultiComboboxData) {
    super(data);
    this.required = data.required;
    this.settings = data.settings;

    makeObservable(this);
  }

  @action.bound
  setTreeData(treeData: DefaultOptionType[]): void {
    this.options = treeData;
  }

  @action.bound
  setValue(value: (string | number)[] | null, setValueAsInitial?: boolean): void {
    if (value === this.value) {
      return;
    }
    this.clearError();

    if (!value) {
      this.value = [];
      return;
    }
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: (string | number)[]): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  clearItem(): void {
    this.value = [];
    this.invalidValue = null;
    this.clearError();
  }

  checkIsReady(): boolean {
    return !this.errorText;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (!value || value === 'null' || value === 'undefined') {
      this.setValue([], setValueAsInitial);
      return true;
    }
    if (Array.isArray(value)) {
      const parsedValue = [];
      for (const rawValue of value) {
        if (typeof rawValue === 'number' && !Number.isNaN(rawValue)) {
          parsedValue.push(rawValue);
          continue;
        }
        if (typeof rawValue === 'string' && !Number.isNaN(Number(rawValue))) {
          parsedValue.push(Number(rawValue));
          continue;
        }
        console.error('wrong value type', rawValue);
      }
      this.setValue(parsedValue, setValueAsInitial);
      return true;
    }

    return false;
  }

  @action.bound
  hasErrors(): boolean {
    if (!this.value.length && this.restrictions?.required) {
      this.errorText = 'newWellForm:Errors.required';
    }
    return !!this.errorText;
  }

  init = (): VoidFunction => {
    const trackOptionsAndResetWrongValueDisposer = this.trackOptionsAndResetWrongValue();

    return () => {
      trackOptionsAndResetWrongValueDisposer();
    };
  };
}
