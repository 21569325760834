import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import styles from './skeleton.module.scss';

interface Props {
  rowsNumber?: number;
  className?: string;
  rowClassName?: string;
}

export const Skeleton = observer(function Skeleton({ rowsNumber = 1, className, rowClassName }: Props) {
  return (
    <div className={clsx(className, styles.container)}>
      {new Array(rowsNumber).fill(null).map((row, index) => (
        <div
          key={index}
          className={clsx(rowClassName, styles.row)}
          style={{ width: `${Math.floor(Math.random() * 100)}%` }}
        />
      ))}
    </div>
  );
});
