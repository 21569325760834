import clsx from 'clsx';
import CSS from 'csstype';
import { observer } from 'mobx-react-lite';
import { ReactChildren, useEffect, useRef, useState } from 'react';

import { TDictObject } from 'src/api/directories/types';
import { ComboBoxFilter } from 'src/features/planning/views/plan-table/table-title/combo-box-filter';
import { ComplexTooltip } from 'src/features/planning/views/plan-table/table-title/complex-tooltip';
import { DateFilter } from 'src/features/planning/views/plan-table/table-title/date-filter';
import { TooltipButtonsGroup } from 'src/features/planning/views/plan-table/table-title/tooltip-buttons-group';
import { SORTING_DIRECTION, SortingOptionType, FilterType } from 'src/pages/plan-page/types';
import { useOutsideClick } from 'src/shared/hooks/use-outside-click';
import { hasValue } from 'src/shared/utils/common';
import { ColumnType, COLUMN_CONTROL } from 'src/store/table/types';

import styles from './table-title.module.scss';

export interface Props {
  children: ReactChildren;
  width: number;
  minColumnWidth: number;
  columnId: string;
  leftOffset: number;
  sorting: SortingOptionType | null;
  filters: FilterType[];
  onFiltersChange(filters: FilterType[]): void;
  onSortTableData(sortingOption: SortingOptionType | null): void;
  onResizeStart(columnName: string, columnWidth: number, minColumnWidth: number, startCoordinates: number): void;
  setXCoordinates(coordinates: number): void;
  onResizeStop(): void;
  object: TDictObject[] | null;
  objectField?: string;
  style?: CSS.Properties;
  columnType?: COLUMN_CONTROL;
  columns?: ColumnType[];
  isResizable?: boolean;
  isSortable?: boolean;
  excludeFromSettings?: boolean;
}

export const TableTitle = observer(function ResizableTitle({
  width,
  onResizeStart,
  onResizeStop,
  children,
  setXCoordinates,
  minColumnWidth,
  columnId,
  isResizable,
  isSortable,
  leftOffset,
  style,
  columnType,
  columns,
  onSortTableData,
  sorting,
  filters,
  object,
  objectField,
  onFiltersChange,
  excludeFromSettings,
  ...rest
}: Props) {
  const refRight = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setOpen(false), [buttonRef]);

  function onSortInAscendingOrder(): void {
    onSortTableData({ fieldId: columnId, direction: SORTING_DIRECTION.asc });
  }

  function onSortInDescendingOrder(): void {
    onSortTableData({ fieldId: columnId, direction: SORTING_DIRECTION.desc });
  }

  function onResetSorting(): void {
    onSortTableData(null);
  }

  function renderTooltipComponent(): React.ReactNode {
    if (excludeFromSettings) {
      return (
        <TooltipButtonsGroup
          onSortInAscendingOrder={onSortInAscendingOrder}
          onSortInDescendingOrder={onSortInDescendingOrder}
          onResetSorting={onResetSorting}
        />
      );
    }

    switch (columnType) {
      case COLUMN_CONTROL.ComboBox:
        return (
          <ComplexTooltip
            fieldId={columnId}
            sorting={sorting}
            onSortInAscendingOrder={onSortInAscendingOrder}
            onSortInDescendingOrder={onSortInDescendingOrder}
            onResetSorting={onResetSorting}
          >
            <ComboBoxFilter
              fieldId={columnId}
              filters={filters}
              onFiltersChange={onFiltersChange}
              object={object}
              objectField={objectField}
            />
          </ComplexTooltip>
        );

      case COLUMN_CONTROL.DateOnlyPicker:
        return (
          <ComplexTooltip
            fieldId={columnId}
            sorting={sorting}
            onSortInAscendingOrder={onSortInAscendingOrder}
            onSortInDescendingOrder={onSortInDescendingOrder}
            onResetSorting={onResetSorting}
          >
            <DateFilter fieldId={columnId} filters={filters} onFiltersChange={onFiltersChange} />
          </ComplexTooltip>
        );
      default: {
        return (
          <TooltipButtonsGroup
            onSortInAscendingOrder={onSortInAscendingOrder}
            onSortInDescendingOrder={onSortInDescendingOrder}
            onResetSorting={onResetSorting}
          />
        );
      }
    }
  }

  useEffect(() => {
    let prevScrollLeft = 0;
    let prevScrollTop = 0;

    const handleScroll = (e: Event) => {
      const target = e.target;

      if (!target || !('scrollLeft' in target) || !('scrollTop' in target)) {
        return;
      }

      const { scrollLeft, scrollTop } = target;

      if (prevScrollLeft !== scrollLeft || prevScrollTop !== scrollTop) {
        setOpen(false);
      }

      prevScrollLeft = scrollLeft;
      prevScrollTop = scrollTop;
    };

    document.addEventListener('scroll', handleScroll, { capture: true });

    return () => {
      document.removeEventListener('scroll', handleScroll, { capture: true });
    };
  }, []);

  useEffect(() => {
    const refRightCurrent = refRight.current;
    if (refRightCurrent) {
      const onMouseDownRightResize = (event: PointerEvent): void => {
        if (!event.isPrimary) {
          return;
        }

        onResizeStart(columnId, width, minColumnWidth, event.clientX);

        document.addEventListener('pointermove', onMouseMoveRightResize);
        document.addEventListener('pointerup', onMouseUpRightResize);
      };

      const onMouseMoveRightResize = (event: PointerEvent): void => {
        setXCoordinates(event.clientX);
      };

      const onMouseUpRightResize = (): void => {
        document.removeEventListener('pointermove', onMouseMoveRightResize);
        document.removeEventListener('pointerup', onMouseUpRightResize);
        onResizeStop();
      };

      refRightCurrent.addEventListener('pointerdown', onMouseDownRightResize);

      return (): void => {
        refRightCurrent?.removeEventListener('pointerdown', onMouseDownRightResize);
      };
    }
  }, [minColumnWidth, columnId, onResizeStart, onResizeStop, setXCoordinates, width]);

  const isFiltered = filters.find((filter) => filter.fieldId === columnId);

  const isSorting = columnId === sorting?.fieldId;

  const buttonStyle = clsx(styles.optionButton, {
    [styles.optionButtonActive]: isFiltered || isSorting,
    [styles.optionButtonSorting]: isSorting,
    [styles.optionButtonFiltered]: isFiltered,
  });

  return (
    <th {...rest} style={{ ...style, ...(hasValue(leftOffset) && { left: leftOffset }) }}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{children}</div>

        {/* TODO Удалить закомментированный код и весь связанный функционал(сортировка/фильтрация) после подтверждения от бизнеса  */}
        {/* {isSortable && (
          <Tooltip
            trigger="click"
            text={<div ref={containerRef}>{renderTooltipComponent()}</div>}
            placement="bottom"
            visible={isOpen}
          >
            <button className={buttonStyle} ref={buttonRef} onClick={() => setOpen(true)}>
              <FilterIcon className={styles.filterIcon} />
              <ArrowDownIcon
                className={clsx(styles.arrowIcon, sorting?.direction === SORTING_DIRECTION.asc && styles.arrowIconAsc)}
              />
              <EllipsisIcon />
            </button>
          </Tooltip>
        )} */}
      </div>
      {isResizable && <div ref={refRight} className={styles.resizeHandler} />}
    </th>
  );
});
