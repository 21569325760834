import { TDictObject } from 'src/api/directories/types';
import { ApproachStageSection } from 'src/features/well-form/entities/approach-entities';
import { ItemContainer } from 'src/shared/entities/abstract-control-entities';
import { Directories } from 'src/store/directories/directories.store';

import {
  TApproachStageSectionRaw,
  TDateIntervalFieldRaw,
  TItemRaw,
  TPassingIntervalRaw,
  TSavedFormItems,
  TSerializedSection,
} from '../../types';
import { mapItems } from '../common-serializers';

type Options = {
  sectionType: TDictObject;
  fieldValues?: { name: string; value: unknown }[];
  reference: TApproachStageSectionRaw;
  directories: Directories;
  parentControl?: ItemContainer;
};

export const mapApproachStageSection = (
  { sectionType, fieldValues, reference, directories, parentControl }: Options,
  savedSection?: TSerializedSection
): ApproachStageSection => {
  const fields: TItemRaw[] = reference.items;

  const savedItems: TSavedFormItems = {};

  for (let key in savedSection) {
    if (key !== 'type') {
      savedItems[key] = savedSection[key];
    }
    const dateControl = reference.items.find(
      (item): item is TDateIntervalFieldRaw => item.control === 'DateTimeIntervalWithDuration'
    );
    const passingValueControl = reference.items.find(
      (item): item is TPassingIntervalRaw => item.control === 'PassingInterval'
    );
    if (['startDate', 'endDate'].includes(key) && dateControl) {
      savedItems[dateControl.fieldId] = {
        [dateControl.attrNames.startDate]: savedSection['startDate'],
        [dateControl.attrNames.endDate]: savedSection['endDate'],
      };
    }
    if (['passingValue', 'passingValueMin', 'passingValueMax'].includes(key) && passingValueControl) {
      savedItems[passingValueControl.fieldId] = {
        [passingValueControl.attrNames.passingValue]: savedSection['passingValue'],
        [passingValueControl.attrNames.passingValueMin]: savedSection['passingValueMin'],
        [passingValueControl.attrNames.passingValueMax]: savedSection['passingValueMax'],
      };
    }
  }

  fieldValues?.forEach((field) => {
    savedItems[field.name] = field.value;
  });

  const data = {
    fields: mapItems(fields, directories, savedItems),
    parentControl,
    sectionType,
  };

  return new ApproachStageSection(data);
};
