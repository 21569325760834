import { action, autorun, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { WellsChartApi } from 'src/api/chart/wells-chart-api';
import { WellsChartDataApi } from 'src/api/chart/wells-chart-data-api';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { RootStore } from 'src/store';

import { DataHeadersPresenter } from '../../features/data-headers/presenter/data-headers-presenter';
import { DataItemsBackgroundPresenter } from '../../features/data-items-background/presenter';
import { DataItemsEmptyPresenter } from '../../features/data-items-empty/presenter/data-items-empty-presenter';
import { DataItemsFullPresenter } from '../../features/data-items-full/presenter/data-items-full-presenter';
import { IndicatorsTableStore } from '../../features/indicators-table/indicators-table.store';
import { WellsChartDataModel } from '../../features/wells-chart/data/wells-chart-data-model';
import { WellsChartDataStorage } from '../../features/wells-chart/data/wells-chart-data-storage';
import { WellsDataPositionsCalculator } from '../../features/wells-chart/data/wells-data-positions-calculator';
import { Range } from '../../layers/model';
import { ChartGrouping } from '../../shared/chart-grouping';
import { DataView } from '../../shared/data-view/data-view';
import { ChartFiltersForm, FiltersFormStore } from '../../shared/filters-form.store';
import {
  generateKeyFromRange,
  parseStringToRange,
  StorageKeyManagerWithParser,
} from '../../shared/storage-key-manager';
import { TimelineController } from '../../shared/timeline-controller';
import { Viewport } from '../../shared/viewport/viewport';

import { ChartWell, WellsGroup } from './entities';
import { WellsGroupsAdapter } from './wells-groups-adapter';
import { WellsViewSettingsStore } from './wells-view-settings.store';

export class WellsChartStore {
  private readonly api: WellsChartApi;
  private readonly dataView: DataView;
  private readonly grouping: ChartGrouping;

  private readonly rootStore: RootStore;

  readonly horizontalViewport: Viewport;
  readonly verticalViewport: Viewport;
  readonly viewSettings: WellsViewSettingsStore;

  readonly chartDataModel: WellsChartDataModel;
  readonly dataHeadersPresenter: DataHeadersPresenter<WellsChartDataModel.ViewItemContent[] | null>;
  readonly dataItemsBackgroundPresenter: DataItemsBackgroundPresenter<WellsChartDataModel.ViewItemContent[] | null>;
  readonly dataItemsFullPresenter: DataItemsFullPresenter<WellsChartDataModel.ViewItemContent[] | null>;
  readonly dataItemsEmptyPresenter: DataItemsEmptyPresenter<WellsChartDataModel.ViewItemContent[] | null>;

  readonly indicators: IndicatorsTableStore;

  @observable isLoading = false;

  /** Used to block actions. */
  @observable isDataUpdating = false;
  /** Used for transparent loaders. */
  @observable isDataLoading = false;
  @observable searchTerm: string = '';
  @observable filtersForm?: FiltersFormStore;

  constructor(
    horizontalViewport: Viewport,
    dataView: DataView,
    rootStore: RootStore,
    viewSettings: WellsViewSettingsStore,
    horizontalViewportController: TimelineController,
    grouping: ChartGrouping,
    sourceRefs: WellsChartDataStorage.SourceRefs
  ) {
    this.api = new WellsChartApi();
    this.dataView = dataView;
    this.viewSettings = viewSettings;
    this.grouping = grouping;

    this.rootStore = rootStore;

    this.horizontalViewport = horizontalViewport;
    this.verticalViewport = new Viewport(0, 0);

    this.chartDataModel = new WellsChartDataModel(
      this.verticalViewport,
      horizontalViewport,
      dataView,
      new WellsChartDataApi(),
      new WellsChartDataStorage(
        new StorageKeyManagerWithParser<Range<number>, string>(generateKeyFromRange, parseStringToRange),
        dataView,
        new WellsDataPositionsCalculator(WellsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS),
        sourceRefs
      ),
      this.rootStore.editing
    );
    this.dataHeadersPresenter = new DataHeadersPresenter(this.verticalViewport, this.chartDataModel, {
      onGroupCollapsedStateChange: () => this.updateWellsSettings(),
    });
    this.dataItemsBackgroundPresenter = new DataItemsBackgroundPresenter(
      this.verticalViewport,
      horizontalViewport,
      this.chartDataModel,
      horizontalViewportController
    );

    this.dataItemsFullPresenter = new DataItemsFullPresenter(this.chartDataModel);
    this.dataItemsEmptyPresenter = new DataItemsEmptyPresenter(this.chartDataModel);

    this.indicators = new IndicatorsTableStore(
      this.horizontalViewport,
      this.rootStore.editing,
      this.rootStore.notifications
    );

    makeObservable(this);
  }

  @computed
  private get planVersionId(): number {
    const planVersionId = this.rootStore.editing.actualPlanVersionId;

    assert(hasValue(planVersionId), 'Invalid plan version ID.');

    return planVersionId;
  }

  @computed
  private get filtersFormValues(): { filter: ChartFiltersForm.FilterValues; grouping: string } {
    return {
      filter: this.filtersForm?.formValues?.filter || {},
      grouping: this.grouping.value,
    };
  }

  private updateWellsSettings(): void {
    if (this.chartDataModel.wellGroups) {
      this.viewSettings.updateWellGroupsSettingsThrottled(this.chartDataModel.wellGroups, this.grouping);
    }
  }

  private async getDataLength(filter: ChartFiltersForm.FilterValues, grouping: string | null): Promise<number> {
    if (!grouping) {
      throw new Error('Invalid grouping value.');
    }

    const wellsGroups = await this.api.getWells(this.planVersionId, grouping, this.searchTerm, filter);

    if (!wellsGroups) {
      return 0;
    }

    return wellsGroups.reduce((wellsNumber: number, wellsGroup) => wellsNumber + wellsGroup.items.length, 0);
  }

  private async search(): Promise<void> {
    this.loadChartData(this.planVersionId);
  }

  private onSearch = debounce(this.search.bind(this), 400);

  @action.bound
  onSearchChange(searchTerm: string): void {
    this.searchTerm = searchTerm;

    this.onSearch();
  }

  @computed
  get hasSelectedFilters(): boolean {
    return Object.values(this.filtersFormValues.filter).filter((fieldValue) => hasValue(fieldValue)).length > 0;
  }

  @flow.bound
  async *loadFilters(planVersionId: number): Promise<void> {
    try {
      const view = await this.rootStore.views.wellsChartFiltersView.loadView();
      yield;

      this.filtersForm = new FiltersFormStore(
        planVersionId,
        view,
        this.rootStore,
        this.onFiltersChange.bind(this),
        this.getDataLength.bind(this),
        this.grouping
      );

      await this.filtersForm.loadData();
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadFilters');
    }
  }

  @flow.bound
  async *loadChart(planVersionId: number) {
    try {
      await Promise.all([this.loadFilters(planVersionId), this.loadChartData(planVersionId)]);
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadChart');
    }
  }

  @flow.bound
  async *loadChartData(planVersionId: number) {
    try {
      this.isDataLoading = true;

      const { filter, grouping } = this.filtersFormValues;

      if (!grouping) {
        throw new Error('Invalid grouping value.');
      }

      const wellsGroups = await this.api.getWells(planVersionId, grouping, this.searchTerm, filter);
      yield;

      const previousWellsGroup = this.chartDataModel.wellGroups;

      const initializedWellsGroups: WellsGroup[] = WellsGroupsAdapter.init(wellsGroups, previousWellsGroup ?? null, {
        wellIdFieldName: this.viewSettings.view.view.carpet.wells.idAttrName,
      });

      const wellsSettingsByGroup = this.viewSettings.view.ownSettingsValues.wellsOrder?.find(
        ({ grouping }) => grouping === this.grouping.value
      )?.settings;

      this.chartDataModel.setWellsGroups(
        WellsGroupsAdapter.applyWellsGroupsSettings(initializedWellsGroups, wellsSettingsByGroup)
      );
    } finally {
      this.isDataLoading = false;
    }
  }

  async onFiltersChange(): Promise<void> {
    await this.loadChartData(this.planVersionId);
  }

  @computed
  get isGroupsCollapsed(): boolean {
    if (this.chartDataModel.wellGroups) {
      for (const wellsGroup of this.chartDataModel.wellGroups) {
        if (wellsGroup.isCollapsed) {
          return true;
        }
      }
    }

    return false;
  }

  @action.bound
  toggleGroupsIsCollapsed(): void {
    const { isGroupsCollapsed } = this;

    this.chartDataModel.wellGroups?.forEach((group) => {
      group.setIsCollapsed(!isGroupsCollapsed);
    });
    this.chartDataModel.recalculatePositions();
    this.updateWellsSettings();
  }

  @action.bound
  init(): VoidFunction {
    this.loadChart(this.planVersionId);

    const disposeViewSettings = this.viewSettings.init();

    const disposeModelVerticalViewportRange = autorun(() => {
      const { start, end } = this.verticalViewport;
      this.chartDataModel.setVerticalRange(start, end);
    });

    const disposeModelHorizontalViewportRange = autorun(() => {
      const { start, end } = this.horizontalViewport;
      this.chartDataModel.setHorizontalRange(start, end);
    });

    const disposeVerticalScrollMovement = reaction(
      () => ({ verticalDataRange: this.chartDataModel.verticalDataRange, dataViewType: this.dataView.type }),
      ({ verticalDataRange, dataViewType }, { verticalDataRange: prevVerticalDataRange }) => {
        Viewport.moveViewportToPercentagePosition(
          this.verticalViewport,
          verticalDataRange,
          prevVerticalDataRange,
          this.dataHeadersPresenter.dataViewHeight
        );
      }
    );

    const disposeVerticalScrollLimitsUpdate = reaction(
      () => ({
        verticalViewport: this.verticalViewport,
        verticalDataRange: this.chartDataModel.verticalDataRange,
        dataViewHeight: this.dataHeadersPresenter.dataViewHeight,
      }),
      ({ verticalViewport, verticalDataRange, dataViewHeight }) => {
        Viewport.updateVerticalViewportLimits(verticalViewport, verticalDataRange, dataViewHeight);
      }
    );

    const disposeChartDataModel = this.chartDataModel.init();

    const disposeShownStageAttributesNumberSetter = reaction(
      () => this.viewSettings.view.shownStageAttributesNumber,
      (shownStageAttributesNumber) => {
        this.chartDataModel.setShownStageAttributesNumber(shownStageAttributesNumber);
        this.chartDataModel.recalculatePositions();
      },
      { fireImmediately: true }
    );

    const disposeCalculationSettingsSetter = reaction(
      () => ({ isEditing: this.rootStore.editing.isEditing }),
      ({ isEditing }) => {
        if (isEditing) {
          this.chartDataModel.setChartSettings({
            calculation: WellsDataPositionsCalculator.EDITING_ELEMENT_SIZES_GETTERS,
          });
        } else {
          this.chartDataModel.setChartSettings({
            calculation: WellsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS,
          });
        }
      },
      { fireImmediately: true }
    );

    const disposeFilterAndGroupingSetter = reaction(
      () => this.filtersFormValues,
      ({ grouping, filter }, { filter: prevFilter }) => {
        this.chartDataModel.setFiltersAndGrouping(filter, grouping);

        if (filter !== prevFilter) {
          this.indicators.onFiltersChange(filter);
        }
      }
    );

    const disposeIndicators = this.indicators.init();

    const disposeDataReloading = reaction(
      () => this.planVersionId,
      (planVersionId) => {
        this.loadChartData(planVersionId);
      }
    );

    return () => {
      disposeModelVerticalViewportRange();
      disposeModelHorizontalViewportRange();
      disposeVerticalScrollLimitsUpdate();
      disposeVerticalScrollMovement();
      disposeChartDataModel();
      disposeShownStageAttributesNumberSetter();
      disposeCalculationSettingsSetter();
      disposeFilterAndGroupingSetter();
      disposeViewSettings();
      disposeIndicators();
      disposeDataReloading();
    };
  }

  @flow.bound
  async *onWellDelete(well: ChartWell): Promise<void> {
    try {
      this.isDataUpdating = true;

      await this.chartDataModel.deleteWell(this.planVersionId, well);

      await this.loadChartData(this.planVersionId);
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToUpdateChart');
    } finally {
      this.isDataUpdating = false;
    }
  }
}
