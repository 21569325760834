import { WellRigOperation } from '../../drilling-wells-chart/entities';

/** Well rig operation on wells chart in comparing mode. */
export class ComparingWellRigOperation extends WellRigOperation {
  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-comparing-rig-operation-${this.uniqueCreationKey}`;
    }

    return `comparing-rig-operation-${this.uniqueCreationKey}`;
  }
}
