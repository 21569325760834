import { observer } from 'mobx-react-lite';

import {
  ChartRig,
  RigsGroup,
  TemporaryChartRig,
  TemporaryRigsGroup,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { Viewport } from '../../../shared/viewport/viewport';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import { EditingRigsDataBackgroundItem } from './editing-rigs-data-background-item';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  verticalViewport: Viewport;
  onWellAdd(): void;
  onRigAdd(groupId: number): void;
}

export const EditingRigsDataItemsBackground = observer(function EditingRigsDataItemsBackground({
  data,
  verticalViewport,
  onWellAdd,
  onRigAdd,
}: Props) {
  return (
    <>
      {data?.map((item) => {
        if (
          item instanceof RigsGroup ||
          item instanceof ChartRig ||
          item instanceof TemporaryChartRig ||
          item instanceof TemporaryRigsGroup ||
          item instanceof SortableGhostItem
        ) {
          return (
            <EditingRigsDataBackgroundItem
              key={item.getKey('background')}
              item={item}
              verticalViewport={verticalViewport}
              onWellAdd={onWellAdd}
              onRigAdd={onRigAdd}
            />
          );
        }

        return null;
      })}
    </>
  );
});
