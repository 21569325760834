import { Collapse } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { AddWellButton } from 'src/features/drilling-rig-conflicts-sidebar/add-well-button';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';

import { Well } from '../well';
import { AddToChartSidebarWellEntity } from '../well/well.entity';

import { PadCollapseHeader } from './pad-collapse-header';
import { AddToChartSidebarPadEntity } from './pad.entity';

import styles from './pad.module.scss';

interface Props {
  pad: AddToChartSidebarPadEntity;
  isExpanded?: boolean;
  isCollapsed?: boolean;
  locateWell: (
    insertOnWell: AddToChartSidebarWellEntity | null,
    insertAfterWell: AddToChartSidebarWellEntity | null
  ) => void;
}

export const Pad = observer(function Pad({ pad, isCollapsed, isExpanded, locateWell }: Props) {
  const [activeKey, setActiveKey] = useState<string[] | string>([pad.id.toString()]);

  const attributeNames = getPadAttributeIcons(pad.pad);

  function handleChange(event: string[] | string): void {
    setActiveKey(event);
  }

  function getCollapsedStatus(): string[] | string {
    if (isCollapsed) {
      return [];
    }

    if (isExpanded) {
      return [pad.id.toString()];
    }

    return activeKey;
  }

  return (
    <div className={clsx(styles.container, styles.containerEditing)}>
      <Collapse
        destroyInactivePanel
        onChange={handleChange}
        activeKey={getCollapsedStatus()}
        expandIcon={({ isActive }) => (
          <DropdownIcon
            className={clsx(styles.expandIcon, isActive && styles.expandIconActive, styles.expandIconDisabled)}
          />
        )}
      >
        <Collapse.Panel header={<PadCollapseHeader title={pad.title} attributeIcons={attributeNames} />} key={pad.id}>
          {pad.wells.map((well, index) => {
            const locateWellInBegginingOrBetweenWells = (): void => {
              const prevWell = pad.wells[index - 1] || null;
              const current = well;

              locateWell(current, prevWell);
            };

            const locateWellAfterLastPad = (): void => {
              if (index !== pad.wells.length - 1) {
                return;
              }

              locateWell(null, well);
            };

            return (
              <React.Fragment key={well.id}>
                <AddWellButton onClick={locateWellInBegginingOrBetweenWells} />

                <Well well={well} />

                {index === pad.wells.length - 1 && <AddWellButton onClick={locateWellAfterLastPad} />}
              </React.Fragment>
            );
          })}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
