import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReduceIcon } from 'src/shared/assets/icons/reduce.svg';
import { ReactComponent as TableIcon } from 'src/shared/assets/icons/table.svg';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './full-screen-buttons.module.scss';

interface Props {
  onFullScreenExit: VoidFunction;
  onSummaryOpen?: VoidFunction;
  summaryButtonDisabled?: boolean;
}

export const FullScreenButtons = observer(function FullScreenButtons({
  onFullScreenExit,
  onSummaryOpen,
  summaryButtonDisabled,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {!!onSummaryOpen && (
        <Tooltip text={t('drillingCarpet:Buttons.summaryTooltip')} placement="topRight">
          <button className={styles.button} disabled={summaryButtonDisabled} onClick={onSummaryOpen}>
            <TableIcon className={styles.iconTable} />
          </button>
        </Tooltip>
      )}

      <Tooltip text={t('fullscreen')} placement="topRight">
        <button className={styles.button} onClick={onFullScreenExit}>
          <ReduceIcon className={styles.iconReduce} />
        </button>
      </Tooltip>
    </div>
  );
});
