import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler } from 'react';

import { DrillingStepColoredCard } from 'src/features/drilling-step-colored-card';
import { hasValue } from 'src/shared/utils/common';

import { WellRigOperationStage } from '../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../presets/drilling-wells-chart/wells-view-settings-provider';
import { ChartColors } from '../../shared/chart-colors';
import { useClickHandler } from '../../shared/use-click-handler';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';

import styles from './drilling-step-wrapper.module.scss';

interface Props {
  item: WellRigOperationStage;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewProvider: WellsViewSettingsProvider;
  onClick(wellId: string | number): void;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

export const DrillingStageWrapper = observer(function DrillingStageWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  viewProvider,
  onMouseOver,
  onMouseLeave,
  onClick,
  className,
  contentClassName,
  headerClassName,
}: Props) {
  const ref = useMovableElement({ item, horizontalViewport, verticalViewport });

  const handleClick = () => {
    if (hasValue(item.parentWellId)) {
      onClick(item.parentWellId);
    }
  };

  const { onPointerDown, onPointerUp } = useClickHandler(handleClick);

  return (
    <div ref={ref} className={styles.dataItem} onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
      <DrillingStepColoredCard
        title={viewProvider.getStageTitle(item)}
        color={ChartColors.yellow}
        attributes={viewProvider.getAttributes(item)}
        className={clsx(className, styles.card)}
        contentClassName={contentClassName}
        headerClassName={headerClassName}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        isInteractive={hasValue(item.parentWellId)}
      />
    </div>
  );
});
