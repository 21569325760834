import { action, comparer, computed, makeObservable, observable } from 'mobx';

import { Range } from '../../../layers/model';
import { ComparingIndicatorColumn } from '../../../presets/comparing-indicators-table/entities';
import { LoadingIndicatorsColumn } from '../../../presets/indicators-view-settings-sidebar/entities';
import { StorageKeyManager } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';

export class ComparingIndicatorsStorage {
  private readonly keyManager: StorageKeyManager<Range<number>, string>;

  @observable private _data: Map<string, ComparingIndicatorColumn | LoadingIndicatorsColumn>;
  @observable private range?: Range<number>;
  @observable private timeUnit?: TimeUnit;

  constructor(keyManager: StorageKeyManager<Range<number>, string>) {
    this.keyManager = keyManager;
    this._data = observable.map();

    makeObservable(this);
  }

  @action.bound
  set(data: (ComparingIndicatorColumn | LoadingIndicatorsColumn)[]): void {
    for (const column of data) {
      const rangeKey = this.keyManager.getKey(column.range);
      this._data.set(rangeKey, column);
    }
  }

  @action.bound
  setRange(start: number, end: number, timeUnit: TimeUnit): void {
    this.range = { start, end };
    this.timeUnit = timeUnit;
  }

  @computed({ equals: comparer.structural })
  get data():
    | {
        columns?: (ComparingIndicatorColumn | LoadingIndicatorsColumn)[];
        emptyRanges?: Range<number>[];
      }
    | undefined {
    if (!this.range || !this.timeUnit) {
      return undefined;
    }

    const columns: (ComparingIndicatorColumn | LoadingIndicatorsColumn)[] = [];
    const intermediateColumns = TimeRangeHelper.getIntermediateDates(this.range, this.timeUnit);

    const emptyRanges: Range<number>[] = [];
    let currentEmptyRange: Range<number> | null = null;

    for (const { start, end } of intermediateColumns) {
      const rangeKey = this.keyManager.getKey({ start, end });

      const foundColumnData = this._data.get(rangeKey);

      if (foundColumnData) {
        columns.push(foundColumnData);

        continue;
      }

      const emptyColumn = new LoadingIndicatorsColumn({ start, end });
      columns.push(emptyColumn);

      if (currentEmptyRange) {
        currentEmptyRange.end = end;
      } else {
        currentEmptyRange = { start, end };
      }
    }

    if (currentEmptyRange) {
      emptyRanges.push(currentEmptyRange);
    }

    return {
      columns,
      emptyRanges,
    };
  }

  @action.bound
  clear(): void {
    this._data.clear();
  }
}
