import { action, flow, makeObservable, observable } from 'mobx';

import { ConflictWell } from 'src/api/draft/conflict-well';
import { IWell } from 'src/api/draft/types';
import { RigConflictsApi } from 'src/api/rig-sidebar/rig-conflicts-api';
import { BaseApiError } from 'src/errors';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PadTypes } from 'src/types/pad-types';

import { Pad } from './pad';
import { Rig } from './rig';
import { Well } from './well';

export class RigStore {
  private readonly api = new RigConflictsApi();
  private readonly conflictRigOperation: ConflictWell;
  private readonly notifications: NotificationsStore;

  conflictWellPosition: RigStore.ConflictPosition | null = null;

  @observable isLoading = false;
  @observable padsStorage: PadTypes.RawPad[] = [];
  @observable pads: (Pad | ConflictWell)[] = [];
  @observable rig?: Rig;

  constructor(conflictPad: ConflictWell, notifications: NotificationsStore) {
    this.conflictRigOperation = conflictPad;
    this.notifications = notifications;

    makeObservable(this);
  }

  private filterConflictWells(wells: IWell[]): Well[] {
    this.conflictWellPosition = null;

    return wells.filter((well): well is Well => well instanceof Well);
  }

  private filterConflictPads(items: (Pad | ConflictWell)[]): Pad[] {
    this.conflictWellPosition = null;

    return items
      .filter((item): item is Pad => item instanceof Pad)
      .map((pad) => {
        pad.setChildren(this.filterConflictWells(pad.items));

        return pad;
      });
  }

  @flow.bound
  async *loadRig(planVersionId: number, rigId: number | string) {
    try {
      this.isLoading = true;

      const [rig, pads] = await Promise.all([this.api.getRig(rigId), this.api.getRigPads(rigId, planVersionId)]);
      yield;

      this.rig = rig;
      this.pads = pads;
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToLoadRigData');
    } finally {
      this.isLoading = false;
    }
  }

  serializePad(pad: Pad): PadTypes.RawPad {
    return JSON.parse(JSON.stringify(pad.item));
  }

  @action.bound
  placeWell(padId: number, index: number): void {
    this.pads = this.pads
      .filter((item): item is Pad => item instanceof Pad)
      .map((pad): Pad => {
        if (pad.id !== padId) {
          pad.setChildren(this.filterConflictWells(pad.items));
        }

        return pad;
      });

    const pad = this.pads.find(({ id }) => id === padId);
    const conflictWell = this.conflictRigOperation;

    if (!conflictWell || !pad || !(pad instanceof Pad)) {
      return;
    }

    const wellsLeft = this.filterConflictWells(pad.items.slice(0, index));
    const wellsRight = this.filterConflictWells(pad.items.slice(index));

    pad.setChildren([...wellsLeft, conflictWell, ...wellsRight]);

    this.conflictWellPosition = {
      insert: conflictWell,
      insertAfter: wellsLeft.at(-1),
      insertOnPlace: wellsRight.at(0),
    };
  }

  @action.bound
  placePadWithWell(index: number): void {
    const conflictWell = this.conflictRigOperation;

    if (!conflictWell) {
      return;
    }

    const pads = this.pads;
    const padsLeft = this.filterConflictPads(pads.slice(0, index));
    const padsRight = this.filterConflictPads(pads.slice(index));

    this.pads = [...padsLeft, conflictWell, ...padsRight];

    this.conflictWellPosition = {
      insert: conflictWell,
      insertAfter: padsLeft.at(-1),
      insertOnPlace: padsRight.at(0),
    };
  }
}

export namespace RigStore {
  export type ConflictPosition<TElement = Pad | Well> = {
    insert: ConflictWell;
    insertAfter?: TElement;
    insertOnPlace?: TElement;
  };
}
