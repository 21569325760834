import { computed, flow, makeObservable, observable } from 'mobx';

import { TJob, TJobResult, TJobStatus, fetchTriplesList } from 'src/api/import-file';

import { TTripleData } from '../../pages/import-matching-page/import-matching-page.store';

import { IJob } from './jobs-store';

export class ImportJob implements IJob {
  @observable private data: Omit<TJob, 'id'>;

  readonly id: number;

  timerId: number | null = null;

  @observable triplesWithError: TTripleData[] | null = null;

  constructor({ id, ...job }: TJob) {
    this.data = job;
    this.id = id;

    makeObservable(this);
  }

  @flow.bound
  private async *onComplete(jobData: TJob) {
    const planVersionId = jobData.jobResult?.planVersionId;

    if (planVersionId === undefined) {
      return;
    }

    const tripleList = await fetchTriplesList(planVersionId);
    yield;

    this.triplesWithError = tripleList.triples.filter((triple) => triple.hasErrors);
  }

  @computed
  get errorsCount(): number | null {
    return this.triplesWithError?.length ?? null;
  }

  @computed
  get status(): TJobStatus {
    return this.data.jobStatus;
  }

  @computed
  get result(): TJobResult | null {
    return this.data.jobResult;
  }

  @flow.bound
  async *update(jobData: TJob) {
    try {
      if (jobData.jobStatus === 'COMPLETED') {
        await this.onComplete(jobData);
      }
      yield;

      const { id, ...job } = jobData;
      this.data = job;
    } catch (error) {
      console.error(error);
    }
  }
}
