import { action, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';
import { getNextIndex } from 'src/shared/utils/index-generator';

import { RigsGroup, TemporaryChartRig, TemporaryRigsGroup } from '../../../presets/drilling-rigs-chart/entities';

export class TemporaryRigsStorage {
  private readonly getIndexForTemporaryRig = getNextIndex();
  private readonly getIndexForTemporaryRigGroup = getNextIndex();

  @observable private rigGroup: TemporaryRigsGroup | null = null;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setRig(rigsGroup: RigsGroup | undefined, rig: AddRigSidebar.Rig): TemporaryChartRig {
    if (rigsGroup) {
      const temporaryRig = new TemporaryChartRig(rig.typeId, `${rigsGroup.id}-${this.getIndexForTemporaryRig()}`, rig);
      const temporaryRigGroup = new TemporaryRigsGroup(
        rigsGroup.id,
        rigsGroup.getKey('temporary'),
        temporaryRig,
        rigsGroup
      );

      rigsGroup.setRelatedTemporaryGroup(temporaryRigGroup);

      if (rigsGroup.isCollapsed) {
        rigsGroup.setIsCollapsed(false);
      }

      this.rigGroup = temporaryRigGroup;

      return temporaryRig;
    } else {
      const temporaryRig = new TemporaryChartRig(rig.id, `non-group-${this.getIndexForTemporaryRig()}`, rig);
      this.rigGroup = new TemporaryRigsGroup(
        rig.modelId,
        `temporary-${this.getIndexForTemporaryRigGroup()}`,
        temporaryRig
      );

      return temporaryRig;
    }
  }

  @action.bound
  clear(): void {
    this.rigGroup?.parentGroup?.removeRelatedTemporaryRigsGroup();
    this.rigGroup = null;
  }

  mergeRigGroups = computedFn((rigsGroups: RigsGroup[]): (RigsGroup | TemporaryRigsGroup)[] => {
    if (this.rigGroup && !this.rigGroup.parentGroup) {
      return [this.rigGroup, ...rigsGroups];
    } else {
      return rigsGroups;
    }
  });
}
