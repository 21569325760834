import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { GroupCollapseHeader } from 'src/features/drilling-chart/features/group-collapse-header';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { ComparingChartRig } from '../../../presets/comparing-drilling-rigs-chart/entities';
import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { EmptyBlockWrapper } from '../../empty-block-wrapper';
import { RigsChartDataModel } from '../../rigs-chart/data/rigs-chart-data-model';

import { RowHeaderWrapper } from './row-header-wrapper';

import styles from './data-headers.module.scss';

interface Props {
  item: RigsChartDataModel.ViewItem;
  viewport: Viewport;
  viewProvider: RigsViewSettingsProvider;
  isCompactView: boolean;
  onIsCollapseToggle(item: RigsGroup): void;
  onRigClick(rigId: number): void;
}

export const ComparingRigsDataHeader = observer(function ComparingRigsDataHeader({
  item,
  viewport,
  isCompactView,
  onIsCollapseToggle,
  viewProvider,
  onRigClick,
}: Props) {
  if (item instanceof ComparingChartRig) {
    return (
      <EmptyBlockWrapper
        item={item}
        verticalViewport={viewport}
        className={clsx(styles.rowHeader, item.hasChanges && styles.rowHeaderComparing)}
        onClick={() => onRigClick(item.id)}
      />
    );
  }

  if (item instanceof ChartRig) {
    return (
      <RowHeaderWrapper
        item={item}
        viewport={viewport}
        isCompactView={isCompactView}
        title={<LoadingTitle field={viewProvider.getTitle(item)} />}
        descriptionAttributes={viewProvider.getAttributes(item)}
        onClick={onRigClick}
      />
    );
  }

  if (item instanceof RigsGroup) {
    return (
      <GroupCollapseHeader
        item={item}
        isCollapsed={item.isCollapsed}
        title={<LoadingTitle field={viewProvider.getTitle(item)} />}
        viewport={viewport}
        onClick={() => onIsCollapseToggle(item)}
        withArrow={true}
      />
    );
  }

  return null;
});
