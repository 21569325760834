import { TRefQuery } from 'src/store/directories/types';

export namespace UnplannedRigOperationsSidebar {
  export type View = {
    items: {
      fieldId: string;
      kind: string;
      label: string;
      pads: PadsView;
    };
    title: unknown;
    rowIdKey: string;
  };

  export type PadsView = {
    attrName: string;
    delimiter: string;
    refObjectAttrConcat: string[];
    refQuery: TRefQuery;
    wells: WellsView;
  };

  export type WellsView = {
    attrName: string;
    refObjectAttr: string;
    refObjectType: string;
    attributes: Attribute[];
    rigOperationIndex: string;
  };

  export type Attribute = {
    type: string;
    objectName: string;
  };

  export type PadObjectType = {
    id: number;
    objectType: string;
    data: Record<string, string | number | undefined>;
    items: Record<string, string | number | boolean>[];
  };

  export enum RIG_OPERATIONS_LIST_REQUEST_TYPES {
    planned = 'PLANNED',
    unplanned = 'UNPLANNED',
    all = 'ALL',
  }

  export enum RIG_OPERATIONS_LIST_GROUP_VARIANTS {
    pad = 'PAD',
    pro = 'PRO',
  }
}
