import { RefObject, useContext, useEffect } from 'react';

import { Dnd } from '../../types';
import { DndContext } from '../context/dnd-context';

export function useDroppable({ id, dataItem, nodeRef, options = {} }: UseDroppable.Args) {
  const { over, registerDroppable, unregisterDroppable } = useContext(DndContext);
  const { overClassname } = options;

  const isOver = over?.id === id;

  useEffect(() => {
    if (nodeRef.current) {
      registerDroppable(id, nodeRef.current, dataItem);

      return () => {
        unregisterDroppable(id);
      };
    }
  }, [dataItem, id, nodeRef, registerDroppable, unregisterDroppable]);

  useEffect(() => {
    const element = nodeRef.current;

    if (!element || !overClassname) {
      return;
    }

    if (isOver) {
      element.classList.add(overClassname);
    } else {
      element.classList.remove(overClassname);
    }

    return () => {
      element.classList.remove(overClassname);
    };
  }, [isOver, nodeRef, overClassname]);
}

export namespace UseDroppable {
  export type Options = Partial<{
    overClassname: string;
  }>;

  export type Args = {
    id: string;
    dataItem: Dnd.Droppable;
    nodeRef: RefObject<HTMLElement>;
    options?: Options;
  };
}
