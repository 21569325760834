import { action, flow, makeObservable, observable } from 'mobx';

import { WellApi } from 'src/api/well';
import { BaseApiError } from 'src/errors';
import { AssertionError } from 'src/errors/assert';
import { WellInfoSidebar } from 'src/features/well-info-sidebar/well-info-sidebar.store';
import { assert } from 'src/shared/utils/assert';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { WellTypes } from 'src/types/well-types';

import { hasValue } from '../../shared/utils/common';
import { EditingStore } from '../../store/editing/editing-store';

export class WellStore {
  private readonly editing: EditingStore;
  private readonly notifications: NotificationsStore;
  private readonly api = new WellApi();

  @observable selectedWell: WellTypes.RawWellWithStages | null = null;
  @observable initialSidebarTab?: WellInfoSidebar.Tabs;
  @observable isLoading: boolean = false;

  constructor(editing: EditingStore, notifications: NotificationsStore) {
    this.editing = editing;
    this.notifications = notifications;

    makeObservable(this);
  }

  @flow.bound
  async *fetchWell(geologicalTaskId: number | string) {
    try {
      this.isLoading = true;

      assert(hasValue(this.editing.actualPlanVersionId), 'Invalid plan version ID.');

      const well = await this.api.getWell(geologicalTaskId, this.editing.actualPlanVersionId);
      yield;
      this.selectedWell = well;
    } catch (e) {
      yield;
      if (e instanceof AssertionError) {
        throw e;
      }

      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToLoadWellData');
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  setInitialSidebarTab(tab: WellInfoSidebar.Tabs): void {
    this.initialSidebarTab = tab;
  }

  @action.bound
  clearSelectedValue(): void {
    this.selectedWell = null;
    this.initialSidebarTab = undefined;
  }
}
