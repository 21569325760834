import { EnableParamType, RestrictionsType } from 'src/api/wells-list';
import { TRefQuery } from 'src/store/directories/types';

export type ColumnType = {
  id: string;
  name: string;
  label: string;
  control: COLUMN_CONTROL;
  width: number;
  isShown: boolean;
  fixed: boolean;
  type?: COLUMN_TYPE;
  attrName?: string;
  columnsGroupId?: string;
  columnsGroupLabel?: string;
  numberOfDecimalPlaces?: number;
  children?: Omit<ColumnType, 'width'>[];
  objectType?: string;
  objectField?: string;
  isChangable?: boolean;
  refQuery?: TRefQuery;
  excludeFromSettings?: boolean;
  isMassEditable?: boolean;
  enableIf?: EnableParamType[];
  restrictions?: RestrictionsType;
};

export type ResizeParamsType = {
  isResize: boolean;
  leftOffset: number | null;
  columnIndex: number | null;
  columnWidth: number | null;
  minColumnWidth: number | null;
  leftColumnCoordinates: number | null;
  startCoordinates: number | null;
  xCoordinates: number | null;
};

export type AntColumnsType = {
  title: string | number | React.ReactNode;
  dataIndex: string;
  key: string;
  width: number;
  isShown?: boolean;
  control?: COLUMN_CONTROL;
  ellipsis?: boolean;
  children?: Omit<AntColumnsType, 'children'>[];
  objectType?: string;
  objectField?: string;
  attrName?: string;
  excludeFromSettings?: boolean;
  isMassEditable?: boolean;
  enableIf?: EnableParamType[];
};

export enum COLUMN_CONTROL {
  Field = 'Field',
  DateOnlyPicker = 'DateOnlyPicker',
  ComboBox = 'ComboBox',
  Progress = 'Progress',
  MultiComboBox = 'MultiComboBox',
}

export enum COLUMN_TYPE {
  String = 'String',
  Number = 'Number',
  DateTime = 'DateTime',
}

export enum CHANGED_VARIANT {
  deleted = 'deleted',
  changed = 'changed',
  added = 'added',
}

export type TableRow = { selected: boolean; rowChanged?: null | CHANGED_VARIANT } & Record<
  string,
  CompareCellType | CellType
>;

export type CellValueType = string | number | boolean | null | string[] | number[];

export type CellType = { value: CellValueType; isError: boolean };

export type CompareCellType = {
  first: CellValueType;
  second: CellValueType;
};

export type ComparePlanningWellType = Record<string, CellType | CompareCellType> & {
  rowChanged: null | CHANGED_VARIANT;
};
