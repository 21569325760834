import { observer } from 'mobx-react-lite';
import { MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { useDebounce } from 'src/shared/utils/use-debounce';

import { RigOperationsDnd } from '../rig-operations-dnd/rig-operations-dnd-module';

import { ControlBar } from './control-bar';
import { Pad } from './pad';
import { PadsAndWellsSidebarStore } from './pads-and-wells-sidebar.store';
import { Search } from './search';

import styles from './pads-and-wells-sidebar.module.scss';

type Props = {
  dndStore: RigOperationsDnd.DndContext | null;
  isOpened: boolean;
  store: PadsAndWellsSidebarStore;
  onClose: VoidFunction;
  onNewWellClick: VoidFunction;
  onWellClick(wellId: number | string): void;
};

export const PadsAndWellsSidebar = observer(function PadsAndWellsSidebar({
  dndStore,
  isOpened,
  store,
  onClose,
  onNewWellClick,
  onWellClick,
}: Props) {
  const { t } = useTranslation();

  const {
    isSidebarHidden,
    setIsPointerOutOfSidebar,
    setIsSidebarHidden,
    setIsPointerOutOfSidebarAndCheckOnActiveElement,
    fetchPads,
    chosenWellType,
    searchString,
    setSearchString,
  } = store;

  const debouncedSearchTerm = useDebounce(searchString, 400);
  const activeDraggableElement = dndStore?.active;

  function handleOpenNewWellForm(): void {
    onNewWellClick();
    onClose();
  }

  function handleWheel(e: MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  function handleLeave(): void {
    setIsPointerOutOfSidebarAndCheckOnActiveElement(!!activeDraggableElement);
  }

  function handleEnter(): void {
    setIsPointerOutOfSidebar(false);
  }

  useEffect(() => {
    store.init();
  }, [store, store.init]);

  useEffect(() => {
    if (isOpened) {
      fetchPads();
    }
  }, [debouncedSearchTerm, fetchPads, isOpened, chosenWellType]);

  useEffect(() => {
    if (isSidebarHidden && !activeDraggableElement) {
      setIsSidebarHidden(false);
    }
  }, [isSidebarHidden, activeDraggableElement, setIsSidebarHidden]);

  return (
    <Sidebar
      isHidden={isSidebarHidden}
      isOpened={isOpened}
      onClose={onClose}
      onLeave={handleLeave}
      onEnter={handleEnter}
      closeOnClickOutside={true}
      title={t('drawers:addPadDrawer.padOrWell')}
      size="l"
    >
      <ControlBar store={store} onNewWellClick={handleOpenNewWellForm} />

      <div className={styles.padsContainer} onWheel={handleWheel}>
        <Search className={styles.search} value={searchString} onSearch={setSearchString} />

        {store.isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader className={styles.loader} />
          </div>
        ) : (
          store.pads.map((pad) => <Pad key={pad.getKey()} pad={pad} onWellClick={onWellClick} />)
        )}
      </div>
    </Sidebar>
  );
});
