import { Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { ReactElement, CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';

import { DropdownPreloader } from '../form-components/form-select/dropdown-preloader';

import styles from './combobox.module.scss';

type Props<V = string | number> = {
  open?: boolean;
  defaultValue?: V | null;
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  searchValue?: string;
  bordered?: boolean;
  value?: V | null;
  options?: DefaultOptionType[];
  label?: string;
  loading?: boolean;
  showSearch?: boolean;
  filterOption?: (value: string, option?: DefaultOptionType) => boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  isDisabled?: boolean;
  dropdownLoading?: boolean;
  customDropdown?: ReactElement;
  dataTestId?: string;
  dropdownStyle?: CSSProperties;
  suffixIcon?: React.ReactNode;
  onDropdownVisibleChange?: (open: boolean) => void;
  onBlur?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  onSearch?: (value: string) => void;
  onChange?: (value: V) => void;
  dropdownRender?: (origin: ReactElement) => ReactElement;
  getPopupContainer?(props: unknown): HTMLElement;
};

export const Combobox = observer(function Combobox<V>({
  open,
  allowClear = true,
  className,
  bordered,
  defaultValue,
  placeholder,
  searchValue,
  value,
  options,
  loading,
  showSearch = true,
  isDisabled,
  dropdownLoading,
  customDropdown,
  dropdownStyle,
  dropdownRender: dropdownRenderProp,
  dataTestId,
  onDropdownVisibleChange,
  filterOption,
  onBlur,
  onSearch,
  onFocus,
  onClear,
  onClick,
  onChange,
  getPopupContainer,
  suffixIcon,
}: Props<V>) {
  const [localSearchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  function dropdownRender(origin: ReactElement): ReactElement {
    if (dropdownLoading) return <DropdownPreloader />;
    if (!!dropdownRenderProp) return dropdownRenderProp(origin);
    if (customDropdown) return customDropdown;
    return origin;
  }

  function localOnSearch(value: string): void {
    setSearchValue(value);
    onSearch?.(value);
  }

  function localFilterOption(value: string, option?: DefaultOptionType): boolean {
    if (option?.label?.toString().toLowerCase().includes(value.toLowerCase())) return true;
    return false;
  }

  function renderArrowIcon(): React.ReactNode {
    return suffixIcon ? suffixIcon : <ArrowIcon className={styles.icon} fill="var(--contrast)" />;
  }

  return (
    <Select<V>
      open={open}
      defaultValue={defaultValue}
      value={value}
      data-testid={dataTestId}
      allowClear={allowClear}
      searchValue={searchValue || localSearchValue}
      placeholder={placeholder || t('common:chooseValue')}
      className={className}
      suffixIcon={
        loading ? (
          <div className={styles.loaderWrapper}>
            <Spin size="small" spinning />
          </div>
        ) : (
          renderArrowIcon()
        )
      }
      bordered={bordered}
      showSearch={showSearch}
      disabled={isDisabled || loading}
      filterOption={filterOption || localFilterOption}
      dropdownRender={dropdownRender}
      options={options}
      dropdownStyle={dropdownStyle}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onClick={onClick}
      onClear={onClear}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={localOnSearch}
      onChange={onChange}
      getPopupContainer={getPopupContainer}
    />
  );
});

export default Select;
