import common from './common.json';
import dashboard from './dashboard.json';
import directories from './directories.json';
import drawers from './drawers.json';
import drillingCarpet from './drilling-carpet.json';
import errors from './errors.json';
import header from './header.json';
import matching from './matching.json';
import modals from './modals.json';
import newWellForm from './new-well-form.json';
import notifications from './notifications.json';
import plan from './plan.json';
import sidebar from './sidebar.json';
import wells from './wells.json';

export default {
  header,
  dashboard,
  directories,
  drillingCarpet,
  common,
  matching,
  newWellForm,
  sidebar,
  wells,
  plan,
  drawers,
  modals,
  notifications,
  errors,
};
