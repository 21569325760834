import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DraftsListItem } from 'src/features/plan-version-sidebar/drafts-list-item';
import { VersionsListItem } from 'src/features/plan-version-sidebar/versions-list-item';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { USER_ADMIN_ROLE, USER_EDITOR_ROLE } from 'src/shared/constants/user';
import { useStore } from 'src/store';
import { TPlanVersion, TActualPlansGroups, PlanVersionType } from 'src/store/comparison/types';

import styles from './versions-group.module.scss';

interface Props {
  versionsGroup: TActualPlansGroups;
  onVersionChange(planVersion: TPlanVersion): void;
  onDeletePlanVersion(planVersion: TPlanVersion): void;
  onDeleteDraftVersion(draftVersion: TPlanVersion): void;
}

export const VersionsGroup = observer(function VersionsGroup({
  versionsGroup,
  onVersionChange,
  onDeletePlanVersion,
  onDeleteDraftVersion,
}: Props) {
  const { t } = useTranslation();

  const { planVersion, auth } = useStore();

  if (!versionsGroup.plans.length) {
    return null;
  }

  const roles = auth.userInfo?.realm_access.roles;

  const role: 'admin' | 'editor' | 'user' = (() => {
    if (roles?.includes(USER_ADMIN_ROLE)) {
      return 'admin';
    }

    if (roles?.includes(USER_EDITOR_ROLE)) {
      return 'editor';
    }

    return 'user';
  })();
  const currentVersionId = planVersion.version?.id;

  const isRemovablePlanVersionGroup = versionsGroup.name === 'actualPersonal';

  return (
    <Collapse key={versionsGroup.name} defaultActiveKey={versionsGroup.name}>
      <Panel key={versionsGroup.name} header={t(`drawers:planVersion.${versionsGroup.name}`)} className={styles.text}>
        <ul className={styles.versionsList}>
          {versionsGroup.plans.map((version, index) => {
            const isVersionRemovingAvailable = isRemovablePlanVersionGroup && version.id !== currentVersionId;
            const versionType = version.data.versionType;
            const isRenamingAvailable =
              version.data.versionType !== PlanVersionType.draft &&
              role !== 'user' &&
              ((versionType === PlanVersionType.personal && ['admin', 'editor'].includes(role)) ||
                ([PlanVersionType.current, PlanVersionType.public].includes(versionType) && role === 'admin'));

            return versionsGroup.name === 'drafts' ? (
              <DraftsListItem
                key={index}
                version={version}
                onVersionChange={onVersionChange}
                onDeleteDraftVersion={onDeleteDraftVersion}
              />
            ) : (
              <VersionsListItem
                key={index}
                version={version}
                isRenamingAvailable={isRenamingAvailable}
                onVersionChange={onVersionChange}
                onRename={planVersion.renameVersion}
                onDeleteVersion={isVersionRemovingAvailable ? onDeletePlanVersion : undefined}
              />
            );
          })}
        </ul>
      </Panel>
    </Collapse>
  );
});
