import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { TimeUnit } from './time-unit';

export class TimelineController {
  @observable private timeUnit: TimeUnit;

  constructor(timeUnit: TimeUnit) {
    this.timeUnit = timeUnit;

    makeObservable(this);
  }

  @action.bound
  setTimeUnit(timeUnit: TimeUnit): void {
    this.timeUnit = timeUnit;
  }

  getNearestPoint(x: number): number {
    const currentPoint = moment.unix(x);

    switch (this.timeUnit) {
      case TimeUnit.month: {
        const previousPoint = moment(currentPoint).startOf('month');

        const diffInDays = Math.abs(previousPoint.diff(currentPoint, 'day'));

        if (diffInDays <= 16) {
          return previousPoint.unix();
        } else {
          const nextPoint = moment(previousPoint).add({ month: 1 });

          return nextPoint.unix();
        }
      }

      case TimeUnit.quarter: {
        const previousPoint = moment(currentPoint).startOf('quarter');

        const diffInMonths = Math.abs(previousPoint.diff(currentPoint, 'month'));

        if (diffInMonths < 2) {
          return previousPoint.unix();
        } else {
          const nextPoint = moment(previousPoint).add({ quarter: 1 });

          return nextPoint.unix();
        }
      }

      case TimeUnit.year: {
        const previousPoint = moment(currentPoint).startOf('year');

        const diffInMonths = Math.abs(previousPoint.diff(currentPoint, 'month'));

        if (diffInMonths <= 6) {
          return previousPoint.unix();
        } else {
          const nextPoint = moment(previousPoint).add({ year: 1 });

          return nextPoint.unix();
        }
      }

      default:
        return x;
    }
  }
}
