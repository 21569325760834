import {
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  DndContext,
  DragOverlay,
  Active,
  DragStartEvent,
  pointerWithin,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TriangleArrow } from 'src/shared/assets/icons/arrow-drop-icon.svg';
import { ReactComponent as CalendarIcon } from 'src/shared/assets/icons/calendar-2.svg';
import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { ReactComponent as InfoIcon } from 'src/shared/assets/icons/info-i.svg';
import { Button } from 'src/shared/components/button';
import { Tooltip } from 'src/shared/components/tooltip';
import { checkIsNode } from 'src/shared/utils/check-is-node';

import { Approach, ApproachesTab } from '../../entities/approach-entities';
import { ApproachesList } from '../../entities/approach-entities/approaches-list.entity';
import { FormStore } from '../../entities/form.entity';
import { TSplitApproachOptions } from '../../types';
import { StageDropdown } from '../stage-dropdown/stage-dropdown';
import { StageRow } from '../stage-row/stage-row';

import { ApproachField } from './approach-field/approach-field';

import styles from './approach.module.scss';

type Props = {
  approach: Approach;
  approachesList: ApproachesList;
  formStore: FormStore;
  index: number;
  onAddWellToChart: VoidFunction;
  onSplitApproach?({ sectionId, stage, approach }: TSplitApproachOptions): void;
  onBlur?(): void;
};

export const ApproachRow = observer(function ApproachRow({
  approach,
  approachesList,
  index,
  formStore,
  onAddWellToChart,
  onSplitApproach,
  onBlur,
}: Props) {
  const { t } = useTranslation();
  const [activeStage, setActiveStage] = useState<Active>();
  const [isApproachCollapsed, setIsApproachCollapsed] = useState(false);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const approachRef = useRef<HTMLDivElement>(null);

  const tab = formStore.activeTab instanceof ApproachesTab ? formStore.activeTab : undefined;
  const isEditing = !!approachesList.activeApproach;
  const isActiveApproach = isEditing && approachesList.activeApproach === approach.id;
  const isDeleteButtonShown = tab && !approach.isVisuallyDisabled;
  const overlayItem = activeStage ? approach.stagesList.getStageById(activeStage.id) : undefined;

  const toggleApproach = (): void => setIsApproachCollapsed(!isApproachCollapsed);

  const handleDragStart = ({ active }: DragStartEvent): void => {
    setActiveStage(active);
  };

  const handleSetActiveApproach = () => {
    if (approachesList.activeApproach === approach.id || (isEditing && !isActiveApproach)) return;
    approachesList.setActiveApproach(approach.id);
  };

  useEffect(() => {
    const handleClickOutsideCurrentApproach = (event: MouseEvent) => {
      if (
        !approachRef.current ||
        !isActiveApproach ||
        !checkIsNode(event.target) ||
        approachRef.current.contains(event.target) ||
        !document.getElementById('root')?.contains(event.target)
      ) {
        return;
      }
      onBlur?.();
      approachesList.setActiveApproach(null);
    };

    document.body.addEventListener('pointerup', handleClickOutsideCurrentApproach);

    return () => {
      document.body.removeEventListener('pointerup', handleClickOutsideCurrentApproach);
    };
  }, [isActiveApproach, formStore, approachesList, onBlur]);

  if (!tab) return null;

  const handleDeleteApproach = (): void => {
    if (approachesList.activeApproach === approach.id) {
      approachesList.setActiveApproach(null);
    }

    tab.approachesList.deleteApproach(approach.id);
  };

  return (
    <>
      <div className={styles.wrapper} ref={approachRef}>
        {isDeleteButtonShown && (
          <Button
            variant="default"
            className={styles.deleteButton}
            icon={<DeleteIcon width={18} height={18} />}
            onClick={handleDeleteApproach}
          />
        )}
        <div
          className={clsx(styles.approachWrapper, isEditing && !isActiveApproach && styles.inactiveApproach)}
          onPointerDown={handleSetActiveApproach}
        >
          <div className={styles.approachHeader}>
            <div className={clsx(styles.fieldsWrapper, styles.approachIndexWrapper)}>
              <button className={styles.collapseButton} onClick={toggleApproach}>
                <TriangleArrow className={clsx(styles.arrowIcon, isApproachCollapsed && styles.arrowIcon__closed)} />
              </button>

              <p className={styles.approachTitle}>{t('newWellForm:Approaches.index', { index: index + 1 })}</p>
            </div>

            <div className={styles.rightHeaderBlock}>
              <Tooltip
                trigger={['hover', 'click', 'focus']}
                placement="bottomLeft"
                align={{
                  offset: [-14],
                }}
                text={t('newWellForm:Tooltips.editingApproach')}
              >
                <button className={styles.infoIconButton}>
                  <InfoIcon width={20} height={20} className={styles.infoIcon} />
                </button>
              </Tooltip>
            </div>
          </div>

          <div className={clsx(styles.collapsingArea, isApproachCollapsed && styles.collapsingArea__collapsed)}>
            <div className={styles.fieldsWrapper}>
              <ApproachField firstElement item={approach.fieldsList[0]} formStore={formStore} />
              <ApproachField item={approach.fieldsList[1]} formStore={formStore} />
              {approachesList.isAddToChartButtonVisible(approach) && (
                <Button
                  variant="primary"
                  prefixIcon={<CalendarIcon stroke="white" />}
                  disabled={approach.isVisuallyDisabled}
                  onClick={onAddWellToChart}
                >
                  {t('newWellForm:Approaches.addToChart')}
                </Button>
              )}
            </div>

            <DndContext
              sensors={sensors}
              collisionDetection={pointerWithin}
              modifiers={[restrictToVerticalAxis]}
              onDragStart={handleDragStart}
              onDragEnd={approach.handleDragEnd}
            >
              <SortableContext items={toJS(approach.stagesList.stages)} strategy={verticalListSortingStrategy}>
                {approach.stagesList.stages.map((stage) => {
                  return (
                    <StageRow
                      key={stage.id}
                      formStore={formStore}
                      stageDropdownOptions={approach.stagesList.stageOptions}
                      stage={stage}
                      approach={approach}
                      onSplitApproach={onSplitApproach}
                      changeStageType={approach.stagesList.changeStageType}
                      deleteStage={approach.stagesList.deleteStage}
                    />
                  );
                })}
              </SortableContext>
              {createPortal(
                <DragOverlay>
                  {overlayItem ? (
                    <StageRow
                      overlay
                      stage={overlayItem}
                      approach={approach}
                      stageDropdownOptions={approach.stagesList.stageOptions}
                      formStore={formStore}
                      onSplitApproach={onSplitApproach}
                      changeStageType={approach.stagesList.changeStageType}
                      deleteStage={approach.stagesList.deleteStage}
                    />
                  ) : null}
                </DragOverlay>,
                document.body
              )}
            </DndContext>

            <StageDropdown
              options={approach.stagesList.stageOptions}
              isDisabled={approach.isVisuallyDisabled}
              addNewStage={approach.addNewStage}
            />
          </div>
        </div>
      </div>
    </>
  );
});
