import { useContext, useEffect, useState } from 'react';

import { DndContextStore } from '../../shared/entities/dnd-context.store';
import { DraggableStore } from '../../shared/entities/draggable.store';
import { Dnd } from '../../types';
import { DndContext } from '../context/dnd-context';

export namespace UseDraggable {
  export interface Args<TDraggableItem> {
    id: string;
    dataItem: Dnd.Draggable<TDraggableItem, unknown>;
    onClick?: VoidFunction;
    onMove?: VoidFunction;
    options?: DndContextStore.DraggingOptions;
  }
}

export function useDraggable<TDraggableItem>(args: UseDraggable.Args<TDraggableItem>) {
  const dndStore = useContext(DndContext);
  const [draggable] = useState(() => new DraggableStore(args, dndStore));

  const { transform, isDragging, onPointerCancel, onPointerMove, onPointerDown, onPointerUp, init, cancelDragging } =
    draggable;

  useEffect(() => {
    const disposeDraggable = init();

    return () => {
      disposeDraggable();
    };
  }, [draggable, init]);

  useEffect(() => {
    const onDocumentVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        cancelDragging();
      }
    };

    window.addEventListener('blur', cancelDragging);
    document.addEventListener('visibilitychange', onDocumentVisibilityChange);

    return () => {
      window.removeEventListener('blur', cancelDragging);
      document.removeEventListener('visibilitychange', onDocumentVisibilityChange);
    };
  }, [cancelDragging]);

  return {
    listeners: {
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onPointerCancel,
    },
    transform,
    isDragging,
  };
}
