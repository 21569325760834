export enum WellTypeIcon {
  vertical = 'vertical',
  horizontal = 'horizontal',
  inclined = 'inclined',
  multibore = 'multibore',
  multihole = 'multihole',
  horizontalWithPilot = 'horizontalWithPilot',
  multiboreWithPilot = 'multiboreWithPilot',
  multiholeWithPilot = 'multiholeWithPilot',
  inclinedWithPilot = 'inclinedWithPilot',
}

export const isWellTypeIconName = (value: unknown): value is WellTypeIcon =>
  typeof value === 'string' && Object.values<string>(WellTypeIcon).includes(value);
