import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoseChangesModal as CancelEditingConfirmationModal } from 'src/features/modals/lose-changes-modal';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as SaveIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as UploadIcon } from 'src/shared/assets/icons/cloud.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as SettingsIcon } from 'src/shared/assets/icons/gear.svg';
import { ReactComponent as AddIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button/button';
import { Tooltip } from 'src/shared/components/tooltip';
import { WellFormManager } from 'src/store/well-form-manager/well-form-manager';

import styles from './edit-mode-buttons.module.scss';

interface Props {
  pageStore: WellsPageStore;
  wellFormManager: WellFormManager;
}

export const EditModeButtons = observer(function EditModeButtons({ pageStore, wellFormManager }: Props) {
  const { t } = useTranslation();

  const [isCancelEditingConfirmationModalOpened, setIsCancelEditingConfirmationModalOpened] = useState<boolean>(false);

  function onOpenCancelEditingConfirmationModal(): void {
    setIsCancelEditingConfirmationModalOpened(true);
  }

  function onCloseCancelEditingConfirmationModal(): void {
    setIsCancelEditingConfirmationModalOpened(false);
  }

  function onConfirmCancelEditing(): void {
    pageStore.cancelEditing();
    onCloseCancelEditingConfirmationModal();
  }

  function onSaveChanges(): void {
    pageStore.setIsActionLoading(true);
    pageStore.saveChanges();
  }

  function onPublishChanges(): void {
    pageStore.setIsActionLoading(true);
    pageStore.publishChanges();
  }

  function onOpenViewSettingsSidebar(): void {
    pageStore.setIsViewSettingsSidebarOpened(true);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            variant="secondary"
            icon={<AddIcon />}
            onClick={wellFormManager.onWellAdd}
            disabled={pageStore.isActionLoading}
            isLoading={pageStore.isActionLoading}
          >
            {t('wells:newWell')}
          </Button>
          <Button
            className={styles.button}
            variant="primary"
            icon={<UploadIcon />}
            onClick={onPublishChanges}
            disabled={pageStore.isActionLoading}
            isLoading={pageStore.isActionLoading}
          >
            {t('common:Buttons.toPublish')}
          </Button>
          <Button
            className={styles.button}
            variant="danger"
            icon={<CloseIcon />}
            onClick={onOpenCancelEditingConfirmationModal}
            disabled={pageStore.isActionLoading}
            isLoading={pageStore.isActionLoading}
          >
            {t('common:Buttons.cancel')}
          </Button>
          <Button
            className={styles.button}
            variant="success"
            icon={<SaveIcon />}
            onClick={onSaveChanges}
            disabled={pageStore.isActionLoading}
            isLoading={pageStore.isActionLoading}
          >
            {t('common:Buttons.save')}
          </Button>
          <Tooltip text={t('common:displaySettings')} placement="bottomRight">
            <button className={styles.iconButton} onClick={onOpenViewSettingsSidebar}>
              <SettingsIcon />
            </button>
          </Tooltip>
        </div>
      </div>

      <CancelEditingConfirmationModal
        isOpened={isCancelEditingConfirmationModalOpened}
        handleClose={onCloseCancelEditingConfirmationModal}
        confirmCallback={onConfirmCancelEditing}
      />
    </>
  );
});
