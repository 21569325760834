import { AxiosResponse } from 'axios';

import { agent } from 'src/api/agent';
import { serializePlanningGroupType, mapPlanningFieldView, mapPlanningRigView } from 'src/api/planning/serializers';
import {
  PlanningViewType,
  PlanningGroupDataType,
  PlanningListType,
  ComparePlanningListType,
} from 'src/api/planning/types';
import { throwApiError } from 'src/api/utils';
import { RIG_OPERATIONS_GROUP, GROUP_TYPE, SortingObjectType, FilterObjectType } from 'src/pages/plan-page/types';

export function getPlanningGroups(groupType: GROUP_TYPE): Promise<AxiosResponse<PlanningGroupDataType[]>> {
  return agent.get('planning/groups', { params: { groupType: serializePlanningGroupType(groupType) } });
}

export async function getPlanningView(groupType: GROUP_TYPE): Promise<PlanningViewType> {
  if (groupType === GROUP_TYPE.field) {
    try {
      const { data } = await agent.get('view/PlanningField');
      return mapPlanningFieldView(data);
    } catch (e) {
      throwApiError(e);
    }
  }

  if (groupType === GROUP_TYPE.rig) {
    try {
      const { data } = await agent.get('view/PlanningRig');
      return mapPlanningRigView(data);
    } catch (e) {
      throwApiError(e);
    }
  }

  try {
    const { data } = await agent.get('view/PlanningField');
    return mapPlanningFieldView(data);
  } catch (e) {
    throwApiError(e);
  }
}

export async function getPlanningListField(
  fieldId: number,
  blockId: number,
  planVersionId: number,
  sorting: SortingObjectType | null,
  filter: FilterObjectType[] | null
): Promise<PlanningListType[]> {
  try {
    const { data } = await agent.post(
      'planning/field/list',
      {
        ...(sorting && { sorting }),
        ...(filter && { filter }),
      },
      {
        params: { fieldId, blockId, planVersionId },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function getPlanningListRig(
  rigId: number,
  planVersionId: number,
  sorting: SortingObjectType | null,
  filter: FilterObjectType[] | null
): Promise<PlanningListType[]> {
  try {
    const { data } = await agent.post(
      'planning/rig/list',
      {
        ...(sorting && { sorting }),
        ...(filter && { filter }),
      },
      {
        params: { rigId, planVersionId },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function getComparePlanningListField(
  fieldId: number,
  blockId: number,
  firstPlanVersionId: number,
  secondPlanVersionId: number,
  sorting: SortingObjectType | null,
  filter: FilterObjectType[] | null
): Promise<ComparePlanningListType[]> {
  try {
    const { data } = await agent.post(
      'planning/field/list/compare',
      {
        ...(sorting && { sorting }),
        ...(filter && { filter }),
      },
      {
        params: { fieldId, blockId, firstPlanVersionId, secondPlanVersionId },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function getComparePlanningListRig(
  rigId: number,
  firstPlanVersionId: number,
  secondPlanVersionId: number,
  sorting: SortingObjectType | null,
  filter: FilterObjectType[] | null
): Promise<ComparePlanningListType[]> {
  try {
    const { data } = await agent.post(
      'planning/rig/list/compare',
      {
        ...(sorting && { sorting }),
        ...(filter && { filter }),
      },
      {
        params: { rigId, firstPlanVersionId, secondPlanVersionId },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function computeRigOperations(
  planVersionId: number,
  insert: number,
  insertOnPlace: number | null,
  insertAfter: number | null,
  groupBy: RIG_OPERATIONS_GROUP = RIG_OPERATIONS_GROUP.pad
) {
  try {
    await agent.post('compute/rigOperation', {
      planVersionId,
      insertAfter,
      insert,
      insertOnPlace,
      groupBy,
    });
  } catch (e) {
    throwApiError(e);
  }
}

export async function computeRigOperationsList(
  planVersionId: number,
  insert: number[],
  insertOnPlace: number | null,
  insertAfter: number | null,
  groupBy: RIG_OPERATIONS_GROUP = RIG_OPERATIONS_GROUP.pad
) {
  try {
    await agent.post('compute/rigOperation/list', {
      planVersionId,
      insertAfter,
      insert,
      insertOnPlace,
      groupBy,
    });
  } catch (e) {
    throwApiError(e);
  }
}
