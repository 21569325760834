import { observable, makeObservable } from 'mobx';

import { PadAttributeIcon } from 'src/shared/constants/pad-attribute-icon';
import { assert } from 'src/shared/utils/assert';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { PadsItem } from '../../graph-info-sidebar';
import { Well } from '../tab-with-wells-entities/well.entity';

export class Pad {
  @observable wells: Well[] = [];

  private readonly pad: Record<string, unknown>;
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;
  private readonly appSettings: AppSettingsStore;
  private readonly padView: PadsItem;
  readonly id: number;

  constructor(
    rawPad: Record<string, unknown>,
    view: PadsItem,
    directories: Directories,
    appSettings: AppSettingsStore,
    notifications: NotificationsStore,
    id: number
  ) {
    this.padView = view;
    this.directories = directories;
    this.appSettings = appSettings;
    this.notifications = notifications;
    this.pad = rawPad;
    this.id = id;

    this.initializeWells();

    makeObservable(this);
  }

  private isWells(rawWells: unknown): rawWells is Record<string, unknown>[] {
    if (!Array.isArray(rawWells) || rawWells.some((rawWell) => typeof rawWell !== 'object' || rawWell === null)) {
      return false;
    }

    return true;
  }

  private initializeWells(): void {
    if (!('items' in this.pad) || !this.isWells(this.pad.items)) {
      return;
    }

    const wellView = this.padView.wells;

    this.pad.items.forEach((rawWell) => {
      const geologicalTaskId = rawWell['GOplan_GeologicalTask.id'];
      const planWellTripleId = rawWell['GOplan_PlanWellTriple.id'];

      assert(
        typeof geologicalTaskId === 'number' ||
          (typeof geologicalTaskId === 'string' && !Number.isNaN(Number(geologicalTaskId))),
        'id is not presented in well'
      );

      assert(typeof planWellTripleId === 'number', 'planWellTripleId is not a number');

      const well = new Well(
        wellView,
        this.directories,
        this.appSettings,
        rawWell,
        this.notifications,
        Number(geologicalTaskId),
        planWellTripleId
      );

      this.wells.push(well);
    });
  }

  get title(): string {
    const { refQuery, refObjectAttrConcat, attrName, delimiter } = this.padView;

    const shortAttrName = attrName.slice(attrName.indexOf('.') + 1);
    const attrValue = this.pad[shortAttrName];

    const refObject = this.directories.getJoinedObjectDeprecated(refQuery);
    const labelObject = refObject?.find(
      (refObjectItem) => refObjectItem[attrName]?.toString() === attrValue?.toString()
    );

    if (labelObject) {
      const labels = refObjectAttrConcat.map((refObjectAttr) => {
        return labelObject[refObjectAttr];
      });

      return labels.join(delimiter || ', ');
    }

    return '';
  }

  get padAttributesIcons(): PadAttributeIcon[] | null {
    return getPadAttributeIcons(this.pad);
  }
}
