import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/shared/components/button';
import { SortableList } from 'src/shared/components/sortable-list/sortable-list';

import { EditingIndicator, IndicatorSettings, IndicatorsSettings } from '../entities';

import styles from './indicators-view-settings.module.scss';

interface Props {
  settings: IndicatorsSettings;
  isLoading: boolean;
  onViewSettingsApply(indicators: IndicatorSettings[]): void;
}

export const IndicatorsViewSettings = observer(function IndicatorsViewSettingsSidebar({
  settings,
  isLoading,
  onViewSettingsApply,
}: Props) {
  const { t } = useTranslation();

  const [editingIndicators, setEditingIndicators] = useState<EditingIndicator[]>([]);
  const [isApplyBlocked, setIsApplyBlocked] = useState(false);

  useEffect(() => {
    const editingIndicators = settings.copyIndicators();
    setEditingIndicators(editingIndicators);
  }, [settings]);

  const onSave = () => {
    onViewSettingsApply(editingIndicators.map((item) => item.extractIndicator()));
  };

  const onError = (error?: Error) => {
    if (!error) {
      setIsApplyBlocked(false);
      return;
    }

    setIsApplyBlocked(true);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{t('drillingCarpet:Indicators.indicatorsDisplayOrder')}</p>

      <SortableList
        items={editingIndicators}
        onSortEnd={setEditingIndicators}
        onIsShownChange={(value, item) => item.setIsShown(value)}
        onError={onError}
        options={{ minChecked: 1 }}
      />

      <Button onClick={onSave} disabled={isApplyBlocked || isLoading} variant="primary" className={styles.applyButton}>
        {t('Buttons.apply')}
      </Button>
    </div>
  );
});
