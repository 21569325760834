import { action, autorun, computed, makeObservable } from 'mobx';

import { IModel } from '../../../layers/model';
import { IPresenter } from '../../../layers/presenter';
import { TimelineController } from '../../../shared/timeline-controller';
import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport, zoomViewport } from '../../../shared/viewport/viewport-calculator';
import { IHeaderColumn } from '../data/header-data-model.types';

export class HeaderPresenter implements IPresenter<IHeaderColumn[], number> {
  private isMoveMode = false;

  private readonly viewport;
  private readonly dataModel;
  private readonly viewportController: TimelineController;

  constructor(viewport: Viewport, dataModel: IModel<IHeaderColumn[]>, viewportController: TimelineController) {
    this.viewport = viewport;
    this.dataModel = dataModel;
    this.viewportController = viewportController;

    this.dataModel.setRange(this.viewport.start, this.viewport.end);

    makeObservable(this);
  }

  init(): VoidFunction {
    const disposeRangeSetter = autorun(() => {
      const { start, end } = this.viewport;
      this.dataModel.setRange(start, end);
    });

    return () => {
      disposeRangeSetter();
    };
  }

  @computed
  get data() {
    return this.dataModel.getData();
  }

  @action.bound
  onPointerDown(): void {
    this.isMoveMode = true;
  }

  @action.bound
  onPointerMove(offsetX: number): void {
    if (!this.isMoveMode) {
      return;
    }

    const { movedStart, movedEnd } = moveViewport(this.viewport, offsetX);
    this.viewport.setRange(movedStart, movedEnd);
  }

  @action.bound
  onPointerUp(): void {
    this.isMoveMode = false;

    const { start: viewportStart, end: viewportEnd } = this.viewport;
    const startNearestPoint = this.viewportController.getNearestPoint(viewportStart);
    const endNearestPoint = this.viewportController.getNearestPoint(viewportEnd);

    this.viewport.setRange(startNearestPoint, endNearestPoint - 1, { animate: true });
  }

  @action.bound
  onZoom(offset: number, centerPosition: number): void {
    const { zoomedStart, zoomedEnd } = zoomViewport(this.viewport, offset, centerPosition);

    this.viewport.setRange(zoomedStart, zoomedEnd);
  }

  @action.bound
  onZoomOut(): void {
    const { start: viewportStart, end: viewportEnd } = this.viewport;
    const startNearestPoint = this.viewportController.getNearestPoint(viewportStart);
    const endNearestPoint = this.viewportController.getNearestPoint(viewportEnd);

    this.viewport.setRange(startNearestPoint, endNearestPoint - 1, { animate: true });
  }
}
