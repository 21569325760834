import { RootStore } from 'src/store';

import { FormPlugin } from './abstract-form-plugin.entity';
import { AdditionalConditionalAttributesPlugin } from './additional-conditiona-attrs-plugin';
import { ApproachesDatesDependencyPlugin } from './approaches-dates-dependency.plugin';
import { ArchivedDirectoryValuesInUnplannedGeotaskPlugin } from './archived-directory-values-in-unplanned-geotask';
import { BlockCommSpeedPlugin } from './block-comm-speed-plugin';
import { CalculatedValuePlugin } from './calculated-value';
import { ComboboxOnChangePlugin } from './combobox-on-change.plugin';
import { ComputeValuesPlugin } from './compute-values-plugin';
import { DirectoryValuesInStagesPlugin } from './directory-values-in-stages-plugin/directory-values-in-stages-plugin';
import { DirectoryValuesPlugin } from './directory-values-plugin';
import { DisableApproachesTabPlugin } from './disable-approaches-tab.plugin';
import { DrilledWellPlugin } from './drilled-well.plugin';
import { DynamicJoinPlugin } from './dynamic-join.plugin';
import { EnableIfPlugin } from './enableIf.plugin';
import { ExpandApproachesFunctionalityPlugin } from './expand-approaches-functionality.plugin';
import { ExpandSectionsFunctionalityPlugin } from './expand-sections-functionality.plugin';
import { FilterRegularComboboxOptionsPlugin } from './filter-regular-combobox-options.plugin';
import { getPluginsWithFormulas } from './hardcoded-formulas';
import { ReadOnlyPlugin } from './read-only.plugin';
import { RequiredIfPlugin } from './requiredIf.plugin';
import { StagePassingAndDatesDisabling } from './stage-passing-and-dates-disabling';

export function getDefaultFormPlugins(rootStore: RootStore): FormPlugin[] {
  return [
    ...getPluginsWithFormulas(rootStore),
    new RequiredIfPlugin(rootStore),
    new EnableIfPlugin(rootStore),
    new DynamicJoinPlugin(rootStore),
    new ComboboxOnChangePlugin(rootStore),
    new FilterRegularComboboxOptionsPlugin(rootStore),
    new DisableApproachesTabPlugin(rootStore),
    new ExpandApproachesFunctionalityPlugin(rootStore),
    new ExpandSectionsFunctionalityPlugin(rootStore),
    new DrilledWellPlugin(rootStore),
    new ReadOnlyPlugin(rootStore),
    new ApproachesDatesDependencyPlugin(rootStore),
    new CalculatedValuePlugin(rootStore),
    new DirectoryValuesPlugin(rootStore),
    new ComputeValuesPlugin(rootStore),
    new AdditionalConditionalAttributesPlugin(rootStore),
    new DirectoryValuesInStagesPlugin(rootStore),
    new ArchivedDirectoryValuesInUnplannedGeotaskPlugin(rootStore),
    new BlockCommSpeedPlugin(rootStore),
    new StagePassingAndDatesDisabling(rootStore),
  ];
}
