import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';
import { LoadingField } from 'src/shared/utils/loading-field';

import styles from './indicator-cell.module.scss';

interface Props {
  value?: string | number | LoadingField;
  className?: string;
}

export const IndicatorCell = observer(function IndicatorCell({ value, className }: Props) {
  return (
    <div className={clsx(className, styles.container)}>
      {value instanceof LoadingField ? (
        <LoadingTitle field={value} />
      ) : (
        <p className={clsx(styles.title, !value && styles.emptyValue)}>{value}</p>
      )}
    </div>
  );
});
