import { observer } from 'mobx-react-lite';

import { LoadingRigOperations } from '../../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';

import styles from './data-items-full.module.scss';

interface Props {
  item: LoadingRigOperations;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
}

export const LoadingRigOperationsWrapper = observer(function LoadingRigOperationsWrapper({
  item,
  horizontalViewport,
  verticalViewport,
}: Props) {
  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });

  return <div ref={ref} className={styles.loadingBlock} />;
});
