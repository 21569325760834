import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { TValuesInterpretations } from 'src/store/directories/types';

import { TDictObject } from './types';

export const fetchDirectory = async <T = TDictObject[]>(dirName: string): Promise<T> => {
  try {
    const res = await agent.get<T>(`object/list/${dirName}?DELETED=true`);
    return res.data;
  } catch (error) {
    throwApiError(error);
  }
};

export const fetchSettingsLabels = async (): Promise<Record<string, string>> => {
  try {
    const { data: settingsLabels } = await agent.get('settings/labels');

    return settingsLabels;
  } catch (e) {
    throwApiError(e);
  }
};

export const fetchValuesInterpretations = async (): Promise<TValuesInterpretations> => {
  try {
    const { data: valuesInterpretations } = await agent.get('settings/value_interpretations');

    return valuesInterpretations;
  } catch (e) {
    throwApiError(e);
  }
};
