import { TDictObject } from 'src/api/directories/types';

export const isDictObject = (value: unknown): value is TDictObject => {
  if (typeof value === 'object' && value !== null) {
    if ('data' in value && 'id' in value && 'objectType' in value) {
      return true;
    }
  }
  return false;
};
