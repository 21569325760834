import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { action, computed, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { mapApproach } from 'src/api/new-well/serializers/approaches-serializers';
import { serializeApproach } from 'src/api/new-well/serializers/serialize-form-for-request';
import { TApproachStageRaw, TApproachRaw, TSerializedStage } from 'src/api/new-well/types';
import { hasValue } from 'src/shared/utils/common';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { Directories } from 'src/store/directories/directories.store';

import { Item, ItemContainer, ValidatableItem } from '../../../../shared/entities/abstract-control-entities';

import { ApproachStagesList } from './approach-stages-list.entity';

type TApproachData = {
  id?: number;
  fields: Item[];
  indexAttrName: string;
  rigOperationIdAttrName: string;
  stageReference?: TApproachStageRaw;
  savedStages?: TSerializedStage[];
  approachReference: TApproachRaw;
  directories: Directories;
};

export class Approach extends ItemContainer {
  readonly id: number;
  readonly approachReference: TApproachRaw;
  readonly stageReference?: TApproachStageRaw;
  readonly directories: Directories;
  readonly stagesList: ApproachStagesList;
  readonly indexAttrName: string;
  readonly rigOperationIdAttrName: string;

  onDeleteCallbacks: VoidFunction[] = [];

  @observable startDate: Moment | null = null;
  @observable endDate: Moment | null = null;
  @observable rigOperationId?: number = undefined;
  @observable isVisuallyDisabled = false;

  constructor(data: TApproachData) {
    super(data.fields);

    this.id = data.id ?? -getRandomNumber();
    this.stagesList = new ApproachStagesList({
      directories: data.directories,
      stageTemplate: data.approachReference.stageTemplate,
      savedStages: data.savedStages,
      approach: this,
    });
    this.indexAttrName = data.indexAttrName;
    this.rigOperationIdAttrName = data.rigOperationIdAttrName;
    this.directories = data.directories;
    this.stageReference = data.stageReference;
    this.approachReference = data.approachReference;

    makeObservable(this);
  }

  @computed
  get duration(): number | null {
    if (!this.startDate || !this.endDate) {
      return null;
    }

    return this.startDate.unix() - this.endDate.unix();
  }

  @computed
  get drillingWorksDuration(): number | null {
    return this.stagesList.stages.reduce((acc: number | null, currStage) => {
      if (!currStage.stageType.data['hasSections']) {
        return acc;
      }

      const duration = currStage.calculatedDuration ?? currStage.dateField?.duration;
      if (hasValue(duration)) {
        return hasValue(acc) ? acc + duration : duration;
      }

      return acc;
    }, null);
  }

  @action.bound
  setStartDate(date: Moment | null): void {
    if (date?.valueOf() !== this.startDate?.valueOf()) {
      this.startDate = date;
    }
  }

  @action
  setEndDate = (date: Moment | null): void => {
    if (date?.valueOf() !== this.endDate?.valueOf()) {
      this.endDate = date;
    }
  };

  @action.bound
  setIsVisuallyDisabled(is: boolean): void {
    this.isVisuallyDisabled = is;
    this.stagesList.setIsVisuallyDisabled(is);
  }

  @action.bound
  addNewStage(stageTypeId: string | number): void {
    this.stagesList.addNewStage(stageTypeId);
  }

  @action.bound
  setRigOperationId(rigOperationId: number): void {
    this.rigOperationId = rigOperationId;
  }

  @action.bound
  validate(): void {
    this.fieldsList.forEach((field) => {
      if (field instanceof ValidatableItem) {
        field.hasErrors();
      }
    });
    this.stagesList.validate();
  }

  @action.bound
  handleDragEnd({ active, over }: DragEndEvent): void {
    if (over && active.id !== over.id) {
      const activeStage = this.stagesList.getStageById(active.id);
      const overStage = this.stagesList.getStageById(over.id);
      if (activeStage && overStage) {
        const activeIndex = this.stagesList.stages.indexOf(activeStage);
        const overIndex = this.stagesList.stages.indexOf(overStage);
        const activeStageDate = activeStage.dateField?.startDate || null;
        const overStageDate = overStage.dateField?.startDate || null;
        const swappedStages = arrayMove(this.stagesList.stages, activeIndex, overIndex);
        this.stagesList.setStagesAndSwapDates(swappedStages, activeIndex, overIndex, activeStageDate, overStageDate);
      }
    }
  }

  clone(): Approach | null {
    const serializedCurrentApproach = serializeApproach(this);
    return mapApproach(
      { approachReference: this.approachReference, directories: this.directories },
      serializedCurrentApproach
    );
  }
}
