import { flow, makeObservable, observable } from 'mobx';

import { PadApi } from 'src/api/pad-info-sidebar/pad-api';
import { BaseApiError } from 'src/errors/errors';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PadTypes } from 'src/types/pad-types';
import { WellTypes } from 'src/types/well-types';

export type TPadWithWells = PadTypes.RawPad['data'] & {
  wells: WellTypes.RawRigOperation[];
};

export class PadStore {
  private readonly api = new PadApi();
  private readonly notifications: NotificationsStore;

  @observable isLoading = false;
  @observable pad?: PadTypes.RawPad;
  @observable wells?: WellTypes.RawRigOperation[];

  constructor(notifications: NotificationsStore) {
    this.notifications = notifications;

    makeObservable(this);
  }

  // Нужно для получения куста со списком скважин для дальнейшего использования в информационном сайдбаре
  get padWithWells(): TPadWithWells | null {
    if (!this.pad || !this.wells) {
      return null;
    }

    return {
      ...this.pad.data,
      wells: this.wells,
    };
  }

  @flow.bound
  async *loadPad(padId: number, planVersionId: number) {
    try {
      this.isLoading = true;

      const [pad, wells] = await Promise.all([this.api.getPad(padId), this.api.getWells(padId, planVersionId)]);
      yield;

      this.pad = pad;
      this.wells = wells;
    } catch (e) {
      yield;

      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToLoadPadData');
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
