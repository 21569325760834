import { Collapse } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Pad } from 'src/features/graph-info-sidebar/entities/tab-with-pads-entities/pad.entity';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';

import { PadWells } from '../../pad-wells/pad-wells';

import { PadCollapseHeader } from './pad-collapse-header';

import styles from './pad-component.module.scss';

type Props = {
  isEditing: boolean;
  pad: Pad;
  onWellClick?(wellId: number): void;
  onPadClick?(padId: number): void;
  onWellAdd?: VoidFunction;
  isExpanded?: boolean;
  isCollapsed?: boolean;
};

export const PadComponent = observer(function PadComponent({
  isEditing,
  pad,
  isCollapsed,
  isExpanded,
  onWellClick,
  onPadClick,
  onWellAdd,
}: Props) {
  const { t } = useTranslation('drawers');

  const [activeKey, setActiveKey] = useState<string[] | string>([pad.id.toString()]);

  function handleChange(event: string[] | string) {
    setActiveKey(event);
  }

  function getCollapsedStatus(): string[] | string {
    if (isCollapsed) {
      return [];
    }

    if (isExpanded) {
      return [pad.id.toString()];
    }

    return activeKey;
  }

  return (
    <div className={clsx(styles.container, isEditing && styles.containerEditing)}>
      <Collapse
        destroyInactivePanel={true}
        onChange={handleChange}
        activeKey={getCollapsedStatus()}
        expandIcon={({ isActive }) => (
          <DropdownIcon className={clsx(styles.expandIcon, isActive && styles.expandIconActive)} />
        )}
      >
        <Collapse.Panel
          header={null}
          key={pad.id}
          extra={
            <PadCollapseHeader
              title={pad.title}
              buttonText={t('drillingRigDrawer.addWell')}
              editing={isEditing}
              attributeIcons={pad.padAttributesIcons}
              onClick={!!onPadClick ? () => onPadClick(pad.id) : undefined}
              onWellAdd={onWellAdd}
            />
          }
        >
          <PadWells wells={pad.wells} onWellClick={onWellClick} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
