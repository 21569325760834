import { action, computed, makeObservable, observable } from 'mobx';

import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { Dnd } from 'src/features/drilling-chart/features/editing/types';
import { Range } from 'src/features/drilling-chart/layers/model';
import { NeighborScheme } from 'src/features/drilling-chart/shared/entities/neighbor-scheme';
import { WithKey } from 'src/features/drilling-chart/types';
import { hasValue } from 'src/shared/utils/common';

import { SidebarWellRigOperation } from './well-rig-operation.entity';

type TSidebarPadOptions = {
  title: string;
  uniqueCreationKey: string;
  pad: UnplannedRigOperationsSidebar.PadObjectType;
  wells: SidebarWellRigOperation[];
  groupVariant: UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS;
};

export class SidebarPadRigOperation implements Dnd.Draggable<SidebarPadRigOperation, SidebarPadRigOperation>, WithKey {
  readonly data: Record<string, string | number | undefined>;
  readonly id: number;
  readonly title: string;
  readonly wells: SidebarWellRigOperation[];
  readonly pad: UnplannedRigOperationsSidebar.PadObjectType;
  readonly groupVariant: UnplannedRigOperationsSidebar.RIG_OPERATIONS_LIST_GROUP_VARIANTS;

  private readonly uniqueCreationKey: string;

  neighborScheme?: NeighborScheme<SidebarWellRigOperation>;

  @observable y: Range<number> = { start: 0, end: 0 };

  constructor({ title, pad, wells, uniqueCreationKey, groupVariant }: TSidebarPadOptions) {
    this.title = title;
    this.uniqueCreationKey = uniqueCreationKey;
    this.pad = pad;
    this.data = pad.data;
    this.id = pad.id;
    this.wells = this.setWells(wells);
    this.groupVariant = groupVariant;

    makeObservable(this);
  }

  private setWells(wells: SidebarWellRigOperation[]): SidebarWellRigOperation[] {
    let sortedWells = wells.sort((first, second) => {
      if (hasValue(first.start) && hasValue(second.start)) {
        return first.start - second.start;
      }

      return 0;
    });

    for (let wellIndex = 0; wellIndex < sortedWells.length; wellIndex++) {
      const previousWell = sortedWells[wellIndex - 1];
      const currentWell = sortedWells[wellIndex];
      const nextWell = sortedWells[wellIndex + 1];

      if (currentWell) {
        const leftNeighbor: NeighborScheme.NeighborInfo<SidebarWellRigOperation> =
          previousWell?.end && currentWell.start
            ? {
                distance: currentWell.start - previousWell.end,
                neighbor: previousWell,
              }
            : {
                neighbor: undefined,
              };

        const rightNeighbor: NeighborScheme.NeighborInfo<SidebarWellRigOperation> =
          nextWell?.start && currentWell.end
            ? {
                distance: nextWell.start - currentWell.end,
                neighbor: nextWell,
              }
            : {
                neighbor: undefined,
              };

        currentWell.setNeighborScheme(new NeighborScheme(leftNeighbor, rightNeighbor));
      }
    }

    return sortedWells;
  }

  get x(): Range<number> {
    const start = this.firstWell?.start;
    const end = this.lastWell?.end;

    return {
      start: start ?? 0,
      end: end ?? 0,
    };
  }

  @computed
  get firstWell(): SidebarWellRigOperation | undefined {
    return this.wells.at(0);
  }

  @computed
  get lastWell(): SidebarWellRigOperation | undefined {
    return this.wells.at(-1);
  }

  getFieldValue(fieldName: string): string | number | undefined {
    if (fieldName in this.data) {
      return this.data[fieldName];
    }
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-sidebar-pad-${this.uniqueCreationKey}`;
    }

    return `sidebar-pad-${this.uniqueCreationKey}`;
  }

  setNeighborScheme(scheme: NeighborScheme<SidebarWellRigOperation>): void {
    this.neighborScheme = scheme;
  }

  @action.bound
  setY(value: Range<number>): void {
    this.y = value;
  }

  clone(): SidebarPadRigOperation {
    return new SidebarPadRigOperation({
      title: this.title,
      wells: this.wells,
      pad: this.pad,
      uniqueCreationKey: this.uniqueCreationKey,
      groupVariant: this.groupVariant,
    });
  }

  getShadow(): SidebarPadRigOperation | null {
    return new SidebarPadRigOperation({
      title: this.title,
      wells: [...this.wells],
      pad: this.pad,
      uniqueCreationKey: this.getKey('shadow'),
      groupVariant: this.groupVariant,
    });
  }
}
