import { makeObservable, action, observable, flow } from 'mobx';

import { getUnplannedRigOperationsView, UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { Pad } from 'src/features/unplanned-rig-operations-sidebar/entities/pad.entity';
import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

export class UnplannedRigOperationsSidebarStore {
  @observable isLoading: boolean = false;
  @observable pads: Pad[] = [];
  @observable selectedWellKey: number | null = null;
  @observable view: null | UnplannedRigOperationsSidebar.View = null;

  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;

  constructor(store: RootStore) {
    this.directories = store.directories;
    this.notifications = store.notifications;

    makeObservable(this);
  }

  init = () => {
    this.fetchViewSidebar();
  };

  getSelectedWellId(): number | null {
    if (hasValue(this.selectedWellKey)) {
      for (const pad of this.pads) {
        for (const well of pad.wells) {
          if (well.id === this.selectedWellKey) {
            return well.wellId;
          }
        }
      }
    }

    return null;
  }

  @action.bound
  setSelectedWellKey(selectedWellKey: number | null) {
    this.selectedWellKey = selectedWellKey;
  }

  @flow.bound
  private async *fetchViewSidebar() {
    this.isLoading = true;

    try {
      const view = await getUnplannedRigOperationsView();
      const { refQuery, wells } = view.items.pads;

      await Promise.all([
        this.directories.loadObjects([wells.refObjectType]),
        this.directories.loadJoinedObjectsDeprecated([refQuery]),
      ]);
      yield;

      this.view = view;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *fetchUnplannedRigOperations() {
    // TODO Написать новый метод для сайдбара
  }
}
