import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import {
  CSSProperties,
  MouseEventHandler,
  PointerEventHandler,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import Card from 'src/shared/components/card/card';
import { useOnClick } from 'src/shared/hooks/use-on-click';
import { LoadingField } from 'src/shared/utils/loading-field';

import styles from './pad-card.module.scss';

interface Props {
  title: ReactElement;
  attributes?: string[] | LoadingField[] | null;
  extra?: ReactNode;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  onPointerDown?: PointerEventHandler<HTMLDivElement>;
  onPointerUp?: PointerEventHandler<HTMLDivElement>;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  style?: CSSProperties;
  onDelete?(): void;
}

export const PadCard = observer(function PadCard({
  title,
  extra,
  className,
  attributes,
  onPointerDown,
  onPointerUp,
  onMouseLeave,
  onMouseOver,
  contentClassName,
  headerClassName,
  children,
  style,
  onDelete,
}: PropsWithChildren<Props>) {
  const deleteButtonListeners = useOnClick(() => onDelete?.());

  const header = (
    <div className={styles.cardHeaderWrapper}>
      <div className={classNames(headerClassName, styles.cardHeader)}>
        {onDelete && (
          <button className={styles.cardHeaderDeleteButton} {...deleteButtonListeners}>
            <DeleteIcon className={styles.cardHeaderDeleteButtonIcon} />
          </button>
        )}
        <h3 className={styles.cardHeaderTitle}>{<p>{attributes?.join(' / ') || title}</p>}</h3>

        {!!extra && <div className={styles.extra}>{extra}</div>}
      </div>
    </div>
  );

  return (
    <Card
      header={header}
      className={clsx(className, styles.card)}
      contentClassName={contentClassName}
      style={style}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      isInteractive
    >
      {children}
    </Card>
  );
});
