import { Progress } from 'antd';
import { observer } from 'mobx-react-lite';

import styles from 'src/shared/components/table-cell/progress-table-cell//progress-table-cell.module.scss';
import { getProgressLineColor } from 'src/shared/components/table-cell/progress-table-cell/utils';

interface Props {
  fieldValue: string | number | null;
  progressPercent: number;
  onCellClick(): void;
  onProgressLineClick(): void;
}

export const ProgressTableCell = observer(function EditableTextField({
  fieldValue,
  progressPercent,
  onCellClick,
  onProgressLineClick,
}: Props) {
  const progressLineColor = getProgressLineColor(progressPercent);

  function onProgressClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onProgressLineClick();
  }

  return (
    <div className={styles.wrapper} onClick={onCellClick}>
      <div className={styles.valueContainer}>{fieldValue}</div>
      <div onClick={onProgressClick}>
        <Progress
          percent={progressPercent}
          strokeWidth={2}
          strokeColor={progressLineColor}
          style={{ color: progressLineColor }}
        />
      </div>
    </div>
  );
});
