import { NumberField } from 'src/shared/entities/control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TNumberFieldRaw, TSavedFormValue } from '../../types';

type TMapNumberFieldItemOptions = {
  item: TNumberFieldRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapNumberFieldItem = ({ item, directories, savedValue }: TMapNumberFieldItemOptions): NumberField => {
  const fieldProps = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    validate: item.validate,
    validationTags: item.validationTags,
    calculatedValue: item.calculatedValue,
    updateDictValue: item.updateDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    checkDictValue: item.checkDictValue,
    useInMainProgressBar: !!item?.useInMainProgressBar,
    restrictions: { required: !!item.required, ...item.restrictions },
    initialValue: typeof savedValue === 'number' ? savedValue : undefined,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    unit: directories.getFieldUnit(item.fieldId) || item?.item || undefined,
    numberOfDecimalPlaces: item.numberOfDecimalPlaces,
  };

  return new NumberField(fieldProps);
};
