import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { observer } from 'mobx-react-lite';
import { useState, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TDictObject } from 'src/api/directories/types';
import { CheckboxItem } from 'src/features/wells-list/wells-table/resizable-table-title/combo-box-filter/types';
import {
  mapObjectItems,
  serializeFilterValues,
} from 'src/features/wells-list/wells-table/resizable-table-title/combo-box-filter/utils';
import { FilterType } from 'src/pages/wells-page/types';
import { Button } from 'src/shared/components/button';
import { Checkbox } from 'src/shared/components/checkbox';

import styles from './combo-box-filter.module.scss';

interface Props {
  object: TDictObject[] | null;
  fieldId: string;
  attrName?: string;
  filters: FilterType[];
  onFiltersChange(filters: FilterType[]): void;
  objectField?: string;
}

export const ComboBoxFilter = observer(function ComboBoxFilter({
  fieldId,
  attrName,
  filters,
  object,
  objectField,
  onFiltersChange,
}: Props) {
  const { t } = useTranslation();

  const currentFilter = filters.find((filter) => filter.fieldId === fieldId);

  const [filtersValues, setFiltersValues] = useState<CheckboxItem[]>(
    mapObjectItems(object, objectField, currentFilter)
  );

  const [searchString, setSearchString] = useState<string>('');

  const isEmptyFilters = filtersValues.every((item) => !item.isChecked);

  function onSelectFilterValue(event: CheckboxChangeEvent): void {
    setFiltersValues((prev) => {
      return prev.map((item) => {
        return item.value.toString() === event?.target.id ? { ...item, isChecked: event.target.checked } : item;
      });
    });
  }

  function resetFilterValues(): void {
    setFiltersValues((prev) => {
      return prev.map((item) => {
        return { ...item, isChecked: false };
      });
    });

    onFiltersChange(filters.filter((item) => fieldId !== item.fieldId));
  }

  function onApplyFilterValues(): void {
    if (attrName) {
      onFiltersChange(
        filters
          .filter((item) => fieldId !== item.fieldId)
          .concat(serializeFilterValues(fieldId, attrName, filtersValues))
      );
    }
  }

  function onSearch(event: ChangeEvent<HTMLInputElement>): void {
    setSearchString(event.target.value);
  }

  const displayedValues = useMemo(() => {
    return searchString
      ? filtersValues.filter((value) => String(value.label).toLowerCase().includes(searchString.toLowerCase()))
      : filtersValues;
  }, [searchString, filtersValues]);

  return (
    <>
      <input className={styles.search} placeholder={t('common:searchByValue')} onChange={onSearch} />

      <div className={styles.filtersContainer}>
        {displayedValues.map((item) => (
          <div className={styles.checkItem} key={item.value}>
            <Checkbox
              onChange={onSelectFilterValue}
              id={item.value.toString()}
              isChecked={item.isChecked}
              label={item.label}
            />
          </div>
        ))}
      </div>

      <div className={styles.bottomButtonsContainer}>
        <Button variant="text" className={styles.button} onClick={resetFilterValues}>
          {t('common:Buttons.reset')}
        </Button>

        <Button
          variant="success"
          className={styles.button}
          onClick={isEmptyFilters ? resetFilterValues : onApplyFilterValues}
        >
          {t('common:Buttons.apply')}
        </Button>
      </div>
    </>
  );
});
