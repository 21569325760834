import { LoadingField } from 'src/shared/utils/loading-field';

export class IconAttribute<TIconName = string> {
  readonly iconName: TIconName;
  readonly label: LoadingField | string;

  constructor(iconName: TIconName, label: LoadingField | string) {
    this.iconName = iconName;
    this.label = label;
  }
}
