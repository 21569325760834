import { action, computed, makeObservable, observable } from 'mobx';

export class ChartGrouping {
  private readonly defaultValue: string;

  @observable private _value: string | null;

  constructor(defaultValue: string) {
    this.defaultValue = defaultValue;
    this._value = this.defaultValue;

    makeObservable(this);
  }

  @computed
  get value(): string {
    return this._value ?? this.defaultValue;
  }

  @action.bound
  setValue(value: string | null) {
    this._value = value;
  }
}
