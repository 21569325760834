import { RefObject } from 'react';

export const getIsPointLocatedOnElements = (e: PointerEvent, elements: RefObject<HTMLDivElement>[]): boolean => {
  const { clientX, clientY } = e;

  return elements
    .map((element) => {
      if (!element.current) return false;

      const { x, y, width, height } = element.current.getBoundingClientRect();

      return clientX >= x && clientX <= x + width && clientY >= y && clientY <= y + height;
    })
    .some((isLocatedOnElement) => isLocatedOnElement);
};
