import { observer } from 'mobx-react-lite';

import { WellRigOperationWrapper } from '../../../components/well-rig-operation-wrapper';
import {
  ComparingPadRigOperation,
  ComparingWellRigOperation,
} from '../../../presets/comparing-drilling-rigs-chart/entities';
import { LoadingRigOperations, PadRigOperation, WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { PadRigOperationWrapper } from '../../pad-rig-operation-wrapper';
import { RigsChartDataModel } from '../../rigs-chart/data/rigs-chart-data-model';

import { LoadingRigOperationsWrapper } from './loading-rig-operations-wrapper';

import styles from './data-items-full.module.scss';

interface Props {
  item: RigsChartDataModel.ViewItem;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick(padId: number, rigId: number): void;
  onWellClick(wellId: number): void;
}

export const ComparingRigsDataItem = observer(function ComparingRigsDataItem({
  item,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
  onPadClick,
  onWellClick,
}: Props) {
  if (item instanceof ComparingPadRigOperation) {
    return (
      <PadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
        className={styles.padCard}
        cardClassName={styles.padCard_inComparingRig}
        onClick={() => onPadClick(item.id, item.parentRig.id)}
      />
    );
  }

  if (item instanceof PadRigOperation) {
    return (
      <PadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
        className={styles.padCard}
        onClick={() => onPadClick(item.id, item.parentRig.id)}
      />
    );
  }

  if (item instanceof ComparingWellRigOperation) {
    return (
      <WellRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
        cardClassName={styles.wellCardComparing}
        contentClassName={styles.wellCardInnerComparing}
        headerClassName={styles.wellCardHeaderComparing}
        onClick={() => onWellClick(item.id)}
      />
    );
  }

  if (item instanceof WellRigOperation) {
    return (
      <WellRigOperationWrapper
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        item={item}
        viewSettingsProvider={viewSettingsProvider}
        onClick={() => onWellClick(item.id)}
      />
    );
  }

  if (item instanceof LoadingRigOperations) {
    return (
      <LoadingRigOperationsWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  return null;
});
