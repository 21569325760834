import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

import { SidebarMode } from 'src/features/drilling-rig-sidebar/store/types';
import { DrillingRigSidebar } from 'src/features/drilling-rig-sidebar/views';
import { PadInfoSidebar } from 'src/features/pad-info-sidebar';
import { WellInfoSidebar } from 'src/features/well-info-sidebar';
import { Loader } from 'src/shared/components/loader';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import { DataContainer } from '../../components/data-container/data-container';
import { TimelineChart } from '../../components/timeline-chart/timeline-chart';
import { ChartSearch } from '../../features/chart-search';
import { ComparingIndicatorsTable } from '../../features/comparing-indicators-table/view/comparing-indicators-table';
import { ComparingRigsChartDataModel } from '../../features/comparing-rigs-chart/data';
import { DataHeadersPresenter } from '../../features/data-headers/presenter/data-headers-presenter';
import { ComparingRigsDataHeaders } from '../../features/data-headers/view/comparing-rigs-data-headers';
import { DataItemsBackgroundPresenter } from '../../features/data-items-background/presenter';
import { ComparingRigsDataItemsBackground } from '../../features/data-items-background/view/comparing-rigs-data-items-background';
import { DataItemsCompactPresenter } from '../../features/data-items-compact/presenter/data-items-compact-presenter';
import { ComparingRigsDataItemsCompact } from '../../features/data-items-compact/view/comparing-rigs-data-items-compact';
import { DataItemsFullPresenter } from '../../features/data-items-full/presenter/data-items-full-presenter';
import { ComparingRigsDataItemsFull } from '../../features/data-items-full/view/comparing-rigs-data-items-full';
import { FiltersModal } from '../../features/filters-modal';
import { FullScreenButtons } from '../../features/full-screen-buttons';
import { HeaderPresenter } from '../../features/header/presenter/header-presenter';
import { Header } from '../../features/header/view/header';
import { DataView } from '../../shared/data-view/data-view';
import { RigsChartSidebarsStore } from '../../shared/rigs-chart-sidebars.store';
import { IndicatorsSettings } from '../indicators-view-settings-sidebar/entities';
import { ComparingSummaryDataSidebar } from '../summary-data-sidebar/comparing-summary-data-sidebar';

import { ComparingRigsChartStore } from './comparing-rigs-chart.store';

import styles from './comparing-drilling-rigs-chart.module.scss';

interface Props {
  chartStore: ComparingRigsChartStore;
  dataView: DataView;
  headerPresenter: HeaderPresenter;
  dataHeadersPresenter: DataHeadersPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  dataItemsBackgroundPresenter: DataItemsBackgroundPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  dataItemsFullPresenter: DataItemsFullPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  dataItemsCompactPresenter: DataItemsCompactPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  indicatorsSettings?: IndicatorsSettings;
  containerWidth?: number;
  containerHeight?: number;
  isFullScreen?: boolean;
  onFullScreenExit?: VoidFunction;
}

export const ComparingDrillingRigsChart = observer(function ComparingDrillingRigsChart({
  headerPresenter,
  chartStore,
  dataView,
  dataHeadersPresenter,
  dataItemsBackgroundPresenter,
  dataItemsFullPresenter,
  dataItemsCompactPresenter,
  containerWidth,
  containerHeight,
  indicatorsSettings,
  isFullScreen,
  onFullScreenExit,
}: Props) {
  const {
    horizontalViewport,
    verticalViewport,
    isLoading,
    filtersForm,
    isDataUpdating,
    viewSettings,
    hasSelectedFilters,
    indicators,
    summaryDataPresenter,
    summaryViewProvider,
    summaryDataSidebar,
  } = chartStore;

  const [sidebars] = useState(() => new RigsChartSidebarsStore());
  const { notifications } = useStore();
  const { isOpen: isSummaryDataOpen, open: openSummaryData, close: closeSummaryData } = summaryDataSidebar;

  const dataViewType = dataView.type;

  const isCompactView = useMemo(
    () => dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty,
    [dataViewType]
  );

  if (isLoading) {
    return <Loader className={styles.loader} />;
  }

  return (
    <div className={styles.chartWrapper}>
      {isDataUpdating && (
        <div className={styles.dataUpdatingLoaderContainer}>
          <Loader className={styles.dataUpdatingLoader} />
        </div>
      )}

      <TimelineChart className={clsx(styles.chart, isFullScreen && styles.chart_fullscreen)}>
        <DataContainer
          className={styles.chartDataContainer}
          horizontalViewport={horizontalViewport}
          verticalViewport={verticalViewport}
          onPointerDown={dataItemsBackgroundPresenter.onPointerDown}
          onPointerMove={dataItemsBackgroundPresenter.onPointerMove}
          onPointerUp={dataItemsBackgroundPresenter.onPointerUp}
          onScroll={dataItemsBackgroundPresenter.onScroll}
          onZoom={dataItemsBackgroundPresenter.onZoom}
          onZoomOut={dataItemsBackgroundPresenter.onZoomOut}
        >
          <ComparingRigsDataItemsBackground
            data={dataItemsBackgroundPresenter.data}
            verticalViewport={verticalViewport}
          />

          {isCompactView && (
            <ComparingRigsDataItemsCompact
              data={dataItemsCompactPresenter.data}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewSettingsProvider={viewSettings.provider}
            />
          )}

          {!isCompactView && (
            <ComparingRigsDataItemsFull
              data={dataItemsFullPresenter.data}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewSettingsProvider={viewSettings.provider}
              onPadClick={sidebars.onPadClick}
              onWellClick={sidebars.onWellClick}
            />
          )}
        </DataContainer>

        <ComparingRigsDataHeaders
          data={dataHeadersPresenter.data}
          viewport={verticalViewport}
          viewProvider={viewSettings.provider}
          className={styles.chartDataHeaders}
          isCompactView={isCompactView}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          onScroll={dataHeadersPresenter.onScroll}
          onHeightChange={dataHeadersPresenter.onHeightChange}
          onGroupCollapseToggle={dataHeadersPresenter.onGroupCollapseToggle}
          onRigClick={sidebars.onRigClick}
        />

        <Header
          data={headerPresenter.data}
          viewport={horizontalViewport}
          className={styles.chartHeader}
          contentClassName={clsx(isFullScreen && styles.chartHeader_fullscreen)}
          onPointerDown={headerPresenter.onPointerDown}
          onPointerMove={headerPresenter.onPointerMove}
          onPointerUp={headerPresenter.onPointerUp}
          onZoom={headerPresenter.onZoom}
          onZoomOut={headerPresenter.onZoomOut}
        />

        <ChartSearch
          className={styles.chartSearch}
          value={chartStore.searchTerm}
          onChange={chartStore.onSearchChange}
          onFiltersButtonClick={
            filtersForm?.onOpen || (() => notifications.showErrorMessageT('errors:failedToLoadFilters'))
          }
          highlightFiltersButton={hasSelectedFilters}
        />

        {!!indicators && (
          <ComparingIndicatorsTable
            data={indicators.presenter.data}
            viewport={horizontalViewport}
            indicatorsSettings={indicatorsSettings}
            className={styles.chartIndicators}
            onPointerDown={indicators.presenter.onPointerDown}
            onPointerMove={indicators.presenter.onPointerMove}
            onPointerUp={indicators.presenter.onPointerDown}
          />
        )}

        {isFullScreen && !!onFullScreenExit && (
          <div className={styles.chartSummaryWrapper}>
            <div className={styles.chartSummary}>
              <FullScreenButtons
                onFullScreenExit={onFullScreenExit}
                onSummaryOpen={openSummaryData}
                summaryButtonDisabled={isCompactView || !summaryViewProvider}
              />
              {!!summaryViewProvider && (
                <ComparingSummaryDataSidebar
                  isOpen={isSummaryDataOpen}
                  onClose={closeSummaryData}
                  data={summaryDataPresenter.data}
                  backgroundItems={dataItemsBackgroundPresenter.data}
                  viewport={verticalViewport}
                  viewProvider={summaryViewProvider}
                  selectedYear={summaryDataPresenter.year}
                  onYearChange={summaryDataPresenter.onYearChange}
                  onScroll={summaryDataPresenter.onScroll}
                />
              )}
            </div>
          </div>
        )}
      </TimelineChart>

      {!!filtersForm && <FiltersModal formStore={filtersForm} className={styles.filtersModal} />}

      {hasValue(sidebars.selectedPadId) && hasValue(sidebars.rigOfSelectedPadId) && (
        <PadInfoSidebar
          key={sidebars.selectedPadId}
          isOpen={sidebars.padInfo.isOpen}
          onClose={sidebars.onPadInfoClose}
          padId={sidebars.selectedPadId}
          rigId={sidebars.rigOfSelectedPadId}
          onWellClick={sidebars.onWellClick}
          disableEditing
        />
      )}

      {hasValue(sidebars.selectedWellId) && (
        <WellInfoSidebar
          key={sidebars.selectedWellId}
          wellId={sidebars.selectedWellId}
          isOpen={sidebars.wellInfo.isOpen}
          onClose={sidebars.onWellInfoClose}
          initialActiveTab={sidebars.initialWellSidebarTab}
          disableEditing
        />
      )}

      {hasValue(sidebars.selectedRigId) && (
        <DrillingRigSidebar
          key={sidebars.selectedRigId}
          isOpened={sidebars.rigInfo.isOpen}
          onClose={sidebars.onRigInfoClose}
          sidebarMode={SidebarMode.view}
          rigId={sidebars.selectedRigId}
          disableEditing
          onPadClick={sidebars.onPadClick}
          onWellClick={sidebars.onWellClick}
        />
      )}
    </div>
  );
});
