import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { assert } from 'src/shared/utils/assert';
import { addCommonPrefix } from 'src/shared/utils/prefixes';

import { RigsDataPositionsCalculator } from '../../shared/rigs-data-positions-calculator';

import { ChartRig, RigsGroup } from './entities';

export namespace RigsGroupsAdapter {
  function sortByOrderArray<TItem, TOrderItem>(
    items: TItem[],
    itemsOrder: TOrderItem[],
    isItemEqualOrder: (current: TItem, orderItem: TOrderItem) => boolean
  ): TItem[] {
    const sortedItems: TItem[] = [];
    const itemsWithoutOrder: TItem[] = [];

    for (const item of items) {
      const orderOfCurrentItem: number = itemsOrder.findIndex((orderItem) => isItemEqualOrder(item, orderItem));

      if (orderOfCurrentItem === -1) {
        itemsWithoutOrder.push(item);
      } else {
        if (sortedItems[orderOfCurrentItem]) {
          throw new Error(`Double positions in order array. Index of element in order array: ${orderOfCurrentItem}`);
        }

        sortedItems[orderOfCurrentItem] = item;
      }
    }

    return sortedItems.filter(Boolean).concat(itemsWithoutOrder);
  }

  export function init(rawRigGroups: RigsChart.RawRigsGroupWithRigs[], prevRigGroups?: RigsGroup[]): RigsGroup[] {
    const initializedRigGroups: RigsGroup[] = [];

    for (const rawRigsGroup of rawRigGroups) {
      const initializedRigs: ChartRig[] = [];

      for (const rawRig of rawRigsGroup.items) {
        const idFieldName = addCommonPrefix('Rig.id');
        const rigId = rawRig[idFieldName];

        assert(idFieldName in rawRig && typeof rigId === 'number', 'Invalid id in drilling rig data');

        const rig = new ChartRig(rawRig, rigId);

        initializedRigs.push(rig);
      }

      const rigsGroup = new RigsGroup(rawRigsGroup, rawRigsGroup.id, initializedRigs);

      const samePrevGroup = prevRigGroups?.find((group) => group.isEqual(rigsGroup));

      if (samePrevGroup) {
        rigsGroup.setIsCollapsed(samePrevGroup.getState().isCollapsed);
      }

      rigsGroup.items.forEach((rig) => rig.setParentGroup(rigsGroup));

      initializedRigGroups.push(rigsGroup);
    }

    return initializedRigGroups;
  }

  export function applySettings(
    rigGroups: RigsGroup[],
    rigsOrderSettings?: ViewSettings.RigsOrderSettingsValues
  ): RigsGroup[] {
    if (!rigsOrderSettings) {
      return rigGroups;
    }

    const sortedRigGroups = sortByOrderArray(
      rigGroups,
      rigsOrderSettings.groups,
      (rigsGroup, rigsGroupOrder) => rigsGroup.id === rigsGroupOrder.groupId
    );

    for (const rigsGroup of sortedRigGroups) {
      const sortedRigs = sortByOrderArray(rigsGroup.items, rigsOrderSettings.rigs, (rig, rigOrder) => {
        if (rigsGroup.id !== rigOrder.groupId) {
          return false;
        }

        return rig.id === rigOrder.rigId;
      });

      rigsGroup.setRigs(sortedRigs);

      const isCollapsed =
        rigsOrderSettings.groups.find(({ groupId }) => groupId === rigsGroup.id)?.isCollapsed ??
        RigsDataPositionsCalculator.DEFAULT_IS_COLLAPSED;

      rigsGroup.setIsCollapsed(isCollapsed);
    }

    return sortedRigGroups;
  }
}
