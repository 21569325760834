import { v4 as uuidv4 } from 'uuid';

import { RigsChart } from 'src/api/chart/rigs-chart-api';

import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';

export class TemporaryPadRigOperation implements RigsChartDataModel.IChartPadRigOperation {
  readonly x: Range<number>;
  readonly id: number;
  readonly item: RigsChart.RawPad;
  readonly uniqueCreationKey: string;
  readonly parentRig: RigsChartDataModel.IChartRig;
  readonly y: Range<number>;

  constructor(item: RigsChart.RawPad, parentRig: RigsChartDataModel.IChartRig, x: Range<number>, y: Range<number>) {
    this.id = item.id;
    this.item = item;
    this.uniqueCreationKey = uuidv4();
    this.parentRig = parentRig;
    this.x = x;
    this.y = y;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-pad-${this.uniqueCreationKey}`;
    }

    return `pad-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): string | number | undefined {
    if (fieldName in this.item.data) {
      return this.item.data[fieldName];
    }
  }
}
