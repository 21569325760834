import { NumberParsingError } from 'src/shared/utils/errors';

import { Range } from '../layers/model';

import { StorageKey } from './storage';

export class StorageKeyManager<TArgs, TKey extends StorageKey> {
  getKey: StorageKeyManager.KeyGenerator<TArgs, TKey>;

  constructor(generatorFn: StorageKeyManager.KeyGenerator<TArgs, TKey>) {
    this.getKey = generatorFn;
  }
}

export class StorageKeyManagerWithParser<TArgs, TKey extends StorageKey> extends StorageKeyManager<TArgs, TKey> {
  parseKey: StorageKeyManager.KeyParser<TKey, TArgs>;

  constructor(
    generatorFn: StorageKeyManager.KeyGenerator<TArgs, TKey>,
    parserFn: StorageKeyManager.KeyParser<TKey, TArgs>
  ) {
    super(generatorFn);
    this.parseKey = parserFn;
  }
}

export namespace StorageKeyManager {
  export type KeyGenerator<TArgs, TKey> = (args: TArgs) => TKey;

  export type KeyParser<TKey, TArgs> = (key: TKey) => TArgs;
}

/* Default generators and parsers */

export const generateKeyFromRange = ({ start, end }: Range<number>): string => `${start}-${end}`.toString();

export const parseStringToRange = (key: string): Range<number> => {
  const [start, end] = key.split('-');
  const startNum = Number.parseInt(start);
  const endNum = Number.parseInt(end);

  if (!startNum || !endNum) {
    throw new NumberParsingError();
  }

  return {
    start: Number(start),
    end: Number(end),
  };
};
