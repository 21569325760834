import type { MutableRefObject, RefCallback } from 'react';

type ReactRef<TElement> = MutableRefObject<TElement | null> | RefCallback<TElement> | null | undefined;

export function mergeRefs<TElement>(...refs: ReactRef<TElement>[]): RefCallback<TElement> {
  return (object) => {
    for (const ref of refs) {
      if (ref) {
        setRefValue(ref, object);
      }
    }
  };
}

export function setRefValue<TElement>(ref: ReactRef<TElement>, value: TElement): void {
  if (ref) {
    if (typeof ref === 'function') {
      ref(value);
    } else {
      ref.current = value;
    }
  }
}
