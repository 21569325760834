import { observer } from 'mobx-react-lite';

import {
  DraggingPadRigOperationWrapper,
  GhostPadRigOperationWrapper,
} from 'src/features/drilling-chart/features/pad-rig-operation-wrapper';

import { LoadingRigOperations, PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { LoadingRigOperationsWrapper } from '../../data-items-full/view/loading-rig-operations-wrapper';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';

interface Props {
  item: RigsChartStore.ViewItem;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick?(padId: number, rigId: number): void;
}

export const RigsDataItemCompact = observer(function RigsDataItemCompact({
  item,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  viewSettingsProvider,
}: Props) {
  if (item instanceof PadRigOperation) {
    return (
      <DraggingPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
        onClick={() => onPadClick?.(item.id, item.parentRig.id)}
      />
    );
  }

  if (item instanceof LoadingRigOperations) {
    return (
      <LoadingRigOperationsWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (RigsSorting.isPadGhost(item)) {
    return (
      <GhostPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  return null;
});
