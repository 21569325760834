import { action, autorun, computed, makeObservable, observable, override } from 'mobx';

import { TDictObject } from 'src/api/directories/types';
import {
  TFormula,
  TEnableIf,
  TRequiredIf,
  TVisuallyDisabled,
  TAditionalConditionalAttribute,
} from 'src/api/new-well/types';
import { TAttrConcatRefs } from 'src/api/types';
import { AddNewValueToDirectoryModalController } from 'src/features/modals/add-new-value-to-directory-modal';
import { Directories } from 'src/store/directories/directories.store';
import { TJoinResponse, TRefQuery, TRefRest } from 'src/store/directories/types';

import { TTabProps } from '../../features/well-form/entities/types';
import { IRestrictions, TOnChangeComboboxInstructions, TOption } from '../../features/well-form/types';
import { checkIsRegularDirectories } from '../utils/check-is-regular-directory';
import { hasValue } from '../utils/common';
import { getConcatedName } from '../utils/get-concated-name';
import { isStringArray } from '../utils/is-string-array';

export interface IFormElement {
  formElementRefId?: string;
}

type TControlDisablingObject = {
  flagId: number | string;
  value: boolean;
};

export abstract class Tab implements IFormElement {
  formElementRefId: string;

  @observable isDisabled: boolean = false;
  @observable tooltipText?: string;

  constructor({ formElementRefId }: TTabProps) {
    this.formElementRefId = formElementRefId;

    makeObservable(this);
  }

  @action.bound
  setIsDisabled(is: boolean) {
    this.isDisabled = is;
  }

  @action.bound
  setTooltipText(text?: string) {
    this.tooltipText = text;
  }

  abstract validate(): void;

  abstract get errorsCount(): number;
}

interface IItemAttributes extends IFormElement {
  computeTags?: string[];
  fieldId: string;
  label?: string;
  comment?: string;
  enableIf?: TEnableIf[];
  calculatedValue?: TFormula;
  visuallyDisabledInstructions?: TVisuallyDisabled[];
  validateByServer?: boolean;
  validationTags?: string[];
  updateDictValue?: string[];
  checkDictValue?: string[];
  additionalConditionalAttributes?: TAditionalConditionalAttribute[];
}

export type TItemAttributes = keyof IItemAttributes;

export abstract class Item<T = unknown> implements IItemAttributes {
  protected readonly _isVisuallyDisabledTrueVotes = observable.set<number | string>();
  protected readonly _isDisabledTrueVotes = observable.set<number | string>();
  @observable valueFromDirectory: T | null;
  @observable parentControl?: ItemContainer;
  @observable computeTags;

  formElementRefId;
  fieldId;
  label;
  comment;
  enableIf;
  calculatedValue;
  visuallyDisabledInstructions;
  validateByServer;
  validationTags;
  updateDictValue;
  checkDictValue;
  additionalConditionalAttributes;

  constructor(item: IItemProps) {
    this.enableIf = item.enableIf;
    this.visuallyDisabledInstructions = item.visuallyDisabled;
    this.calculatedValue = item.calculatedValue;
    this.formElementRefId = item.formElementRefId;
    this.fieldId = item.fieldId;
    this.comment = item.comment;
    this.label = item.label;
    this.validateByServer = item.validate;
    this.validationTags = item.validationTags;
    this.valueFromDirectory = null;
    this.updateDictValue = item.updateDictValue;
    this.checkDictValue = item.checkDictValue;
    this.computeTags = item.computeTags;
    this.parentControl = item.parentControl;
    this.additionalConditionalAttributes = item.additionalConditionalAttributes;

    makeObservable(this);
  }

  abstract get value(): T;
  abstract get initialValue(): T;

  abstract setValue(value: T | null, setValueAsInitial?: boolean): void;
  abstract setInitialValue(value: T): void;
  abstract returnInitialValue(): void;
  abstract tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean;

  abstract clearItem(): void;

  @action.bound
  addAttribute(attrName: TItemAttributes, value: unknown) {
    if (attrName === 'computeTags' && isStringArray(value)) {
      this.computeTags = value;
      return;
    }
    console.error('this control does not contains presented attrName or attrName is not checked before setting');
  }

  @action.bound
  setParentControl(item: ItemContainer) {
    this.parentControl = item;
  }

  @action.bound
  setValueFromDirectory(value: T) {
    this.valueFromDirectory = value;
  }

  @computed
  get isVisuallyDisabled(): boolean {
    return !!this._isVisuallyDisabledTrueVotes.size;
  }

  @computed
  get isDisabled(): boolean {
    return !!this._isDisabledTrueVotes.size;
  }

  @action.bound
  setIsVisuallyDisabled(object: TControlDisablingObject) {
    if (object.value) {
      this._isVisuallyDisabledTrueVotes.add(object.flagId);
    } else {
      if (this._isVisuallyDisabledTrueVotes.has(object.flagId)) {
        this._isVisuallyDisabledTrueVotes.delete(object.flagId);
      }
    }
  }

  @action.bound
  setIsDisabled(object: TControlDisablingObject) {
    if (object.value) {
      this._isDisabledTrueVotes.add(object.flagId);
    } else {
      if (this._isDisabledTrueVotes.has(object.flagId)) {
        this._isDisabledTrueVotes.delete(object.flagId);
      }
    }
  }
}
export abstract class ItemContainer {
  readonly fieldsList: Item[];
  readonly fields: Record<string, Item>;
  parentControl?: ItemContainer;

  constructor(fieldsList: Item[], parentControl?: ItemContainer) {
    this.fieldsList = fieldsList;
    this.parentControl = parentControl;
    this.fields = this.getFields();
    fieldsList.forEach((field) => field.setParentControl(this));
  }

  private getFields(): Record<string, Item> {
    const fields: Record<string, Item> = {};

    this.fieldsList.forEach((field) => {
      fields[field.fieldId] = field;
    });

    return fields;
  }
}

export abstract class ValidatableItem<T> extends Item<T> {
  @observable errorText?: string | [string, { [locKey: string]: number | string }];

  useInMainProgressBar: boolean;
  @observable readonly restrictions: IRestrictions;
  readonly requiredIf?: TRequiredIf[];

  constructor(item: TValidatableItemProps) {
    super(item);
    this.errorText = undefined;
    this.useInMainProgressBar = !!item.useInMainProgressBar;
    this.restrictions = item.restrictions;
    this.requiredIf = item.requiredIf;

    makeObservable(this);
  }

  abstract clearError(): void;

  @action.bound
  setError(error: TControlError): void {
    if (!this.isVisuallyDisabled) {
      this.errorText = error;
    }
  }

  abstract hasErrors(): boolean;

  abstract checkIsReady(): boolean;

  @action.bound
  setIsRequired(is: boolean) {
    this.restrictions.required = is;
  }

  @override
  setIsVisuallyDisabled(object: TControlDisablingObject) {
    if (object.value) {
      this._isVisuallyDisabledTrueVotes.add(object.flagId);
      this.clearError();
    } else {
      if (this._isVisuallyDisabledTrueVotes.has(object.flagId)) {
        this._isVisuallyDisabledTrueVotes.delete(object.flagId);
      }
    }
  }

  @override
  setIsDisabled(object: TControlDisablingObject) {
    if (object.value) {
      this._isDisabledTrueVotes.add(object.flagId);
      this.clearError();
      this.setValue(null);
    } else {
      if (this._isDisabledTrueVotes.has(object.flagId)) {
        this._isDisabledTrueVotes.delete(object.flagId);
      }
    }
  }

  @computed
  get isReady(): boolean {
    return this.checkIsReady();
  }
}

export abstract class Field<T> extends ValidatableItem<T> {
  placeholder?: string;
  unit?: string;

  constructor(props: TFieldProps) {
    super(props);
    this.placeholder = props.placeholder;
    this.unit = props.unit;
  }
}

type TComboboxData = TValidatableItemProps & {
  directories: Directories;
  refQuery?: TRefQuery;
  refRest?: TRefRest;
  placeholder?: string;
  onChangeInstructions?: TOnChangeComboboxInstructions;
  refObjectFilterByFields?: Record<string, string>;
  refObjectType?: string;
  refObjectAttr?: string;
  attrConcat?: string[];
  attrConcatRefs?: TAttrConcatRefs;
  delimiter?: string;
  directory: TDictObject[] | TJoinResponse[] | null;
};

export abstract class Combobox<T = unknown, O = TOption> extends ValidatableItem<T | null> {
  readonly directories: Directories;
  readonly refQuery?: TRefQuery;
  readonly placeholder?: string;
  readonly refRest?: TRefRest;
  readonly onChangeInstructions?: TOnChangeComboboxInstructions;
  readonly refObjectFilterByFields?: Record<string, string>;
  readonly refObjectType?: string;
  readonly refObjectAttr?: string;
  readonly attrConcat?: string[];
  readonly attrConcatRefs?: TAttrConcatRefs;
  readonly delimiter?: string;
  readonly addNewValueToDirectoryModalController: AddNewValueToDirectoryModalController;

  @observable directory: TDictObject[] | TJoinResponse[] | null = null;
  @observable filterValues: Record<string, unknown> | null = null;
  @observable invalidValue: string | null = null;
  @observable archivedOptions: TOption[] = [];

  constructor(data: TComboboxData) {
    super(data);

    this.directory = data.directory;
    this.refQuery = data.refQuery;
    this.refRest = data.refRest;
    this.placeholder = data.placeholder;
    this.onChangeInstructions = data.onChangeInstructions;
    this.refObjectFilterByFields = data.refObjectFilterByFields;
    this.refObjectType = data.refObjectType;
    this.refObjectAttr = data.refObjectAttr;
    this.attrConcat = data.attrConcat;
    this.attrConcatRefs = data.attrConcatRefs;
    this.delimiter = data.delimiter;
    this.directories = data.directories;
    this.addNewValueToDirectoryModalController = new AddNewValueToDirectoryModalController();

    makeObservable(this);
  }

  abstract options: O[];

  protected serializeDataToOptions(): TOption[] {
    const directory = this.directory;

    if (!directory) {
      return [];
    }

    return checkIsRegularDirectories(directory)
      ? this.serializeDirectoryToOptions(directory)
      : this.serializeJoinResponeToOptions(directory);
  }

  @action.bound
  private serializeDirectoryToOptions(directory: TDictObject[]): TOption[] {
    if (!this.refObjectAttr) return [];
    const options = [];
    const archivedOptions = [];

    optionCycle: for (const dirValue of directory) {
      if (!dirValue.data[this.refObjectAttr] || !hasValue(dirValue.id)) {
        continue;
      }
      if (this.filterValues) {
        for (const key in this.filterValues) {
          if (dirValue.data[key] !== this.filterValues[key]) {
            continue optionCycle;
          }
        }
      }
      const dirValueAttr = dirValue.data[this.refObjectAttr];

      if (!dirValueAttr) continue;
      if (dirValue.status === 'ACTIVE') {
        options.push({
          label: dirValueAttr.toString(),
          value: dirValue.id,
        });
      } else {
        archivedOptions.push({
          label: dirValueAttr.toString(),
          value: dirValue.id,
        });
      }
    }

    this.archivedOptions = archivedOptions;

    return options;
  }

  private serializeJoinResponeToOptions(directory: TJoinResponse[]): TOption[] {
    const options: TOption[] = [];
    const archivedOptions = [];

    for (const optionRaw of directory) {
      if (!this.refObjectType) {
        continue;
      }
      const optionValue = optionRaw[this.refObjectType]?.id;
      const optionStatus = optionRaw[this.refObjectType]?.status;

      if (!['number', 'string'].includes(typeof optionValue)) continue;

      const concatedLabel = getConcatedName(this.directories, this, optionRaw);
      const simpleLabel = this.refObjectAttr ? optionRaw[this.refObjectType].data[this.refObjectAttr]?.toString() : '';

      if (optionStatus === 'ACTIVE') {
        options.push({
          label: concatedLabel || simpleLabel || '',
          value: optionValue,
        });
      } else {
        archivedOptions.push({
          label: concatedLabel || simpleLabel || '',
          value: optionValue,
        });
      }

      this.archivedOptions = archivedOptions;
    }

    return options;
  }

  private getDirectoryForFiltration(): TDictObject | null {
    const directory = this.directory;

    if (!directory) {
      return null;
    }

    if (checkIsRegularDirectories(directory)) {
      return directory.find((directoryValue) => directoryValue.id === this.value) || null;
    } else {
      const refObjectType = this.refObjectType;

      if (!refObjectType) {
        return null;
      }

      for (const joinDirectoryValue of directory) {
        if (joinDirectoryValue[refObjectType]) {
          return joinDirectoryValue[refObjectType];
        }
      }

      return null;
    }
  }

  getArchivedValueOption(value: unknown): TOption | null {
    return this.archivedOptions.find((option) => option.value === value) || null;
  }

  private isValuePresentedInOptions(options: unknown[], value: unknown): boolean {
    const isFlatOption = (
      option: unknown
    ): option is {
      value: unknown;
    } => !!option && typeof option === 'object' && 'value' in option;

    const isArrayOption = (
      option: any
    ): option is {
      children: unknown[];
    } => !!option && typeof option === 'object' && 'children' in option && Array.isArray(option.children);

    return !!options.find((option) => {
      if (!!option && typeof option === 'object') {
        if (isFlatOption(option) || !!this.getArchivedValueOption(value)) {
          return true;
        }

        if (isArrayOption(option)) {
          return this.isValuePresentedInOptions(option.children, value);
        }
      }

      return false;
    });
  }

  protected trackOptionsAndResetWrongValue(): VoidFunction {
    const disposer = autorun(() => {
      if (Array.isArray(this.value)) {
        let filteredValues = [...this.value];

        for (const item of this.value) {
          const isExists = this.isValuePresentedInOptions(this.options, item);

          if (!isExists) {
            filteredValues = filteredValues.filter((valueItem) => valueItem !== item);
          }
        }

        // проверяем длину текущих выбранных значений и отфильтрованных текущих значений для понимания, нужно ли обновлять текущее значение контрола или нет.
        if (filteredValues.length !== this.value.length) {
          this.tryToSetRawValue([...filteredValues]);
        }
      } else {
        const isCurrentValuePresentedInOptions = !!this.options.find((option) => {
          if (!!option && typeof option === 'object' && 'value' in option) {
            return option['value'] === this.value || !!this.getArchivedValueOption(this.value);
          }

          return false;
        });

        if (!this.invalidValue && !isCurrentValuePresentedInOptions) {
          this.setValue(null);
        }
      }
    });

    return disposer;
  }

  @action.bound
  setDirectory(directory: TDictObject[] | TJoinResponse[]) {
    this.directory = directory;
  }

  @action.bound
  setFilteringValues(filter: Record<string, unknown>): void {
    this.filterValues = filter;

    const directory = this.getDirectoryForFiltration();

    if (directory) {
      for (const key in filter) {
        if (directory.data[key] !== this.filterValues[key]) {
          this.clearItem();
          return;
        }
      }
    }
  }
}

export interface IItemProps {
  formElementRefId?: string;
  fieldId: string;
  validate?: boolean;
  validationTags?: string[];
  calculatedValue?: TFormula;
  enableIf?: TEnableIf[];
  visuallyDisabled?: TVisuallyDisabled[];
  comment?: string;
  label?: string;
  updateDictValue?: string[];
  checkDictValue?: string[];
  computeTags?: string[];
  parentControl?: ItemContainer;
  additionalConditionalAttributes?: TAditionalConditionalAttribute[];
}

export interface TValidatableItemProps extends IItemProps {
  useInMainProgressBar?: boolean;
  restrictions: IRestrictions;
  requiredIf?: TRequiredIf[];
}

export interface TFieldProps extends TValidatableItemProps {
  placeholder?: string;
  unit?: string;
}

export type TNumberIntervalValue = {
  start: number | null;
  end: number | null;
};

export type TControlError =
  | string
  | [
      string,
      {
        [locKey: string]: string | number;
      }
    ];

export type TLabelValue = {
  label: string;
  value: number[] | number | null;
};
