import { action, autorun, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { BaseApiError } from 'src/errors';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { ISummaryDataModel } from '../../../layers/model';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';

import { SummaryDataStorage } from './summary-data-storage';
import { ISummaryDataApi, ISummaryDataStorage } from './summary-data.types';

export class SummaryDataModel implements ISummaryDataModel {
  private readonly storage: ISummaryDataStorage;
  private readonly api: ISummaryDataApi;
  private readonly editing: EditingStore;
  private readonly notifications: NotificationsStore;

  @observable selectedYear: number = new Date().getFullYear();

  constructor(api: ISummaryDataApi, editing: EditingStore, notifications: NotificationsStore) {
    this.storage = new SummaryDataStorage(this.selectedYear);
    this.api = api;
    this.editing = editing;
    this.notifications = notifications;

    makeObservable(this);
  }

  @flow.bound
  private async *loadSummary(parentIdsList: number[], year: number, planVersionId: number) {
    try {
      const summaryData = await this.api.getSummary(parentIdsList, year, planVersionId);
      yield;

      this.storage.setData(year, summaryData);
    } catch (e) {
      yield;

      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToLoadData');
    }
  }

  init(): VoidFunction {
    const disposeDataLoading = reaction(
      () => {
        const { emptyRows } = this.storage.data;

        return { emptyRows, year: this.selectedYear, planVersionId: this.editing.actualPlanVersionId };
      },
      ({ emptyRows, year, planVersionId }) => {
        assert(hasValue(planVersionId), 'Invalid plan version ID.');

        if (emptyRows.length) {
          this.loadSummary(emptyRows, year, planVersionId);
        }
      }
    );

    const disposeYearSetter = autorun(() => {
      this.storage.setYear(this.selectedYear);
    });

    const disposeStorageClearing = reaction(
      () => this.editing.actualPlanVersionId,
      () => this.storage.clear()
    );

    return () => {
      disposeDataLoading();
      disposeYearSetter();
      disposeStorageClearing();
    };
  }

  getData = computedFn((): (IRigSummary | null)[] => {
    const { rows } = this.storage.data;

    return rows;
  });

  @action.bound
  setParentIdsList(idsList: number[]): void {
    this.storage.setParentIdsList(idsList);
  }

  @action.bound
  setYear(year: number): void {
    assert(/\d{4}/.test(year.toString()), 'Invalid year.');

    this.selectedYear = year;
  }
}
