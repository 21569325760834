import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode } from 'react';

import { Loader } from 'src/shared/components/loader';

import styles from './icon-button.module.scss';

interface Props {
  icon: ReactNode;
  onClick: VoidFunction;
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

export const IconButton = observer(function IconButton({
  icon,
  onClick,
  className,
  isLoading,
  isDisabled,
  style,
}: Props) {
  if (isLoading) {
    return (
      <div className={clsx(className, styles.container)}>
        <Loader size="small" className={styles.loader} />
      </div>
    );
  }

  return (
    <button className={clsx(className, styles.button)} onClick={onClick} disabled={isDisabled} style={style}>
      <div className={styles.iconWrapper}>{icon}</div>
    </button>
  );
});
