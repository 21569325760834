import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { useStore } from 'src/store';
import { TPlanVersion } from 'src/store/comparison/types';

import { CollapseItem } from './collapse-item/collapse-item';
import styles from './styles.module.scss';

interface Props {
  /** Checked version IDs. */
  onChange(planVersion: TPlanVersion): void;
  isDisabled?: boolean;
}

export const VersionsList = observer(function VersionsList({ onChange, isDisabled = false }: Props) {
  const { comparison } = useStore();
  const { firstTargetPlan, secondTargetPlan } = comparison;
  const { t } = useTranslation('drillingCarpet');
  const data = comparison.sidebarData;

  const isEmptyList = !comparison.sidebarData.length && comparison.searchingValue;

  return (
    <>
      {!isEmptyList && <p className={styles.compareText}>{t('Compare.description')}</p>}
      {isEmptyList && (
        <p className={styles.noItemsText}>
          {t(!!comparison.searchingValue ? 'Compare.noSearchItems' : 'Compare.noItems')}
        </p>
      )}
      <div className={styles.list}>
        {data.map((yearGroup) => {
          switch (yearGroup.type) {
            case 'actual': {
              if (!!yearGroup.plans.length) {
                return (
                  <Collapse key={yearGroup.name} defaultActiveKey={[yearGroup.name]}>
                    <Panel key={yearGroup.name} header={t(yearGroup.name)} className={styles.text}>
                      <div className={styles.itemsWrapper}>
                        {yearGroup.plans.map((plan) => {
                          const isChecked = firstTargetPlan?.id === plan.id || secondTargetPlan?.id === plan.id;
                          const handleChange = () => onChange(plan);

                          return (
                            <CollapseItem
                              isChecked={isChecked}
                              isDisabled={!isChecked && isDisabled}
                              key={plan.id}
                              text={plan.data.name}
                              onChange={handleChange}
                            />
                          );
                        })}
                      </div>
                    </Panel>
                  </Collapse>
                );
              }
              break;
            }
            case 'archived': {
              if (!!yearGroup.months.length) {
                return (
                  <Collapse key={yearGroup.year} defaultActiveKey={[yearGroup.year]}>
                    <Panel key={yearGroup.year} header={t(yearGroup.year)} className={styles.text}>
                      <div className={styles.itemsWrapper}>
                        {yearGroup.months.map((monthFroup) => (
                          <Collapse key={monthFroup.month} defaultActiveKey={[monthFroup.month]}>
                            <Panel key={monthFroup.month} header={t(monthFroup.month)} className={styles.text}>
                              <div className={styles.itemsWrapper}>
                                {monthFroup.plans.map((plan) => {
                                  const isChecked = firstTargetPlan?.id === plan.id || secondTargetPlan?.id === plan.id;
                                  const handleChange = () => onChange(plan);

                                  return (
                                    <CollapseItem
                                      isChecked={isChecked}
                                      isDisabled={!isChecked && isDisabled}
                                      key={plan.id}
                                      text={plan.data.name}
                                      onChange={handleChange}
                                    />
                                  );
                                })}
                              </div>
                            </Panel>
                          </Collapse>
                        ))}
                      </div>
                    </Panel>
                  </Collapse>
                );
              }
              break;
            }
            default: {
              return null;
            }
          }
          return null;
        })}
      </div>
    </>
  );
});
