import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/triangle-arrow.svg';

import { ComparingRigSummary, LoadingRigSummary } from '../../../presets/summary-data-sidebar/entities';
import { IRigSummary, RigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';
import { SummaryViewProvider } from '../../../shared/summary-view-provider';

import { getChange } from './summary-data.utils';

import styles from './summary-data.module.scss';

interface Props {
  data: IRigSummary | null;
  viewProvider: SummaryViewProvider;
}

export const SummaryDataRow = observer(function SummaryDataRow({ data, viewProvider }: Props) {
  if (data instanceof LoadingRigSummary) {
    return <div className={styles.rowLoading} />;
  }

  if (data instanceof ComparingRigSummary) {
    const mapDataToView = () => {
      const dataValues = [];

      for (const row of viewProvider.rows) {
        const rowLabel = row.label;
        const rowValues: { key: string; value: string | number; change: 'up' | 'down' | null }[] = [];

        for (const column of viewProvider.columns) {
          const value = data?.getValue(row.name, column.name);
          const changesResult = data.comparingResult.getCellResult(row.name, column.name);

          rowValues.push({
            key: column.name,
            value: value ?? 0,
            change: getChange(changesResult),
          });
        }

        dataValues.push({ key: row.name, label: rowLabel, values: rowValues });
      }

      return dataValues;
    };

    return (
      <div className={styles.rowData}>
        {mapDataToView().map(({ key, label, values }) => (
          <div key={key} className={styles.rowTableRow}>
            <p>{label}</p>

            {values.map(({ key, value, change }) => (
              <div
                key={key}
                className={clsx(change === 'up' && styles.cell_positive, change === 'down' && styles.cell_negative)}
              >
                <p title={value.toString()} className={clsx(styles.cellValue, value === 0 && styles.zeroData)}>
                  {value}
                </p>

                {!!change && <ArrowIcon className={styles.arrowIcon} />}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  if (!data || data instanceof RigSummary) {
    const mapDataToView = () => {
      const dataValues = [];

      for (const row of viewProvider.rows) {
        const rowLabel = row.label;
        const rowValues: { key: string; value: string | number }[] = [];

        for (const column of viewProvider.columns) {
          const value = data?.getValue(row.name, column.name);
          rowValues.push({ key: column.name, value: value ?? 0 });
        }

        dataValues.push({ key: row.name, label: rowLabel, values: rowValues });
      }

      return dataValues;
    };

    return (
      <div className={styles.rowData}>
        {mapDataToView().map(({ key, label, values }) => (
          <div key={key} className={styles.rowTableRow}>
            <p>{label}</p>

            {values.map(({ key, value }) => (
              <p key={key} title={value.toString()} className={clsx(styles.cellValue, value === 0 && styles.zeroData)}>
                {value}
              </p>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return null;
});
