import { reaction } from 'mobx';

import { TAditionalConditionalAttribute } from 'src/api/new-well/types';
import { Item, ItemContainer } from 'src/shared/entities/abstract-control-entities';

import { FormStore } from '../entities/form.entity';

import { FormPluginWithFieldsProcessing } from './abstract-form-plugin.entity';

export class AdditionalConditionalAttributesPlugin extends FormPluginWithFieldsProcessing {
  connect(form: FormStore): VoidFunction {
    this.form = form;

    const processItem = (item: Item): VoidFunction | void => {
      if (item.additionalConditionalAttributes) {
        const disposers: VoidFunction[] = [];

        item.additionalConditionalAttributes.forEach((additCondAttr) => {
          const managingControl = this.getManagingControl(additCondAttr.if.control, item);
          if (managingControl) {
            const managingControlDisposer = reaction(
              () => managingControl.value,
              (value) => {
                if (value === additCondAttr.if.value) {
                  if (additCondAttr.add) {
                    this.addAtribute(additCondAttr, item);
                  }
                }
              },
              { fireImmediately: true }
            );

            disposers.push(managingControlDisposer);
          }
        });

        return () => {
          disposers.forEach((disposer) => disposer());
        };
      }
    };

    const disposer = this.processItems(processItem);

    return () => {
      disposer?.();
    };
  }

  private addAtribute(additionalCondAttr: TAditionalConditionalAttribute, item: Item): void {
    if (!additionalCondAttr.add) {
      return;
    }
    const attrName = additionalCondAttr.add.attributeName;
    const attrValue = additionalCondAttr.add.value;
    item.addAttribute(attrName, attrValue);
  }

  private getManagingControl(fieldId: string, item: Item): Item | null {
    if (!item.parentControl) {
      return this.form?.fields[fieldId] || null;
    }

    const getManaginControlRecursively = (item: ItemContainer, fieldId: string): Item | null => {
      const foundField = item.fields[fieldId];

      if (!foundField) {
        if (item.parentControl) {
          return getManaginControlRecursively(item.parentControl, fieldId);
        } else {
          return this.form?.fields[fieldId] || null;
        }
      }

      return foundField;
    };

    return getManaginControlRecursively(item.parentControl, fieldId);
  }
}
