import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler } from 'react';

import { DrillingStepColoredCard } from 'src/features/drilling-step-colored-card';
import { hasValue } from 'src/shared/utils/common';

import { WellRigOperationStage } from '../../presets/drilling-wells-chart/entities';
import { useClickHandler } from '../../shared/use-click-handler';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';

import styles from './drilling-step-wrapper.module.scss';

interface Props {
  item: WellRigOperationStage;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  onClick(wellId: string | number): void;
  className?: string;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

export const DrillingStageWrapperCompact = observer(function DrillingStageWrapperCompact({
  item,
  horizontalViewport,
  verticalViewport,
  onMouseOver,
  onMouseLeave,
  onClick,
  className,
}: Props) {
  const ref = useMovableElement({ item, horizontalViewport, verticalViewport });

  const { onPointerDown, onPointerUp } = useClickHandler(() => {
    if (hasValue(item.parentWellId)) {
      onClick(item.parentWellId);
    }
  });

  return (
    <div ref={ref} className={styles.dataItem} onPointerUp={onPointerUp} onPointerDown={onPointerDown}>
      <DrillingStepColoredCard
        className={clsx(className, styles.card)}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        isInteractive={true}
      />
    </div>
  );
});
