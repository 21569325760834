import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { WellsChartViewTypes } from 'src/api/chart/wells-chart-api';
import { ControlTypes } from 'src/shared/constants/ControlTypes';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { hasValue } from 'src/shared/utils/common';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { LoadingField } from 'src/shared/utils/loading-field';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';

import { DateHelper } from '../../shared/date-helper';
import { ColorAttribute } from '../../shared/entities/color-attribute';
import { IconAttribute } from '../../shared/entities/icon-attribute';
import { RegularAttribute } from '../../shared/entities/regular-attribute';
import { isValueStringOrNumber } from '../../shared/is-valid-text-value';

import { ChartWell, WellRigOperation, WellRigOperationStage, WellsGroup } from './entities';
import { WellsChartView } from './wells-chart-view';

export class WellsViewSettingsProvider {
  private readonly chartView: WellsChartView;
  private readonly directories: Directories;
  private readonly appSettings: AppSettingsStore;

  @observable private isLoading = false;

  constructor(chartView: WellsChartView, directories: Directories, appSettings: AppSettingsStore) {
    this.chartView = chartView;
    this.directories = directories;
    this.appSettings = appSettings;

    makeObservable(this);
  }

  @flow.bound
  private async *loadCarpetDictionaries(view: WellsChartViewTypes.View) {
    this.isLoading = true;
    const tooltipsObjectTypes: string[] = [];

    for (const attr of view.carpet.stages.tooltip.attributes) {
      if (attr.refObjectType) {
        tooltipsObjectTypes.push(attr.refObjectType);
      }
    }

    const refObjects = [view.carpet.stages.refObjectType, ...tooltipsObjectTypes];

    try {
      await this.directories.loadObjects(refObjects);
      yield;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *loadDictionaries(shownWellAttributesFields: Record<string, ViewSettings.SettingsControl[]>) {
    this.isLoading = true;

    const refObjects: string[] = [];
    const joinedObjects: TRefQuery[] = [];

    for (const shownAttributes of Object.values(shownWellAttributesFields)) {
      for (const shownWellAttributesField of shownAttributes) {
        const { view } = shownWellAttributesField;
        const { refObjectAttr, refObjectType, refQuery } = view;

        if (refQuery) {
          joinedObjects.push(refQuery);
        } else if (refObjectAttr && refObjectType) {
          refObjects.push(refObjectType);
        }
      }
    }

    try {
      await Promise.all([
        this.directories.loadObjects(refObjects),
        this.directories.loadJoinedObjectsDeprecated(joinedObjects),
      ]);
      yield;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private receiveWellAttributeValue(
    item: WellsViewSettings.ItemWithFieldNameGetter,
    settingsItemView: ViewSettings.SettingsControlView
  ): string | null {
    const { attrName, refObjectAttr, refObjectType, attr, objectType, refQuery } = settingsItemView;

    const attrValue = item.getFieldValue(attrName || `${objectType}.${attr}`);

    if (!attrValue) {
      return null;
    }

    if (refQuery && refObjectAttr && attrName) {
      const refObject = this.directories.getJoinedObjectDeprecated(refQuery);
      const labelObject = refObject?.find((refObjectItem) => {
        return refObjectItem[attrName]?.toString() === attrValue?.toString();
      });

      if (labelObject) {
        const label = labelObject[`${refObjectType}.${refObjectAttr}`];

        return isValueStringOrNumber(label) ? label.toString() : null;
      }
    }

    if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const attributeObject = refObject?.find(({ id }) => id.toString() === attrValue);
      const attribute = attributeObject?.data[refObjectAttr];

      return attribute ? attribute.toString() : null;
    } else {
      return isValueStringOrNumber(attrValue) ? attrValue.toString() : null;
    }
  }

  private recieveStageAttributeValue(item: WellRigOperationStage, view: ViewSettings.SettingsControlView): string {
    const additionalAttributes = this.chartView.view.carpet.stages.additionalAttributes;
    const { fieldId, objectType, attr } = view;

    const fieldValue = item.getFieldValue(fieldId);

    if (objectType && attr) {
      const attributeView = additionalAttributes.find((attribute) => attribute.objectName === `${objectType}.${attr}`);

      if (
        attributeView?.type === ControlTypes.dateTime &&
        (typeof fieldValue === 'number' || typeof fieldValue === 'string')
      ) {
        const date = DateHelper.unixToDateFormat(fieldValue);

        if (date) {
          return date;
        }
      }
    }

    return isValueStringOrNumber(fieldValue) ? fieldValue.toString() : '-';
  }

  private receiveStageAttribute(
    item: WellRigOperation,
    view: WellsChartViewTypes.StageTooltipAttribute
  ): string | null {
    const { attrName, refObjectAttr, refObjectType, fieldId } = view;

    const fieldValue = item.getFieldValue(attrName);

    if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const value = refObject?.find(({ id }) => id.toString() === fieldValue)?.data?.[refObjectAttr];

      return isValueStringOrNumber(value) ? value.toString() : null;
    }

    if (isValueStringOrNumber(fieldValue) && fieldValue !== 'null') {
      const unit = this.directories.getFieldUnit(fieldId);

      return `${fieldValue}${unit ? ` ${unit}` : ''}`;
    }

    return null;
  }

  private processTooltipAttribute(
    rigOperation: WellRigOperation,
    attr: WellsChartViewTypes.StageTooltipAttribute
  ): ColorAttribute | IconAttribute<WellTypeIcon> | RegularAttribute | null {
    switch (attr.type) {
      case 'Color': {
        const wellTypeColor = getWellColor(rigOperation.item, this.appSettings.wellColorRules);
        const wellPurposeValue = this.receiveStageAttribute(rigOperation, attr);

        if (hasValue(wellPurposeValue)) {
          return new ColorAttribute(wellTypeColor, this.isLoading ? new LoadingField() : wellPurposeValue);
        }

        return null;
      }
      case 'Icon': {
        const wellTypeValue = this.receiveStageAttribute(rigOperation, attr);
        const wellTypeIconName = this.appSettings.getWellIcon(rigOperation.item, attr.attrName);

        if (wellTypeIconName) {
          return new IconAttribute<WellTypeIcon>(
            wellTypeIconName,
            this.isLoading ? new LoadingField() : wellTypeValue ?? ''
          );
        }

        return null;
      }
      case 'Regular': {
        const label = this.directories.getFieldLabel(attr.fieldId);
        const value = this.receiveStageAttribute(rigOperation, attr);

        if (hasValue(label)) {
          return new RegularAttribute(label, !hasValue(value) || value === 'null' ? '-' : value);
        }

        return null;
      }
    }
  }

  @action.bound
  init(): VoidFunction {
    this.loadCarpetDictionaries(this.chartView.view);
    this.loadDictionaries(this.shownAttributesFields);

    const disposeCarpetDictionariesLoading = reaction(
      () => this.chartView.view,
      (view) => {
        this.loadCarpetDictionaries(view);
      }
    );

    const disposeWellDictionariesLoading = reaction(
      () => this.shownAttributesFields,
      (shownAttributesFields) => {
        this.loadDictionaries(shownAttributesFields);
      }
    );

    return () => {
      disposeCarpetDictionariesLoading();
      disposeWellDictionariesLoading();
    };
  }

  @computed
  get shownAttributesFields(): Record<string, ViewSettings.SettingsControl[]> {
    if (!this.chartView.infoSettingsValues) {
      return {};
    }

    const attributes: Record<string, ViewSettings.SettingsControl[]> = {};

    function findSettingViewInGroups(
      settingFieldId: string,
      viewSettingGroups: ViewSettings.RawSettingsGroup[]
    ): ViewSettings.SettingsControlView | undefined {
      for (const group of viewSettingGroups) {
        const viewSetting = group.items.find((viewSetting) => viewSetting.fieldId === settingFieldId);

        if (viewSetting) {
          return viewSetting;
        }
      }
    }

    for (const [settingKey, settingValues] of Object.entries(this.chartView.infoSettingsValues)) {
      const settingAttributes: ViewSettings.SettingsControl[] = [];

      if (!isObjectWithKeys(settingValues)) {
        continue;
      }

      const viewSettings = this.chartView.settings[settingKey];

      if (settingValues.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
        for (const settingValue of settingValues.settings) {
          if (!settingValue.isShown) {
            continue;
          }

          if (viewSettings.type !== settingValues.type) {
            continue;
          }

          const settingView = viewSettings.items.find((viewSetting) => viewSetting.fieldId === settingValue.fieldId);

          if (settingView) {
            settingAttributes.push({ view: settingView, values: settingValue });
          }
        }
      } else {
        for (const settingsGroup of settingValues.settings) {
          for (const settingValue of settingsGroup.items) {
            if (!settingValue.isShown) {
              continue;
            }

            if (viewSettings.type !== settingValues.type) {
              continue;
            }

            const settingView = findSettingViewInGroups(settingValue.fieldId, viewSettings.groups);

            if (settingView) {
              settingAttributes.push({ view: settingView, values: settingValue });
            }
          }
        }
      }

      attributes[viewSettings.fieldId] = settingAttributes;
    }

    return attributes;
  }

  getGroupTitle(item: WellsGroup): string | LoadingField {
    if (this.isLoading) {
      return new LoadingField();
    }

    const title = item.getFieldValue('name');

    return isValueStringOrNumber(title) ? title.toString() : '';
  }

  getWellTitle(item: ChartWell): string | LoadingField {
    if (this.isLoading) {
      return new LoadingField();
    }

    const title = item.getFieldValue(this.chartView.view.carpet.wells.objectName);

    return isValueStringOrNumber(title) ? title.toString() : '';
  }

  getAttributes = computedFn((item: ChartWell | WellRigOperationStage): string[] | LoadingField[] | null => {
    if (item instanceof ChartWell) {
      if (this.isLoading) {
        return this.shownAttributesFields['wellSettings']?.map(() => new LoadingField());
      }

      return this.shownAttributesFields['wellSettings']?.map(
        (attribute) => this.receiveWellAttributeValue(item, attribute.view) || '-'
      );
    }

    if (item instanceof WellRigOperationStage) {
      if (this.isLoading) {
        return this.shownAttributesFields['stagesSettings']?.map(() => new LoadingField());
      }

      return this.shownAttributesFields['stagesSettings']?.map((attribute) =>
        this.recieveStageAttributeValue(item, attribute.view)
      );
    }

    return null;
  });

  getStageTitle(item: WellRigOperationStage): string | LoadingField {
    if (this.isLoading) {
      return new LoadingField();
    }

    const { attrName, refObjectAttr, refObjectType } = this.chartView.view.carpet.stages;

    const attrValue = item.getFieldValue(attrName);

    if (!isValueStringOrNumber(attrValue)) {
      return '-';
    }

    if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const attributeObject = refObject?.find(({ id }) => id.toString() === attrValue.toString());
      const attribute = attributeObject?.data[refObjectAttr];

      return attribute ? attribute.toString() : '-';
    } else {
      return attrValue.toString() || '-';
    }
  }

  getWellColor(item: ChartWell): string {
    return getWellColor(item.item, this.appSettings.wellColorRules);
  }

  getStageTooltip(item: WellRigOperationStage): WellsViewSettings.StageTooltip | undefined {
    try {
      const { parentWell: well, parentRigOperation: rigOperation } = item;

      if (!well) {
        return;
      }

      const { wellName, attributes: rawAttributes } = this.chartView.view.carpet.stages.tooltip;

      const title = well.getFieldValue(wellName);

      const startDateString = DateHelper.unixToDateFormat(item.start);
      const endDateString = DateHelper.unixToDateFormat(item.end);

      const parsedAttributes: WellsViewSettings.StageTooltipAttribute[] = [];

      for (const rawTooltipAttr of rawAttributes) {
        const attribute = this.processTooltipAttribute(rigOperation, rawTooltipAttr);

        if (attribute) {
          parsedAttributes.push(attribute);
        }
      }

      return {
        title: isValueStringOrNumber(title) ? title?.toString() || '' : '-',
        subTitle: `${startDateString} - ${endDateString}`,
        attributes: parsedAttributes,
      };
    } catch (e) {
      console.error(e);
    }
  }
}

export namespace WellsViewSettings {
  export interface ItemWithFieldNameGetter {
    getFieldValue(fieldName: string): unknown;
  }

  export type StageTooltipAttribute = ColorAttribute | IconAttribute<WellTypeIcon> | RegularAttribute;

  export type StageTooltip = {
    title: string;
    subTitle: string;
    attributes: StageTooltipAttribute[];
  };
}
