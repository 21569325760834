import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { navItems } from './consts';
import styles from './styles.module.scss';

export const DrillingCarpetNavigation = observer(function DrillingCarpetNavigation() {
  const { t } = useTranslation('drillingCarpet');
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {navItems.map((nav) => {
        const isActive = location.pathname.includes(nav.to);

        return (
          <NavLink
            key={nav.to}
            to={nav.to}
            className={clsx(styles.navWrapper, !isActive && styles.navWrapper__inactive)}
          >
            <h3 className={clsx(styles.navTitle, styles.h3, !isActive && styles.navTitle__inactive)}>{t(nav.name)}</h3>
          </NavLink>
        );
      })}
    </div>
  );
});
