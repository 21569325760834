import { flow, makeObservable, observable } from 'mobx';

import { fetchDirectory, fetchSettingsLabels, fetchValuesInterpretations } from 'src/api/directories/fetch-directory';
import { fetchDirectoryTypeAttributes } from 'src/api/directories/fetch-directory-type-attributes';
import { fetchJoinedDirectory, fetchJoinedDirectoryDeprecated } from 'src/api/directories/fetch-joined-directory';
import { TDictObject } from 'src/api/directories/types';
import { BaseApiError, Validation400ApiErrorWithCause } from 'src/errors';
import { REF_QUERY_REG_EXP } from 'src/shared/constants/ref-query-reg-exp';
import { hasValue } from 'src/shared/utils/common';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';

import { I18NextStore } from '../i18next/i18next-store';
import { NotificationsStore } from '../notifications-store/notifications-store';

import {
  TDirectoriesAttributesDictionary,
  TJoinResponse,
  TPlainDictObject,
  TRefQuery,
  TValuesInterpretations,
} from './types';

export function isValidationErrorWithCause<T>(error: unknown): error is Validation400ApiErrorWithCause<T> {
  return error instanceof Validation400ApiErrorWithCause;
}

export class Directories {
  private currentAttributesRequests: Record<string, Promise<TDirectoriesAttributesDictionary>> = {};
  private currentObjectsRequests: Record<string, Promise<TDictObject[]>>;

  @observable dictionary: TDirectoriesAttributesDictionary = {};
  @observable valuesInterpretations: TValuesInterpretations = {};
  @observable objects: Record<string, TDictObject[]> = {};
  /** @deprecated */
  @observable joinedObjectsDeprecated: Record<string, TPlainDictObject[]> = {};
  @observable joinedObjects: Record<string, TJoinResponse[]> = {};
  @observable isLoading: boolean = false;
  @observable errors: Error[] | null = null;
  @observable labelsByFieldId?: Record<string, string>;

  readonly i18: I18NextStore;
  readonly notifications: NotificationsStore;

  constructor(i18: I18NextStore, notifications: NotificationsStore) {
    this.i18 = i18;
    this.notifications = notifications;
    this.currentAttributesRequests = {};
    this.currentObjectsRequests = {};

    makeObservable(this);
  }

  private async _fetchDirectoryTypeAttributes(type: string, attr: string): Promise<TDirectoriesAttributesDictionary> {
    const request = fetchDirectoryTypeAttributes(type, attr);
    this.currentAttributesRequests[type] = request;
    return await request;
  }

  private async _fetchDirectoryObject(objectName: string): Promise<TDictObject[]> {
    const request = fetchDirectory(objectName);
    this.currentObjectsRequests[objectName] = request;
    return request;
  }

  async loadLabelsAndInterpretations(): Promise<void> {
    await Promise.all([this.loadLables(), this.loadValuesInterpretations()]);
  }

  getLabel(attr: string): string | null {
    if (!!this.dictionary[attr]) {
      return this.dictionary[attr].defaultLabel;
    }

    return null;
  }

  getFieldLabel(fieldId: string): string | null {
    return this.labelsByFieldId?.[`${fieldId}.label`] || null;
  }

  getFieldPlaceholder(fieldId: string): string | null {
    return this.labelsByFieldId?.[`${fieldId}.placeholder`] || null;
  }

  getFieldNullValue(fieldId: string): string | null {
    return this.labelsByFieldId?.[`${fieldId}.nullValue`] || null;
  }

  getFieldComment(fieldId: string): string | null {
    return this.labelsByFieldId?.[`${fieldId}.comment`] || null;
  }

  getFieldUnit(fieldId: string): string | null {
    return this.labelsByFieldId?.[`${fieldId}.unit`] || null;
  }

  getObject(objName: string | undefined, onlyActive?: boolean): TDictObject[] | null {
    if (objName && !!this.objects[objName]) {
      const obj = this.objects[objName];

      if (onlyActive) {
        return obj.filter((dirValue) => dirValue.status === 'ACTIVE');
      }

      return obj;
    }

    return null;
  }

  /** @deprecated */
  getJoinedObjectDeprecated(refQuery?: TRefQuery): TPlainDictObject[] | null {
    if (!refQuery) return null;
    const serializedRefQuery = JSON.stringify(refQuery);
    return this.joinedObjectsDeprecated[serializedRefQuery] || null;
  }

  getJoinedObject(refQuery?: TRefQuery): TJoinResponse[] | null {
    if (!refQuery) return null;
    const serializedRefQuery = JSON.stringify(refQuery);
    return this.joinedObjects[serializedRefQuery] || null;
  }

  getValueInterpretation(fieldId: string, value: unknown, undefinedAsNull: boolean = false): string | number | null {
    const interObject = this.valuesInterpretations[fieldId];
    const _value = value === undefined && undefinedAsNull ? null : value;

    if (_value === null || _value === 'null') {
      return interObject?.['null'] || null;
    }

    if (typeof _value === 'string') {
      return interObject?.[_value] || null;
    }

    if (typeof _value === 'number' || typeof _value === 'boolean') {
      return interObject?.[_value.toString()] || null;
    }

    return null;
  }

  async loadObjects(objectsNames: string[]): Promise<void> {
    const promises: Promise<void>[] = objectsNames.map((obj) => {
      if (!this.objects[obj]) {
        return this.fetchDirectoryObject(obj);
      }

      return Promise.resolve();
    });

    await Promise.all(promises);
  }

  async loadAttrNames(attrs: string[]): Promise<void> {
    const promises: Promise<void>[] = attrs.map((attr) => {
      if (!this.dictionary[attr]) {
        return this.fetchDirectoryAttribute(attr);
      }
      return Promise.resolve();
    });

    await Promise.all(promises);
  }

  /** @deprecated */
  async loadJoinedObjectsDeprecated(refQueries: TRefQuery[]): Promise<void> {
    const promises: Promise<void>[] = refQueries.map((refQuery) => {
      if (!this.joinedObjects[JSON.stringify(refQuery)]) {
        return this.fetchJoinedDirectoryDeprecated(refQuery);
      }
      return Promise.resolve();
    });

    await Promise.all(promises);
  }

  async loadJoinedObjects(refQueries: TRefQuery[]): Promise<void> {
    const promises: Promise<TJoinResponse[] | void>[] = refQueries.map((refQuery) => {
      if (!this.joinedObjects[JSON.stringify(refQuery)]) {
        return this.fetchJoinedDirectory(refQuery);
      }
      return Promise.resolve();
    });

    await Promise.all(promises);
  }

  @flow.bound
  private async *loadLables(): Promise<void> {
    try {
      const labels = await fetchSettingsLabels();
      yield;

      this.labelsByFieldId = labels;
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('directories:Errors.receivingLabels');
    }
  }

  @flow.bound
  private async *loadValuesInterpretations(): Promise<void> {
    try {
      const interpretations = await fetchValuesInterpretations();
      yield;

      this.valuesInterpretations = interpretations || {};
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('directories:Errors.receivingInterpretations');
    }
  }

  @flow.bound
  private async *fetchDirectoryObject(objName: string) {
    try {
      const dictionaryObject = !!this.currentObjectsRequests[objName]
        ? await this.currentObjectsRequests[objName]
        : await this._fetchDirectoryObject(objName);
      yield;

      this.objects[objName] = dictionaryObject;
    } catch (error) {
      yield;
      if (isValidationErrorWithCause<{ error: { longMessage: string } }>(error)) {
        this.notifications.showErrorMessage(error.reason.error.longMessage);
        return;
      }
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('directories:Errors.recievingType');
      return;
    }
  }

  @flow.bound
  private async *fetchDirectoryAttribute(attr: string) {
    const splittedAttr = attr.split('.');
    let _dict: TDirectoriesAttributesDictionary = {};
    try {
      // optimization to reduce the number of duplicate requests
      _dict = !!this.currentAttributesRequests[splittedAttr[0]]
        ? await this.currentAttributesRequests[splittedAttr[0]]
        : await this._fetchDirectoryTypeAttributes(splittedAttr[0], attr);
      yield;
    } catch (error) {
      yield;
      if (isValidationErrorWithCause<{ error: { longMessage: string } }>(error)) {
        this.notifications.showErrorMessage(error.reason.error.longMessage);
        return;
      }
      this.notifications.showErrorMessageT('directories:Errors.recievingType');
      return;
    } finally {
      this.dictionary = { ...this.dictionary, ..._dict };
    }
  }

  private filterInvalidJoinWhere(refQuery: TRefQuery): TRefQuery {
    const joinWhere = refQuery.where
      ? {
          where: refQuery.where.filter((where) => {
            const whereValue = where['value'];
            return hasValue(whereValue) && whereValue !== 'undefined' && whereValue !== 'null';
          }),
        }
      : {};

    return {
      ...refQuery,
      ...joinWhere,
      join: refQuery.join.map((join) => {
        const where = join.where
          ? {
              where: join.where.filter((where) => {
                const whereValue = where['value'];

                return hasValue(whereValue) && whereValue !== 'undefined' && whereValue !== 'null';
              }),
            }
          : {};

        return {
          ...join,
          ...where,
        };
      }),
    };
  }

  fetchDynamicJoinObject = async (refQuery: TRefQuery, values: Record<string, unknown>): Promise<TJoinResponse[]> => {
    try {
      const stringifiedRefQuery = JSON.stringify(refQuery);

      const parseKey = (_: string, sign: string, key: string): string => {
        const valueKey = `${sign}${key}`;
        const value = values[valueKey];

        if (!hasValue(value) || (Array.isArray(value) && !value.length)) {
          return 'null';
        }

        if (isStringNumberOrBoolean(value)) {
          return value.toString();
        }

        if (Array.isArray(value)) {
          return value.join(',');
        }

        return JSON.stringify(value);
      };

      const stringifiedRefQueryWithSetValues = stringifiedRefQuery.replace(REF_QUERY_REG_EXP, parseKey);
      const refQueryWithFilteredInvalidWhere = this.filterInvalidJoinWhere(
        JSON.parse(stringifiedRefQueryWithSetValues)
      );

      return await fetchJoinedDirectory(refQueryWithFilteredInvalidWhere);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  /** @deprecated */
  @flow.bound
  private async *fetchJoinedDirectoryDeprecated(refQuery: TRefQuery) {
    try {
      if (isDynamicJoin(refQuery)) {
        // Remove 'where' field to load all items.
        const simpleRefQuery: TRefQuery = {
          join: refQuery.join.map(({ where, ...joinItem }) => joinItem),
          objectType: refQuery.objectType,
          joinedAlias: refQuery.joinedAlias,
        };
        const res = await fetchJoinedDirectoryDeprecated(simpleRefQuery);
        yield;

        this.joinedObjectsDeprecated[JSON.stringify(refQuery)] = res;
      } else {
        const res = await fetchJoinedDirectoryDeprecated(refQuery);
        yield;

        this.joinedObjectsDeprecated[JSON.stringify(refQuery)] = res;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }

  @flow.bound
  async *fetchJoinedDirectory(refQuery: TRefQuery) {
    try {
      if (isDynamicJoin(refQuery)) {
        // Remove 'where' field to load all items.
        const simpleRefQuery: TRefQuery = {
          join: refQuery.join.map(({ where, ...joinItem }) => joinItem),
          objectType: refQuery.objectType,
          joinedAlias: refQuery.joinedAlias,
        };
        const res = await fetchJoinedDirectory(simpleRefQuery);
        yield;

        this.joinedObjects[JSON.stringify(refQuery)] = res;
        return res;
      } else {
        const res = await fetchJoinedDirectory(refQuery);
        yield;

        this.joinedObjects[JSON.stringify(refQuery)] = res;
        return res;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }
}
