import { flow, makeObservable } from 'mobx';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { UserSettingsManager } from 'src/api/user-settings';
import { throttleFn } from 'src/shared/utils/throttle-fn';
import { RootStore } from 'src/store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { ChartGrouping } from '../../shared/chart-grouping';
import { WellViewSettingsSidebarStore } from '../well-view-settings-sidebar';

import { WellsGroup } from './entities';
import { WellsChartView } from './wells-chart-view';
import { wellGroupsToOrderSettings } from './wells-chart.utils';
import { WellsViewSettingsProvider } from './wells-view-settings-provider';

export class WellsViewSettingsStore {
  private readonly notifications: NotificationsStore;

  readonly view: WellsChartView;
  readonly sidebar: WellViewSettingsSidebarStore;
  readonly manager: UserSettingsManager<ViewSettings.WellsChartRawSettingsValues>;
  readonly provider: WellsViewSettingsProvider;

  constructor(
    rootStore: RootStore,
    view: WellsChartView,
    manager: UserSettingsManager<ViewSettings.WellsChartRawSettingsValues>
  ) {
    this.notifications = rootStore.notifications;
    this.view = view;
    this.manager = manager;
    this.sidebar = new WellViewSettingsSidebarStore(rootStore, this.view, this.manager);
    this.provider = new WellsViewSettingsProvider(this.view, rootStore.directories, rootStore.appSettings);

    makeObservable(this);
  }

  @flow.bound
  private async *updateWellGroupsSettings(wellGroups: WellsGroup[], grouping: ChartGrouping) {
    try {
      const wellsSettings: ViewSettings.WellsOrderSettingsValues = wellGroupsToOrderSettings(wellGroups);

      const collectAllWellsOrderSettings = (): ViewSettings.WellsOrderSettingsValuesByGrouping[] => {
        if (!this.view.ownSettingsValues.wellsOrder?.length) {
          return [
            {
              grouping: grouping.value,
              settings: wellsSettings,
            },
          ];
        }

        const allSettingsEntries: ViewSettings.WellsOrderSettingsValuesByGrouping[] =
          this.view.ownSettingsValues.wellsOrder.filter((entry) => entry.grouping !== grouping.value);

        allSettingsEntries.push({
          grouping: grouping.value,
          settings: wellsSettings,
        });

        return allSettingsEntries;
      };

      const allViewSettings: ViewSettings.WellsChartRawSettingsValues = {
        info: this.view.infoSettingsValues,
        wellsOrder: collectAllWellsOrderSettings(),
      };

      if (allViewSettings) {
        await this.manager.update(allViewSettings);
        yield;

        this.view.setSettingsValues(allViewSettings);
      }
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToUpdateChartSettings');
    }
  }

  updateWellGroupsSettingsThrottled = throttleFn((wellGroups: WellsGroup[], grouping: ChartGrouping) => {
    this.updateWellGroupsSettings(wellGroups, grouping);
  }, 1000);

  init(): VoidFunction {
    const disposeProvider = this.provider.init();
    const disposeSidebar = this.sidebar.init();

    return () => {
      disposeProvider();
      disposeSidebar();
    };
  }
}
