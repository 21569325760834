import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { Directories } from 'src/store/directories/directories.store';

import { EditingSettingsItem } from './editing-settings-item';

export namespace ViewSettingsHelper {
  type ViewItemWithValue = {
    view: ViewSettings.SettingsControlView;
    values: ViewSettings.SettingsControlValue;
    order: number;
  };

  type SettingsGroupView = {
    fieldId: string;
    title: string;
    items: ViewSettings.SettingsControlView[];
  };

  export type EditingItemsGroup = { title: string; items: EditingSettingsItem[] };

  const mapValuesToViews = (
    viewItems: ViewSettings.SettingsControlView[],
    values: ViewSettings.SettingsControlValue[]
  ) => {
    return viewItems.reduce((itemsWithValues: ViewItemWithValue[], itemView: ViewSettings.SettingsControlView) => {
      const itemValues = values.find(({ fieldId }) => fieldId === itemView.fieldId);

      if (itemValues) {
        const itemOrder = values.indexOf(itemValues);

        if (itemOrder !== undefined) {
          itemsWithValues.push({ view: itemView, values: itemValues, order: itemOrder });
        }
      } else {
        // Add item that is not registered in values. For ex., when sidebar view was changed on backend.
        itemsWithValues.push({
          view: itemView,
          values: { fieldId: itemView.fieldId, isShown: false },
          order: -1,
        });
      }

      return itemsWithValues;
    }, []);
  };

  const sortItems = (itemsWithValues: ViewItemWithValue[]): ViewItemWithValue[] =>
    itemsWithValues?.sort(({ order: orderFirst }, { order: orderSecond }) => {
      if (orderFirst === -1) {
        return 1;
      }

      if (orderSecond === -1) {
        return 0;
      }

      return orderFirst - orderSecond;
    });

  const initEditingItems = (itemsWithValues: ViewItemWithValue[], directories: Directories): EditingSettingsItem[] => {
    return itemsWithValues.reduce((editingSettingsItems: EditingSettingsItem[], { view, values }) => {
      const label = directories.getFieldLabel(view.fieldId);

      if (label) {
        editingSettingsItems.push(new EditingSettingsItem({ fieldId: view.fieldId, label }, values));
      }

      return editingSettingsItems;
    }, []);
  };

  export const simpleItemsToEditing = (
    viewItems: ViewSettings.SettingsControlView[],
    values: ViewSettings.SettingsControlValue[],
    directories: Directories
  ): EditingSettingsItem[] => {
    const viewItemsWithValues = mapValuesToViews(viewItems, values);
    const sortedItemsWithValues = sortItems(viewItemsWithValues);

    return initEditingItems(sortedItemsWithValues, directories);
  };

  export const groupedItemsToEditing = (
    viewItemsGroups: SettingsGroupView[],
    values: ViewSettings.SettingsGroupValues[],
    directories: Directories
  ): EditingItemsGroup[] => {
    return viewItemsGroups.map((settingsGroup, index): ViewSettings.GroupedSettingsControlsGroupData => {
      const currentGroupValues = values[index];

      const settingsGroupTitle = directories.getFieldLabel(settingsGroup.fieldId) || settingsGroup.title || '';

      if (currentGroupValues) {
        const groupItemsWithValues = mapValuesToViews(settingsGroup.items, currentGroupValues.items);
        const sortedItemsWithValues = sortItems(groupItemsWithValues);

        return {
          title: settingsGroupTitle,
          fieldId: settingsGroup.fieldId,
          items: initEditingItems(sortedItemsWithValues, directories),
        };
      }

      return {
        title: settingsGroupTitle,
        fieldId: settingsGroup.fieldId,
        items: settingsGroup.items.reduce(
          (editingSettingsItems: EditingSettingsItem[], view: ViewSettings.SettingsControlView) => {
            const label = directories.getFieldLabel(view.fieldId);

            if (label) {
              editingSettingsItems.push(
                new EditingSettingsItem({ fieldId: view.fieldId, label }, { fieldId: view.fieldId, isShown: false })
              );
            }

            return editingSettingsItems;
          },
          []
        ),
      };
    });
  };

  export function getTypedSetting(
    rawSetting: ViewSettings.RawFlatSettingsControls | ViewSettings.RawGroupedSettingsControls
  ): ViewSettings.TypedFlatSettingsControls | ViewSettings.TypedGroupedSettingsControls {
    if ('groups' in rawSetting) {
      return {
        ...rawSetting,
        type: ViewSettings.ViewSettingsTabsDataTypes.grouped,
      };
    } else {
      return {
        ...rawSetting,
        type: ViewSettings.ViewSettingsTabsDataTypes.flat,
      };
    }
  }

  export function checkSettingsValues(values: unknown): boolean {
    if (!isObjectWithKeys(values)) {
      return false;
    }
    if (
      Object.entries(values).some(([key, value]) => {
        return (
          key !== 'ownerUserId' &&
          (!isObjectWithKeys(value) || !('type' in value) || !('settings' in value) || !Array.isArray(value.settings))
        );
      })
    ) {
      return false;
    }

    return true;
  }

  export function getUpdatedSettings(
    settings: Record<string, ViewSettings.GroupedSettingsControls | ViewSettings.FlatSettingsControls>
  ): Record<string, ViewSettings.RawSettingValue> {
    const updatedSettings: Record<string, ViewSettings.RawSettingValue> = {};

    for (const tabSettingsKey in settings) {
      const tabSettings = settings[tabSettingsKey];

      if (tabSettings.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
        updatedSettings[tabSettings.tabId] = {
          type: tabSettings.type,
          settings: tabSettings.items.map((setting) => setting.settingsValue),
        };
      } else {
        updatedSettings[tabSettings.tabId] = {
          type: tabSettings.type,
          settings: tabSettings.items.map((group) => ({
            fieldId: group.fieldId,
            items: group.items.map((setting) => setting.settingsValue),
          })),
        };
      }
    }

    return updatedSettings;
  }

  export function getSortedRawFlatSettings(
    items: ViewSettings.SettingsControlView[],
    savedSettingsValuesObj: ViewSettings.RawSettingValue | undefined
  ): ViewSettings.SettingsControlWithOrder[] {
    const unsortedRawFlatSettings = items.reduce((_settings: ViewSettings.SettingsControlWithOrder[], settingView) => {
      if (savedSettingsValuesObj && savedSettingsValuesObj.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
        const savedSettingDataIndex = savedSettingsValuesObj.settings.findIndex(
          (settingValues) => settingValues.fieldId === settingView.fieldId
        );
        const savedSettingData = savedSettingsValuesObj.settings[savedSettingDataIndex];

        return [
          ..._settings,
          {
            view: settingView,
            order: savedSettingDataIndex,
            values: !!savedSettingData ? savedSettingData : { fieldId: settingView.fieldId, isShown: false },
          },
        ];
      } else if (!savedSettingsValuesObj) {
        // Add item that is not registered in values. For ex., when sidebar view was changed on backend.
        return [
          ..._settings,
          {
            view: settingView,
            order: -1,
            values: { fieldId: settingView.fieldId, isShown: false },
          },
        ];
      }

      return _settings;
    }, []);

    return unsortedRawFlatSettings.sort(({ order: orderFirst }, { order: orderSecond }) => orderFirst - orderSecond);
  }

  export function getGroupWithSortedRawSettings(
    group: ViewSettings.RawSettingsGroup,
    savedSettings: ViewSettings.RawSettingValue
  ): Omit<ViewSettings.RawSettingsGroup, 'items'> & {
    items: ViewSettings.SettingsControlWithOrder[];
  } {
    return {
      fieldId: group.fieldId,
      items: group.items
        .reduce((_settings: ViewSettings.SettingsControlWithOrder[], settingView) => {
          if (savedSettings && savedSettings.type === ViewSettings.ViewSettingsTabsDataTypes.grouped) {
            const savedGroup = savedSettings.settings.find((savedGroup) => savedGroup.fieldId === group.fieldId);
            const savedSettingIndex = savedGroup?.items.findIndex((setting) => setting.fieldId === settingView.fieldId);

            if (hasValue(savedSettingIndex)) {
              const savedSettingValues = savedGroup?.items[savedSettingIndex];

              return [
                ..._settings,
                {
                  view: settingView,
                  order: savedSettingIndex,
                  values: !!savedSettingValues ? savedSettingValues : { fieldId: settingView.fieldId, isShown: false },
                },
              ];
            } else {
              return [
                ..._settings,
                {
                  view: settingView,
                  order: -1,
                  values: { fieldId: settingView.fieldId, isShown: false },
                },
              ];
            }
          } else if (!savedSettings) {
            return [
              ..._settings,
              {
                view: settingView,
                order: -1,
                values: { fieldId: settingView.fieldId, isShown: false },
              },
            ];
          }

          return _settings;
        }, [])
        .sort(({ order: orderFirst }, { order: orderSecond }) => orderFirst - orderSecond),
    };
  }

  export function mapViewAndValuesToSettingsControls(
    viewSettings: ViewSettings.TypedSettingsControls,
    viewSettingsValues: ViewSettings.InfoSettingsValues,
    directories: Directories
  ): Record<string, ViewSettings.GroupedSettingsControls | ViewSettings.FlatSettingsControls> {
    const settings: Record<string, ViewSettings.GroupedSettingsControls | ViewSettings.FlatSettingsControls> = {};

    for (const tabId in viewSettings) {
      const tabViewData = viewSettings[tabId];
      const savedSettingsValuesObj = viewSettingsValues[tabId];

      if (tabViewData.type === ViewSettings.ViewSettingsTabsDataTypes.flat) {
        const sortedRawFlatSettings = getSortedRawFlatSettings(tabViewData.items, savedSettingsValuesObj);

        settings[tabViewData.fieldId] = {
          type: tabViewData.type,
          tabId,
          fieldId: tabViewData.fieldId,
          items: sortedRawFlatSettings.reduce((settings: EditingSettingsItem[], { view, values }) => {
            const label = directories.getFieldLabel(view.fieldId);

            return [
              ...settings,
              new EditingSettingsItem({ fieldId: view.fieldId, label: label || 'Unrecognized field' }, values),
            ];
          }, []),
        };
      } else {
        const sortedRawGroupedSettings = tabViewData.groups.map((group) =>
          getGroupWithSortedRawSettings(group, savedSettingsValuesObj)
        );

        settings[tabViewData.fieldId] = {
          type: tabViewData.type,
          tabId,
          fieldId: tabViewData.fieldId,
          items: sortedRawGroupedSettings.map((group) => {
            return {
              fieldId: group.fieldId,
              title: directories.getFieldLabel(group.fieldId) || 'Unrecognized group',
              items: group.items.map((setting) => {
                const label = directories.getFieldLabel(setting.view.fieldId) || 'Unrecognized field';

                return new EditingSettingsItem({ fieldId: setting.view.fieldId, label }, setting.values);
              }),
            };
          }),
        };
      }
    }

    return settings;
  }
}
