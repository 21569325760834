import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/shared/components/checkbox';

import styles from './check-table-cell.module.scss';

interface Props {
  isChecked: boolean;
  onChange: (event?: CheckboxChangeEvent) => void;
}

export const CheckTableCell = observer(function CheckTableCell({ isChecked, onChange }: Props) {
  return (
    <div className={styles.container}>
      <Checkbox onChange={onChange} isChecked={isChecked} />
    </div>
  );
});
