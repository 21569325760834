import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { DashboardPage } from 'src/pages/dashboard-page/dashboard-page';
import { DrillingCarpetPage } from 'src/pages/drilling-carpet-page/drilling-carpet-page';
import { DrillingPlanChartPage } from 'src/pages/drilling-plan-chart';
import { ImportConflictsPage } from 'src/pages/import-conflicts-page/import-conflicts-page';
import { ImportMatchingPage } from 'src/pages/import-matching-page/import-matching-page';
import { NewWellPage } from 'src/pages/new-well-page/new-well-page';
import { WellsPage } from 'src/pages/wells-page';
import { ROUTES } from 'src/shared/constants/routes';

import { PlanPage } from '../pages/plan-page';

export const Router: FC = observer(function Router() {
  return (
    <Routes>
      <Route path={ROUTES.carpet} element={<DrillingCarpetPage />}>
        <Route path={ROUTES.wells} element={<WellsPage />} />
        <Route path={ROUTES.schedule} element={<DrillingPlanChartPage />} />
        <Route path={ROUTES.planning} element={<PlanPage />} />
        <Route path={ROUTES.matching} element={<ImportMatchingPage />} />
        <Route path={ROUTES.conflicts} element={<ImportConflictsPage />} />
        <Route path={ROUTES.newWell} element={<NewWellPage />} />
        <Route path="*" element={<Navigate to={ROUTES.wells} />} />
      </Route>
      <Route path={ROUTES.dashboard} element={<DashboardPage />} />
      <Route path="*" element={<Navigate to={ROUTES.carpet} />} />
    </Routes>
  );
});

export default Router;
