import { action, flow, makeObservable, observable } from 'mobx';

import { RigStore } from 'src/features/drilling-rig-sidebar/store/rig.store';
import { ViewMode } from 'src/features/drilling-rig-sidebar/store/types';
import { GraphInfoSidebarStore, TSidebarInfoView } from 'src/features/graph-info-sidebar/graph-info-sidebar';
import { RootStore } from 'src/store';
import { View } from 'src/store/views/views-store';

import { DEFAULT_VIEW_MODE } from './view-pads-sidebar.constants';

export class ViewPadsSidebarStore extends GraphInfoSidebarStore {
  protected readonly viewManager: View<TSidebarInfoView>;

  private closeHandler?: ViewPadsSidebarStore.CloseHandler;

  readonly rigStore: RigStore;

  @observable isOpen = false;
  @observable tab: ViewMode = DEFAULT_VIEW_MODE;

  constructor(rootStore: RootStore) {
    super({ rootStore });
    this.viewManager = rootStore.views.rigSidebarView;
    this.rigStore = new RigStore(rootStore.notifications);

    makeObservable(this);
  }

  @action.bound
  onTabChange(tab: ViewMode): void {
    this.tab = tab;
  }

  @flow.bound
  async *open(
    planVersionId: number,
    rigId: number,
    tab?: ViewMode,
    closeHandler?: ViewPadsSidebarStore.CloseHandler
  ): Promise<void> {
    this.closeHandler = closeHandler;

    await this.loadView();
    await this.rigStore.loadRig(rigId, planVersionId);

    yield;

    if (tab) {
      this.onTabChange(tab);
    }

    this.isOpen = true;
  }

  @action.bound
  close(): void {
    this.isOpen = false;
    this.tab = DEFAULT_VIEW_MODE;
    this.rigStore.clear();
    this.closeHandler?.();
  }
}

export namespace ViewPadsSidebarStore {
  export type CloseHandler = VoidFunction;
}
