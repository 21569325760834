import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/shared/components/checkbox/checkbox';

import styles from './styles.module.scss';

type Props = {
  isChecked: boolean;
  isDisabled: boolean;
  text?: string;
  onChange: () => void;
};

export const CollapseItem = observer(function CollapseItem({ isChecked, isDisabled, text, onChange }: Props) {
  return (
    <div className={styles.checkboxWrapper}>
      <Checkbox isChecked={isChecked} isDisabled={isDisabled} className={styles.text} onChange={onChange}>
        {text}
      </Checkbox>
    </div>
  );
});
