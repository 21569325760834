import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as LogoutIcon } from 'src/shared/assets/icons/logout.svg';
import profileImg from 'src/shared/assets/img/profile.png';
import { Auth } from 'src/store/auth/auth-store';

import styles from './styles.module.scss';

type Props = {
  isOpened: boolean;
  onLogout: VoidFunction;
  userInfo: Auth.UserInfo | null;
};

export const UserSection: React.FC<Props> = observer(function UserSection({ isOpened, userInfo, onLogout }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.photoWrapper}>
        <img className={styles.photo} src={profileImg} alt="avatar" />
      </div>
      <div className={clsx(styles.nameAndLogoutWrapper, isOpened && styles.nameAndLogoutWrapper__opened)}>
        <p className={clsx(styles.text)}>{userInfo?.name}</p>

        <button className={styles.logoutButton} onClick={onLogout}>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
});
