import { observer } from 'mobx-react-lite';
import { PointerEventHandler } from 'react';

import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/move.svg';

import styles from './well.module.scss';

interface Props {
  onPointerDown?: PointerEventHandler<HTMLElement>;
  onPointerMove?: PointerEventHandler<HTMLElement>;
  onPointerUp?: PointerEventHandler<HTMLElement>;
  onPointerCancel?: PointerEventHandler<HTMLElement>;
}

export const DraggingElement = observer(function DraggingElement({
  onPointerCancel,
  onPointerUp,
  onPointerMove,
  onPointerDown,
}: Props) {
  return (
    <div
      className={styles.moveElement}
      onPointerMove={onPointerMove}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerCancel={onPointerCancel}
    >
      <MoveIcon className={styles.moveIcon} />
    </div>
  );
});
