import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { PadCard } from '../../../../pad-card';
import { TemporaryPadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import styles from '../../pad-rig-operation-wrapper/pad-rig-operation-wrapper.module.scss';

interface Props {
  item: TemporaryPadRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const TemporaryPadRigOperationWrapper = observer(function TemporaryPadRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
}: Props) {
  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });
  const padAttributes = viewSettingsProvider.getPadAttributes(item);

  const padIcons = padAttributes?.length && (
    <div className={styles.iconsContainer}>
      {padAttributes?.map((attributeName) => (
        <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
      ))}
    </div>
  );

  return (
    <div key={item.id} ref={ref} className={styles.container}>
      <PadCard
        title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
        extra={padIcons}
        attributes={viewSettingsProvider.getAttributes(item)}
        className={styles.card}
      />
    </div>
  );
});
