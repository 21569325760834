import { ComparingIndicators } from 'src/api/chart/comparing-indicators-api';
import { TrendType } from 'src/shared/constants/trend-type';

import { Range } from '../../../layers/model';

export class ComparingIndicatorColumn {
  readonly item: ComparingIndicators.Column;

  constructor(item: ComparingIndicators.Column) {
    this.item = item;
  }

  get range(): Range<number> {
    return { start: this.item.start, end: this.item.end };
  }

  get start(): number {
    return this.item.start;
  }

  get end(): number {
    return this.item.end;
  }

  get firstVersionData(): Partial<Record<TrendType, number>> {
    return this.item.first;
  }

  get secondVersionData(): Partial<Record<TrendType, number>> {
    return this.item.second;
  }

  get key(): string {
    return `indicators-column-${this.start}-${this.end}`;
  }
}
