import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import styles from './vertical-colored-card.module.scss';

interface Props {
  color?: string;
  className?: string;
}

export const VerticalColoredCard = observer(function VerticalColoredCard({
  color = 'transparent',
  children,
  className,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(className, styles.container)}
      style={{ '--vertical-card-border-color': color }}
      children={children}
    />
  );
});
