import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';

import { EditingColumnType } from 'src/features/planning/views/view-settings-sidebar/types';

interface Props {
  children: React.ReactNode;
  dragEnd: (event: DragEndEvent) => void;
  items: EditingColumnType[];
}

export const SortableWrapper = observer(function SortableWrapper({ children, dragEnd, items }: Props) {
  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={dragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
});
