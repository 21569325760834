import { action, autorun, comparer, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { ComparisonStore } from 'src/store/comparison/comparison-store';

import { IChartDataModel, Range } from '../../../layers/model';
import { ComparingChartRig } from '../../../presets/comparing-drilling-rigs-chart/entities';
import {
  ChartRig,
  LoadingRigOperations,
  PadRigOperation,
  RigsGroup,
  WellRigOperation,
} from '../../../presets/drilling-rigs-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';

import { IComparingRigsChartDataApi, IComparingRigsChartDataStorage } from './comparing-rigs-chart-data.types';

export class ComparingRigsChartDataModel implements IChartDataModel<ComparingRigsChartDataModel.ViewItem[] | null> {
  private readonly api: IComparingRigsChartDataApi;
  private readonly dataView: DataView;
  private readonly dataStorage: IComparingRigsChartDataStorage;
  @observable private comparingVersionsStore: ComparisonStore;

  constructor(
    initialVerticalViewRange: Range<number>,
    initialHorizontalViewRange: Range<number>,
    dataView: DataView,
    api: IComparingRigsChartDataApi,
    storage: IComparingRigsChartDataStorage,
    comparingVersionsStore: ComparisonStore
  ) {
    this.dataView = dataView;
    this.api = api;
    this.dataStorage = storage;
    this.comparingVersionsStore = comparingVersionsStore;

    this.dataStorage.setVerticalViewRange(initialVerticalViewRange.start, initialVerticalViewRange.end);
    this.dataStorage.setHorizontalViewRange(initialHorizontalViewRange.start, initialHorizontalViewRange.end);

    makeObservable(this);
  }

  @flow.bound
  private async *downloadWells(rigsIds: number[], horizontalRange: Range<number>) {
    const firstVersion = this.comparingVersionsStore.firstTargetPlan;
    const secondVersion = this.comparingVersionsStore.secondTargetPlan;

    if (firstVersion && secondVersion) {
      const wells = await this.api.getWells(
        horizontalRange.start,
        horizontalRange.end,
        rigsIds,
        firstVersion.id,
        secondVersion.id,
        this.dataStorage.rigs || []
      );
      yield;

      if (wells) {
        this.dataStorage.setWells(wells, horizontalRange, this.dataView.type);
      }
    }
  }

  @computed
  get verticalDataRange(): Range<number> {
    return this.dataStorage.allDataVerticalRange || { start: 0, end: 0 };
  }

  init(): VoidFunction {
    const disposeStorage = this.dataStorage.init();

    const disposePositionsCalculating = reaction(
      () => ({
        dataViewType: this.dataView.type,
      }),
      ({ dataViewType }) => {
        this.dataStorage.normalizeData(dataViewType);
      },
      { equals: comparer.shallow, fireImmediately: true }
    );

    const disposeMissingDataProcess = autorun(() => {
      const missingData = this.dataStorage.missingDataBounds;

      if (missingData?.length) {
        for (const missingDataRange of missingData) {
          this.downloadWells([...missingDataRange.rigsIds], missingDataRange.horizontalRange);
        }
      }
    });

    return () => {
      disposeStorage();
      disposePositionsCalculating();
      disposeMissingDataProcess();
    };
  }

  @computed({ equals: comparer.shallow })
  get data(): ComparingRigsChartDataModel.ViewItem[] | null {
    const data = this.dataStorage.data;

    return data.items?.length ? data.items : null;
  }

  @computed
  get rigsInView(): number[] | null {
    const { data } = this.dataStorage;

    if (!data.rigIds?.length) {
      return null;
    }

    return data.rigIds;
  }

  @action.bound
  setVerticalRange(start: number, end: number) {
    this.dataStorage.setVerticalViewRange(start, end);
  }

  @action.bound
  setHorizontalRange(start: number, end: number) {
    this.dataStorage.setHorizontalViewRange(start, end);
  }

  @action.bound
  setRigs(rigs: RigsChart.RawRig[], firstPlanVersionId: number, secondPlanVersionId: number) {
    this.dataStorage.setRigs(rigs, firstPlanVersionId, secondPlanVersionId);
  }

  @action.bound
  setShownWellAttributesNumber(attributesNumber: number): void {
    this.dataStorage.setShownWellAttributesNumber(attributesNumber, this.dataView.type);
  }

  @action.bound
  recalculatePositions() {
    this.dataStorage.normalizeData(this.dataView.type);
  }
}

export namespace ComparingRigsChartDataModel {
  export type ViewItem =
    | Omit<RigsGroup, 'items'>
    | Omit<ComparingChartRig, 'items'>
    | Omit<ChartRig, 'items'>
    | Omit<PadRigOperation, 'wellRigOperations'>
    | WellRigOperation
    | LoadingRigOperations;

  export type ChangesItem = {
    dateStart: number;
    dateEnd: number;
    rigId: string;
    rigsGroupId: string;
  };

  export type Changes = {
    totalCount: number;
    changes: ChangesItem[];
  };
}
