import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useState, useEffect, useLayoutEffect, useRef, ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';

import { useOnClick } from '../../hooks/use-on-click';

import { ESidebarSizes } from './types';

import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  isOpened: boolean;
  title?: string;
  subheader?: ReactNode;
  description?: string;
  size?: keyof typeof ESidebarSizes;
  width?: number;
  isHidden?: boolean;
  side?: 'left' | 'right';
  transitionDuration?: number;
  closeOnClickOutside?: boolean;
  className?: string;
  onClose?: () => void;
  onLeave?: () => void;
  onEnter?: () => void;
}>;

export const Sidebar: React.FC<Props> = observer(function Sidebar({
  title,
  className,
  description,
  size = 'm',
  isHidden,
  isOpened,
  closeOnClickOutside = true,
  transitionDuration = 200,
  children,
  onClose,
  onLeave,
  onEnter,
  subheader,
}) {
  const [containerElement] = useState(() => document.createElement('div'));
  const [isContainerMounted, setIsContainerMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback((): void => {
    if (closeOnClickOutside) {
      onClose?.();
    }
  }, [closeOnClickOutside, onClose]);

  const overlayListeners = useOnClick(onClickOutside);

  function stopPropagation(e: React.BaseSyntheticEvent): void {
    e.stopPropagation();
  }

  useLayoutEffect(() => {
    if (isOpened) {
      document.body.appendChild(containerElement);
      setIsContainerMounted(true);
      return;
    }

    if (!isContainerMounted) return;
    const timer = setTimeout(() => {
      containerElement.remove();
    }, transitionDuration);

    return () => clearTimeout(timer);
  }, [isContainerMounted, transitionDuration, containerElement, isOpened]);

  useEffect(() => {
    if (isOpened && !isHidden && isContainerMounted) {
      window.requestAnimationFrame(() => {
        containerRef!.current?.classList.add(styles[`wrapper__${size}`]);
        containerRef!.current?.classList.add(styles.wrapper__opened);
      });
      return;
    }

    if ((!isOpened || isHidden) && isContainerMounted) {
      window.requestAnimationFrame(() => {
        containerRef!.current?.classList.remove(styles[`wrapper__${size}`]);
        containerRef!.current?.classList.remove(styles.wrapper__opened);
      });
    }
  }, [isHidden, isOpened, size, isContainerMounted]);

  useEffect(() => {
    return () => containerElement.remove();
  }, [containerElement]);

  useEffect(() => {
    const overlay = overlayRef.current;
    const container = containerRef.current;

    if (overlay && container && (onEnter || onLeave)) {
      const handlePointerMove = (e: PointerEvent) => {
        const { x, y, width, height } = container.getBoundingClientRect();
        const isPointerOverSidebar = e.clientX > x && e.clientX < x + width && e.clientY > y && e.clientY < y + height;

        if (isPointerOverSidebar) {
          onEnter?.();
        } else {
          onLeave?.();
        }
      };

      overlay.addEventListener('pointermove', handlePointerMove);

      return () => {
        overlay.removeEventListener('pointermove', handlePointerMove);
      };
    }
  }, [onEnter, onLeave]);

  return createPortal(
    <div
      ref={overlayRef}
      className={styles.overlay}
      onPointerDown={overlayListeners.onPointerDown}
      onPointerMove={overlayListeners.onPointerMove}
      onPointerUp={overlayListeners.onPointerUp}
      onPointerCancel={overlayListeners.onPointerCancel}
      onWheel={stopPropagation}
    >
      <div
        ref={containerRef}
        className={styles.wrapper}
        onPointerDown={stopPropagation}
        onPointerMove={stopPropagation}
        onPointerCancel={stopPropagation}
        onPointerUp={stopPropagation}
      >
        <div className={clsx(className, styles.contentWrapper, styles[`wrapper__${size}`])}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{title}</p>
          </div>
          {!!subheader && subheader}
          {!!description && <p className={styles.descriptionText}>{description}</p>}

          {isOpened && children}
          {onClose && <button className={styles.closeButton} onClick={onClose} />}
        </div>
      </div>
    </div>,
    containerElement
  );
});
