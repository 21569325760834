import { observer } from 'mobx-react-lite';

import { RigsViewSettingsProvider } from 'src/features/drilling-chart/presets/drilling-rigs-chart/rigs-view-settings-provider';
import { SidebarWellRigOperation } from 'src/features/drilling-chart/presets/pads-and-wells-sidebar/entities/well-rig-operation.entity';

import { StageWellTooltipAttribute } from '../../features/stage-well-tooltip-attribute';
import { WellRigOperation } from '../../presets/drilling-rigs-chart/entities';

import styles from './well-rig-operation-wrapper.module.scss';

type Props = {
  well: WellRigOperation | SidebarWellRigOperation;
  viewProvider: RigsViewSettingsProvider;
};

export const WellTooltipInner = observer(function WellTooltipInner({ well, viewProvider }: Props) {
  const tooltip = viewProvider.getWellTooltip(well);

  if (!tooltip) {
    return null;
  }

  return (
    <div className={styles.tooltipInner}>
      {tooltip.attributes.map((attributesList, listIndex) => (
        <div key={listIndex} className={styles.tooltipAttributeGroup}>
          {attributesList.map((attribute, index) => (
            <StageWellTooltipAttribute key={index} item={attribute} />
          ))}
        </div>
      ))}
    </div>
  );
});
