import { RenderFunction, TooltipPlacement } from 'antd/lib/tooltip';
import { observer } from 'mobx-react-lite';
import React, { MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useState } from 'react';

import { Tooltip } from 'src/shared/components/tooltip';

import './overwrite.scss';

export interface TooltipControl {
  handleMouseOver: MouseEventHandler<HTMLDivElement>;
  handleMouseLeave: MouseEventHandler<HTMLDivElement>;
}

interface Props {
  content: ReactNode | RenderFunction;
  children: (control: TooltipControl) => ReactNode;
  placement?: TooltipPlacement;
}

export const ChartTooltip = observer(function ChartTooltip({ content, placement, children }: PropsWithChildren<Props>) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseOver: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (!tooltipVisible) {
        setTooltipVisible(true);
      }
    },
    [tooltipVisible]
  );

  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (tooltipVisible) {
        setTooltipVisible(false);
      }
    },
    [tooltipVisible]
  );

  return (
    <Tooltip text={content} visible={tooltipVisible} destroyTooltipOnHide={{ keepParent: false }} placement={placement}>
      {children({ handleMouseOver, handleMouseLeave })}
    </Tooltip>
  );
});
