import { reaction } from 'mobx';

import { Item } from 'src/shared/entities/abstract-control-entities';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { RootStore } from 'src/store';

import { ApproachesTab } from '../entities/approach-entities';
import { ColumnTab } from '../entities/column-tab.entity';
import { FormStore, TProcessFormFieldsData } from '../entities/form.entity';

export interface IForm {
  fields: Record<string, Item>;
  processFormFields: (callback: (item: Item<unknown>) => void, options?: TProcessFormFieldsData) => void;
}

export abstract class FormPlugin<T extends IForm = IForm> {
  readonly pluginId: number;
  protected readonly rootStore: RootStore;
  protected form?: T;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.pluginId = getRandomNumber();
  }
  abstract connect(form: IForm): VoidFunction | void;
}

export abstract class FormPluginWithFieldsProcessing extends FormPlugin<FormStore> {
  private disposers = new Map<Item, VoidFunction>();

  protected processItems(callback: (item: Item) => void | VoidFunction): VoidFunction | void {
    if (!this.form) {
      return;
    }

    const disposer = reaction(
      this.getAllFormFields,
      (currFields, prevFields) => {
        const { newFields, deletedFields } = this.getNewAndDeletedFields(currFields, prevFields);

        newFields.forEach((field) => {
          const disposer = callback(field);

          if (disposer) {
            this.disposers.set(field, disposer);
          }
        });

        deletedFields.forEach((field) => {
          const disposer = this.disposers.get(field);

          disposer?.();
        });
      },
      { fireImmediately: true }
    );

    return () => {
      disposer();
      for (const tuple of this.disposers) {
        tuple[1]?.();
      }
    };
  }

  private getAllFormFields = (): Item[] => {
    if (!this.form) {
      return [];
    }

    const fields: Item[] = [];

    for (let tab of this.form.tabs) {
      if (tab instanceof ColumnTab) {
        for (let column of tab.columns) {
          for (let item of column.items) {
            fields.push(item);
          }
        }
      }
      if (tab instanceof ApproachesTab) {
        tab.approachesList.approaches.forEach((approach) => {
          approach.fieldsList.forEach((field) => fields.push(field));
          approach.stagesList.stages.forEach((stage) => {
            stage.fieldsList.forEach((field) => fields.push(field));
            stage.sectionsList.sections.forEach((section) => {
              section.fieldsList.forEach((field) => fields.push(field));
            });
          });
        });
      }
    }

    return fields;
  };

  private getNewAndDeletedFields(
    currFields: Item[],
    prevFields: Item[] = []
  ): { newFields: Item[]; deletedFields: Item[] } {
    const deletedFields = [...prevFields];
    const newFields = currFields.filter((field) => {
      const foundField = deletedFields.find((prevField, index) => {
        const isFieldFound = prevField === field;

        if (isFieldFound) {
          deletedFields.splice(index, 1);
        }

        return isFieldFound;
      });

      return !foundField;
    });
    return { newFields, deletedFields };
  }
}
