import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';

import { PadsAndWellsSidebarStore } from '../pads-and-wells-sidebar.store';
import { TSwitchButtonItem, WELLS_TYPE } from '../types';

import styles from './control-bar.module.scss';

interface Props {
  store: PadsAndWellsSidebarStore;
  onNewWellClick?(): void;
}

export const ControlBar = observer(function ControlBar({ store, onNewWellClick }: Props) {
  const { t } = useTranslation('drawers');

  const items: TSwitchButtonItem[] = [
    {
      key: WELLS_TYPE.planned,
      title: t('wells:planned'),
    },
    {
      key: WELLS_TYPE.unplanned,
      title: t('wells:unplanned'),
    },
  ];

  return (
    <div className={styles.container}>
      <SwitchButtonGroup
        items={items}
        value={store.chosenWellType}
        buttonClassName={styles.tile}
        defaultOptionKey={WELLS_TYPE.planned}
        onChange={store.toggleWellsType}
      />
      <Button variant="primary" icon={<PlusIcon />} onClick={onNewWellClick}>
        {t('addPadDrawer.newWell')}
      </Button>
    </div>
  );
});
