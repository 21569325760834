import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { Tooltip } from 'src/shared/components/tooltip';

import styles from './indicators-table.module.scss';

interface Props {
  title: string;
  total: string | ReactNode;
}

export const IndicatorsHeaderColumnRow = observer(function IndicatorsHeaderColumnRow({ title, total }: Props) {
  return (
    <div className={styles.rowHeader}>
      <p className={styles.rowHeaderTitle} title={title}>
        {title}
      </p>

      <div className={styles.rowHeaderTotal}>
        {typeof total === 'string' ? (
          <Tooltip text={total} placement="right" destroyTooltipOnHide>
            <p className={styles.rowHeaderTotalLabel}>{total}</p>
          </Tooltip>
        ) : (
          total
        )}
      </div>
    </div>
  );
});
