import { ReactNode } from 'react';

import { getStageWithComputedDuration } from 'src/api/new-well/requests';
import { TSerializedStage } from 'src/api/new-well/types';
import { BaseApiError, ValidationError } from 'src/errors';
import { Item, ItemContainer } from 'src/shared/entities/abstract-control-entities';
import { DateIntervalField } from 'src/shared/entities/control-entities';
import { hasValue } from 'src/shared/utils/common';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';

import { ApproachStage } from '../../entities/approach-entities';
import { FormStore } from '../../entities/form.entity';
import { FormPlugin } from '../abstract-form-plugin.entity';

import { GetDirectoryValuesButton } from './get-directory-values-button';

export class DirectoryValuesInStagesPlugin extends FormPlugin<FormStore> {
  private readonly PARAMS_WITH_FIELD_ID: Record<string, string> = {
    stageId: 'stage',
    startDate: 'stageDates',
    rigId: 'rig',
    padId: 'pad',
    wellPurposeId: 'wellPurpose',
    wellTypeId: 'wellType',
    fluidTypeId: 'fluidType',
    coreSamplingDayAmount: 'coreSamplingDayAmount',
    testingFormationWorkDayAmount: 'testingFormationWorkDayAmount',
    passingValue: 'passingValue',
    sectionsDetailing: 'sectionsDetailing',
    daysAmountInterpretation: 'daysAmountOfDataInterpretationOperation',
  };

  connect(form: FormStore): void | VoidFunction {
    this.form = form;
  }

  private validateStageWithComputedDurationParams = (
    params: Record<string, unknown>
  ): Record<string, string | number | boolean> => {
    const processedParams: Record<string, string | number | boolean> = {};

    if (!hasValue(params?.startDate)) {
      throw new ValidationError('errors:NewWellForm.autofillStageMisssingStartDate');
    }

    for (const param in params) {
      const value = params[param];

      if (isStringNumberOrBoolean(value)) {
        processedParams[param] = value;
        continue;
      }
      if (Array.isArray(value)) {
        processedParams[param] = value.join(',');
        continue;
      }
    }

    return processedParams;
  };

  private async getStageWithComputedDuration(stage: ApproachStage): Promise<void> {
    if (!this.form) {
      return;
    }

    const params = this.getParams(stage);
    if (!params) {
      return;
    }

    const pendingEventId = getRandomNumber();
    this.form.addPendingEvent(pendingEventId);

    try {
      const validatedParams = this.validateStageWithComputedDurationParams(params);
      const response = await getStageWithComputedDuration(validatedParams);
      this.setReceivedStage(stage, response);
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.rootStore.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      if (e instanceof ValidationError && e.message) {
        this.rootStore.notifications.showErrorMessageT(e.message);
        return;
      }
    } finally {
      this.form.removePendingEvent(pendingEventId);
    }
  }

  private setReceivedStage(stage: ApproachStage, newStageRaw: TSerializedStage): void {
    if (!this.form) {
      return;
    }

    const stageList = this.form.approaches.find((approach) => approach.stagesList.stages.includes(stage))?.stagesList;
    if (stageList) {
      const newStage = stageList.createStageByData(newStageRaw);
      const oldStageIndex = stageList.stages.indexOf(stage);
      if (oldStageIndex >= 0 && newStage) {
        const stages = [...stageList.stages];
        stages.splice(oldStageIndex, 1, newStage);
        stageList.setStages(stages);
      }
    }
  }

  private getParams(stage: ApproachStage): Record<string, unknown> | void {
    if (!this.form) {
      return;
    }

    const params: Record<string, unknown> = {};

    for (const paramName in this.PARAMS_WITH_FIELD_ID) {
      const fieldId = this.PARAMS_WITH_FIELD_ID[paramName];

      const control = this.getManagingControl(fieldId, stage);
      if (control) {
        if (paramName === 'startDate' && control instanceof DateIntervalField) {
          params[paramName] = control.startDate?.unix();
        } else {
          params[paramName] = control.value;
        }
      }
    }

    return params;
  }

  private getManagingControl(fieldId: string, item: ItemContainer): Item | null {
    if (!item.parentControl) {
      return item.fields[fieldId] || this.form?.fields[fieldId] || null;
    }

    const getManaginControlRecursively = (item: ItemContainer, fieldId: string): Item | null => {
      const foundField = item.fields[fieldId];

      if (!foundField) {
        if (item.parentControl) {
          return getManaginControlRecursively(item.parentControl, fieldId);
        } else {
          return this.form?.fields[fieldId] || null;
        }
      }

      return foundField;
    };

    return getManaginControlRecursively(item, fieldId);
  }

  getDurationButton(stage: ApproachStage): ReactNode {
    if (!this.form) {
      return;
    }

    const handleClick = () => {
      this.getStageWithComputedDuration(stage);
    };

    return <GetDirectoryValuesButton onClick={handleClick} />;
  }
}
