import { Pad } from 'src/features/drilling-rig-conflicts-sidebar/store/pad';
import { Well } from 'src/features/drilling-rig-conflicts-sidebar/store/well';
import { assert } from 'src/shared/utils/assert';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { PadTypes } from 'src/types/pad-types';

export class RigConflictsAdapter {
  initRigPads(pads: PadTypes.RawPadWithItems[]): Pad[] {
    return pads.map((rawPad) => {
      const initializedWells = rawPad.items.map((rawRigOperation) => {
        const idFieldName = addGOplanPrefix('PlanWellTriple.id');
        const idFieldValue = rawRigOperation[idFieldName];

        assert(typeof idFieldValue === 'number', `Invalid ID in well object with ID ${idFieldValue}.`);

        return new Well(rawRigOperation, idFieldValue);
      });

      return new Pad(rawPad, rawPad.id, initializedWells);
    });
  }
}
