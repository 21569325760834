import { IHeaderColumn } from '../header-data-model.types';

export class HeaderColumn implements IHeaderColumn {
  end: number;
  id: number;
  start: number;

  constructor(id: number, start: number, end: number) {
    this.id = id;
    this.start = start;
    this.end = end;
  }
}

export class MonthHeaderColumn extends HeaderColumn {}

export class YearHeaderColumn extends HeaderColumn {}
