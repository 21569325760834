import { action, autorun, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { ComparingRigsApi } from 'src/api/chart/comparing-rigs-api';
import { ComparingRigsChartDataApi } from 'src/api/chart/comparing-rigs-chart-data-api';
import { ComparingSummaryDataApi } from 'src/api/chart/comparing-summary-data-api';
import { BaseApiError } from 'src/errors';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { RootStore } from 'src/store';

import { ComparingRigsChartDataModel, ComparingRigsChartDataStorage } from '../../features/comparing-rigs-chart/data';
import { ComparingSummaryDataModel } from '../../features/comparing-summary-data/data/comparing-summary-data-model';
import { DataHeadersPresenter } from '../../features/data-headers/presenter/data-headers-presenter';
import { DataItemsBackgroundPresenter } from '../../features/data-items-background/presenter';
import { DataItemsCompactPresenter } from '../../features/data-items-compact/presenter/data-items-compact-presenter';
import { DataItemsFullPresenter } from '../../features/data-items-full/presenter/data-items-full-presenter';
import { SummaryDataPresenter } from '../../features/summary-data/presenter/summary-data-presenter';
import { ChartGrouping } from '../../shared/chart-grouping';
import { DataView } from '../../shared/data-view/data-view';
import { ChartFiltersForm, FiltersFormStore } from '../../shared/filters-form.store';
import { RigsDataPositionsCalculator } from '../../shared/rigs-data-positions-calculator';
import {
  generateKeyFromRange,
  parseStringToRange,
  StorageKeyManagerWithParser,
} from '../../shared/storage-key-manager';
import { SummaryViewProvider } from '../../shared/summary-view-provider';
import { TimelineController } from '../../shared/timeline-controller';
import { Viewport } from '../../shared/viewport/viewport';
import { ComparingIndicatorsTableStore } from '../comparing-indicators-table';
import { RigsViewSettingsStore } from '../drilling-rigs-chart/rigs-view-settings.store';
import { SummaryDataSidebarStore } from '../summary-data-sidebar/summary-data-sidebar.store';

export class ComparingRigsChartStore {
  private readonly firstPlanVersionId: number;
  private readonly secondPlanVersionId: number;
  private readonly dataView: DataView;
  private readonly api: ComparingRigsApi;
  private readonly grouping: ChartGrouping;

  private readonly rootStore: RootStore;

  private readonly chartDataModel: ComparingRigsChartDataModel;

  readonly viewSettings: RigsViewSettingsStore;
  readonly horizontalViewport: Viewport;

  readonly verticalViewport: Viewport;

  readonly dataHeadersPresenter: DataHeadersPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  readonly dataItemsBackgroundPresenter: DataItemsBackgroundPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  readonly dataItemsCompactPresenter: DataItemsCompactPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;
  readonly dataItemsFullPresenter: DataItemsFullPresenter<ComparingRigsChartDataModel.ViewItem[] | null>;

  readonly indicators: ComparingIndicatorsTableStore;

  readonly summaryDataModel: ComparingSummaryDataModel;
  readonly summaryDataPresenter: SummaryDataPresenter;
  readonly summaryDataSidebar: SummaryDataSidebarStore;

  @observable isLoading = false;
  @observable isDataUpdating = false;
  @observable searchTerm = '';
  @observable filtersForm?: FiltersFormStore;
  @observable summaryViewProvider?: SummaryViewProvider;

  constructor(
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    horizontalViewport: Viewport,
    dataView: DataView,
    rootStore: RootStore,
    viewSettings: RigsViewSettingsStore,
    horizontalViewportController: TimelineController,
    grouping: ChartGrouping
  ) {
    this.firstPlanVersionId = firstPlanVersionId;
    this.secondPlanVersionId = secondPlanVersionId;
    this.rootStore = rootStore;

    this.api = new ComparingRigsApi();
    this.dataView = dataView;
    this.horizontalViewport = horizontalViewport;
    this.verticalViewport = new Viewport(0, 0);
    this.grouping = grouping;

    this.viewSettings = viewSettings;

    this.chartDataModel = new ComparingRigsChartDataModel(
      this.verticalViewport,
      this.horizontalViewport,
      this.dataView,
      new ComparingRigsChartDataApi(rootStore.notifications),
      new ComparingRigsChartDataStorage(
        new StorageKeyManagerWithParser(generateKeyFromRange, parseStringToRange),
        new RigsDataPositionsCalculator(RigsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS),
        this.dataView
      ),
      rootStore.comparison
    );

    this.dataHeadersPresenter = new DataHeadersPresenter(this.verticalViewport, this.chartDataModel);
    this.dataItemsBackgroundPresenter = new DataItemsBackgroundPresenter(
      this.verticalViewport,
      this.horizontalViewport,
      this.chartDataModel,
      horizontalViewportController
    );
    this.dataItemsCompactPresenter = new DataItemsCompactPresenter(this.chartDataModel);
    this.dataItemsFullPresenter = new DataItemsFullPresenter(this.chartDataModel);

    this.indicators = new ComparingIndicatorsTableStore(
      this.horizontalViewport,
      this.firstPlanVersionId,
      this.secondPlanVersionId,
      this.rootStore.notifications
    );

    this.summaryDataModel = new ComparingSummaryDataModel(new ComparingSummaryDataApi(), rootStore.comparison);
    this.summaryDataPresenter = new SummaryDataPresenter(this.verticalViewport, this.summaryDataModel);
    this.summaryDataSidebar = new SummaryDataSidebarStore();

    makeObservable(this);
  }

  @computed
  private get filtersFormValues(): { filter: ChartFiltersForm.FilterValues; grouping: string } {
    return {
      filter: this.filtersForm?.formValues?.filter || {},
      grouping: this.grouping.value,
    };
  }

  private async getDataLength(filter: ChartFiltersForm.FilterValues): Promise<number> {
    try {
      const planVersionId = this.firstPlanVersionId;

      const rigs = await this.api.getRigsWithChanges(planVersionId, filter, this.searchTerm);

      if (!rigs) {
        return 0;
      }

      return rigs.length;
    } catch (e) {
      console.error(e);

      throw e;
    }
  }

  @action.bound
  private async search(): Promise<void> {
    this.loadChartData(this.firstPlanVersionId, this.secondPlanVersionId);
  }

  private onSearch = debounce(this.search.bind(this), 400);

  @flow.bound
  private async *loadFilters(planVersionId: number): Promise<void> {
    try {
      const view = await this.rootStore.views.rigsChartFiltersView.loadView();
      yield;

      this.filtersForm = new FiltersFormStore(
        planVersionId,
        view,
        this.rootStore,
        this.onFiltersChange.bind(this),
        this.getDataLength.bind(this)
      );

      await this.filtersForm.loadData();
    } catch (e) {
      yield;

      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.rootStore.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadFilters');
    }
  }

  @action.bound
  onSearchChange(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.onSearch();
  }

  @flow.bound
  async *loadChartData(firstPlanVersionId: number, secondPlanVersionId: number) {
    try {
      this.isDataUpdating = true;

      const { filter } = this.filtersFormValues;

      const rigs = await this.api.getRigsWithChanges(firstPlanVersionId, filter, this.searchTerm);
      yield;

      if (rigs) {
        this.chartDataModel.setRigs(rigs, firstPlanVersionId, secondPlanVersionId);
      }
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadChart');
    } finally {
      this.isDataUpdating = false;
    }
  }

  @computed
  get hasSelectedFilters(): boolean {
    return Object.values(this.filtersFormValues.filter).filter((fieldValue) => hasValue(fieldValue)).length > 0;
  }

  @action.bound
  async onFiltersChange(): Promise<void> {
    await this.loadChartData(this.firstPlanVersionId, this.secondPlanVersionId);
  }

  @action.bound
  init(): VoidFunction {
    this.loadFilters(this.firstPlanVersionId);
    this.loadChartData(this.firstPlanVersionId, this.secondPlanVersionId);

    const disposeVerticalScrollMovement = reaction(
      () => ({ verticalDataRange: this.chartDataModel.verticalDataRange, dataViewType: this.dataView.type }),
      ({ verticalDataRange, dataViewType }, { verticalDataRange: prevVerticalDataRange }) => {
        Viewport.moveViewportToPercentagePosition(
          this.verticalViewport,
          verticalDataRange,
          prevVerticalDataRange,
          this.dataHeadersPresenter.dataViewHeight
        );
      }
    );

    const disposeVerticalScrollLimitsUpdate = reaction(
      () => ({
        verticalViewport: this.verticalViewport,
        verticalDataRange: this.chartDataModel.verticalDataRange,
        dataViewHeight: this.dataHeadersPresenter.dataViewHeight,
      }),
      ({ verticalViewport, verticalDataRange, dataViewHeight }) => {
        Viewport.updateVerticalViewportLimits(verticalViewport, verticalDataRange, dataViewHeight);
      }
    );

    const disposeModelVerticalViewportRange = autorun(() => {
      const { start, end } = this.verticalViewport;
      this.chartDataModel.setVerticalRange(start, end);
    });

    const disposeModelHorizontalViewportRange = autorun(() => {
      const { start, end } = this.horizontalViewport;
      this.chartDataModel.setHorizontalRange(start, end);
    });

    const disposeShownWellAttributesNumberSetter = reaction(
      () => this.viewSettings.view.shownWellAttributesNumber,
      (shownWellAttributesNumber) => {
        this.chartDataModel.setShownWellAttributesNumber(shownWellAttributesNumber);
      },
      { fireImmediately: true }
    );

    const disposeDataModel = this.chartDataModel.init();

    const disposeFiltersAndGroupingSetter = reaction(
      () => ({ filter: this.filtersFormValues.filter }),
      ({ filter }) => {
        this.indicators.onFiltersChange(filter);
      }
    );

    const disposeIndicators = this.indicators.init();

    const disposeSummaryDataModel = this.summaryDataModel.init();

    const disposeSummaryRigsSetter = reaction(
      () => ({ rigsInView: this.chartDataModel.rigsInView, summaryIsOpen: this.summaryDataSidebar.isOpen }),
      ({ rigsInView, summaryIsOpen }) => {
        if (summaryIsOpen && rigsInView) {
          this.summaryDataModel.setParentIdsList(rigsInView);
        }
      },
      {
        fireImmediately: true,
      }
    );

    const disposeSummaryViewProvider = reaction(
      () => this.viewSettings.view?.view.summary,
      (summaryView) => {
        if (summaryView) {
          this.summaryViewProvider = new SummaryViewProvider(summaryView, this.rootStore.directories);
        }
      },
      { fireImmediately: true }
    );

    return () => {
      disposeModelVerticalViewportRange();
      disposeModelHorizontalViewportRange();
      disposeVerticalScrollLimitsUpdate();
      disposeVerticalScrollMovement();
      disposeDataModel();
      disposeShownWellAttributesNumberSetter();
      disposeFiltersAndGroupingSetter();
      disposeIndicators();
      disposeSummaryDataModel();
      disposeSummaryRigsSetter();
      disposeSummaryViewProvider();
    };
  }
}
