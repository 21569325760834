import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { USER_ADMIN_ROLE } from 'src/shared/constants/user';
import { useStore } from 'src/store';
import { TAnalyticsPlansGroup } from 'src/store/comparison/types';

import { AnalyticsVersionItem } from './analytics-version-item';

import styles from './analytics-version-group.module.scss';

type Props = {
  versionsGroup: TAnalyticsPlansGroup;
};

export const AnalyticsVersionGroup = observer(function AnalyticsVersionGroup({ versionsGroup }: Props) {
  const { t } = useTranslation();
  const { planVersion } = useStore();

  const { auth } = useStore();
  const roles = auth.userInfo?.realm_access.roles;
  const isAdmin = !!roles?.some((v) => v === USER_ADMIN_ROLE);

  if (!versionsGroup.months.length) {
    return null;
  }

  return (
    <Collapse key={versionsGroup.year} defaultActiveKey={versionsGroup.year}>
      <Panel key={versionsGroup.year} header={versionsGroup.year} className={styles.text}>
        {versionsGroup.months.map((monthGroup) => (
          <Collapse key={monthGroup.month} defaultActiveKey={monthGroup.month}>
            <Panel key={monthGroup.month} header={t(monthGroup.month)} className={styles.text}>
              <ul className={styles.versionsList}>
                {monthGroup.plans.map((plan) => {
                  const isChosen = planVersion.chosenAnalyticsVersions.has(plan.id);
                  const isDisabled = !isAdmin || (planVersion.chosenAnalyticsVersions.size === 10 && !isChosen);
                  const toggleversion = async () => {
                    if (!isAdmin) {
                      return;
                    }

                    await planVersion.toggleVersionForAnalytics(plan);
                  };

                  return (
                    <AnalyticsVersionItem
                      key={plan.id}
                      version={plan}
                      isChosen={isChosen}
                      isDisabled={isDisabled}
                      toggleVersion={toggleversion}
                    />
                  );
                })}
              </ul>
            </Panel>
          </Collapse>
        ))}
      </Panel>
    </Collapse>
  );
});
