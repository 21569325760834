import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as TriangleIcon } from 'src/shared/assets/icons/insertion-triangle.svg';

import { Range } from '../../../../../layers/model';
import { InteractiveInsertionPoint as InteractiveInsertionPointEntity } from '../../../../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../../../../shared/use-movable-element';
import { useDraggingInteractive } from '../../hooks/use-dragging-interactive';
import { UseDroppable, useDroppable } from '../../hooks/use-droppable';

import styles from './insertion-point.module.scss';

interface Props {
  item: InteractiveInsertionPointEntity;
  horizontalViewport: Range<number>;
  verticalViewport: Range<number>;
}

const options: UseDroppable.Options = {
  overClassname: styles.over,
};

export const InteractiveInsertionPoint = observer(function InteractiveInsertionPoint({
  item,
  horizontalViewport,
  verticalViewport,
}: Props) {
  const ref = useMovableElement({ item, horizontalViewport, verticalViewport });

  useDroppable({
    id: item.getKey('droppable'),
    dataItem: item,
    nodeRef: ref,
    options,
  });

  useDraggingInteractive({
    id: item.getKey('interactive'),
    dataItem: item,
    nodeRef: ref,
  });

  return (
    <div
      ref={ref}
      className={clsx(
        styles.container,
        !item.isAvailable && styles.notAvailable,
        item.expandLeft && styles.expandedLeft,
        item.expandRight && styles.expandedRight
      )}
    >
      <div className={styles.rect}>
        <TriangleIcon className={styles.arrow} />
        <TriangleIcon className={styles.arrowUp} />
      </div>
    </div>
  );
});
