import { TrendGranularity } from 'src/shared/constants/trend-granularity';

import { TimeUnit } from '../time-unit';

export const timeUnitToGranularity = (timeUnit: TimeUnit): TrendGranularity | undefined => {
  if (timeUnit === TimeUnit.year) {
    return TrendGranularity.year;
  }

  if (timeUnit === TimeUnit.month) {
    return TrendGranularity.month;
  }
};
