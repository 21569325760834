import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CompareModeButtons } from 'src/features/planning/views/plan-control-bar/compare-mode-buttons';
import { EditModeButtons } from 'src/features/planning/views/plan-control-bar/edit-mode-buttons';
import { ViewModeButtons } from 'src/features/planning/views/plan-control-bar/view-mode-buttons';
import { PlanPageStore } from 'src/pages/plan-page/plan-page.store';
import { GROUP_TYPE, PAGE_MODE } from 'src/pages/plan-page/types';
import { ReactComponent as SettingsIcon } from 'src/shared/assets/icons/gear.svg';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './plan-control-bar.module.scss';

interface Props {
  pageStore: PlanPageStore;
}

export const PlanControlBar = observer(function PlanControlBar({ pageStore }: Props) {
  const { t } = useTranslation();

  function renderControlButtons(): React.ReactNode {
    switch (pageStore.mode) {
      case PAGE_MODE.view:
        return <ViewModeButtons pageStore={pageStore} />;

      case PAGE_MODE.edit:
        return (
          <EditModeButtons
            onCancelEditing={pageStore.cancelEditing}
            onPublishChanges={pageStore.cancelEditing}
            onSaveChanges={pageStore.saveChanges}
            isDisabled={pageStore.isActionLoading}
            isLoading={pageStore.isActionLoading}
          />
        );

      case PAGE_MODE.compare:
        return <CompareModeButtons pageStore={pageStore} />;

      default: {
        return <ViewModeButtons pageStore={pageStore} />;
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <SwitchButtonGroup
        onChange={pageStore.setGroupType}
        defaultOptionKey={GROUP_TYPE.field}
        items={[
          {
            key: GROUP_TYPE.field,
            title: t('plan:deposit'),
          },
          {
            key: GROUP_TYPE.rig,
            title: t('plan:drillingRig'),
          },
        ]}
      />

      <div className={styles.rightControlBlock}>
        {renderControlButtons()}
        <Tooltip text={t('common:displaySettings')} placement="bottomRight">
          <button className={styles.settingsButton} onClick={pageStore.openViewSettingSidebar}>
            <SettingsIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
});
