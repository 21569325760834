import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';

import { SidebarPadRigOperation } from '../../entities';

import styles from './pad-collapse-header.module.scss';

interface Props {
  pad: SidebarPadRigOperation;
}

export const PadCollapseHeader = observer(function PadCollapseHeader({ pad }: Props) {
  const attributeIcons = getPadAttributeIcons(pad.data);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{pad.title}</p>
      <div className={styles.attributes}>
        {attributeIcons?.map((attributeName) => (
          <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.attribute} />
        ))}
      </div>
    </div>
  );
});
