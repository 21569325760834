import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';

import { WellTypeIcon } from 'src/features/well-type-icon';
import { useStore } from 'src/store';

import { Well } from '../../entities/tab-with-wells-entities/well.entity';
import { WellColoredCard } from '../well-card/well-colored-card';

import styles from './pad-wells.module.scss';

interface Props {
  wells: Well[];
  wellCardClassName?: string;
  onToggleSlider?: (well: Well, isSliderUsing: boolean) => Promise<void>;
  onWellClick?: (wellId: number) => void;
  onWellEditingClick?(wellId: number): void;
  onWellAdd?: VoidFunction;
}

export const PadWells = observer(function PadWells({ wells, wellCardClassName, onToggleSlider, onWellClick }: Props) {
  const isEditing = useStore().editing.isEditing;

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {wells.map((well) => {
          return (
            <WellColoredCard
              key={well.planWellTripleId}
              text={well.attributes}
              title={well.title}
              color={well.wellColor}
              className={wellCardClassName}
              headerNextExtraComponent={
                isEditing && well.isMobile && onToggleSlider ? (
                  <Switch
                    checked={well.isSliderUsed}
                    onChange={(value) => onToggleSlider(well, value)}
                    loading={well.isLoading}
                  />
                ) : null
              }
              icons={<WellTypeIcon iconName={well.wellIcon} className={styles.wellIcon} />}
              onClick={() => onWellClick?.(well.geoTaskId)}
            />
          );
        })}
      </div>
    </div>
  );
});
