import { Range } from '../layers/model';

export namespace ViewHelper {
  /**
   * @param position Start and end of checking element.
   * @param view Start an end of view.
   */
  export const isInView = (position: Range<number>, view: Range<number>) => {
    const { start, end } = view;

    if (position.start === position.end) {
      return false;
    }

    if (position.start >= start && position.start <= end) {
      return true;
    }

    if (position.end >= start && position.end <= end) {
      return true;
    }

    if (position.start <= start && position.end >= end) {
      return true;
    }

    return false;
  };
}
