import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as UploadIcon } from 'src/shared/assets/icons/cloud.svg';
import { ReactComponent as CrossIcon, ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as FullIcon } from 'src/shared/assets/icons/full-icon.svg';
import { ReactComponent as SettingsIcon } from 'src/shared/assets/icons/gear.svg';
import { Button } from 'src/shared/components/button';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { useStore } from 'src/store';

import { PlanType } from '../drilling-chart/shared/plan-type';

import styles from './comparing-drilling-plan-header.module.scss';

interface Props {
  displayChartType: PlanType;
  onDisplayChartTypeChange(type: PlanType): void;
  onSettingsOpen: VoidFunction;
  onFullScreen: VoidFunction;
  onComparingModeExit: VoidFunction;
}

export const ComparingDrillingPlanHeader = observer(function ComparingDrillingPlanHeader({
  displayChartType,
  onDisplayChartTypeChange,
  onSettingsOpen,
  onFullScreen,
  onComparingModeExit,
}: Props) {
  const { t } = useTranslation('drillingCarpet');

  const {
    comparison: { isImportOccurred, isLoading, cancelImport, publishImportVersion },
  } = useStore();

  return (
    <div className={styles.container}>
      <SwitchButtonGroup
        items={[
          { key: PlanType.wells, title: t('Header.wellPad') },
          { key: PlanType.rigs, title: t('Header.drillingRig') },
        ]}
        defaultOptionKey={displayChartType}
        className={styles.switchContainer}
        buttonClassName={styles.switchButton}
        onChange={onDisplayChartTypeChange}
      />

      <div className={styles.controlsContainer}>
        {isImportOccurred ? (
          <>
            <Button
              disabled={isLoading}
              className={styles.importButton}
              variant="primary"
              icon={<UploadIcon />}
              onClick={publishImportVersion}
            >
              {t('common:Buttons.save')}
            </Button>
            <Button
              disabled={isLoading}
              className={styles.importButton}
              variant="danger"
              icon={<CloseIcon />}
              onClick={cancelImport}
            >
              {t('common:Buttons.cancel')}
            </Button>
          </>
        ) : (
          <Button
            variant="danger"
            icon={<CrossIcon />}
            className={styles.controlsButtonComparingExit}
            onClick={onComparingModeExit}
          >
            {t('common:Buttons.finishComparison')}
          </Button>
        )}

        <Button
          icon={<SettingsIcon className={styles.controlsButtonIcon} />}
          className={styles.controlsButton}
          onClick={onSettingsOpen}
        />
        <Button
          icon={<FullIcon className={styles.controlsButtonIcon} />}
          className={styles.controlsButton}
          onClick={onFullScreen}
        />
      </div>
    </div>
  );
});
