import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WellInfoStageCard } from 'src/features/well-info-stage-card';

import { RigOperation } from '../../entities/tab-with-stages-entities/rig-operation.entity';

import styles from './well-stages.module.scss';

interface Props {
  rigOperations: RigOperation[];
}

export const WellStages = observer(function WellStages({ rigOperations }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {rigOperations.map((rigOperation) => {
        return (
          <div key={rigOperation.id}>
            <p className={styles.title}>{t('newWellForm:Approaches.index', { index: rigOperation.index + 1 })}</p>

            {rigOperation.stages?.map((stage) => (
              <WellInfoStageCard key={stage.id} stage={stage} className={styles.stageCard} />
            ))}
          </div>
        );
      })}
    </div>
  );
});
