import { observer } from 'mobx-react-lite';
import React from 'react';

import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { SwitchButtonGroupItem } from 'src/shared/components/switch-button-group/switch-button-group';

import styles from './control-bar.module.scss';

interface Props {
  tab?: string;
  tabs: SwitchButtonGroupItem<string>[];
  onTabChange(tab?: string): void;
  isLoading: boolean;
}

export const ControlBar = observer(function ControlBar({ tab, tabs, onTabChange, isLoading }: Props) {
  return (
    <div className={styles.controlBar}>
      <SwitchButtonGroup onChange={onTabChange} value={tab} defaultOptionKey={tab} items={tabs} disabled={isLoading} />
    </div>
  );
});
