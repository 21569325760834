import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { DrillingElementCard } from 'src/features/drilling-element-card';
import { ReactComponent as DrillingRigIcon } from 'src/shared/assets/icons/drilling-rigs-icons/drilling-rig-icon.svg';
import { IndexField } from 'src/shared/utils/index-field';
import { LoadingField } from 'src/shared/utils/loading-field';

import { Skeleton } from '../../../../../shared/components/skeleton';
import { Range } from '../../../layers/model';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';

import styles from './data-headers.module.scss';

interface Props<TID> {
  item: { id: TID; y?: Range<number>; index?: number };
  viewport: Viewport;
  isCompactView: boolean;
  title?: ReactNode;
  descriptionAttributes?: (string | IndexField)[] | LoadingField | null;
  isLast?: boolean;
  onClick?(id: TID): void;
  className?: string;
}

export const RowHeaderWrapper = observer(function RowHeaderWrapper<TID>({
  item,
  isLast,
  viewport,
  isCompactView,
  onClick,
  title,
  descriptionAttributes,
  className,
}: Props<TID>) {
  const ref = useMovableElement({
    item,
    verticalViewport: viewport,
  });

  const handleClick = () => onClick?.(item.id);

  const getDescription = (): ReactNode => {
    if (!descriptionAttributes) {
      return null;
    }

    if (descriptionAttributes instanceof LoadingField) {
      return (
        <div className={styles.descriptionContainer}>
          <Skeleton rowsNumber={2} />
        </div>
      );
    }

    const nodes: string[] = descriptionAttributes.map((attribute) => {
      if (attribute instanceof IndexField) {
        return item.index !== undefined ? (item.index + 1).toString() : '-';
      }

      return attribute;
    });

    const descriptionString = nodes.join(', ');

    return <p title={descriptionString}>{descriptionString}</p>;
  };

  return (
    <div
      ref={ref}
      className={clsx(
        className,
        styles.rowHeader,
        isLast && styles.rowHeaderLast,
        !!onClick && styles.rowHeaderInteractive
      )}
      onClick={handleClick}
    >
      <DrillingElementCard
        title={title}
        description={isCompactView ? undefined : getDescription()}
        icon={<DrillingRigIcon className={styles.rigIcon} />}
      />
    </div>
  );
});
