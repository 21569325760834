import { action, comparer, makeObservable, observable, ObservableMap, when } from 'mobx';
import { computedFn } from 'mobx-utils';

import { Range } from '../../../layers/model';
import { IndicatorColumn, LoadingIndicatorsColumn } from '../../../presets/indicators-view-settings-sidebar/entities';
import { StorageKeyManager } from '../../../shared/storage-key-manager';
import { TimeUnit } from '../../../shared/time-unit';

const MAX_STORAGE_SIZE = 20;

export class IndicatorsStorage {
  private keyManager: StorageKeyManager<IndicatorsStorage.Key, string>;

  @observable private data: ObservableMap<string, IndicatorsStorage.Entry>;

  constructor(keyManager: StorageKeyManager<IndicatorsStorage.Key, string>) {
    this.keyManager = keyManager;
    this.data = observable.map();

    makeObservable(this);
  }

  init = (): VoidFunction => {
    const disposeStorageClearing = when(
      () => this.data.size >= MAX_STORAGE_SIZE,
      () => {
        this.clear();
      }
    );

    return () => {
      disposeStorageClearing();
    };
  };

  @action.bound
  set(data: IndicatorsStorage.Entry, dataRange: Range<number>, timeUnit: TimeUnit): void {
    const rangeKey = this.keyManager.getKey({ start: dataRange.start, end: dataRange.end, timeUnit });

    this.data.set(rangeKey, data);
  }

  get = computedFn(
    (rangeStart: number, rangeEnd: number, timeUnit: TimeUnit): IndicatorsStorage.Entry | undefined => {
      const rangeKey = this.keyManager.getKey({ start: rangeStart, end: rangeEnd, timeUnit });

      return this.data.get(rangeKey);
    },
    { equals: comparer.structural }
  );

  @action.bound
  clear(): void {
    this.data.clear();
  }
}

export namespace IndicatorsStorage {
  export type Key = Range<number> & {
    timeUnit: TimeUnit;
  };

  export type Entry = {
    columns: IndicatorColumn[] | LoadingIndicatorsColumn[];
    total: IndicatorColumn | LoadingIndicatorsColumn;
  };
}
