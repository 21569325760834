import { Range } from '../../layers/model';
import { TimeUnit } from '../time-unit';

import { Viewport } from './viewport';

export const MAX_SCROLL_OFFSET = 80;

export const YEAR_DURATION_IN_UNIX = 12 * 31 * 24 * 60 * 60;

export type OffsetParams = { width: number; offset: number };

/**
 * Calculate percentage width and left offset.
 */
export const calculateItemOffsetParams = <T extends Range<number>>(position: Range<number>, item: T): OffsetParams => {
  const { start, end } = position;

  const rangeLength = end - start;

  if (!rangeLength) {
    return {
      width: 0,
      offset: 0,
    };
  }

  const dataItemWidth = item.end - item.start;
  const leftOffsetLength = item.start - start;

  return {
    width: (dataItemWidth / rangeLength) * 100,
    offset: (leftOffsetLength / rangeLength) * 100,
  };
};

/**
 * Convert pixels to milliseconds.
 * @param pixels
 * @param position Start and end of viewport in milliseconds.
 * @param width Width in pixels.
 */
export const pixelsToMilliseconds = (pixels: number, position: Range<number>, width: number) => {
  const { start, end } = position;

  const periodLength = Math.abs(end - start);

  if (periodLength === 0) {
    return 0;
  }

  return (pixels * periodLength) / width;
};

export const millisecondsToPixels = (ms: number, position: Range<number>, width: number) => {
  const { start, end } = position;

  const periodLength = Math.abs(end - start);

  return (ms * width) / periodLength;
};

export const zoomViewport = (viewport: Viewport, offset: number, focusPosition?: number | null) => {
  const viewportWidth = viewport.end - viewport.start;
  const pointerPosition = focusPosition || viewportWidth / 2 + viewport.start;
  const focusPositionPercentage = (pointerPosition - viewport.start) / viewportWidth;

  const zoomedStart = viewport.start - (offset / 2) * focusPositionPercentage;
  const zoomedEnd = viewport.end + (offset / 2) * (1 - focusPositionPercentage);

  return { zoomedStart, zoomedEnd };
};

export const moveViewport = (viewport: Viewport, offset: number) => {
  const movedStart = viewport.start + offset;
  const movedEnd = viewport.end + offset;

  return { movedStart, movedEnd };
};

export const getPositiveOffsetPx = (viewport: Range<number>, position: Range<number>) => {
  const positiveYStart = Math.max(0, position.start - viewport.start);

  return `${positiveYStart}px`;
};

export const getOffsetPx = (viewport: Range<number>, position: Range<number>) => `${position.start - viewport.start}px`;

export const getSizePx = (position: Range<number>) => `${position.end - position.start}px`;

export const isDiffMoreThanYear = (viewRange: Range<number>) => {
  const viewRangeLengthInUnix = viewRange.end - viewRange.start;

  return viewRangeLengthInUnix > YEAR_DURATION_IN_UNIX;
};

export const getTimeUnit = (viewport: Range<number>): TimeUnit => {
  return isDiffMoreThanYear(viewport) ? TimeUnit.year : TimeUnit.month;
};

export const normalizeScrollOffset = (offset: number): number => {
  return offset >= 0 ? Math.min(offset, MAX_SCROLL_OFFSET) : Math.max(offset, -MAX_SCROLL_OFFSET);
};
