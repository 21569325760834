import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';

import { IComparingSummaryDataApi } from '../../features/drilling-chart/features/summary-data/data/summary-data.types';
import { IRigSummary } from '../../features/drilling-chart/presets/summary-data-sidebar/entities/rig-summary';

import { ComparingSummaryDataAdapter } from './comparing-summary-data-adapter';
import { Summary } from './summary-data-api';

export class ComparingSummaryDataApi implements IComparingSummaryDataApi {
  private readonly adapter = new ComparingSummaryDataAdapter();

  async getSummary(
    parentIdsList: number[],
    year: number,
    planVersions: [number, number]
  ): Promise<Map<number, IRigSummary | null>> {
    try {
      const [firstVersion, secondVersion] = planVersions;

      const { data } = await agent.post<ComparingSummary.RawData>('/dashboard/table/compare', {
        firstVersion,
        secondVersion,
        year,
        rigs: parentIdsList,
      });

      return this.adapter.initializeSummary(data, parentIdsList);
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace ComparingSummary {
  export enum ResultCode {
    noChanges = 'NO_CHANGES',
    deleted = 'DELETED',
    added = 'ADDED',
    changed = 'CHANGED',
  }

  export type Values = {
    oldValue: number;
    newValue: number;
    result: ResultCode;
  };

  export type RawData = Record<Summary.Indicator, Summary.Values<Summary.Values<Values>, Values>>;

  export type ResultEntry = {
    code: ResultCode;
    change: number;
  };

  export type Result = Partial<Record<Summary.Indicator, Partial<Record<Summary.RowValuesField, ResultEntry>>>>;
}
