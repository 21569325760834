import { DashboardTrendResponse } from 'src/api/dashboard/types';

export const getLabels = (
  rawLabels: DashboardTrendResponse,
  cache: Partial<Record<string, string>>
): string[] | null => {
  const rawDataEntries = Object.values(rawLabels);
  const labels: string[] = [];

  for (const rawItem of rawDataEntries) {
    const parsedPoints = Object.keys(rawItem.points);

    for (const label of parsedPoints) {
      labels.push(label);
    }
  }

  const distinctLabels = Array.from(new Set(labels));
  const cachedKeys = Object.keys(cache);
  const newLabels = distinctLabels.filter((label) => !cachedKeys.includes(label));

  if (newLabels.length === 0) return null;

  return newLabels;
};
