import { Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-thin.svg';
import { Button } from 'src/shared/components/button';

import { ComboboxDropdownButton } from '../../form-controls/combobox-dropdown/combobox-dropdown-button';
import { TOption } from '../../types';

import styles from './stage-dropdown.module.scss';

type Props = {
  options: TOption[];
  isDisabled: boolean;
  addNewStage: (stageType: string | number) => void;
};

export const StageDropdown = observer(function StageDropdown({ options, isDisabled, addNewStage }: Props) {
  const { t } = useTranslation();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const handleOpenDropdown = (): void => setIsDropdownOpened(true);
  const handleVisibleChange = (is: boolean): void => setIsDropdownOpened(is);

  return (
    <Dropdown
      visible={isDropdownOpened}
      className={styles.dropdown}
      trigger={['click']}
      disabled={isDisabled}
      onVisibleChange={handleVisibleChange}
      overlay={
        <ul className={styles.wrapper}>
          {options.map((option) => {
            const handleAddNewStage = (): void => {
              addNewStage(option.value);
              setIsDropdownOpened(false);
            };
            return (
              <li key={option.value} className={styles.itemWrapper} onClick={handleAddNewStage}>
                <p className={styles.itemText}>{option.label}</p>
              </li>
            );
          })}
          <ComboboxDropdownButton directoryName={t('newWellForm:AddNewValueToDirectory.stage')} />
        </ul>
      }
    >
      <Button
        width={204}
        variant="primary"
        icon={<ArrowIcon transform={isDropdownOpened ? 'rotate(180)' : undefined} />}
        disabled={isDisabled}
        onClick={handleOpenDropdown}
      >
        <p>{t('newWellForm:Stages.addNewStage')}</p>
      </Button>
    </Dropdown>
  );
});
