import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { WellForm } from 'src/features/well-form';
import { WellFormSubheader } from 'src/features/well-form/well-form-subheader';
import { useStore } from 'src/store';

import { WellFormStore } from './well-form.store';

export const NewWellPage = observer(function NewWellPage() {
  const rootStore = useStore();
  const [store] = useState(() => new WellFormStore(rootStore));
  const { effect } = store;

  useEffect(effect, [effect]);

  return (
    <WellForm
      wellFormManager={store.formManager}
      isFormLoading={store.formManager.isFormLoading || store.isFormInitializing}
      isFormFetching={store.formManager.isSaveUpdateLoading}
      onSplitApproach={store.formManager.splitApproach}
      onAddNewApproach={store.formManager.addNewApproach}
      subheader={
        <WellFormSubheader
          title={store.formManager.currentWellName}
          formStore={store.formManager.currentFormStore}
          onSave={store.formManager.saveWell}
          onCancel={store.formManager.cancelForm}
        />
      }
    />
  );
});
