import { observable, makeObservable } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { PadsItem } from '../../graph-info-sidebar';
import { InfoSidebarTab } from '../info-sidebar-tab.entity';

import { Pad } from './pad.entity';

type TTabWithPadsOptions = {
  fieldId: string;
  directories: Directories;
  notifications: NotificationsStore;
  appSettings: AppSettingsStore;
  pads: PadsItem;
};
export class TabWithPads extends InfoSidebarTab {
  @observable pads: Pad[] = [];

  private readonly padsView: PadsItem;
  private readonly appSettings: AppSettingsStore;

  constructor({ fieldId, directories, pads, appSettings, notifications }: TTabWithPadsOptions) {
    super(fieldId, directories, notifications);

    this.padsView = pads;
    this.appSettings = appSettings;

    makeObservable(this);
  }

  private isPads(rawPads: unknown): rawPads is Record<string, unknown>[] {
    if (!Array.isArray(rawPads) || rawPads.some((rawPad) => typeof rawPad !== 'object' || rawPad === null)) {
      return false;
    }

    return true;
  }

  setValues(data: Record<string, unknown>): void {
    if (!('pads' in data) || !this.isPads(data.pads)) {
      return;
    }

    data.pads.forEach((rawPad) => {
      const id = rawPad['id'];

      assert(
        typeof id === 'number' || (typeof id === 'string' && !Number.isNaN(Number(id))),
        'id is not presented in pad'
      );

      const pad = new Pad(rawPad, this.padsView, this.directories, this.appSettings, this.notifications, Number(id));

      this.pads.push(pad);
    });
  }
}
