import moment, { Moment } from 'moment';

import { Range } from '../../../layers/model';
import { generateKeyFromRange, StorageKeyManager } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';
import { getTimeUnit } from '../../../shared/viewport/viewport-calculator';

import { IHeaderColumn } from './header-data-model.types';
import { MonthHeaderColumn, YearHeaderColumn } from './model/header-column';

namespace HeaderDataSource {
  export const getDateColumnsInPeriod = (
    range: Range<Moment>,
    timeUnit: TimeUnit,
    previousColumns?: IHeaderColumn[]
  ): IHeaderColumn[] => {
    const keyManager = new StorageKeyManager(generateKeyFromRange);
    const previousColumnsMap = new Map(
      previousColumns?.map((column): [string, IHeaderColumn] => {
        const rangeKey = keyManager.getKey({ start: column.start, end: column.end });
        return [rangeKey, column];
      })
    );

    switch (timeUnit) {
      case TimeUnit.month:
        const monthColumns: MonthHeaderColumn[] = [];
        const lastMonthUnix = range.end.startOf('month').unix();

        let monthCount = 0;
        let currentMonth = range.start;

        do {
          currentMonth = moment(range.start).add({ month: monthCount });

          const start = currentMonth.startOf('month').unix();
          const end = currentMonth.endOf('month').unix();

          const rangeKey = keyManager.getKey({ start, end });
          const oldColumn = previousColumnsMap.get(rangeKey);

          monthColumns.push(oldColumn ?? new MonthHeaderColumn(currentMonth.month(), start, end));

          monthCount++;
        } while (moment(currentMonth).startOf('month').unix() < lastMonthUnix);

        return monthColumns;
      case TimeUnit.year:
        const startYear = range.start.get('year');
        const endYear = range.end.get('year');

        const yearColumns: YearHeaderColumn[] = [];

        for (let year = startYear; year <= endYear; year++) {
          const currentYear = moment().set('year', year);

          const start = currentYear.startOf('year').unix();
          const end = currentYear.endOf('year').unix();

          const rangeKey = keyManager.getKey({ start, end });
          const oldColumn = previousColumnsMap.get(rangeKey);

          yearColumns.push(oldColumn ?? new YearHeaderColumn(year, start, end));
        }

        return yearColumns;
      default:
        return [];
    }
  };

  export const generateColumns = (viewRange: Range<number>, previousColumns?: IHeaderColumn[]): IHeaderColumn[] => {
    const viewStartMoment = moment.unix(viewRange.start);
    const viewEndMoment = moment.unix(viewRange.end);

    const timeUnit = getTimeUnit(viewRange);

    const expandedRange = TimeRangeHelper.expandRange({ start: viewStartMoment, end: viewEndMoment }, timeUnit);

    return getDateColumnsInPeriod(expandedRange, timeUnit, previousColumns);
  };
}

export default HeaderDataSource;
