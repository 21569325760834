import { observer } from 'mobx-react-lite';
import React from 'react';

import { WellTypeIcon } from 'src/features/well-type-icon';
import { ColoredCard } from 'src/shared/components/colored-card';

import { AddToChartSidebarWellEntity } from './well.entity';

import styles from './well.module.scss';

interface Props {
  well: AddToChartSidebarWellEntity;
  className?: string;
}

export const Well = observer(function Well({ well, className }: Props) {
  return (
    <div className={styles.container}>
      <ColoredCard
        cardColor={well.color}
        title={well.title}
        text={well.attributes.join(' - ')}
        showExpandButton={false}
        className={className}
        icons={<WellTypeIcon iconName={well.wellTypeIcon} />}
      />
    </div>
  );
});
