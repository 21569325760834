import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { ReactComponent as AddIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button/button';
import { PadAttributeIcon as PadAttributeIconType } from 'src/shared/constants/pad-attribute-icon';

import styles from './table-collapse-header.module.scss';

interface Props {
  title: string;
  subTitles: string[];
  editing: boolean;
  buttonText: string;
  addItem: () => void;
  attributeNames: PadAttributeIconType[] | null;
}

export const TableCollapseHeader = observer(function TableCollapseHeader({
  title,
  subTitles,
  editing,
  buttonText,
  addItem,
  attributeNames,
}: Props) {
  function handleClick(event: React.MouseEvent): void {
    event.stopPropagation();
    addItem();
  }

  return (
    <div className={styles.container}>
      <div className={styles.textAttributes}>
        <p className={styles.title}>{title}</p>
        {subTitles.map((item, index) => (
          <p key={index} className={styles.subtitle}>
            {item}
          </p>
        ))}

        <div className={styles.iconAttributes}>
          {attributeNames?.map((attributeName) => (
            <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
          ))}
        </div>
      </div>

      {editing && (
        <Button onClick={handleClick} variant="secondary" icon={<AddIcon />}>
          {buttonText}
        </Button>
      )}
    </div>
  );
});
