import { observer } from 'mobx-react-lite';

import { LoadingRigOperations, PadRigOperation, WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import { RigsDataItem } from './rigs-data-item';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  onPadClick(padId: number, rigId: number): void;
  onWellClick(wellId: number): void;
  viewSettingsProvider?: RigsViewSettingsProvider;
}

export const RigsDataItemsFull = observer(function RigsDataItemsFull({
  data,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  onWellClick,
  viewSettingsProvider,
}: Props) {
  if (!viewSettingsProvider) {
    return null;
  }

  return (
    <>
      {data?.map((item) => {
        if (
          item instanceof PadRigOperation ||
          item instanceof WellRigOperation ||
          item instanceof LoadingRigOperations ||
          item instanceof SortableGhostItem
        ) {
          return (
            <RigsDataItem
              key={item.getKey('full')}
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              onPadClick={onPadClick}
              onWellClick={onWellClick}
              viewSettingsProvider={viewSettingsProvider}
            />
          );
        }

        return null;
      })}
    </>
  );
});
