import { RefObject, useContext, useEffect, useState } from 'react';

import { Timer } from '../../../../shared/timer';
import { Dnd } from '../../types';
import { DndContext } from '../context/dnd-context';

export function useDraggingInteractive({ id, nodeRef, dataItem, timerOptions }: UseInteractive.Args) {
  const { draggingInteractiveOver, registerInteractive, unregisterInteractive, onInteractiveOver } =
    useContext(DndContext);
  const [timer] = useState(() => new Timer());

  const isDraggingInteractive = draggingInteractiveOver?.id === id;

  useEffect(() => {
    if (nodeRef.current) {
      registerInteractive(id, nodeRef.current, dataItem);

      return () => {
        unregisterInteractive(id);
      };
    }
  });

  useEffect(() => {
    if (!isDraggingInteractive) {
      timer.cancelTimer();
      return;
    }

    if (timerOptions) {
      timer.startTimer(onInteractiveOver, timerOptions.delay);

      return () => {
        timer.cancelTimer();
      };
    } else {
      onInteractiveOver();
    }
  }, [isDraggingInteractive, onInteractiveOver, timer, timerOptions]);
}

export namespace UseInteractive {
  export type TimerOptions = {
    delay: number;
  };

  export type Args = {
    id: string;
    dataItem: Dnd.Interactive;
    nodeRef: RefObject<HTMLElement>;
    timerOptions?: TimerOptions;
  };
}
