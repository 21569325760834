import { action, makeObservable, observable } from 'mobx';

import { Item } from '../abstract-control-entities';

type TCheckboxData = {
  formElementRefId: string;
  fieldId: string;
  initialValue?: boolean;
};

export class Switcher extends Item<boolean> {
  @observable value: boolean;
  @observable initialValue = false;

  constructor(data: TCheckboxData) {
    super(data);
    this.value = !!data.initialValue;

    makeObservable(this);
  }

  @action.bound
  clearItem(): void {
    this.value = false;
  }

  @action.bound
  setValue(value: boolean, setValueAsInitial?: boolean): void {
    if (value === this.value) {
      return;
    }
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: boolean): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    switch (value) {
      case '0':
      case '':
      case 0:
      case false:
      case 'false':
      case 'null':
      case null:
      case undefined:
        this.setValue(false, setValueAsInitial);
        return true;
      case '1':
      case 'true':
      case 1:
      case true:
        this.setValue(true, setValueAsInitial);
        return true;
      default:
        return false;
    }
  }
}
