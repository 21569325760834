import { action, flow, makeObservable, observable } from 'mobx';

import DEFAULT_INDICATORS_SETTINGS_VALUES from 'src/api/chart/default-indicators-settings.json';
import { IndicatorsView } from 'src/api/chart/drilling-plan-charts-api';
import { UserSettingsManager } from 'src/api/user-settings';
import { Directories } from 'src/store/directories/directories.store';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { ViewsStore } from 'src/store/views';

import { IndicatorSettings, IndicatorsSettings } from '../../presets/indicators-view-settings-sidebar/entities';
import { Viewport } from '../../shared/viewport/viewport';

export class IndicatorsTableSettingsStore {
  private readonly views: ViewsStore;
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;

  private readonly viewSettingsManager: UserSettingsManager<IndicatorsView.SettingsValues>;

  @observable isLoading = false;
  @observable settings?: IndicatorsSettings;

  constructor(
    horizontalViewport: Viewport,
    editing: EditingStore,
    views: ViewsStore,
    directories: Directories,
    notifications: NotificationsStore
  ) {
    this.views = views;
    this.directories = directories;
    this.notifications = notifications;

    this.viewSettingsManager = new UserSettingsManager<IndicatorsView.SettingsValues>(
      'graphIndicators',
      DEFAULT_INDICATORS_SETTINGS_VALUES
    );

    makeObservable(this);
  }

  @flow.bound
  private async *loadIndicatorsView() {
    try {
      this.isLoading = true;

      const [indicatorsSettingsView, indicatorsSettingsValues] = await Promise.all([
        this.views.indicatorsSettingsView.loadView(),
        this.viewSettingsManager.getOrCreate(),
      ]);
      yield;

      if (!indicatorsSettingsValues?.table?.items) {
        await this.viewSettingsManager.create();

        const newSettingsValues = await this.viewSettingsManager.get();
        yield;

        this.settings = new IndicatorsSettings(indicatorsSettingsView, newSettingsValues, this.directories);
      } else {
        this.settings = new IndicatorsSettings(indicatorsSettingsView, indicatorsSettingsValues, this.directories);
      }
    } catch (e) {
      yield;

      this.notifications.showErrorMessageT('errors:failedToLoadIndicatorsTableSettings');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *updateIndicatorsValues(indicatorsValues: IndicatorsView.SettingsValues, onSuccess?: VoidFunction) {
    try {
      this.isLoading = true;

      const indicatorsSettings = this.settings;

      if (indicatorsSettings) {
        await this.viewSettingsManager.update(indicatorsValues);
        yield;

        indicatorsSettings.setValues(indicatorsValues);
        onSuccess?.();
      }
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToUpdateIndicatorsTableSettings');
    } finally {
      this.isLoading = false;
    }
  }

  updateIndicators(indicatorsSettings: IndicatorSettings[], onSuccess?: VoidFunction): void {
    const updatedSettingsValues: IndicatorsView.SettingsValues = {
      table: {
        items: indicatorsSettings.map(({ isShown, name }) => ({ fieldId: name, isShown })),
      },
    };

    this.updateIndicatorsValues(updatedSettingsValues, onSuccess);
  }

  @action.bound
  init(): void {
    this.loadIndicatorsView();
  }
}
