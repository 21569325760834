import { action, makeObservable, observable } from 'mobx';

import { tryParseNumber } from '../../../../../shared/utils/try-parse-number';
import { Range } from '../../../layers/model';

import { ChartWell } from './chart-well';
import { WellRigOperation } from './well-rig-operation';

export class WellRigOperationStage {
  protected readonly item: Record<string, unknown>;

  readonly wellLifeCycleId: number;
  readonly uniqueCreationKey: string;
  readonly parentRigOperation: WellRigOperation;
  readonly parentWellId?: number;
  readonly parentWell?: ChartWell;

  @observable y?: Range<number>;

  constructor(
    rigOperationStage: Record<string, unknown>,
    wellLifeCycleId: number,
    uniqueCreationKey: string,
    parentRigOperation: WellRigOperation,
    parentWellId?: number,
    parentWell?: ChartWell
  ) {
    this.item = rigOperationStage;
    this.wellLifeCycleId = wellLifeCycleId;
    this.uniqueCreationKey = uniqueCreationKey;
    this.parentWellId = parentWellId;
    this.parentWell = parentWell;
    this.parentRigOperation = parentRigOperation;

    makeObservable(this);
  }

  get start(): number {
    try {
      const startDateFieldValue = this.getFieldValue('startDate');

      return tryParseNumber(startDateFieldValue) ?? 0;
    } catch (e) {
      console.error(e);

      return 0;
    }
  }

  get end(): number {
    try {
      const endDateFieldValue = this.getFieldValue('endDate');

      return tryParseNumber(endDateFieldValue) ?? 0;
    } catch (e) {
      console.error(e);

      return 0;
    }
  }

  get x(): Range<number> {
    return {
      start: this.start,
      end: this.end,
    };
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-rig-operation-stage-${this.uniqueCreationKey}`;
    }

    return `rig-operation-stage-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): unknown | undefined {
    return this.item[fieldName];
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }
}
