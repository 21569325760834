import moment from 'moment';

import {
  RigsChartConflictSidebarViewTypes,
  RigsChartRemoveConfirmationSidebarViewTypes,
} from 'src/api/chart/rigs-chart-api';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { hasValue } from 'src/shared/utils/common';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';

import { RawWell } from '../../types';

import { RigOperation } from './entities/rig-operation';

export function mapRigOperations(
  appSettings: AppSettingsStore,
  directories: Directories,
  view: RigsChartRemoveConfirmationSidebarViewTypes.RigsChartRemoveConfirmationSidebarView,
  affectedRigOperations: RawWell[]
): RigOperation[] {
  return affectedRigOperations.map((rigOperation) => {
    const wellColor = getWellColor(rigOperation, appSettings.wellColorRules);
    const wellTitle = getWellTitle(directories, view.well, rigOperation);
    const wellAttributes = getWellAttributes(view.well, rigOperation);
    const rigOperationIndex = getRigOperationIndex(view.well, rigOperation);
    const uniqueCreationKey = getUniqueWellKey(rigOperation);
    const wellTypeIcon = appSettings.getWellIcon(rigOperation, addGOplanPrefix('GeologicalTask.wellTypeId'));
    const parentRigTitle = getRigName(rigOperation, directories, view);

    return new RigOperation(
      uniqueCreationKey,
      rigOperation,
      wellTitle,
      parentRigTitle,
      rigOperationIndex,
      wellAttributes,
      wellColor,
      wellTypeIcon
    );
  });
}

export function getWellTitle(
  directories: Directories,
  view: RigsChartConflictSidebarViewTypes.ConflictWell,
  wellData: RawWell
): string {
  const { attrName, refObjectAttr, refObjectType } = view;

  const attrValue = wellData[attrName];

  if (refObjectAttr && refObjectType) {
    const refObject = directories.getObject(refObjectType);
    const labelObject = refObject?.find(({ id }) => id.toString() === attrValue);
    const label = labelObject?.data[refObjectAttr];

    return label ? label.toString() : '';
  }

  return attrValue?.toString() || '';
}

export function getPadTitle(
  rigOperation: RawWell,
  directoriesStore: Directories,
  view: RigsChartRemoveConfirmationSidebarViewTypes.RigsChartRemoveConfirmationSidebarView
): string {
  const titleHeaders = view.pad.headers.filter((item) => item.control === 'Title');

  return titleHeaders
    .map((header) => {
      const directories = directoriesStore.getObject(header.refObjectType);
      const directory = directories?.find((item) => String(item.id) === String(rigOperation[header.attrName]));

      return directory?.data[header.refObjectAttr]?.toString() ?? '';
    })
    .join(' ');
}

export function getRigName(
  rigOperation: RawWell,
  directoriesStore: Directories,
  view: RigsChartRemoveConfirmationSidebarViewTypes.RigsChartRemoveConfirmationSidebarView
): string {
  const values = view.rigName.refObjectAttrConcat.reduce((values: string[], attr) => {
    if (attr.includes('||')) {
      const attrs = attr.split(' || ');

      for (const attr of attrs) {
        if (attr.includes('$')) {
          const cleanAttr = attr.replaceAll(/[${}]/g, '');
          const refObjectType = view.rigName.attrConcatRefs?.[cleanAttr]?.refObjectType;
          const refObjectAttr = view.rigName.attrConcatRefs?.[cleanAttr]?.refObjectAttr;
          const directoryId = rigOperation[cleanAttr];

          if (!refObjectType || !refObjectAttr || !directoryId) {
            continue;
          }

          const directories = directoriesStore.getObject(refObjectType);

          if (!directories) continue;

          const directory = directories.find((dir) => dir.id.toString() === directoryId?.toString());

          if (!directory) continue;

          const dirValue = directory.data[refObjectAttr];

          if (hasValue(dirValue) && dirValue !== '' && dirValue !== 'null') {
            values.push(dirValue.toString());
            break;
          }
        }

        const value = rigOperation[attr];

        if (hasValue(value) && value !== '' && value !== 'null') {
          values.push(value.toString());
          break;
        }
      }

      return values;
    }

    const value = rigOperation[attr];

    if (hasValue(value) && (typeof value === 'string' || typeof value === 'number')) {
      values.push(value.toString());
    }

    return values;
  }, []);

  return values.join(`${view.rigName.delimiter}` ?? ' ');
}

export function getWellAttributes(view: RigsChartConflictSidebarViewTypes.ConflictWell, wellData: RawWell): string[] {
  const attributes: string[] = [];

  for (const attribute of view.attributes) {
    const attributeValue = wellData[attribute.objectName];

    if (!hasValue(attributeValue)) {
      continue;
    }

    if (attribute.type === 'DateTime') {
      if (typeof attributeValue === 'string') {
        attributes.push(moment.unix(parseInt(attributeValue)).format(DATE_FORMAT));
      }

      if (typeof attributeValue === 'number') {
        attributes.push(moment.unix(attributeValue).format(DATE_FORMAT));
      }
    }
  }

  return attributes;
}

export function getRigOperationIndex(
  view: RigsChartConflictSidebarViewTypes.ConflictWell,
  wellData: RawWell
): number | null {
  const rigOperationIndex = wellData[view.rigOperationIndex];

  if (typeof rigOperationIndex === 'string' && !Number.isNaN(rigOperationIndex)) {
    return Number(rigOperationIndex) + 1;
  }

  if (typeof rigOperationIndex === 'number') {
    return rigOperationIndex + 1;
  }

  return null;
}

export function getUniqueWellKey(wellData: RawWell): string | null {
  const uniqueWellKey = wellData['GOplan_PlanWellTriple.id'];

  return hasValue(uniqueWellKey) ? uniqueWellKey.toString() : null;
}
