export const debounce = <TArgs extends any[], TResult>(fn: (...args: TArgs) => TResult, ms: number) => {
  let timer: ReturnType<typeof window.setTimeout>;

  return (...args: TArgs): Promise<TResult> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => resolve(fn(...args)), ms);
    });
};
