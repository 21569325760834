import { observer } from 'mobx-react-lite';

import { FormNumberInput } from 'src/shared/components/form-components';

import { NumberField } from '../../../../shared/entities/control-entities';

type Props = {
  className?: string;
  label?: string;
  item: NumberField;
  isDisabled: boolean;
  onBlur?: VoidFunction;
};

export const NewWellNumberField = observer(function NewWellNumberField({
  className,
  label,
  item,
  isDisabled,
  onBlur,
}: Props) {
  const onChange = (value: number | null): void => {
    item.tryToSetRawValue(value);
  };

  const handleBlur = () => {
    onBlur?.();
  };

  return (
    <FormNumberInput
      value={item.value}
      label={label}
      numberOfDecimalPlaces={item.numberOfDecimalPlaces}
      className={className}
      isError={!!item.errorText}
      errorText={item.errorText}
      placeholder={item.placeholder}
      unit={item.unit}
      isDisabled={isDisabled}
      onChange={onChange}
      onFocus={item.clearError}
      onBlur={handleBlur}
    />
  );
});
