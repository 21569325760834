import { action, computed, makeObservable, observable } from 'mobx';

import { TDictObject } from 'src/api/directories/types';
import { Item, ItemContainer, ValidatableItem } from 'src/shared/entities/abstract-control-entities';
import { DateIntervalField } from 'src/shared/entities/control-entities';
import { getRandomNumber } from 'src/shared/utils/get-random-number';

type TApproachStageSection = {
  fields: Item[];
  sectionType: TDictObject;
  parentControl?: ItemContainer;
};

export class ApproachStageSection extends ItemContainer {
  onDeleteCallbacks: VoidFunction[] = [];
  readonly id: number;
  readonly type: TDictObject;

  @observable isVisuallyDisabled = false;

  constructor(data: TApproachStageSection) {
    super(data.fields, data.parentControl);
    this.id = getRandomNumber();
    this.type = data.sectionType;

    makeObservable(this);
  }

  @computed
  get dateField(): DateIntervalField | null {
    return this.fieldsList.find((field): field is DateIntervalField => field instanceof DateIntervalField) ?? null;
  }

  @action.bound
  setIsDateFieldStartDateDisabled(is: boolean) {
    this.dateField?.setIsStartDateDisabled(is);
  }

  @action.bound
  setIsVisuallyDisabled(is: boolean) {
    this.isVisuallyDisabled = is;
  }

  @action.bound
  validate() {
    this.fieldsList.forEach((field) => {
      if (field instanceof ValidatableItem) {
        field.hasErrors();
      }
    });
  }
}
