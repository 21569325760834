import { INumberRestrictions } from '../types';

export class NumberValidation {
  readonly restrictions: INumberRestrictions;

  constructor(restrictions: INumberRestrictions) {
    this.restrictions = restrictions;
  }

  validate(value: number | null): string | [string, { [locKey: string]: string | number }] | undefined {
    if (value === undefined || value === null || isNaN(value)) {
      if (this.restrictions.required) return 'newWellForm:Errors.required';
      return;
    }
    if (this.restrictions?.higherThen !== undefined) {
      if (value > this.restrictions.higherThen) {
        return;
      }
      return ['newWellForm:Errors.moreThan', { minimum: this.restrictions.higherThen + 1 }];
    }
  }
}
