import { useContext, useEffect, useRef, useState } from 'react';

import { Dnd } from '../../editing/types';
import { SortableStore } from '../sortable.store';
import { SortingContext } from '../sorting-context';
import { SortingContextStore } from '../sorting-context.store';

export namespace UseSortable {
  export interface Args<TSortableItem> {
    id: string;
    dataItem: Dnd.Sortable<TSortableItem>;
    options?: SortingContextStore.DraggingOptions;
    onClick?: VoidFunction;
    onMove?: VoidFunction;
  }
}

export function useSortable<TSortableItem>(args: UseSortable.Args<TSortableItem>) {
  const sortingStore = useContext(SortingContext);
  const [sortable] = useState(() => new SortableStore(args, sortingStore));

  const { transform, isDragging, onPointerCancel, onPointerMove, onPointerDown, onPointerUp, cancelDragging } =
    sortable;

  const { id, dataItem } = args;

  const ref = useRef<HTMLElement>(null!);

  useEffect(() => {
    if (ref) {
      if (
        dataItem !== sortingStore.active?.dataItem &&
        sortingStore.getIsElementInteractive(sortingStore.active?.dataItem, dataItem)
      ) {
        sortingStore.registerInteractive(id, ref, dataItem);
      } else {
        sortingStore.unregisterInteractive(id);
      }
    }

    return () => sortingStore.unregisterInteractive(id);
  }, [dataItem, id, sortingStore, sortingStore.active]);

  useEffect(() => {
    const onDocumentVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        cancelDragging();
      }
    };

    window.addEventListener('blur', cancelDragging);
    document.addEventListener('visibilitychange', onDocumentVisibilityChange);

    return () => {
      window.removeEventListener('blur', cancelDragging);
      document.removeEventListener('visibilitychange', onDocumentVisibilityChange);
    };
  }, [cancelDragging]);

  return {
    ref,
    listeners: {
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onPointerCancel,
    },
    transform,
    isDragging,
  };
}
