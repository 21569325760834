import { action, computed, makeObservable } from 'mobx';

import { ISummaryDataModel } from '../../../layers/model';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';
import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport } from '../../../shared/viewport/viewport-calculator';

export class SummaryDataPresenter {
  private readonly viewport: Viewport;
  private readonly dataModel: ISummaryDataModel;

  constructor(viewport: Viewport, dataModel: ISummaryDataModel) {
    this.viewport = viewport;
    this.dataModel = dataModel;

    makeObservable(this);
  }

  @computed
  get data(): (IRigSummary | null)[] {
    return this.dataModel.getData();
  }

  @computed
  get year(): number {
    return this.dataModel.selectedYear;
  }

  @action.bound
  onYearChange(year: number): void {
    this.dataModel.setYear(year);
  }

  @action.bound
  onScroll(offset: number) {
    const { movedStart, movedEnd } = moveViewport(this.viewport, offset);

    this.viewport.setRange(movedStart, movedEnd);
  }
}
