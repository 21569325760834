import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { ConflictsTypes } from 'src/types/conflicts-types';

export class ConflictApi {
  async placeConflictRigOperation(
    planVersionId: number,
    insert: number,
    rigId: number,
    insertAfter?: number,
    insertOnPlace?: number
  ): Promise<void> {
    try {
      await agent.post('/compute/rigOperation', {
        planVersionId,
        groupBy: 'RIG',
        insert,
        insertAfter,
        insertOnPlace,
        rigId,
      });
    } catch (e) {
      throwApiError(e);
    }
  }

  async checkConflicts(
    planVersionId: number,
    rigId?: number,
    geologicalTaskId?: number
  ): Promise<ConflictsTypes.RawRigOperation | undefined> {
    try {
      const { data } = await agent.post<ConflictsTypes.RawRigOperation>(
        `/intersection/checkConflicts/${planVersionId}`,
        {
          rigId,
          geologicalTaskId,
        }
      );

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
