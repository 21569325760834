import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { ViewSettings as TViewSettings } from 'src/api/chart/drilling-plan-charts-api';

import { GroupedViewSettings } from '../../features/settings-components/grouped-view-settings';
import { ViewSettings } from '../../features/settings-components/view-settings';
import { ChartViewSettingsSidebar } from '../chart-view-settings-sidebar/chart-view-settings-sidebar';

import { WellViewSettingsSidebarStore } from './well-view-settings-sidebar.store';

interface Props {
  isOpen: boolean;
  store: WellViewSettingsSidebarStore;
  onClose: VoidFunction;
}

export const WellViewSettingsSidebar = observer(function WellViewSettingsSidebar({ isOpen, onClose, store }: Props) {
  const { tabs, isLoading, settings, onFlatTabSortEnd, onGroupedTabSortEnd, onSettingsSave, notifications } = store;

  const [isApplyBlocked, setIsApplyBlocked] = useState(false);

  const onError = useCallback(
    (error?: Error) => {
      setIsApplyBlocked(!!error);

      if (error) {
        notifications.showWarningMessageT('errors:limitExceeded');
      }
    },
    [notifications]
  );

  const handleSettingsSave = useCallback(() => {
    if (isApplyBlocked) {
      notifications.showWarningMessageT('errors:limitExceeded');
    } else {
      onSettingsSave();
    }
  }, [isApplyBlocked, notifications, onSettingsSave]);

  return (
    <ChartViewSettingsSidebar
      isOpen={isOpen}
      onClose={onClose}
      tabs={tabs}
      isLoading={isLoading}
      onApply={handleSettingsSave}
    >
      {({ activeTab }) => {
        if (!activeTab || !Object.keys(settings).length) {
          return null;
        }

        const currentSettings = settings[activeTab];

        if (!currentSettings) {
          return null;
        }

        return (
          <>
            {currentSettings.type === TViewSettings.ViewSettingsTabsDataTypes.flat && currentSettings.items.length && (
              <ViewSettings onError={onError} settings={currentSettings} onSortEnd={onFlatTabSortEnd} />
            )}
            {currentSettings.type === TViewSettings.ViewSettingsTabsDataTypes.grouped && (
              <GroupedViewSettings groups={currentSettings.items} onError={onError} onSortEnd={onGroupedTabSortEnd} />
            )}
          </>
        );
      }}
    </ChartViewSettingsSidebar>
  );
});
