import { action, computed, makeObservable, observable } from 'mobx';

import { PLAN_VERSION_TYPE } from 'src/features/plan-version-sidebar/types';
import { getDraftTitle, mapActualData } from 'src/features/plan-version-sidebar/utils';
import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';
import {
  TPlanVersion,
  PlanVersionType,
  TActualPlansGroups,
  TAnalyticsPlansGroup,
  TArchivedPlansGroup,
} from 'src/store/comparison/types';
import { getVersionsYearGroupsWithMonths } from 'src/store/comparison/utils';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { DRAFT_ASSIGNMENT } from 'src/store/drafts/types';
import { EditingStore } from 'src/store/editing/editing-store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { PlanVersionStore } from 'src/store/plan-version';
import { RouterStore } from 'src/store/router/router-store';

export class PlanVersionSidebarStore {
  readonly editing: EditingStore;
  readonly drafts: DraftsStore;
  readonly router: RouterStore;
  readonly planVersion: PlanVersionStore;
  readonly i18: I18NextStore;

  @observable planVersionType: PLAN_VERSION_TYPE = PLAN_VERSION_TYPE.actual;
  @observable searchValue = '';

  constructor(rootStore: RootStore) {
    this.editing = rootStore.editing;
    this.drafts = rootStore.drafts;
    this.router = rootStore.router;
    this.planVersion = rootStore.planVersion;
    this.i18 = rootStore.i18;

    makeObservable(this);
  }

  @computed
  get versionsList(): TActualPlansGroups[] | TArchivedPlansGroup[] | TAnalyticsPlansGroup[] {
    switch (this.planVersionType) {
      case PLAN_VERSION_TYPE.actual: {
        return this.actualData;
      }
      case PLAN_VERSION_TYPE.archive: {
        return this.archivedData;
      }

      case PLAN_VERSION_TYPE.analytics: {
        return this.analyticsData;
      }

      default: {
        throw new Error('unknown version group type');
      }
    }
  }

  @computed
  get isEmptyList(): boolean {
    return !this.actualDataSearchResult.length && !this.archivedDataSearchResult.length;
  }

  @computed
  get actualDataSearchResult(): TPlanVersion[] {
    return this.planVersion.actualVersions.concat(this.drafts.draftsList).filter((plan) => {
      if (plan.data.versionType === PlanVersionType.draft) {
        return getDraftTitle(plan, this.i18.t).toLowerCase().includes(this.searchValue);
      }

      return plan.data.name.toLowerCase().includes(this.searchValue);
    });
  }

  @computed
  get archivedDataSearchResult(): TPlanVersion[] {
    return this.planVersion.archivedVersions.filter((plan) => plan.data.name.toLowerCase().includes(this.searchValue));
  }

  @computed
  get analyticsDataSearchResult(): TPlanVersion[] {
    return this.planVersion.analyticsVersions.filter((plan) => plan.data.name.toLowerCase().includes(this.searchValue));
  }

  @computed
  get actualData(): TActualPlansGroups[] {
    return mapActualData(this.actualDataSearchResult);
  }

  @computed
  get archivedData(): TArchivedPlansGroup[] {
    return getVersionsYearGroupsWithMonths('archived', this.archivedDataSearchResult);
  }

  @computed
  get analyticsData(): TAnalyticsPlansGroup[] {
    return getVersionsYearGroupsWithMonths('analytics', this.analyticsDataSearchResult);
  }

  @action.bound
  setPlanVersionType(planVersionType: PLAN_VERSION_TYPE): void {
    this.planVersionType = planVersionType;
  }

  @action.bound
  setSearchValue(searchValue: string): void {
    this.searchValue = searchValue;
  }

  @action.bound
  changeVersion(planVersion: TPlanVersion): void {
    if (planVersion.data.versionType === PlanVersionType.draft) {
      this.changeDraftVersion(planVersion);
      return;
    }

    this.planVersion.onVersionChange(planVersion.id);
  }

  @action.bound
  changeDraftVersion(draft: TPlanVersion): void {
    const draftName = draft.data.name;
    const parentVersionId = draft.data.parentVersionId;

    if (hasValue(parentVersionId)) {
      this.planVersion.onVersionChange(parentVersionId);
    }

    switch (true) {
      case draftName.includes(DRAFT_ASSIGNMENT.editing):
        this.editing.restoreEditing(draft.id, draft.data.parentVersionId);
        return;

      case draftName.includes(DRAFT_ASSIGNMENT.addWell):
        this.drafts.setDraft(draft.id, draft.data.parentVersionId);
        this.router.push('carpet/newWell');
        return;

      case draftName.includes(DRAFT_ASSIGNMENT.import):
        this.router.push(`carpet/conflicts?planVersionId=${draft.id}`);
        return;
    }
  }
}
