import { observer } from 'mobx-react-lite';
import { ChangeEvent, ReactElement, ReactNode, useEffect, useState } from 'react';

import { hasValue } from 'src/shared/utils/common';

import { FormInput } from '../form-input';

import { processInputValue, processNumberValue } from './utils';

type Props = {
  prefix?: string;
  allowClear?: boolean;
  suffixComponent?: ReactNode;
  isInteger?: boolean;
  label?: string | ReactElement;
  numberOfDecimalPlaces?: number;
  className?: string;
  isError?: boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  placeholder?: string;
  unit?: string;
  value?: number | null;
  isDisabled?: boolean;
  onChange: (value: number | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

//TODO: отрефакторить валидацию в компоненте
export const FormNumberInput = observer(function FormNumberInput({
  prefix,
  allowClear,
  suffixComponent,
  label,
  numberOfDecimalPlaces,
  isInteger = false,
  className,
  isError,
  errorText,
  placeholder,
  unit,
  value,
  isDisabled,
  onChange,
  onFocus,
  onBlur,
}: Props) {
  const _numberOfDecimalPlaces =
    hasValue(numberOfDecimalPlaces) && numberOfDecimalPlaces <= 6 ? numberOfDecimalPlaces : 6;

  const [inputValue, setInputValue] = useState<string | null>(() =>
    processNumberValue(value, isInteger, _numberOfDecimalPlaces)
  );

  const onChangeValidator = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;

    const regEx = new RegExp(`^-?(?:0|(?:[1-9][0-9]{0,8}))?(?:[0-9]+[.]{1}[0-9]{0,${_numberOfDecimalPlaces}})?$`, 'g');

    if (
      isInteger || _numberOfDecimalPlaces === 0
        ? inputValue.match(/^-?(?:0|(?:[1-9][0-9]{0,8}))?$/g)
        : inputValue.match(regEx)
    ) {
      setInputValue(inputValue);
    }
  };

  const handleFocus = () => {
    setInputValue(processInputValue(inputValue));
    onFocus?.();
  };

  const handleOnBlur = (): void => {
    if (!inputValue || inputValue === '-') {
      onChange(null);
      onBlur?.();
      setInputValue('');
      return;
    }
    onChange(Number(inputValue));
    onBlur?.();
    setInputValue(processNumberValue(value, isInteger, _numberOfDecimalPlaces));
  };

  useEffect(() => {
    setInputValue(processNumberValue(value, isInteger, _numberOfDecimalPlaces));
  }, [value, isInteger, _numberOfDecimalPlaces]);

  return (
    <FormInput
      allowClear={allowClear}
      isDisabled={isDisabled}
      className={className}
      label={label}
      value={inputValue}
      placeholder={placeholder}
      prefix={prefix}
      suffixComponent={suffixComponent}
      unit={unit}
      isError={isError}
      errorText={errorText}
      onChange={onChangeValidator}
      onFocus={handleFocus}
      onBlur={handleOnBlur}
    />
  );
});
