import { autorun, computed, makeObservable } from 'mobx';

import { Indicators } from 'src/api/chart/indicators-api';

import { IPresenter } from '../../../layers/presenter';
import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport, zoomViewport } from '../../../shared/viewport/viewport-calculator';
import { IndicatorsDataModel } from '../data';

export class IndicatorsPresenter implements IPresenter<Indicators.ViewIndicators | null, number> {
  private isMoveMode = false;

  private readonly viewport;
  private readonly dataModel: IndicatorsDataModel;

  constructor(viewport: Viewport, dataModel: IndicatorsDataModel) {
    this.viewport = viewport;
    this.dataModel = dataModel;
    this.dataModel.setRange(this.viewport.start, this.viewport.end);

    makeObservable(this);
  }

  init() {
    const disposeRangeSetter = autorun(() => {
      const { start, end } = this.viewport;
      this.dataModel.setRange(start, end);
    });

    return () => {
      disposeRangeSetter();
    };
  }

  @computed
  get data(): Indicators.ViewIndicators | null {
    return this.dataModel.getData();
  }

  onPointerDown = () => {
    this.isMoveMode = true;
  };

  onPointerMove = (offsetX: number) => {
    if (!this.isMoveMode) {
      return;
    }

    const { movedStart, movedEnd } = moveViewport(this.viewport, offsetX);
    this.viewport.setRange(movedStart, movedEnd);
  };

  onPointerUp = () => {
    this.isMoveMode = false;
  };

  onZoom = (offset: number, centerPosition: number) => {
    const { zoomedStart, zoomedEnd } = zoomViewport(this.viewport, offset, centerPosition);

    this.viewport.setRange(zoomedStart, zoomedEnd);
  };
}
