import { mapItems } from 'src/api/new-well/serializers/common-serializers';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { Label } from 'src/shared/entities/control-entities';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { InfoSidebarTab } from '../entities/info-sidebar-tab.entity';
import { TabWithColumns } from '../entities/tab-with-columns-entities/tab-with-columns.entity';
import { TabWithPads } from '../entities/tab-with-pads-entities/tab-with-pads.entity';
import { TabWithStages } from '../entities/tab-with-stages-entities/tab-with-stages.entity';
import { TabWithWells } from '../entities/tab-with-wells-entities/tab-with-wells.entity';
import { TTab, TTabWithColumns, TTabWithPads, TTabWithStages, TTabWithWells } from '../graph-info-sidebar';

export const mapTabs = (
  rawTabs: TTab[],
  directories: Directories,
  appSettings: AppSettingsStore,
  notifications: NotificationsStore
) => {
  const tabs: InfoSidebarTab[] = [];

  rawTabs.forEach((rawTab) => {
    switch (rawTab.kind) {
      case 'tabWithColumns': {
        tabs.push(mapColumnTab(rawTab, directories, notifications));
        break;
      }
      case 'tabWithStages': {
        tabs.push(mapTabWithStages(rawTab, directories, notifications));
        break;
      }
      case 'tabWithWells': {
        tabs.push(mapTabWithWells(rawTab, directories, appSettings, notifications));
        break;
      }
      case 'tabWithPads': {
        tabs.push(mapTabWithPads(rawTab, directories, appSettings, notifications));
        break;
      }
    }
  });

  return tabs;
};

const mapColumnTab = (
  tab: TTabWithColumns,
  directories: Directories,
  notifications: NotificationsStore
): TabWithColumns => {
  return new TabWithColumns({
    fieldId: tab.fieldId,
    directories,
    notifications,
    columns: tab.columns.map((column) => {
      const controls = mapItems(column.items, directories);
      controls.forEach((control) => adjustControl(control));

      return { items: controls };
    }),
  });
};

const adjustControl = (control: Item): void => {
  if (control instanceof Label) {
    control.setNoPlaceholders();
  }
};

const mapTabWithStages = (
  tab: TTabWithStages,
  directories: Directories,
  notifications: NotificationsStore
): TabWithStages => {
  return new TabWithStages({
    fieldId: tab.fieldId,
    directories,
    notifications,
    rigOperations: tab.rigOperations,
  });
};

const mapTabWithWells = (
  tab: TTabWithWells,
  directories: Directories,
  appSettings: AppSettingsStore,
  notifications: NotificationsStore
): TabWithWells => {
  return new TabWithWells({
    fieldId: tab.fieldId,
    directories,
    notifications,
    appSettings,
    wells: tab.wells,
  });
};

const mapTabWithPads = (
  tab: TTabWithPads,
  directories: Directories,
  appSettings: AppSettingsStore,
  notifications: NotificationsStore
): TabWithPads => {
  return new TabWithPads({
    fieldId: tab.fieldId,
    directories,
    notifications,
    appSettings,
    pads: tab.pads,
  });
};
