import { action, flow, makeObservable } from 'mobx';

import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { I18NextStore } from 'src/store/i18next/i18next-store';
import { View } from 'src/store/views/views-store';

import { GraphInfoSidebarStore, TSidebarInfoView } from '../graph-info-sidebar/graph-info-sidebar';

import { WellStore } from './well.store';

export class WellInfoSidebarStore extends GraphInfoSidebarStore {
  protected readonly viewManager: View<TSidebarInfoView>;
  private readonly i18: I18NextStore;

  readonly directories: Directories;

  readonly wellStore: WellStore;

  constructor(rootStore: RootStore) {
    super({ rootStore });
    this.directories = rootStore.directories;
    this.viewManager = rootStore.views.wellSidebarView;
    this.i18 = rootStore.i18;

    this.wellStore = new WellStore(rootStore.editing, rootStore.notifications);

    makeObservable(this);
  }

  @flow.bound
  async *init() {
    await this.loadView();
    yield;
  }

  @flow.bound
  async *loadWell(wellId: string | number) {
    if (!this.viewProvider) return;

    this.isLoading = true;

    try {
      await this.wellStore.fetchWell(wellId);
      await this.viewProvider.setValues(this.wellStore.selectedWell);
      yield;
    } catch (e) {
      yield;
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  setIsLoading(is: boolean) {
    this.isLoading = is;
  }
}

export namespace WellInfoSidebar {
  export enum Tabs {
    mainInfo = 'mainData',
    geoLoad = 'geologicalLoad',
    stages = 'stages',
  }
}
