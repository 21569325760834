import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import { getDurationByStartAndEndDates } from 'src/shared/utils/get-duration-by-start-and-end-dates';
import { getStartOrEndDateByDateAndDuration } from 'src/shared/utils/get-start-or-end-date-by-date-and-duration';

import { IDateIntervalRestrictions, IRestrictions } from '../../../features/well-form/types';
import { ValidatableItem } from '../abstract-control-entities';

type DateIntervalFieldData = {
  fieldId: string;
  unit: string;
  initialStart: Moment | null;
  initialEnd: Moment | null;
  initialDuration: number | null;
  restrictions: IDateIntervalRestrictions;
  labels: Record<string, string>;
  placeholderDuration: string;
  placeholderMax: string;
  placeholderMin: string;
  attrNames: {
    duration: string;
    endDate: string;
    startDate: string;
  };
};

type TDateValue = {
  startDate: Moment | null;
  endDate: Moment | null;
  duration: number | null;
};

export class DateIntervalField extends ValidatableItem<TDateValue> {
  readonly restrictions: IRestrictions;
  readonly placeholderMin: string;
  readonly placeholderMax: string;
  readonly placeholderDuration: string;
  readonly labels: Record<string, string>;
  readonly unit: string;

  @observable initialValue: TDateValue = {
    startDate: null,
    endDate: null,
    duration: null,
  };
  @observable isStartDateDisabled: boolean = false;
  @observable startDate: Moment | null;
  @observable endDate: Moment | null;
  @observable duration: number | null;
  @observable disabledStartDate: Moment | null = null;
  @observable disabledEndDate: Moment | null = null;
  @observable fieldsErrors: {
    startDateError?: string;
    endDateError?: string;
    durationError?: string;
  };

  readonly attrNames: {
    duration: string;
    endDate: string;
    startDate: string;
  };

  constructor(data: DateIntervalFieldData) {
    super(data);
    this.attrNames = data.attrNames;
    this.unit = data.unit;
    this.labels = data.labels;
    this.placeholderDuration = data.placeholderDuration;
    this.placeholderMax = data.placeholderMax;
    this.placeholderMin = data.placeholderMin;
    this.restrictions = data.restrictions;
    this.startDate = data.initialStart;
    this.endDate = data.initialEnd;
    this.duration = getDurationByStartAndEndDates(this.startDate, this.endDate);
    this.fieldsErrors = {};
    this.initialValue = {
      startDate: data.initialStart,
      endDate: data.initialEnd,
      duration: getDurationByStartAndEndDates(data.initialStart, data.initialEnd),
    };

    makeObservable(this);
  }

  get value(): TDateValue {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      duration: this.duration,
    };
  }

  @computed
  get isEndDateDisabled(): boolean {
    return this.isStartDateDisabled && !this.startDate;
  }

  @action.bound
  setValue(value: TDateValue, setValueAsInitial?: boolean): void {
    this.clearError();
    this.startDate?.valueOf() !== value.startDate?.valueOf() && this.setStartDate(value.startDate);
    this.endDate?.valueOf() !== value.endDate?.valueOf() && this.setEndDate(value.endDate);
    this.duration !== value.duration && this.setDuration(value.duration);

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: TDateValue): void {
    this.initialValue = {
      startDate: value.startDate,
      endDate: value.endDate,
      duration: value.duration,
    };
  }

  @action.bound
  returnInitialValue(): void {
    this.startDate = this.initialValue.startDate;
    this.endDate = this.initialValue.endDate;
    this.duration = this.initialValue.duration;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    const objectValue: { startDate?: unknown; endDate?: unknown; duration?: unknown } = value;
    const { startDate, endDate, duration } = objectValue;

    if (
      (typeof startDate === 'number' ||
        (typeof startDate === 'string' && !isNaN(Number(startDate))) ||
        startDate === null) &&
      (typeof endDate === 'number' || (typeof endDate === 'string' && !isNaN(Number(endDate))) || endDate === null) &&
      (typeof duration === 'number' || (typeof duration === 'string' && !isNaN(Number(duration))) || duration === null)
    ) {
      const newValue = {
        startDate: startDate === null ? null : moment(Number(startDate) * 1000),
        endDate: endDate === null ? null : moment(Number(endDate) * 1000),
        duration: duration === null ? null : Number(duration),
      };

      this.setValue(newValue, setValueAsInitial);

      return true;
    }
    return false;
  }

  @action.bound
  clearItem(): void {
    this.startDate = null;
    this.endDate = null;
    this.duration = null;
  }

  @action.bound
  setDisabledStartDate(value: Moment | null) {
    this.disabledStartDate = value;
  }

  @action.bound
  setDisabledEndDate(value: Moment | null) {
    this.disabledEndDate = value;
  }

  @action.bound
  setIsStartDateDisabled(is: boolean) {
    this.isStartDateDisabled = is;
  }

  @action.bound
  setStartDate(value: Moment | null): void {
    if (value === this.startDate || value?.unix() === this.startDate?.unix()) {
      return;
    }
    this.clearStartDateError();
    this.fieldsErrors.startDateError = undefined;
    this.startDate = value;
    if (value) {
      if (!this.endDate && this.duration !== null) {
        this.endDate = getStartOrEndDateByDateAndDuration(value, this.duration, 'end');
        return;
      }
      if (this.duration !== null) {
        this.endDate = getStartOrEndDateByDateAndDuration(value, this.duration, 'end');
      }
      if (this.endDate && value) {
        if (value > this.endDate) {
          this.endDate = null;
          return;
        }
        this.setDuration(getDurationByStartAndEndDates(value, this.endDate));
      }
    }
  }

  @action.bound
  setEndDate(value: Moment | null): void {
    if (value === this.endDate || value?.unix() === this.endDate?.unix()) {
      return;
    }
    this.clearEndDateError();
    this.fieldsErrors.endDateError = undefined;
    this.endDate = value;

    if (value) {
      if (!this.startDate && this.duration !== null) {
        this.startDate = getStartOrEndDateByDateAndDuration(value, this.duration, 'start');
        return;
      }
      if (this.startDate && value) {
        const duration = getDurationByStartAndEndDates(this.startDate, value);

        if (duration === this.duration || Number.isNaN(duration)) {
          return;
        }
        this.duration = duration;
      }
    }
  }

  @action.bound
  setDuration(value: number | null): void {
    if (value === this.duration || Number.isNaN(value)) {
      return;
    }
    this.clearDurationError();
    this.fieldsErrors.durationError = undefined;
    this.duration = value;
    if (value !== null) {
      if (this.startDate) {
        this.endDate = getStartOrEndDateByDateAndDuration(this.startDate, value, 'end');
        return;
      }
      if (!this.startDate && this.endDate) {
        this.startDate = getStartOrEndDateByDateAndDuration(this.endDate, value, 'start');
        return;
      }
    }
  }

  checkIsReady(): boolean {
    const { startDateError, endDateError, durationError } = this.fieldsErrors;
    return (
      [startDateError, endDateError, durationError].every((error) => !error) &&
      [this.startDate, this.endDate, this.duration].every((value) => value !== null)
    );
  }

  @action.bound
  clearError(): void {
    this.fieldsErrors = {};
  }

  @action.bound
  validateStartDate() {
    if (this.startDate === null) {
      this.fieldsErrors.startDateError = 'newWellForm:Errors.required';
    }
  }

  @action.bound
  clearStartDateError() {
    this.fieldsErrors.startDateError = undefined;
  }

  @action.bound
  validateEndDate() {
    if (this.endDate === null) {
      this.fieldsErrors.endDateError = 'newWellForm:Errors.required';
    }
  }

  @action.bound
  clearEndDateError() {
    this.fieldsErrors.endDateError = undefined;
  }

  @action.bound
  validateDuration() {
    if (this.duration === null) {
      this.fieldsErrors.durationError = 'newWellForm:Errors.required';
    }
  }

  @action.bound
  clearDurationError() {
    this.fieldsErrors.durationError = undefined;
  }

  @action.bound
  hasErrors(): boolean {
    this.validateStartDate();
    this.validateEndDate();
    this.validateDuration();
    return !!this.fieldsErrors.durationError || !!this.fieldsErrors.startDateError || !!this.fieldsErrors.endDateError;
  }

  getDisabledStartDates = (currentDate: Moment): boolean => {
    return !!this.disabledStartDate && currentDate < this.disabledStartDate;
  };

  getDisabledEndDates = (currentDate: Moment): boolean => {
    return (
      (!!this.startDate && currentDate < this.startDate) ||
      (!!this.disabledEndDate && this.disabledEndDate > currentDate)
    );
  };
}
