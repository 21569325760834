import { observer } from 'mobx-react-lite';

import { DrillingElementColoredCard } from '../../../../drilling-element-colored-card';
import { WellTypeIcon } from '../../../../well-type-icon';
import { TemporaryWellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';

import styles from './data-items-full.module.scss';

interface Props {
  item: TemporaryWellRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const TemporaryWellRigOperationWrapper = observer(function TemporaryWellRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
}: Props) {
  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });

  const wellIcon = viewSettingsProvider.getWellIcon(item.data);

  return (
    <div ref={ref} className={styles.dataItem}>
      <DrillingElementColoredCard
        title={viewSettingsProvider.getTitle(item)}
        color={viewSettingsProvider.getWellColor(item.data) || ''}
        attributes={viewSettingsProvider.getAttributes(item)}
        icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
        className={styles.wellCard}
      />
    </div>
  );
});
