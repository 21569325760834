import { agent } from 'src/api/agent';
import { WellsChartAdapter } from 'src/api/chart/wells-chart-adapter';
import { throwApiError } from 'src/api/utils';
import { WellTypes } from 'src/types/well-types';

import { ViewSettings } from './drilling-plan-charts-api';

export class WellsChartApi {
  private readonly adapter = new WellsChartAdapter();

  async getWells(
    planVersionId: number,
    groupType: string,
    wellName?: string,
    filterValues: Record<string, unknown> = {}
  ): Promise<WellTypes.RawWellsGroupWithItems[]> {
    try {
      const { data: rawGroups } = await agent.post<WellTypes.RawWellsGroup[]>('graph/grouping', filterValues, {
        params: { planVersionId, groupType },
      });

      const groupsIds = this.adapter.receiveRigGroupIds(rawGroups);

      if (!groupsIds.length) {
        return [];
      }

      const { data: wells } = await agent.post<WellTypes.RawWellsGroupWithItems[]>('graph/well/list', filterValues, {
        params: {
          planVersionId,
          groupIdList: groupsIds.join(),
          groupType,
          wellName,
        },
      });

      return wells;
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace WellsChartViewTypes {
  export type CarpetWells = {
    objectName: string;
    idAttrName: string;
  };

  export type Attribute = {
    type: string;
    objectName: string;
  };

  export type StageTooltipAttribute = {
    attrName: string;
    fieldId: string;
    refObjectAttr?: string;
    refObjectType?: string;
    type: 'Color' | 'Icon' | 'Regular';
  };

  export type StageTooltip = {
    wellName: string;
    startDate: string;
    endDate: string;
    attributes: StageTooltipAttribute[];
  };

  export type CarpetStages = {
    attrName: string;
    objectName: string;
    refObjectAttr: string;
    refObjectType: string;
    additionalAttributes: Attribute[];
    tooltip: StageTooltip;
  };

  export type Carpet = {
    wells: CarpetWells;
    stages: CarpetStages;
    defaultGrouping: string;
  };

  export type View = {
    carpet: Carpet;
    settings: ViewSettings.RawSettingsControls;
  };
}
