import { message } from 'antd';

export function showErrorMessage(text: string): void {
  message.error({
    content: text,
    className: 'errorMessage',
  });
}

export function showWarningMessage(text: string): void {
  message.warn({
    content: text,
    className: 'warningMessage',
  });
}

export function showSuccessMessage(text: string): void {
  message.warn({
    content: text,
    className: 'successMessage',
  });
}
