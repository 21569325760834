import { agent } from 'src/api/agent';
import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { throwApiError } from 'src/api/utils';

export class ComparingRigsApi {
  async getRigsWithChanges(
    firstPlanVersionId: number,
    filter: Record<string, unknown>,
    rigName?: string
  ): Promise<RigsChart.RawRig[] | undefined> {
    try {
      const { data: rigsGroups } = await agent.post<RigsChart.RawRig[]>('graph/rig/list/', filter, {
        params: {
          planVersionId: firstPlanVersionId,
          rigName,
        },
      });

      return rigsGroups;
    } catch (e) {
      throwApiError(e);
    }
  }
}
