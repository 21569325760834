import moment from 'moment';

import { Range } from '../../../layers/model';

import { TimelinePeriodsTogglePresenter } from './timeline-periods-toggle-presenter';

export namespace TimelinePeriodsAdapter {
  export const getPeriodRange = (
    period: TimelinePeriodsTogglePresenter.Periods,
    dataRange: Range<number>
  ): Range<number> => {
    switch (period) {
      case TimelinePeriodsTogglePresenter.Periods.monthOne: {
        const start = moment().startOf('month').unix();
        const end = moment().endOf('month').unix();

        return { start, end };
      }
      case TimelinePeriodsTogglePresenter.Periods.monthThree: {
        const start = moment().startOf('month').unix();
        const end = moment().startOf('month').add({ month: 3 }).unix();

        return { start, end };
      }
      case TimelinePeriodsTogglePresenter.Periods.monthSix: {
        const start = moment().startOf('month').unix();
        const end = moment().startOf('month').add({ month: 6 }).unix();

        return { start, end };
      }
      case TimelinePeriodsTogglePresenter.Periods.yearOne: {
        const start = moment().startOf('year').unix();
        const end = moment().endOf('year').unix();

        return { start, end };
      }
      case TimelinePeriodsTogglePresenter.Periods.all: {
        return dataRange;
      }
    }
  };
}
