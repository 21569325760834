import { observer } from 'mobx-react-lite';

import { useClickHandler } from 'src/features/drilling-chart/shared/use-click-handler';
import { ReactComponent as AddRigIcon } from 'src/shared/assets/icons/add-rig.svg';
import { Button } from 'src/shared/components/button';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { RigsGroup, TemporaryRigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { getPositiveOffsetPx } from '../../../shared/viewport/viewport-calculator';
import { useDraggingInteractive } from '../../editing/view/hooks/use-dragging-interactive';
import { useDragging } from '../../sorting/hooks/use-dragging';

import styles from './data-items.module.scss';

interface Props {
  item: RigsGroup | TemporaryRigsGroup;
  verticalViewport: Viewport;
  onRigAdd(groupId: number): void;
}

export const EditingRigsGroupHeader = observer(function EditingRigsGroupHeader({
  item,
  verticalViewport,
  onRigAdd,
}: Props) {
  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging-row'),
    item,
  });

  const ref = useMovableElement({
    item,
    verticalViewport,
    getOffsetY: !item.isCollapsed ? getPositiveOffsetPx : undefined,
  });

  useDraggingInteractive({
    id: item.getKey('interactive'),
    nodeRef: ref,
    dataItem: item,
    timerOptions: {
      delay: 1000,
    },
  });

  const { onPointerUp, onPointerDown } = useClickHandler(() => onRigAdd(item.id));

  return (
    <div ref={mergeRefs(ref, draggingRef)} className={styles.rowEditing}>
      <Button
        variant="primary"
        icon={<AddRigIcon className={styles.addRigIcon} />}
        iconPosition="before"
        className={styles.addRigButton}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
      />
    </div>
  );
});
