import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'src/shared/assets/icons/info-i.svg';
import { ReactComponent as SplitIcon } from 'src/shared/assets/icons/split.svg';
import { Button } from 'src/shared/components/button';
import { Tooltip } from 'src/shared/components/tooltip';

import { ApproachesTab } from '../entities/approach-entities';
import { FormStore } from '../entities/form.entity';
import { TSplitApproachOptions } from '../types';

import { ApproachRow } from './approach-row/approach';

import styles from './well-form-approaches-tab.module.scss';

type Props = {
  formStore: FormStore;
  onAddWellToChart: () => void;
  onSplitApproach?({ sectionId, stage, approach }: TSplitApproachOptions): void;
  onAddNewApproach?(): void;
};

export const WellFormApproachesTab = observer(function WellFormApproachesTab({
  formStore,
  onAddWellToChart,
  onSplitApproach,
  onAddNewApproach,
}: Props) {
  const { t } = useTranslation();
  const approachesTab = formStore.approachesTab;

  if (!(approachesTab instanceof ApproachesTab)) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.approachesList}>
        {approachesTab.approachesList.approaches.map((approach, index) => {
          return (
            <ApproachRow
              key={approach.id}
              index={index}
              approach={approach}
              approachesList={approachesTab.approachesList}
              formStore={formStore}
              onAddWellToChart={onAddWellToChart}
              onSplitApproach={onSplitApproach}
            />
          );
        })}
      </div>
      <div className={styles.newApproachButtonWrapper}>
        <Button
          prefixIcon={<SplitIcon width={16} height={16} stroke="white" />}
          variant="success"
          disabled={approachesTab.isVisuallyDisabled}
          onClick={approachesTab.approachesList.activeApproach ? undefined : onAddNewApproach}
        >
          <p>{t('newWellForm:Approaches.addNewApproach')}</p>
        </Button>
        <Tooltip
          trigger={['click', 'hover', 'focus']}
          text="Тут должен быть текст поясняющий что такое подход и как им пользоваться"
        >
          <button className={styles.newApproachTooltipIcon}>
            <InfoIcon className={styles.icon} stroke="var(--contrast)" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
});
