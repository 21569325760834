import { Select, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { RenderDOMFunc } from 'rc-select/lib/BaseSelect';
import { ReactElement, CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';

import { hasValue } from '../../../utils/common';
import { showWarningMessage } from '../../../utils/messages';
import { DropdownPreloader } from '../form-select/dropdown-preloader';
import { InputError } from '../input-error';
import { InputLabel } from '../input-label';

import styles from './form-multiselect.module.scss';

export type ValidationOptions = Partial<{
  maxCount: number;
}>;

type Props<V = string | number> = {
  open?: boolean;
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  searchValue?: string;
  value?: V[] | null;
  options?: DefaultOptionType[];
  label?: string;
  loading?: boolean;
  showSearch?: boolean;
  filterOption?: boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  isDisabled?: boolean;
  dropdownLoading?: boolean;
  customDropdown?: ReactElement;
  dataTestId?: string;
  optionFilterProp?: string;
  dropdownStyle?: CSSProperties;
  onBlur?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
  onSearch?: (value: string) => void;
  onChange?: (value: V[]) => void;
  onSelect?: (value: V) => void;
  onDeselect?: (value: V) => void;
  onClick?: () => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  dropdownRender?: (origin: ReactElement) => ReactElement;
  getPopupContainer?: RenderDOMFunc;
  validationOptions?: ValidationOptions;
};

export const FormMultiSelect = observer(function FormMultiselect<V>({
  open,
  allowClear = true,
  className,
  placeholder,
  searchValue,
  value,
  label,
  options,
  errorText,
  loading,
  showSearch,
  isDisabled,
  filterOption,
  dropdownLoading,
  customDropdown,
  dropdownStyle,
  optionFilterProp,
  dropdownRender: dropdownRenderProp,
  dataTestId,
  onBlur,
  onSearch,
  onFocus,
  onClear,
  onChange,
  onClick,
  onDeselect,
  onDropdownVisibleChange,
  getPopupContainer,
  validationOptions,
}: Props<V>) {
  const [searchTerm, setSearchTerm] = useState(searchValue ?? '');
  const { t } = useTranslation();

  function handleSearch(searchTerm: string): void {
    setSearchTerm(searchTerm);
    onSearch?.(searchTerm);
  }

  function localFilterOption(searchValue: string, option?: DefaultOptionType): boolean {
    const optionInLowerCase = option?.label?.toString().toLowerCase();
    const searchValueInLowerCase = searchValue.toLowerCase();

    return !!optionInLowerCase?.includes(searchValueInLowerCase);
  }

  function dropdownRender(origin: ReactElement): ReactElement {
    if (dropdownLoading) return <DropdownPreloader />;
    if (!!dropdownRenderProp) return dropdownRenderProp(origin);
    if (customDropdown) return customDropdown;
    return origin;
  }

  function handleChange(changedValue: V[]): void {
    if (!validationOptions) {
      onChange?.(changedValue);
      return;
    }

    if (hasValue(validationOptions.maxCount) && changedValue.length > validationOptions.maxCount) {
      const message = label ? t('errors:limitExceeded') : t('errors:limitExceededWithLabel', { label });

      showWarningMessage(message);
    } else {
      onChange?.(changedValue);
    }
  }

  return (
    <div className={clsx(styles.wrapper, className)}>
      {!!label && <InputLabel text={label} />}
      <Select
        open={open}
        mode={'multiple'}
        value={!!value ? value : []}
        data-testid={dataTestId}
        allowClear={allowClear}
        searchValue={searchValue || searchTerm}
        placeholder={loading ? t('common:pleaseWait') : placeholder || t('common:chooseValue')}
        className={clsx(styles.select, !!errorText && styles.select__error)}
        bordered={false}
        suffixIcon={
          loading ? (
            <div className={styles.loaderWrapper}>
              <Spin size="small" spinning />
            </div>
          ) : (
            <ArrowIcon className={styles.icon} fill="var(--contrast)" />
          )
        }
        showSearch={showSearch}
        disabled={isDisabled || loading}
        filterOption={filterOption || localFilterOption}
        dropdownStyle={dropdownStyle}
        optionFilterProp={optionFilterProp}
        dropdownRender={dropdownRender}
        options={options}
        onClear={onClear}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={handleSearch}
        onDeselect={onDeselect}
        onChange={handleChange}
        onClick={onClick}
        onDropdownVisibleChange={onDropdownVisibleChange}
        getPopupContainer={getPopupContainer}
        showArrow
      />
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
