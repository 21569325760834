import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditImg } from 'src/shared/assets/img/computer.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';
import { Tooltip } from 'src/shared/components/tooltip';
import { useStore } from 'src/store';

import styles from './edit-public-draft-modal.module.scss';

export const EditPublicDraftModal = observer(function EditPublicDraftModal() {
  const { t } = useTranslation();

  const { editing } = useStore();

  const isModalOpened = editing.isEditPublicDraftModalOpened;

  return (
    <Modal isOpened={isModalOpened} className={styles.modal} closeOnClickOutside={false} loading={editing.isLoading}>
      <EditImg className={styles.image} />
      <h2 className={styles.title}>{t('modals:EditPublicDraftModal.title')}</h2>

      <p className={styles.descriptionParagraph}>{t('modals:EditPublicDraftModal.publicDraftDesc')}</p>

      <p className={styles.descriptionParagraph}>{t('modals:EditPublicDraftModal.privateDraftDesc')}</p>

      <p className={styles.descriptionParagraph}>{t('modals:EditPublicDraftModal.saveDraftDesc')}</p>

      <div className={styles.buttonsWrapper}>
        <Button variant="primary" className={styles.button} onClick={editing.editCurrentVersion}>
          {t('modals:EditPublicDraftModal.edit')}
        </Button>

        {editing.isCanCreatePrivateDraft ? (
          <Button
            variant="primary"
            className={styles.button}
            onClick={editing.createPrivateDraft}
            disabled={!editing.isCanCreatePrivateDraft}
          >
            {t('modals:EditPublicDraftModal.create')}
          </Button>
        ) : (
          <Tooltip text={t('modals:EditPublicDraftModal.unableToCreatePrivateDraft')}>
            {/* div необходим, т.к. иначе antd отказывается показывать тултип */}
            <div>
              <Button variant="primary" className={styles.button} disabled={true}>
                {t('modals:EditPublicDraftModal.create')}
              </Button>
            </div>
          </Tooltip>
        )}

        <Button variant="danger" className={styles.button} onClick={editing.closeEditPublicDraftModal}>
          {t('common:Buttons.cancel')}
        </Button>
      </div>
    </Modal>
  );
});
