import { PlanningUserSettingsType, UserSettingsColumnType } from 'src/api/planning';
import { GROUP_TYPE } from 'src/pages/plan-page/types';

import { PlanningRigViewRawType, PlanningFieldViewRawType, PlanningViewType } from './types';

export function serializePlanningGroupType(groupType: GROUP_TYPE): string {
  return groupType.toUpperCase();
}

export function mapPlanningFieldView(planningViewResponse: PlanningFieldViewRawType): PlanningViewType {
  return {
    ...planningViewResponse?.field,
    settings: planningViewResponse.settings,
    rowIdKey: planningViewResponse.rowIdKey,
  };
}

export function mapPlanningRigView(planningViewResponse: PlanningRigViewRawType): PlanningViewType {
  return { ...planningViewResponse?.rig, settings: planningViewResponse.settings };
}

export function mapUserSettings(
  userSettingsResponse: PlanningUserSettingsType,
  groupType: GROUP_TYPE
): UserSettingsColumnType[] {
  return userSettingsResponse[groupType].columns;
}
