export const isNumberArray = (value: unknown): value is number[] => {
  if (Array.isArray(value)) {
    for (const item of value) {
      if (typeof item !== 'number') {
        return false;
      }
    }
    return true;
  }
  return false;
};
