import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { MaxCheckedLimitError, MinCheckedLimitError } from '../../utils/errors';

import { SortableItem } from './sortable-item';

export type IsShownChangeHandler<TData> = (value: boolean, item: TData) => void;

export type TitleComponent<TItem> = FC<{ item: TItem }>;

export interface SortableItemType {
  id: number | string;
  title?: ReactNode;
  isShown?: boolean;
}

interface Options {
  minChecked?: number;
  maxChecked?: number;
}

interface Props<TData extends { id: number | string }> {
  items: TData[];
  onSortEnd(
    items: TData[],
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier,
    oldIndex: number,
    newIndex: number
  ): void;
  /** Custom item title component. */
  titleComponent?: TitleComponent<TData>;
  className?: string;
  onIsShownChange?: IsShownChangeHandler<TData>;
  options?: Options;
  onError?(error?: Error): void;
  customValidateFn?(items: TData[], item?: TData): void;
}

export const SortableList = observer(function SortableList<TData extends SortableItemType>({
  items,
  titleComponent,
  options,
  className,
  onError,
  onSortEnd,
  onIsShownChange,
  customValidateFn,
}: Props<TData>) {
  const hasSwitch = Boolean(onIsShownChange);

  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  const [error, setError] = useState<Error | undefined>();

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = items.findIndex(({ id }) => id === active.id);
    const newIndex = items.findIndex(({ id }) => id === over.id);

    const sortedArray = arrayMove(items, oldIndex, newIndex);
    onSortEnd(sortedArray, active.id, over.id, oldIndex, newIndex);
  };

  const validate = useCallback(() => {
    const checkedItemsCount = items.filter(({ isShown }) => isShown).length;

    if (items.length && options?.maxChecked && checkedItemsCount > options.maxChecked) {
      setError(new MaxCheckedLimitError(`Max checked items count is ${options.maxChecked}`));
      return;
    }

    if (items.length && options?.minChecked && checkedItemsCount < options.minChecked) {
      setError(new MinCheckedLimitError(`Min checked items count is ${options.minChecked}`));
      return;
    }

    setError(undefined);
  }, [items, options?.maxChecked, options?.minChecked]);

  const handleIsOnShownChange = (value: boolean, item: TData) => {
    onIsShownChange?.(value, item);

    if (customValidateFn) {
      customValidateFn(items, item);
    } else {
      validate();
    }
  };

  useEffect(() => {
    if (hasSwitch) {
      if (customValidateFn) {
        customValidateFn(items);
      } else {
        validate();
      }
    }
  }, [customValidateFn, hasSwitch, items, validate]);

  useEffect(() => {
    onError?.(error);
  }, [error, onError]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className={className}>
          {items.map((item) => (
            <SortableItem
              key={item.id}
              item={item}
              onIsShownChange={hasSwitch ? (value) => handleIsOnShownChange(value, item) : undefined}
              hasSwitch={hasSwitch}
              titleComponent={titleComponent}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
});
