import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckmarkIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import { Loader } from 'src/shared/components/loader';
import { RadioGroup } from 'src/shared/components/radio-group/radio-group';
import { Search } from 'src/shared/components/search';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { useDebounce } from 'src/shared/utils/use-debounce';
import { useStore } from 'src/store';

import { AddRigSidebarStore } from './add-rig-sidebar.store';
import { EmptyRig } from './entities/empty-rig';

import styles from './add-rig-sidebar.module.scss';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onApply(rig: EmptyRig | null): void;
}

export const AddRigSidebar = observer(function AddRigSidebar({ isOpen, onClose, onApply }: Props) {
  const { t } = useTranslation('drawers');

  const store = useStore();

  const [sidebarStore] = useState(() => new AddRigSidebarStore(store));

  const { rigs, selectedRigId, selectedRig, onRigChange, isLoading, searchString, setSearchString, fetchEmptyRigs } =
    sidebarStore;

  const debouncedSearchTerm = useDebounce(searchString, 400);

  useEffect(() => {
    if (isOpen) {
      fetchEmptyRigs();
    }
  }, [debouncedSearchTerm, fetchEmptyRigs, isOpen]);

  const handleApply = useCallback(() => {
    if (!selectedRig) {
      return;
    }

    onApply(selectedRig);
    onClose();
  }, [onApply, onClose, selectedRig]);

  return (
    <Sidebar
      isOpened={isOpen}
      onClose={onClose}
      size="l"
      title={t('drawers:addRig.title')}
      className={styles.container}
    >
      <div className={styles.block}>
        <p>{t('drawers:addRig.hint')}</p>
        <Search
          onSearch={setSearchString}
          value={searchString}
          placeholder={t('common:search')}
          className={styles.search}
        />
      </div>

      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} />
        </div>
      ) : (
        <div className={styles.contentWrapper}>
          <RadioGroup items={rigs} value={selectedRigId} onChange={onRigChange} />
        </div>
      )}

      <div className={styles.footer}>
        <Button variant="danger" className={styles.button} icon={<CrossIcon />} iconPosition="before" onClick={onClose}>
          {t('common:Buttons.cancel')}
        </Button>
        <Button
          variant="success"
          className={styles.button}
          icon={<CheckmarkIcon />}
          iconPosition="before"
          onClick={handleApply}
          disabled={isLoading}
        >
          {t('common:Buttons.apply')}
        </Button>
      </div>
    </Sidebar>
  );
});
