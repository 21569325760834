import { useSortable } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { ReactComponent as DotsIcon } from 'src/shared/assets/icons/dots.svg';
import { ReactComponent as SplitIcon } from 'src/shared/assets/icons/split.svg';
import { Button } from 'src/shared/components/button';
import { FormSelect } from 'src/shared/components/form-components';

import { Approach, ApproachStage } from '../../entities/approach-entities';
import { ApproachStageSection } from '../../entities/approach-entities/approach-stage-section.entity';
import { FormStore } from '../../entities/form.entity';
import { TOption, TSplitApproachOptions } from '../../types';

import { SectionField } from './section-field';

import styles from './section-row.module.scss';

type Props = {
  overlay?: boolean;
  options?: TOption[];
  section: ApproachStageSection;
  formStore: FormStore;
  stage: ApproachStage;
  approach: Approach;
  onSplitApproach?({ sectionId, stage, approach }: TSplitApproachOptions): void;
  changeSectionType: (section: ApproachStageSection, newType: string | number) => void;
  deleteSection: (id: number) => void;
};

export const SectionRow = observer(function SectionRow({
  overlay,
  section,
  formStore,
  options,
  stage,
  approach,
  onSplitApproach,
  changeSectionType,
  deleteSection,
}: Props) {
  const { active, attributes, listeners, transform, transition, setNodeRef } = useSortable({
    id: section.id,
  });

  const onDeleteSection = (): void => {
    deleteSection(section.id);
  };

  const onChangeSectionType = (newType: string | number): void => {
    changeSectionType(section, newType);
  };

  const handleSplitSections = (): void => {
    onSplitApproach?.({
      sectionId: section.id,
      stage,
      approach,
    });
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ transform: `translateY(${transform?.y || 0}px)`, transition }}
      className={clsx(
        styles.wrapper,
        active?.id === section.id && styles.wrapper__active,
        overlay && styles.wrapper__overlay
      )}
    >
      {!section.isVisuallyDisabled && (
        <div className={styles.contentWrapper}>
          <Button variant="default" className={styles.splitButton} icon={<SplitIcon />} onClick={handleSplitSections} />
          <DotsIcon {...listeners} className={styles.dotsIcon} />
          <Button
            variant="default"
            className={styles.deleteButton}
            icon={<DeleteIcon width={18} height={18} />}
            onClick={onDeleteSection}
          />
        </div>
      )}
      <div className={styles.fieldsWrapper}>
        <FormSelect
          value={section.type.id}
          allowClear={false}
          isDisabled={section.isVisuallyDisabled}
          className={styles.mainElement}
          options={options}
          onChange={onChangeSectionType}
        />
        <div className={clsx(styles.inputsWrapper)}>
          {section.fieldsList.map((field) => {
            return <SectionField key={field.fieldId} className={styles.field} item={field} formStore={formStore} />;
          })}
        </div>
      </div>
    </div>
  );
});
