import { useEffect, useState } from 'react';

import styles from './drilling-plan-chart.module.scss';

export const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onFullScreen = () => setIsFullScreen(true);
  const onFullScreenExit = () => setIsFullScreen(false);

  const [portalRootElement] = useState(() => document.createElement('div'));

  useEffect(() => {
    if (isFullScreen) {
      document.body.appendChild(portalRootElement).setAttribute('class', styles.fullScreenContainer);
    }

    return () => portalRootElement.remove();
  }, [portalRootElement, isFullScreen]);

  useEffect(() => {
    return () => setIsFullScreen(false);
  }, []);

  return { isFullScreen, onFullScreen, onFullScreenExit, portalRoot: portalRootElement };
};
