import { ITextRestrictions } from '../types';

export class TextValidation {
  readonly restrictions: ITextRestrictions;

  constructor(restrictions: ITextRestrictions) {
    this.restrictions = restrictions;
  }

  validate(value: string | null) {
    if (!value) {
      if (this.restrictions.required) return 'newWellForm:Errors.required';
      return;
    }
  }
}
