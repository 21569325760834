import { observer } from 'mobx-react-lite';

import { LoadingRigOperations, WellRigOperationStage } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingWellsData } from '../../comparing-wells-chart/data';

import { ComparingWellsDataItem } from './comparing-wells-data-item';

interface Props {
  data: ComparingWellsData.ViewItem[];
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewProvider: WellsViewSettingsProvider;
  onWellStageClick(wellId: number): void;
}

export const ComparingWellsDataItemsEmpty = observer(function ComparingWellsDataItemsEmpty({
  data,
  horizontalViewport,
  verticalViewport,
  viewProvider,
  onWellStageClick,
}: Props) {
  return (
    <>
      {data.map((item) => {
        if (item instanceof WellRigOperationStage || item instanceof LoadingRigOperations) {
          return (
            <ComparingWellsDataItem
              key={item.getKey()}
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewProvider={viewProvider}
              onWellStageClick={onWellStageClick}
            />
          );
        }

        return null;
      })}
    </>
  );
});
