import moment from 'moment';

import { RigsChartConflictSidebarViewTypes } from 'src/api/chart/rigs-chart-api';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { hasValue } from 'src/shared/utils/common';
import { Directories } from 'src/store/directories/directories.store';

import { RawWell } from '../../types';

export function getWellTitle(
  directories: Directories,
  view: RigsChartConflictSidebarViewTypes.ConflictWell,
  wellData: RawWell
): string {
  const { attrName, refObjectAttr, refObjectType } = view;

  const attrValue = wellData[attrName];

  if (refObjectAttr && refObjectType) {
    const refObject = directories.getObject(refObjectType);
    const labelObject = refObject?.find(({ id }) => id.toString() === attrValue);
    const label = labelObject?.data[refObjectAttr];

    return label ? label.toString() : '';
  }

  return attrValue?.toString() || '';
}

export function getWellAttributes(view: RigsChartConflictSidebarViewTypes.ConflictWell, wellData: RawWell): string[] {
  const attributes: string[] = [];

  for (const attribute of view.attributes) {
    const attributeValue = wellData[attribute.objectName];

    if (!hasValue(attributeValue)) {
      continue;
    }

    if (attribute.type === 'DateTime') {
      if (typeof attributeValue === 'string') {
        attributes.push(moment.unix(parseInt(attributeValue)).format(DATE_FORMAT));
      }

      if (typeof attributeValue === 'number') {
        attributes.push(moment.unix(attributeValue).format(DATE_FORMAT));
      }
    }
  }

  return attributes;
}

export function getRigOperationIndex(
  view: RigsChartConflictSidebarViewTypes.ConflictWell,
  wellData: RawWell
): number | null {
  const rigOperationIndex = wellData[view.rigOperationIndex];

  if (typeof rigOperationIndex === 'string' && !Number.isNaN(rigOperationIndex)) {
    return Number(rigOperationIndex) + 1;
  }

  if (typeof rigOperationIndex === 'number') {
    return rigOperationIndex + 1;
  }

  return null;
}

export function getUniqueWellKey(wellData: RawWell): string | null {
  const uniqueWellKey = wellData['GOplan_PlanWellTriple.id'];

  return hasValue(uniqueWellKey) ? uniqueWellKey.toString() : null;
}
