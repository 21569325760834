import { ConflictsTypes } from 'src/types/conflicts-types';

import { IWell } from './types';

export class ConflictWell implements IWell {
  private readonly item: ConflictsTypes.RawRigOperation;

  readonly id: number;

  constructor(item: ConflictsTypes.RawRigOperation, id: number) {
    this.item = item;
    this.id = id;
  }

  getFieldValue(fieldName: keyof ConflictsTypes.RigOperationData): unknown | undefined {
    return this.item[fieldName];
  }
}
