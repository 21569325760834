import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';

export class PadSidebarApi {
  // It's recommended to use only 'insertAfter' or 'insertOnPlace' parameter.
  async changeWellsOrder(
    planVersionId: number,
    rigId: number,
    insertAfter: number | null,
    insert: number,
    insertOnPlace: number | null
  ): Promise<void> {
    try {
      await agent.post('/compute/rigOperation', {
        planVersionId,
        rigId,
        insertAfter,
        insert,
        insertOnPlace,
        groupBy: 'PAD',
      });
    } catch (e) {
      throwApiError(e);
    }
  }

  async toggleWellSlider(planWellTripleId: number, isSliderUsed: boolean): Promise<void> {
    try {
      await agent.post(
        `/slider/wells/${planWellTripleId}`,
        {},
        {
          params: {
            isSliderUsed,
          },
        }
      );
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace PadSidebar {
  export enum Tabs {
    mainData = 'mainData',
    wells = 'wells',
  }

  export const isValidTabName = (tabName: unknown): tabName is Tabs =>
    typeof tabName === 'string' && Object.values<string>(Tabs).includes(tabName);
}
