import { Item } from 'src/shared/entities/abstract-control-entities';
import { Label } from 'src/shared/entities/control-entities';
import { hasValue } from 'src/shared/utils/common';
import { getDynamicJoinDependencies } from 'src/shared/utils/get-dynamic-join-dependencies';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';
import { Directories } from 'src/store/directories/directories.store';
import { TJoinResponse, TRefQuery } from 'src/store/directories/types';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { InfoSidebarTab } from '../info-sidebar-tab.entity';

type TColumnTabOptions = {
  fieldId: string;
  columns: { items: Item[] }[];
  directories: Directories;
  notifications: NotificationsStore;
};

export class TabWithColumns extends InfoSidebarTab {
  columns: { items: Item[] }[];

  constructor({ fieldId, columns, directories, notifications }: TColumnTabOptions) {
    super(fieldId, directories, notifications);

    this.columns = columns;
  }

  private async getValuesByDynamicJoin(
    refQuery: TRefQuery | undefined | null,
    data: Record<string, unknown>
  ): Promise<TJoinResponse | null> {
    if (!refQuery) {
      return null;
    }

    const deps = getDynamicJoinDependencies(refQuery);
    const values: Record<string, string | number | boolean> = {};

    deps.attrs.forEach((dep) => {
      const value = data[dep.slice(1)];

      if (isStringNumberOrBoolean(value)) {
        values[dep] = value;
      }
    });

    const res = await this.directories.fetchDynamicJoinObject(refQuery, values);
    return res?.[0] || null;
  }

  private async setLabelControlValueByDinamicJoin(item: Label, data: Record<string, unknown>): Promise<void> {
    if (!item.refObjectAttr || !item.refObjectType) {
      item.tryToSetRawValue(null);
      return;
    }

    const joinRes = await this.getValuesByDynamicJoin(item.refQuery, data);

    if (!joinRes) {
      item.tryToSetRawValue(null);
      return;
    }

    const value = joinRes[item.refObjectType]?.data[item.refObjectAttr];

    if (hasValue(value)) {
      item.tryToSetRawValue(value);
    }
  }

  async setValues(data: Record<string, unknown>): Promise<void> {
    const asyncProcesses: Promise<void>[] = [];

    this.columns.forEach((column) => {
      for (const item of column.items) {
        if (item instanceof Label && item.refQuery && isDynamicJoin(item.refQuery)) {
          asyncProcesses.push(this.setLabelControlValueByDinamicJoin(item, data));
        } else {
          if (item.formElementRefId) {
            const rawValue = data[item.formElementRefId];
            item.tryToSetRawValue(rawValue);
          } else {
            const rawValue = data[item.fieldId];
            item.tryToSetRawValue(rawValue);
          }
        }
      }
    });

    await Promise.all(asyncProcesses);
  }
}
