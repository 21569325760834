import { DatePicker } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'src/shared/assets/icons/calendar-2.svg';
import { DATE_FORMAT, DATETIME_FORMAT } from 'src/shared/constants/date';

import { InputError } from '../input-error';
import { InputLabel } from '../input-label';

import styles from './form-date-picker.module.scss';

type Props = {
  allowClear?: boolean;
  value?: Moment | null; // TODO: make required
  label?: ReactNode;
  errorText?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  format?: string;
  defaultTime?: Moment;
  defaultDate?: Moment;
  withTime?: boolean;
  ghostLabel?: boolean;
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year';
  disabledDate?: (date: Moment) => boolean;
  onChange?(value: Moment | null, dateString: string): void; // TODO: make required
  onBlur?(): void;
  onFocus?(): void;
  getPopupContainer?(props?: unknown): HTMLElement;
};

export const FormDatePicker = observer(function FormDatePicker({
  allowClear,
  value,
  label,
  className,
  disabled,
  placeholder,
  withTime,
  errorText,
  defaultTime,
  defaultDate,
  ghostLabel = false,
  picker,
  format = withTime ? DATETIME_FORMAT : DATE_FORMAT,
  disabledDate,
  onChange,
  onBlur,
  onFocus,
  getPopupContainer,
}: Props) {
  const { t } = useTranslation();

  const _defaultTime = defaultTime ? { defaultValue: defaultTime } : undefined;
  const labelRender = () => {
    if (typeof label === 'string') {
      return <InputLabel text={label} className={clsx(!ghostLabel && styles.label)} />;
    }

    return label;
  };

  const locale = {
    lang: {
      locale: t('common:DatePicker.locale'),
      placeholder: t('common:DatePicker.defaultPlaceholder'),
      today: t('common:DatePicker.today'),
      now: t('common:DatePicker.now'),
      backToToday: t('common:DatePicker.backToToday'),
      ok: 'OK',
      clear: t('common:DatePicker.clear'),
      month: t('common:DatePicker.month'),
      year: t('common:DatePicker.year'),
      timeSelect: t('common:DatePicker.timeSelect'),
      dateSelect: t('common:DatePicker.dateSelect'),
      monthSelect: t('common:DatePicker.monthSelect'),
      yearSelect: t('common:DatePicker.yearSelect'),
      decadeSelect: t('common:DatePicker.decadeSelect'),
      yearFormat: 'YYYY',
      dateFormat: 'DD/MMMM/YYYY',
      dayFormat: 'DD',
      dateTimeFormat: 'DD/MMMM/YYYY HH:mm:ss',
      monthFormat: 'MMMM',
      monthBeforeYear: true,
      previousMonth: t('common:DatePicker.previousMonth'),
      nextMonth: t('common:DatePicker.nextMonth'),
      previousYear: t('common:DatePicker.previousYear'),
      nextYear: t('common:DatePicker.nextYear'),
      previousDecade: t('common:DatePicker.previousDecade'),
      nextDecade: t('common:DatePicker.nextDecade'),
      previousCentury: t('common:DatePicker.previousCentury'),
      nextCentury: t('common:DatePicker.nextCentury'),
    },
    timePickerLocale: {
      placeholder: t('common:DatePicker.defaultTimepickerPlaceholder'),
    },
  };

  return (
    <div className={clsx(styles.inputWrapper, className)}>
      {labelRender()}

      <DatePicker
        value={value}
        disabled={disabled}
        format={format}
        allowClear={allowClear}
        className={clsx(styles.input, allowClear && !!value && !disabled && styles.input__clearable)}
        placeholder={placeholder}
        suffixIcon={<CalendarIcon className={styles.icon} />}
        defaultPickerValue={defaultDate}
        showTime={_defaultTime || withTime}
        locale={locale}
        disabledDate={disabledDate}
        picker={picker}
        onChange={onChange}
        getPopupContainer={getPopupContainer}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
