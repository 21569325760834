import { WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';

import { Range } from '../../../layers/model';
import { RawWell } from '../../../types';

export class RigOperation {
  readonly uniqueCreationKey: string | null;
  readonly data: RawWell;
  readonly color: string;
  readonly title: string;
  readonly attributes: string[];
  readonly wellTypeIcon?: WellTypeIcon;
  readonly rigOperationIndex: number | null;
  readonly parentRigTitle: string;

  constructor(
    uniqueCreationKey: string | null,
    data: RawWell,
    title: string,
    parentRigTitle: string,
    rigOperationIndex: number | null,
    attributes: string[],
    color: string,
    wellTypeIcon?: WellTypeIcon
  ) {
    this.uniqueCreationKey = uniqueCreationKey;
    this.data = data;
    this.color = color;
    this.attributes = attributes;
    this.title = title;
    this.rigOperationIndex = rigOperationIndex;
    this.wellTypeIcon = wellTypeIcon;
    this.parentRigTitle = parentRigTitle;
  }

  get x(): Range<number> | null {
    return null;
  }

  getFieldValue(fieldName: string): unknown {
    if (fieldName in this.data) {
      return this.data[fieldName];
    }
  }

  getRigId(): number | null {
    const rigIdAttr = addGOplanPrefix('RigOperation.rigId');

    if (rigIdAttr in this.data) {
      const rigId = Number(this.data[rigIdAttr]);

      return !Number.isNaN(rigId) ? rigId : null;
    }

    return null;
  }
}
