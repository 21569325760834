import { action, autorun, makeObservable, reaction } from 'mobx';

import { Combobox } from 'src/shared/entities/abstract-control-entities';
import { checkIsRegularDirectories } from 'src/shared/utils/check-is-regular-directory';
import { hasValue } from 'src/shared/utils/common';
import { getDynamicJoinDependencies } from 'src/shared/utils/get-dynamic-join-dependencies';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';
import { RootStore } from 'src/store';

import { Label, RegularComboBox } from '../../../shared/entities/control-entities';
import { Approach } from '../entities/approach-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class ExpandApproachesFunctionalityPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound
  connect(form: FormStore): VoidFunction {
    let disposersFromPrevStep: VoidFunction[] = [];
    const requiredIf = form.approachesTab?.approachesList.requiredIf;

    const disposer = autorun(() => {
      if (requiredIf) {
        requiredIf.forEach((condition) => {
          const control = form.fields[condition.control];
          form.approachesTab?.approachesList.setIsRequired(control.value === condition.value);
        });
      }
      const approaches = form.approaches;
      disposersFromPrevStep.forEach((disposerFromPrevStep) => disposerFromPrevStep());
      disposersFromPrevStep = approaches.map((approach) => this.initializeApproach(approach, form));
    });

    return disposer;
  }

  private initializeApproach(approach: Approach, form: FormStore): () => void {
    let disposersFromPrevStep: VoidFunction[] = [];
    const rigControl = approach.fieldsList.find((control) => control.fieldId === 'rig');

    if (!rigControl) {
      throw new Error('rig control is not presented');
    }

    const controlsInitializationDisposer = autorun(() => {
      disposersFromPrevStep.forEach((disposerFromPrevStep) => disposerFromPrevStep());
      disposersFromPrevStep = [];

      approach.fieldsList.forEach((field) => {
        if (field instanceof Combobox) {
          if (field.onChangeInstructions && field.directory && field.refQuery) {
            const itemRefObjectType = field.refObjectType;
            const directory = field.directory;
            for (const updateField of field.onChangeInstructions.updateField) {
              const fieldId = updateField.fieldId;
              const refObjectAttr = updateField.refObjectAttr;
              const refObjectType = updateField.refObjectType;

              if (!refObjectType || !refObjectAttr || !itemRefObjectType) {
                continue;
              }

              const dependentField = approach.fieldsList.find((depField) => depField.fieldId === fieldId);

              const connectDependentFieldsWithJoinComboboxValueDisposer = autorun(() => {
                const currentDirectoryValue = checkIsRegularDirectories(directory)
                  ? directory.find((dir) => dir.id === field.value)
                  : directory.find((dirValue) => dirValue[itemRefObjectType]?.id === field.value)?.[refObjectType];

                if (dependentField instanceof Label) {
                  if (currentDirectoryValue) {
                    const dependentFieldDirectoryValue = currentDirectoryValue.data[refObjectAttr];

                    if (hasValue(dependentFieldDirectoryValue)) {
                      const labelControlValue = {
                        label: dependentFieldDirectoryValue.toString(),
                        value: null,
                      };

                      dependentField.tryToSetRawValue(labelControlValue);
                    }
                  }
                }

                if (dependentField instanceof RegularComboBox) {
                  if (currentDirectoryValue) {
                    const dependentFieldDirectoryValue = currentDirectoryValue.data[refObjectAttr];
                    dependentField.tryToSetRawValue(dependentFieldDirectoryValue);
                  }
                }
              });
              disposersFromPrevStep.push(connectDependentFieldsWithJoinComboboxValueDisposer);
            }
          }
          if (field.refQuery && isDynamicJoin(field.refQuery)) {
            const refQuery = field.refQuery;

            const dependencies = getDynamicJoinDependencies(refQuery);
            const connectDynamicJoinWithValuesDisposer = autorun(async () => {
              const dependenciesObject: Record<string, string | number | boolean> = {};

              for (const dep of dependencies.fieldId) {
                const value = approach.fieldsList.find((depField) => depField.fieldId === dep)?.value;
                if (value === null || value === undefined) {
                  break;
                }
                if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                  dependenciesObject[dep] = value;
                }
              }
              for (const dep of dependencies.attrs) {
                const value = approach.fieldsList.find((depField) => depField.fieldId === dep)?.value;
                if (value === null || value === undefined) {
                  break;
                }
                if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                  dependenciesObject[dep] = value;
                }
              }
              const joinRes = await this.rootStore?.directories.fetchDynamicJoinObject(refQuery, dependenciesObject);
              if (joinRes) {
                field.setDirectory(joinRes);
              }
            });
            disposersFromPrevStep.push(connectDynamicJoinWithValuesDisposer);
          }
        }
      });
    });

    const rigControlDisposer = reaction(
      () => rigControl.value,
      () => {
        approach.stagesList.removeStages();
      }
    );

    return () => {
      controlsInitializationDisposer();
      rigControlDisposer();
    };
  }
}
