import { action, makeObservable, observable } from 'mobx';

import { WellTypes } from 'src/types/well-types';

import { Range } from '../../../layers/model';

import { WellRigOperationStage } from './well-rig-operation-stage';

/** Well rig operation on wells chart. */
export class WellRigOperation {
  readonly item: WellTypes.RawRigOperation;
  readonly uniqueCreationKey: string;
  readonly id: number;

  @observable y?: Range<number>;
  @observable items?: WellRigOperationStage[];

  constructor(item: WellTypes.RawRigOperation, id: number, uniqueCreationKey: string) {
    this.item = item;
    this.id = id;
    this.uniqueCreationKey = uniqueCreationKey;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-rig-operation-${this.uniqueCreationKey}`;
    }

    return `rig-operation-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): string | number | boolean | WellTypes.RawRigOperationStage[] {
    return this.item[fieldName];
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }

  @action.bound
  setItems(items: WellRigOperationStage[]): void {
    this.items = items;
  }
}
