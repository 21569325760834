import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil.svg';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import styles from './blank-field-sidebar.module.scss';

interface Props {
  store: WellsPageStore;
}

export const BlankFieldSidebar = observer(function BlankFieldSidebar({ store }: Props) {
  const { t } = useTranslation('drawers');

  return (
    <Sidebar
      isOpened={store.isBlankFieldSidebar}
      onClose={store.closeBlankFieldSidebar}
      closeOnClickOutside={true}
      title={t('blankField.blankField')}
    >
      <div className={styles.controlBar}>
        <p className={styles.controlBarHint}>{t('blankField.hint')}</p>
        <button className={styles.controlBarButton}>
          <EditIcon />
        </button>
      </div>
      <div className={styles.wrapper}>
        <Collapse className={styles.collapse}>
          <Panel
            key={'Какая-то группа колонок'}
            header={<p className={styles.groupTittle}>{'Какая-то группа колонок'}</p>}
          >
            <div className={styles.columnsGroup}>
              {store.table.columnsData.map((column) => (
                <p className={styles.columnName} key={column.name}>
                  {column.label}
                </p>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>
    </Sidebar>
  );
});
