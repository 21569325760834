import moment from 'moment';

import { TimelineDataModel } from './timeline-data-model';
import { TimelineYear } from './timeline-year';

export namespace TimelineDataSource {
  const generateYearsInterval = (start: number, end: number): TimelineDataModel.ITimelineItem[] => {
    const years: TimelineDataModel.ITimelineItem[] = [];

    const startYear = moment.unix(start).get('year');
    const endYear = moment.unix(end).get('year');

    for (let year = startYear; year <= endYear; year++) {
      const currentYear = moment().set('year', year);

      years.push(
        new TimelineYear({
          start: year === startYear ? moment.unix(start).startOf('month').unix() : currentYear.startOf('year').unix(),
          end: year === endYear ? moment.unix(end).endOf('month').unix() : currentYear.endOf('year').unix(),
        })
      );
    }

    return years;
  };

  export const generateTimelineYears = (start: number, end: number): TimelineDataModel.ITimelineItem[] => {
    return generateYearsInterval(start, end);
  };
}
