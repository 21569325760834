import { action, makeObservable } from 'mobx';

import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { ComparingIndicatorsDataModel } from '../../features/comparing-indicators-table/data';
import { ComparingIndicatorsPresenter } from '../../features/comparing-indicators-table/presenter';
import { Viewport } from '../../shared/viewport/viewport';

export class ComparingIndicatorsTableStore {
  private readonly horizontalViewport: Viewport;
  private readonly dataModel: ComparingIndicatorsDataModel;

  readonly presenter: ComparingIndicatorsPresenter;

  constructor(
    horizontalViewport: Viewport,
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    notifications: NotificationsStore
  ) {
    this.horizontalViewport = horizontalViewport;

    this.dataModel = new ComparingIndicatorsDataModel(
      this.horizontalViewport,
      firstPlanVersionId,
      secondPlanVersionId,
      notifications
    );
    this.presenter = new ComparingIndicatorsPresenter(this.horizontalViewport, this.dataModel);

    makeObservable(this);
  }

  @action.bound
  onFiltersChange(filters: Record<string, unknown>): void {
    this.dataModel.setFilters(filters);
  }

  init(): VoidFunction {
    const disposeDataModel = this.dataModel.init();
    const disposePresenter = this.presenter.init();

    return () => {
      disposeDataModel();
      disposePresenter();
    };
  }
}
