import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useIsOutOfViewport } from 'src/shared/hooks/use-is-out-of-viewport';

import { ComparingWellRigOperationStage } from '../../../presets/comparing-drilling-wells-chart/entities';
import { LoadingRigOperations, WellRigOperationStage } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ChartTooltip } from '../../chart-tooltip/chart-tooltip';
import { DrillingStageTooltipInner } from '../../data-items-empty/view/drilling-stage-tooltip-inner';
import { DrillingStageWrapper } from '../../drilling-step-wrapper';
import { EmptyBlockWrapper } from '../../empty-block-wrapper';

import styles from './data-items-full.module.scss';

interface Props {
  item: WellRigOperationStage | LoadingRigOperations;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewProvider: WellsViewSettingsProvider;
  onClick(wellId: string | number): void;
}

export const ComparingWellsDataItemFull = observer(function ComparingWellsDataItemFull({
  item,
  horizontalViewport,
  verticalViewport,
  viewProvider,
  onClick,
}: Props) {
  const isOutOfViewport = useIsOutOfViewport(item, horizontalViewport);

  if (item instanceof LoadingRigOperations) {
    return (
      <EmptyBlockWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        className={styles.loadingBlock}
      />
    );
  }

  if (item instanceof ComparingWellRigOperationStage) {
    return (
      <DrillingStageWrapper
        item={item}
        viewProvider={viewProvider}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        className={clsx(item.hasChanges && styles.stageCardComparing)}
        contentClassName={clsx(item.hasChanges && styles.stageCardInnerComparing)}
        headerClassName={clsx(item.hasChanges && styles.stageCardHeaderComparing)}
        onClick={onClick}
      />
    );
  }

  if (item instanceof WellRigOperationStage) {
    if (item.parentWell) {
      const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

      return (
        <ChartTooltip
          content={<DrillingStageTooltipInner stage={item} viewProvider={viewProvider} />}
          placement={tooltipPlacement}
        >
          {({ handleMouseOver, handleMouseLeave }) => (
            <DrillingStageWrapper
              item={item}
              viewProvider={viewProvider}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            />
          )}
        </ChartTooltip>
      );
    }

    return (
      <DrillingStageWrapper
        item={item}
        viewProvider={viewProvider}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        onClick={onClick}
      />
    );
  }

  return null;
});
