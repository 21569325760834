import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './no-data-table-cell.module.scss';

export const NoDataTableCell = observer(function NoDataTableCell() {
  const { t } = useTranslation('common');

  return <div className={styles.container}>{t('noData')}</div>;
});
