import { TDirectoriesAttributesDictionary } from 'src/store/directories/types';

import { TDirectoryTypeAttributeRaw } from '../types';

export const mapDirectoryTypeAttributes = (
  attrName: string,
  rawAttributes: TDirectoryTypeAttributeRaw[]
): TDirectoriesAttributesDictionary => {
  let _dict: TDirectoriesAttributesDictionary = {};
  for (let attribute of rawAttributes) {
    // Stages and sections attributes parsing
    if ('stages' in attribute) {
      attribute.stages[0].attributes.forEach((stageAttr) => {
        if ('sections' in stageAttr) {
          stageAttr.sections[0].attributes.forEach((sectionAttr) => {
            _dict[attrName] = sectionAttr;
          });
          return;
        }
        _dict[attrName] = stageAttr;
      });
      continue;
    }
    _dict[attrName] = attribute;
  }
  return _dict;
};
