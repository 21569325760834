import { ReactComponent as CrossedLabelIcon } from 'src/shared/assets/icons/label-crossed.svg';
import { ReactComponent as LabelIcon } from 'src/shared/assets/icons/label.svg';
import { TSwitcherItems } from 'src/shared/components/switcher/switcher';

export const labelSwitcherItems: TSwitcherItems = [
  {
    text: '',
    renderCompactIcon: (className) => <CrossedLabelIcon className={className} />,
  },
  {
    text: '',
    renderCompactIcon: (className) => <LabelIcon className={className} />,
  },
];

export const PLAN_VERSION_GROUP = 'planVersion';
