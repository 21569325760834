import { action, computed, flow, makeObservable, reaction } from 'mobx';

import { IndicatorsApi } from 'src/api/chart/indicators-api';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { assert } from '../../../../shared/utils/assert';
import { hasValue } from '../../../../shared/utils/common';
import { Viewport } from '../../shared/viewport/viewport';

import { IndicatorsDataModel } from './data';
import { IndicatorsPresenter } from './presenter';

export class IndicatorsTableStore {
  private readonly dataModel: IndicatorsDataModel;
  private readonly editing: EditingStore;

  readonly presenter: IndicatorsPresenter;

  constructor(horizontalViewport: Viewport, editing: EditingStore, notifications: NotificationsStore) {
    this.editing = editing;

    this.dataModel = new IndicatorsDataModel(horizontalViewport, new IndicatorsApi(), editing, notifications);
    this.presenter = new IndicatorsPresenter(horizontalViewport, this.dataModel);

    makeObservable(this);
  }

  @computed
  private get planVersionId(): number {
    const planVersionId = this.editing.actualPlanVersionId;

    assert(hasValue(planVersionId), 'Invalid plan version ID.');

    return planVersionId;
  }

  @action.bound
  onFiltersChange(filters: Record<string, unknown>): void {
    this.dataModel.setFilters(filters);
  }

  @flow.bound
  async *reloadIndiatorsData() {
    await this.dataModel.reloadIndiatorsData();
    yield;
  }

  @action.bound
  init(): VoidFunction {
    const disposeIndicatorsPresenter = this.presenter.init();
    const disposeIndicatorsDataModel = this.dataModel.init();

    const disposeStorageClearing = reaction(
      () => this.planVersionId,
      () => {
        this.dataModel.clearStorage();
      }
    );

    return () => {
      disposeIndicatorsDataModel();
      disposeIndicatorsPresenter();
      disposeStorageClearing();
    };
  }
}
