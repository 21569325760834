import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { ComparingRigsChart } from 'src/api/chart/comparing-rigs-chart-data-api';
import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { debounce } from 'src/shared/utils/debounce';

import { Range } from '../../../layers/model';
import { MainComparingChartRig } from '../../../presets/comparing-drilling-rigs-chart/entities';
import { ChartRig, LoadingRigOperations, PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';
import { RigsDataPositionsCalculator } from '../../../shared/rigs-data-positions-calculator';
import { StorageKeyManagerWithParser } from '../../../shared/storage-key-manager';
import { TimeRangeHelper } from '../../../shared/time-range-helper';
import { TimeUnit } from '../../../shared/time-unit';
import { ViewFrameController } from '../../../shared/view-frame-controller';
import { ViewHelper } from '../../../shared/view-helper';

import { ComparingRigsChartDataModel } from './comparing-rigs-chart-data-model';
import { IComparingRigsChartDataStorage } from './comparing-rigs-chart-data.types';

export class ComparingRigsChartDataStorage implements IComparingRigsChartDataStorage {
  private readonly keyManager: StorageKeyManagerWithParser<Range<number>, string>;
  /*
   * Format:
   *
   * {
   *   rigId: {
   *     range: ChartPad
   *   }
   * }
   *
   * Range - the date range. Format: '${startDate}-${endDate}'.
   * */
  private readonly pads: Map<number, Map<string, ComparingRigsChart.TwoVersionsData<PadRigOperation[]>>>;
  /*
   * Format:
   *
   * {
   *   rigId: {
   *     range: EmptyPad
   *   }
   * }
   *
   * Range - the date range. Format: '${startDate}-${endDate}'.
   * */
  private readonly emptyPads: Map<number, Map<string, LoadingRigOperations>>;
  private readonly dataView: DataView;
  private readonly viewFrameController = new ViewFrameController<ComparingRigsChartDataModel.ViewItem>(
    ComparingRigsChartDataStorage.FRAME_HEIGHT
  );

  @observable private horizontalDataRange: Range<number> | null = null;
  // Can be replaced with full chart view object if you need more chart view data here.
  @observable private shownWellAttributesNumber: number = 0;
  @observable private positionsCalculator: RigsDataPositionsCalculator;

  @observable verticalDataRange: Range<number> | null = null;
  @observable rigs?: ChartRig[];

  constructor(
    keyManager: StorageKeyManagerWithParser<Range<number>, string>,
    positionsCalculator: RigsDataPositionsCalculator,
    dataView: DataView
  ) {
    this.positionsCalculator = positionsCalculator;
    this.keyManager = keyManager;
    this.dataView = dataView;
    this.pads = observable.map();
    this.emptyPads = observable.map();

    makeObservable(this);
  }

  @action.bound
  private setPads(
    rigId: number,
    horizontalViewRange: Range<number>,
    pads: ComparingRigsChart.TwoVersionsData<PadRigOperation[]>
  ): void {
    const padsInRig = this.pads.get(rigId);
    const padsRangeKey = this.keyManager.getKey(horizontalViewRange);

    if (padsInRig) {
      const padsInView = padsInRig.get(padsRangeKey);

      if (padsInView) {
        padsInView.first.push(...pads.first);

        if (pads.second) {
          if (!padsInView.second) {
            padsInView.second = [...pads.second];
          } else {
            padsInView.second.push(...pads.second);
          }
        }
      } else {
        padsInRig.set(padsRangeKey, pads);
      }
    } else {
      this.pads.set(rigId, observable.map().set(padsRangeKey, pads));
    }
  }

  @action.bound
  private setEmptyPads(rigId: number, horizontalViewRange: Range<number>, pad: LoadingRigOperations): void {
    const padsInRig = this.emptyPads.get(rigId);
    const padsRangeKey = this.keyManager.getKey(horizontalViewRange);

    if (padsInRig) {
      const padInView = padsInRig.get(padsRangeKey);

      if (!padInView) {
        padsInRig.set(padsRangeKey, pad);
      }
    } else {
      this.emptyPads.set(rigId, observable.map().set(padsRangeKey, pad));
    }
  }

  /** Add empty pads objects to empty data ranges.
   * This allows to determine missing data to download pads and display loading data in view.
   * */
  @action.bound
  private fillInMissingPads(
    data: ChartRig[],
    verticalViewFrame: Range<number>,
    horizontalViewFrame: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    for (let rigIndex = 0; rigIndex < data.length; rigIndex++) {
      const rig = data[rigIndex];

      if (rig && rig.y && ViewHelper.isInView(rig.y, verticalViewFrame)) {
        const padsInRig = this.pads.get(rig.id);
        const emptyPadsInRig = this.emptyPads.get(rig.id);
        const rangesOfAllPadsInRig = [...(padsInRig?.keys() || []), ...(emptyPadsInRig?.keys() || [])];

        const horizontalViewFrameChunks = ComparingRigsChartDataStorage.getChunksOfDataRange(horizontalViewFrame);
        const emptyRanges: Range<number>[] = [];

        // Iterate chunks of horizontal view frame to fill in empty ranges.
        for (let rangeChunkIndex = 0; rangeChunkIndex < horizontalViewFrameChunks.length; rangeChunkIndex++) {
          const viewFrameChunk = horizontalViewFrameChunks[rangeChunkIndex];

          // Add empty range if there is no chunk of horizontal view frame that contains data or empty range object.
          if (
            !rangesOfAllPadsInRig.find((range) => {
              const { start, end } = this.keyManager.parseKey(range);
              return viewFrameChunk.start >= start && viewFrameChunk.end <= end;
            })
          ) {
            emptyRanges.push(viewFrameChunk);
          }
        }

        // Reduce found empty ranges to create empty pads objects and put into the ranges.
        const emptyPads = emptyRanges.reduce((pads: LoadingRigOperations[], currentRange: Range<number>) => {
          const id = Number(rig.id.toString().concat(currentRange.start.toString()));

          if (rig.y) {
            const emptyPad = new LoadingRigOperations(id, currentRange);
            emptyPad.setY(this.positionsCalculator.calculatePadPosition(rig.y, dataViewType, false));
            pads.push(emptyPad);
          }

          return pads;
        }, []);

        emptyPads.forEach((pad) => {
          this.setEmptyPads(rig.id, pad.x, pad);
        });
      }
    }
  }

  private fillInMissingPadsDebounced = debounce(
    (
      data: ChartRig[],
      verticalViewFrame: Range<number>,
      horizontalViewFrame: Range<number>,
      dataViewType: DataView.DataViewType
    ) => this.fillInMissingPads(data, verticalViewFrame, horizontalViewFrame, dataViewType),
    300
  );

  /** Calculate the boundaries of empty data to load them.
   * Takes computed empty ranges and cluster them to rectangular blocks.
   * Returns list of these bounds.
   *
   * @return Boundaries that include horizontal time ranges and vertical rigs IDs. */
  @computed
  get missingDataBounds(): ComparingRigsChartDataStorage.BlockBounds[] | null {
    if (!this.rigs) {
      return null;
    }

    const missingDataBounds: Map<string, ComparingRigsChartDataStorage.BlockBounds> = new Map();

    const sortedEmptyPads = [...this.emptyPads.entries()].sort(
      ([firstRigId], [secondRigId]) =>
        (this.rigs?.findIndex(({ id }) => id === firstRigId) || 0) -
        (this.rigs?.findIndex(({ id }) => id === secondRigId) || 0)
    );

    for (const [rigId, emptyPadsInRig] of sortedEmptyPads) {
      let rigMissingDataRanges: Range<number>[] = [];

      for (const [emptyPadRangeKey, emptyPad] of emptyPadsInRig) {
        // Continue if empty pad has already been put into missing data.
        if (emptyPad.isInLoading) {
          continue;
        }

        const { start, end } = this.keyManager.parseKey(emptyPadRangeKey);

        // Add new current empty range if there are no missing ranges for current rig.
        if (!rigMissingDataRanges.length) {
          rigMissingDataRanges.push({ start, end });
          continue;
        } else {
          // Iterate the previously added missing ranges to expand them if they are nearby.
          for (
            let rigHorizontalRangeIndex = 0;
            rigHorizontalRangeIndex < rigMissingDataRanges.length;
            rigHorizontalRangeIndex++
          ) {
            const rigHorizontalRange = rigMissingDataRanges[rigHorizontalRangeIndex];

            if (start === rigHorizontalRange.end + 1) {
              rigHorizontalRange.end = end;
              break;
            } else if (end === rigHorizontalRange.start - 1) {
              rigHorizontalRange.start = start;
              break;
            } else if (rigHorizontalRangeIndex === rigMissingDataRanges.length - 1) {
              rigMissingDataRanges.push({ start, end });
              break;
            }
          }
        }

        emptyPad.isInLoading = true;
      }

      // Put previously calculated missing ranges for rig to missing data map.
      for (let rigRangeIndex = 0; rigRangeIndex < rigMissingDataRanges.length; rigRangeIndex++) {
        const rigRange = rigMissingDataRanges[rigRangeIndex];
        const currentRangeKey = `${rigRange.start}-${rigRange.end}`;
        const sameRangeInExistingRanges = missingDataBounds.get(currentRangeKey);

        if (sameRangeInExistingRanges) {
          sameRangeInExistingRanges.rigsIds.add(rigId);
        } else {
          missingDataBounds.set(currentRangeKey, {
            horizontalRange: { ...rigRange },
            rigsIds: new Set<number>([rigId]),
          });
        }
      }
    }

    if (!missingDataBounds.size) {
      return null;
    } else {
      return [...missingDataBounds.values()];
    }
  }

  @computed
  get allDataVerticalRange(): Range<number> | null {
    const { rigs } = this;

    if (!rigs) {
      return null;
    }

    const firstRig = rigs.at(0);
    const lastRig = rigs.at(-1);

    if (!firstRig?.y || !lastRig?.y) {
      return null;
    }

    return { start: firstRig.y.start, end: lastRig.y.end };
  }

  @computed
  get data(): ComparingRigsChartDataStorage.DataInView {
    const { horizontalFrame, verticalFrame, elements } = this.viewFrameController;

    if (!verticalFrame || !horizontalFrame || !elements) {
      return { items: null };
    }

    const rigsInView: number[] = elements.filter((item) => item instanceof ChartRig).map(({ id }) => id);

    return { items: elements, rigIds: rigsInView };
  }

  init(): VoidFunction {
    const disposeEmptyPadsCalculating = reaction(
      () => ({
        data: this.rigs,
        verticalViewFrame: this.viewFrameController.verticalFrame,
        horizontalViewFrame: this.viewFrameController.horizontalFrame,
      }),
      ({ data, verticalViewFrame, horizontalViewFrame }) => {
        if (data?.length && verticalViewFrame !== undefined && horizontalViewFrame !== undefined) {
          this.fillInMissingPadsDebounced(data, verticalViewFrame, horizontalViewFrame, this.dataView.type);
        }
      }
    );

    const disposeWellsSetter = autorun(() => {
      const { rigs, pads, emptyPads } = this;

      if (rigs) {
        for (const rig of rigs) {
          const padsItemsFirst: (PadRigOperation | LoadingRigOperations)[] = [];
          const padsItemsSecond: (PadRigOperation | LoadingRigOperations)[] = [];

          for (const [rigId, padsInRig] of pads) {
            if (rigId === rig.id) {
              for (const [, padsChunk] of padsInRig) {
                padsItemsFirst.push(...padsChunk.first);

                if (padsChunk.second) {
                  padsItemsSecond.push(...padsChunk.second);
                }
              }
            }
          }

          for (const [rigId, padsInRig] of emptyPads) {
            if (rigId === rig.id) {
              for (const [, padsChunk] of padsInRig) {
                padsItemsFirst.push(padsChunk);
              }
            }
          }

          rig.setPads(padsItemsFirst);

          if (rig instanceof MainComparingChartRig) {
            rig.comparingPair.setPads(padsItemsSecond);
          }
        }

        this.normalizeData();
      }
    });

    return () => {
      disposeEmptyPadsCalculating();
      disposeWellsSetter();
    };
  }

  // Need to be used in calculating positions of chart objects.
  @action.bound
  setShownWellAttributesNumber(attributesNumber: number, dataView: DataView.DataViewType): void {
    this.shownWellAttributesNumber = attributesNumber;
    this.normalizeData();
  }

  @action.bound
  setRigs(rigs: RigsChart.RawRig[], firstPlanVersionId: number, secondPlanVersionId: number): void {
    this.rigs = this.positionsCalculator.initRigsWithChanges(
      rigs,
      this.dataView.type,
      this.shownWellAttributesNumber,
      firstPlanVersionId,
      secondPlanVersionId,
      this.viewFrameController.setElementToFrame
    );
    this.pads.clear();
    this.emptyPads.clear();
  }

  @action.bound
  setWells(
    rigs: Map<number, ComparingRigsChart.TwoVersionsData<PadRigOperation[]>>,
    horizontalViewRange: Range<number>,
    dataViewType: DataView.DataViewType
  ): void {
    for (const [rigId, padsChunk] of rigs) {
      const currentPadMap = this.pads.get(rigId);
      const allPadsInRig = currentPadMap ? [...currentPadMap.values()].flat() : [];
      const allPadsInRigByVersions = allPadsInRig.reduce(
        (
          allPads: Required<ComparingRigsChart.TwoVersionsData<PadRigOperation[]>>,
          currentPadsChunk: ComparingRigsChart.TwoVersionsData<PadRigOperation[]>
        ) => {
          allPads.first.push(...currentPadsChunk.first);

          if (currentPadsChunk.second) {
            allPads.second?.push(...currentPadsChunk.second);
          }

          return allPads;
        },
        { first: [], second: [] }
      );

      // Check and filter existing pads.
      const firstVersionFilteredPads = padsChunk.first.reduce((newPads: PadRigOperation[], currentPad) => {
        const firstVersionExistingPad = allPadsInRigByVersions.first.find(({ id }) => id === currentPad.id);

        if (firstVersionExistingPad) {
          for (let wellIndex = 0; wellIndex < currentPad.wellRigOperations.length; wellIndex++) {
            const well = currentPad.wellRigOperations[wellIndex];

            if (!firstVersionExistingPad.wellRigOperations.find(({ id }) => id === well.id)) {
              firstVersionExistingPad.setItems([...firstVersionExistingPad.wellRigOperations, well]);
            }
          }
        } else {
          newPads.push(currentPad);
        }

        return newPads;
      }, []);

      const secondVersionFilteredPads = padsChunk.second?.reduce((newPads: PadRigOperation[], currentPad) => {
        const secondVersionExistingPad = allPadsInRigByVersions.second.find(({ id }) => id === currentPad.id);

        if (secondVersionExistingPad) {
          for (let wellIndex = 0; wellIndex < currentPad.wellRigOperations.length; wellIndex++) {
            const well = currentPad.wellRigOperations[wellIndex];

            if (!secondVersionExistingPad.wellRigOperations.find(({ id }) => id === well.id)) {
              secondVersionExistingPad.setItems([...secondVersionExistingPad.wellRigOperations, well]);
            }
          }
        } else {
          newPads.push(currentPad);
        }

        return newPads;
      }, []);

      this.setPads(rigId, horizontalViewRange, {
        first: firstVersionFilteredPads,
        second: secondVersionFilteredPads,
      });

      const emptyPadsInRig = this.emptyPads.get(rigId);
      const viewRangeChunks = ComparingRigsChartDataStorage.getChunksOfDataRange(horizontalViewRange);

      viewRangeChunks.forEach((range) => {
        const rangeKey = this.keyManager.getKey(range);
        emptyPadsInRig?.delete(rangeKey);
      });
    }

    // Filter rigs with new data to calculate only added pads (and wells) positions.
    const data = this.rigs?.filter((rig) => [...rigs.keys()].includes(rig.id));

    if (data?.length) {
      this.positionsCalculator.calculateComparingPadsPositions(data, dataViewType);
    }
  }

  @action.bound
  normalizeData(): void {
    const data = this.rigs;
    const dataViewType = this.dataView.type;

    if (data) {
      this.viewFrameController.clearMap();
      this.positionsCalculator.calculateComparingDataPositions(
        data,
        dataViewType,
        this.shownWellAttributesNumber,
        this.viewFrameController.setElementToFrame
      );
    }
  }

  @action.bound
  setVerticalViewRange(start: number, end: number): void {
    this.viewFrameController.setVerticalViewRange({ start, end });
  }

  @action.bound
  setHorizontalViewRange(start: number, end: number): void {
    this.viewFrameController.setHorizontalViewRange({ start, end });
  }

  getRig = (rigId: number): { rig?: ChartRig } | null => {
    const { rigs } = this;

    if (!rigs) {
      return null;
    }

    for (const rig of rigs) {
      if (rig && rig.id === rigId) {
        return { rig };
      }
    }

    return null;
  };
}

export namespace ComparingRigsChartDataStorage {
  /** In pixels. */
  export const FRAME_HEIGHT = 800;

  export type BlockBounds = { horizontalRange: Range<number>; rigsIds: Set<number> };

  export type DataInView = { items: ComparingRigsChartDataModel.ViewItem[] | null; rigIds?: number[] };

  /** Split view range into small chunks. Chunk size is one year. */
  export const getChunksOfDataRange = (horizontalViewRange: Range<number>): Range<number>[] =>
    TimeRangeHelper.getIntermediateDates(horizontalViewRange, TimeUnit.year);
}
