import { action, makeObservable, observable } from 'mobx';

import { Range } from '../../../layers/model';

import { WellRigOperation } from './well-rig-operation';

export class LoadingRigOperations {
  readonly id: number;
  readonly x: Range<number>;
  readonly uniqueCreationKey: string;
  readonly parent?: WellRigOperation;

  isInLoading = false;

  @observable y: Range<number>;

  constructor(id: number, x: Range<number>, y: Range<number>, uniqueCreationKey: string, parent?: WellRigOperation) {
    this.id = id;
    this.x = x;
    this.y = y;
    this.uniqueCreationKey = uniqueCreationKey;
    this.parent = parent;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-empty-rig-operation-${this.uniqueCreationKey}`;
    }

    return `empty-rig-operation-${this.uniqueCreationKey}`;
  }

  get start(): number {
    return this.x.start;
  }

  get end(): number {
    return this.x.end;
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }
}
