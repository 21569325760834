import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FiltersIcon } from 'src/shared/assets/icons/filters.svg';
import { IconButton } from 'src/shared/components/icon-button';
import { Search } from 'src/shared/components/search';

import styles from './chart-search.module.scss';

interface Props {
  onChange(searchTerm: string): void;
  onFiltersButtonClick: VoidFunction;
  value?: string;
  className?: string;
  highlightFiltersButton?: boolean;
}

export const ChartSearch = observer(function ChartSearch({
  className,
  value,
  onChange,
  onFiltersButtonClick,
  highlightFiltersButton = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={clsx(className, styles.container)}>
      <Search
        value={value}
        suffix={
          <IconButton
            icon={<FiltersIcon className={styles.icon} />}
            className={clsx(styles.button, highlightFiltersButton && styles.buttonHighlighted)}
            onClick={onFiltersButtonClick}
          />
        }
        placeholder={t('search')}
        className={styles.search}
        onSearch={onChange}
      />
    </div>
  );
});
