import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler, PointerEventHandler, ReactNode } from 'react';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import Card from 'src/shared/components/card/card';
import { useOnClick } from 'src/shared/hooks/use-on-click';

import { LoadingTitle } from '../../shared/components/loading-title/loading-title';
import { LoadingField } from '../../shared/utils/loading-field';

import styles from './styles.module.scss';

interface Props {
  title: string | LoadingField;
  attributes: string[] | LoadingField[] | null;
  color?: string;
  icons?: ReactNode;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  withDeleteButton?: boolean;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onPointerDown?: PointerEventHandler<HTMLDivElement>;
  onPointerUp?: PointerEventHandler<HTMLDivElement>;
  onPointerMove?: PointerEventHandler<HTMLDivElement>;
  onPointerCancel?: PointerEventHandler<HTMLDivElement>;
  onDelete?(): void;
}

export const DrillingElementColoredCard = observer(function DrillingElementColoredCard({
  title,
  color,
  attributes,
  icons,
  className,
  contentClassName,
  headerClassName,
  onMouseOver,
  onMouseLeave,
  onPointerDown,
  onPointerUp,
  onPointerMove,
  onPointerCancel,
  onDelete,
  withDeleteButton = false,
}: Props) {
  const deleteButtonListeners = useOnClick(() => onDelete?.());

  const header = (
    <div className={styles.cardHeaderWrapper}>
      {!!color && <div className={styles.cardColoredBorder} style={{ backgroundColor: color }} />}

      <div className={clsx(headerClassName, styles.cardHeader)}>
        {withDeleteButton && (
          <button className={styles.cardHeaderDeleteButton} {...deleteButtonListeners}>
            <DeleteIcon className={styles.cardHeaderDeleteButtonIcon} />
          </button>
        )}

        <LoadingTitle
          field={title}
          as="h3"
          className={styles.cardHeaderTitle}
          loadingClassName={styles.cardHeaderLoadingTitle}
        />

        {icons}
      </div>
    </div>
  );

  const attributesRender = () => {
    return attributes?.map((attribute, index) => (
      <LoadingTitle
        key={index}
        field={attribute}
        as="p"
        className={styles.cardText}
        loadingClassName={styles.loadingField}
      />
    ));
  };

  return (
    <Card
      header={header}
      className={clsx(className, styles.card)}
      contentClassName={clsx(contentClassName, styles.cardInner)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onPointerCancel={onPointerCancel}
    >
      {attributesRender()}
    </Card>
  );
});
