import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { WellTypeIcon } from 'src/features/well-type-icon';

import { DrillingElementColoredCard } from '../../../drilling-element-colored-card';
import { useSortableGhost } from '../../features/sorting/hooks/use-sortable-ghost';
import { SortableGhostItem } from '../../features/sorting/sortable-ghost-item';
import { WellRigOperation } from '../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';

import styles from './well-rig-operation-wrapper.module.scss';

interface Props {
  item: SortableGhostItem<WellRigOperation>;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  className?: string;
  cardClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
}

export const GhostWellRigOperationWrapper = observer(function GhostWellRigOperationWrapper({
  item: ghostWrapper,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
  className,
  cardClassName,
  contentClassName,
  headerClassName,
}: Props) {
  const { transform } = useSortableGhost({
    id: ghostWrapper.activeKey,
  });

  const ref = useMovableElement({
    item: ghostWrapper,
    horizontalViewport,
    verticalViewport,
    transform,
  });

  if (!ghostWrapper.options?.isShown) {
    return null;
  }

  const wellIcon = viewSettingsProvider.getWellIcon(ghostWrapper.ghost.data);

  return (
    <div ref={ref} className={clsx(className, styles.dataItem, styles.dragging)}>
      <DrillingElementColoredCard
        title={viewSettingsProvider.getTitle(ghostWrapper.ghost)}
        color={viewSettingsProvider.getWellColor(ghostWrapper.ghost.data) || ''}
        attributes={viewSettingsProvider.getAttributes(ghostWrapper.ghost)}
        icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
        className={classNames(cardClassName, styles.wellCard)}
        contentClassName={contentClassName}
        headerClassName={headerClassName}
      />
    </div>
  );
});
