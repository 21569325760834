import { assert } from 'src/shared/utils/assert';
import { addCommonPrefix } from 'src/shared/utils/prefixes';
import { WellTypes } from 'src/types/well-types';

import { Range } from '../../../layers/model';
import {
  ComparingChartWell,
  MainComparingChartWell,
  ComparingWellRigOperationStage,
} from '../../../presets/comparing-drilling-wells-chart/entities';
import {
  ChartWell,
  LoadingRigOperations,
  WellRigOperation,
  WellsGroup,
} from '../../../presets/drilling-wells-chart/entities';
import { DataView } from '../../../shared/data-view/data-view';
import { ViewFrameController } from '../../../shared/view-frame-controller';
import { ComparingWellsData, ComparingWellsDataStorage } from '../../comparing-wells-chart/data';

import { WellsChartDataModel } from './wells-chart-data-model';

export class WellsDataPositionsCalculator {
  private readonly elementSizesGetters: WellsChartDataModel.IRigsChartElementSizesGetters;

  constructor(elementSizesGetters: WellsChartDataModel.IRigsChartElementSizesGetters) {
    this.elementSizesGetters = elementSizesGetters;
  }

  private calculateWellsGroupPosition(
    previousItem: WellsGroup | null,
    isCollapsed: boolean,
    isCompactView: boolean,
    shownStageAttributesNumber: number
  ): Range<number> {
    if (!previousItem) {
      return { start: 0, end: this.elementSizesGetters.wellsGroup() };
    }

    if (isCollapsed) {
      const start = previousItem.rowsY.end + this.elementSizesGetters.wellsGroupsMargin();

      return {
        start: start,
        end: start + this.elementSizesGetters.wellsGroup(),
      };
    }

    const previousGroupChildrenCount = previousItem.items.reduce((childrenCount: number, currentWell) => {
      if (currentWell instanceof MainComparingChartWell) {
        return childrenCount + 2;
      }

      return childrenCount + 1;
    }, 0);

    if (isCompactView) {
      const previousItemEnd = previousItem.rowsY.end || previousItem.y.end + this.elementSizesGetters.wellCompact();

      const start = previousItemEnd + this.elementSizesGetters.wellsGroupsMargin() + 1;

      return {
        start: start,
        end: start + this.elementSizesGetters.wellsGroup(),
      };
    }

    const rowHeight = this.elementSizesGetters.rowHeight(shownStageAttributesNumber);

    const previousItemEnd = previousItem.rowsY.end || previousItem.y.end + rowHeight * previousGroupChildrenCount;

    const start = previousItemEnd + this.elementSizesGetters.wellsGroupsMargin() + 1;

    return {
      start: start,
      end: start + this.elementSizesGetters.wellsGroup(),
    };
  }

  private calculateWellPosition(
    previousItemY: Range<number> | null,
    parentItemY: Range<number> | null,
    isCollapsed: boolean,
    isCompactView: boolean,
    shownStageAttributesNumber: number
  ): Range<number> {
    if (isCollapsed) {
      return {
        start: parentItemY?.start ?? 0,
        end: parentItemY?.start ?? 0,
      };
    }

    const baseY = previousItemY || parentItemY || { start: 0, end: 0 };

    const start = baseY.end;

    if (isCompactView) {
      return {
        start,
        end: start + this.elementSizesGetters.wellCompact(),
      };
    }

    return {
      start,
      end: start + this.elementSizesGetters.rowHeight(shownStageAttributesNumber),
    };
  }

  calculateRigOperationStagePosition(
    parentItemY: Range<number>,
    isCollapsed: boolean,
    isCompactView: boolean,
    shownStageAttributesNumber: number
  ): Range<number> {
    if (isCollapsed) {
      return {
        start: parentItemY.start,
        end: parentItemY.start,
      };
    }

    const rowPadding = this.elementSizesGetters.rowPadding();

    if (isCompactView) {
      return {
        start: parentItemY.start + 1 + rowPadding,
        end: parentItemY.end + 1 - rowPadding * 2,
      };
    }

    const start = parentItemY.start + 1 + rowPadding;

    return {
      start,
      end: start + this.elementSizesGetters.drillingStageCard(shownStageAttributesNumber),
    };
  }

  calculatePositions(
    wellsGroups: WellsGroup[],
    dataViewType: DataView.DataViewType,
    shownStageAttributesNumber: number,
    setElementToFrame: (element: WellsChartDataModel.ViewItemContent, position: ViewFrameController.Position) => void
  ): void {
    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;
    let previousGroup: WellsGroup | null = null;

    for (const wellsGroup of wellsGroups) {
      const wellsGroupY = this.calculateWellsGroupPosition(
        previousGroup,
        wellsGroup.isCollapsed,
        isCompactView,
        shownStageAttributesNumber
      );
      wellsGroup.setY(wellsGroupY);

      const { items: wells } = wellsGroup;

      let previousWell: ChartWell | null = null;
      let rowsEnd = wellsGroupY.end;

      for (const well of wells) {
        const wellY = this.calculateWellPosition(
          previousWell?.y || null,
          wellsGroupY,
          wellsGroup.isCollapsed,
          isCompactView,
          shownStageAttributesNumber
        );
        well.setY(wellY);

        rowsEnd = Math.max(wellsGroupY.end, wellY.end);

        const rigOperations = well.items;

        if (rigOperations) {
          for (const rigOperation of rigOperations) {
            if (rigOperation instanceof WellRigOperation && rigOperation.items) {
              for (const stage of rigOperation.items) {
                const stageY = this.calculateRigOperationStagePosition(
                  wellY,
                  wellsGroup.isCollapsed,
                  isCompactView,
                  shownStageAttributesNumber
                );

                stage.setY(stageY);

                setElementToFrame(stage, { x: stage.x, y: stageY });

                if (
                  well instanceof ComparingChartWell &&
                  !well.hasChanges &&
                  stage instanceof ComparingWellRigOperationStage &&
                  stage.hasChanges
                ) {
                  well.setHasChanges(true);
                }
              }
            }

            if (rigOperation instanceof LoadingRigOperations) {
              const rigOperationY = this.calculateRigOperationStagePosition(
                wellY,
                wellsGroup.isCollapsed,
                isCompactView,
                shownStageAttributesNumber
              );

              rigOperation.setY(rigOperationY);

              setElementToFrame(rigOperation, { x: rigOperation.x, y: rigOperationY });
            }
          }
        }

        setElementToFrame(well, { y: wellY });

        previousWell = well;
      }

      wellsGroup.setRowsY({ start: wellsGroup.rowsY.start, end: rowsEnd });

      setElementToFrame(wellsGroup, { y: { start: wellsGroupY.start, end: rowsEnd } });

      previousGroup = wellsGroup;
    }
  }

  calculateWellPositionsRecursively(
    parent: WellsGroup,
    well: ChartWell,
    previousWell: ChartWell | null,
    isCompactView: boolean,
    shownStageAttributesNumber: number
  ): void {
    const wellY = this.calculateWellPosition(
      previousWell?.y || null,
      parent.y,
      parent.isCollapsed,
      isCompactView,
      shownStageAttributesNumber
    );
    well.setY(wellY);

    const rigOperations = well.items;

    if (rigOperations) {
      for (const rigOperation of rigOperations) {
        if (rigOperation instanceof WellRigOperation && rigOperation.items) {
          for (const stage of rigOperation.items) {
            const stageY = this.calculateRigOperationStagePosition(
              wellY,
              parent.isCollapsed,
              isCompactView,
              shownStageAttributesNumber
            );

            stage.setY(stageY);

            if (
              well instanceof ComparingChartWell &&
              !well.hasChanges &&
              stage instanceof ComparingWellRigOperationStage &&
              stage.hasChanges
            ) {
              well.setHasChanges(true);
            }
          }
        }

        if (rigOperation instanceof LoadingRigOperations) {
          const rigOperationY = this.calculateRigOperationStagePosition(
            wellY,
            parent.isCollapsed,
            isCompactView,
            shownStageAttributesNumber
          );

          rigOperation.setY(rigOperationY);
        }
      }
    }
  }

  calculateComparingWellPositionsRecursively(
    well: ChartWell,
    previousWell: ChartWell | null,
    isCompactView: boolean,
    shownStageAttributesNumber: number,
    setElementToFrame: ViewFrameController.SetElementToFrameFn<ComparingWellsData.ViewItem>
  ): void {
    const wellY = this.calculateWellPosition(
      previousWell?.y || null,
      null,
      false,
      isCompactView,
      shownStageAttributesNumber
    );
    well.setY(wellY);

    const rigOperations = well.items;

    if (rigOperations) {
      for (const rigOperation of rigOperations) {
        if (rigOperation instanceof WellRigOperation && rigOperation.items) {
          for (const stage of rigOperation.items) {
            const stageY = this.calculateRigOperationStagePosition(
              wellY,
              false,
              isCompactView,
              shownStageAttributesNumber
            );

            stage.setY(stageY);

            if (
              well instanceof ComparingChartWell &&
              !well.hasChanges &&
              stage instanceof ComparingWellRigOperationStage &&
              stage.hasChanges
            ) {
              well.setHasChanges(true);
            }

            setElementToFrame(stage, { x: stage.x, y: stageY });
          }
        }

        if (rigOperation instanceof LoadingRigOperations) {
          const rigOperationY = this.calculateRigOperationStagePosition(
            wellY,
            false,
            isCompactView,
            shownStageAttributesNumber
          );

          rigOperation.setY(rigOperationY);

          setElementToFrame(rigOperation, { x: rigOperation.x, y: rigOperationY });
        }
      }
    }

    setElementToFrame(well, { y: wellY });
  }

  calculatePositionsWithComparing(
    wells: ChartWell[],
    dataViewType: DataView.DataViewType,
    shownStageAttributesNumber: number,
    setElementToFrame: ViewFrameController.SetElementToFrameFn<ComparingWellsData.ViewItem>
  ): void {
    const isCompactView =
      dataViewType === DataView.DataViewType.compact || dataViewType === DataView.DataViewType.empty;

    let previousWell: ChartWell | null = null;

    for (const well of wells) {
      this.calculateComparingWellPositionsRecursively(
        well,
        previousWell,
        isCompactView,
        shownStageAttributesNumber,
        setElementToFrame
      );

      previousWell = well;

      if (well instanceof MainComparingChartWell) {
        const comparingWell = well.comparingPair;

        this.calculateComparingWellPositionsRecursively(
          comparingWell,
          well,
          isCompactView,
          shownStageAttributesNumber,
          setElementToFrame
        );

        previousWell = comparingWell;
      }
    }
  }

  initWellsGroupsWithPositions(
    wellGroups: WellsGroup[],
    dataViewType: DataView.DataViewType,
    shownStageAttributesNumber: number,
    setElementToFrame: (element: WellsChartDataModel.ViewItemContent, position: ViewFrameController.Position) => void
  ): WellsGroup[] {
    const isCompactView = dataViewType === DataView.DataViewType.compact;

    let previousGroup: WellsGroup | null = null;

    for (const wellsGroup of wellGroups) {
      const groupY = this.calculateWellsGroupPosition(
        previousGroup,
        wellsGroup.isCollapsed,
        isCompactView,
        shownStageAttributesNumber
      );

      let previousWell: ChartWell | null = null;

      let rowsEnd = groupY.end;

      for (const well of wellsGroup.items) {
        const wellY = this.calculateWellPosition(
          previousWell?.y || null,
          groupY,
          wellsGroup.isCollapsed,
          isCompactView,
          shownStageAttributesNumber
        );

        rowsEnd = Math.max(rowsEnd, wellY.end);

        well.setY(wellY);

        setElementToFrame(well, { y: wellY });

        previousWell = well;
      }

      wellsGroup.setY(groupY);
      wellsGroup.setRowsY({
        start: groupY.end,
        end: rowsEnd,
      });

      setElementToFrame(wellsGroup, { y: { start: groupY.start, end: rowsEnd } });

      previousGroup = wellsGroup;
    }

    return wellGroups;
  }

  initComparingWellsGroups(
    wells: WellTypes.RawWell[],
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    dataViewType: DataView.DataViewType,
    shownStageAttributesNumber: number,
    setElementToFrame: ViewFrameController.SetElementToFrameFn<ComparingWellsData.ViewItem>,
    sourceRefs: ComparingWellsDataStorage.SourceRefs
  ): ChartWell[] {
    const wellsWithPosition: ChartWell[] = [];
    const isCompactView = dataViewType === DataView.DataViewType.compact;

    let previousWell: ChartWell | null = null;

    for (const rawWell of wells) {
      const nameFieldName = addCommonPrefix('Well.name');
      const wellIdFieldValue = rawWell[sourceRefs.wellIdFieldName];

      assert(typeof wellIdFieldValue === 'number', `Invalid id in element named ${rawWell[nameFieldName]}`);

      const wellY = this.calculateWellPosition(
        previousWell?.y || null,
        null,
        false,
        isCompactView,
        shownStageAttributesNumber
      );
      const comparingWellY = this.calculateWellPosition(wellY, null, false, isCompactView, shownStageAttributesNumber);

      // Second well in comparing pair.
      const comparingWellWithPosition = new ComparingChartWell(rawWell, wellIdFieldValue);

      comparingWellWithPosition.setY(comparingWellY);

      setElementToFrame(comparingWellWithPosition, { y: comparingWellY });

      // First well in comparing pair.
      const wellWithPosition = new MainComparingChartWell(rawWell, wellIdFieldValue, comparingWellWithPosition);

      wellWithPosition.setY(wellY);

      setElementToFrame(wellWithPosition, { y: wellY });

      previousWell = comparingWellWithPosition;
      wellsWithPosition.push(wellWithPosition);
    }

    return wellsWithPosition;
  }
}

export namespace WellsDataPositionsCalculator {
  export const DEFAULT_IS_COLLAPSED = true;

  export const DEFAULT_ELEMENT_SIZES_GETTERS: WellsChartDataModel.IRigsChartElementSizesGetters = {
    wellsGroup: () => 28,
    wellCompact: () => 40,
    wellFullMin: () => 76,
    drillingStageCard: (attributesCount: number) => attributesCount * 16 + 40,
    rowPadding: () => 4,
    rowHeight: (drillingStepFieldsCount: number) => {
      const wellHeightMin = DEFAULT_ELEMENT_SIZES_GETTERS.wellFullMin();
      const drillingStepHeight = DEFAULT_ELEMENT_SIZES_GETTERS.drillingStageCard(drillingStepFieldsCount);
      const rowPaddings = DEFAULT_ELEMENT_SIZES_GETTERS.rowPadding() * 2;

      return Math.max(wellHeightMin, drillingStepHeight + rowPaddings);
    },
    wellsGroupsMargin: () => 2,
  };

  export const EDITING_ELEMENT_SIZES_GETTERS: WellsChartDataModel.IRigsChartElementSizesGetters = {
    wellsGroup: () => 36,
    wellCompact: () => 40,
    wellFullMin: () => 76,
    drillingStageCard: (attributesCount: number) => attributesCount * 16 + 40,
    rowPadding: () => 4,
    rowHeight: (drillingStepFieldsCount: number) => {
      const wellHeightMin = EDITING_ELEMENT_SIZES_GETTERS.wellFullMin();
      const drillingStepHeight = EDITING_ELEMENT_SIZES_GETTERS.drillingStageCard(drillingStepFieldsCount);
      const rowPaddings = EDITING_ELEMENT_SIZES_GETTERS.rowPadding() * 2;

      return Math.max(wellHeightMin, drillingStepHeight + rowPaddings);
    },
    wellsGroupsMargin: () => 2,
  };
}
