import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { useStore } from 'src/store';

import styles from '../drilling-rig-sidebar/views/drilling-rig-sidebar.module.scss';

import { ControlBar } from './control-bar';
import { Pads } from './pads';

export const DrillingRigConflictsSidebar = observer(function DrillingRigConflictsSidebar() {
  const { rigConflictSidebar: store } = useStore();

  const { t } = useTranslation('drawers');

  const isLoading = store.isLoading || store.rigStore?.isLoading || false;

  return (
    <Sidebar
      isOpened={store.isOpen}
      onClose={store.onCancel}
      closeOnClickOutside
      title={store.rigTitle || t('drillingRigDrawer.drillingRig')}
      size="l"
    >
      <ControlBar isLoading={isLoading} onApply={store.onApply} onCancel={store.onCancel} />

      <div className={styles.contentContainer}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}

        {!isLoading && !!store.rigStore && !!store.viewProvider && (
          <Pads store={store.rigStore} viewProvider={store.viewProvider} />
        )}
      </div>
    </Sidebar>
  );
});
