import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { FormStore } from 'src/features/well-form/entities/form.entity';
import { FormElement } from 'src/features/well-form/form-element';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { useStore } from 'src/store';

import styles from './approach-field.module.scss';

type Props = {
  firstElement?: boolean;
  item: Item;
  formStore: FormStore;
};

export const ApproachField = observer(function ApproachField({ firstElement, item, formStore }: Props) {
  const { directories, i18 } = useStore();
  const label = directories.getFieldLabel(item.fieldId) || i18.t('errors:unrecognizedField');

  return (
    <div className={clsx(styles.itemWrapper, firstElement && styles.firstWrapper)}>
      <p className={styles.itemName}>{label}</p>
      <div className={clsx(firstElement ? styles.firstElement : styles.secondElement)}>
        <FormElement item={item} formStore={formStore} />
      </div>
    </div>
  );
});
