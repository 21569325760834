import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoseChangesModal } from 'src/features/modals/lose-changes-modal/lose-changes-modal';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as RoundArrowLeftIcon } from 'src/shared/assets/icons/round-arrow-left.svg';
import { Button } from 'src/shared/components/button/button';
import { MenuSubheaderLayout } from 'src/shared/layouts/menu-subheader-layout/menu-subheader-layout';

import { FormStore } from '../entities/form.entity';
import { WellFormProgressBar } from '../well-form-progress-bar';

import styles from './well-form-subheader.module.scss';

type Props = {
  formStore: FormStore | null;
  title: string;
  additionalHeaderComponent?: ReactNode;
  onSave(): void;
  onCancel(): void;
};

export const WellFormSubheader = observer(function WellFormSubheader({
  formStore,
  title,
  additionalHeaderComponent,
  onSave,
  onCancel,
}: Props) {
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const { t } = useTranslation();

  const [useInProgressberFieldsCount, filledFieldsCount] = formStore?.progressBarFieldsCount || [0, 0];

  const handleOpenConfirmModal = (): void => setIsConfirmModalOpened(true);
  const handleCloseConfirmModal = (): void => setIsConfirmModalOpened(false);

  return (
    <>
      <MenuSubheaderLayout classname={styles.subheaderLayout}>
        <div className={styles.titleWrapper}>
          <Button variant="text" onClick={handleOpenConfirmModal}>
            <RoundArrowLeftIcon className={styles.arrowIcon} />
            {t('common:Buttons.back')}
          </Button>
          <h3 className={styles.title}>{title}</h3>
          {additionalHeaderComponent}
        </div>
        {!!formStore && (
          <>
            <WellFormProgressBar
              useInProgressberFieldsCount={useInProgressberFieldsCount}
              filledFieldsCount={filledFieldsCount}
            />
            <div className={styles.buttonsWRapper}>
              <Button prefixIcon={<CrossIcon />} variant="danger" onClick={handleOpenConfirmModal}>
                {t('common:Buttons.cancel')}
              </Button>
              <Button
                prefixIcon={<CheckIcon />}
                disabled={!formStore.validationManager.isAllNecessaryControlsAreFilled}
                variant="success"
                onClick={onSave}
              >
                {t('common:Buttons.save')}
              </Button>
            </div>
          </>
        )}
      </MenuSubheaderLayout>
      <LoseChangesModal
        isOpened={isConfirmModalOpened}
        handleClose={handleCloseConfirmModal}
        confirmCallback={onCancel}
      />
    </>
  );
});
