import { AxiosError } from 'axios';

import { isAxiosError } from 'src/api/utils';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';

export abstract class BaseApiError extends Error {
  axiosError: AxiosError | null = null;
  responseMessage: string | null;

  constructor(error?: unknown) {
    super();
    if (isAxiosError(error)) {
      this.axiosError = error;
      this.responseMessage = this.getResponseMessage();
    } else {
      this.responseMessage = null;
    }
  }

  private checkMessageObj(data: unknown): data is { message: string | string[] } {
    return isObjectWithKeys(data) && 'message' in data;
  }

  private getResponseMessage(): string | null {
    const data = this.axiosError?.response?.data;

    if (!this.checkMessageObj(data)) {
      return null;
    }

    const messageRaw = data['message'];
    if (Array.isArray(messageRaw)) {
      if (typeof messageRaw[0] === 'string') {
        return messageRaw[0];
      }
    }
    if (typeof messageRaw === 'string') {
      return messageRaw;
    }

    return null;
  }
}

export class ValidationError extends Error {}

export class Server500ApiError extends BaseApiError {}

export class Unauthorized401ApiError extends BaseApiError {}

export class Validation400ApiError extends BaseApiError {}

export class NetworkApiError extends BaseApiError {}

export class UnknownApiError extends BaseApiError {}

export class NotFoundApiError extends BaseApiError {}

export class Validation400ApiErrorWithCause<T> extends Validation400ApiError {
  readonly reason: T;

  constructor(error: unknown, reason: T, message?: string) {
    super(error);
    if (message) {
      this.message = message;
    }
    this.reason = reason;
  }
}

export class NotFoundError extends Error {}
