import { computed, makeObservable } from 'mobx';

import { RigsChartViewTypes } from 'src/api/chart/rigs-chart-api';
import { Summary } from 'src/api/chart/summary-data-api';
import { Directories } from 'src/store/directories/directories.store';

export class SummaryViewProvider {
  private readonly view: RigsChartViewTypes.Summary;
  private readonly directories: Directories;

  constructor(view: RigsChartViewTypes.Summary, directories: Directories) {
    this.view = view;
    this.directories = directories;

    makeObservable(this);
  }

  @computed
  get headerColumns(): string[] {
    return Summary.RowValuesFieldNames;
  }

  @computed
  get columns(): RigsChartViewTypes.SummaryHeader[] {
    return Summary.RowValuesFieldNames.map((field) => ({ name: field, label: field }));
  }

  @computed
  get rows(): RigsChartViewTypes.SummaryHeader[] {
    return this.view.rigGroup.table.items.map(({ attrName, fieldId }) => ({
      name: attrName,
      label: this.directories.getFieldLabel(fieldId) || '',
    }));
  }

  @computed
  get columnsWidthPercentage(): number {
    // Add 1 for row header column (first column).
    return 100 / (this.headerColumns.length + 1);
  }
}
