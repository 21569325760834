import { observer } from 'mobx-react-lite';

import {
  InsertionPoint,
  InteractiveInsertionPoint,
  LoadingRigOperations,
  PadRigOperation,
  TemporaryPadRigOperation,
  TemporaryWellRigOperation,
  WellRigOperation,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { RigOperationsDnd } from '../../../presets/rig-operations-dnd/rig-operations-dnd-module';
import { Viewport } from '../../../shared/viewport/viewport';
import { InsertionPointNeighbor } from '../../editing/shared/entities/insertion-point-neighbor';
import {
  InsertionPoint as InsertionPointComponent,
  InteractiveInsertionPoint as InteractiveInsertionPointComponent,
} from '../../editing/view/components/insertion-point';
import { GhostPadRigOperationWrapper as SortableGhostPadWrapper } from '../../pad-rig-operation-wrapper/ghost-pad-rig-operation-wrapper';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';
import { ShadowPadRigOperation } from '../presenter/shadow-pad-rig-operation';
import { ShadowWellRigOperation } from '../presenter/shadow-well-rig-operation';

import { DraggableGhostPadRigOperationWrapper } from './draggable-ghost-pad-rig-operation-wrapper';
import { DraggablePadRigOperationWrapper } from './draggable-pad-rig-operation-wrapper';
import { DraggableWellWrapper } from './draggable-well-wrapper';
import { GhostWellRigOperationWrapper } from './ghost-well-rig-operation-wrapper';
import { LoadingRigOperationsWrapper } from './loading-rig-operations-wrapper';
import { ShadowPadRigOperationWrapper } from './shadow-pad-rig-operation-wrapper';
import { ShadowWellRigOperationWrapper } from './shadow-well-rig-operation-wrapper';
import { SortableGhostWellRigOperationWrapper } from './sortable-ghost-well-rig-operation-wrapper';
import { TemporaryPadRigOperationWrapper } from './temporary-pad-rig-operation-wrapper';
import { TemporaryWellRigOperationWrapper } from './temporary-well-rig-operation-wrapper';

import styles from './data-items-full.module.scss';

interface Props {
  item: RigsChartStore.ViewItem;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick(padId: number, rigId: number): void;
  onWellClick(wellId: string | number): void;
  onRemoveRigOperation(item: WellRigOperation): void;
  onRemoveRigOperationsList(item: PadRigOperation): void;
}

export const EditingRigsDataItem = observer(function EditingRigsDataItem({
  item,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  onWellClick,
  viewSettingsProvider,
  onRemoveRigOperation,
  onRemoveRigOperationsList,
}: Props) {
  if (item instanceof TemporaryPadRigOperation) {
    return (
      <TemporaryPadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof PadRigOperation) {
    return (
      <DraggablePadRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        onClick={() => onPadClick(item.id, item.parentRig.id)}
        viewSettingsProvider={viewSettingsProvider}
        onRemoveRigOperationsList={() => onRemoveRigOperationsList(item)}
      />
    );
  }

  if (item instanceof TemporaryWellRigOperation) {
    return (
      <TemporaryWellRigOperationWrapper
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        item={item}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof WellRigOperation) {
    return (
      <DraggableWellWrapper
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        item={item}
        onClick={() => onWellClick(item.id)}
        viewSettingsProvider={viewSettingsProvider}
        onRemoveRigOperation={() => onRemoveRigOperation(item)}
      />
    );
  }

  if (item instanceof InsertionPointNeighbor) {
    if (item.neighbor instanceof WellRigOperation) {
      const wellItem = item.neighbor;

      return (
        <DraggableWellWrapper
          horizontalViewport={horizontalViewport}
          verticalViewport={verticalViewport}
          item={wellItem}
          onClick={() => onWellClick(wellItem.id)}
          viewSettingsProvider={viewSettingsProvider}
        />
      );
    }

    if (item.neighbor instanceof PadRigOperation) {
      const padItem = item.neighbor;

      return (
        <DraggablePadRigOperationWrapper
          item={item.neighbor}
          horizontalViewport={horizontalViewport}
          verticalViewport={verticalViewport}
          onClick={() => onPadClick(padItem.id, padItem.parentRig.id)}
          viewSettingsProvider={viewSettingsProvider}
          className={styles.padItem}
          onRemoveRigOperationsList={() => onRemoveRigOperationsList(padItem)}
        />
      );
    }
  }

  if (item instanceof LoadingRigOperations) {
    return (
      <LoadingRigOperationsWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (item instanceof InteractiveInsertionPoint) {
    return (
      <InteractiveInsertionPointComponent
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (item instanceof InsertionPoint) {
    return (
      <InsertionPointComponent
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
      />
    );
  }

  if (RigOperationsDnd.isWellDraggableGhost(item) || RigOperationsDnd.isConflictWellDraggableGhost(item)) {
    return <GhostWellRigOperationWrapper item={item} viewSettingsProvider={viewSettingsProvider} />;
  }

  if (RigOperationsDnd.isPadDraggableGhost(item)) {
    return <DraggableGhostPadRigOperationWrapper item={item} viewSettingsProvider={viewSettingsProvider} />;
  }

  if (item instanceof ShadowPadRigOperation) {
    return (
      <ShadowPadRigOperationWrapper
        item={item}
        verticalViewport={verticalViewport}
        horizontalViewport={horizontalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (item instanceof ShadowWellRigOperation) {
    return (
      <ShadowWellRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (RigsSorting.isPadGhost(item)) {
    return (
      <SortableGhostPadWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  if (RigsSorting.isWellGhost(item)) {
    return (
      <SortableGhostWellRigOperationWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        viewSettingsProvider={viewSettingsProvider}
      />
    );
  }

  return null;
});
