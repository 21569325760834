import { RefCallback, RefObject, useContext, useEffect, useMemo, useRef } from 'react';

import { hasValue } from 'src/shared/utils/common';

import { SortableTransform } from '../sortable-transform';
import { SortingContext } from '../sorting-context';
import { SortingContextStore } from '../sorting-context.store';

export function useDragging<TDraggingItem>({
  id,
  bindingId,
  item,
  options,
}: UseDragging.Args<TDraggingItem>): UseDragging.Return {
  const { registerDragging, unregisterDragging, active } = useContext(SortingContext);

  const ref = useRef<HTMLElement>(null!);

  const activeTransform: SortableTransform | null = useMemo(() => {
    if (hasValue(bindingId) && active?.id === bindingId) {
      return active?.transform;
    }

    return null;
  }, [active, bindingId]);

  useEffect(() => {
    registerDragging(id, ref, item, options);

    return () => {
      unregisterDragging(id);
    };
  }, [id, item, ref, options, registerDragging, unregisterDragging]);

  useEffect(() => {
    if (bindingId === '') {
      console.warn('Empty ID of active draggable element.');
    }
  }, [bindingId]);

  return {
    ref,
    transform: activeTransform,
  };
}

export namespace UseDragging {
  export type Args<TDraggingItem> = {
    id: string;
    item: TDraggingItem;
    /** ID of active draggable that this is related with. Required to receive transform of active draggable. */
    bindingId?: string;
    options?: SortingContextStore.DraggingOptions;
  };

  export type Return = {
    ref: RefObject<HTMLElement> | RefCallback<HTMLElement>;
    transform: SortableTransform | null;
  };
}
