import { action, makeObservable, observable, reaction } from 'mobx';

import { hasValue } from 'src/shared/utils/common';

import { AutoScroll } from './auto-scroll.utils';

const MAX_ACCELERATION = 3;

export class AutoScrollController {
  private readonly horizontalViewport: AutoScrollController.Viewport;
  private readonly verticalViewport: AutoScrollController.Viewport;

  private scrollerId: number | null = null;
  private startScrollingTime: number | null = null;
  private prevScrollingTime: number | null = null;
  private scrollListeners: Set<AutoScrollController.onScrollFn> = new Set();

  @observable private direction: AutoScroll.Direction | null = null;
  @observable private distanceAcceleration: [number, number] | null = null;

  constructor(horizontalViewport: AutoScrollController.Viewport, verticalViewport: AutoScrollController.Viewport) {
    this.horizontalViewport = horizontalViewport;
    this.verticalViewport = verticalViewport;

    makeObservable(this);
  }

  /** Increments when pointer stays at one point. */
  private getHoldAcceleration(currentTime: number): number {
    if (this.startScrollingTime) {
      const acceleration = 1 + ((currentTime - this.startScrollingTime) / currentTime) * 20;

      return Math.min(acceleration, MAX_ACCELERATION);
    }

    return 1;
  }

  private getScrolledDistance(currentTime: number, distanceAcceleration: number): number {
    const holdAcceleration = this.getHoldAcceleration(currentTime);
    const prevTime = this.prevScrollingTime ?? this.startScrollingTime ?? 0;
    const timeDiff = currentTime - prevTime;

    return (timeDiff / 10) * distanceAcceleration * holdAcceleration;
  }

  private onScroll(horizontalOffset: number, verticalOffset: number): [number, number] {
    const initialHorizontalStart = this.horizontalViewport.start;
    const initialVerticalStart = this.verticalViewport.start;

    this.horizontalViewport.add(horizontalOffset);
    this.verticalViewport.add(verticalOffset);

    const horizontalDiff = this.horizontalViewport.start - initialHorizontalStart;
    const verticalDiff = this.verticalViewport.start - initialVerticalStart;

    return [horizontalDiff, verticalDiff];
  }

  private getScrolledPixelsByDirection(
    direction: AutoScroll.Direction,
    scrolledPerFrameHorizontally: number,
    scrolledPerFrameVertically: number
  ): [number, number] {
    if (['left', 'right'].includes(direction)) {
      const isOppositeDirection = direction === AutoScroll.Direction.left;

      return [scrolledPerFrameHorizontally * (isOppositeDirection ? -1 : 1), 0];
    }

    if (['top', 'bottom'].includes(direction)) {
      const isOppositeDirection = direction === AutoScroll.Direction.top;

      return [0, scrolledPerFrameVertically * (isOppositeDirection ? -1 : 1)];
    }

    if (direction === AutoScroll.Direction.topRight) {
      return [scrolledPerFrameHorizontally, -scrolledPerFrameVertically];
    }

    if (direction === AutoScroll.Direction.topLeft) {
      return [-scrolledPerFrameHorizontally, -scrolledPerFrameVertically];
    }

    if (direction === AutoScroll.Direction.bottomRight) {
      return [scrolledPerFrameHorizontally, scrolledPerFrameVertically];
    }

    if (direction === AutoScroll.Direction.bottomLeft) {
      return [-scrolledPerFrameHorizontally, scrolledPerFrameVertically];
    }

    return [0, 0];
  }

  @action.bound
  private scrollStep(currentTime: number): void {
    if (!this.direction || !this.distanceAcceleration?.length) {
      return;
    }

    if (!this.startScrollingTime) {
      this.startScrollingTime = currentTime;
    }

    this.scrollerId = requestAnimationFrame(this.scrollStep);

    const [horizontalAcceleration, verticalAcceleration] = this.distanceAcceleration;

    const scrolledPerFrameHorizontally = this.getScrolledDistance(currentTime, horizontalAcceleration);
    const scrolledPerFrameVertically = this.getScrolledDistance(currentTime, verticalAcceleration);

    this.prevScrollingTime = currentTime;

    const [scrolledX, scrolledY] = this.getScrolledPixelsByDirection(
      this.direction,
      scrolledPerFrameHorizontally,
      scrolledPerFrameVertically
    );

    const [actualXScrolled, actualYScrolled] = this.onScroll(scrolledX, scrolledY);
    this.scrollListeners.forEach((callback) => callback(actualXScrolled, actualYScrolled));
  }

  @action.bound
  private scroll(): void {
    if (hasValue(this.scrollerId)) {
      cancelAnimationFrame(this.scrollerId);
    }

    this.scrollerId = requestAnimationFrame(this.scrollStep);
  }

  init = (): VoidFunction => {
    const disposeScrolling = reaction(
      () => ({
        direction: this.direction,
        acceleration: this.distanceAcceleration,
      }),
      ({ direction, acceleration }, { direction: prevDirection }) => {
        if (direction !== prevDirection) {
          this.startScrollingTime = null;
          this.prevScrollingTime = null;
        }

        if (direction && acceleration) {
          this.scroll();
        } else {
          this.finishScrolling();
        }
      }
    );

    return () => {
      disposeScrolling();
    };
  };

  /**
   * @param direction {AutoScroll.Direction} Direction of scroll.
   * @param distanceAcceleration [horizontal acceleration, vertical acceleration].
   * */
  @action.bound
  startScrolling(direction: AutoScroll.Direction, distanceAcceleration: [number, number]): void {
    this.finishScrolling();

    this.direction = direction;
    this.distanceAcceleration = distanceAcceleration;
  }

  @action.bound
  finishScrolling(): void {
    if (hasValue(this.scrollerId)) {
      cancelAnimationFrame(this.scrollerId);

      this.scrollerId = null;
      this.direction = null;
      this.distanceAcceleration = null;
    }
  }

  addOnScrollListener(onScroll: AutoScrollController.onScrollFn): void {
    this.scrollListeners.add(onScroll);
  }

  removeOnScrollListener(onScroll: AutoScrollController.onScrollFn): void {
    this.scrollListeners.delete(onScroll);
  }
}

export namespace AutoScrollController {
  export interface Viewport {
    start: number;
    add(value: number): void;
  }

  export type onScrollFn = (scrolledX: number, scrolledY: number) => void;
}
