import { DragEndEvent } from '@dnd-kit/core';
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';

interface Props {
  children: React.ReactNode[];
  onDragEnd: (event: DragEndEvent) => void;
}

export const SortableBody = observer(function SortableBody({ children, onDragEnd, ...rest }: Props) {
  //TODO Типизирвать
  return (
    <SortableContext
      items={children[1] instanceof Array ? children[1].map((child: any) => child.key) : []}
      strategy={verticalListSortingStrategy}
    >
      <tbody {...rest}>{children}</tbody>
    </SortableContext>
  );
});
