import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';

import { WellsGroup } from './entities';

export function wellGroupsToOrderSettings(wellGroups: WellsGroup[]): ViewSettings.WellsOrderSettingsValues {
  const groupsOrder: ViewSettings.WellsGroup[] = wellGroups.map((group) => ({
    groupId: group.id,
    isCollapsed: group.isCollapsed,
  }));

  return {
    groups: groupsOrder,
  };
}
