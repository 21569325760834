import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { WellTooltipInner } from 'src/features/drilling-chart/components/well-rig-operation-wrapper/well-tooltip-inner';
import { ChartTooltip } from 'src/features/drilling-chart/features/chart-tooltip/chart-tooltip';
import { DateHelper } from 'src/features/drilling-chart/shared/date-helper';
import { useIsOutOfViewport } from 'src/shared/hooks/use-is-out-of-viewport';
import { hasValue } from 'src/shared/utils/common';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { DrillingElementColoredCard } from '../../../../drilling-element-colored-card';
import { WellTypeIcon } from '../../../../well-type-icon';
import { WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { DndContextStore } from '../../editing/shared/entities/dnd-context.store';
import { useDraggable } from '../../editing/view/hooks/use-draggable';
import { useDragging } from '../../editing/view/hooks/use-dragging';
import { useDragging as useSortableDragging } from '../../sorting/hooks/use-dragging';

import styles from './data-items-full.module.scss';

interface Props {
  item: WellRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onClick?: VoidFunction;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  onRemoveRigOperation?: VoidFunction;
}

const options: DndContextStore.DraggingOptions = {
  draggableClassName: styles.draggingItem,
};

export const DraggableWellWrapper = observer(function DraggableWellWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  onClick,
  viewSettingsProvider,
  className,
  contentClassName,
  headerClassName,
  onRemoveRigOperation,
}: Props) {
  const { listeners } = useDraggable({
    id: item.getKey('draggable'),
    dataItem: item,
    onClick,
    options,
  });

  // To be dragged with sortable row.
  const { ref: draggingRef } = useSortableDragging({
    id: item.getKey('dragging'),
    item,
  });

  const isOutOfViewport = useIsOutOfViewport(item, horizontalViewport);

  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });

  const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

  useDragging({
    id: item.getKey('dragging'),
    item,
    nodeRef: ref,
  });

  const wellIcon = viewSettingsProvider.getWellIcon(item.data);

  const rigOperationStart = viewSettingsProvider.getRigOperationStartUnix(item);

  const isRemovableRigOperation = hasValue(rigOperationStart) && rigOperationStart > DateHelper.dateToUnix(new Date());

  return (
    <ChartTooltip
      content={<WellTooltipInner well={item} viewProvider={viewSettingsProvider} />}
      placement={tooltipPlacement}
    >
      {({ handleMouseOver, handleMouseLeave }) => {
        return (
          <div ref={mergeRefs(ref, draggingRef)} className={styles.dataItem} {...listeners}>
            <DrillingElementColoredCard
              title={viewSettingsProvider.getTitle(item)}
              color={viewSettingsProvider.getWellColor(item.data) || ''}
              attributes={viewSettingsProvider.getAttributes(item)}
              icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
              className={classNames(className, styles.wellCard)}
              contentClassName={contentClassName}
              headerClassName={headerClassName}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              onDelete={isRemovableRigOperation ? onRemoveRigOperation : undefined}
              withDeleteButton={isRemovableRigOperation}
            />
          </div>
        );
      }}
    </ChartTooltip>
  );
});
