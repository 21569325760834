import { autorun } from 'mobx';
import { useLayoutEffect, useRef } from 'react';

import { hasValue } from '../../../shared/utils/common';
import { getSizeString } from '../components/timeline-chart/utils';
import { DraggableTransform } from '../features/editing/shared/entities/draggable-transform';
import { Dnd } from '../features/editing/types';
import { SortableTransform } from '../features/sorting/sortable-transform';
import { Range } from '../layers/model';

import {
  calculateItemOffsetParams,
  getOffsetPx,
  getSizePx,
  millisecondsToPixels,
} from './viewport/viewport-calculator';

type Options = Partial<{
  marginTop: boolean;
  marginLeft: boolean;
  fixY: boolean;
  initialOffsetX: number;
}>;

interface UseMovableItemParams {
  item: { x?: Range<number | null> | null; y?: Range<number | null> | null; minStartX?: number };
  id?: string;
  transform?: DraggableTransform | SortableTransform;
  horizontalViewport?: Range<number>;
  verticalViewport?: Range<number>;
  options?: Options;
  containerWidth?: number;
  getOffsetY?(verticalViewport: Range<number>, y: Range<number>): string;
  getRelativeTransform?(coordinates: Partial<Dnd.Coordinates>): Dnd.Coordinates;
}

export const useMovableElement = ({
  item,
  id,
  transform,
  horizontalViewport,
  verticalViewport,
  getOffsetY,
  options,
  getRelativeTransform,
  containerWidth,
}: UseMovableItemParams) => {
  const ref = useRef<HTMLDivElement>(null);

  const withMarginTop = options?.marginTop ?? true;
  const withMarginLeft = options?.marginLeft ?? true;

  useLayoutEffect(() => {
    const disposeHorizontalPositionCalculating = autorun(() => {
      if (ref.current) {
        const { x, minStartX } = item;

        if (horizontalViewport) {
          const xStart = x?.start ?? horizontalViewport.start;
          const xEnd = x?.end ?? horizontalViewport.end;

          const { width, offset: offsetX } = calculateItemOffsetParams(horizontalViewport, {
            start: hasValue(minStartX) ? Math.max(xStart, minStartX) : xStart,
            end: xEnd,
          });

          ref.current.style.width = getSizeString(width);

          if (withMarginLeft) {
            ref.current.style.marginLeft = getSizeString(offsetX);
          }
        }
      }
    });

    return () => {
      disposeHorizontalPositionCalculating();
    };
  }, [horizontalViewport, item, withMarginLeft]);

  useLayoutEffect(() => {
    const disposeVerticalPositionCalculating = autorun(() => {
      if (ref.current) {
        const { y } = item;

        if (verticalViewport && y && hasValue(y?.start) && hasValue(y?.end)) {
          ref.current.style.height = getSizeString(
            getSizePx({
              start: y.start,
              end: y.end,
            })
          );

          const offsetY =
            getOffsetY?.(verticalViewport, {
              start: y.start,
              end: y.end,
            }) ||
            getSizeString(
              getOffsetPx(verticalViewport, {
                start: y.start,
                end: y.end,
              })
            );

          if (withMarginTop) {
            ref.current.style.marginTop = getSizeString(offsetY);
          }
        }
      }
    });

    return () => {
      disposeVerticalPositionCalculating();
    };
  }, [getOffsetY, item, verticalViewport, withMarginTop]);

  useLayoutEffect(() => {
    const disposeTransformation = autorun(() => {
      if (ref.current && transform) {
        const { x } = item;

        const initialOffsetXPx =
          options?.initialOffsetX && horizontalViewport && x && x.start !== null && x.end !== null && containerWidth
            ? millisecondsToPixels(options.initialOffsetX, horizontalViewport, containerWidth)
            : 0;

        if (getRelativeTransform) {
          const { x, y } = getRelativeTransform(transform.value);
          const transformY = options?.fixY ? 0 : y;
          const transformX = initialOffsetXPx + x;

          ref.current.style.transform = `translate(${transformX}px, ${transformY}px)`;
        } else {
          const getY = (): number => {
            if (!options?.fixY) {
              return transform.value.y ?? 0;
            }

            return 0;
          };

          const getX = (): number => {
            return (initialOffsetXPx ?? 0) + transform.value.x;
          };

          ref.current.style.transform = `translate(${getX()}px, ${getY()}px)`;
        }
      }
    });

    return () => {
      disposeTransformation();
    };
  }, [
    containerWidth,
    getRelativeTransform,
    horizontalViewport,
    item,
    options?.fixY,
    options?.initialOffsetX,
    transform,
  ]);

  return ref;
};
