import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import moment from 'moment';

import { fetchDashboardTrend, getFiltersFormControlsAndItsDirectories } from 'src/api/dashboard/dashboard-api';
import { DashboardTrendResponse, GroupingValue, TrendTypeValue } from 'src/api/dashboard/types';
import { BaseApiError } from 'src/errors';
import { Range } from 'src/features/drilling-chart/layers/model';
import { TimeUnit } from 'src/features/drilling-chart/shared/time-unit';
import { debounce } from 'src/shared/utils/debounce';
import { isNumberArray } from 'src/shared/utils/is-number-array';
import { isStringArray } from 'src/shared/utils/is-string-array';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';
import { FilterForm } from 'src/store/filter-form/filter-form';
import { GlobalVariables } from 'src/store/global-variables/global-variables';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { ThemeStore } from 'src/store/theme/theme-store';

import { IDResolver } from '../../utils/id-resolver';
import { MonthResolver } from '../../utils/monthResolver';
import { PlanVersionResolver } from '../../utils/plan-version-resolver';
import { QuarterResolver } from '../../utils/quarterResolver';
import { TimelineStore } from '../../utils/timeline.store';
import { ILabelResolver } from '../../utils/types';
import { ChartEntity } from '../chart/entity/chart.entity';

import { PLAN_VERSION_GROUP } from './dashboard.consts';
import { ChartData, ChartType } from './types';
import { mapGroupingValues, mapTrendData, validateChartType } from './utils';

export class DashboardStore {
  private trend?: DashboardTrendResponse;

  private readonly globalVariables: GlobalVariables;
  private readonly notifications: NotificationsStore;
  private readonly directories: Directories;
  private readonly theme: ThemeStore;
  private readonly planVersionResolver: PlanVersionResolver;
  private readonly rootStore: RootStore;

  readonly timeline: TimelineStore;

  @observable idResolvers?: IDResolver[];
  @observable isFirstLoading: boolean = true;
  @observable trendData?: ChartData[];
  @observable charts?: ChartEntity[];
  @observable isLoading: boolean = false;
  @observable isUpdating: boolean = false;
  @observable filterForm?: FilterForm;
  @observable isAccumulate = false;
  @observable chartType: ChartType = ChartType.column;
  @observable grouping?: DashboardStore.GroupingValueWithLabel[];
  @observable selectedGroup?: string;
  @observable range: Range<number>;
  @observable granularity: TimeUnit;
  @observable groupingView: GroupingValue[] = [];
  @observable chartNames?: TrendTypeValue[];

  constructor(rootStore: RootStore) {
    this.notifications = rootStore.notifications;
    this.directories = rootStore.directories;
    this.theme = rootStore.theme;
    this.globalVariables = rootStore.globalVariables;
    this.rootStore = rootStore;
    this.timeline = new TimelineStore();
    this.granularity = this.timeline.selectedPeriod;
    this.planVersionResolver = new PlanVersionResolver(rootStore);

    this.range = { start: this.timelineRange.start, end: this.timelineRange.end };
    makeObservable(this);
  }

  @computed
  get filterFormAndChartValues(): Record<string, unknown> | null {
    if (!this.filterForm || !this.selectedGroup || !this.chartNames) {
      return null;
    }

    const collectedFieldsValues: Record<string, unknown> = {};

    for (let field of this.filterForm.fieldsArray) {
      const { value } = field;

      if (!field.formElementRefId) continue;

      if (isNumberArray(value) || isStringArray(value)) {
        if (value.length > 0) {
          collectedFieldsValues[field.formElementRefId] = field.value;
        }
      } else {
        collectedFieldsValues[field.formElementRefId] = field.value;
      }
    }

    const availableCharts: string[] = [];

    for (const chartView of this.chartNames) {
      if (chartView.settings?.isHidden) {
        continue;
      }
      availableCharts.push(chartView.value);
    }

    const result: Record<string, unknown> = {
      trendGranularity: this.period,
      trendTypes: availableCharts,
      from: moment.unix(this.dateRange.start).format('YYYY-MM-DD'),
      to: moment.unix(this.dateRange.end).format('YYYY-MM-DD'),
      accumulate: this.isAccumulate,
      ...collectedFieldsValues,
    };

    if (this.selectedGroup !== PLAN_VERSION_GROUP) {
      result.groupBy = this.selectedGroup;
    }

    return result;
  }

  get timelineRange() {
    return this.timeline.horizontalViewport;
  }

  get dateRange(): Range<number> {
    return this.range;
  }

  get period(): TimeUnit {
    return this.timeline.selectedPeriod;
  }

  @action.bound
  setGrouping(key: string) {
    const isPlanVersionPrevious =
      this.selectedGroup === PLAN_VERSION_GROUP && key !== PLAN_VERSION_GROUP && this.chartType === ChartType.line;
    const isPlanVersionNewGrouping =
      this.selectedGroup !== key && key === PLAN_VERSION_GROUP && this.chartType !== ChartType.column;

    if (isPlanVersionNewGrouping || isPlanVersionPrevious) {
      this.setChartType(ChartType.column);
    }

    if (key !== PLAN_VERSION_GROUP) {
      this.isAccumulate = false;
    }

    this.selectedGroup = key;
  }

  @action.bound
  setChartType(type: ChartType) {
    this.chartType = type;

    this.charts?.forEach((chart) => {
      const validChartType = validateChartType(type, chart.trendTypeSettings, this.selectedGroup);

      chart.updateChartData({ type: validChartType, withRerender: true });
    });
  }

  @action.bound
  toggleAccumulate() {
    this.isAccumulate = !this.isAccumulate;
  }

  @flow.bound
  private async *loadData() {
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;

      const { fields, grouping, trendType } = await getFiltersFormControlsAndItsDirectories(this.directories);
      yield;

      const _resolvers: IDResolver[] = [];

      for (const group of grouping) {
        _resolvers.push(new IDResolver(this.directories, group.value));
      }

      this.groupingView = grouping;
      this.filterForm = new FilterForm(fields, this.rootStore);
      this.grouping = mapGroupingValues(grouping, this.directories.getFieldLabel.bind(this.directories));
      this.idResolvers = _resolvers;
      this.chartNames = trendType;
      this.selectedGroup = grouping[0].value;
    } catch (error) {
      console.error(error);
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToExcludeWellFromImport');
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  setRange(range: Range<number>) {
    this.range = range;
  }

  @action.bound
  setTrendData(data: ChartData[]) {
    this.trendData = data;
  }

  @action.bound
  setGranularity(value: TimeUnit) {
    this.granularity = value;
  }

  @action.bound
  onTimelineChange = debounce((range: Range<number>) => {
    this.setRange(range);
  }, 1000);

  init() {
    this.timeline.loadDataBoundaries();
    this.loadData();

    const disposeTimeline = reaction(
      () => ({
        start: this.timelineRange.start,
        end: this.timelineRange.end,
        granularity: this.period,
        theme: this.theme.theme,
      }),
      ({ start, end, granularity, theme }, { granularity: prevGranularity, theme: prevTheme }) => {
        const newRange = { start, end };
        if (theme !== prevTheme) {
          if (!this.charts) return;

          this.charts.forEach((chart) => {
            const options = chart.getNewOptions({ theme });
            chart.setOptions(options);
            chart.chart?.update(chart.options);
          });
        } else if (granularity === prevGranularity) {
          this.onTimelineChange(newRange);
        } else {
          this.setRange(newRange);
          this.setGranularity(granularity);
        }
      }
    );

    const disposer = reaction(
      () => this.filterFormAndChartValues,
      (collectedValues, prevValues) => {
        let shouldRerender = true;

        const isPrevRangeExist =
          prevValues && 'from' in prevValues && 'to' in prevValues && 'trendGranularity' in prevValues;
        const isCurrentRangeExist =
          collectedValues &&
          'from' in collectedValues &&
          'to' in collectedValues &&
          'trendGranularity' in collectedValues;

        if (!this.isFirstLoading && isPrevRangeExist && isCurrentRangeExist) {
          const { from: prevFrom, to: prevTo, trendGranularity: prevTrendGranularity } = prevValues;
          const { from, to, trendGranularity } = collectedValues;

          if ((prevFrom !== from || prevTo !== to) && prevTrendGranularity === trendGranularity) {
            shouldRerender = false;
          }
        }

        this.updateTrend(collectedValues, shouldRerender);
      }
    );

    return () => {
      disposeTimeline();
      disposer();
    };
  }

  @action.bound
  parseTrendData(rawLabels: DashboardTrendResponse, resolver: ILabelResolver): Record<string, string | null> {
    const rawDataEntries = Object.values(rawLabels);
    const labels: Record<string, string | null> = {};

    for (const rawItem of rawDataEntries) {
      const parsedPoints = Object.keys(rawItem.points);

      for (const label of parsedPoints) {
        labels[label] = resolver.tryGetLabelByKey(label);
      }
    }

    return labels;
  }

  getDateResolver = computedFn((): ILabelResolver | null => {
    let resolver: ILabelResolver | null = null;

    if (this.granularity === TimeUnit.month) {
      resolver = new MonthResolver();
    }
    if (this.granularity === TimeUnit.quarter) {
      resolver = new QuarterResolver();
    }

    return resolver;
  });

  @flow.bound
  async *updateTrend(collectValues: Record<string, unknown> | null, withRerender: boolean) {
    this.timeline.timelinePresenter.disableMove();

    if (this.isUpdating || !collectValues) {
      return;
    }

    this.isUpdating = true;

    try {
      const trendResponse = await fetchDashboardTrend(collectValues);
      yield;

      const isDateResolver = this.selectedGroup === PLAN_VERSION_GROUP;
      let resolver: null | ILabelResolver = null;

      if (isDateResolver) {
        resolver = this.getDateResolver();
      } else if (this.selectedGroup) {
        const idResolver = this.idResolvers?.find((resolver) => resolver.fieldId === this.selectedGroup);
        const directory = this.groupingView?.find((group) => group.value === this.selectedGroup);

        if (idResolver && directory) {
          await idResolver.loadMissingLabels(trendResponse, directory);
          yield;

          resolver = idResolver;
        }
      }

      await this.planVersionResolver.getLabels(trendResponse);
      yield;

      if (!this.chartNames) {
        return;
      }

      if (this.isFirstLoading) {
        this.charts = mapTrendData(
          trendResponse,
          this.chartType,
          this.planVersionResolver.cache,
          this.theme,
          this.directories,
          this.chartNames,
          resolver
        );
        this.trend = trendResponse;
        this.isFirstLoading = false;
      } else {
        this.charts?.forEach((chart) => {
          const validChartType = validateChartType(this.chartType, chart.trendTypeSettings, this.selectedGroup);

          chart.updateChartData({
            type: validChartType,
            rawData: trendResponse,
            planVersions: this.planVersionResolver.cache,
            resolver,
            withRerender,
          });
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isUpdating = false;
    }
  }
}

export namespace DashboardStore {
  export type GroupingValueWithLabel = GroupingValue & {
    label: string;
  };
}
