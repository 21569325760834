import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { Pad as PadStore } from 'src/features/unplanned-rig-operations-sidebar/entities/pad.entity';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';

import styles from './pad-collapse-header.module.scss';

interface Props {
  store: PadStore;
}

export const PadCollapseHeader = observer(function PadCollapseHeader({ store }: Props) {
  const attributeIcons = getPadAttributeIcons(store.data);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{store.title}</p>
      <div className={styles.attributes}>
        {attributeIcons?.map((attributeName) => (
          <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.attribute} />
        ))}
      </div>
    </div>
  );
});
