import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { TrendGranularity } from 'src/shared/constants/trend-granularity';
import { TrendType } from 'src/shared/constants/trend-type';

import { IIndicatorsDataApi } from '../../features/drilling-chart/features/indicators-table/data/indicators-data-model.types';
import {
  IndicatorColumn,
  LoadingIndicatorsColumn,
} from '../../features/drilling-chart/presets/indicators-view-settings-sidebar/entities';

export class IndicatorsApi implements IIndicatorsDataApi {
  async getIndicators(
    /** Format: "2022-01-01". */
    from: string,
    /** Format: "2022-01-01". */
    to: string,
    trendGranularity: TrendGranularity,
    planVersionId: number,
    filter: Record<string, unknown>
  ): Promise<Indicators.RawIndicators> {
    try {
      const { data } = await agent.post<Indicators.RawIndicators>('/dashboard/trend', {
        ...filter,
        from,
        to,
        trendGranularity,
        // TODO: add filter by trend type.
        trendTypes: [],
        groupBy: 'date',
        planVersionId: [planVersionId],
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace Indicators {
  export type ItemValues<TValue> = Partial<Record<TrendType, TValue>>;

  export type Column<TValue = number> = ItemValues<TValue> & {
    start: number;
    end: number;
  };

  export const isColumnKey = (key: unknown): key is TrendType | 'start' | 'end' =>
    typeof key === 'string' && (Object.values<string>(TrendType).includes(key) || key === 'start' || key === 'end');

  export type Items<TItem = Column> = {
    items: TItem[];
    result?: TItem;
  };

  export type TwoVersionsData = Record<string, number>;

  export type RawIndicator = {
    points: Record<string, TwoVersionsData>;
    total: TwoVersionsData;
  };

  export type RawIndicators = Record<string, RawIndicator>;

  export type ViewIndicatorsColumn = Column & Partial<ItemValues<number>>;

  export type ViewIndicators = Items<IndicatorColumn | LoadingIndicatorsColumn>;
}
