import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MatchingImportStore } from 'src/pages/import-matching-page/import-matching-page.store';
import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';
import { Modal } from 'src/shared/components/modal/modal';

import { ConflictContent } from './conflict-content';
import styles from './new-value-conflict-modal.module.scss';
import { ConflictController } from './new-value.conflict-modal.controller';

type Props = {
  matchingImport: MatchingImportStore;
};

export const NewValueConflictModal = observer(function NewValueConflictModal({ matchingImport }: Props) {
  const { t } = useTranslation('matching');
  const [conflictController] = useState(() => new ConflictController(matchingImport));

  const currentConflictIndex = conflictController.currentConflictIndex;
  const currentConflict = !!matchingImport.conflicts.length ? matchingImport.conflicts[currentConflictIndex] : null;

  useEffect(() => {
    if (matchingImport.conflicts.length) {
      conflictController.runConflictScenario();
    }
  }, [conflictController, matchingImport.conflicts]);

  return (
    <Modal
      title={
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>{t('modal.title')}</h2>
          <p className={styles.conflictsCount}>
            {t('modal.of', {
              current: currentConflictIndex !== null && currentConflictIndex + 1,
              all: conflictController.conflictsLength,
            })}
          </p>
        </div>
      }
      isOpened={conflictController.isModalOpened}
    >
      <div className={styles.contentWrapper}>
        <UnmountWithTransition
          mountDelay={200}
          noAnimationDelayOnFirstRender
          className={styles.conflictContentWrapper}
          mountStylesClass={styles.conflictContentWrapper__mount}
          unmountStylesClass={styles.conflictContentWrapper__unmount}
          isShown={currentConflictIndex % 2 === 0 && !!currentConflict}
        >
          <ConflictContent conflict={currentConflict} conflictController={conflictController} />
        </UnmountWithTransition>
        <UnmountWithTransition
          mountDelay={200}
          className={styles.conflictContentWrapper}
          mountStylesClass={styles.conflictContentWrapper__mount}
          unmountStylesClass={styles.conflictContentWrapper__unmount}
          isShown={currentConflictIndex % 2 !== 0 && !!currentConflict}
        >
          <ConflictContent conflict={currentConflict} conflictController={conflictController} />
        </UnmountWithTransition>
      </div>
      <UnmountWithTransition
        isShown={conflictController.isLoading}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <div className={styles.preloaderWrapper}>
          <Spin size="large" />
        </div>
      </UnmountWithTransition>
    </Modal>
  );
});
