import { action, makeObservable, observable } from 'mobx';

export class Modal {
  private readonly handleOpen?: VoidFunction;

  private handleClose: VoidFunction | null = null;

  @observable isOpen: boolean;

  constructor(args?: Modal.Args) {
    this.isOpen = args?.initialIsOpen ?? false;
    this.handleOpen = args?.onOpen;

    makeObservable(this);
  }

  @action.bound
  open(handleClose?: VoidFunction): VoidFunction {
    this.isOpen = true;

    if (handleClose) {
      this.handleClose = handleClose;
    }

    this.handleOpen?.();

    return this.close;
  }

  @action.bound
  close(): void {
    this.handleClose?.();

    this.handleClose = null;
    this.isOpen = false;
  }
}

export namespace Modal {
  export type Args = Partial<{
    initialIsOpen: boolean;
    onOpen: VoidFunction;
  }>;
}
