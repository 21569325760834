import { RefObject, useContext, useEffect } from 'react';

import { useDraggablePortalRoot } from '../../editing/view/hooks/use-draggable-portal-root';
import { SortingContext } from '../sorting-context';

export function useSortingContext(): UseSortingContext.Return {
  const { containerRef, containerRect, setSortableContainer, setContainerRect } = useContext(SortingContext);

  useDraggablePortalRoot('sortable-container', setSortableContainer);

  useEffect(() => {
    const element = containerRef.current;

    const resizeObserver = new ResizeObserver(() => {
      if (element) {
        const elementRect = element.getBoundingClientRect();

        setContainerRect(elementRect);
      } else {
        setContainerRect(null);
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, setContainerRect]);

  return {
    ref: containerRef,
    containerRect,
  };
}

export namespace UseSortingContext {
  export type Args = {
    sortableContainerClassName?: string;
  };

  export type Return = {
    ref: RefObject<HTMLDivElement>;
    containerRect: DOMRect | null;
  };
}
