import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { WellTypes } from 'src/types/well-types';

export class ComparingWellsApi {
  async getWells(
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    filter: Record<string, unknown>,
    wellName?: string
  ): Promise<WellTypes.RawWell[] | null> {
    try {
      const { data: wells } = await agent.post<WellTypes.RawWell[]>('graph/well/list', filter, {
        params: {
          planVersionId: firstPlanVersionId,
          wellName,
        },
      });

      return wells;
    } catch (e) {
      throwApiError(e);
    }
  }
}
