import { action, computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { hasValue } from 'src/shared/utils/common';

import { Dnd } from '../../../features/editing/types';
import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';
import { NeighborScheme } from '../../../shared/entities/neighbor-scheme';

import { WellRigOperation } from './well-rig-operation';

export class PadRigOperation
  implements Dnd.Draggable<PadRigOperation, PadRigOperation>, RigsChartDataModel.IChartPadRigOperation
{
  readonly id: number;
  readonly item: RigsChart.RawPad;
  readonly uniqueCreationKey: string;
  readonly parentRig: RigsChartDataModel.IChartRig;

  neighborScheme?: NeighborScheme<WellRigOperation>;

  @observable wellRigOperations: WellRigOperation[] = [];
  @observable y: Range<number> = { start: 0, end: 0 };

  constructor(item: RigsChart.RawPad, parentRig: RigsChartDataModel.IChartRig) {
    this.id = item.id;
    this.item = item;
    this.uniqueCreationKey = uuidv4();
    this.parentRig = parentRig;

    makeObservable(this);
  }

  @computed
  get firstWell(): WellRigOperation | undefined {
    return this.wellRigOperations.at(0);
  }

  @computed
  get lastWell(): WellRigOperation | undefined {
    return this.wellRigOperations.at(-1);
  }

  get x(): Range<number> {
    const start = this.firstWell?.start;
    const end = this.lastWell?.end;

    if (start === undefined || start === null || end === undefined || end === null) {
      return { start: 0, end: 0 };
    }

    return {
      start,
      end,
    };
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-pad-${this.uniqueCreationKey}`;
    }

    return `pad-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): string | number | undefined {
    if (fieldName in this.item.data) {
      return this.item.data[fieldName];
    }
  }

  @action.bound
  setY(value: Range<number>): void {
    this.y = value;
  }

  @action.bound
  setItems(wells: WellRigOperation[]): void {
    this.wellRigOperations = wells.sort((first, second) => {
      if (hasValue(first.start) && hasValue(second.start)) {
        return first.start - second.start;
      }

      return 0;
    });

    for (let wellIndex = 0; wellIndex < this.wellRigOperations.length; wellIndex++) {
      const previousWell = this.wellRigOperations[wellIndex - 1];
      const currentWell = this.wellRigOperations[wellIndex];
      const nextWell = this.wellRigOperations[wellIndex + 1];

      if (currentWell) {
        const leftNeighbor: NeighborScheme.NeighborInfo<WellRigOperation> =
          previousWell?.end && currentWell.start
            ? {
                distance: currentWell.start - previousWell.end,
                neighbor: previousWell,
              }
            : {
                neighbor: undefined,
              };

        const rightNeighbor: NeighborScheme.NeighborInfo<WellRigOperation> =
          nextWell?.start && currentWell.end
            ? {
                distance: nextWell.start - currentWell.end,
                neighbor: nextWell,
              }
            : {
                neighbor: undefined,
              };

        currentWell.setNeighborScheme(new NeighborScheme(leftNeighbor, rightNeighbor));
      }
    }
  }

  setNeighborScheme(scheme: NeighborScheme<WellRigOperation>): void {
    this.neighborScheme = scheme;
  }

  clone(): PadRigOperation {
    const pad = new PadRigOperation(this.item, this.parentRig);

    pad.setItems(this.wellRigOperations.map((well) => well.clone()));

    return pad;
  }
}
