import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BookIcon } from 'src/shared/assets/icons/book.svg';
import { Button } from 'src/shared/components/button';
import { useStore } from 'src/store';

import { Item, ValidatableItem } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';
import { FormElement } from '../form-element';
import { DirectoryValuesPlugin } from '../plugins/directory-values-plugin';

import { getAdditionalItemComponent } from './get-additionalitem-component';

import styles from './well-form-column-item.module.scss';

type Props = {
  item: Item;
  formStore: FormStore;
};

export const WellFormColumnItem = observer(function WellFormColumnItem({ item, formStore }: Props) {
  const { t } = useTranslation();
  const { directories, i18 } = useStore();
  const [isDirectoryValueLoading, setIsDirectoryValueLoading] = useState(false);

  const directoryValuesPlugin = formStore.plugins.find(
    (plugin): plugin is DirectoryValuesPlugin => plugin instanceof DirectoryValuesPlugin
  );
  const isItemRequired = (item instanceof ValidatableItem && item.restrictions.required) || false;
  const label = directories.getFieldLabel(item.fieldId) || i18.t('errors:unrecognizedField') || '';
  const isItemWithDirectoryValue = directoryValuesPlugin?.checkIsItemWithDirectoryValues(item);

  const handleGetAndSetValueFromDirectory = () => {
    if (!directoryValuesPlugin) {
      return;
    }
    setIsDirectoryValueLoading(true);
    directoryValuesPlugin.getAndSetValueFromDirectory(item).finally(() => setIsDirectoryValueLoading(false));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.rowWrapper}>
        <div className={styles.itemNameWrapper}>
          <p className={styles.itemName} title={label}>
            {label}
            {isItemRequired ? <span> *</span> : null}
          </p>
          {label && getAdditionalItemComponent(item, label)}
        </div>
        <div className={styles.fieldWithButtonWrapper}>
          <div className={styles.itemWrapper}>
            <FormElement item={item} formStore={formStore} />
          </div>
          {directoryValuesPlugin && isItemWithDirectoryValue && !item.isVisuallyDisabled && !item.isDisabled && (
            <Button
              width={32}
              height={32}
              icon={<BookIcon />}
              title={directoryValuesPlugin.getBlockingMessage(item) || t('common:restoreToDirectoryValues')}
              variant="primary"
              disabled={!item.valueFromDirectory || item.valueFromDirectory === item.value}
              isLoading={isDirectoryValueLoading}
              onClick={handleGetAndSetValueFromDirectory}
            />
          )}
        </div>
      </div>
      {item.comment && <p className={styles.comment}>{item.comment}</p>}
    </div>
  );
});
