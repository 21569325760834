import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import { FormDatePicker } from 'src/shared/components/form-components';

import styles from './summary-data-sidebar.module.scss';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  selectedYear: number;
  onYearChange(year: number): void;
  className?: string;
}

export const SummaryDataSidebarWrapper = observer(function SummaryDataSidebarWrapper({
  isOpen,
  className,
  onClose,
  selectedYear,
  onYearChange,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Summary' });

  const yearMoment = useMemo(() => {
    return moment(selectedYear, 'YYYY');
  }, [selectedYear]);

  const handleYearChange = useCallback(
    (date: Moment) => {
      const year = date.year();

      onYearChange(year);
    },
    [onYearChange]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>{t('title')}</h2>
        <FormDatePicker
          picker="year"
          format="YYYY"
          value={yearMoment}
          onChange={handleYearChange}
          className={styles.yearPicker}
        />
        <Button className={styles.closeButton} icon={<CrossIcon className={styles.closeIcon} />} onClick={onClose} />
      </div>

      {children}
    </div>
  );
});
