import { WellTypes } from 'src/types/well-types';

export class WellsChartAdapter {
  receiveRigGroupIds(rawGroups: WellTypes.RawWellsGroup[]): number[] {
    const groupIds = new Set<number>();

    for (const rawGroup of rawGroups) {
      for (const [, groupData] of Object.entries(rawGroup)) {
        groupIds.add(groupData.id);
      }
    }

    return [...groupIds];
  }
}
