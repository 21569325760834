export namespace DateHelper {
  export const unixToDate = (date: number): Date => {
    return new Date(date * 1000);
  };

  export const dateToUnix = (date: Date): number => {
    return Math.trunc(date.getTime() / 1000);
  };

  export const unixToDateFormat = (
    date: string | number,
    locales: Intl.LocalesArgument = 'ru',
    formatOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' }
  ): string | null => {
    const validDate = Number.parseInt(date.toString());

    if (isNaN(validDate)) {
      return null;
    }

    return new Date(validDate * 1000).toLocaleDateString(locales, formatOptions);
  };

  export const unixToTimeFormat = (date: number): string | null => {
    const validDate = Number.parseInt(date.toString());

    if (isNaN(validDate)) {
      return null;
    }

    return new Date(validDate * 1000).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' });
  };

  export const startOf = (date: number, timeUnit: 'year' | 'month'): number => {
    const newDate = new Date(date * 1000);

    if (timeUnit === 'year') {
      newDate.setMonth(0, 1);
      newDate.setHours(0, 0, 0);
    }

    if (timeUnit === 'month') {
      newDate.setDate(1);
      newDate.setHours(0, 0, 0);
    }

    return Math.trunc(newDate.getTime() / 1000);
  };

  export const endOf = (date: number, timeUnit: 'year' | 'month'): number => {
    const newDate = new Date(date * 1000);

    if (timeUnit === 'year') {
      newDate.setMonth(11, 31);
      newDate.setHours(23, 59, 59);
    }

    if (timeUnit === 'month') {
      const monthDaysNumber = new Date(newDate.getFullYear(), newDate.getMonth(), 0).getDate();
      newDate.setDate(monthDaysNumber);
      newDate.setHours(23, 59, 59);
    }

    return Math.trunc(newDate.getTime() / 1000);
  };
}
