import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'src/shared/assets/icons/magnifier.svg';

import styles from './search.module.scss';

interface Props {
  className?: string;
  value?: string;
  onSearch?(searchTerm: string): void;
}

export const Search = observer(function Search({ className, value, onSearch }: Props) {
  const { t } = useTranslation();

  const handleSearch: FormEventHandler<HTMLInputElement> = (e): void => {
    onSearch?.(e.currentTarget.value);
  };

  return (
    <div className={clsx(styles.container, className)}>
      <SearchIcon className={styles.searchIcon} />
      <input value={value} className={styles.searchInput} placeholder={t('common:search')} onInput={handleSearch} />
    </div>
  );
});
