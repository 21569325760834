import { action, makeObservable, observable } from 'mobx';

import { storage } from 'src/api/storage';
import { LOCAL_STORAGE } from 'src/shared/constants/local-storage';
import { THEMES } from 'src/shared/constants/themes';

const checkTheme = (value: string | null): value is THEMES => {
  if (!value) return false;
  if (['light', 'dark'].includes(value)) return true;
  return false;
};

export class ThemeStore {
  @observable theme: THEMES;

  constructor() {
    const storedTheme = storage.GET<THEMES>(LOCAL_STORAGE.theme);
    const initTheme = checkTheme(storedTheme) ? storedTheme : THEMES.dark;
    this.theme = initTheme;

    makeObservable(this);
  }

  private _setTheme(theme: THEMES) {
    this.theme = theme;
    storage.SET(LOCAL_STORAGE.theme, theme);
  }

  @action.bound
  toggle() {
    this._setTheme(this.theme === THEMES.dark ? THEMES.light : THEMES.dark);
  }
}
