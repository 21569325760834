import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TConflict } from 'src/pages/import-matching-page/import-matching-page.store';
import { ReactComponent as CheckmarkIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as InfoIcon } from 'src/shared/assets/icons/info-i.svg';
import { ReactComponent as PlucIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button/button';
import { Combobox } from 'src/shared/components/combobox/combobox';
import { Tooltip } from 'src/shared/components/tooltip';
import { useStore } from 'src/store';

import { ConflictController } from './new-value.conflict-modal.controller';

import styles from './conflict-content.module.scss';

const TEST_MAIL = 'one_book@mail.com'; //TODO

type Props = {
  conflictController: ConflictController;
  conflict: TConflict | null;
};

export const ConflictContent = observer(function ConflictContent({ conflict, conflictController }: Props) {
  const [savedConflict] = useState(conflict);
  const { t } = useTranslation('matching');
  const { notifications } = useStore();
  const [replaceValue, setReplaceValue] = useState<string | number>('');

  const handleChangeValue = (value: string | number) => setReplaceValue(value);

  useEffect(() => {
    if (!!conflictController.errorMessage) {
      notifications.showErrorMessageT(conflictController.errorMessage);
    }
  }, [conflictController.errorMessage, notifications]);

  if (!savedConflict) return null;

  const mailHref = `mailto:${TEST_MAIL}?subject=${t('modal.mail.subjectText', { prop: savedConflict.name })}&body=${t(
    'modal.mail.bodyText',
    { prop: savedConflict.name, value: savedConflict.value }
  )}`;

  const handleReplaceValue = () => conflictController.handleReplaceValue(replaceValue, savedConflict);
  const handleAddToSystem = () => {
    conflictController.handleAddToSystem(savedConflict, t);
  };
  const handleDontAdd = () => conflictController.handleDontAdd(savedConflict);

  return (
    <Fragment>
      <p className={styles.text}>
        {t('modal.desc-first')} <b className={styles.valueName}>{savedConflict.name}</b> {t('modal.desc-sec')}
      </p>
      <div className={styles.selectWrapper}>
        <p className={styles.selectText}>{t('modal.found')}</p>
        <p className={styles.foundValue}>{savedConflict.value}</p>
      </div>
      <label className={styles.selectWrapper}>
        <p className={styles.selectText}>{t('modal.changeTo')}</p>
        <Combobox
          defaultValue={t('modal.selectPlaceholder')}
          className={styles.select}
          bordered={false}
          onChange={handleChangeValue}
          options={savedConflict.options.map((option) => ({
            label: option,
            value: option,
          }))}
        />
      </label>
      <div className={styles.buttonsWrapper}>
        <Button
          disabled={!replaceValue}
          prefixIcon={<CheckmarkIcon />}
          className={styles.button}
          variant="success"
          onClick={handleReplaceValue}
        >
          {t('modal.buttons.replace')}
        </Button>
        <div className={styles.buttonWrapper}>
          <Button
            as="a"
            href={mailHref}
            prefixIcon={<PlucIcon stroke="white" />}
            className={styles.button}
            variant="primary"
            onClick={handleAddToSystem}
          >
            {t('modal.buttons.add')}
          </Button>
          <Tooltip trigger={['focus', 'hover']} maxWidth={348} text={t('modal.buttons.addTooltip')}>
            <button className={styles.tooltipButton}>
              <InfoIcon className={styles.icon} />
            </button>
          </Tooltip>
        </div>
        <div className={styles.buttonWrapper}>
          <Button prefixIcon={<CrossIcon />} className={styles.button} variant="danger" onClick={handleDontAdd}>
            {t('modal.buttons.dontAdd')}
          </Button>
          <Tooltip trigger={['focus', 'hover']} maxWidth={348} text={t('modal.buttons.dontAddTooltip')}>
            <button className={styles.tooltipButton}>
              <InfoIcon className={styles.icon} />
            </button>
          </Tooltip>
        </div>
      </div>
    </Fragment>
  );
});
