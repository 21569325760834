import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { ChartTooltip } from 'src/features/drilling-chart/features/chart-tooltip/chart-tooltip';
import { useDragging } from 'src/features/drilling-chart/features/sorting/hooks/use-dragging';
import { WellTypeIcon } from 'src/features/well-type-icon';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { DrillingElementColoredCard } from '../../../drilling-element-colored-card';
import { WellRigOperation } from '../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useClickHandler } from '../../shared/use-click-handler';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';

import { WellTooltipInner } from './well-tooltip-inner';

import styles from './well-rig-operation-wrapper.module.scss';

interface Props {
  item: WellRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  className?: string;
  cardClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
  onClick?: VoidFunction;
}

export const DraggingWellRigOperationWrapper = observer(function DraggingWellRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  viewSettingsProvider,
  className,
  cardClassName,
  contentClassName,
  headerClassName,
  onClick,
}: Props) {
  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging'),
    bindingId: item.parentRig.getKey('sortable'),
    item,
  });

  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });

  const isOutOfViewport = useMemo(() => {
    return (item.x?.start ?? 0) + ((item.x?.end ?? 0) - (item.x?.start ?? 0)) / 2 < horizontalViewport.start;
  }, [item, horizontalViewport]);
  const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

  const { onPointerDown, onPointerUp } = useClickHandler(onClick);

  const wellIcon = viewSettingsProvider.getWellIcon(item.data);

  return (
    <ChartTooltip
      content={<WellTooltipInner well={item} viewProvider={viewSettingsProvider} />}
      placement={tooltipPlacement}
    >
      {({ handleMouseOver, handleMouseLeave }) => {
        return (
          <div ref={mergeRefs(ref, draggingRef)} className={clsx(className, styles.dataItem)}>
            <DrillingElementColoredCard
              title={viewSettingsProvider.getTitle(item)}
              color={viewSettingsProvider.getWellColor(item.data) || ''}
              attributes={viewSettingsProvider.getAttributes(item)}
              icons={<WellTypeIcon iconName={wellIcon} className={styles.wellIcon} />}
              className={classNames(cardClassName, styles.wellCard)}
              contentClassName={contentClassName}
              headerClassName={headerClassName}
              onMouseLeave={handleMouseLeave}
              onMouseOver={handleMouseOver}
              onPointerDown={onPointerDown}
              onPointerUp={onPointerUp}
            />
          </div>
        );
      }}
    </ChartTooltip>
  );
});
