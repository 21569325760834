import { DatePicker } from 'antd';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';

import { ReactComponent as CalendarIcon } from 'src/shared/assets/icons/calendar.svg';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { getLocale } from 'src/shared/utils/languages';

import styles from './date-table-cell.module.scss';

interface Props {
  fieldValue: number | string | null;
  onEditCell: (value: number | null) => void;
}

export const DateTableCell = observer(function EditableTextField({ fieldValue, onEditCell }: Props) {
  function onChangeDate(date: Moment | null): void {
    onEditCell(date?.unix() || null);
  }

  const value = useMemo(
    () => (fieldValue && !Number.isNaN(fieldValue) ? moment.unix(Number(fieldValue)) : null),
    [fieldValue]
  );

  return (
    <div className={styles.wrapper}>
      <DatePicker
        format={DATE_FORMAT}
        value={value}
        onChange={onChangeDate}
        locale={getLocale() === 'ru' ? localeRu : localeEn}
        suffixIcon={<CalendarIcon />}
        allowClear
      />
    </div>
  );
});
