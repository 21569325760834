import { flow, makeObservable, observable } from 'mobx';

import { TAddWellToChartSidebarView } from 'src/api/add-well-to-chart-sidebar/types';
import { AddRigSidebar } from 'src/api/chart/add-rig-sidebar-api';
import { IndicatorsView } from 'src/api/chart/drilling-plan-charts-api';
import {
  RigsChartConflictSidebarViewTypes,
  RigsChartViewTypes,
  RigsChartRemoveConfirmationSidebarViewTypes,
} from 'src/api/chart/rigs-chart-api';
import { WellsChartViewTypes } from 'src/api/chart/wells-chart-api';
import { RigConflictsSidebar } from 'src/api/rig-sidebar/rig-conflicts-sidebar-api';
import { ViewsApi } from 'src/api/views/views';
import { WellsListViewType } from 'src/api/wells-list';
import { ViewNotFoundError } from 'src/errors/views';
import { TSidebarInfoView } from 'src/features/graph-info-sidebar/graph-info-sidebar';

import { ChartFiltersForm } from '../../features/drilling-chart/shared/filters-form.store';

/*
 * How to use:
 *   1. Create field as object of View in ViewsStore. Add view loader function as param.
 *   2. Call View.loadView() method where you need the view. (This method can throw errors.)
 *   3. Get View.view.
 *
 * The view will only be loaded once if the loader succeeds.
 * */

export class View<TView> {
  private readonly _loadView: () => Promise<TView>;
  private loadingView: Promise<TView> | null = null;

  @observable private _view?: TView;

  @observable isLoading = false;

  constructor(loadView: () => Promise<TView>) {
    this._loadView = loadView;

    makeObservable(this);
  }

  @flow.bound
  async *loadView(): Promise<TView> {
    if (this._view) {
      return this.view;
    }

    if (this.loadingView) {
      return this.loadingView;
    }

    try {
      this.isLoading = true;

      this.loadingView = this._loadView();

      const view = await this.loadingView;
      yield;

      this._view = view;
      this.loadingView = null;

      return this.view;
    } finally {
      yield;

      this.isLoading = false;
    }
  }

  get view(): TView {
    if (!this._view) {
      throw new ViewNotFoundError();
    }

    return this._view;
  }
}

export class ViewsStore {
  private readonly api = new ViewsApi();

  readonly wellListView = new View(() => this.api.getView<WellsListViewType>('WellList'));
  readonly padSidebarView = new View(() => this.api.getView<TSidebarInfoView>('SideBar_ViewPad'));
  readonly wellSidebarView = new View(() => this.api.getView<TSidebarInfoView>('SideBar_ViewWell'));
  readonly rigSidebarView = new View(() => this.api.getView<TSidebarInfoView>('SideBar_ViewRig'));
  readonly addRigSidebarView = new View(() => this.api.getView<AddRigSidebar.View>('SideBar_AddRig'));
  readonly addWellToChart = new View(() => this.api.getView<TAddWellToChartSidebarView>('SideBar_AddWellToChart'));
  readonly conflictSidebarView = new View(() =>
    this.api.getView<RigConflictsSidebar.View>('SideBar_ConflictResolution')
  );
  readonly rigsChartConflictSidebarView = new View(() =>
    this.api.getView<RigsChartConflictSidebarViewTypes.RigsChartConflictSidebarView>('SideBar_ViewConflict')
  );
  readonly confirmationRemoveSidebar = new View(() =>
    this.api.getView<RigsChartRemoveConfirmationSidebarViewTypes.RigsChartRemoveConfirmationSidebarView>(
      'SideBar_ConfirmRemove'
    )
  );
  readonly rigsChartView = new View(() => this.api.getView<RigsChartViewTypes.RigsChartView>('GraphRigs'));
  readonly wellsChartView = new View(() => this.api.getView<WellsChartViewTypes.View>('GraphWellsPads'));
  readonly wellsChartFiltersView = new View(() => this.api.getView<ChartFiltersForm.View>('Filter_GraphWellsPads'));
  readonly rigsChartFiltersView = new View(() => this.api.getView<ChartFiltersForm.View>('Filter_GraphRigs'));
  readonly indicatorsSettingsView = new View(() => this.api.getView<IndicatorsView.Settings>('GraphBottomTable'));
}
