import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { getRandomNumber } from 'src/shared/utils/get-random-number';

type TAddToChartSidebarWellEntityOprions = {
  view: UnplannedRigOperationsSidebar.WellsView;
  wellData: Record<string, unknown>;
  color: string;
  title: string;
  wellId: number | null;
  attributes: string[];
  wellTypeIcon?: WellTypeIcon;
};

export class AddToChartSidebarWellEntity {
  readonly id: number;
  readonly data: Record<string, unknown>;
  readonly view: UnplannedRigOperationsSidebar.WellsView;
  readonly title: string;
  readonly color: string;
  readonly wellId: number | null = null;
  readonly attributes: string[] = [];
  readonly wellTypeIcon?: WellTypeIcon;

  constructor({ view, wellData, color, title, wellId, attributes, wellTypeIcon }: TAddToChartSidebarWellEntityOprions) {
    this.id = getRandomNumber();
    this.view = view;
    this.data = wellData;
    this.color = color;
    this.title = title;
    this.wellId = wellId;
    this.attributes = attributes;
    this.wellTypeIcon = wellTypeIcon;
  }
}
