import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoSidebarTabContent } from 'src/features/graph-info-sidebar/info-sidebar-tab-component/info-sidebar-tab-component';
import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import { ControlBar } from './control-bar';
import { ViewPadsSidebarStore } from './view-pads-sidebar.store';

import styles from './view-pads-sidebar.module.scss';

interface Props {
  store: ViewPadsSidebarStore;
}

export const ViewPadsSidebar = observer(function ViewPadsSidebar({ store }: Props) {
  const { t } = useTranslation('drawers');
  const [activeKey, setActiveKey] = useState(store.viewProvider?.tabsSwitchButtonsGroup[0]?.key);
  const activeTab = store.viewProvider?.tabs.find((tab) => tab.fieldId === activeKey);

  const isLoading = store.isLoading || store.rigStore.isLoading;

  return (
    <Sidebar
      isOpened={store.isOpen}
      onClose={store.close}
      closeOnClickOutside
      size="l"
      title={t('drillingRigDrawer.drillingRig')}
    >
      <ControlBar
        tabs={store.viewProvider?.tabsSwitchButtonsGroup || []}
        tab={activeKey}
        onTabChange={setActiveKey}
        isLoading={isLoading}
      />

      <div className={styles.contentContainer}>
        {isLoading ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <InfoSidebarTabContent activeTab={activeTab} />
        )}
      </div>
    </Sidebar>
  );
});
