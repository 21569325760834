import { observable, makeObservable, action, computed } from 'mobx';
import { Moment } from 'moment';

import { TDictObject } from 'src/api/directories/types';
import { mapApproachStageSection } from 'src/api/new-well/serializers/approaches-serializers';
import {
  TApprachStageSectionsList,
  TApproachStageSectionRaw,
  TRequiredIf,
  TSerializedSection,
} from 'src/api/new-well/types';
import { Directories } from 'src/store/directories/directories.store';

import { TOption } from '../../types';

import { ApproachStageSection } from './approach-stage-section.entity';
import { ApproachStage } from './approach-stage.entity';

type TApproachStageSectionsListData = {
  directories: Directories;
  sectionTemplate: TApprachStageSectionsList;
  stage: ApproachStage;
  savedSections?: TSerializedSection[] | undefined;
};

function processSectionOptions(data: TDictObject[] | null): TOption[] {
  if (!data) return [];
  return data
    .filter((section) => section.status === 'ACTIVE')
    .map((sectionType) => ({
      label: sectionType.data.name.toString(),
      value: sectionType.id,
    }));
}

export class ApproachStageSectionsList {
  readonly directories: Directories;
  readonly requiredIf: TRequiredIf[];
  readonly sectionReference: TApproachStageSectionRaw;
  readonly attrName: string;
  readonly sectionTypes: TDictObject[];
  readonly stage: ApproachStage;

  @observable sectionOptions: TOption[];
  @observable sections: ApproachStageSection[];
  @observable required: boolean;

  constructor(data: TApproachStageSectionsListData) {
    this.directories = data.directories;
    this.stage = data.stage;
    this.attrName = data.sectionTemplate.attrName;
    this.required = !!data.sectionTemplate.required;
    this.requiredIf = data.sectionTemplate.requiredIf;
    this.sectionReference = data.sectionTemplate.section;
    this.sectionOptions = processSectionOptions(
      this.directories.getObject(this.sectionReference.sectionTypeId.refObjectType)
    );
    this.sections = this.getSavedSections(data.savedSections) || [];
    this.sectionTypes = this.directories.getObject(this.sectionReference.sectionTypeId.refObjectType) || [];

    makeObservable(this);
  }

  private getSavedSections = (rawSavedSections?: TSerializedSection[]): ApproachStageSection[] | undefined => {
    if (!rawSavedSections || !this.sectionReference) return;
    const reference = this.sectionReference;
    const sectionTypesDirectoryValues = this.directories.getObject(this.sectionReference.sectionTypeId.refObjectType);
    const savedSections: ApproachStageSection[] = [];

    rawSavedSections.forEach((savedSection) => {
      const sectionDirValue = sectionTypesDirectoryValues?.find(
        (dirValue) => dirValue.id === savedSection.sectionTypeId
      );
      if (sectionDirValue) {
        savedSections.push(
          mapApproachStageSection(
            { sectionType: sectionDirValue, reference, directories: this.directories, parentControl: this.stage },
            savedSection
          )
        );
      }
    });

    return savedSections;
  };

  @computed
  get startDate(): Moment | null {
    return this.sections.at(0)?.dateField?.startDate || null;
  }

  @computed
  get endDate(): Moment | null {
    return this.sections.at(-1)?.dateField?.endDate || null;
  }

  @action.bound
  setSections(sections: ApproachStageSection[]): void {
    this.sections = sections;
  }

  @action.bound
  setSectionsAndSwapDates(sections: ApproachStageSection[], newIndex: number, oldIndex: number) {
    const firstSection = sections.at(newIndex);
    const secondSection = sections.at(oldIndex);

    const firstSectionStartDate = firstSection?.dateField?.startDate;
    const secondSectionStartDate = secondSection?.dateField?.startDate;

    if (firstSection?.dateField && secondSectionStartDate) {
      firstSection.dateField.setStartDate(secondSectionStartDate);
    }

    if (secondSection?.dateField && firstSectionStartDate) {
      secondSection.dateField.setStartDate(firstSectionStartDate);
    }

    this.setSections(sections);
  }

  @action.bound
  changeSectionType(section: ApproachStageSection, sectionTypeId: string | number): void {
    if (!this.sectionReference) return;
    const newSections = this.sections;
    const fieldValues: { name: string; value: unknown }[] = [];

    section.fieldsList.forEach((field) => {
      if (field.value !== undefined) {
        fieldValues.push({
          name: field.fieldId,
          value: field.value,
        });
      }
    });

    const sectionType = this.sectionTypes.find((type) => type.id === sectionTypeId);
    if (!sectionType) return;

    const newSection = mapApproachStageSection({
      sectionType,
      fieldValues,
      directories: this.directories,
      reference: this.sectionReference,
      parentControl: this.stage,
    });

    const oldSectionIndex = this.sections.indexOf(section);
    newSections.splice(oldSectionIndex, 1, newSection);
    this.sections = newSections;
  }

  @action.bound
  addNewSection(sectionTypeId: string | number): void {
    if (!this.sectionReference) return;
    const sectionType = this.sectionTypes.find((type) => type.id === sectionTypeId);
    if (!sectionType) return;

    this.sections.push(
      mapApproachStageSection({
        sectionType,
        directories: this.directories,
        reference: this.sectionReference,
        parentControl: this.stage,
      })
    );
  }

  @action.bound
  deleteSection(id: number): void {
    const targetSection = this.sections.find((section) => section.id === id);

    targetSection?.onDeleteCallbacks.forEach((clb) => clb());

    this.sections = this.sections.filter((item) => item.id !== id);
  }

  @action.bound
  validate() {
    this.sections.forEach((section) => section.validate());
  }

  @action.bound
  setIsVisuallyDisabled(is: boolean) {
    this.sections.forEach((section) => section.setIsVisuallyDisabled(is));
  }

  @action.bound
  setIsRequired(is: boolean) {
    this.required = is;
  }
}
