import { action, makeObservable, observable } from 'mobx';

export class GlobalVariables {
  @observable variables: Record<string, string | number> = {};

  constructor() {
    makeObservable(this);
  }

  @action.bound
  addVariable(key: string, value: string) {
    this.variables[key] = value;
  }
}
