import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { ViewSettings as TViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import { SortableList } from 'src/shared/components/sortable-list/sortable-list';

import { DEFAULT_VIEW_SETTINGS_OPTIONS } from '../../drilling-chart.constants';
import { EditingSettingsItem } from '../../shared/editing-settings-item';

interface Props {
  settings?: TViewSettings.FlatSettingsControls;
  onSortEnd(items: EditingSettingsItem[], settingsId: string): void;
  onError?(error?: Error): void;
}

export const ViewSettings = observer(function ViewSettings({ settings, onError, onSortEnd }: Props) {
  const [errorItem, setErrorItem] = useState<EditingSettingsItem | null>(null);

  const onIsShownChange = useCallback((value: boolean, item: EditingSettingsItem): void => {
    item.setIsShown(value);
  }, []);

  const validate = useCallback(
    (items: EditingSettingsItem[], item?: EditingSettingsItem) => {
      if (onError && settings?.items) {
        let shownValuesCount = 0;
        let error: Error | undefined = undefined;

        for (const settingsItem of settings.items) {
          if (settingsItem.isShown) {
            shownValuesCount++;
          }

          if (shownValuesCount > DEFAULT_VIEW_SETTINGS_OPTIONS.maxChecked) {
            error = new Error(`Max checked items count is ${DEFAULT_VIEW_SETTINGS_OPTIONS.maxChecked}`);

            if (item) {
              setErrorItem(item);
            }

            break;
          }
        }

        onError(error);
      }
    },
    [settings?.items, onError]
  );

  useEffect(() => {
    if (errorItem) {
      const timer = setTimeout(() => {
        errorItem.setIsShown(false);
        setErrorItem(null);
        onError?.();
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [errorItem, onError]);

  if (!settings?.items?.length) {
    return null;
  }

  const handleSortEnd = (items: EditingSettingsItem[]): void => {
    onSortEnd(items, settings?.fieldId);
  };

  return (
    <SortableList
      items={settings.items}
      onSortEnd={handleSortEnd}
      onIsShownChange={onIsShownChange}
      options={DEFAULT_VIEW_SETTINGS_OPTIONS}
      onError={onError}
      customValidateFn={validate}
    />
  );
});
