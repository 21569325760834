import { LoadingField } from 'src/shared/utils/loading-field';

export class ColorAttribute {
  readonly color: string;
  readonly label: LoadingField | string;

  constructor(color: string, label: LoadingField | string) {
    this.color = color;
    this.label = label;
  }
}
