import { makeObservable, observable, action } from 'mobx';

import { IRestrictions } from '../../../features/well-form/types';
import { TextValidation } from '../../../features/well-form/validation/text-validation';
import { Field } from '../abstract-control-entities';

export type TTextFieldData = {
  formElementRefId: string;
  fieldId: string;
  useInMainProgressBar: boolean;
  required: boolean;
  objectName?: string;
  placeholder?: string;
  unit?: string;
  initialValue?: string;
  restrictions: IRestrictions;
};

export class TextField extends Field<string | null> {
  @observable value: string | null;
  @observable initialValue: string | null = null;
  readonly validation: TextValidation;

  constructor(data: TTextFieldData) {
    super(data);
    this.value = data.initialValue || null;
    this.validation = new TextValidation(data.restrictions);

    makeObservable(this);
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return this.value !== '';
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  clearItem(): void {
    this.clearError();
    this.value = '';
  }

  @action.bound
  hasErrors(): boolean {
    if (this.value) {
      this.value = this.value.trim();
    }
    this.errorText = this.validation.validate(this.value);
    return !!this.errorText;
  }

  @action
  setValue(value: string, setValueAsInitial?: boolean): void {
    if (value === this.value) {
      return;
    }
    this.clearError();
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: string | null): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (value === 'null') {
      this.setValue('', setValueAsInitial);
      return true;
    }
    if (typeof value === 'string' || typeof value === 'number') {
      this.setValue(value.toString(), setValueAsInitial);
      return true;
    }
    return false;
  }
}
