import { Modal } from 'src/shared/utils/modal';
import { createPromiseController, TPromiseController } from 'src/shared/utils/promise-controller';

import { AddingRigCancellationError } from '../../features/data-items-full/presenter/errors';

import { RigOperationsDnd } from './rig-operations-dnd-module';

export class AddingRigScenario {
  private readonly padsAndWellsSidebarController: Modal;
  private readonly selectStartDateSidebarController: Modal;

  isRunning: boolean = false;
  addingChildElement?: TPromiseController<RigOperationsDnd.DraggableItem>;
  addingChildElementStartDate?: TPromiseController<null | number>;

  constructor(padsAndWellsSidebarController: Modal, selectStartDateSidebarController: Modal) {
    this.padsAndWellsSidebarController = padsAndWellsSidebarController;
    this.selectStartDateSidebarController = selectStartDateSidebarController;
  }

  async run(): Promise<AddingRigScenario.ScenarioResult> {
    this.isRunning = true;
    this.addingChildElement = createPromiseController();
    this.padsAndWellsSidebarController.open(() => this.addingChildElement?.reject(new AddingRigCancellationError()));
    const addingElement = await this.addingChildElement;

    this.addingChildElementStartDate = createPromiseController();
    this.selectStartDateSidebarController.open(() =>
      this.addingChildElementStartDate?.reject(new AddingRigCancellationError())
    );

    try {
      const startDate = await this.addingChildElementStartDate;

      return { addingElement, startDate };
    } catch (e) {
      throw e;
    } finally {
      this.isRunning = false;
      this.selectStartDateSidebarController.close();
      this.padsAndWellsSidebarController.close();
    }
  }
}

export namespace AddingRigScenario {
  export type ScenarioResult = {
    addingElement: RigOperationsDnd.DraggableItem;
    startDate: number | null;
  };
}
