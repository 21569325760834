import { TSerializedFormValues } from 'src/features/well-form/entities/types';
import { Tab } from 'src/shared/entities/abstract-control-entities';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';
import { PadTypes } from 'src/types/pad-types';

import { agent } from '../agent';
import { throwApiError } from '../utils';

import { serializeFormForRequest } from './serializers/serialize-form-for-request';
import {
  TCreateUpdateWellReturnType,
  TDirectoryValueWithMsg,
  TFormRaw,
  TIdTriple,
  TItemRaw,
  TSerializedStage,
} from './types';

const loadAttributesAndDirectories = async (directoriesStore: Directories, rawForm: TFormRaw): Promise<void> => {
  const attrNames: string[] = [];
  const joinedDirectoriesQueries: TRefQuery[] = [];
  const directoriesTypes: string[] = ['Common_GeologicalLoad'];

  const processItem = (item: TItemRaw): void => {
    if (item.attrName) {
      attrNames.push(item.attrName);
    }
    if (item.control === 'ComboBox' || item.control === 'Label' || item.control === 'MultiComboBox') {
      if (item.refObjectType) {
        directoriesTypes.push(item.refObjectType);
      }
      if ('refQuery' in item && item.refQuery) {
        joinedDirectoriesQueries.push(item.refQuery);
      }
      if ('attrConcatRefs' in item) {
        for (const key in item.attrConcatRefs) {
          if (item.attrConcatRefs[key].refObjectType) {
            directoriesTypes.push(item.attrConcatRefs[key].refObjectType);
          }
        }
      }
    }
  };

  for (const tab of rawForm.tabs) {
    if (tab.kind === 'tabWithColumns') {
      for (const column of tab.columns) {
        for (const item of column.items) {
          processItem(item);
        }
      }
    }
    if (tab.kind === 'tabWithStages') {
      const approach = tab.approachesList.approach;
      for (const approachItem of approach.items) {
        processItem(approachItem);
      }
      const stage = approach.stageTemplate.stage;
      directoriesTypes.push(stage.wellLifeCycleStageId.refObjectType);
      attrNames.push(stage.wellLifeCycleStageId.attrName);
      for (const approachField of stage.items) {
        processItem(approachField);
      }
      const section = stage.sectionTemplate.section;
      attrNames.push(section.sectionTypeId.attrName);
      directoriesTypes.push(section.sectionTypeId.refObjectType);
      for (const sectionField of section.items) {
        processItem(sectionField);
      }
    }
  }
  await Promise.all([
    directoriesStore.loadAttrNames(attrNames),
    directoriesStore.loadObjects(directoriesTypes),
    directoriesStore.loadJoinedObjects(joinedDirectoriesQueries),
  ]);
};

export const getWellFormViewAndItsDirectories = async (directories: Directories): Promise<TFormRaw> => {
  const { data } = await agent.get<TFormRaw>('view/EditWell');

  await loadAttributesAndDirectories(directories, data);
  return data;
};

export const createWell = async (tabs: Tab[], planVersionId: number): Promise<TCreateUpdateWellReturnType> => {
  try {
    const serializedForm = serializeFormForRequest(tabs);
    const { data: tripleIds } = await agent.post('/well/new', serializedForm, { params: { planVersionId } });
    return tripleIds;
  } catch (e) {
    throwApiError(e);
  }
};

export const updateWell = async (
  tabs: Tab[],
  planVersionId: number,
  tripleIds?: TIdTriple[]
): Promise<TCreateUpdateWellReturnType> => {
  try {
    const serializedForm = serializeFormForRequest(tabs, tripleIds);

    const { data } = await agent.patch<TCreateUpdateWellReturnType>('/well', serializedForm, {
      params: { planVersionId },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
};

export const getWellByWellId = async (wellId: number, planVersionId: number): Promise<Record<string, unknown>> => {
  try {
    const { data: well } = await agent.get<Record<string, unknown>>(`well/${wellId}`, {
      params: { planVersionId },
    });
    return well;
  } catch (e) {
    throwApiError(e);
  }
};

export const getGeologicalTaskHistory = async (
  planVersionId: number,
  wellPlacementId: number
): Promise<Record<string, unknown>[]> => {
  try {
    const { data: history } = await agent.get<Record<string, unknown>[]>('well/getGeologicalTaskHistory', {
      params: { planVersionId, wellPlacementId },
    });
    return history;
  } catch (e) {
    throwApiError(e);
  }
};

export const validateFormRequest = async (
  form: Tab[],
  planVersionId: number,
  controlTags: string[],
  tripleIds?: TIdTriple[] | null
): Promise<void> => {
  try {
    const serializedForm = serializeFormForRequest(form, tripleIds);
    const validationTags = controlTags.join(',');

    await agent.post(`well/validate/${planVersionId}`, serializedForm, {
      params: { validationTags },
    });
  } catch (e) {
    throwApiError(e);
  }
};

export const getRigPads = async (rigId: number, planVersionId: number): Promise<PadTypes.RawPadWithItems[]> => {
  try {
    const { data } = await agent.get<PadTypes.RawPadWithItems[]>('rig/rigOperation/list', {
      params: { rigId, planVersionId },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
};

export const getDirectoryValues = async (
  tabs: Tab[],
  fieldIdList: string[] = []
): Promise<Record<string, TDirectoryValueWithMsg>> => {
  const serializedForm = serializeFormForRequest(tabs);

  try {
    const { data } = await agent.post<Record<string, TDirectoryValueWithMsg>>(
      'well/getDictionaryValues',
      serializedForm,
      {
        params: {
          fieldIdList: fieldIdList.join(','),
        },
      }
    );
    return data;
  } catch (e) {
    throwApiError(e);
  }
};

export const getStageWithComputedDuration = async (params: Record<string, unknown>): Promise<TSerializedStage> => {
  try {
    const { data } = await agent.get<TSerializedStage>('well/getStage', {
      params,
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
};

export const fetchComputedValues = async (
  tabs: Tab[],
  prevFormState: string | null,
  computeTags: string[]
): Promise<TSerializedFormValues> => {
  const serializedForm = serializeFormForRequest(tabs);

  try {
    const { data } = await agent.post<TSerializedFormValues>(
      'well/computeWellData',
      {
        new: JSON.parse(serializedForm),
        old: prevFormState ? JSON.parse(prevFormState) : null,
      },
      {
        params: {
          computeTag: computeTags.join(','),
        },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
};
