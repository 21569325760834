import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import styles from '../../features/data-items-background/view/data-items.module.scss';
import { useMovableContainer } from '../../shared/use-movable-container';
import { Viewport } from '../../shared/viewport/viewport';

interface Props {
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  onPointerDown(): void;
  onPointerMove(offsetX: number, offsetY?: number): void;
  onPointerUp(): void;
  onScroll?(offset: number): void;
  onZoom?(offset: number, centerPosition: number): void;
  onZoomOut?: VoidFunction;
  className?: string;
}

export const DataContainer = observer(function DataContainer({
  className,
  horizontalViewport,
  children,
  onPointerDown,
  onPointerMove,
  onPointerUp,
  onScroll,
  onZoom,
  onZoomOut,
}: PropsWithChildren<Props>) {
  const { containerRef, handlePointerDown, handlePointerMove, handlePointerUp, handleScroll } = useMovableContainer({
    horizontalViewport,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onZoom,
    onZoomOut,
    onScroll,
    onCancel: onPointerDown,
    onBlur: onPointerDown,
  });

  return (
    <div
      ref={containerRef}
      className={clsx(className, styles.wrapper)}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerCancel={handlePointerUp}
      onWheel={handleScroll}
    >
      <div className={styles.rows}>{children}</div>
    </div>
  );
});
