import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { RigsGroup } from '../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';
import { useSortableGhost } from '../sorting/hooks/use-sortable-ghost';
import { SortableGhostItem } from '../sorting/sortable-ghost-item';

import styles from './group-collapse-header.module.scss';

interface Props {
  item: SortableGhostItem<RigsGroup>;
  isCollapsed: boolean;
  viewport: Viewport;
  title?: ReactNode;
  className?: string;
}

export const GhostGroupCollapseHeader = observer(function GhostGroupCollapseHeader({
  item: ghostWrapper,
  viewport,
  isCollapsed,
  title,
  className,
}: Props) {
  const { transform } = useSortableGhost({
    id: ghostWrapper.activeKey,
  });

  const ref = useMovableElement({
    item: ghostWrapper,
    verticalViewport: viewport,
    transform,
  });

  return (
    <div
      ref={ref}
      className={clsx(
        styles.collapseHeaderWrapper,
        isCollapsed && styles.collapseHeaderCollapsedWrapper,
        styles.sortableGhost
      )}
    >
      <div className={clsx(className, styles.collapseHeader)}>{title}</div>
    </div>
  );
});
