import { SeriesData } from '../../features/dashboard/types';

import { LabelsPlacement } from './types';

export namespace ColumnLabelsDrawer {
  const chartTopMargin = 40;

  type PlacedPoint = {
    parent: LabelsPlacement.PointWithPosition;
    labelPosition: { x: number; y: number; rowNumber: number };
  };

  function getMaxReservedRowsNumber(plotWidth: number, pointsNumber: number): number {
    const pointsDensity = plotWidth / pointsNumber;

    if (pointsDensity > 80) {
      return 1;
    }

    if (pointsDensity > 40) {
      return 2;
    }

    if (pointsDensity < 15) {
      return 3;
    }

    return LabelsPlacement.RESERVED_ROWS_MAX_LENGTH;
  }

  export function placeLabelsInStackedBars(
    groupedSeries: LabelsPlacement.GroupedPoints,
    plotLeft: number,
    plotWidth: number,
    plotHeight: number
  ): LabelsPlacement.PointWithParent[] {
    const _groupedSeriesValues = Object.values(groupedSeries);
    const categorySize = _groupedSeriesValues.at(0)?.length;

    if (!categorySize) {
      return [];
    }

    const series: LabelsPlacement.PointWithPosition[][] = _groupedSeriesValues;

    const placedPoints: (Omit<PlacedPoint, 'labelPosition'> & { labelPosition: { x: number; y: number } })[] = [];
    let isSmallHeight = false;

    series.forEach((points: LabelsPlacement.PointWithPosition[]) => {
      let previousPosition = 0;
      let previousRow = 0;
      let previousMaxLabelWidth: number | undefined;

      points.reverse().forEach((positionedPoint: LabelsPlacement.PointWithPosition) => {
        if (positionedPoint.point.y === null) {
          return;
        }
        const { shapeArgs } = positionedPoint.point;

        const columnWidth = shapeArgs ? shapeArgs.height / 2 : 0;
        const currentValue = positionedPoint.point.y;
        const valueDigitsCount = String(currentValue).length;
        const maxLabelWidth = valueDigitsCount
          ? valueDigitsCount * LabelsPlacement.BAR_LABEL_PART_WIDTH + LabelsPlacement.BAR_SPACE_WIDTH
          : 0;

        const x = plotLeft + (plotWidth - shapeArgs?.y - columnWidth);
        const y1 = shapeArgs ? shapeArgs.x + shapeArgs.width : 0;
        const shouldIncreaseRow =
          previousPosition !== 0 &&
          previousMaxLabelWidth &&
          previousPosition + previousMaxLabelWidth / 2 >= x - maxLabelWidth / 2 &&
          previousRow < 2;

        previousMaxLabelWidth = maxLabelWidth;
        previousRow = shouldIncreaseRow ? previousRow + 1 : 0;
        previousPosition = x;

        if (y1 > 0) {
          placedPoints.push({
            parent: positionedPoint,
            labelPosition: {
              x,
              y:
                plotHeight -
                y1 +
                LabelsPlacement.BAR_LABEL_POSITION_OFFSET -
                LabelsPlacement.BAR_DATA_LABEL_HEIGHT * previousRow,
            },
          });
        }

        if (!isSmallHeight && y1 < LabelsPlacement.BAR_DATA_LABEL_HEIGHT && y1 > 0) {
          isSmallHeight = true;
        }
      });
    });

    return placedPoints.map(({ parent, labelPosition }) => {
      const { x, y: y1 } = labelPosition;
      const { point } = parent;

      return {
        point: {
          point,
          position: { x, y: y1 },
          style: {
            color: point.color?.toString(),
            fontSize: isSmallHeight ? 10 : 12,
          },
        },
        parent: { point, position: { x, y: y1 } },
      };
    });
  }

  export function placeLabelsInRows(
    groupedSeries: LabelsPlacement.GroupedPoints,
    plotLeft: number,
    plotWidth: number
  ): LabelsPlacement.PointWithParent[] {
    const previouslyReservedRows: number[] = [];
    const _groupedSeriesValues = Object.values(groupedSeries);
    const categorySize = _groupedSeriesValues.at(0)?.length;

    if (!categorySize) {
      return [];
    }

    const isSingleSeries = categorySize === 1;
    const allPoints = _groupedSeriesValues.flat();

    const series: LabelsPlacement.PointWithPosition[][] = isSingleSeries ? [allPoints] : _groupedSeriesValues;

    const reservedRowsMaxLength = getMaxReservedRowsNumber(plotWidth, allPoints.length);

    const minValue = 0;
    const maxValue = Math.max(
      ...allPoints
        .map((point: LabelsPlacement.PointWithPosition) => point.position.y)
        .filter((value) => value !== null || value > 0)
    );

    const rowsNumber = Math.floor((maxValue - minValue) / LabelsPlacement.DATA_LABEL_HEIGHT);
    const placedPoints: PlacedPoint[] = [];
    let maxReservedRow = 0;
    let isSmallHeight = false;

    const maxCalculationFunction = isSingleSeries ? Math.max : Math.min;

    series.forEach((points: LabelsPlacement.PointWithPosition[]) => {
      const maxInGroup = maxCalculationFunction(
        ...points
          .map((point: LabelsPlacement.PointWithPosition) => point.position.y)
          .filter((value) => value !== null || value > 0)
      );

      points.forEach((positionedPoint: LabelsPlacement.PointWithPosition) => {
        // Filter zero-value.
        if (positionedPoint.point.y === null) {
          return;
        }

        const rowNumber = Math.floor(
          (maxValue - Math.min(positionedPoint.position.y, maxInGroup)) / LabelsPlacement.DATA_LABEL_HEIGHT
        );

        if (rowNumber || rowNumber === 0) {
          const columnWidth = positionedPoint.point.shapeArgs ? positionedPoint.point.shapeArgs.width / 2 : 0;

          // Highcharts does not allow to calculate X coordinate for each column in category,
          //  it can calculate only X coordinate for all columns in category.
          //  'barX' is X coordinate of current column, calculated internally.
          const x = (positionedPoint.point?.barX || positionedPoint.position.x) + plotLeft + columnWidth;
          const y1 = positionedPoint.position.y;

          let y2: number;

          if (!previouslyReservedRows.includes(rowNumber)) {
            y2 = (rowsNumber - rowNumber) * LabelsPlacement.DATA_LABEL_HEIGHT - chartTopMargin;

            if (previouslyReservedRows.length === reservedRowsMaxLength) {
              previouslyReservedRows.shift();
            }

            previouslyReservedRows.push(rowNumber);
            maxReservedRow = maxReservedRow >= rowNumber ? maxReservedRow : rowNumber;

            if (y2 > 0 && y1 > 0) {
              placedPoints.push({
                parent: positionedPoint,
                labelPosition: {
                  x,
                  y: y2,
                  rowNumber,
                },
              });
            }
          } else {
            let index = 0;

            do {
              index++;
            } while (previouslyReservedRows.includes(rowNumber + index));

            y2 = (rowsNumber - rowNumber - index) * LabelsPlacement.DATA_LABEL_HEIGHT - chartTopMargin;

            if (previouslyReservedRows.length === reservedRowsMaxLength) {
              previouslyReservedRows.shift();
            }

            previouslyReservedRows.push(rowNumber + index);
            maxReservedRow = maxReservedRow >= rowNumber + index ? maxReservedRow : rowNumber + index;

            if (y2 > 0 && y1 > 0) {
              placedPoints.push({
                parent: positionedPoint,
                labelPosition: {
                  x,
                  y: y2,
                  rowNumber: rowNumber + index,
                },
              });
            }
          }

          if (!isSmallHeight && y1 < LabelsPlacement.DATA_LABEL_HEIGHT && y1 > 0) {
            isSmallHeight = true;
          }
        }
      });
    });

    const labelsHeight = maxReservedRow * LabelsPlacement.DATA_LABEL_HEIGHT;
    const dataLabelHeight =
      labelsHeight > maxValue ? Math.min(maxValue / maxReservedRow, 10) : LabelsPlacement.DATA_LABEL_HEIGHT;

    return placedPoints.map(({ parent, labelPosition }) => {
      const { x, y: y1, rowNumber } = labelPosition;
      const {
        position: { y: y2 },
        point,
      } = parent;

      const newY = isSmallHeight ? y1 + (rowNumber + 1) * (LabelsPlacement.DATA_LABEL_HEIGHT - dataLabelHeight) : y1;

      return {
        point: {
          point,
          position: { x, y: Math.min(newY, y2) },
          style: {
            color: isSingleSeries ? undefined : point.color?.toString(),
            fontSize: isSmallHeight ? 10 : 12,
          },
        },
        parent: { point, position: { x, y: y2 } },
      };
    });
  }

  function getPreliminaryChartData(series: SeriesData[], plotHeight: number): number[][] {
    const clearSeries = series
      .map((serie) => serie.data.filter<number>((value): value is number => value !== null))
      .filter((serie) => serie.length > 0);

    if (clearSeries.length === 0) {
      return [];
    }

    const transposedSeries = clearSeries[0].map((_, ind) => clearSeries.map((serie) => serie[ind]));

    const maxValue = Math.max(...transposedSeries.map((val) => Math.max(...val)));
    const basis = maxValue > 0 ? plotHeight / maxValue : 0;

    return transposedSeries.map((val) => val.map((value) => plotHeight - basis * value));
  }

  /**
   * The function calculates the maxPadding value based on business data.
   * It's necessary so that maxPadding is defined before the chart is rendered
   */

  export function precalcMaxPadding(seriesData: SeriesData[], plotWidth: number, plotHeight: number): number {
    const formattedData = getPreliminaryChartData(seriesData, plotHeight);

    const previouslyReservedRows: number[] = [];
    const categorySize = formattedData[0]?.length;

    if (!categorySize) {
      return 0;
    }

    const isSingleSeries = categorySize === 1;
    const allPoints = formattedData.flat();
    const series = isSingleSeries ? [allPoints] : formattedData;

    const reservedRowsMaxLength = getMaxReservedRowsNumber(plotWidth, allPoints.length);

    const minValue = 0;
    const maxValue = Math.max(...allPoints);

    const rowsNumber = Math.floor((maxValue - minValue) / LabelsPlacement.DATA_LABEL_HEIGHT);

    const placedPoints: {
      rowNumber: number;
      y: number;
    }[] = [];

    const maxCalculationFunction = isSingleSeries ? Math.max : Math.min;

    series.forEach((points) => {
      const minOrMaxInGroup = maxCalculationFunction(...points.filter((value) => value !== null || value > 0));

      points.forEach((positionedPoint) => {
        if (positionedPoint === null) {
          return;
        }

        const rowNumber = Math.floor(
          (maxValue - Math.min(positionedPoint, minOrMaxInGroup)) / LabelsPlacement.DATA_LABEL_HEIGHT
        );

        if (rowNumber || rowNumber === 0) {
          let y2: number;

          if (!previouslyReservedRows.includes(rowNumber)) {
            y2 = (rowsNumber - rowNumber) * LabelsPlacement.DATA_LABEL_HEIGHT - chartTopMargin;

            if (previouslyReservedRows.length === reservedRowsMaxLength) {
              previouslyReservedRows.shift();
            }

            previouslyReservedRows.push(rowNumber);

            placedPoints.push({
              y: y2,
              rowNumber,
            });
          } else {
            let index = 1;

            while (previouslyReservedRows.includes(rowNumber + index)) {
              index += 1;
            }

            y2 = (rowsNumber - rowNumber - index) * LabelsPlacement.DATA_LABEL_HEIGHT - chartTopMargin;

            if (previouslyReservedRows.length === reservedRowsMaxLength) {
              previouslyReservedRows.shift();
            }

            const newRowNumber = rowNumber + index;

            previouslyReservedRows.push(newRowNumber);

            placedPoints.push({
              y: y2,
              rowNumber: newRowNumber,
            });
          }
        }
      });
    });

    const minY = Math.min(...placedPoints.map((point) => point.y));
    const pointsMinValue = Math.abs(minY) + LabelsPlacement.DATA_LABEL_HEIGHT + chartTopMargin;

    return pointsMinValue / plotHeight;
  }
}
