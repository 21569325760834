import { agent } from '../agent';
import { TAttrConcatRefs } from '../types';
import { throwApiError } from '../utils';

export class AddRigSidebarApi {
  async getRigs(
    planVersionId: string | number,
    searchString?: string,
    abortSignal?: AbortSignal
  ): Promise<AddRigSidebar.RigObject[]> {
    try {
      const { data } = await agent.get<AddRigSidebar.RigObject[]>('rig/idle', {
        signal: abortSignal,
        params: {
          planVersionId,
          ...(searchString && { searchString: searchString }),
        },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}

export namespace AddRigSidebar {
  export type RigView = {
    attrName?: string;
    attrConcat?: string[];
    attrConcatRefs?: TAttrConcatRefs;
    delimiter?: string;
  };

  export type View = {
    rig: RigView;
  };

  export type DurationByStage = {
    duration: number;
    wellLifeCycleStageId: number;
  };

  export type RigData = {
    brigadeId: number;
    drillingContractorId: number;
    driveTypeId: number;
    durationByStage: DurationByStage[];
    isTds?: boolean;
    modelId: number;
    name: string;
    rigNumber: string;
    typeId: number;
  };

  export type RigObject = {
    id: number;
    data: RigData;
    createdAt: number;
    objectType: string;
    status: string;
  };

  export type Rig = RigData & {
    id: number;
  };
}
