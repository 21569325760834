import { RefObject, useContext, useEffect } from 'react';

import { DndContext } from '../context/dnd-context';

export function useDragging<TDraggingItem>({ id, item, nodeRef }: UseDragging.Args<TDraggingItem>): void {
  const { registerDragging, unregisterDragging } = useContext(DndContext);

  useEffect(() => {
    registerDragging(id, nodeRef, item);

    return () => {
      unregisterDragging(id);
    };
  }, [id, item, nodeRef, registerDragging, unregisterDragging]);
}

export namespace UseDragging {
  export type Args<TDraggingItem> = {
    id: string;
    item: TDraggingItem;
    nodeRef: RefObject<HTMLElement>;
  };
}
