import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { HeaderViewType, PlanningWellType, ComparePlanningWellType, PlanningListDataType } from 'src/api/planning';
import { TableStore as Table } from 'src/store/table';
import { ColumnType } from 'src/store/table/types';

export class Pad {
  @observable headerView: HeaderViewType[];
  @observable dataSource: PlanningListDataType;

  readonly id: number;
  readonly name: string;
  readonly key: string;
  readonly table: Table;

  constructor(
    id: number,
    dataSource: PlanningListDataType,
    columns: ColumnType[],
    items: PlanningWellType[] | ComparePlanningWellType[],
    headerView: HeaderViewType[]
  ) {
    this.id = id;
    this.key = uuidv4();
    this.dataSource = dataSource;
    this.table = new Table(columns, items);
    this.headerView = headerView;
    this.name = dataSource.name;

    makeObservable(this);
  }

  @computed
  get subTitles(): string[] {
    // TODO Прописать, когда будут доступны соответствующие свойства
    return [];
  }

  @action.bound
  setTableView(tableView: ColumnType[]): void {
    this.table.setColumnsData(tableView);
  }
}
