import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { hasValue } from 'src/shared/utils/common';

import styles from './ellipsis-table-cell.module.scss';

interface Props {
  value?: string | number | null;
  onClick?(): void;
  isDeleted?: boolean;
  maxRows?: number;
}

export const EllipsisTableCell = observer(function EllipsisTableCell({ value, onClick, isDeleted, maxRows }: Props) {
  return (
    <div className={styles.container}>
      <Typography.Paragraph ellipsis={{ rows: maxRows ?? 3 }} delete={isDeleted} onClick={onClick}>
        {hasValue(value) && String(value).length ? value : '-'}
      </Typography.Paragraph>
    </div>
  );
});
