import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/shared/components/button';
import { getMailtoHref } from 'src/shared/utils/get-mailto-href';
import { useStore } from 'src/store';

import styles from './combobox-dropdown.module.scss';

type Props = {
  fieldId?: string;
  directoryName?: string;
};

export const ComboboxDropdownButton = observer(function ComboboxDropdownButton({ fieldId, directoryName }: Props) {
  const { t } = useTranslation();

  const rootStore = useStore();

  const directoryLabel = fieldId ? rootStore.directories.getFieldLabel(fieldId) : directoryName || '';

  const mailtoHref = getMailtoHref(directoryLabel || '', t);

  return (
    <Button variant="default" as="a" className={styles.button} href={mailtoHref}>
      <p className={styles.buttonText}>{t('newWellForm:Dropdowns.addNewValueToDirectory', { directoryLabel })}</p>
    </Button>
  );
});
