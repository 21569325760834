import { action, makeObservable, observable } from 'mobx';
import { TFunction } from 'react-i18next';

import { copyText } from 'src/shared/utils/copy-text';

type TAddNewValueToDirectoryModalData = {
  directory: string;
  values: string[];
};

const testEmail = 'kozlov.v@mail.ru';

const DEFAULT_DATA = {
  directory: '',
  values: [],
};

export class AddNewValueToDirectoryModalController {
  @observable isOpened: boolean;
  @observable data: TAddNewValueToDirectoryModalData;

  constructor() {
    this.isOpened = false;
    this.data = DEFAULT_DATA;

    makeObservable(this);
  }

  sendLetter = (t: TFunction) => {
    const link = document.createElement('a');
    link.href = `mailto:${testEmail}?subject=${t('newWellForm:Modals.AddNewValueToDirectory.subject', {
      directory: this.data.directory,
    })}`;
    link.click();
    link.remove();
  };

  copyData() {
    const values = this.data.values.map((value, index) => `${index + 1}. ${value}`).join('\n');

    const text = `${this.data.directory}\n\n${values}`;

    copyText(text);
  }

  @action.bound
  onOpenModal(data: TAddNewValueToDirectoryModalData) {
    this.data = data;
    this.isOpened = true;
  }

  @action.bound
  onCloseModal() {
    this.isOpened = false;
  }
}
