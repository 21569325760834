import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';

import styles from './summary-data.module.scss';

interface Props {
  item: ChartRig | RigsGroup;
  viewport: Viewport;
  hasChanges?: boolean;
}

export const SummaryDataRowWrapper = observer(function SummaryDataRowWrapper({
  item,
  viewport,
  children,
  hasChanges = false,
}: PropsWithChildren<Props>) {
  const ref = useMovableElement({
    item,
    verticalViewport: viewport,
  });

  return <div ref={ref} className={clsx(styles.row, hasChanges && styles.rowComparing)} children={children} />;
});
