import { autorun } from 'mobx';

import { getArgumentsValues } from 'src/shared/utils/formula-calculation-tools';

import { Item, ValidatableItem } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

const checkIfControllingItemHasDirectoryAttributes = (
  control: Item
): control is Item & {
  refObjectType: string;
} => {
  if ('refObjectType' in control) {
    return true;
  }
  return false;
};

export class EnableIfPlugin extends FormPlugin {
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.enableIf) {
        const disposer = autorun(() => {
          if (!item.enableIf) {
            return;
          }
          const isItemDisabled = item.enableIf.some((cond) => {
            if (cond.type === 'regular') {
              const controllingItem = form.fields[cond.control];
              if (!controllingItem) {
                return true;
              }

              if (cond.attr && checkIfControllingItemHasDirectoryAttributes(controllingItem)) {
                const directory = this.rootStore.directories.getObject(controllingItem.refObjectType);
                const directoryValue = directory?.find((dirValue) => dirValue.id === controllingItem.value);
                if (!directoryValue || !directoryValue.data[cond.attr]) {
                  return true;
                }
                return directoryValue.data[cond.attr] !== cond.value;
              } else {
                return controllingItem.value !== cond.value;
              }
            } else if (cond.type === 'formula') {
              const args = getArgumentsValues(form.fields, this.rootStore.directories, cond.arguments);

              const argsNames = Object.keys(args).join(', ');
              const argsValues = Object.values(args);

              // eslint-disable-next-line no-new-func
              const getControlValue = new Function(argsNames, cond.body);

              return !getControlValue(...argsValues);
            }

            return true;
          });
          item.setIsDisabled({ flagId: this.pluginId, value: isItemDisabled });
          if (isItemDisabled) {
            item.clearItem();
            if (item instanceof ValidatableItem) {
              item.clearError();
            }
          }
        });

        disposers.push(disposer);
      }
      if (item.visuallyDisabledInstructions) {
        const disposer = autorun(() => {
          if (!item.visuallyDisabledInstructions) {
            return;
          }
          const isItemVisuallyDisabled = item.visuallyDisabledInstructions.some((cond) => {
            const controllingItem = form.fields[cond.control];
            if (!controllingItem) {
              return true;
            }
            if (cond.attr && checkIfControllingItemHasDirectoryAttributes(controllingItem)) {
              const directory = this.rootStore.directories.getObject(controllingItem.refObjectType);
              const directoryValue = directory?.find((dirValue) => dirValue.id === controllingItem.value);
              if (!directoryValue || !directoryValue.data[cond.attr]) {
                return true;
              }
              return directoryValue.data[cond.attr] !== cond.value;
            } else {
              return controllingItem.value === cond.value;
            }
          });
          item.setIsVisuallyDisabled({ flagId: this.pluginId, value: isItemVisuallyDisabled });
          if (isItemVisuallyDisabled && item instanceof ValidatableItem) {
            item.clearError();
          }
        });

        disposers.push(disposer);
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
