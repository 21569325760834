import { UserSettingsColumnsGroupType } from 'src/pages/wells-page/types';
import { TRefQuery } from 'src/store/directories/types';
import { COLUMN_CONTROL, CHANGED_VARIANT, CellValueType, COLUMN_TYPE } from 'src/store/table/types';

export type WellsListViewType = {
  rowIdKey: string;
  tabs: WellsTabItemType[];
};

export type CompareWellItemType = {
  first: CellValueType;
  second: CellValueType;
};

export type WellType = Record<string, CellValueType>;

export type WellsListResponseType = {
  total: number;
  wells: WellType[];
};

export type CompareWellsListResponseType = {
  total: number;
  wells: CompareWellType[];
};

export type IndexedWellType = Record<string, CellValueType | CompareWellItemType> & {
  index: number;
};

export type CompareWellType = Record<string, CellValueType | CompareWellItemType> & {
  rowChanged?: null | CHANGED_VARIANT;
};

export type WellsTabItemType = {
  columns: ParentColumnViewType[];
  fieldId: string;
  withRigOperation: boolean;
};

export type EnableParamType = {
  attr: string;
  value: string | number | boolean;
};

export type WellColumnViewType = {
  attrName: string;
  fieldId: string;
  control: COLUMN_CONTROL;
  type: COLUMN_TYPE;
  label: string;
  excludeFromSettings?: boolean;
  numberOfDecimalPlaces?: number;
  isChangable?: true;
  isMassEditable?: boolean;
  refObjectAttr?: string;
  refObjectType?: string;
  refQuery?: TRefQuery;
  useInMainProgressBar?: true;
  cast?: string;
  enableIf?: EnableParamType[];
  restrictions?: RestrictionsType;
};

export type RestrictionsType = Partial<{
  required: boolean;
  higherThen: number;
  lowerThan: number;
}>;

export type ParentColumnViewType = {
  innerColumns: WellColumnViewType[];
  title: string;
  wrapped: boolean;
  fieldId: string;
};

export type UserSettingsType = Record<string, UserSettingsColumnsGroupType[]>;

export type UserSettingsResponseType = {
  id: number;
  objectType: string;
  status: string;
  data: UserSettingsType;
};

export type WellObjectType = {
  objectType: string;
  id: number;
  oldId: number | null;
};

export type WellsChangesResponseType = {
  idList: WellObjectType[];
  conflictRigOperation: unknown;
};

export type IndexedWellObjectType = {
  index: number;
  objects: WellObjectType[];
};

export enum OBJECT_TYPE {
  rigOperation = 'RIGOPERATION',
  well = 'WELL',
  pad = 'PAD',
  geologicalTask = 'GEOLOGICALTASK',
}

export type RemovableRigObjectType = {
  objectType: string;
  id: number;
  oldId: number;
};
