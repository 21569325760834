import { action, computed, flow, makeObservable, observable } from 'mobx';

import { TJob, getJobList, TJobStatus, TJobResult, cancelJob, getJobInfo } from 'src/api/import-file';

import { ImportJob } from './import-job';

export interface IJob {
  readonly id: number;

  timerId: number | null;
  status: TJobStatus;
  result: TJobResult;

  update(jobData: TJob): Promise<void>;
}

const JOB_SYNC_INTERVAL = 3000;

export class JobsStore {
  @observable private importJob: ImportJob | null = null;

  jobDisposers: Map<IJob, VoidFunction> = new Map();

  constructor() {
    makeObservable(this);
  }

  private async loadJobs(): Promise<void> {
    try {
      const rawJobs = await getJobList();

      for (const job of rawJobs) {
        await this.registerJob(job);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action.bound
  private stopJobSync(job: IJob): void {
    if (job.timerId !== null) {
      window.clearInterval(job.timerId);
      job.timerId = null;
    }
  }

  @action.bound
  private initJob(job: IJob): VoidFunction {
    this.stopJobSync(job);

    job.timerId = window.setInterval(() => this.syncJob(job), JOB_SYNC_INTERVAL);

    return () => {
      this.stopJobSync(job);
    };
  }

  @flow.bound
  async *syncJob(job: IJob) {
    const jobData = await getJobInfo(job.id);
    yield;

    const completedJobStatuses: TJobStatus[] = ['CANCELLED', 'COMPLETED', 'ERROR'];

    if (completedJobStatuses.includes(job.status)) {
      this.stopJobSync(job);
    }

    await job.update(jobData);
  }

  @action.bound
  private async createJob(job: TJob): Promise<IJob | null> {
    try {
      // ERROR временно убран
      // const activeJobStatuses: TJobStatus[] = ['COMPLETED', 'ERROR', 'IN_PROGRESS', 'NEW'];
      const activeJobStatuses: TJobStatus[] = ['COMPLETED', 'IN_PROGRESS', 'NEW'];

      if (job.jobType === 'IMPORT_FROM_EXCEL' && activeJobStatuses.includes(job.jobStatus)) {
        const importJob = new ImportJob(job);

        await importJob.update(job);

        this.importJob = importJob;

        return this.importJob;
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  @computed
  get hasActiveImportJob(): boolean {
    // ERROR временно убран
    // const activeJobStatuses: TJobStatus[] = ['IN_PROGRESS', 'NEW', 'COMPLETED', 'ERROR'];
    const activeJobStatuses: TJobStatus[] = ['IN_PROGRESS', 'NEW', 'COMPLETED'];

    return !!this.importJob && activeJobStatuses.includes(this.importJob.status);
  }

  @computed
  get activeImportJob(): ImportJob | null {
    if (this.hasActiveImportJob) {
      return this.importJob;
    }

    return null;
  }

  @flow.bound
  async *registerJob(rawJob: TJob): Promise<IJob | null> {
    try {
      const job = await this.createJob(rawJob);
      yield;

      if (job) {
        const disposeJob = this.initJob(job);

        this.jobDisposers.set(job, disposeJob);
      }

      return job;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  @flow.bound
  async *cancelJob(jobId: number) {
    try {
      const job: IJob | null = this.importJob?.id === jobId ? this.importJob : null;

      if (!job) {
        return;
      }

      await cancelJob(job.id);
      yield;

      this.stopJobSync(job);

      if (job instanceof ImportJob) {
        this.importJob = null;
      }
    } catch (error) {
      yield;
      console.error(error);
    }
  }

  init = async (): Promise<void> => {
    await this.loadJobs();
  };
}
