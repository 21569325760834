import { action, autorun, makeObservable } from 'mobx';

import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';

import { Item } from '../../../../shared/entities/abstract-control-entities';
import { PassingIntervalField } from '../../../../shared/entities/control-entities';
import { FormStore } from '../../entities/form.entity';
import { FormPlugin } from '../abstract-form-plugin.entity';

type TTotalDurationAndTotalPassingValue = {
  duration: number | null;
  passingValue: number | null;
};

export class SpeedPerDayFormulaPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.fieldId === 'speedPerDay') {
        const approachesList = form.approachesTab?.approachesList;

        if (!!approachesList) {
          const disposer = autorun(() => {
            if (item.value === 0) {
              item.setValue(null);
            }

            const isItemDisabled = (() => {
              return (
                approachesList.approaches.length > 1 ||
                (!!approachesList.approaches.length &&
                  approachesList.approaches[0].stagesList.stages.some((s) => s.sectionsList.sections.length))
              );
            })();

            if (isItemDisabled) {
              item.setIsVisuallyDisabled({ flagId: `speedPerDayFormula_${item.fieldId}`, value: true });
            } else {
              item.setIsVisuallyDisabled({ flagId: `speedPerDayFormula_${item.fieldId}`, value: false });

              return;
            }

            const speedPerDayValues: TTotalDurationAndTotalPassingValue = approachesList.approaches.reduce(
              (prevAppr, curAppr) => {
                const approachValues: TTotalDurationAndTotalPassingValue = curAppr.stagesList.stages.reduce(
                  (prevStage, currStage) => {
                    const stageValues: TTotalDurationAndTotalPassingValue = (() => {
                      if (!currStage.sectionsList.sections.length) {
                        const duration = currStage.dateField?.duration ?? null;
                        const passingValue = currStage.passingField?.value ?? null;

                        return { duration, passingValue };
                      }

                      return currStage.sectionsList.sections.reduce(
                        (prevSec, currSec) => {
                          const dateField = currSec.dateField;
                          const passingField: PassingIntervalField | undefined = currSec.fieldsList.find(
                            (field): field is PassingIntervalField => field instanceof PassingIntervalField
                          );
                          const passingFieldValue = passingField?.passingValue;

                          const duration = (() => {
                            if (!hasValue(prevSec.duration)) {
                              return hasValue(dateField?.duration) && dateField ? dateField.duration : null;
                            } else {
                              return hasValue(dateField?.duration) && dateField
                                ? prevSec.duration + dateField.duration
                                : prevSec.duration;
                            }
                          })();

                          const passingValue = (() => {
                            if (!hasValue(prevSec.passingValue)) {
                              return hasValue(passingFieldValue) ? passingFieldValue : null;
                            } else {
                              return hasValue(passingFieldValue)
                                ? prevSec.passingValue + passingFieldValue
                                : prevSec.passingValue;
                            }
                          })();

                          return {
                            duration,
                            passingValue,
                          };
                        },
                        {
                          duration: null,
                          passingValue: null,
                        } as { duration: number | null; passingValue: number | null }
                      );
                    })();

                    const duration = (() => {
                      if (!hasValue(prevStage.duration)) {
                        return hasValue(stageValues?.duration) ? stageValues.duration : null;
                      } else {
                        return hasValue(stageValues?.duration)
                          ? prevStage.duration + stageValues.duration
                          : prevStage.duration;
                      }
                    })();

                    const passingValue = (() => {
                      if (!hasValue(prevStage.passingValue)) {
                        return hasValue(stageValues.passingValue) ? stageValues.passingValue : null;
                      } else {
                        return hasValue(stageValues.passingValue)
                          ? prevStage.passingValue + stageValues.passingValue
                          : prevStage.passingValue;
                      }
                    })();

                    return {
                      duration,
                      passingValue,
                    };
                  },
                  {
                    duration: null,
                    passingValue: null,
                  } as { duration: number | null; passingValue: number | null }
                );

                const duration = (() => {
                  if (!hasValue(prevAppr.duration)) {
                    return hasValue(approachValues?.duration) ? approachValues.duration : null;
                  } else {
                    return hasValue(approachValues?.duration)
                      ? prevAppr.duration + approachValues.duration
                      : prevAppr.duration;
                  }
                })();

                const passingValue = (() => {
                  if (!hasValue(prevAppr.passingValue)) {
                    return hasValue(approachValues.passingValue) ? approachValues.passingValue : null;
                  } else {
                    return hasValue(approachValues.passingValue)
                      ? prevAppr.passingValue + approachValues.passingValue
                      : prevAppr.passingValue;
                  }
                })();

                return {
                  duration,
                  passingValue,
                };
              },
              {
                duration: null,
                passingValue: null,
              } as { duration: number | null; passingValue: number | null }
            );

            const speedPerDay = (() => {
              if (
                !hasValue(speedPerDayValues.duration) ||
                !hasValue(speedPerDayValues.passingValue) ||
                speedPerDayValues.duration === 0 ||
                speedPerDayValues.passingValue === 0
              ) {
                return null;
              }

              return (1000 * speedPerDayValues.duration) / speedPerDayValues.passingValue;
            })();

            item.tryToSetRawValue(speedPerDay);
          });
          disposers.push(disposer);
        }
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
