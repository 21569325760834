import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConflictWell } from 'src/api/draft/conflict-well';

import { AddWellButton } from '../add-well-button';
import { Pad } from '../store/pad';
import { RigConflictsSidebarViewProvider } from '../store/rig-conflicts-sidebar-view-provider';
import { RigStore } from '../store/rig.store';

import { Pad as PadElement } from './pad';
import { Well as WellElement } from './well';

import styles from './pads.module.scss';

interface Props {
  store: RigStore;
  viewProvider: RigConflictsSidebarViewProvider;
}

export const Pads = observer(function Pads({ store, viewProvider }: Props) {
  const { t } = useTranslation('drawers');

  function onWellPlace(index: number): void {
    store.placePadWithWell(index);
  }

  function isAddButtonShown(padOrWell: Pad | ConflictWell, index: number): boolean {
    if (padOrWell instanceof ConflictWell) {
      return true;
    }

    const isTopPad = index === 0;
    const previousPad = store.pads.at(index - 1);

    if (!isTopPad && previousPad instanceof ConflictWell) {
      return true;
    }

    return false;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.addButtonContainer}>
          <p className={styles.hint}>{t('drillingRigDrawer.hint')}</p>
        </div>

        {store.pads.map((padOrWell, index) => (
          <React.Fragment key={padOrWell.id}>
            {!isAddButtonShown(padOrWell, index) && <AddWellButton onClick={() => onWellPlace(index)} />}

            {padOrWell instanceof Pad ? (
              <PadElement store={padOrWell} viewProvider={viewProvider} placeWell={store.placeWell} />
            ) : (
              <WellElement well={padOrWell} viewProvider={viewProvider} className={styles.freeWell} />
            )}
          </React.Fragment>
        ))}

        {!(store.pads.at(-1) instanceof ConflictWell) && (
          <AddWellButton onClick={() => onWellPlace(store.pads.length)} />
        )}
      </div>
    </div>
  );
});
