import { autorun } from 'mobx';

import { hasValue } from 'src/shared/utils/common';
import { getDynamicJoinDependencies } from 'src/shared/utils/get-dynamic-join-dependencies';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';

import { Combobox, Item } from '../../../shared/entities/abstract-control-entities';

import { FormPlugin, IForm } from './abstract-form-plugin.entity';

export class DynamicJoinPlugin extends FormPlugin {
  connect(form: IForm): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item instanceof Combobox && item.refQuery && isDynamicJoin(item.refQuery)) {
        const refQuery = item.refQuery;

        const dependencies = getDynamicJoinDependencies(refQuery);
        const disposer = autorun(async () => {
          const dependenciesObject: Record<string, unknown> = {};

          for (const dep of dependencies.outer) {
            const key = dep.slice(1);

            if (!this.rootStore.globalVariables) {
              break;
            }
            const value = this.rootStore.globalVariables.variables[key];

            if (hasValue(value)) {
              dependenciesObject[dep] = value;
            }
          }

          for (const dep of dependencies.fieldId) {
            const key = dep.slice(1);
            const value = form.fields[key].value;

            if (hasValue(value)) {
              dependenciesObject[dep] = value;
            }
          }

          for (const dep of dependencies.attrs) {
            const key = dep.slice(1);
            const value = form.fields[key]?.value;

            if (hasValue(value)) {
              dependenciesObject[dep] = value;
            }
          }
          const joinRes = await this.rootStore.directories.fetchDynamicJoinObject(refQuery, dependenciesObject);
          item.setDirectory(joinRes);
        });
        disposers.push(disposer);
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
