import { PointerEventHandler, RefObject, useCallback, useEffect, useRef, useState, WheelEventHandler } from 'react';

import { useDisableSafariTouchSelectionFix } from '../../../shared/hooks/use-disable-safari-touch-selection-fix';
import { useZoomMode } from '../features/header/view/header.utils';
import { Range } from '../layers/model';
import { Pointer } from '../layers/presenter';

import { pixelsToMilliseconds } from './viewport/viewport-calculator';

interface UseMovableContainerParams {
  horizontalViewport?: Range<number>;
  containerElement?: HTMLDivElement;
  defaultElementRef?: RefObject<HTMLDivElement>;
  onPointerDown?(): void;
  onCancel?(): void;
  onBlur?(): void;
  onPointerMove?(offsetX: number, offsetY?: number): void;
  onPointerUp?(): void;
  onScroll?(offset: number): void;
  onZoom?(offset: number, centerPosition: number): void;
  onZoomOut?: VoidFunction;
}

export const useMovableContainer = ({
  horizontalViewport,
  containerElement,
  defaultElementRef,
  onPointerDown,
  onPointerMove,
  onPointerUp,
  onScroll,
  onZoom,
  onBlur,
  onCancel,
  onZoomOut,
}: UseMovableContainerParams) => {
  const ref = useRef<HTMLDivElement>(null);
  const elementRef = defaultElementRef ?? ref;
  // Several pointers. Needed for multi-touch zoom on touch devices.
  const pointersCache = useRef<Pointer<number>[]>([]);
  // Only first pointer. Needed for determine zoom focus point on desktop devices.
  const pointer = useRef<Pointer<number> | null>();

  const { isZoomMode: isDesktopZoomMode, cancelZoomMode: cancelDesktopZoomMode } = useZoomMode({ onZoomOut });

  // Distance between two pointers. Required for zoom on touch devices.
  const [prevOffset, setPrevOffset] = useState<number | null>(null);

  const { disableSelectAbility, returnSelectAbility } = useDisableSafariTouchSelectionFix();

  const handlePointerDown: PointerEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (!onPointerDown) {
      throw new Error('Pointer down handler not passed!');
    }

    if (!e.defaultPrevented) {
      e.currentTarget.setPointerCapture(e.pointerId);
    }

    if (!elementRef.current) {
      return;
    }

    pointer.current = {
      id: e.pointerId,
      coordinate: {
        x: e.clientX,
        y: e.clientY,
      },
    };

    pointersCache.current.push({
      id: e.pointerId,
      coordinate: {
        x: e.clientX,
        y: e.clientY,
      },
    });

    onPointerDown();
  };

  const handlePointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (!onPointerMove) {
      throw new Error('Pointer move handler not passed!');
    }

    e.currentTarget.setPointerCapture(e.pointerId);

    const container = containerElement || elementRef.current;

    if (!container || !horizontalViewport) {
      return;
    }

    for (let pointer of pointersCache.current) {
      if (pointer.id === e.pointerId) {
        pointer.id = e.pointerId;
        pointer.coordinate = {
          x: e.clientX,
          y: e.clientY,
        };
        break;
      }
    }

    const prevPointer = pointer.current;

    pointer.current = {
      id: e.pointerId,
      coordinate: {
        x: e.clientX,
        y: e.clientY,
      },
    };

    const { width: containerWidth, left: containerLeft } = container.getBoundingClientRect();

    if (pointersCache.current.length === 2 && onZoom) {
      // Multi-touch zoom.
      const [firstPointer, secondPointer] = pointersCache.current;
      const offset = Math.abs(firstPointer.coordinate.x - secondPointer.coordinate.x) / 2;

      if (prevOffset !== null) {
        const center = Math.min(firstPointer.coordinate.x, secondPointer.coordinate.x) - containerLeft + offset / 2;
        const centerInMilliseconds =
          horizontalViewport.start + pixelsToMilliseconds(center, horizontalViewport, containerWidth);

        // Zoom-out
        if (offset > prevOffset) {
          onZoom(pixelsToMilliseconds(-offset, horizontalViewport, containerWidth), centerInMilliseconds);
        }

        // Zoom-in
        if (offset < prevOffset) {
          onZoom(pixelsToMilliseconds(offset, horizontalViewport, containerWidth), centerInMilliseconds);
        }
      }

      setPrevOffset(offset);
    } else {
      // Horizontal movement.
      if (!prevPointer) {
        return;
      }

      disableSelectAbility();

      const isTouch = e.pointerType === 'touch';
      const offsetX = prevPointer.coordinate.x - e.clientX;

      if (isTouch && prevPointer?.coordinate.y) {
        // Only add y-offset for touch devices to scroll container vertically.
        const y = prevPointer.coordinate.y - e.clientY;
        onPointerMove(pixelsToMilliseconds(offsetX, horizontalViewport, containerWidth), y);
      } else {
        onPointerMove(pixelsToMilliseconds(offsetX, horizontalViewport, containerWidth));
      }
    }
  };

  const handlePointerUp: PointerEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (!onPointerUp) {
      throw new Error('Pointer up handler not passed!');
    }

    if (!e.defaultPrevented) {
      e.currentTarget.setPointerCapture(e.pointerId);
    }

    onPointerUp();

    pointersCache.current = pointersCache.current.filter(({ id }) => id !== e.pointerId);
    setPrevOffset(null);

    pointer.current = null;

    returnSelectAbility();
  };

  const handleScroll: WheelEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const container = containerElement || elementRef.current;

      if (!container) {
        return;
      }

      const { width: containerWidth, left: containerLeft } = container.getBoundingClientRect();

      if (isDesktopZoomMode && horizontalViewport) {
        if (pointer.current && onZoom) {
          const x = pointer.current.coordinate.x - containerLeft;
          const offsetInMilliseconds = pixelsToMilliseconds(e.deltaY, horizontalViewport, containerWidth);
          const centerInMilliseconds =
            horizontalViewport.start + pixelsToMilliseconds(x, horizontalViewport, containerWidth);

          onZoom(offsetInMilliseconds, centerInMilliseconds);
        }
      } else {
        onScroll?.(e.deltaY);
      }
    },
    [containerElement, horizontalViewport, isDesktopZoomMode, onScroll, onZoom, elementRef]
  );

  useEffect(() => {
    const container = containerElement || elementRef.current;

    const handleBlur = () => {
      cancelDesktopZoomMode();
      onBlur?.();
    };

    const handleCancel = () => {
      cancelDesktopZoomMode();
      onCancel?.();
    };

    container?.addEventListener('oncancel', handleCancel);
    container?.addEventListener('onblur', handleBlur);

    return () => {
      container?.removeEventListener('oncancel', handleCancel);
      container?.removeEventListener('onblur', handleBlur);
    };
  }, [cancelDesktopZoomMode, containerElement, onBlur, onCancel, elementRef]);

  return {
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleScroll,
    containerRef: elementRef,
  };
};
