import moment from 'moment';

import { TAddWellToChartSidebarView } from 'src/api/add-well-to-chart-sidebar/types';
import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { hasValue } from 'src/shared/utils/common';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { WellTypes } from 'src/types/well-types';

import { AddToChartSidebarPadEntity } from './pads/pad/pad.entity';
import { AddToChartSidebarWellEntity } from './pads/well/well.entity';

export type TPadObjectType = Omit<UnplannedRigOperationsSidebar.PadObjectType, 'data' | 'items'> & {
  data: Record<string, unknown>;
  items: WellTypes.RawRigOperation[];
};

export function getPadTitle(pad: TPadObjectType): string {
  if (pad.data['name'] && typeof pad.data['name'] === 'string') {
    return pad.data['name'];
  }

  return '';
}

export function getWellTitle(
  directories: Directories,
  view: UnplannedRigOperationsSidebar.WellsView,
  wellData: Record<string, unknown>
): string {
  const { attrName, refObjectAttr, refObjectType } = view;

  const attrValue = wellData[attrName];

  if (refObjectAttr && refObjectType) {
    const refObject = directories.getObject(refObjectType);
    const labelObject = refObject?.find(({ id }) => id.toString() === attrValue);
    const label = labelObject?.data[refObjectAttr];

    return label ? label.toString() : '';
  }

  return attrValue?.toString() || '';
}

export function getWellId(wellData: Record<string, unknown>): number | null {
  // Hardcode-attr
  const wellId = wellData['GOplan_GeologicalTask.id'];

  return typeof wellId === 'number' ? wellId : null;
}

export function getWellAttributes(
  view: UnplannedRigOperationsSidebar.WellsView,
  wellData: Record<string, unknown>
): string[] {
  const attributes: string[] = [];

  for (const attribute of view.attributes) {
    const attributeValue = wellData[attribute.objectName];

    if (!hasValue(attributeValue)) {
      continue;
    }

    if (attribute.type === 'DateTime') {
      if (typeof attributeValue === 'string') {
        attributes.push(moment.unix(parseInt(attributeValue)).format(DATE_FORMAT));
      }

      if (typeof attributeValue === 'number') {
        attributes.push(moment.unix(attributeValue).format(DATE_FORMAT));
      }
    }
  }

  return attributes;
}

export function mapPads(
  directories: Directories,
  appSettings: AppSettingsStore,
  view: TAddWellToChartSidebarView,
  pads: TPadObjectType[]
): AddToChartSidebarPadEntity[] {
  return pads.map((rawPad) => {
    const padTitle = getPadTitle(rawPad);
    const wells = rawPad.items.map((well) => {
      const wellView = view.pad.wells;
      const wellId = getWellId(well);
      const wellColor = getWellColor(well, appSettings.wellColorRules);
      const wellTitle = getWellTitle(directories, wellView, well);
      const wellAttributes = getWellAttributes(wellView, well);
      const wellTypeIcon = appSettings.getWellIcon(well, addGOplanPrefix('GeologicalTask.wellTypeId'));

      return new AddToChartSidebarWellEntity({
        view: wellView,
        wellData: well,
        color: wellColor,
        title: wellTitle,
        wellId,
        attributes: wellAttributes,
        wellTypeIcon,
      });
    });

    return new AddToChartSidebarPadEntity({ padTitle, rawPad, wells });
  });
}
