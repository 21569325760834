import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { LoadingTitle } from '../../../../../shared/components/loading-title/loading-title';
import { ComparingChartWell } from '../../../presets/comparing-drilling-wells-chart/entities';
import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingWellsData } from '../../comparing-wells-chart/data';
import { EmptyBlockWrapper } from '../../empty-block-wrapper';
import { GroupCollapseHeader } from '../../group-collapse-header';

import { RowHeaderWrapper } from './row-header-wrapper';

import styles from './data-headers.module.scss';

interface Props {
  item: ComparingWellsData.ViewItem;
  viewport: Viewport;
  viewProvider: WellsViewSettingsProvider;
  isCompactView: boolean;
  onIsCollapseToggle(item: WellsGroup): void;
}

export const ComparingWellsDataHeader = observer(function ComparingWellsDataHeader({
  item,
  viewport,
  viewProvider,
  isCompactView,
  onIsCollapseToggle,
}: Props) {
  if (item instanceof ComparingChartWell) {
    return (
      <EmptyBlockWrapper
        item={item}
        verticalViewport={viewport}
        className={clsx(styles.rowHeader, item.hasChanges && styles.rowHeaderComparing)}
      />
    );
  }

  if (item instanceof ChartWell) {
    return (
      <RowHeaderWrapper
        item={item}
        title={<LoadingTitle field={viewProvider.getWellTitle(item)} />}
        descriptionAttributes={viewProvider.getAttributes(item)}
        viewport={viewport}
        isCompactView={isCompactView}
      />
    );
  }

  if (item instanceof WellsGroup) {
    return (
      <GroupCollapseHeader
        key={item.id}
        item={item}
        isCollapsed={item.isCollapsed}
        title={<LoadingTitle field={viewProvider.getGroupTitle(item)} />}
        viewport={viewport}
        onClick={() => onIsCollapseToggle(item)}
        withArrow={true}
      />
    );
  }

  return null;
});
