import { action, computed, makeObservable, observable } from 'mobx';

import { CompareWellType, WellType } from 'src/api/wells-list';
import { hasValue } from 'src/shared/utils/common';
import { ColumnType, ResizeParamsType, TableRow, CellValueType } from 'src/store/table/types';
import { mapTableData } from 'src/store/table/utils';

export class TableStore {
  @observable loading: boolean = false;
  @observable isValidationError: boolean = false;
  @observable columnsData: ColumnType[] = [];
  @observable tableData: TableRow[] = [];
  @observable resizeParams: ResizeParamsType = {
    isResize: false,
    leftOffset: null,
    columnIndex: null,
    columnWidth: null,
    minColumnWidth: null,
    leftColumnCoordinates: null,
    startCoordinates: null,
    xCoordinates: null,
  };

  constructor(columnsData?: ColumnType[], tableData?: CompareWellType[] | WellType[]) {
    if (columnsData) this.columnsData = columnsData;
    if (tableData) this.tableData = mapTableData(tableData);

    makeObservable(this);
  }

  @computed
  get isSelectedAllRows(): boolean {
    return this.tableData.every((row) => row.selected);
  }

  @computed
  get selectedRows(): TableRow[] {
    return this.tableData.reduce<TableRow[]>((target, curr) => {
      if (curr.selected) {
        target.push(curr);
      }

      return target;
    }, []);
  }

  @computed
  get selectedRowsNumber(): number {
    return this.selectedRows.length;
  }

  @action.bound
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @action.bound
  setInvalidCellValue(rowIndex: number, attrName: string, value: CellValueType): void {
    this.tableData[rowIndex][attrName] = { value, isError: true };
    this.isValidationError = true;
  }

  @action.bound
  setIsValidationError(isError: boolean): void {
    this.isValidationError = isError;
  }

  @action.bound
  setColumnsData(columnsData: ColumnType[]): void {
    this.columnsData = columnsData;
  }

  @action.bound
  setTableData(tableData: CompareWellType[] | WellType[]): void {
    this.isValidationError = false;
    this.tableData = mapTableData(tableData);
  }

  @action.bound
  addTableData(tableData: CompareWellType[] | WellType[]): void {
    this.tableData.push(...mapTableData(tableData));
  }

  @action.bound
  setSelectAllRows(): void {
    this.isSelectedAllRows
      ? this.tableData.forEach((row) => (row.selected = false))
      : this.tableData.forEach((row) => (row.selected = true));
  }

  @action.bound
  selectRow(rowIndex: number): void {
    if (this.tableData[rowIndex].selected) {
      this.tableData[rowIndex].selected = false;
      return;
    }

    if (!this.tableData[rowIndex].selected) {
      this.tableData[rowIndex].selected = true;
      return;
    }
  }

  @action.bound
  setLeftOffset(leftOffset: number): void {
    this.resizeParams.leftOffset = leftOffset;
  }

  @action.bound
  resizeStart(columnId: string, columnWidth: number, minColumnWidth: number, startCoordinates: number): void {
    const columnIndex = this.columnsData.findIndex((column) => column.id === columnId);
    if (columnIndex !== -1) {
      this.resizeParams.isResize = true;
      this.resizeParams.columnIndex = columnIndex;
      this.resizeParams.columnWidth = columnWidth;
      this.resizeParams.minColumnWidth = minColumnWidth;
      this.resizeParams.leftColumnCoordinates = startCoordinates - columnWidth;
      this.resizeParams.startCoordinates = startCoordinates;
      this.resizeParams.xCoordinates = startCoordinates;
    }
  }

  @action.bound
  setXCoordinates(coordinates: number): void {
    if (
      hasValue(this.resizeParams.columnIndex) &&
      hasValue(this.resizeParams.columnWidth) &&
      hasValue(this.resizeParams.startCoordinates) &&
      hasValue(this.resizeParams.minColumnWidth)
    ) {
      const newColumnWidth = this.resizeParams.columnWidth + (coordinates - this.resizeParams.startCoordinates);

      if (newColumnWidth > this.resizeParams.minColumnWidth) {
        this.resizeParams.xCoordinates = coordinates;
      }
    }
  }

  @action.bound
  resizeEnd(): ColumnType[] {
    if (
      hasValue(this.resizeParams.columnIndex) &&
      hasValue(this.resizeParams.columnWidth) &&
      hasValue(this.resizeParams.startCoordinates) &&
      hasValue(this.resizeParams.xCoordinates)
    ) {
      this.columnsData[this.resizeParams.columnIndex].width =
        this.resizeParams.columnWidth + (this.resizeParams.xCoordinates - this.resizeParams.startCoordinates);
    }

    this.resizeParams.isResize = false;
    this.resizeParams.columnIndex = null;
    this.resizeParams.columnWidth = null;
    this.resizeParams.minColumnWidth = null;
    this.resizeParams.leftColumnCoordinates = null;
    this.resizeParams.startCoordinates = null;
    this.resizeParams.xCoordinates = null;

    return this.columnsData;
  }
}
