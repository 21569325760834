import { Collapse } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useDraggable } from 'src/features/drilling-chart/features/editing/view/hooks/use-draggable';
import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/move.svg';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';

import { SidebarPadRigOperation } from '../entities';

import { PadCollapseHeader } from './pad-collapse-header';
import { WellComponent } from './well';

import styles from './pad.module.scss';

interface Props {
  pad: SidebarPadRigOperation;
  onWellClick(wellId: number | string): void;
}

export const Pad = observer(function Pad({ pad, onWellClick }: Props) {
  const [activeKey, setActiveKey] = useState<number[]>([]);

  const { listeners, isDragging } = useDraggable({
    id: pad.getKey('draggable'),
    dataItem: pad,
  });

  function handleChange(event: string[] | string) {
    if (Array.isArray(event)) {
      setActiveKey(event.map((id) => Number(id)));
    } else {
      setActiveKey([Number(event)]);
    }
  }

  return (
    <div className={clsx(styles.container, isDragging && styles.containerDragging)}>
      <Collapse
        destroyInactivePanel
        onChange={handleChange}
        activeKey={activeKey}
        expandIcon={({ isActive }) => (
          <>
            <div className={styles.moveIconWrapper} {...listeners}>
              <MoveIcon className={styles.moveIcon} />
            </div>
            <DropdownIcon className={clsx(styles.expandIcon, isActive && styles.expandIconActive)} />
          </>
        )}
      >
        <Collapse.Panel showArrow={true} key={pad.id} header={<PadCollapseHeader pad={pad} />}>
          {pad.wells.map((well) => (
            <WellComponent key={well.id} well={well} onWellClick={onWellClick} />
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
