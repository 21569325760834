import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { ComparingIndicators } from 'src/api/chart/comparing-indicators-api';

import { CollapseTable } from '../../../components/collapse-table/collapse-table';
import { IndicatorsSettings } from '../../../presets/indicators-view-settings-sidebar/entities';
import { useMovableContainer } from '../../../shared/use-movable-container';
import { Viewport } from '../../../shared/viewport/viewport';

import { ComparingIndicatorsColumn } from './comparing-indicators-column';
import { ComparingIndicatorsHeaderColumn } from './comparing-indicators-header-column';
import { ComparingIndicatorsTableFooter } from './comparing-indicators-table-footer';

import styles from './comparing-indicators-table.module.scss';

interface Props {
  data: ComparingIndicators.ViewItems | null;
  viewport: Viewport;
  indicatorsSettings?: IndicatorsSettings;
  onPointerDown(): void;
  onPointerMove(offsetX: number, offsetY?: number): void;
  onPointerUp(): void;
  onZoom?(offset: number, centerPosition: number): void;
  className?: string;
}

export const ComparingIndicatorsTable = observer(function ComparingIndicatorsTable({
  data,
  viewport,
  className,
  onPointerUp,
  onPointerMove,
  onPointerDown,
  onZoom,
  indicatorsSettings,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const { containerRef, handlePointerDown, handlePointerMove, handlePointerUp, handleScroll } = useMovableContainer({
    horizontalViewport: viewport,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onZoom,
    onCancel: onPointerUp,
    onBlur: onPointerUp,
  });

  const topIndicatorName = data?.items.at(0);

  if (!data || !topIndicatorName || !indicatorsSettings) {
    return null;
  }

  return (
    <CollapseTable isCollapsed={!isExpanded} className={className}>
      <div className={clsx(styles.table, !isExpanded && styles.tableCollapsed)}>
        <div
          ref={containerRef}
          className={styles.dataContainer}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          onWheel={handleScroll}
        >
          <div className={styles.data}>
            {!!topIndicatorName &&
              data.items.map((item) => (
                <ComparingIndicatorsColumn
                  key={item.key}
                  item={item}
                  horizontalViewport={viewport}
                  isExpanded={isExpanded}
                />
              ))}
          </div>
        </div>

        {!!topIndicatorName && (
          <ComparingIndicatorsHeaderColumn
            isExpanded={isExpanded}
            indicatorsResult={data.result}
            indicatorsSettings={indicatorsSettings}
          />
        )}

        <ComparingIndicatorsTableFooter isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </div>
    </CollapseTable>
  );
});
