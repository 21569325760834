import { SVGRenderer, SVGElement, SVGAttributes } from 'highcharts';

import { LabelsPlacement } from './types';

export class Drawer implements LabelsPlacement.Drawer {
  private readonly renderer: SVGRenderer;
  private readonly labelClass: string;

  constructor(renderer: SVGRenderer, labelClass: string) {
    this.renderer = renderer;
    this.labelClass = labelClass;
  }

  drawLabel = (
    str: string,
    x: number,
    y: number,
    color = LabelsPlacement.DEFAULT_LABEL_COLOR_DARK,
    fontSize = 10,
    invert = false
  ): SVGElement => {
    const label = this.renderer
      .label(str, x, y)
      .css({
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: `${fontSize}px`,
      })
      .attr({
        fill: color,
        r: 4,
      })
      .addClass(this.labelClass || '')
      .add()
      .toFront();

    const { width: labelWidth } = label.getBBox();

    label.align({
      x: x - labelWidth / 2 + 1,
      y: y,
    });

    return label;
  };

  drawVerticalLine = (x: number, y1: number, y2: number, color = LabelsPlacement.DEFAULT_LINE_COLOR): SVGElement => {
    return this.renderer
      .path([
        ['M', x, y1 + LabelsPlacement.LABEL_OFFSET],
        ['L', x, y2 + LabelsPlacement.CONNECTED_LINE_OFFSET],
      ])
      .attr({
        'stroke-width': 1,
        stroke: color,
      })
      .addClass(this.labelClass || '')
      .add();
  };

  drawLine = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color = LabelsPlacement.DEFAULT_LINE_COLOR,
    attributes?: SVGAttributes
  ): SVGElement => {
    return this.renderer
      .path([
        ['M', x1, y1],
        ['L', x2, y2],
      ])
      .attr({
        'stroke-width': 1,
        stroke: color,
        ...attributes,
      })
      .addClass(this.labelClass || '')
      .add();
  };
}
