import { TrendType } from 'src/shared/constants/trend-type';
import { hasValue } from 'src/shared/utils/common';

import { Range } from '../../features/drilling-chart/layers/model';
import { ComparingIndicatorColumn } from '../../features/drilling-chart/presets/comparing-indicators-table/entities';
import { dateKeyToRange } from '../../features/drilling-chart/shared/date-key-to-range';
import { generateKeyFromRange, StorageKeyManager } from '../../features/drilling-chart/shared/storage-key-manager';
import { TimeRangeHelper } from '../../features/drilling-chart/shared/time-range-helper';
import { TimeUnit } from '../../features/drilling-chart/shared/time-unit';

import { ComparingIndicators } from './comparing-indicators-api';
import { Indicators } from './indicators-api';

export class ComparingIndicatorsAdapter {
  roundValue(value: number): number {
    return Math.floor(value * 10000) / 10000;
  }

  initializeIndicators(
    rawItems: Indicators.RawIndicators,
    timeRange: Range<number>,
    timeUnit: TimeUnit,
    indicatorNames: TrendType[],
    firstPlanVersionId: number,
    secondPlanVersionId: number
  ): ComparingIndicatorColumn[] {
    const rangeKeyManager = new StorageKeyManager(generateKeyFromRange);
    const timeRangeColumns = TimeRangeHelper.getIntermediateDates(timeRange, timeUnit);
    /*
     * Format:
     *
     * Map<'1679990008-1679990008', {
     *   start: 1679990008,
     *   end: 1679990008,
     *   first: {
     *     PASSING: 1220.0,
     *     COMMERCIAL_SPEED: 1220.0,
     *     DRILLING_COMPLETE_WELLS_COUNT: 1220.0,
     *     DEVELOPMENT_COMPLETE_WELLS_COUNT: 1220.0,
     *   },
     *   second: {
     *     PASSING: 1220.0,
     *     COMMERCIAL_SPEED: 1220.0,
     *     DRILLING_COMPLETE_WELLS_COUNT: 1220.0,
     *     DEVELOPMENT_COMPLETE_WELLS_COUNT: 1220.0,
     *   },
     * }>
     * */
    const columns: Map<
      string,
      ComparingIndicators.TwoVersionsData<Partial<Record<TrendType, number>>> & Range<number>
    > = new Map(timeRangeColumns.map((range) => [rangeKeyManager.getKey(range), { ...range, first: {}, second: {} }]));

    for (const indicatorName of indicatorNames) {
      const indicator = rawItems[indicatorName];

      if (indicator) {
        for (const dateKey in indicator.points) {
          const firstVersionValue = indicator.points[dateKey]?.[firstPlanVersionId] ?? 0;
          const secondVersionValue = indicator.points[dateKey]?.[secondPlanVersionId] ?? 0;
          // Key like "2022-1" --> "1640995200-1643673599"
          const rangeFromDateKey = dateKeyToRange(dateKey, timeUnit);

          if (rangeFromDateKey) {
            const rangeKey = rangeKeyManager.getKey(rangeFromDateKey);
            const column = columns.get(rangeKey);

            if (column) {
              column.first[indicatorName] = Number.isNaN(firstVersionValue) ? 0 : this.roundValue(firstVersionValue);
              column.second[indicatorName] = Number.isNaN(secondVersionValue) ? 0 : this.roundValue(secondVersionValue);
            }
          }
        }
      }
    }

    for (const [, column] of columns) {
      for (const indicatorName of indicatorNames) {
        if (!hasValue(column.first[indicatorName])) {
          column.first[indicatorName] = 0;
        }

        if (!hasValue(column.second[indicatorName])) {
          column.second[indicatorName] = 0;
        }
      }
    }

    const initializedColumns: ComparingIndicatorColumn[] = [];

    for (const columnData of columns.values()) {
      initializedColumns.push(new ComparingIndicatorColumn(columnData));
    }

    return initializedColumns;
  }
}

export default ComparingIndicatorsAdapter;
