import { observer } from 'mobx-react-lite';

import { Item } from '../../../shared/entities/abstract-control-entities';
import { FormStore } from '../entities/form.entity';
import { WellFormColumnItem } from '../well-form-column-item';

import styles from './well-form-column.module.scss';

type Props = {
  items: Item[];
  formStore: FormStore;
};

export const WellFormColumn = observer(function WellFormColumn({ items, formStore }: Props) {
  return (
    <div className={styles.column}>
      {items.map((item) => (
        <WellFormColumnItem key={item.fieldId} item={item} formStore={formStore} />
      ))}
    </div>
  );
});
