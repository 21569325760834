import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';

import styles from './chart-collapse-button.module.scss';

interface Props {
  isCollapsed: boolean;
  toggleIsCollapsed: VoidFunction;
  className?: string;
}

export const ChartCollapseButton = observer(function ChartCollapseButton({
  isCollapsed,
  toggleIsCollapsed,
  className,
}: Props) {
  return (
    <button className={clsx(className, styles.button)} onClick={toggleIsCollapsed}>
      <ArrowIcon className={clsx(styles.arrowIcon, isCollapsed && styles.arrowIconUp)} />
    </button>
  );
});
