import { ColumnTab } from 'src/features/well-form/entities/column-tab.entity';
import { FormStore } from 'src/features/well-form/entities/form.entity';
import { getDefaultFormPlugins, FormPlugin } from 'src/features/well-form/plugins';
import { Item, Tab } from 'src/shared/entities/abstract-control-entities';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';

import {
  TSavedForm,
  TSavedFormItems,
  TSavedApproach,
  TSavedFormValue,
  TColumnRaw,
  TColumnTabRaw,
  TFormRaw,
  TItemRaw,
  TTabRaw,
} from '../types';

import { mapApproachesList } from './approaches-serializers';
import { mapComboBoxItem } from './combobox-serializers/map-combobox';
import { mapMulticombobox } from './combobox-serializers/map-multicombobox';
import { mapTreeMultiCombobox } from './combobox-serializers/map-treemulticombobox';
import { mapFieldItem } from './field-serializers/map-field-item';
import { mapDateIntervalFieldItem } from './interval-field-serializers/map-date-interval-field.serializer';
import { mapPassingIntervalFieldItem } from './interval-field-serializers/map-passing-interval-field.serializer';
import { mapLabelItem } from './map-label-item';
import { mapSwitchItem } from './map-switch-item';
import { mapTextArea } from './map-text-area';

export const mapData = (
  data: TFormRaw,
  savedForm: TSavedForm | null,
  rootStore: RootStore,
  plugins: FormPlugin[] = []
): FormStore => {
  const formData = {
    tabs: mapTabs(data.tabs, savedForm, rootStore.directories),
  };
  return new FormStore(formData, rootStore, [...getDefaultFormPlugins(rootStore), ...plugins]);
};

const mapTabs = (data: TTabRaw[], savedForm: TSavedForm | null, directories: Directories): Tab[] => {
  return data.map((tab) => {
    // Временно отключенный функционалл восстановления формы из локального хранилища
    // const savedItems = savedForm?.[tab.fieldId];
    // if (tab.kind === 'tabWithStages') return mapApproachesList(tab, directories, savedItems);
    if (tab.kind === 'tabWithStages') return mapApproachesList(tab, directories);
    // return mapColumnTab(tab, directories, savedItems);
    return mapColumnTab(tab, directories);
  });
};

const mapColumnTab = (
  tab: TColumnTabRaw,
  directories: Directories,
  savedItems?: TSavedFormItems | TSavedApproach[]
): ColumnTab => {
  const columns = tab.columns.map((column: TColumnRaw, index: number) => {
    return { id: index + 1, items: mapItems(column.items, directories, savedItems) };
  });

  return new ColumnTab({ formElementRefId: tab.fieldId, columns });
};

export const mapItems = (
  items: TItemRaw[],
  directories: Directories,
  savedItems?: TSavedFormItems | TSavedApproach[]
): Item[] => {
  return items.map((item) => {
    const savedValue = Array.isArray(savedItems)
      ? undefined
      : (!!item.attrName && savedItems?.[item.attrName]) || savedItems?.[item.fieldId];
    return mapItem(item, directories, savedValue);
  });
};

export const mapItem = (item: TItemRaw, directories: Directories, savedValue?: TSavedFormValue): Item => {
  switch (item.control) {
    case 'Field':
      return mapFieldItem({ item, directories, savedValue });
    case 'TextArea':
      return mapTextArea({ item, directories, savedValue });
    case 'ComboBox':
      return mapComboBoxItem({ item, directories, savedValue });
    case 'MultiComboBox':
      return mapMulticombobox({ item, directories });
    case 'TreeMultiComboBox':
      return mapTreeMultiCombobox({ item, directories });
    case 'PassingInterval':
      return mapPassingIntervalFieldItem({ item, directories, savedValue });
    case 'DateTimeIntervalWithDuration':
      return mapDateIntervalFieldItem({ item, savedValue, directories });
    case 'CheckBox':
      return mapSwitchItem({ item, directories, savedValue });
    case 'Label': {
      const directory = item.refObjectType ? directories.getObject(item.refObjectType) : null;
      return mapLabelItem({ item, directories, savedValue, directory });
    }
    default: {
      console.error('Unable to recognize the item', item);
      throw new Error('Unable to recognize the item');
    }
  }
};
