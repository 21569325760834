import { autorun } from 'mobx';

import { checkIsRegularDirectories } from 'src/shared/utils/check-is-regular-directory';

import { Combobox, Item } from '../../../shared/entities/abstract-control-entities';
import { Label } from '../../../shared/entities/control-entities';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class ComboboxOnChangePlugin extends FormPlugin {
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item instanceof Combobox && item.onChangeInstructions && item.directory) {
        const itemRefObjectType = item.refObjectType;

        if (!itemRefObjectType) {
          return;
        }

        const directory = item.directory;
        for (const updateField of item.onChangeInstructions.updateField) {
          const fieldId = updateField.fieldId;
          const refObjectAttr = updateField.refObjectAttr;
          const refObjectType = updateField.refObjectType;
          const dependentField = form.fields[fieldId];

          if (!refObjectType || !refObjectAttr) {
            continue;
          }

          const disposer = autorun(() => {
            const currentDirectoryValue = checkIsRegularDirectories(directory)
              ? directory.find((dir) => dir.id === item.value)
              : directory.find((dirValue) => dirValue[itemRefObjectType]?.id === item.value)?.[refObjectType];

            if (dependentField instanceof Label) {
              if (currentDirectoryValue) {
                const dependentFieldDirectoryValue = currentDirectoryValue.data[refObjectAttr];

                if (!dependentFieldDirectoryValue && dependentField.nullValue) {
                  dependentField.setValue(dependentField.nullValue);
                }

                if (dependentFieldDirectoryValue) {
                  const labelControlValue = {
                    label: dependentFieldDirectoryValue,
                    value: null,
                  };

                  dependentField.tryToSetRawValue(labelControlValue);
                }
              } else {
                dependentField.clearItem();
              }
            }
          });
          disposers.push(disposer);
        }
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
