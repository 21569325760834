import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import { ChartsContainer } from '../charts-container/charts-container';
import { DashboardHeader } from '../dashboard-header/dashboard-header';
import { Filter } from '../filter/filter';

import { DashboardStore } from './dashboard.store';

import styles from './dashboard.module.scss';

type Props = {
  store: DashboardStore;
};

export const Dashboard = observer(function Dashboard({ store }: Props) {
  if (!store.grouping || !store.selectedGroup) return null;

  return (
    <div className={styles.pageContainer}>
      {store.isUpdating && <Spin size="large" className={styles.loader} />}
      <DashboardHeader
        displayChartType={store.chartType}
        grouping={store.grouping}
        selectedGroup={store.selectedGroup}
        isAccumulate={store.isAccumulate}
        onChartTypeChange={store.setChartType}
        onGroupingChange={store.setGrouping}
        onAccumulateChange={store.toggleAccumulate}
      />

      <div className={styles.contentContainer}>
        <ChartsContainer dashboardStore={store} />
        <Filter dashbaordStore={store} />
      </div>
    </div>
  );
});
