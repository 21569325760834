import { observable, makeObservable, flow, action, computed, runInAction } from 'mobx';

import { getWellFormViewAndItsDirectories, updateWell, createWell } from 'src/api/new-well/requests';
import { mapApproach } from 'src/api/new-well/serializers/approaches-serializers';
import { mapData } from 'src/api/new-well/serializers/common-serializers';
import { serializeApproach } from 'src/api/new-well/serializers/serialize-form-for-request';
import { TIdTriple, TFormRaw, TCreateUpdateWellReturnType } from 'src/api/new-well/types';
import { BaseApiError, Validation400ApiError, ValidationError } from 'src/errors';
import { ConflictResolvingError } from 'src/errors/conflicts';
import { AddWellToChartSidebarStore } from 'src/features/add-well-to-chart-sidebar/add-well-to-chart-sidebar.store';
import { ApproachesTab } from 'src/features/well-form/entities/approach-entities';
import { FormStore } from 'src/features/well-form/entities/form.entity';
import { FormPlugin } from 'src/features/well-form/plugins';
import { TSplitApproachOptions } from 'src/features/well-form/types';
import { RegularComboBox } from 'src/shared/entities/control-entities';
import { assert } from 'src/shared/utils/assert';
import { getInitialTripleIdsByWell } from 'src/shared/utils/get-triple-ids';
import { ENABLE_EDITING_VARIANTS } from 'src/store/editing/types';

import { Directories } from '../directories/directories.store';
import { DraftsStore } from '../drafts/drafts-store';
import { EditingStore } from '../editing/editing-store';
import { I18NextStore } from '../i18next/i18next-store';
import { NotificationsStore } from '../notifications-store/notifications-store';
import { PlanVersionStore } from '../plan-version';
import { RootStore } from '../root-store';

type TCreateFormOptions = {
  additionalPlugins?: FormPlugin[];
  well?: { well: Record<string, unknown>; setBy: 'attrName' | 'fieldId' };
};

/** This class uses for form managment on Wells, Planning and Graph pages */
export class WellFormManager {
  readonly directories: Directories;
  readonly notifications: NotificationsStore;
  readonly i18: I18NextStore;
  readonly planVersion: PlanVersionStore;
  readonly editing: EditingStore;
  readonly drafts: DraftsStore;
  readonly formPlugins: FormPlugin[];
  readonly addWellToChartStore: AddWellToChartSidebarStore;
  protected readonly rootStore: RootStore;

  @observable currentTripleIds: TIdTriple[] | null = null;
  @observable isFormLoading = false;
  @observable isFormOpen = false;
  @observable isAddWellToChartSidebarOpened = false;
  @observable currentFormStore: FormStore | null = null;
  @observable formList: FormStore[] = [];
  @observable tripleIdsList: Record<number, TIdTriple[]> = {};
  @observable formView?: TFormRaw;
  @observable isSaveUpdateLoading = false;
  failedForms = observable.map<FormStore, Error>();
  formsDisposers = observable.map<FormStore, VoidFunction>();

  onCloseForm?(): void | Promise<void>;
  onFormSaveSuccess?(): void | Promise<void>;
  onFormCancel?(): void | Promise<void>;
  onFormInit?(): void | Promise<void>;
  onCreateWell?(well: TCreateUpdateWellReturnType): void | Promise<void>;
  onUpdateWell?(well: TCreateUpdateWellReturnType): void | Promise<void>;

  constructor(rootStore: RootStore, formPlugins: FormPlugin[] = []) {
    this.directories = rootStore.directories;
    this.notifications = rootStore.notifications;
    this.i18 = rootStore.i18;
    this.planVersion = rootStore.planVersion;
    this.editing = rootStore.editing;
    this.drafts = rootStore.drafts;
    this.formPlugins = formPlugins;
    this.rootStore = rootStore;
    this.addWellToChartStore = new AddWellToChartSidebarStore({
      draftStore: rootStore.drafts,
      directories: rootStore.directories,
      appSettings: rootStore.appSettings,
      notifications: rootStore.notifications,
      wellFormManager: this,
      view: rootStore.views.addWellToChart,
    });

    makeObservable(this);
  }

  @action.bound
  private checkIsWellPlacementIdUpdated(oldTripleIds: TIdTriple[], newTripleIds: TIdTriple[]) {
    const oldWellPlacementId = oldTripleIds.find((triples) => triples.objectType === 'GOplan_WellPlacement')?.id;
    const newWellPlacementId = newTripleIds.find((triples) => triples.objectType === 'GOplan_WellPlacement')?.id;
    if (oldWellPlacementId !== newWellPlacementId) {
      for (const trieplIds of Object.values(this.tripleIdsList)) {
        const triple = trieplIds.find((triple) => triple.objectType === 'GOplan_WellPlacement');
        if (triple && newWellPlacementId) {
          triple.id = newWellPlacementId;
        }
      }
    }
  }

  @flow.bound
  private async *loadFormView() {
    try {
      const formView = await getWellFormViewAndItsDirectories(this.directories);
      yield;

      this.formView = formView;
    } catch (e) {
      yield;

      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('newWellForm:Errors.failedLoadForm');
    }
  }

  async init(): Promise<void> {
    await this.loadFormView();
    await this.onFormInit?.();
  }

  @computed
  get currentWellName(): string {
    if (!this.currentFormStore?.isEditingMode) {
      return this.i18.t('newWellForm:Subheader.title');
    }

    const wellControl = this.currentFormStore?.fields['wellName'];
    if (wellControl instanceof RegularComboBox) {
      const wellDirValue = this.directories
        .getObject(wellControl.refObjectType)
        ?.find((dirValue) => dirValue.id === wellControl.value);

      if (wellControl.refObjectAttr) {
        const wellName = wellDirValue?.data[wellControl.refObjectAttr];
        return wellName?.toString() || '';
      }
    }

    return '';
  }

  @flow.bound
  protected async *validateForm(form: FormStore) {
    if (form.validationManager.validationRequest) {
      const isValidationPassed = await form.validationManager.validationRequest;
      yield;

      form.validationManager.validationRequest = null;

      if (!isValidationPassed) {
        throw new Error('');
      }
    }

    form.validationManager.validateForm();
  }

  @flow.bound
  async *changeForm(formId: number) {
    if (!this.currentFormStore) {
      return;
    }

    assert(this.currentFormStore, 'currentFormStore is not presented');

    try {
      const newCurrentForm = this.formList.find((form) => form.id === formId);
      const newTripleIds = this.tripleIdsList[formId];

      if (newCurrentForm && newTripleIds) {
        this.currentFormStore = newCurrentForm;
        this.currentTripleIds = newTripleIds;
      }
    } catch (e) {
      yield;
      if (e instanceof ValidationError) {
        this.notifications.showErrorMessage(e.message);
      }
    }
  }

  @action.bound
  setIsFormOpen(is: boolean) {
    this.isFormOpen = is;
  }

  @action.bound
  createForm(options?: TCreateFormOptions): FormStore | null {
    if (!this.formView) {
      return null;
    }

    const savedWell = options?.well ?? null;
    const additionalPlugins = options?.additionalPlugins ?? [];

    const newForm = mapData(this.formView, null, this.rootStore, [...this.formPlugins, ...additionalPlugins]);
    this.currentFormStore = newForm;
    this.formList.push(newForm);

    if (savedWell) {
      newForm.setFormValues(savedWell.well);
      const newTripleIds = getInitialTripleIdsByWell(savedWell.well);
      this.tripleIdsList[newForm.id] = newTripleIds;
      this.currentTripleIds = newTripleIds;
    }

    const newFormDisposer = newForm.init();

    if (newFormDisposer) {
      this.formsDisposers.set(newForm, newFormDisposer);
    }

    return newForm;
  }

  @flow.bound
  async *onWellAdd() {
    if (!this.formView) {
      this.notifications.showErrorMessageT('newWellForm:Errors.failedOpenEditForm');
      return;
    }

    this.isFormLoading = true;
    this.isFormOpen = true;
    try {
      if (!this.editing.isEditing) {
        const enableEditingVariant = await this.editing.enableEditing();
        yield;

        if (enableEditingVariant === ENABLE_EDITING_VARIANTS.cancel) {
          return;
        }
      }
      this.currentFormStore = this.createForm();
    } catch (e) {
      yield;
      console.error(e);
      this.isFormOpen = false;
    } finally {
      this.isFormLoading = false;
    }
  }

  @action.bound
  addNewApproach() {
    if (
      this.currentFormStore &&
      ((!this.failedForms.has(this.currentFormStore) &&
        this.currentFormStore.validationManager.isAllNecessaryControlsAreFilled) ||
        !this.currentFormStore.approaches.length)
    ) {
      this.currentFormStore?.addNewApproach();
    }
  }

  @action.bound
  splitApproach({ sectionId, stage, approach }: TSplitApproachOptions) {
    if (this.currentFormStore && this.failedForms.has(this.currentFormStore)) {
      return;
    }

    const approachesTab = this.currentFormStore?.tabs.find((tab): tab is ApproachesTab => tab instanceof ApproachesTab);
    if (!approachesTab) {
      return;
    }
    if (!approachesTab.approachesList.approachReference) return;
    const splitSectionIndex = stage.sectionsList.sections.findIndex((section) => section.id === sectionId);
    const clonedApproach = approach.clone();
    if (!clonedApproach) return;
    const clonedStage = stage.clone();
    const splicedSections = stage.sectionsList.sections.splice(splitSectionIndex + 1);
    clonedStage.sectionsList.setSections(splicedSections);

    clonedApproach.stagesList.setStages([clonedStage]);
    const serializedApproach = serializeApproach(clonedApproach);

    const approachReference = approachesTab.approachesList.approachReference;
    const approachControl = mapApproach({ approachReference, directories: this.directories }, serializedApproach);
    if (approachControl) {
      approachesTab.approachesList.approaches.push(approachControl);
    }
  }

  @flow.bound
  protected async *updateWell(form: FormStore, tripleIds: TIdTriple[]): Promise<TCreateUpdateWellReturnType> {
    await this.validateForm(form);

    assert(this.drafts.draftVersionId, 'draftVersionId is invalid');
    const well = await updateWell(form.tabs, this.drafts.draftVersionId, tripleIds);
    yield;

    const currentWellTripleIds = this.tripleIdsList[form.id];
    this.checkIsWellPlacementIdUpdated(currentWellTripleIds, well.idList);
    this.tripleIdsList[form.id] = well.idList;
    form.setRigOperationsIds(well.idList);
    await this.onUpdateWell?.(well);

    const withoutPreloader = true;
    const isSucceed = await this.drafts.conflictResolver.resolveConflict(
      well.conflictRigOperation,
      this.drafts.draftVersionId,
      withoutPreloader
    );
    yield;

    if (!isSucceed) {
      throw new ConflictResolvingError();
    }
  }

  @flow.bound
  private async *createWell(form: FormStore): Promise<TCreateUpdateWellReturnType> {
    assert(this.drafts.draftVersionId, 'draftVersionId is invalid');
    const well = await createWell(form.tabs, this.drafts.draftVersionId);
    yield;

    this.tripleIdsList[form.id] = well.idList;
    form.setRigOperationsIds(well.idList);
    await this.onCreateWell?.(well);

    const withoutPreloader = true;
    const isSucceed = await this.drafts.conflictResolver.resolveConflict(
      well.conflictRigOperation,
      this.drafts.draftVersionId,
      withoutPreloader
    );
    yield;

    if (!isSucceed) {
      throw new ConflictResolvingError();
    }
  }

  @flow.bound
  async *saveWell() {
    if (!this.formList.length) {
      return;
    }

    this.isSaveUpdateLoading = true;

    try {
      await Promise.all(this.formList.map((form) => this.validateForm(form)));

      await Promise.all(
        this.formList.map(async (form) => {
          runInAction(() => this.failedForms.delete(form));
          const formTrippleIds = this.tripleIdsList[form.id];

          try {
            if (formTrippleIds) {
              await this.updateWell(form, formTrippleIds);
            } else {
              await this.createWell(form);
            }
          } catch (e) {
            const error = e instanceof Error ? e : new Error('failed to create or update well');
            runInAction(() => this.failedForms.set(form, error));
            throw e;
          }
        })
      );
      yield;

      await this.onFormSaveSuccess?.();
      yield;

      await this.onCloseForm?.();
      yield;
    } catch (e) {
      yield;

      if ((e instanceof Validation400ApiError || e instanceof ValidationError) && e.message) {
        this.notifications.showErrorMessage(e.message);
        return;
      } else if (e instanceof ConflictResolvingError) {
        this.notifications.showErrorMessageT('errors:failedToResolveConflict');
        return;
      } else if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      } else if (this.currentTripleIds) {
        this.notifications.showErrorMessageT('newWellForm:Errors.updateWell');
      } else {
        this.notifications.showErrorMessageT('newWellForm:Errors.createWell');
      }

      console.error(e);
    } finally {
      this.isSaveUpdateLoading = false;
    }
  }

  @action.bound
  disposeForms(): void {
    for (const disposer of this.formsDisposers.values()) {
      disposer();
    }

    this.formsDisposers.clear();
  }

  @action.bound
  resetFormManager() {
    this.currentTripleIds = null;
    this.tripleIdsList = {};
    this.formList = [];
    this.currentFormStore = null;
    this.failedForms.clear();
    this.disposeForms();
  }

  @action.bound
  cancelForm() {
    this.isFormOpen = false;
    this.resetFormManager();
    this.onFormCancel?.();
    this.onCloseForm?.();
  }
}
