import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './summary-data.module.scss';

interface Props {
  titles: string[];
  className?: string;
}

export const SummaryDataHeader = observer(function SummaryDataHeader({ titles, className }: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Summary' });

  return (
    <div className={clsx(className, styles.header)}>
      <div className={styles.rowTableRow}>
        <div />

        {titles.map((title, index) => (
          <div key={index}>
            <p>{t(`headers.${title}`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
});
