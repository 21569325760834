import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/shared/components/button';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { SwitchButtonGroupItem } from 'src/shared/components/switch-button-group/switch-button-group';

import styles from './chart-view-settings-sidebar.module.scss';

type RenderFunction<TProps> = (props: TProps) => ReactNode;

interface Props<TTabKey> {
  isOpen: boolean;
  tabs: SwitchButtonGroupItem<TTabKey>[];
  isApplyBlocked?: boolean;
  isLoading?: boolean;
  children?: RenderFunction<{ activeTab?: TTabKey }>;
  onClose: VoidFunction;
  onApply: VoidFunction;
}

export const ChartViewSettingsSidebar = observer(function ChartViewSettingsSidebar<TTabsKey>({
  isOpen,
  tabs,
  isApplyBlocked,
  isLoading,
  children,
  onClose,
  onApply,
}: Props<TTabsKey>) {
  const { t } = useTranslation();

  const [sidebarSize, setSidebarSize] = useState<'m' | 'l'>('m');
  const [activeTab, setActiveTab] = useState<TTabsKey | undefined>(tabs[0]?.key);

  const switchButtonGroupRefWrapper = useRef<HTMLDivElement>(null);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeTab === undefined) {
      setActiveTab(tabs.at(0)?.key);
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    const switchButtonsCont = switchButtonGroupRefWrapper.current;
    const contentCont = contentContainerRef.current;

    if (!isOpen || !tabs.length || !switchButtonsCont || !contentCont || sidebarSize === 'l') {
      return;
    }

    const { width: switchButtonsContWidth } = switchButtonsCont.getBoundingClientRect();
    const { width: contentContWidth } = contentCont.getBoundingClientRect();

    if (switchButtonsContWidth > contentContWidth) {
      setSidebarSize('l');
    }
  }, [tabs, isOpen, sidebarSize]);

  return (
    <Sidebar
      isOpened={isOpen}
      size={sidebarSize}
      onClose={onClose}
      title={t('displaySettings')}
      description={t('drawers:chartDisplaySettings.hint')}
    >
      <div ref={contentContainerRef} className={styles.container}>
        {isLoading && <Spin size="large" className={styles.loader} />}

        <div className={styles.switchButtonsContainer}>
          <div className={styles.switchButtonsInnerContainer}>
            <div ref={switchButtonGroupRefWrapper} className={styles.switchButtonGroupRefWrapper}>
              <SwitchButtonGroup
                items={tabs}
                defaultOptionKey={activeTab}
                className={styles.tabs}
                buttonClassName={styles.tabsButton}
                onChange={setActiveTab}
                disabled={isApplyBlocked}
              />
            </div>
          </div>
        </div>

        <div className={styles.tabContent}>{children?.({ activeTab })}</div>

        <Button
          className={styles.applyButton}
          variant="primary"
          disabled={isApplyBlocked || isLoading}
          onClick={onApply}
        >
          {t('Buttons.apply')}
        </Button>
      </div>
    </Sidebar>
  );
});
