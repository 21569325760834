import { action, autorun, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { ComparingWellsApi } from 'src/api/chart/comparing-wells-api';
import { ComparingWellsDataApi } from 'src/api/chart/comparing-wells-data-api';
import { hasValue } from 'src/shared/utils/common';
import { debounce } from 'src/shared/utils/debounce';
import { RootStore } from 'src/store';

import {
  ComparingWellsDataModel,
  ComparingWellsDataStorage,
  ComparingWellsData,
} from '../../features/comparing-wells-chart/data';
import { DataHeadersPresenter } from '../../features/data-headers/presenter/data-headers-presenter';
import { DataItemsBackgroundPresenter } from '../../features/data-items-background/presenter';
import { DataItemsEmptyPresenter } from '../../features/data-items-empty/presenter/data-items-empty-presenter';
import { DataItemsFullPresenter } from '../../features/data-items-full/presenter/data-items-full-presenter';
import { WellsDataPositionsCalculator } from '../../features/wells-chart/data/wells-data-positions-calculator';
import { ChartGrouping } from '../../shared/chart-grouping';
import { DataView } from '../../shared/data-view/data-view';
import { ChartFiltersForm, FiltersFormStore } from '../../shared/filters-form.store';
import {
  generateKeyFromRange,
  parseStringToRange,
  StorageKeyManagerWithParser,
} from '../../shared/storage-key-manager';
import { TimelineController } from '../../shared/timeline-controller';
import { Viewport } from '../../shared/viewport/viewport';
import { ComparingIndicatorsTableStore } from '../comparing-indicators-table';
import { WellsViewSettingsStore } from '../drilling-wells-chart/wells-view-settings.store';

export class ComparingWellsChartStore {
  private readonly firstPlanVersionId: number;
  private readonly secondPlanVersionId: number;
  private readonly api = new ComparingWellsApi();
  private readonly chartDataModel: ComparingWellsDataModel;
  private readonly grouping: ChartGrouping;

  private readonly rootStore: RootStore;

  readonly verticalViewport = new Viewport(0, 0);
  readonly horizontalViewport: Viewport;
  readonly dataView: DataView;
  readonly viewSettings: WellsViewSettingsStore;

  readonly dataItemsBackgroundPresenter: DataItemsBackgroundPresenter<ComparingWellsData.ViewItem[] | null>;
  readonly dataHeadersPresenter: DataHeadersPresenter<ComparingWellsData.ViewItem[] | null>;
  readonly dataItemsFullPresenter: DataItemsFullPresenter<ComparingWellsData.ViewItem[] | null>;
  readonly dataItemsEmptyPresenter: DataItemsEmptyPresenter<ComparingWellsData.ViewItem[] | null>;

  readonly indicators: ComparingIndicatorsTableStore;

  @observable isLoading = false;
  @observable isDataUpdating = false;
  @observable searchTerm = '';
  @observable filtersForm?: FiltersFormStore;

  constructor(
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    horizontalViewport: Viewport,
    dataView: DataView,
    rootStore: RootStore,
    viewSettings: WellsViewSettingsStore,
    horizontalViewportController: TimelineController,
    grouping: ChartGrouping,
    sourceRefs: ComparingWellsDataStorage.SourceRefs
  ) {
    this.firstPlanVersionId = firstPlanVersionId;
    this.secondPlanVersionId = secondPlanVersionId;
    this.horizontalViewport = horizontalViewport;
    this.dataView = dataView;
    this.viewSettings = viewSettings;
    this.grouping = grouping;

    this.rootStore = rootStore;

    this.chartDataModel = new ComparingWellsDataModel(
      this.rootStore.notifications,
      this.firstPlanVersionId,
      this.secondPlanVersionId,
      this.verticalViewport,
      this.horizontalViewport,
      this.dataView,
      new ComparingWellsDataStorage(
        new StorageKeyManagerWithParser(generateKeyFromRange, parseStringToRange),
        this.dataView,
        new WellsDataPositionsCalculator(WellsDataPositionsCalculator.DEFAULT_ELEMENT_SIZES_GETTERS),
        sourceRefs
      ),
      new ComparingWellsDataApi(rootStore.notifications)
    );

    this.dataItemsBackgroundPresenter = new DataItemsBackgroundPresenter(
      this.verticalViewport,
      this.horizontalViewport,
      this.chartDataModel,
      horizontalViewportController
    );
    this.dataHeadersPresenter = new DataHeadersPresenter(this.verticalViewport, this.chartDataModel);
    this.dataItemsFullPresenter = new DataItemsFullPresenter<ComparingWellsData.ViewItem[] | null>(this.chartDataModel);
    this.dataItemsEmptyPresenter = new DataItemsEmptyPresenter<ComparingWellsData.ViewItem[] | null>(
      this.chartDataModel
    );

    this.indicators = new ComparingIndicatorsTableStore(
      this.horizontalViewport,
      this.firstPlanVersionId,
      this.secondPlanVersionId,
      this.rootStore.notifications
    );

    makeObservable(this);
  }

  @computed
  private get filtersFormValues(): { filter: ChartFiltersForm.FilterValues } {
    return {
      filter: this.filtersForm?.formValues?.filter || {},
    };
  }

  private async getDataLength(filter: ChartFiltersForm.FilterValues): Promise<number> {
    const wells = await this.api.getWells(this.firstPlanVersionId, this.secondPlanVersionId, filter, this.searchTerm);

    return wells?.length ?? 0;
  }

  private async search(): Promise<void> {
    this.loadChartData(this.firstPlanVersionId, this.secondPlanVersionId);
  }

  private onSearch = debounce(this.search.bind(this), 400);

  @flow.bound
  private async *loadFilters(planVersionId: number): Promise<void> {
    try {
      const view = await this.rootStore.views.wellsChartFiltersView.loadView();
      yield;

      this.filtersForm = new FiltersFormStore(
        planVersionId,
        view,
        this.rootStore,
        this.onFiltersChange.bind(this),
        this.getDataLength.bind(this)
      );

      await this.filtersForm.loadData();
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadFilters');
    }
  }

  @action.bound
  onSearchChange(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.onSearch();
  }

  @computed
  get hasSelectedFilters(): boolean {
    return Object.values(this.filtersFormValues.filter).filter((fieldValue) => hasValue(fieldValue)).length > 0;
  }

  @flow.bound
  async *loadChartData(firstPlanVersionId: number, secondPlanVersionId: number) {
    try {
      this.isDataUpdating = true;

      const { filter } = this.filtersFormValues;

      const wells = await this.api.getWells(firstPlanVersionId, secondPlanVersionId, filter, this.searchTerm);
      yield;

      if (wells) {
        this.chartDataModel.setWells(wells);
      }
    } catch (e) {
      yield;

      console.error(e);
      this.rootStore.notifications.showErrorMessageT('errors:failedToLoadChart');
    } finally {
      this.isDataUpdating = false;
    }
  }

  async onFiltersChange(): Promise<void> {
    await this.loadChartData(this.firstPlanVersionId, this.secondPlanVersionId);
  }

  @action.bound
  init(): VoidFunction {
    this.loadFilters(this.firstPlanVersionId);
    this.loadChartData(this.firstPlanVersionId, this.secondPlanVersionId);

    const disposeVerticalScrollMovement = reaction(
      () => ({ verticalDataRange: this.chartDataModel.verticalDataRange, dataViewType: this.dataView.type }),
      ({ verticalDataRange, dataViewType }, { verticalDataRange: prevVerticalDataRange }) => {
        Viewport.moveViewportToPercentagePosition(
          this.verticalViewport,
          verticalDataRange,
          prevVerticalDataRange,
          this.dataHeadersPresenter.dataViewHeight
        );
      }
    );

    const disposeVerticalScrollLimitsUpdate = reaction(
      () => ({
        verticalViewport: this.verticalViewport,
        verticalDataRange: this.chartDataModel.verticalDataRange,
        dataViewHeight: this.dataHeadersPresenter.dataViewHeight,
      }),
      ({ verticalViewport, verticalDataRange, dataViewHeight }) => {
        Viewport.updateVerticalViewportLimits(verticalViewport, verticalDataRange, dataViewHeight);
      }
    );

    const disposeModelVerticalViewportRange = autorun(() => {
      const { start, end } = this.verticalViewport;
      this.chartDataModel.setVerticalRange(start, end);
    });

    const disposeModelHorizontalViewportRange = autorun(() => {
      const { start, end } = this.horizontalViewport;
      this.chartDataModel.setHorizontalRange(start, end);
    });

    const disposeDataModel = this.chartDataModel.init();

    const disposeShownStageAttributesNumberSetter = reaction(
      () => ({
        shownStageAttributesNumber: this.viewSettings.view.shownStageAttributesNumber,
      }),
      ({ shownStageAttributesNumber }) => {
        this.chartDataModel.setShownStageAttributesNumber(shownStageAttributesNumber);
        this.chartDataModel.recalculatePositions();
      },
      { fireImmediately: true }
    );

    const disposeFiltersSetter = reaction(
      () => this.filtersFormValues,
      ({ filter }, { filter: prevFilter }) => {
        this.chartDataModel.setFilters(filter);

        if (filter !== prevFilter) {
          this.indicators.onFiltersChange(filter);
        }
      }
    );

    const disposeIndicators = this.indicators.init();

    return () => {
      disposeModelVerticalViewportRange();
      disposeModelHorizontalViewportRange();
      disposeVerticalScrollLimitsUpdate();
      disposeVerticalScrollMovement();
      disposeDataModel();
      disposeShownStageAttributesNumberSetter();
      disposeFiltersSetter();
      disposeIndicators();
    };
  }
}
