import { observer } from 'mobx-react-lite';

import { useIsOutOfViewport } from 'src/shared/hooks/use-is-out-of-viewport';

import { LoadingRigOperations, WellRigOperationStage } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ChartTooltip } from '../../chart-tooltip/chart-tooltip';
import { DrillingStageWrapperCompact } from '../../drilling-step-wrapper';
import { EmptyBlockWrapper } from '../../empty-block-wrapper';

import { DrillingStageTooltipInner } from './drilling-stage-tooltip-inner';

import styles from './data-items-empty.module.scss';

interface Props {
  item: WellRigOperationStage | LoadingRigOperations;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewProvider: WellsViewSettingsProvider;
  onWellStageClick(wellId: number): void;
}

export const WellsDataItem = observer(function WellsDataItem({
  item,
  verticalViewport,
  horizontalViewport,
  viewProvider,
  onWellStageClick,
}: Props) {
  const isOutOfViewport = useIsOutOfViewport(item, horizontalViewport);

  if (item instanceof LoadingRigOperations) {
    return (
      <EmptyBlockWrapper
        item={item}
        horizontalViewport={horizontalViewport}
        verticalViewport={verticalViewport}
        className={styles.loadingBlock}
      />
    );
  }

  if (item instanceof WellRigOperationStage) {
    if (item.parentWell) {
      const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

      return (
        <ChartTooltip
          content={<DrillingStageTooltipInner stage={item} viewProvider={viewProvider} />}
          placement={tooltipPlacement}
        >
          {({ handleMouseOver, handleMouseLeave }) => (
            <DrillingStageWrapperCompact
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              onClick={onWellStageClick}
            />
          )}
        </ChartTooltip>
      );
    } else {
      return (
        <DrillingStageWrapperCompact
          item={item}
          horizontalViewport={horizontalViewport}
          verticalViewport={verticalViewport}
          onClick={onWellStageClick}
        />
      );
    }
  }

  return null;
});
