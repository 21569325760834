import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler } from 'react';

import Card from 'src/shared/components/card/card';

import { LoadingTitle } from '../../shared/components/loading-title/loading-title';
import { LoadingField } from '../../shared/utils/loading-field';

import styles from './drilling-step-colored-card.module.scss';

interface Props {
  title?: string | LoadingField;
  attributes?: string[] | LoadingField[] | null;
  color?: string;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  isInteractive?: boolean;
}

export const DrillingStepColoredCard = observer(function DrillingStepColoredCard({
  title,
  color,
  attributes,
  className,
  contentClassName,
  headerClassName,
  onMouseOver,
  onMouseLeave,
  isInteractive,
}: Props) {
  const header = (
    <div className={styles.cardHeaderWrapper}>
      {!!color && <div className={styles.cardColoredBorder} style={{ backgroundColor: color }} />}

      <div className={clsx(headerClassName, styles.cardHeader)}>
        {!!title && (
          <LoadingTitle
            field={title}
            as="h3"
            className={styles.cardHeaderTitle}
            loadingClassName={styles.cardHeaderLoadingTitle}
          />
        )}
      </div>
    </div>
  );

  const attributesRender = () => {
    return attributes?.map((attribute, index) => (
      <LoadingTitle
        key={index}
        field={attribute}
        as="p"
        className={styles.cardText}
        loadingClassName={styles.loadingField}
      />
    ));
  };

  return (
    <Card
      header={header}
      className={clsx(className, styles.card)}
      contentClassName={clsx(contentClassName, styles.cardInner)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      isInteractive={isInteractive}
    >
      {attributesRender()}
    </Card>
  );
});
