import { observer } from 'mobx-react-lite';

import { getSizeString } from '../../../../components/timeline-chart/utils';
import { Range } from '../../../../layers/model';
import { calculateItemOffsetParams } from '../../../../shared/viewport/viewport-calculator';
import { TimelineDataModel } from '../../data/timeline-data-model';

import { getMonthsElements } from './timeline-year.utils';

import styles from './timeline-year.module.scss';

interface Props {
  data: TimelineDataModel.ITimelineItem;
  timelineViewport: Range<number>;
}

export const TimelineYear = observer(function TimelineYear({ data, timelineViewport }: Props) {
  const monthsElements = getMonthsElements(data.x.start, data.x.end);
  const { width } = calculateItemOffsetParams(timelineViewport, data.x);

  return (
    <div className={styles.yearContainer} style={{ width: getSizeString(width) }}>
      <div className={styles.monthsContainer}>{monthsElements}</div>
      <p className={styles.year}>{data.toString()}</p>
    </div>
  );
});
