import { Switcher } from 'src/shared/entities/control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TSavedFormValue, TSwitcherRaw } from '../types';

type TmapSwitchItemOptions = {
  item: TSwitcherRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapSwitchItem = ({ item, directories, savedValue }: TmapSwitchItemOptions) => {
  const data = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    comment: directories.getFieldComment(item.fieldId),
    validate: item.validate,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    validationTags: item.validationTags,
    calculatedValue: item.calculatedValue,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    initialValue: typeof savedValue === 'boolean' ? savedValue : undefined,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
  };

  return new Switcher(data);
};
