import { Range } from '../../layers/model';

export namespace Animator {
  export type AnimationOptions = Partial<{
    duration: number;
  }>;

  export type AnimationItem = Range<number> & { onChange: (value: number) => void };

  export function easeInOutQuad(currentTime: number, start: number, diff: number, duration: number): number {
    if ((currentTime /= duration / 2) < 1) {
      return (diff / 2) * currentTime * currentTime * currentTime * currentTime + start;
    }

    return (-diff / 2) * ((currentTime -= 2) * currentTime * currentTime * currentTime - 2) + start;
  }

  export function animate(
    start: number,
    end: number,
    onChange: (value: number) => void,
    onFinish?: VoidFunction,
    options: AnimationOptions = {}
  ): VoidFunction {
    const { duration = 300 } = options;

    let startTime: number;
    let animationId: number;

    const animationStep = (currentTime: number) => {
      if (startTime === undefined) {
        startTime = currentTime;
      }

      if (currentTime - startTime < duration) {
        animationId = requestAnimationFrame(animationStep);

        const result = Animator.easeInOutQuad(currentTime - startTime, start, end - start, duration);

        onChange(result);
      } else {
        onChange(end);
        onFinish?.();
      }
    };

    animationId = requestAnimationFrame(animationStep);

    return () => cancelAnimationFrame(animationId);
  }

  export function animateMultiple(
    elements: AnimationItem[],
    onFinish?: VoidFunction,
    options: AnimationOptions = {}
  ): VoidFunction {
    const { duration = 300 } = options;

    let startTime: number;
    let animationId: number;

    const animationStep = (currentTime: number) => {
      if (startTime === undefined) {
        startTime = currentTime;
      }

      if (currentTime - startTime < duration) {
        animationId = requestAnimationFrame(animationStep);

        for (const element of elements) {
          const result = Animator.easeInOutQuad(
            currentTime - startTime,
            element.start,
            element.end - element.start,
            duration
          );

          element.onChange(result);
        }
      } else {
        for (const element of elements) {
          element.onChange(element.end);
        }

        onFinish?.();
      }
    };

    animationId = requestAnimationFrame(animationStep);

    return () => cancelAnimationFrame(animationId);
  }
}
