import { hasValue } from 'src/shared/utils/common';
import { CellValueType } from 'src/store/table/types';

export function mapFieldValue(value: CellValueType): string | number {
  if (!hasValue(value)) {
    return '';
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }

  return '';
}

export function serializeFieldValue(value: string | number): string | number | null {
  return typeof value === 'string' && !value.length ? null : value;
}
