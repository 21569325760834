import { PlanningViewType, UserSettingsColumnType, TableItemViewType } from 'src/api/planning/types';
import { SortingOptionType, SortingObjectType, FilterType, FilterObjectType } from 'src/pages/plan-page/types';
import { Directories } from 'src/store/directories/directories.store';
import { ColumnType } from 'src/store/table/types';

export function mapColumnsData(
  view: PlanningViewType,
  columnsSettings: UserSettingsColumnType[],
  directories: Directories
): ColumnType[] {
  const columns: ColumnType[] = [];

  view.settings.groups.forEach((columnGroup) => {
    columnGroup.items

      .sort((a, b) => {
        const firstValue = columnsSettings.findIndex((item) => a.fieldId === item.id);
        const secondValue = columnsSettings.findIndex((item) => b.fieldId === item.id);

        if (firstValue === -1) {
          return 1;
        }

        if (secondValue === -1) {
          return secondValue;
        }

        return firstValue - secondValue;
      })

      .forEach((columnItem) => {
        const column = view.pad.tableItems.find((item) => item.fieldId === columnItem.fieldId);

        const userColumn = columnsSettings.find((item) => item.id === columnItem.fieldId);

        if (column) {
          columns.push({
            id: column.fieldId,
            name: column.attrName,
            label: column.label ?? directories.getFieldLabel(column.fieldId) ?? column.fieldId,
            numberOfDecimalPlaces: column.numberOfDecimalPlaces,
            control: column.control,
            width: userColumn?.width ?? 200,
            isShown: true,
            fixed: false,
            objectType: column.refObjectType,
            objectField: column.refObjectAttr,
            refQuery: column.refQuery,
            isChangable: column.isChangable,
            excludeFromSettings: column.excludeFromSettings,
          });
        }
      });
  });

  return columns;
}

export function serializeSortingObject(
  view: TableItemViewType[],
  sorting: SortingOptionType | null
): SortingObjectType | null {
  if (view && sorting) {
    for (const column of view) {
      if (column.fieldId === sorting.fieldId) {
        return {
          direction: sorting.direction,
          attrName: column.attrName,
          ...(column.refObjectType && { refObjectType: column.refObjectType }),
          ...(column.refObjectAttr && { refObjectAttr: column.refObjectAttr }),
          ...(column.refQuery && { refQuery: column.refQuery }),
          ...(column.cast && { cast: column.cast }),
        };
      }
    }
  }

  return null;
}

export function serializeFiltersObject(view: TableItemViewType[], filters: FilterType[]): FilterObjectType[] | null {
  const serializedFilters: FilterObjectType[] = [];

  view.forEach((column) => {
    const filter = filters.find((filter) => filter.fieldId === column.fieldId);

    if (filter) {
      serializedFilters.push({
        attrName: column.attrName,
        ...(filter.valueArray && { valueArray: filter.valueArray }),
        ...(filter.valueMin && { valueMin: filter.valueMin }),
        ...(filter.valueMax && { valueMax: filter.valueMax }),
        ...(column.refObjectType && { refObjectType: column.refObjectType }),
        ...(column.refObjectAttr && { refObjectAttr: column.refObjectAttr }),
        ...(column.refQuery && { refQuery: column.refQuery }),
      });
    }
  });

  return serializedFilters.length > 0 ? serializedFilters : null;
}
