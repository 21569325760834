import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { Pad } from 'src/features/drilling-rig-conflicts-sidebar/store/pad';
import { Rig } from 'src/features/drilling-rig-conflicts-sidebar/store/rig';
import { PadTypes } from 'src/types/pad-types';
import { RigTypes } from 'src/types/rig-types';

import { RigConflictsAdapter } from './rig-conflicts-adapter';

export class RigConflictsApi {
  private readonly adapter = new RigConflictsAdapter();

  async getRig(rigId: number | string): Promise<Rig> {
    try {
      const { data } = await agent.get<RigTypes.RawRig>(`/object/Common_Rig/${rigId}`);

      return new Rig(data);
    } catch (e) {
      throwApiError(e);
    }
  }

  async getRigPads(rigId: number | string, planVersionId: number): Promise<Pad[]> {
    try {
      const { data } = await agent.get<PadTypes.RawPadWithItems[]>('rig/rigOperation/list', {
        params: { rigId, planVersionId },
      });

      return this.adapter.initRigPads(data);
    } catch (e) {
      throwApiError(e);
    }
  }
}
