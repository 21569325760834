import { v4 as uuidv4 } from 'uuid';

import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';
import { RawWell } from '../../../types';

export class TemporaryWellRigOperation implements RigsChartDataModel.IChartWellRigOperation {
  readonly uniqueCreationKey: string;
  readonly id: number;
  readonly data: RawWell;
  readonly parentRig: RigsChartDataModel.IChartRig;
  readonly x: Range<number>;
  readonly y: Range<number>;

  constructor(item: RawWell, id: number, parentRig: RigsChartDataModel.IChartRig, x: Range<number>, y: Range<number>) {
    this.data = item;
    this.id = id;
    this.uniqueCreationKey = uuidv4();
    this.parentRig = parentRig;
    this.x = x;
    this.y = y;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-well-${this.uniqueCreationKey}`;
    }

    return `well-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): unknown {
    if (fieldName in this.data) {
      return this.data[fieldName];
    }
  }
}
