import { observable, makeObservable } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { TRigOperation } from '../../graph-info-sidebar';

import { Stage } from './stage.entity';

export class RigOperation {
  private readonly item: Record<string, unknown>;
  private readonly view: TRigOperation;
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;

  @observable stages: Stage[] = [];
  readonly index: number;
  readonly id: number;

  constructor(
    rawRigOperation: Record<string, unknown>,
    view: TRigOperation,
    directories: Directories,
    notifications: NotificationsStore,
    id: number
  ) {
    this.item = rawRigOperation;
    this.view = view;
    this.directories = directories;
    this.notifications = notifications;
    this.id = id;

    const index = Number(rawRigOperation[view.rigOperationIndex]);
    this.index = !Number.isNaN(index) ? index : 0;

    this.initializeStages();

    makeObservable(this);
  }

  private initializeStages(): void {
    const stagesData = this.item[this.view.stages.objectName];
    const stagesView = this.view.stages;

    if (!this.isStages(stagesData)) {
      return;
    }

    stagesData.forEach((rawStage) => {
      const id = rawStage[stagesView.attrName];

      assert(
        typeof id === 'number' || (typeof id === 'string' && !Number.isNaN(Number(id))),
        'id is not presented in stage'
      );

      const stage = new Stage(rawStage, stagesView, this.directories, this.notifications, Number(id));

      this.stages.push(stage);
    });
  }

  private isStages(rawStages: unknown): rawStages is Record<string, unknown>[] {
    if (!Array.isArray(rawStages) || rawStages.some((rigOper) => typeof rigOper !== 'object' || rigOper === null)) {
      return false;
    }

    return true;
  }
}
