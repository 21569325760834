import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

import { LoadingField } from 'src/shared/utils/loading-field';

import styles from './loading-title.module.scss';

interface Props {
  field?: string | LoadingField;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'p';
  className?: string;
  loadingClassName?: string;
  style?: CSSProperties;
}

export const LoadingTitle = observer(function LoadingTitle({
  field,
  as: Wrapper = 'p',
  className,
  loadingClassName,
  style,
}: Props) {
  if (typeof field === 'string') {
    return <Wrapper className={className}>{field}</Wrapper>;
  }

  if (!field || field instanceof LoadingField) {
    return <div className={clsx(loadingClassName, styles.title)} style={style} />;
  }

  return null;
});
