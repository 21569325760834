import { IWell } from 'src/api/draft/types';
import { RigConflictsSidebar } from 'src/api/rig-sidebar/rig-conflicts-sidebar-api';
import { Directories } from 'src/store/directories/directories.store';

import { ChartColors } from '../../drilling-chart/shared/chart-colors';
import { DateHelper } from '../../drilling-chart/shared/date-helper';

import { Pad } from './pad';

export class RigConflictsSidebarViewProvider {
  readonly view: RigConflictsSidebar.View;
  private readonly directories: Directories;

  constructor(view: RigConflictsSidebar.View, directories: Directories) {
    this.view = view;
    this.directories = directories;
  }

  getPadTitle(item: Pad): string {
    const { refQuery, refObjectAttrConcat, attrName, delimiter } = this.view.items.pads;

    const shortAttrName = attrName.slice(attrName.indexOf('.') + 1);
    const attrValue = item.getFieldValue(shortAttrName);

    const refObject = this.directories.getJoinedObjectDeprecated(refQuery);
    const labelObject = refObject?.find(
      (refObjectItem) => refObjectItem[attrName]?.toString() === attrValue?.toString()
    );

    if (labelObject) {
      const labels = refObjectAttrConcat.map((refObjectAttr) => {
        return labelObject[refObjectAttr];
      });

      return labels.join(delimiter || ', ');
    }

    return '';
  }

  getWellTitle(item: IWell): string {
    const { attrName, refObjectAttr, refObjectType } = this.view.items.pads.wells;

    const attrValue = item.getFieldValue(attrName);

    if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const labelObject = refObject?.find(({ id }) => id.toString() === attrValue);
      const label = labelObject?.data[refObjectAttr];

      return label ? label.toString() : '';
    }

    return attrValue?.toString() || '';
  }

  getWellAttributes(item: IWell): string {
    const attributes = this.view.items.pads.wells.attributes;

    if (!attributes) {
      return '';
    }

    return attributes
      .reduce<string[]>((stringAttributes, attribute) => {
        const { objectName, type } = attribute;

        const attributeValue = item.getFieldValue(objectName);

        if (type === 'DateTime') {
          if (typeof attributeValue === 'string' || typeof attributeValue === 'number') {
            const date = DateHelper.unixToDateFormat(attributeValue);

            if (date) {
              stringAttributes.push(date);
            }
          }
        }

        return stringAttributes;
      }, [])
      .join(' - ');
  }

  getWellColor(): string {
    return ChartColors.yellow;
  }
}
