import { action, makeObservable, observable } from 'mobx';

import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';

import { RigsGroup } from './rigs-group';
import { TemporaryChartRig } from './temporary-chart-rig';

export class TemporaryRigsGroup implements RigsChartDataModel.IChartRigsGroup<TemporaryChartRig> {
  readonly id: number;
  readonly uniqueCreationKey: string;
  readonly x = null;
  readonly items: TemporaryChartRig[];
  readonly parentGroup?: RigsGroup;

  @observable rowsEnd: number | null = null;
  @observable rowsStart: number | null = null;
  @observable isCollapsed = false;
  @observable y: Range<number> = { start: 0, end: 0 };

  constructor(id: number, uniqueCreationKey: string, rig: TemporaryChartRig, parentGroup?: RigsGroup) {
    this.id = id;
    this.uniqueCreationKey = uniqueCreationKey;
    this.items = [rig];
    this.parentGroup = parentGroup;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-temporary-rigs-group-${this.uniqueCreationKey}`;
    }

    return `temporary-rigs-group-${this.uniqueCreationKey}`;
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }

  @action.bound
  setIsCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  setRowsY(rowsY: Range<number | null>): void {
    this.rowsStart = rowsY.start;
    this.rowsEnd = rowsY.end;
  }
}
