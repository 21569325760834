import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { ReactComponent as ExpandIcon } from 'src/shared/assets/icons/expand-down.svg';
import { ReactComponent as AddIcon } from 'src/shared/assets/icons/plus.svg';
import { Button } from 'src/shared/components/button/button';
import { PadAttributeIcon as PadAttributeIconType } from 'src/shared/constants/pad-attribute-icon';

import styles from './pad-collapse-header.module.scss';

interface Props {
  title: string;
  editing: boolean;
  buttonText: string;
  onClick?: VoidFunction;
  onWellAdd?(): void;
  attributeIcons: PadAttributeIconType[] | null;
}

export const PadCollapseHeader = observer(function PadCollapseHeader({
  title,
  editing,
  buttonText,
  attributeIcons,
  onClick,
  onWellAdd,
}: Props) {
  function handleAddWellClick(event: React.MouseEvent): void {
    event.stopPropagation();
    onWellAdd?.();
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{title}</p>
        <div className={styles.attributes}>
          {attributeIcons?.map((attribute) => (
            <PadAttributeIcon key={attribute} iconName={attribute} className={styles.attributeIcon} />
          ))}
        </div>
      </div>

      {editing && (
        <Button onClick={handleAddWellClick} variant="tertiary" icon={<AddIcon />} iconPosition="before">
          {buttonText}
        </Button>
      )}

      {!editing && !!onClick && (
        <button className={styles.expandButton} onClick={onClick}>
          <ExpandIcon className={styles.expandIcon} />
        </button>
      )}
    </div>
  );
});
