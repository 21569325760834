import { ReactComponent as ColumnChartIcon } from 'src/shared/assets/icons/column-chart.svg';
import { ReactComponent as LineChartIcon } from 'src/shared/assets/icons/line-chart.svg';
import { ReactComponent as StackedBarIcon } from 'src/shared/assets/icons/stacked-bar.svg';

import { ChartType } from '../dashboard/types';

import styles from './dashboard-header.module.scss';

export const defaultChartTypeItems = [
  { key: ChartType.line, title: <LineChartIcon className={styles.switchIcon} /> },
  { key: ChartType.column, title: <ColumnChartIcon className={styles.switchIcon} /> },
];

export const chartTypeItems = [
  { key: ChartType.column, title: <ColumnChartIcon className={styles.switchIcon} /> },
  { key: ChartType.bar, title: <StackedBarIcon className={styles.switchIcon} /> },
];
