import { action, makeObservable, observable } from 'mobx';

export class SummaryDataSidebarStore {
  @observable isOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  open(): void {
    this.isOpen = true;
  }

  @action.bound
  close(): void {
    this.isOpen = false;
  }
}
