import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './input-error.module.scss';

type Props = {
  errorTextRaw: string | [string, { [locKey: string]: string | number }];
};

export const InputError = observer(function InputError({ errorTextRaw }: Props) {
  const { t } = useTranslation();
  const errorText = typeof errorTextRaw === 'string' ? t(errorTextRaw) : t(errorTextRaw[0], errorTextRaw[1]);

  return (
    <p className={styles.errorText} title={errorText}>
      {errorText}
    </p>
  );
});
