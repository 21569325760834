import { Collapse } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Pad as PadStore } from 'src/features/unplanned-rig-operations-sidebar/entities/pad.entity';
import { PadCollapseHeader } from 'src/features/unplanned-rig-operations-sidebar/pad/pad-collapse-header';
import { WellTitle } from 'src/features/unplanned-rig-operations-sidebar/pad/well-title';
import { WellTypeIcon } from 'src/features/well-type-icon';
import { ReactComponent as DropdownIcon } from 'src/shared/assets/icons/triangle.svg';
import { ColoredCard } from 'src/shared/components/colored-card';
import { hasValue } from 'src/shared/utils/common';

import styles from './pad.module.scss';

interface Props {
  pad: PadStore;
  onWellClick(wellId: number | string): void;
}

export const Pad = observer(function Pad({ pad, onWellClick }: Props) {
  const [activeKey, setActiveKey] = useState<number[]>([]);

  function handleChange(event: string[] | string) {
    if (Array.isArray(event)) {
      setActiveKey(event.map((id) => Number(id)));
    } else {
      setActiveKey([Number(event)]);
    }
  }

  function onOpenWellInfoSidebar(wellId: number | null): void {
    if (hasValue(wellId)) {
      onWellClick(wellId);
    }
  }

  return (
    <div className={styles.container}>
      <Collapse
        destroyInactivePanel
        onChange={handleChange}
        activeKey={activeKey}
        expandIcon={({ isActive }) => (
          <DropdownIcon className={clsx(styles.expandIcon, isActive && styles.expandIconActive)} />
        )}
      >
        <Collapse.Panel showArrow={true} key={pad.id} header={<PadCollapseHeader store={pad} />}>
          {pad.wells.map((well, index) => (
            <ColoredCard
              key={index}
              cardColor={well.color}
              title={<WellTitle well={well} />}
              text={well.attributes.length ? well.attributes.join(' - ') : undefined}
              icons={<WellTypeIcon iconName={well.wellTypeIcon} className={styles.wellIcon} />}
              showExpandButton
              className={styles.well}
              onShowInfo={() => onOpenWellInfoSidebar(well.wellId)}
            />
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});
