import { DatePicker } from 'antd';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterType } from 'src/pages/wells-page/types';
import { ReactComponent as CalendarIcon } from 'src/shared/assets/icons/calendar.svg';
import { Button } from 'src/shared/components/button';
import { DATE_FORMAT } from 'src/shared/constants/date';
import { getLocale } from 'src/shared/utils/languages';

import styles from './date-filter.module.scss';
interface Props {
  fieldId: string;
  attrName?: string;
  filters: FilterType[];
  onFiltersChange(filters: FilterType[]): void;
}

export const DateFilter = observer(function DateFilter({ fieldId, filters, onFiltersChange, attrName }: Props) {
  const { t } = useTranslation();

  const currentFilter = filters.find((filter) => filter.fieldId === fieldId);

  const [startDate, setStartDate] = useState<number | null>(
    typeof currentFilter?.valueMin === 'number' ? currentFilter.valueMin : null
  );

  const [endDate, setEndDate] = useState<number | null>(
    typeof currentFilter?.valueMax === 'number' ? currentFilter.valueMax : null
  );

  function onChangeStartDate(date: Moment | null): void {
    setStartDate(date?.unix() || null);
  }

  function onChangeEndDate(date: Moment | null): void {
    setEndDate(date?.unix() || null);
  }

  function resetFilterValues(): void {
    onFiltersChange(filters.filter((item) => fieldId !== item.fieldId));
    setStartDate(null);
    setEndDate(null);
  }

  function onApplyFilterValues(): void {
    if (startDate && endDate && attrName) {
      onFiltersChange(
        filters
          .filter((item) => fieldId !== item.fieldId)
          .concat({
            fieldId,
            attrName,
            valueMin: startDate,
            valueMax: endDate,
          })
      );
    }
  }

  return (
    <div className={styles.container}>
      <p className={styles.hint}>{t('common:dateFilterHint')}</p>
      <div className={styles.filtersContainer}>
        <DatePicker
          format={DATE_FORMAT}
          value={startDate ? moment.unix(startDate) : undefined}
          onChange={onChangeStartDate}
          allowClear={false}
          locale={getLocale() === 'ru' ? localeRu : localeEn}
          suffixIcon={<CalendarIcon />}
          placeholder={t('form:startDate')}
        />

        <span className={styles.dash}>–</span>

        <DatePicker
          format={DATE_FORMAT}
          value={endDate ? moment.unix(endDate) : undefined}
          onChange={onChangeEndDate}
          allowClear={false}
          locale={getLocale() === 'ru' ? localeRu : localeEn}
          suffixIcon={<CalendarIcon />}
          placeholder={t('form:endDate')}
        />
      </div>

      <div className={styles.bottomButtonsContainer}>
        <Button variant="text" className={styles.button} onClick={resetFilterValues}>
          {t('common:Buttons.reset')}
        </Button>

        <Button
          variant="success"
          className={styles.button}
          onClick={onApplyFilterValues}
          disabled={!(startDate && endDate)}
        >
          {t('common:Buttons.apply')}
        </Button>
      </div>
    </div>
  );
});
