import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as DockIcon } from 'src/shared/assets/icons/pad-icon/dock-icon.svg';
import { ReactComponent as LightningIcon } from 'src/shared/assets/icons/pad-icon/lightning-icon.svg';
import { ReactComponent as PinIcon } from 'src/shared/assets/icons/pad-icon/pin-icon.svg';
import { ReactComponent as RoadIcon } from 'src/shared/assets/icons/pad-icon/road-icon.svg';
import { PadAttributeIcon as PadAttributeIconNames } from 'src/shared/constants/pad-attribute-icon';

import styles from './pad-attribute-icon.module.scss';

interface Props {
  iconName: PadAttributeIconNames;
  className?: string;
}
export const PadAttributeIcon = observer(function PadAttributeIcon({ iconName, className }: Props) {
  switch (iconName) {
    case PadAttributeIconNames.pin:
      return <PinIcon className={clsx(className, styles.icon)} />;

    case PadAttributeIconNames.doc:
      return <DockIcon className={clsx(className, styles.icon)} />;

    case PadAttributeIconNames.route:
      return <RoadIcon className={clsx(className, styles.icon)} />;

    case PadAttributeIconNames.lightning:
      return <LightningIcon className={clsx(className, styles.icon)} />;

    default: {
      return null;
    }
  }
});
