import { useEffect, useState } from 'react';

export class SelectAbilityManager {
  disableSelectAbility = () => {
    document.body.style.setProperty('-webkit-touch-callout', 'none');
    document.body.style.setProperty('-webkit-user-select', 'none');
  };

  returnSelectAbility = () => {
    document.body.style.removeProperty('-webkit-touch-callout');
    document.body.style.removeProperty('-webkit-user-select');
  };
}

export function useDisableSafariTouchSelectionFix(applyClearEffect = true): UseDisableSafariTouchSelectionFix.Return {
  const [ability] = useState(() => new SelectAbilityManager());

  useEffect(() => {
    return () => {
      applyClearEffect && ability.returnSelectAbility();
    };
  }, [ability, applyClearEffect]);

  return ability;
}

export namespace UseDisableSafariTouchSelectionFix {
  export type Return = {
    disableSelectAbility: VoidFunction;
    returnSelectAbility: VoidFunction;
  };
}
