import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { RigsChart } from 'src/api/chart/rigs-chart-api';
import { hasValue } from 'src/shared/utils/common';

import { Dnd } from '../../../features/editing/types';
import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';
import { NeighborScheme } from '../../../shared/entities/neighbor-scheme';

import { LoadingRigOperations } from './loading-rig-operations';
import { PadRigOperation } from './pad-rig-operation';
import { RigsGroup } from './rigs-group';
import { WellRigOperation } from './well-rig-operation';

export class ChartRig implements RigsChartDataModel.IChartRig, Dnd.Draggable<ChartRig> {
  readonly item: RigsChart.RawRig;
  readonly id: number;
  readonly uniqueCreationKey: string;
  readonly x = null;

  @observable index: number | undefined;
  @observable items: (PadRigOperation | LoadingRigOperations)[] | undefined;
  @observable y: Range<number> = { start: 0, end: 0 };

  parentGroup?: RigsGroup;

  constructor(item: RigsChart.RawRig, id: number) {
    this.item = item;
    this.id = id;
    this.uniqueCreationKey = uuidv4();

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-rig-${this.uniqueCreationKey}`;
    }

    return `rig-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): string | number | undefined {
    return this.item[fieldName];
  }

  @action.bound
  setY(y: Range<number>): void {
    this.y = y;
  }

  @action.bound
  setIndex(index: number): void {
    this.index = index;
  }

  @action.bound
  setPads(items: (PadRigOperation | LoadingRigOperations)[]): void {
    this.items = items.sort((first, second) => {
      if (hasValue(first.x.start) && hasValue(second.x.start)) {
        return first.x.start - second.x.start;
      }

      return 0;
    });

    const loadedPads: PadRigOperation[] = this.items.filter(
      (item): item is PadRigOperation => item instanceof PadRigOperation
    );

    for (let padIndex = 0; padIndex < loadedPads.length; padIndex++) {
      const currentPad = loadedPads[padIndex];

      const previousWell = loadedPads[padIndex - 1]?.lastWell;
      const nextWell = loadedPads[padIndex + 1]?.firstWell;

      if (currentPad) {
        const leftNeighbor: NeighborScheme.NeighborInfo<WellRigOperation> =
          previousWell?.end && currentPad.x.start
            ? {
                distance: currentPad.x.start - previousWell.end,
                neighbor: previousWell,
              }
            : {
                neighbor: undefined,
              };

        const rightNeighbor: NeighborScheme.NeighborInfo<WellRigOperation> =
          nextWell?.start && currentPad.x.end
            ? {
                distance: nextWell.start - currentPad.x.end,
                neighbor: nextWell,
              }
            : {
                neighbor: undefined,
              };

        currentPad.setNeighborScheme(new NeighborScheme(leftNeighbor, rightNeighbor));
      }
    }
  }

  @action.bound
  setParentGroup(group: RigsGroup): void {
    this.parentGroup = group;
  }

  clone(): ChartRig {
    const rigCopy = new ChartRig(this.item, this.id);

    rigCopy.setY({ ...this.y });

    return rigCopy;
  }
}
