import { Switch } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Switcher } from '../../../../shared/entities/control-entities';

import styles from './new-well-switcher-feature.module.scss';

type Props = {
  className?: string;
  item: Switcher;
  isDisabled: boolean;
  onBlur?: VoidFunction;
};

export const NewWellSwitcherFeature = observer(function NewWellSwitcherFeature({
  className,
  item,
  isDisabled,
  onBlur,
}: Props) {
  const handleChange = (value: boolean) => {
    item.setValue(value);
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Switch checked={item.value} disabled={isDisabled} onChange={handleChange} onClick={onBlur} />
    </div>
  );
});
