import { observer } from 'mobx-react-lite';

import { ReactComponent as CheckmarkIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';

import styles from './add-well-to-chart-subheader.module.scss';

type Props = {
  isSuccessDisabled?: boolean;
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
};

export const AddWellToChartSubheader = observer(function AddWellToChartSubheader({
  isSuccessDisabled,
  onSuccess,
  onCancel,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <Button className={styles.button} icon={<CrossIcon />} variant="danger" onClick={onCancel} />
      <Button
        className={styles.button}
        icon={<CheckmarkIcon />}
        variant="success"
        disabled={isSuccessDisabled}
        onClick={onSuccess}
      />
    </div>
  );
});
