import { WithKey } from '../../../../types';

export class InsertionPointNeighbor<TNeighborItem extends WithKey> {
  readonly neighbor: TNeighborItem;

  constructor(item: TNeighborItem) {
    this.neighbor = item;
  }

  getKey(prefix?: string): string {
    return this.neighbor.getKey(prefix);
  }
}
