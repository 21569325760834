import { Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-thin.svg';
import { Button } from 'src/shared/components/button';

import { ComboboxDropdownButton } from '../../form-controls/combobox-dropdown/combobox-dropdown-button';
import { TOption } from '../../types';

import styles from './section-dropdown.module.scss';

type Props = {
  options: TOption[];
  isDisabled: boolean;
  onNewSection: (sectionType: string | number) => void;
};

export const SectionDropdown = observer(function SectionDropdown({ options, isDisabled, onNewSection }: Props) {
  const { t } = useTranslation();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const handleOpenDropdown = (): void => setIsDropdownOpened(true);
  const handleVisibleChange = (is: boolean): void => setIsDropdownOpened(is);

  return (
    <Dropdown
      visible={isDropdownOpened}
      className={styles.sectionDropdown}
      trigger={['click']}
      disabled={isDisabled}
      onVisibleChange={handleVisibleChange}
      overlay={
        <ul className={styles.wrapper}>
          {options.map((option) => {
            const handleAddNewSection = (): void => {
              onNewSection(option.value);
              setIsDropdownOpened(false);
            };

            return (
              <li key={option.value} className={styles.itemWrapper} onClick={handleAddNewSection}>
                <p className={styles.itemText}>{option.label}</p>
              </li>
            );
          })}
          <ComboboxDropdownButton directoryName={t('newWellForm:AddNewValueToDirectory.section')} />
        </ul>
      }
    >
      <Button
        width={220}
        variant="secondary"
        icon={<ArrowIcon transform={isDropdownOpened ? 'rotate(180)' : undefined} className={styles.arrowIcon} />}
        disabled={isDisabled}
        onClick={handleOpenDropdown}
      >
        <p>{t('newWellForm:Sections.addNewSection')}</p>
      </Button>
    </Dropdown>
  );
});
