import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { getSub } from 'src/shared/utils/auth';
import { TPlanVersion } from 'src/store/comparison/types';

export class PlanVersionsApi {
  async getVersions(): Promise<TPlanVersion[]> {
    try {
      const { data } = await agent.get<TPlanVersion[]>('versions', {
        params: { ownerUserId: getSub() },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async renameVersion(id: number, newName: string): Promise<void> {
    try {
      await agent.patch(`versions/rename/${id}/${newName}`);
    } catch (e) {
      throwApiError(e);
    }
  }
}
