import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { WellsViewSettingsProvider } from '../../../presets/drilling-wells-chart/wells-view-settings-provider';
import { useMovableContainer } from '../../../shared/use-movable-container';
import { Viewport } from '../../../shared/viewport/viewport';
import { WellsChartDataModel } from '../../wells-chart/data/wells-chart-data-model';

import { WellsDataHeader } from './wells-data-header';

import styles from './data-headers.module.scss';

interface Props {
  data: WellsChartDataModel.ViewItemContent[];
  viewport: Viewport;
  isCompactView: boolean;
  isEditing: boolean;
  isLoading: boolean;
  viewProvider: WellsViewSettingsProvider;
  onIsCollapseToggle(item: WellsGroup): void;
  containerWidth?: number;
  containerHeight?: number;
  className?: string;
  onScroll?(offset: number): void;
  onHeightChange?(height: number): void;
  onWellDelete?(well: ChartWell): void;
}

export const WellsDataHeaders = observer(function WellsDataHeaders({
  data,
  viewport,
  isCompactView,
  isEditing,
  containerWidth,
  containerHeight,
  className,
  onScroll,
  onHeightChange,
  onIsCollapseToggle,
  viewProvider,
  onWellDelete,
  isLoading,
}: Props) {
  const { containerRef, handleScroll } = useMovableContainer({ onScroll });

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        if (container) {
          const { height } = container.getBoundingClientRect();
          onHeightChange?.(height);
        }
      });

      resizeObserver.observe(container);

      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, [containerHeight, containerWidth, onHeightChange, containerRef]);

  return (
    <div className={clsx(className, styles.wrapper)} ref={containerRef} onWheel={handleScroll}>
      <div className={styles.headers}>
        {data.map((item) => {
          if (item instanceof ChartWell || item instanceof WellsGroup) {
            return (
              <WellsDataHeader
                key={item.getKey()}
                item={item}
                viewport={viewport}
                viewProvider={viewProvider}
                isCompactView={isCompactView}
                isEditing={isEditing}
                isLoading={isLoading}
                onIsCollapseToggle={onIsCollapseToggle}
                onWellDelete={onWellDelete}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});
