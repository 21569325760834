import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HideIcon } from 'src/shared/assets/icons/closed-eye.svg';
import { ReactComponent as PinIcon } from 'src/shared/assets/icons/pin.svg';
import { ReactComponent as SortDownIcon } from 'src/shared/assets/icons/sort-down.svg';
import { ReactComponent as SortUpIcon } from 'src/shared/assets/icons/sort-up.svg';
import { ReactComponent as UnpinIcon } from 'src/shared/assets/icons/unpin.svg';
import { Button } from 'src/shared/components/button';

import styles from './tooltip-buttons-group.module.scss';

interface Props {
  isFixed?: boolean;
  onShownColumnsChange?(): void;
  onFixedColumnsChange?(): void;
  onSortInAscendingOrder?(): void;
  onSortInDescendingOrder?(): void;
  onResetSorting?(): void;
}

export const TooltipButtonsGroup = observer(function TooltipButtonsGroup({
  onSortInAscendingOrder,
  onSortInDescendingOrder,
  onShownColumnsChange,
  onFixedColumnsChange,
  onResetSorting,
  isFixed,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      {onShownColumnsChange && (
        <Button variant="secondary" icon={<HideIcon />} className={styles.tooltipButton} onClick={onShownColumnsChange}>
          {t('common:Buttons.hide')}
        </Button>
      )}

      {onFixedColumnsChange && (
        <Button
          variant="secondary"
          icon={isFixed ? <UnpinIcon /> : <PinIcon />}
          className={styles.tooltipButton}
          onClick={onFixedColumnsChange}
        >
          {t(isFixed ? 'common:Buttons.unpin' : 'common:Buttons.fix')}
        </Button>
      )}

      {onSortInAscendingOrder && (
        <Button
          variant="secondary"
          icon={<SortUpIcon />}
          className={styles.tooltipButton}
          onClick={onSortInAscendingOrder}
        >
          {t('common:Buttons.sortInAscendingOrder')}
        </Button>
      )}

      {onSortInDescendingOrder && (
        <Button
          variant="secondary"
          icon={<SortDownIcon />}
          className={styles.tooltipButton}
          onClick={onSortInDescendingOrder}
        >
          {t('common:Buttons.sortInDescendingOrder')}
        </Button>
      )}

      {onResetSorting && (
        <Button
          variant="text"
          className={clsx(styles.tooltipButton, styles.tooltipButtonCenter)}
          onClick={onResetSorting}
        >
          {t('common:Buttons.resetSorting')}
        </Button>
      )}
    </>
  );
});
