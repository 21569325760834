import moment, { Moment, isMoment } from 'moment';

import { DateIntervalField } from 'src/shared/entities/control-entities';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { Directories } from 'src/store/directories/directories.store';

import { TDateIntervalFieldRaw, TSavedFormValue } from '../../types';

type TMapDateIntervalFieldItemOptions = {
  item: TDateIntervalFieldRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapDateIntervalFieldItem = ({
  item,
  savedValue,
  directories,
}: TMapDateIntervalFieldItemOptions): DateIntervalField => {
  const initialDates: { [key: string]: Moment | number | null } = {};
  if (isObjectWithKeys<string | number | null>(savedValue)) {
    for (let key in savedValue) {
      const value = savedValue[key];
      if (key.includes('startDate') || key.includes('endDate')) {
        if (typeof value === 'number') {
          initialDates[key] = moment(value * 1000);
          continue;
        }
        if (typeof value === 'string' && !isNaN(Number(value))) {
          initialDates[key] = moment(Number(value) * 1000);
          continue;
        }
      }
      if (typeof value === 'number') {
        initialDates[key] = value;
      }
    }
  }

  const initialStart = initialDates[item.attrNames.startDate];
  const initialEnd = initialDates[item.attrNames.endDate];
  const initialDuration = initialDates[item.attrNames.duration];

  const data = {
    fieldId: item.fieldId,
    placeholderMin: directories.getFieldPlaceholder('startDate') || '',
    placeholderMax: directories.getFieldPlaceholder('endDate') || '',
    placeholderDuration: directories.getFieldPlaceholder('duration') || '',
    labels: {
      startDate: directories.getFieldLabel('startDate') || '',
      endDate: directories.getFieldLabel('endDate') || '',
      duration: directories.getFieldLabel('duration') || '',
    },
    unit: directories.getFieldUnit('duration') || '',
    attrNames: item.attrNames,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    calculatedValue: item.calculatedValue,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    validate: item.validate,
    validationTags: item.validationTags,
    restrictions: { required: !!item.required },
    initialStart: isMoment(initialStart) ? initialStart : null,
    initialEnd: isMoment(initialEnd) ? initialEnd : null,
    initialDuration: typeof initialDuration === 'number' ? initialDuration : null,
  };

  return new DateIntervalField(data);
};
