import { WellTypes } from 'src/types/well-types';

import {
  ChartWell,
  WellRigOperation,
  WellRigOperationsCreator,
} from '../../features/drilling-chart/presets/drilling-wells-chart/entities';

export class WellsChartDataAdapter {
  /**
   * Returns map like { wellsGroupId: { wellId: rigOperations[] } }.
   */
  initWellsStages(
    wellsGroupsWithStages: WellTypes.RawWellsGroupWithStages[],
    wellIds: number[],
    groupIds: number[],
    wells: Map<number, Map<number, ChartWell>>,
    planVersionId: number
  ): Map<number, Map<number, WellRigOperation[]>> {
    const initializedWells = new Map<number, Map<number, WellRigOperation[]>>();
    const groupsWithEmptyDataIds = new Set(groupIds);

    for (const wellsGroup of wellsGroupsWithStages) {
      const initializedRigOperationsMap = new Map<number, WellRigOperation[]>();
      const wellsWithEmptyDataIds = new Set(wellIds);

      for (const rawWell of wellsGroup.items) {
        const initializedRigOperations: WellRigOperation[] = [];

        for (const rawRigOperation of rawWell.items) {
          const parentWell = wells.get(wellsGroup.id)?.get(rawWell.id);

          if (parentWell) {
            const rigOperation = WellRigOperationsCreator.create(
              parentWell.id,
              rawRigOperation,
              planVersionId,
              parentWell
            );
            initializedRigOperations.push(rigOperation);
          }
        }

        initializedRigOperationsMap.set(rawWell.id, initializedRigOperations);
        wellsWithEmptyDataIds.delete(rawWell.id);
      }

      for (const wellId of wellsWithEmptyDataIds) {
        initializedRigOperationsMap.set(wellId, []);
      }

      initializedWells.set(wellsGroup.id, initializedRigOperationsMap);
      groupsWithEmptyDataIds.delete(wellsGroup.id);
    }

    for (const groupId of groupsWithEmptyDataIds) {
      const emptyWellsMap = new Map(wellIds.map((id) => [id, []]));

      initializedWells.set(groupId, emptyWellsMap);
    }

    return initializedWells;
  }
}
