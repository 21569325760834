export const SERIES_COLORS = [
  '#2D9CDB',
  '#219653',
  '#F2C94C',
  '#F2994A',
  '#E42216',
  '#9A1917',
  '#E94773',
  '#EE85B3',
  '#82BB2B',
  '#077E35',
  '#9B51E0',
  '#EDD08A',
];

export const CHART_COLORS = {
  lightBlue: '#D2DFFA',
  white: '#FFFFFF',
  lightGray: '#FFFFFF80',
  darkBlue: '#364153',
  gray: '#FFFFFF1A',
  black: '#000000',
};
