import moment from 'moment/moment';

import { Range } from '../layers/model';

import { TimeUnit } from './time-unit';

export function dateKeyToRange(dateKey: string, timeUnit: TimeUnit): Range<number> | null {
  if (timeUnit === TimeUnit.month) {
    // Date key format:
    // "2022-4"
    // "year-month"
    // Month - [1-12], year - YYYY
    const dateStart = moment(dateKey, 'YYYY-MM').startOf('month');
    const dateEnd = moment(dateKey, 'YYYY-MM').endOf('month');

    const start = dateStart.unix();
    const end = dateEnd.unix();

    return { start, end };
  }

  if (timeUnit === TimeUnit.year) {
    // Date key format:
    // "2022"
    // "year" - "YYYY"
    const dateStart = moment(dateKey, 'YYYY').startOf('year');
    const dateEnd = moment(dateKey, 'YYYY').endOf('year');

    const start = dateStart.unix();
    const end = dateEnd.unix();

    return { start, end };
  }

  return null;
}
