import classNames from 'classnames';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { DateHelper } from 'src/features/drilling-chart/shared/date-helper';
import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';
import { useIsOutOfViewport } from 'src/shared/hooks/use-is-out-of-viewport';
import { hasValue } from 'src/shared/utils/common';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { PadCard } from '../../../../pad-card';
import { PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { ChartTooltip } from '../../chart-tooltip/chart-tooltip';
import { DndContextStore } from '../../editing/shared/entities/dnd-context.store';
import { useDraggable } from '../../editing/view/hooks/use-draggable';
import { PadTooltipInner } from '../../pad-rig-operation-wrapper';
import styles from '../../pad-rig-operation-wrapper/pad-rig-operation-wrapper.module.scss';
import { useDragging } from '../../sorting/hooks/use-dragging';

interface Props {
  item: PadRigOperation;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onClick?: VoidFunction;
  className?: string;
  cardClassName?: string;
  headerClassName?: string;
  onRemoveRigOperationsList?: VoidFunction;
}

const options: DndContextStore.DraggingOptions = {
  draggableClassName: styles.draggingItem,
};

export const DraggablePadRigOperationWrapper = observer(function DraggablePadRigOperationWrapper({
  item,
  horizontalViewport,
  verticalViewport,
  onClick,
  className,
  cardClassName,
  headerClassName,
  viewSettingsProvider,
  onRemoveRigOperationsList,
}: Props) {
  const { listeners } = useDraggable({
    id: item.getKey('draggable'),
    dataItem: item,
    onClick,
    options,
  });

  // To be dragged with sortable rig row.
  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging'),
    item,
  });

  const ref = useMovableElement({
    item,
    horizontalViewport,
    verticalViewport,
  });
  const isOutOfViewport = useIsOutOfViewport(item, horizontalViewport);

  const tooltipPlacement = isOutOfViewport ? 'rightTop' : 'top';

  const padAttributes = viewSettingsProvider.getPadAttributes(item);

  const padIcons = padAttributes?.length && (
    <div className={styles.iconsContainer}>
      {padAttributes?.map((attributeName) => (
        <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
      ))}
    </div>
  );

  const wellRigOperationsStartDates = item.wellRigOperations.reduce((dates: number[], well) => {
    const date = viewSettingsProvider.getRigOperationStartUnix(well);

    if (hasValue(date)) {
      dates.push(date);
    }

    return dates;
  }, []);

  const firstWellStartDate = Math.min(...wellRigOperationsStartDates);

  const isRemovableRigOperation =
    firstWellStartDate !== Infinity && firstWellStartDate > DateHelper.dateToUnix(new Date());

  return (
    <ChartTooltip
      content={
        <PadTooltipInner
          title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
          subTitle={viewSettingsProvider.getPadTooltipDate(item)}
          attributes={viewSettingsProvider.getPadTooltipAttributes(item)}
        />
      }
      placement={tooltipPlacement}
    >
      {({ handleMouseOver, handleMouseLeave }) => (
        <div
          key={item.id}
          ref={mergeRefs(ref, draggingRef)}
          className={clsx(className, styles.container)}
          {...listeners}
        >
          <PadCard
            title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
            extra={padIcons}
            attributes={viewSettingsProvider.getAttributes(item)}
            className={classNames(cardClassName, styles.card)}
            headerClassName={headerClassName}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onDelete={isRemovableRigOperation ? onRemoveRigOperationsList : undefined}
          />
        </div>
      )}
    </ChartTooltip>
  );
});
