import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';

import { ReactComponent as HideIcon } from 'src/shared/assets/icons/closed-eye.svg';
import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/dots.svg';
import { ReactComponent as ShowIcon } from 'src/shared/assets/icons/eye.svg';

import { SortableItemType, TitleComponent } from './sortable-list';

import styles from './sortable-list.module.scss';

interface Props<TItem> {
  item: TItem;
  onIsShownChange?(value: boolean): void;
  titleComponent?: TitleComponent<TItem>;
  hasSwitch?: boolean;
}

export const SortableItem = observer(function SortableItem<TItem extends SortableItemType>({
  item,
  titleComponent: TitleComponent,
  onIsShownChange,
  hasSwitch,
}: Props<TItem>) {
  const { id, title, isShown } = item;

  const { setNodeRef, attributes, transform, transition, listeners } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      className={styles.settingsItem}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      {...attributes}
    >
      <div className={styles.settingsItemDrag} {...listeners}>
        <MoveIcon className={styles.moveIcon} />
      </div>

      {hasSwitch && (
        <Switch
          checkedChildren={<ShowIcon />}
          unCheckedChildren={<HideIcon />}
          checked={isShown}
          onChange={onIsShownChange}
          className={styles.settingsItemSwitch}
        />
      )}

      <div className={styles.settingsItemLabelWrapper}>
        {TitleComponent ? <TitleComponent item={item} /> : <p className={styles.settingsItemLabel}>{title}</p>}
      </div>
    </div>
  );
});
