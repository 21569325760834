import { Checkbox, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SaveIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { FormDatePicker } from 'src/shared/components/form-components';
import { Modal } from 'src/shared/components/modal/modal';
import { RecalculateCarpetStore } from 'src/store/recalculate-carpet-store/recalculate-carpet-store';

import styles from './recalculate-carpet-modal.module.scss';

type Props = {
  isOpen: boolean;
  store: RecalculateCarpetStore;
  onSuccess: VoidFunction;
  onClose: VoidFunction;
};

export const RecalculateCarpetModal = observer<Props>(function RecalculateCarpetModal({
  isOpen,
  store,
  onClose,
  onSuccess,
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    store.clear();
    onClose();
  };

  const isControlsDisabled = store.isLoading;
  const dateForComparing = (() => {
    const initDate = new Date();

    return new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate(), 0, 0, 0, 0);
  })();

  return (
    <Modal
      isOpened={isOpen}
      title={'Пересчет параметров'}
      handleClose={handleClose}
      closeOnClickOutside={false}
      showCloseCross={!store.isLoading}
    >
      <div className={styles.intervalWrapper}>
        <FormDatePicker
          withTime
          value={store.startDate}
          label={t('modals:RecalculateCarpet.startDate')}
          disabled={isControlsDisabled}
          disabledDate={(date) => date.valueOf() <= dateForComparing.valueOf()}
          onChange={store.setStartDate}
        />
        <p className={styles.dash}>—</p>
        <FormDatePicker
          withTime
          allowClear
          value={store.endDate}
          disabledDate={(date) => {
            if (!store.startDate) {
              return true;
            }

            return date.valueOf() <= store.startDate.valueOf();
          }}
          label={t('modals:RecalculateCarpet.endDate')}
          disabled={isControlsDisabled || !store.startDate}
          onChange={store.setEndDate}
        />
      </div>

      <Collapse>
        <Panel key={'dr'} header={'Буровая установка'}>
          {store.rigs.map((rig) => (
            <div key={rig.value} className={styles.rigWrapper}>
              <Checkbox checked={store.chosenRigs.includes(rig.value)} onChange={() => store.toggleRig(rig.value)} />
              <p>{rig.label}</p>
            </div>
          ))}
        </Panel>
      </Collapse>

      <div className={styles.divider} />

      <div className={styles.switchersWrapper}>
        <div className={styles.switcherRow}>
          <Switch checked={store.penetration} onChange={store.setPenetration} disabled={isControlsDisabled} />
          <p>{t('modals:RecalculateCarpet.passing')}</p>
        </div>
        <div className={styles.switcherRow}>
          <Switch checked={store.commSpeed} onChange={store.setCommSpeed} disabled={isControlsDisabled} />
          <p>{t('modals:RecalculateCarpet.commSpeed')}</p>
        </div>
        <div className={styles.switcherRow}>
          <Switch checked={store.geoLoad} onChange={store.setGeoLoad} disabled={isControlsDisabled} />
          <p>{t('modals:RecalculateCarpet.geoLoad')}</p>
        </div>
        <div className={styles.switcherRow}>
          <Switch checked={store.stages} onChange={store.setStages} disabled={isControlsDisabled} />
          <p>{t('modals:RecalculateCarpet.stages')}</p>
        </div>
      </div>

      <div className={styles.buttonsWrapper}>
        <Button
          variant="danger"
          className={styles.button}
          prefixIcon={<CloseIcon />}
          disabled={store.isLoading}
          onClick={handleClose}
        >
          {t('common:Buttons.cancel')}
        </Button>
        <Button
          variant="success"
          className={styles.button}
          prefixIcon={<SaveIcon />}
          disabled={!store.isCompareAvailable || store.isLoading}
          isLoading={store.isLoading}
          onClick={() => store.compare(onSuccess, onClose)}
        >
          {t('common:Buttons.recalculate')}
        </Button>
      </div>
    </Modal>
  );
});
