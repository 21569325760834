import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';

export class ViewsApi {
  async getView<TView>(viewName: string): Promise<TView> {
    try {
      const { data } = await agent.get<TView>(`view/${viewName}`);

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
