import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { createRef, RefObject } from 'react';

import { Timer } from '../../shared/timer';
import { Viewport } from '../../shared/viewport/viewport';
import { AutoScrollController } from '../auto-scroll/auto-scroll-controller';
import { AutoScroll } from '../auto-scroll/auto-scroll.utils';
import { Dnd } from '../editing/types';

import { SortableActive } from './sortable-active';

// --> Not shared types and constants.
const SORTING_DELAY_MS = 20;

const SCROLL_DISTANCE = AutoScroll.DEFAULT_CONTAINER_SCROLL_DISTANCE / window.devicePixelRatio;
// <--

export class SortingContextStore<TSortableItem, TDraggingItem> {
  private readonly verticalViewport: Viewport;
  private readonly autoScrollController: AutoScrollController;
  private readonly onMovingStart?: SortingContextStore.OnMovingStartFn<TSortableItem, TDraggingItem>;
  private readonly onMovingFinish?: SortingContextStore.OnMovingFinishFn;
  private readonly draggingElements: Map<string, SortingContextStore.DraggingEntry<TDraggingItem>> = new Map();
  private readonly interactiveElements: Map<string, SortingContextStore.InteractiveEntry<TSortableItem>> = new Map();

  readonly getIsElementInteractive: SortingContextStore.GetIsElementInteractiveFn<TSortableItem>;
  readonly getIsSortable: SortingContextStore.GetIsSortableFn<TSortableItem>;
  readonly getIsElementDragging: SortingContextStore.GetIsElementDraggingFn<TSortableItem, TDraggingItem>;
  readonly containerRef = createRef<HTMLDivElement>();
  readonly onSortEnd?: SortingContextStore.OnSortEndFn<TSortableItem>;

  sortableContainerElement: HTMLElement | null = null;

  @observable.ref containerRect: DOMRect | null = null;
  @observable.ref active: SortableActive<TSortableItem, TDraggingItem> | null = null;
  @observable.ref interactiveOver: SortingContextStore.InteractiveEntry<TSortableItem> | null = null;
  @observable placement: 'after' | 'before' | null = null;

  constructor(
    verticalViewport: Viewport,
    autoScrollController: AutoScrollController,
    handlers: SortingContextStore.Handlers<TSortableItem, TDraggingItem>
  ) {
    this.verticalViewport = verticalViewport;
    this.autoScrollController = autoScrollController;
    this.getIsSortable = handlers.getIsSortable || (() => true);
    this.getIsElementDragging = handlers.getIsElementDragging || (() => false);
    this.getIsElementInteractive = handlers.getIsElementInteractive || (() => true);
    this.onSortEnd = handlers.onSortEnd;
    this.onMovingStart = handlers.onMovingStart;
    this.onMovingFinish = handlers.onMovingFinish;

    makeObservable(this);
  }

  @computed
  private get sortedInteractiveItems(): SortingContextStore.InteractiveEntry<TSortableItem>[] {
    return [...this.interactiveElements.values()].sort(
      (first, second) => first.dataItem.y.start - second.dataItem.y.start
    );
  }

  private updateDraggingInteractiveFromPoint(pointY: number): void {
    const { active, sortedInteractiveItems } = this;

    if (!active || !sortedInteractiveItems.length) {
      return;
    }

    for (let index = 0; index + 1 < sortedInteractiveItems.length; index++) {
      const currentInteractive = sortedInteractiveItems[index];
      const nextInteractive = sortedInteractiveItems[index + 1];

      const currentInteractiveHalfHeight =
        (currentInteractive.dataItem.y.end - currentInteractive.dataItem.y.start) / 2;
      const nextInteractiveHalfHeight = (nextInteractive.dataItem.y.end - nextInteractive.dataItem.y.start) / 2;

      if (this.getIsElementInteractive(active.dataItem, currentInteractive.dataItem)) {
        if (
          pointY > currentInteractive.dataItem.y.start + currentInteractiveHalfHeight &&
          pointY < nextInteractive.dataItem.y.end - nextInteractiveHalfHeight
        ) {
          this.interactiveOver = currentInteractive;
          this.placement = 'after';

          return;
        }
      }
    }

    const firstInteractive = sortedInteractiveItems.at(0);

    if (firstInteractive) {
      const firstInteractiveHalfHeight = (firstInteractive.dataItem.y.end - firstInteractive.dataItem.y.start) / 2;

      if (pointY < firstInteractive.dataItem.y.start + firstInteractiveHalfHeight) {
        this.interactiveOver = firstInteractive;
        this.placement = 'before';

        return;
      }
    }

    const lastInteractive = sortedInteractiveItems.at(-1);

    if (lastInteractive) {
      const lastInteractiveHalfHeight = (lastInteractive.dataItem.y.end - lastInteractive.dataItem.y.start) / 2;

      if (pointY > lastInteractive.dataItem.y.start - lastInteractiveHalfHeight) {
        this.interactiveOver = lastInteractive;
        this.placement = 'after';

        return;
      }
    }
  }

  private updateAutoScrolling(y: number): void {
    if (!this.autoScrollController) {
      return;
    }

    const validY = AutoScroll.validateCoordinate(y, this.verticalViewport.start, this.verticalViewport.end);

    const isTop = validY >= this.verticalViewport.start && validY <= this.verticalViewport.start + SCROLL_DISTANCE;
    const isBottom =
      !isTop && validY >= this.verticalViewport.end - SCROLL_DISTANCE && validY <= this.verticalViewport.end;

    if (!isTop && !isBottom) {
      this.autoScrollController.finishScrolling();
      return;
    }

    const direction = AutoScroll.getDirection(false, false, isTop, isBottom);

    if (!direction) {
      this.autoScrollController.finishScrolling();
      return;
    }

    const verticalVelocity =
      isTop || isBottom
        ? AutoScroll.getScrollAcceleration(
            isTop ? validY - this.verticalViewport.start : this.verticalViewport.end - validY,
            SCROLL_DISTANCE
          )
        : 0;

    if (verticalVelocity <= 0) {
      this.autoScrollController.finishScrolling();
      return;
    }

    if (direction && this.containerRect) {
      this.autoScrollController.startScrolling(direction, [0, verticalVelocity]);
    }
  }

  private moveSortableVertically = (_: number, scrolledY: number): void => {
    this.active?.transform.add({ y: scrolledY });
  };

  init = (): VoidFunction => {
    const timer = new Timer();

    const disposeSortEndHandling = reaction(
      () => ({
        active: this.active,
        interactive: this.interactiveOver,
        placement: this.placement,
      }),
      ({ active, interactive, placement }) => {
        if (active && interactive && placement) {
          timer.startTimer(() => {
            this.onSortEnd?.(active.dataItem, interactive.dataItem, placement);
          }, SORTING_DELAY_MS);
        } else {
          timer.cancelTimer();
        }
      }
    );

    return () => {
      timer.cancelTimer();

      disposeSortEndHandling();
    };
  };

  setSortableContainer = (element: HTMLElement | null): void => {
    this.sortableContainerElement = element;
  };

  @action.bound
  setContainerRect = (rect: DOMRect | null): void => {
    this.containerRect = rect;
  };

  @action.bound
  setActive(
    id: string,
    element: HTMLElement,
    dataItem: Dnd.Sortable<TSortableItem>,
    options?: SortingContextStore.DraggingOptions
  ): SortableActive<TSortableItem> | undefined {
    const relatedItems: SortingContextStore.DraggingEntry<TDraggingItem>[] = [];

    for (const [, draggingItem] of this.draggingElements) {
      if (this.getIsElementDragging(id, element, dataItem, draggingItem)) {
        relatedItems.push(draggingItem);
      }
    }

    this.active = new SortableActive(id, element, dataItem, { y: 0 }, relatedItems, options?.draggingClassName);

    this.onMovingStart?.(this.active);

    this.autoScrollController.addOnScrollListener(this.moveSortableVertically);

    return this.active;
  }

  @action.bound
  removeActive(beforeRemoving?: (active: SortableActive<TSortableItem>) => void): void {
    if (!this.active) {
      return;
    }

    if (beforeRemoving && this.active) {
      beforeRemoving(this.active);
    }

    this.active = null;
    this.interactiveOver = null;
    this.placement = null;

    this.autoScrollController.removeOnScrollListener(this.moveSortableVertically);
    this.autoScrollController?.finishScrolling();
    this.onMovingFinish?.();
  }

  registerDragging = (
    id: string,
    element: RefObject<HTMLElement>,
    dataItem: TDraggingItem,
    options?: SortingContextStore.DraggingOptions
  ): void => {
    const draggingEntry = { element, dataItem, options };

    this.draggingElements.set(id, draggingEntry);

    if (
      this.active &&
      this.getIsElementDragging(this.active.id, this.active.element, this.active.dataItem, draggingEntry)
    ) {
      this.active.addRelatedItem(draggingEntry);
    }
  };

  unregisterDragging = (id: string): void => {
    const draggingEntry = this.draggingElements.get(id);

    if (
      draggingEntry &&
      this.active &&
      this.getIsElementDragging(this.active.id, this.active.element, this.active.dataItem, draggingEntry)
    ) {
      this.active.removeRelatedItem(draggingEntry);
    }

    this.draggingElements.delete(id);
  };

  registerInteractive = (id: string, element: RefObject<HTMLElement>, dataItem: Dnd.Sortable<TSortableItem>): void => {
    this.interactiveElements.set(id, { element, dataItem });
  };

  unregisterInteractive = (id: string): void => {
    this.interactiveElements.delete(id);
  };

  @action.bound
  onDragMove(pointerClientY: number): void {
    const { containerRect } = this;

    if (!this.active || !containerRect) {
      return;
    }

    const relativeY = pointerClientY - containerRect.top + this.verticalViewport.start;

    this.updateAutoScrolling(relativeY);
    this.updateDraggingInteractiveFromPoint(relativeY);
  }
}

export namespace SortingContextStore {
  export type OnMovingStartFn<TSortableItem, TRelatedItem> = (
    active: SortableActive<TSortableItem, TRelatedItem>
  ) => void;

  export type OnMovingFinishFn = VoidFunction;

  export type GetIsSortableFn<TSortableItem> = (dataItem: Dnd.Sortable<TSortableItem>) => boolean;

  export type GetIsElementDraggingFn<TSortableItem, TDraggingItem> = (
    activeId: string,
    activeElement: HTMLElement,
    activeDataItem: Dnd.Sortable<TSortableItem>,
    draggingItem: DraggingEntry<TDraggingItem>
  ) => boolean;

  export type GetIsElementInteractiveFn<TSortableItem> = (
    active: Dnd.Sortable<TSortableItem> | undefined,
    interactive: Dnd.Sortable<TSortableItem>
  ) => boolean;

  export type OnSortEndFn<TSortableItem> = (
    active: Dnd.Sortable<TSortableItem>,
    interactive: Dnd.Sortable<TSortableItem>,
    placement: 'before' | 'after'
  ) => void;

  export type Handlers<TSortableItem, TDraggingItem> = Partial<{
    onMovingStart: OnMovingStartFn<TSortableItem, TDraggingItem>;
    onMovingFinish: OnMovingFinishFn;
    onSortEnd: OnSortEndFn<TSortableItem>;
    getIsSortable: GetIsSortableFn<TSortableItem>;
    getIsElementDragging: GetIsElementDraggingFn<TSortableItem, TDraggingItem>;
    getIsElementInteractive: GetIsElementInteractiveFn<TSortableItem>;
  }>;

  export type Options = Partial<{
    interactiveDistance: number;
  }>;

  export type DraggingEntry<TItem> = {
    element: RefObject<HTMLElement>;
    dataItem: TItem;
    options?: DraggingOptions;
  };

  export type InteractiveEntry<TItem> = {
    element: RefObject<HTMLElement>;
    dataItem: Dnd.Sortable<TItem>;
  };

  export type DraggingOptions = Partial<{
    draggingClassName: string;
  }>;
}
