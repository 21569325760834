import { ColumnType as AntdColumnType } from 'antd/lib/table';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

import { CELL_HEIGHT } from 'src/features/wells-list/wells-table/constants';
import {
  getLeftChildColumnOffset,
  getLeftColumnOffset,
  getTableData,
  getTableHeight,
  getTableWidth,
  getColumns,
  getMinColumnWidth,
  hasSideScrollbar,
} from 'src/features/wells-list/wells-table/utils';
import { VirtualTable } from 'src/features/wells-list/wells-table/virtual-table';
import { PAGE_MODE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { TableRow } from 'src/store/table/types';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import styles from './wells-table.module.scss';

interface Props {
  pageStore: WellsPageStore;
  wellFormManager: WellFormManagerWithGeoTasksHistory;
}

export const WellsTable = observer(function WellsTable({ pageStore, wellFormManager }: Props) {
  const [singleColumnHeaderHeight, setSingleColumnHeaderHeight] = useState<number>(60);
  const [parentColumnHeaderHeight, setParentColumnHeaderHeight] = useState<number>(0);
  const [childColumnHeaderHeight, setChildColumnHeaderHeight] = useState<number>(0);

  const columnsData = pageStore.table.columnsData.slice().sort((prev, next) => Number(next.fixed) - Number(prev.fixed));

  const columns = getColumns(
    columnsData,
    pageStore.wellsType,
    pageStore.mode === PAGE_MODE.edit,
    pageStore.table.isSelectedAllRows,
    pageStore.table.setSelectAllRows
  );

  const dataSource = getTableData(pageStore, wellFormManager);

  const hasChildren = useMemo(() => columns.some((item) => item?.children && item.children.length > 0), [columns]);

  const tableHeight = getTableHeight(
    dataSource.length,
    CELL_HEIGHT,
    singleColumnHeaderHeight,
    parentColumnHeaderHeight,
    childColumnHeaderHeight,
    hasChildren,
    pageStore.availableSpaceHeight
  );

  const isSideScrollbar = hasSideScrollbar(dataSource.length, CELL_HEIGHT, tableHeight);

  const mergeColumns = columns.map((antdColumn, columnIndex) => {
    const isParent = antdColumn?.children?.length && antdColumn.children.length > 0;

    return {
      ...antdColumn,
      onHeaderCell: (column: AntdColumnType<TableRow>) => ({
        columnId: column.key,
        width: column.width,
        minColumnWidth: getMinColumnWidth(antdColumn),
        leftOffset: getLeftColumnOffset(columnIndex, columns, column.fixed),
        isSortable: column.key !== 'select' && column.key !== 'copy',
        columnType: antdColumn.control,
        setColumnHeaderHeight: isParent ? setParentColumnHeaderHeight : setSingleColumnHeaderHeight,
        pageStore: pageStore,
        objectType: antdColumn.objectType,
        objectField: antdColumn.objectField,
        isFixed: column.fixed,
        attrName: antdColumn.attrName,
        isParent,
      }),
      ...(antdColumn?.children && {
        children: antdColumn?.children.map((childColumn, childColumnIndex) => {
          return {
            ...childColumn,
            onHeaderCell: (column: AntdColumnType<TableRow>) => ({
              columnId: childColumn.key,
              columnType: childColumn.control,
              width: childColumn.width,
              isChild: true,
              isSortable: true,
              pageStore: pageStore,
              objectType: childColumn.objectType,
              objectField: childColumn.objectField,
              setColumnHeaderHeight: setChildColumnHeaderHeight,
              leftOffset: getLeftChildColumnOffset(
                columnIndex,
                childColumnIndex,
                columns,
                antdColumn.children,
                column.fixed
              ),
              isFixed: column.fixed,
              attrName: childColumn.attrName,
            }),
          };
        }),
      }),
    };
  });

  return (
    <div
      className={clsx(styles.wrapper, pageStore.table.resizeParams.isResize && styles.wrapperResizable)}
      style={{ width: getTableWidth(columns, isSideScrollbar) }}
    >
      <VirtualTable
        columns={mergeColumns}
        dataSource={dataSource}
        cellHeight={CELL_HEIGHT}
        pageStore={pageStore}
        tableHeight={tableHeight}
        isSideScrollbar={isSideScrollbar}
      />
    </div>
  );
});
