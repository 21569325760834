import { DashboardTrendResponse } from 'src/api/dashboard/types';
import { RootStore } from 'src/store';
import { Directories } from 'src/store/directories/directories.store';

const GOplan_PlanVersion = 'GOplan_PlanVersion';

export class PlanVersionResolver {
  private readonly directories: Directories;

  readonly cache: Record<string, string> = {};

  constructor(rootStore: RootStore) {
    this.directories = rootStore.directories;
  }

  private getPlanVersionList(rawData: DashboardTrendResponse) {
    const rawDataEntries = Object.values(rawData);
    const planVersions: string[] = [];

    for (const rawItem of rawDataEntries) {
      const rawVersions = Object.keys(rawItem.total);

      for (const planVersionId of rawVersions) {
        if (!planVersions.includes(planVersionId)) {
          planVersions.push(planVersionId);
        }
      }
    }

    const cachedKeys = Object.keys(this.cache);
    const newLabels = planVersions.filter((planVersionId) => !cachedKeys.includes(planVersionId));

    if (newLabels.length === 0) return null;

    return newLabels;
  }

  async getLabels(rawData: DashboardTrendResponse) {
    const rawPlanVersions = this.getPlanVersionList(rawData);

    if (!rawPlanVersions) {
      return;
    }

    await this.directories.loadObjects([GOplan_PlanVersion]);

    const objects = this.directories.getObject(GOplan_PlanVersion);

    if (!objects) {
      return;
    }

    for (const planVersionId of rawPlanVersions) {
      const object = objects.find((obj) => obj.id === Number(planVersionId));
      const newLabel = object?.data?.name;

      this.cache[planVersionId] = newLabel ? String(newLabel) : planVersionId;
    }
  }
}
