import { autorun } from 'mobx';

import { Approach, ApproachStage } from '../entities/approach-entities';
import { ApproachesList } from '../entities/approach-entities/approaches-list.entity';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class StagePassingAndDatesDisabling extends FormPlugin<FormStore> {
  private initializedApproaches = new Set<Approach>();
  private initializedStages = new Set<ApproachStage>();

  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const approachesDisposer = autorun(() => {
      if (!form.approachesTab) {
        return;
      }
      const approachesList = form.approachesTab.approachesList;
      approachesList.approaches.forEach((approach) => {
        if (!this.initializedApproaches.has(approach)) {
          disposers.push(this.initializeApproach(approach, approachesList));
        }
      });

      return () => {
        disposers.forEach((disposer) => disposer());
      };
    });

    return () => {
      approachesDisposer();
      disposers.forEach((disposer) => disposer());
    };
  }

  private initializeApproach(approach: Approach, approachesList: ApproachesList): VoidFunction {
    this.initializedApproaches.add(approach);

    const stagesinitializerDisposer = this.initializeStages(approachesList, approach);

    const initApproachDisposer = () => {
      stagesinitializerDisposer();
    };

    approach.onDeleteCallbacks.push(initApproachDisposer);

    return initApproachDisposer;
  }

  private initializeStages(approachesList: ApproachesList, approach: Approach): VoidFunction {
    const stagesDisposers: VoidFunction[] = [];
    const stagesinitDisposer = autorun(() => {
      approach.stagesList.stages.forEach((stage) => {
        if (!this.initializedStages.has(stage)) {
          stagesDisposers.push(this.initializeStage(approachesList, approach, stage));
        }
      });
    });

    return () => {
      stagesinitDisposer();
      stagesDisposers.forEach((disposer) => disposer());
    };
  }

  private initializeStage(approachesList: ApproachesList, approach: Approach, stage: ApproachStage): VoidFunction {
    this.initializedStages.add(stage);

    const dateFieldVisibilityDisposer = autorun(() => {
      if (stage.stageType.data['hasSections'] && stage.sectionsList.sections.length > 0) {
        stage.dateField?.setIsVisuallyDisabled({ flagId: stage.id, value: true });
      } else {
        stage.dateField?.setIsVisuallyDisabled({ flagId: stage.id, value: false });
      }
    });

    const passingFieldVisibilityDisposer = autorun(() => {
      if (stage.stageType.data['hasSections'] && stage.sectionsList.sections.length > 0) {
        stage.passingField?.setIsVisuallyDisabled({ flagId: stage.id, value: true });
      } else {
        stage.passingField?.setIsVisuallyDisabled({ flagId: stage.id, value: false });
      }

      if (
        stage.stageType.data['hasSections'] &&
        approachesList.approaches.length === 1 &&
        !stage.sectionsList.sections.length
      ) {
        stage.passingField?.setIsDisabled({ flagId: stage.id, value: true });
      } else {
        stage.passingField?.setIsDisabled({ flagId: stage.id, value: false });
      }
    });

    const initStageDisposer = () => {
      dateFieldVisibilityDisposer();
      passingFieldVisibilityDisposer();
    };

    stage.onDeleteCallbacks.push(initStageDisposer);

    return initStageDisposer;
  }
}
