import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'src/shared/components/loader';
import { MainLayout } from 'src/shared/layouts/main-layout/main-layout';
import { useStore } from 'src/store';

import { Dashboard } from './features/dashboard/dashboard';
import { DashboardStore } from './features/dashboard/dashboard.store';

export const DashboardPage: FC = observer(function DashboardPage() {
  const { t } = useTranslation('dashboard');

  const rootStore = useStore();
  const { planVersion } = rootStore;

  const [dashbaordStore] = useState(() => new DashboardStore(rootStore));

  const isLoading = planVersion.isLoading || dashbaordStore.isLoading;
  const haveData = !isLoading && planVersion.version !== undefined;

  useEffect(() => {
    dashbaordStore.init();
  }, [dashbaordStore]);

  const renderContent = () => {
    if (isLoading) {
      return <Loader size="large" />;
    }

    if (haveData) {
      return <Dashboard store={dashbaordStore} />;
    }

    return <div>EMPTY</div>;
  };

  return <MainLayout title={t('Title')}>{renderContent()}</MainLayout>;
});
