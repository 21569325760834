import { observer } from 'mobx-react-lite';

import { NewWellLabel } from 'src/shared/components/form-components/new-well-label/new-well-label';

import { Label } from '../../../../shared/entities/control-entities';

type Props = {
  className?: string;
  item: Label;
};

export const NewWellLabelControl = observer(function NewWellLabelControl({ className, item }: Props) {
  return <NewWellLabel className={className} text={item.controlValue.label} errorText={item.errorText} />;
});
