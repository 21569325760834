import { agent } from 'src/api/agent';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { WellTypes } from 'src/types/well-types';

import { ComparingWellsData } from '../../features/drilling-chart/features/comparing-wells-chart/data';
import { WellRigOperation } from '../../features/drilling-chart/presets/drilling-wells-chart/entities';

import { ComparingWellsDataAdapter } from './comparing-wells-data-adapter';

export class ComparingWellsDataApi implements ComparingWellsData.ComparingWellsChartDataApi {
  private readonly adapter = new ComparingWellsDataAdapter();
  private readonly notifications: NotificationsStore;

  constructor(notifications: NotificationsStore) {
    this.notifications = notifications;
  }

  async getStages(
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    wellIdsList: number[],
    startDate: number,
    endDate: number,
    filter?: Record<string, unknown>
  ): Promise<Map<number, ComparingWellsData.TwoVersionsData<WellRigOperation[]>> | undefined> {
    if (wellIdsList.length) {
      try {
        const { data } = await agent.post<ComparingWellsChart.RawWellWithComparingItems[]>(
          'graph/well/rigOperation/list/compare',
          filter,
          {
            params: {
              firstPlanVersionId,
              secondPlanVersionId,
              startDate,
              endDate,
              wellIdList: wellIdsList.join(),
            },
          }
        );

        if (data) {
          return this.adapter.initializeRigOperationStages(data, firstPlanVersionId, secondPlanVersionId, wellIdsList);
        }
      } catch (e) {
        console.error(e);
        this.notifications.showErrorMessageT('errors:failedToLoadData');
      }
    }
  }
}

export namespace ComparingWellsChart {
  export type RawRigOperationStage = WellTypes.RawRigOperationStage & { hasChanges?: boolean };

  export type RawRigOperation = Record<string, string | number | boolean | RawRigOperationStage[]>;

  export type RawWellWithComparingItems = {
    id: number;
  } & ComparingWellsData.TwoVersionsData<RawRigOperation[]>;
}
