import { action, makeObservable, observable } from 'mobx';

import { TApproachesListRaw, TSerializedApproach } from 'src/api/new-well/types';
import { Directories } from 'src/store/directories/directories.store';

import { Tab } from '../../../../shared/entities/abstract-control-entities';

import { ApproachesList } from './approaches-list.entity';

type ApproachesTabData = {
  formElementRefId: string;
  label?: string;
  approachTemplate: TApproachesListRaw;
  savedApproaches?: TSerializedApproach[];
  directories: Directories;
};
export class ApproachesTab extends Tab {
  @observable errorsCount: number;
  @observable isVisuallyDisabled = false;

  readonly directories: Directories;
  readonly approachesList: ApproachesList;

  constructor(data: ApproachesTabData) {
    super(data);
    this.directories = data.directories;
    this.approachesList = new ApproachesList({
      directories: data.directories,
      approachesTab: this,
      approachTemplate: data.approachTemplate,
      savedApproaches: data.savedApproaches,
    });
    this.errorsCount = 0;

    makeObservable(this);
  }

  @action.bound
  setIsVisuallyDisabled(is: boolean) {
    this.isVisuallyDisabled = is;
  }

  @action.bound
  addNewApproach() {
    this.approachesList.addNewApproach();
  }

  @action.bound
  validate() {
    this.approachesList.validate();
  }
}
