import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useSortableGhost } from '../../sorting/hooks/use-sortable-ghost';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import styles from './data-items.module.scss';

export interface Props {
  item: SortableGhostItem<ChartRig | RigsGroup>;
  verticalViewport: Viewport;
  className?: string;
}

export const GhostRow = observer(function GhostRigRow({
  item,
  verticalViewport,
  className,
  children,
}: PropsWithChildren<Props>) {
  const { transform } = useSortableGhost({
    id: item.activeKey,
  });

  const ref = useMovableElement({
    item,
    verticalViewport,
    transform,
  });

  if (!item.options?.isShown) {
    return null;
  }

  return <div ref={ref} className={clsx(className, styles.rigRow, styles.sortableGhost)} children={children} />;
});
