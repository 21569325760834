import { observer } from 'mobx-react-lite';

import { FormNumberInput } from 'src/shared/components/form-components';

import { PassingIntervalField } from '../../../../shared/entities/control-entities';

import styles from './new-well-passing-interval-field.module.scss';

type Props = {
  item: PassingIntervalField;
  isDisabled: boolean;
};

export const NewWellIntervalPassingField = observer(function NewWellIntervalPassingField({ item, isDisabled }: Props) {
  return (
    <div className={styles.wrapper}>
      <FormNumberInput
        value={item.passingValue}
        isInteger
        errorText={item.fieldsErrors.passingValueError}
        placeholder={item.placeholderTotal}
        label={item.labels['passingValue']}
        unit={item.unit}
        isDisabled={isDisabled || (Number.isInteger(item.passingValueMin) && Number.isInteger(item.passingValueMax))}
        onChange={item.handleChangePassingValue}
        onBlur={item.handleBlurPassingValue}
      />
      <div className={styles.intervalWrapper}>
        <FormNumberInput
          label={item.labels['passingValueInterval']}
          isInteger
          value={item.passingValueMin}
          className={styles.input}
          prefix={item.placeholderMin}
          unit={item.unit}
          isDisabled={isDisabled}
          onChange={item.handleChangePassingValueMin}
          onBlur={item.handleBlurPassingValueMin}
        />
        <p className={styles.dash}>—</p>
        <FormNumberInput
          value={item.passingValueMax}
          isInteger
          className={styles.input}
          prefix={item.placeholderMax}
          unit={item.unit}
          isDisabled={isDisabled}
          onChange={item.handleChangePassingValueMax}
          onBlur={item.handleBlurPassingValueMax}
        />
      </div>
    </div>
  );
});
