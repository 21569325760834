import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { EditingStore } from 'src/store/editing/editing-store';
import { JobsStore } from 'src/store/jobs/jobs-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { PlanType } from '../../features/drilling-chart/shared/plan-type';

import { DrillingPlanChartStore } from './drilling-plan-chart.store';

export class HeaderStore {
  private readonly drillingPlanChartStore: DrillingPlanChartStore;
  private readonly notifications: NotificationsStore;
  readonly editing: EditingStore;
  readonly jobListStore: JobsStore;

  @observable displayChartType: PlanType = PlanType.rigs;
  @observable isEditModeLoading = false;
  @observable isLoading = false;

  constructor(
    editing: EditingStore,
    notifications: NotificationsStore,
    drillingPlanChartStore: DrillingPlanChartStore,
    jobListStore: JobsStore
  ) {
    this.editing = editing;
    this.notifications = notifications;
    this.drillingPlanChartStore = drillingPlanChartStore;
    this.jobListStore = jobListStore;

    makeObservable(this);
  }

  @action.bound
  init() {
    const disposeEditing = reaction(
      () => this.isEditing,
      (isEditing: boolean) => {
        if (isEditing && this.displayChartType === PlanType.wells) {
          this.onDisplayChartTypeChange(PlanType.rigs);
        }
      }
    );

    return () => {
      disposeEditing();
    };
  }

  @computed
  get isEditing(): boolean {
    return this.editing.isEditing;
  }

  @action.bound
  onDisplayChartTypeChange(type: PlanType): void {
    this.displayChartType = type;
  }

  @flow.bound
  async *onEdit(): Promise<void> {
    if (!this.editing.isCanEditing || this.jobListStore.hasActiveImportJob) {
      return;
    }

    try {
      this.isEditModeLoading = true;

      await this.editing.enableEditing();
      yield;
    } catch {
      yield;

      this.notifications.showErrorMessageT('errors:failedToActivateEditMode');
    } finally {
      this.isEditModeLoading = false;
    }
  }

  @flow.bound
  async *onSave(): Promise<void> {
    try {
      this.isLoading = true;

      const isAllowedSave = await this.drillingPlanChartStore.checkConflicts();
      yield;

      if (!isAllowedSave) {
        this.notifications.showErrorMessageT('errors:failedToSaveChanges');
        return;
      }

      await this.editing.saveChanges(true);
      yield;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToSaveChanges');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *onCancel(): Promise<void> {
    try {
      this.isLoading = true;

      await this.editing.cancelEditing();
      yield;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:errorExitingEditMode');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *onPublish(): Promise<void> {
    try {
      this.isLoading = true;
      const isAllowedPublish = await this.drillingPlanChartStore.checkConflicts();
      yield;

      if (!isAllowedPublish) {
        this.notifications.showErrorMessageT('errors:failedToPublishDraft');
        return;
      }

      await this.editing.publishChanges();
      yield;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToPublishDraft');
    } finally {
      this.isLoading = false;
    }
  }
}
