import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Loader } from 'src/shared/components/loader';

import { ButtonProps, ButtonVariant } from './types';

import styles from './button.module.scss';

const buttonVariantsStyles: Record<ButtonVariant, string> = {
  text: styles.buttonText,
  link: styles.buttonLink,
  tag: '',
  default: '',
  filled: '',
  danger: styles.buttonDanger,
  success: styles.buttonSuccess,
  primary: styles.buttonPrimary,
  secondary: styles.buttonSecondary,
  tertiary: styles.buttonTertiary,
};

export const Button = observer(function Button({
  as: Wrapper = 'button',
  target,
  title,
  width,
  height,
  className,
  children,
  type = 'button',
  variant = 'filled',
  prefixIcon,
  icon,
  iconPosition = 'after',
  disabled,
  href,
  onClick,
  onPointerDown,
  onPointerUp,
  isLoading = false,
}: ButtonProps) {
  return (
    <Wrapper
      className={clsx(
        className,
        styles.button,
        variant && buttonVariantsStyles[variant],
        iconPosition === 'before' && styles.buttonReversed
      )}
      title={title}
      type={Wrapper === 'button' ? type : undefined}
      disabled={disabled}
      style={{ width, height }}
      href={Wrapper === 'a' ? href : undefined}
      target={Wrapper === 'a' ? target : undefined}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      {!!prefixIcon && (
        <div className={styles.buttonIconWrapper}>
          {isLoading ? <Loader size="small" className={styles.buttonLoader} /> : prefixIcon}
        </div>
      )}

      {!!children && <div className={styles.buttonInner}>{children}</div>}

      {!!icon && (
        <div className={clsx(styles.buttonIconWrapper)}>
          {isLoading && !prefixIcon ? <Loader size="small" className={styles.buttonLoader} /> : icon}
        </div>
      )}
    </Wrapper>
  );
});
