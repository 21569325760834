import { assert } from './assert';

export function equalsDeep<T>(left: T, right: T): boolean {
  if (typeof left !== typeof right) {
    return false;
  }

  switch (typeof left) {
    case 'boolean':
    case 'string':
    case 'function':
    case 'symbol':
    case 'undefined':
      return left === right;
    case 'bigint':
    case 'number':
      return Object.is(left, right);
    case 'object':
      assert(typeof right === 'object', 'Assertation error');

      if (left === null && right === null) {
        return true;
      } else if (left === null || right === null) {
        return false;
      }

      const isLeftArray = Array.isArray(left);
      const isRightArray = Array.isArray(right);

      if (isLeftArray && isRightArray) {
        return equalsDeep__arraysImpl(left, right);
      } else if (isLeftArray || isRightArray) {
        // types are different
        return false;
      }

      return equalsDeep__objectsImpl(left, right);
  }

  assert(false, 'Assertation error');
}

function equalsDeep__arraysImpl<T>(left: T[], right: T[]): boolean {
  if (left.length !== right.length) return false;

  for (let i = 0; i < left.length; i++) {
    const leftItem = left[i];
    const rightItem = right[i];

    if (!equalsDeep(leftItem, rightItem)) {
      return false;
    }
  }

  return true;
}

function equalsDeep__objectsImpl<T extends object>(left: T, right: T): boolean;
function equalsDeep__objectsImpl<T extends object>(
  left: T & Partial<Record<string, unknown>>,
  right: T & Partial<Record<string, unknown>>
): boolean {
  const leftKeys = Object.keys(left).sort();
  const rightKeys = Object.keys(right).sort();

  if (leftKeys.length !== rightKeys.length) {
    return false;
  }

  for (let i = 0; i < leftKeys.length; i++) {
    const leftKey = leftKeys[i];
    const rightKey = rightKeys[i];
    if (leftKey !== rightKey) {
      return false;
    }

    const leftValue = left[leftKey];
    const rightValue = right[leftKey];

    if (!equalsDeep(leftValue, rightValue)) {
      return false;
    }
  }

  return true;
}
