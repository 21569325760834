import { observer } from 'mobx-react-lite';

import { ComparingChartRig } from '../../../presets/comparing-drilling-rigs-chart/entities';
import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { MainComparingRigSummary, LoadingRigSummary } from '../../../presets/summary-data-sidebar/entities';
import { IRigSummary } from '../../../presets/summary-data-sidebar/entities/rig-summary';
import { SummaryViewProvider } from '../../../shared/summary-view-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingRigsChartDataModel } from '../../comparing-rigs-chart/data';
import { RigsChartDataModel } from '../../rigs-chart/data/rigs-chart-data-model';

import { SummaryDataRow } from './summary-data-row';
import { SummaryDataRowWrapper } from './summary-data-row-wrapper';

interface Props {
  data: (IRigSummary | null)[] | null;
  backgroundItem: RigsChartDataModel.ViewItem | ComparingRigsChartDataModel.ViewItem;
  viewport: Viewport;
  viewProvider: SummaryViewProvider;
}

export const SummaryDataItem = observer(function SummaryDataItem({
  data,
  backgroundItem,
  viewport,
  viewProvider,
}: Props) {
  if (backgroundItem instanceof RigsGroup) {
    return <SummaryDataRowWrapper item={backgroundItem} viewport={viewport} />;
  }

  if (backgroundItem instanceof ComparingChartRig) {
    const dataItem = data?.find((row) => row && row.rigId === backgroundItem.id);

    if (dataItem instanceof MainComparingRigSummary) {
      return (
        <SummaryDataRowWrapper item={backgroundItem} viewport={viewport} hasChanges={dataItem.comparingPair.hasChanges}>
          <SummaryDataRow data={dataItem.comparingPair} viewProvider={viewProvider} />
        </SummaryDataRowWrapper>
      );
    }

    if (dataItem instanceof LoadingRigSummary) {
      return (
        <SummaryDataRowWrapper item={backgroundItem} viewport={viewport}>
          <SummaryDataRow data={dataItem} viewProvider={viewProvider} />
        </SummaryDataRowWrapper>
      );
    }

    return (
      <SummaryDataRowWrapper item={backgroundItem} viewport={viewport}>
        <SummaryDataRow data={null} viewProvider={viewProvider} />
      </SummaryDataRowWrapper>
    );
  }

  if (backgroundItem instanceof ChartRig) {
    const dataItem = data?.find((row) => row && row.rigId === backgroundItem.id);

    return (
      <SummaryDataRowWrapper item={backgroundItem} viewport={viewport}>
        <SummaryDataRow data={dataItem || null} viewProvider={viewProvider} />
      </SummaryDataRowWrapper>
    );
  }

  return null;
});
