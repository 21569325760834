import { observer } from 'mobx-react-lite';
import React from 'react';

import { RigSidebarStore } from 'src/features/drilling-rig-sidebar/store/rig-sidebar.store';
import { InfoSidebarTab } from 'src/features/graph-info-sidebar/entities/info-sidebar-tab.entity';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';

import styles from './control-bar.module.scss';

interface Props {
  store: RigSidebarStore;
  disableEditing?: boolean;
  activeKey?: string;
  activeTab?: InfoSidebarTab;
  setActiveKey: (key?: string) => void;
}

export const ControlBar = observer(function ControlBar({ activeKey, setActiveKey, store }: Props) {
  const isLoading = store.isLoading || store.rigStore.isLoading;

  return (
    <div className={styles.controlBar}>
      <SwitchButtonGroup
        onChange={setActiveKey}
        value={activeKey}
        defaultOptionKey={store.viewProvider?.tabsSwitchButtonsGroup[0].key}
        items={store.viewProvider?.tabsSwitchButtonsGroup || []}
        disabled={isLoading}
      />
    </div>
  );
});
