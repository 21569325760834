import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type TSliderItem = {
  text: string;
  compactText?: string;
  renderCompactIcon?: (className: string) => ReactNode;
};

export type TSwitcherItems = [TSliderItem, TSliderItem];

type Props = {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  isCompact?: boolean;
  isDisabled?: boolean;
  items?: TSwitcherItems;
  isWithoutStroke?: boolean;
  isActive: boolean;
  onToggle: () => void;
};

export const Switcher: React.FC<Props> = observer(function Switcher({
  className,
  isCompact = true,
  isWithoutStroke = false,
  isDisabled,
  orientation = 'horizontal',
  items,
  isActive,
  onToggle,
}) {
  const { t } = useTranslation();

  return (
    <button
      className={clsx(
        styles.wrapper,
        isCompact && styles.wrapper__compact,
        orientation === 'vertical' && isCompact && styles.wrapper__vertical,
        isActive && (orientation === 'horizontal' ? styles.wrapper__active : styles.wrapper__verticalActive),
        isActive && isCompact && styles.wrapper__compactActive,
        className && className
      )}
      disabled={isDisabled}
      onClick={onToggle}
    >
      <div
        className={clsx(
          styles.textWrapper,
          isCompact && styles.textWrapper__compact,
          !isActive && !isCompact && styles.textWrapper__selected
        )}
      >
        {!!items?.length && (
          <>
            <p className={clsx(styles.text, isCompact && styles.text__hidden, !isActive && styles.text__active)}>
              {t(items[0].text)}
            </p>
            <p
              className={clsx(
                styles.text,
                !isCompact && styles.text__hidden,
                styles.text__compact,
                !isActive && styles.text__compactActive,
                isActive && styles.text__compactLightInactive
              )}
            >
              {t(items[0].compactText || '')}
            </p>
            {items[0].renderCompactIcon && (
              <div
                className={clsx(
                  styles.compactIconWrapper,
                  !isActive && styles.compactIconWrapper__active,
                  !isCompact && styles.compactIconWrapper__hidden
                )}
              >
                {items[0].renderCompactIcon(
                  clsx(styles.icon, !isActive && styles.icon__active, isWithoutStroke && styles.icon__withoutStroke)
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={clsx(
          styles.textWrapper,
          isCompact && styles.textWrapper__compact,
          isActive && !isCompact && styles.textWrapper__selected
        )}
      >
        {!!items?.length && (
          <>
            <p className={clsx(styles.text, isCompact && styles.text__hidden, isActive && styles.text__active)}>
              {t(items[1].text)}
            </p>
            <p
              className={clsx(
                styles.text,
                !isCompact && styles.text__hidden,
                styles.text__compact,
                isActive && styles.text__compactActive,
                !isActive && styles.text__compactInactive
              )}
            >
              {t(items[1].compactText || '')}
            </p>
            {items[1].renderCompactIcon && (
              <div className={clsx(styles.compactIconWrapper, !isCompact && styles.compactIconWrapper__hidden)}>
                {items[1].renderCompactIcon(
                  clsx(styles.icon, isActive && styles.icon__active, isWithoutStroke && styles.icon__withoutStroke)
                )}
              </div>
            )}
          </>
        )}
      </div>
    </button>
  );
});
