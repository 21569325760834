import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Description, Descriptions } from 'src/shared/components/descriptions';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { Label, Switcher } from 'src/shared/entities/control-entities';
import { hasValue } from 'src/shared/utils/common';
import { isEmptyStringOrStringifiedNull } from 'src/shared/utils/is-empty-string-or-stringified-null';

import styles from './basic-data.module.scss';

interface Props {
  columns?: { items: Item[] }[];
}

export const BasicData = observer(function BasicData({ columns }: Props) {
  const { t } = useTranslation();

  const getParsedValue = (control: Label) => {
    if (
      !hasValue(control.controlValue.label) ||
      isEmptyStringOrStringifiedNull(control.controlValue.label?.toString())
    ) {
      return '-';
    }

    return control.controlValue.label;
  };

  return (
    <Descriptions className={styles.descriptions}>
      {columns?.map((column) => {
        return column.items.map((control) => {
          if (control instanceof Label) {
            const parsedValue = getParsedValue(control);

            return (
              <Description key={control.fieldId} label={control.label || t('newWellForm:Errors.unrecognizedField')}>
                {parsedValue}
              </Description>
            );
          }

          if (control instanceof Switcher) {
            return (
              <Description key={control.fieldId} label={control.label || t('newWellForm:Errors.unrecognizedField')}>
                <Switch disabled checked={control.value} />
              </Description>
            );
          }

          return null;
        });
      })}
    </Descriptions>
  );
});
