import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'src/shared/assets/icons/edit-icon.svg';
import { ReactComponent as FullIcon } from 'src/shared/assets/icons/expand-icon.svg';
import { Button } from 'src/shared/components/button/button';

import styles from './indicators-table.module.scss';

interface Props {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  openViewSettings: VoidFunction;
}

export const IndicatorsTableFooter = observer(function IndicatorsTableFooter({
  isExpanded,
  setIsExpanded,
  openViewSettings,
}: Props) {
  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Indicators' });

  return (
    <div className={clsx(styles.buttonsContainer, isExpanded && styles.buttonsContainerExpanded)}>
      <Button
        variant="text"
        className={styles.button}
        icon={<FullIcon className={clsx(styles.collapseIcon, isExpanded && styles.collapseIconDown)} />}
        onClick={() => setIsExpanded((actual) => !actual)}
      >
        {isExpanded ? t('closeTable') : t('openTable')}
      </Button>

      {isExpanded && (
        <Button
          variant="text"
          className={styles.button}
          icon={<EditIcon className={styles.buttonIcon} />}
          onClick={openViewSettings}
        >
          {t('editSequence')}
        </Button>
      )}
    </div>
  );
});
