export function* indexGenerator() {
  for (let index = 0; ; index++) {
    yield index;
  }
}

/** Creates new infinite index generator and returns function that returns new index. */
export function getNextIndex(): () => number {
  const rigIndexIterator = indexGenerator();

  return () => {
    const { value, done } = rigIndexIterator.next();

    if (done) {
      throw new Error('Index generator worked unexpectedly.');
    }

    return value;
  };
}
