import { observer } from 'mobx-react-lite';

import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingRigsChartDataModel } from '../../comparing-rigs-chart/data';

import { ComparingRigsDataBackgroundItem } from './comparing-rigs-data-background-item';

interface Props {
  data: ComparingRigsChartDataModel.ViewItem[] | null;
  verticalViewport: Viewport;
}

export const ComparingRigsDataItemsBackground = observer(function ComparingRigsDataItemsBackground({
  data,
  verticalViewport,
}: Props) {
  return (
    <>
      {data?.map((item) => {
        if (item instanceof RigsGroup || item instanceof ChartRig) {
          return (
            <ComparingRigsDataBackgroundItem
              key={item.getKey('background')}
              item={item}
              verticalViewport={verticalViewport}
            />
          );
        }

        return null;
      })}
    </>
  );
});
