import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { Moment } from 'moment';

import { fetchRighs, recalculateGraph } from 'src/api/recalculate-graph/recalculate-graph';
import { BaseApiError } from 'src/errors';
import { TOption } from 'src/features/well-form/types';
import { hasValue } from 'src/shared/utils/common';

import { Directories } from '../directories/directories.store';
import { TPlainDictObject } from '../directories/types';
import { DraftsStore } from '../drafts/drafts-store';
import { NotificationsStore } from '../notifications-store/notifications-store';

type TTag = 'DRILLING_DURATION' | 'COMMERCIAL_SPEED' | 'PASSING_VALUE' | 'STAGE' | 'GEOLOGIC_LOAD';

type TReqBody = {
  startDate: number;
  endDate?: number;
  tags: TTag[];
  rigs: number[];
};

export class RecalculateCarpetStore {
  private readonly planVersion: DraftsStore;
  private readonly notifications: NotificationsStore;
  private readonly directories: Directories;

  @observable rigs: TOption<number>[] = [];
  @observable isLoading = false;

  @observable startDate: Moment | null = null;
  @observable endDate: Moment | null = null;
  @observable chosenRigs: number[] = [];

  @observable commSpeed = false;
  @observable stages = false;
  @observable geoLoad = false;
  @observable penetration = false;

  constructor(directories: Directories, notifications: NotificationsStore, planVersion: DraftsStore) {
    this.planVersion = planVersion;
    this.notifications = notifications;
    this.directories = directories;

    makeObservable(this);
  }

  private serializeRigs(rawRigs: TPlainDictObject[]): TOption<number>[] {
    const options: TOption<number>[] = [];

    for (const rawRig of rawRigs) {
      const label: string | null = (() => {
        const rigNumber = rawRig['Common_Rig.rigNumber'];

        if (!hasValue(rigNumber)) {
          return null;
        }

        if (hasValue(rawRig['Common_Rig.name'])) {
          const name = rawRig['Common_Rig.name'] as string;

          return `${name} / ${rigNumber}`;
        } else if (hasValue(rawRig['Common_Rig.modelId'])) {
          const modelId = rawRig['Common_Rig.modelId'].toString();

          const model = this.directories.getObject('Common_Rig_Model')?.find((d) => d.id.toString() === modelId)
            ?.data.name;

          if (!model) {
            return null;
          }

          return `${model} / ${rigNumber}`;
        }

        return null;
      })();

      if (!label) {
        continue;
      }

      options.push({
        label,
        value: rawRig['Common_Rig.id'] as number,
      });
    }

    return options;
  }

  private async fetchDirectories() {
    await this.directories.loadObjects(['Common_Rig_Model']);
  }

  @flow.bound
  private async *fetchRigs(planVersionId: number) {
    try {
      await this.fetchDirectories();
      yield;

      const data = await fetchRighs(planVersionId);
      yield;

      this.rigs = this.serializeRigs(data);
    } catch (e) {
      yield;

      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }

      console.error();
    }
  }

  private getRequestData(): TReqBody | null {
    if (!this.startDate) {
      return null;
    }

    const reqBody: TReqBody = {
      startDate: this.startDate.unix(),
      tags: [],
      rigs: [...this.chosenRigs],
    };

    if (this.endDate) {
      reqBody.endDate = this.endDate.unix();
    }

    if (this.commSpeed) {
      reqBody.tags.push('COMMERCIAL_SPEED');
    }

    if (this.stages) {
      reqBody.tags.push('STAGE');
    }

    if (this.geoLoad) {
      reqBody.tags.push('GEOLOGIC_LOAD');
    }

    if (this.penetration) {
      reqBody.tags.push('PASSING_VALUE');
    }

    return reqBody;
  }

  @flow.bound
  async *compare(onSuccess: VoidFunction, onCloseClb: VoidFunction) {
    const data = this.getRequestData();

    if (!data || !this.planVersion.draftVersionId) {
      return;
    }

    this.isLoading = true;

    try {
      await recalculateGraph({
        planVersionId: this.planVersion.draftVersionId,
        startDate: data.startDate,
        endDate: data.endDate,
        computeTags: data.tags,
        rigIds: data.rigs,
      });

      this.clear();
      onSuccess();
      onCloseClb();
    } catch (e) {
      yield;

      if (e instanceof BaseApiError) {
        if (e.responseMessage) {
          this.notifications.showErrorMessage(e.responseMessage);
          return;
        }

        if (e.message) {
          this.notifications.showErrorMessage(e.message);
          return;
        }
      }

      this.notifications.showErrorMessageT('errors:RecalculateModal.failedToRecalculate');

      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  @computed
  get isCompareAvailable(): boolean {
    return !!this.startDate;
  }

  @action.bound
  toggleRig(rigId: number): void {
    const index = this.chosenRigs.indexOf(rigId);
    const copiedChosenRigs = [...this.chosenRigs];

    if (index >= 0) {
      copiedChosenRigs.splice(index, 1);
    } else {
      copiedChosenRigs.push(rigId);
    }

    this.chosenRigs = copiedChosenRigs;
  }

  @action.bound
  clear(): void {
    this.commSpeed = false;
    this.geoLoad = false;
    this.penetration = false;
    this.stages = false;
    this.startDate = null;
    this.endDate = null;
  }

  @action.bound
  setStartDate(date: Moment | null): void {
    this.startDate = date;
  }

  @action.bound
  setEndDate(date: Moment | null): void {
    this.endDate = date;
  }

  @action.bound
  setCommSpeed(is: boolean): void {
    this.commSpeed = is;
  }

  @action.bound
  setStages(is: boolean): void {
    this.stages = is;
  }

  @action.bound
  setGeoLoad(is: boolean): void {
    this.geoLoad = is;
  }

  @action.bound
  setPenetration(is: boolean): void {
    this.penetration = is;
  }

  init = (): VoidFunction => {
    this.fetchDirectories();

    const rigsDisposer = reaction(
      () => this.planVersion.draftVersionId,
      (draftId: number | null) => {
        if (!hasValue(draftId)) {
          return;
        }

        this.fetchRigs(draftId);
      },
      { fireImmediately: true }
    );

    return () => {
      rigsDisposer();
    };
  };
}
