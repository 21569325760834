import {
  closestCenter,
  DndContext,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { observer } from 'mobx-react-lite';
import React from 'react';

interface Props {
  children: React.ReactNode;
  onDragEnd: (event: DragEndEvent) => void;
}

export const SortableWrapper = observer(function SortableWrapper({ onDragEnd, children }: Props) {
  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      {children}
    </DndContext>
  );
});
