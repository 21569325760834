import { action, flow, makeObservable, observable, computed, reaction } from 'mobx';

import {
  getPlanningView,
  getPlanningGroups,
  getPlanningListField,
  getComparePlanningListField,
  getComparePlanningListRig,
  getPlanningListRig,
  HeaderViewType,
  ViewSettingItem,
  PlanningUserSettingsType,
  computeRigOperations,
  computeRigOperationsList,
} from 'src/api/planning';
import { mapUserSettings } from 'src/api/planning/serializers';
import { UserSettingsManager } from 'src/api/user-settings';
import { OBJECT_TYPE, removeRig } from 'src/api/wells-list';
import { BaseApiError } from 'src/errors';
import { Pad } from 'src/features/planning/store/pad';
import { TableGroupStore as TableGroup } from 'src/features/planning/store/table-group';
import { DEFAULT_PLANNING_SETTINGS_VALUES } from 'src/pages/plan-page/constants';
import { SortingOptionType, GROUP_TYPE, FilterType, PAGE_MODE, RIG_OPERATIONS_GROUP } from 'src/pages/plan-page/types';
import { mapColumnsData, serializeSortingObject, serializeFiltersObject } from 'src/pages/plan-page/utils';
import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';
import { ComparisonStore } from 'src/store/comparison/comparison-store';
import { Directories } from 'src/store/directories/directories.store';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { EditingStore } from 'src/store/editing/editing-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore as PlanVersion } from 'src/store/plan-version';
import { RouterStore } from 'src/store/router/router-store';
import { ColumnType } from 'src/store/table/types';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

export class PlanPageStore {
  private readonly userSettingsManager: UserSettingsManager<PlanningUserSettingsType>;

  @observable isLoading: boolean = false;
  @observable isActionLoading: boolean = false;
  @observable viewSettingsSidebar: boolean = false;
  @observable isAddPadSidebarOpened: boolean = false;
  @observable isWellInfoSidebarOpen: boolean = false;
  @observable groupType: GROUP_TYPE = GROUP_TYPE.field;
  @observable tableView: ColumnType[] = [];
  @observable tableGroups: TableGroup[] = [];
  @observable padHeaderView: HeaderViewType[] = [];
  @observable viewSettingsGroups: ViewSettingItem[] = [];
  @observable selectedWellId: number | null = null;
  @observable selectedPadName?: string;
  @observable rowIdKey: string | null = null;
  @observable activeTableGroupsIndexes: string[] = [];

  readonly directories: Directories;
  readonly planVersion: PlanVersion;
  readonly comparison: ComparisonStore;
  readonly draftStore: DraftsStore;
  readonly editing: EditingStore;
  readonly notifications: NotificationsStore;
  readonly router: RouterStore;
  readonly wellFormManager: WellFormManagerWithGeoTasksHistory;

  constructor(store: RootStore) {
    this.userSettingsManager = new UserSettingsManager<PlanningUserSettingsType>(
      'planning',
      DEFAULT_PLANNING_SETTINGS_VALUES
    );
    this.directories = store.directories;
    this.planVersion = store.planVersion;
    this.comparison = store.comparison;
    this.draftStore = store.drafts;
    this.router = store.router;
    this.notifications = store.notifications;
    this.editing = store.editing;
    this.wellFormManager = new WellFormManagerWithGeoTasksHistory(store);

    makeObservable(this);
  }

  @action.bound
  pageEffect() {
    this.fetchPlanning();

    const disposePlanVersionId = reaction(() => this.planVersion.version?.id, this.fetchPlanning);
    const disposeMode = reaction(() => this.mode, this.reloadActiveTableGroups);

    return () => {
      disposePlanVersionId();
      disposeMode();
    };
  }

  @computed
  get mode(): PAGE_MODE {
    if (this.editing.isEditing) {
      return PAGE_MODE.edit;
    }

    if (this.comparison.isComparing) {
      return PAGE_MODE.compare;
    }

    return PAGE_MODE.view;
  }

  @computed
  get currentVersionId(): number | null {
    if (this.mode === PAGE_MODE.edit && hasValue(this.editing.drafts.draftVersionId)) {
      return this.editing.drafts.draftVersionId;
    }

    return this.planVersion.version?.id || null;
  }

  @computed
  get planTableView(): ColumnType[] {
    return this.tableView;
  }

  @computed
  get planData(): TableGroup[] {
    return this.tableGroups;
  }

  @computed
  get rigOperationGroup(): RIG_OPERATIONS_GROUP {
    if (this.groupType === GROUP_TYPE.rig) {
      return RIG_OPERATIONS_GROUP.rig;
    }

    return RIG_OPERATIONS_GROUP.pad;
  }

  @action.bound
  openTableGroup(tableGroup: TableGroup): void {
    this.activeTableGroupsIndexes.push(tableGroup.indexKey);

    if (this.mode === PAGE_MODE.compare) {
      this.fetchComparePads(tableGroup);
      return;
    }

    this.fetchPads(tableGroup);
  }

  @action.bound
  closeTableGroup(tableGroup: TableGroup): void {
    const elementIndex = this.activeTableGroupsIndexes.findIndex(
      (tableGroupIndex) => tableGroupIndex === tableGroup.indexKey
    );

    if (elementIndex !== -1) {
      this.activeTableGroupsIndexes.splice(elementIndex, 1);
    }
  }

  @action.bound
  setGroupType(groupType: GROUP_TYPE): void {
    this.groupType = groupType;
    this.activeTableGroupsIndexes = [];
    this.fetchPlanning();
  }

  @action.bound
  setTableView(tableView: ColumnType[]): void {
    this.userSettingsManager.update({
      [this.groupType]: {
        columns: tableView.map((column) => {
          return { id: column.id, width: column.width };
        }),
      },
    });

    this.tableGroups.forEach((tableGroup) => tableGroup.setTableColumns(tableView));
  }

  @action.bound
  openViewSettingSidebar(): void {
    this.viewSettingsSidebar = true;
  }

  @action.bound
  closeViewSettingSidebar(): void {
    this.viewSettingsSidebar = false;
  }

  @action.bound
  openAddPadSidebar(padName?: string): void {
    this.selectedPadName = padName;

    this.isAddPadSidebarOpened = true;
  }

  @action.bound
  closeAddPadSidebar(): void {
    this.isAddPadSidebarOpened = false;
  }

  @action.bound
  openWellInfoSidebarWithWell(wellId: number): void {
    this.selectedWellId = wellId;
    this.isWellInfoSidebarOpen = true;
  }

  @action.bound
  closeWellInfoSidebar(): void {
    this.isWellInfoSidebarOpen = false;
  }

  @flow.bound
  async *removePad(tableGroup: TableGroup, padId: number) {
    if (!this.currentVersionId) return;
    tableGroup.setLoading(true);

    try {
      await removeRig(this.currentVersionId, OBJECT_TYPE.pad, [padId]);
      await this.fetchPads(tableGroup);
    } catch (error) {
      yield;
      console.error(error);
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToCopyWell');
    } finally {
      tableGroup.setLoading(false);
    }
  }

  @flow.bound
  async *reloadActiveTableGroups() {
    this.isLoading = true;

    try {
      for (const activeTableGroupIndex of this.activeTableGroupsIndexes) {
        const tableGroup = this.tableGroups[Number(activeTableGroupIndex)];
        if (this.mode === PAGE_MODE.compare) {
          await this.fetchComparePads(tableGroup);
        } else {
          await this.fetchPads(tableGroup);
        }
      }
    } catch (error) {
      yield;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToReloadCarpet');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *setSorting(tableGroup: TableGroup, sortingOption: SortingOptionType | null) {
    tableGroup.pads.forEach((pad) => pad.table.setLoading(true));
    tableGroup.setSorting(sortingOption);

    try {
      await this.fetchPads(tableGroup);
    } finally {
      yield;
      tableGroup.pads.forEach((pad) => pad.table.setLoading(false));
    }
  }

  @flow.bound
  async *setFilters(tableGroup: TableGroup, filters: FilterType[]) {
    tableGroup.pads.forEach((pad) => pad.table.setLoading(true));
    tableGroup.setFilters(filters);

    try {
      await this.fetchPads(tableGroup);
    } finally {
      yield;
      tableGroup.pads.forEach((pad) => pad.table.setLoading(false));
    }
  }

  @flow.bound
  async *enableEditing() {
    if (this.isLoading) return;
    this.isLoading = true;

    for (const tableGroup of this.tableGroups) {
      tableGroup.resetFilters();
      tableGroup.resetSorting();
    }

    try {
      await this.editing.enableEditing();
      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *cancelEditing() {
    try {
      this.isActionLoading = true;

      await this.editing.cancelEditing();
      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isActionLoading = false;
    }
  }

  @flow.bound
  async *saveChanges() {
    try {
      this.isActionLoading = true;

      await this.editing.saveChanges();
      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isActionLoading = false;
    }
  }

  @flow.bound
  async *publishChanges() {
    try {
      this.isActionLoading = true;

      await this.editing.publishChanges();
      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isActionLoading = false;
    }
  }

  @flow.bound
  async *computeRigOperations(
    tableGroup: TableGroup,
    insert: number,
    insertOnPlace: number | null,
    insertAfter: number | null
  ) {
    if (!this.currentVersionId) {
      return;
    }

    tableGroup.pads.forEach((pad) => pad.table.setLoading(true));

    try {
      await computeRigOperations(this.currentVersionId, insert, insertOnPlace, insertAfter, this.rigOperationGroup);
      await this.editing.drafts.conflictResolver.checkConflicts(this.currentVersionId);
      yield;

      await this.fetchPads(tableGroup);
      yield;
    } catch (error) {
      yield;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToComutingCarpet');
    } finally {
      tableGroup.pads.forEach((pad) => pad.table.setLoading(false));
    }
  }

  @flow.bound
  async *computeRigOperationsList(
    tableGroup: TableGroup,
    insert: number[],
    insertOnPlace: number | null,
    insertAfter: number | null
  ) {
    if (!this.currentVersionId) {
      return;
    }

    tableGroup.pads.forEach((pad) => pad.table.setLoading(true));

    try {
      await computeRigOperationsList(this.currentVersionId, insert, insertOnPlace, insertAfter, this.rigOperationGroup);
      await this.editing.drafts.conflictResolver.checkConflicts(this.currentVersionId);
      yield;

      await this.fetchPads(tableGroup);
      yield;
    } catch (error) {
      yield;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToComutingCarpet');
    } finally {
      tableGroup.pads.forEach((pad) => pad.table.setLoading(false));
    }
  }

  @flow.bound
  async *refreshTableData() {
    try {
      const [view, groups] = await Promise.all([getPlanningView(this.groupType), getPlanningGroups(this.groupType)]);
      yield;

      this.tableGroups = groups.data.map((group, index) => new TableGroup(index.toString(), view, group));
      this.tableGroups.forEach(this.fetchPads);
      this.selectedWellId = null;
    } catch (e) {
      yield;

      console.error(e);
      this.notifications.showErrorMessageT('errors:failedToReloadPage');
    }
  }

  @flow.bound
  async *fetchPlanning() {
    this.isLoading = true;
    this.activeTableGroupsIndexes = [];

    try {
      const [view, groups, columnsSettings] = await Promise.all([
        getPlanningView(this.groupType),
        getPlanningGroups(this.groupType),
        this.userSettingsManager.getOrCreate().then((userSettings) => mapUserSettings(userSettings, this.groupType)),
      ]);

      const objNames = view.pad.tableItems.reduce<string[]>((prev, curr) => {
        return curr.refObjectType ? prev.concat(curr.refObjectType) : prev;
      }, []);

      await this.directories.loadObjects(objNames);

      yield;

      this.rowIdKey = view.rowIdKey;

      this.viewSettingsGroups = view.settings.groups;

      this.padHeaderView = view.pad.header;

      this.tableView = mapColumnsData(view, columnsSettings, this.directories);

      this.tableGroups = groups.data.map((group, index) => new TableGroup(index.toString(), view, group));
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
      return;
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *fetchPads(tableGroup: TableGroup) {
    try {
      const sortingObject = serializeSortingObject(tableGroup.tableView, tableGroup.sorting);
      const filterObject = serializeFiltersObject(tableGroup.tableView, tableGroup.filters);

      if (this.groupType === GROUP_TYPE.field) {
        const fieldId = tableGroup.getGroupDataValue('Common_Field.id');
        const blockId = tableGroup.getGroupDataValue('Common_Block.id');

        if (hasValue(fieldId) && hasValue(blockId) && hasValue(this.currentVersionId)) {
          const planningListField = await getPlanningListField(
            fieldId,
            blockId,
            this.currentVersionId,
            sortingObject,
            filterObject
          );

          yield;

          tableGroup.setPads(
            planningListField.map((pad) => new Pad(pad.id, pad.data, this.tableView, pad.items, this.padHeaderView))
          );
        }
      }

      if (this.groupType === GROUP_TYPE.rig) {
        const rigId = tableGroup.getGroupDataValue('Common_Rig.id');

        if (hasValue(rigId) && hasValue(this.currentVersionId)) {
          const planningListRig = await getPlanningListRig(rigId, this.currentVersionId, sortingObject, filterObject);

          yield;
          tableGroup.setPads(
            planningListRig.map((pad) => new Pad(pad.id, pad.data, this.tableView, pad.items, this.padHeaderView))
          );
        }
      }
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
      return;
    }
  }

  @flow.bound
  async *fetchComparePads(tableGroup: TableGroup) {
    try {
      const firstVersionId = this.comparison.firstTargetPlan?.id;
      const secondVersionId = this.comparison.secondTargetPlan?.id;

      const sortingObject = serializeSortingObject(tableGroup.tableView, tableGroup.sorting);
      const filterObject = serializeFiltersObject(tableGroup.tableView, tableGroup.filters);

      if (hasValue(firstVersionId) && hasValue(secondVersionId)) {
        if (this.groupType === GROUP_TYPE.field) {
          const fieldId = tableGroup.getGroupDataValue('Common_Field.id');
          const blockId = tableGroup.getGroupDataValue('Common_Block.id');

          if (hasValue(fieldId) && hasValue(blockId)) {
            const comparePlanningListField = await getComparePlanningListField(
              fieldId,
              blockId,
              firstVersionId,
              secondVersionId,
              sortingObject,
              filterObject
            );

            yield;

            tableGroup.setPads(
              comparePlanningListField.map(
                (pad) => new Pad(pad.id, pad.data, this.tableView, pad.items, this.padHeaderView)
              )
            );
          }
        }

        if (this.groupType === GROUP_TYPE.rig) {
          const rigId = tableGroup.getGroupDataValue('Common_Rig.id');

          if (hasValue(rigId)) {
            const comparePlanningListRig = await getComparePlanningListRig(
              rigId,
              firstVersionId,
              secondVersionId,
              sortingObject,
              filterObject
            );

            yield;

            tableGroup.setPads(
              comparePlanningListRig.map(
                (pad) => new Pad(pad.id, pad.data, this.tableView, pad.items, this.padHeaderView)
              )
            );
          }
        }
      }
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
      return;
    }
  }

  @action.bound
  async onFormClose() {
    await this.refreshTableData();
    this.wellFormManager.setIsFormOpen(false);
    this.wellFormManager.resetFormManager();
  }
}
