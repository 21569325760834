import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Range } from '../../layers/model';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';

import styles from './empty-block-wrapper.module.scss';

interface Props {
  item: { x?: Range<number | null> | null; y?: Range<number> };
  verticalViewport?: Viewport;
  horizontalViewport?: Viewport;
  className?: string;
  onClick?: VoidFunction;
}

export const EmptyBlockWrapper = observer(function EmptyBlockWrapper({
  item,
  verticalViewport,
  horizontalViewport,
  className,
  onClick,
}: Props) {
  const ref = useMovableElement({
    item,
    verticalViewport,
    horizontalViewport,
  });

  return <div ref={ref} className={classNames(className, styles.block)} onClick={onClick} />;
});
