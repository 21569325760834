import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { SidebarPadRigOperation } from 'src/features/drilling-chart/presets/pads-and-wells-sidebar/entities';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';

import { PadAttributeIcon } from '../../../../pad-attribute-icon';
import { PadCard } from '../../../../pad-card';
import { PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { DraggableGhost } from '../../editing/shared/entities/draggable-ghost';
import { useDraggableGhost } from '../../editing/view/hooks/use-draggable-ghost';

import styles from './data-items-full.module.scss';

interface Props {
  item: DraggableGhost<PadRigOperation | SidebarPadRigOperation>;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const DraggableGhostPadRigOperationWrapper = observer(function DraggableGhostPadRigOperationWrapper({
  item,
  viewSettingsProvider,
}: Props) {
  const { transform, isPositionAvailable, draggableContainerElement } = useDraggableGhost({
    id: item.activeKey,
  });

  const ref = useMovableElement({
    item: item.ghost,
    transform,
  });

  const padAttributes = viewSettingsProvider.getPadAttributes(item.ghost);

  const padIcons = padAttributes?.length && (
    <div className={styles.iconsContainer}>
      {padAttributes?.map((attributeName) => (
        <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.icon} />
      ))}
    </div>
  );

  if (!draggableContainerElement) {
    return null;
  }

  return createPortal(
    <div ref={ref} className={clsx(styles.dataItem, styles.draggablePadGhost)}>
      <PadCard
        attributes={viewSettingsProvider.getAttributes(item.ghost)}
        title={<LoadingTitle field={viewSettingsProvider.getTitle(item.ghost)} />}
        extra={padIcons}
        className={clsx(styles.card, !isPositionAvailable && styles.notAvailable)}
      />
    </div>,
    draggableContainerElement
  );
});
