import { action, makeObservable, observable } from 'mobx';

import { IEqual } from 'src/types/common';
import { WellTypes } from 'src/types/well-types';

import { WellsDataPositionsCalculator } from '../../../features/wells-chart/data/wells-data-positions-calculator';
import { Range } from '../../../layers/model';

import { ChartWell } from './chart-well';

export class WellsGroup implements IEqual<WellsGroup> {
  private readonly item: WellTypes.RawWellsGroupWithItems;

  readonly items: ChartWell[];
  readonly id: number;

  @observable isCollapsed: boolean = WellsDataPositionsCalculator.DEFAULT_IS_COLLAPSED;
  @observable y: Range<number> = { start: 0, end: 0 };
  @observable rowsY: Range<number> = { start: 0, end: 0 };

  constructor(wellsGroup: WellTypes.RawWellsGroupWithItems, id: number, wells: ChartWell[]) {
    this.item = wellsGroup;
    this.id = id;
    this.items = wells;

    makeObservable(this);
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-wells-group-${this.id}`;
    }

    return `wells-group-${this.id}`;
  }

  getFieldValue(fieldName: string): string | number | boolean | undefined {
    if (fieldName in this.item.data) {
      return this.item.data[fieldName];
    }
  }

  getState(): WellsGroup.State {
    return {
      isCollapsed: this.isCollapsed,
    };
  }

  isEqual(item: WellsGroup): boolean {
    return this.id === item.id;
  }

  @action.bound
  setY(value: Range<number>) {
    this.y = value;
  }

  @action.bound
  setRowsY(rowsY: Range<number>) {
    this.rowsY = rowsY;
  }

  @action.bound
  setIsCollapsed(isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;
  }
}

export namespace WellsGroup {
  export type State = {
    isCollapsed: boolean;
  };
}
