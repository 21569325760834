import { v4 as uuid4 } from 'uuid';

import { Dnd } from '../editing/types';

const DEFAULT_OPTIONS: SortableGhostItem.Options = {
  isShown: true,
};

export class SortableGhostItem<TGhostItem> {
  private readonly uniqueCreationKey: string;

  readonly ghost: Dnd.Dragging<TGhostItem>;
  /** ID of active draggable/sortable element that this element is related with. */
  readonly activeKey: string;
  /** Initial X coordinates of parent item. */
  readonly x: Dnd.PositionRange;
  /** Initial Y coordinates of parent item. */
  readonly y: Dnd.PositionRange;
  readonly options?: SortableGhostItem.Options;

  constructor(
    item: Dnd.Dragging<TGhostItem>,
    activeSortableKey: string,
    x: Dnd.PositionRange,
    y: Dnd.PositionRange,
    options?: SortableGhostItem.Options
  ) {
    this.ghost = item;
    this.activeKey = activeSortableKey;
    this.uniqueCreationKey = uuid4();
    this.options = { ...DEFAULT_OPTIONS, ...options };

    this.x = x !== null ? { ...x } : null;
    this.y = y !== null ? { ...y } : null;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-ghost-${this.uniqueCreationKey}`;
    }

    return `ghost-${this.uniqueCreationKey}`;
  }
}

export namespace SortableGhostItem {
  export type Options = Partial<{
    isShown: boolean;
  }>;
}
