import { Point, SVGAttributes, SVGElement } from 'highcharts';

export namespace LabelsPlacement {
  export const LABEL_OFFSET = 18;
  export const CONNECTED_LINE_OFFSET = 10;
  export const DATA_LABEL_HEIGHT = 21;
  export const BAR_DATA_LABEL_HEIGHT = 21;
  export const BAR_LABEL_OFFSET = 1;
  export const BAR_VERTICAL_LINE_OFFSET = Math.ceil(BAR_DATA_LABEL_HEIGHT * 2.5);
  export const BAR_LABEL_POSITION_OFFSET = BAR_DATA_LABEL_HEIGHT * 2;
  export const BAR_SPACING_TOP = BAR_DATA_LABEL_HEIGHT * 3;
  export const BAR_LABEL_PART_WIDTH = 8;
  export const BAR_SPACE_WIDTH = 15;
  export const RESERVED_ROWS_MAX_LENGTH = 2;

  export const LINE_DATA_LABELS_FONT_SIZE = 10;

  export const DEFAULT_LABEL_COLOR_DARK = '#1e1e1e';
  export const DEFAULT_LABEL_COLOR_LIGHT = '#ffffff';
  export const DEFAULT_LINE_COLOR = '#b6b6b6';

  export type Position = {
    x: number;
    y: number;
  };

  export interface SimplePoint {
    position: Position;
    label?: string;
    color?: string;
    isTop?: boolean;
    isBottom?: boolean;
  }

  export interface LabelPoint extends SimplePoint {
    parentPosition: Position;
  }

  export type CellState = {
    isBusy: boolean;
    point?: LabelPoint;
    alignedPosition?: Position;
  };

  export type Drawer = {
    drawLabel: (str: string, x: number, y: number, color?: string, fontSize?: number, invert?: boolean) => SVGElement;
    drawVerticalLine: (x: number, y1: number, y2: number, color?: string) => SVGElement;
    drawLine: (
      x1: number,
      y1: number,
      x2: number,
      y2: number,
      color?: string,
      attributes?: SVGAttributes
    ) => SVGElement;
  };

  export type PointWithPosition = {
    point: Point;
    position: { x: number; y: number };
    style?: {
      color?: string;
      fontSize?: number;
    };
  };

  export type PointWithParent = {
    point: PointWithPosition;
    parent: PointWithPosition;
  };

  export type GroupedPoints = Record<string, PointWithPosition[]>;
}
