import clsx from 'clsx';
import CSS from 'csstype';
import { observer } from 'mobx-react-lite';
import { ReactChildren, useEffect, useRef, useState } from 'react';

import { ComboBoxFilter } from 'src/features/wells-list/wells-table/resizable-table-title/combo-box-filter';
import { ComplexTooltip } from 'src/features/wells-list/wells-table/resizable-table-title/complex-tooltip';
import { DateFilter } from 'src/features/wells-list/wells-table/resizable-table-title/date-filter';
import { TooltipButtonsGroup } from 'src/features/wells-list/wells-table/resizable-table-title/tooltip-buttons-group';
import { SORTING_DIRECTION } from 'src/pages/wells-page/types';
import { serializeUserSettingsData } from 'src/pages/wells-page/utils';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as ArrowDownIcon } from 'src/shared/assets/icons/arrow-down.svg';
import { ReactComponent as EllipsisIcon } from 'src/shared/assets/icons/ellipsis.svg';
import { ReactComponent as FilterIcon } from 'src/shared/assets/icons/filter.svg';
import { Tooltip } from 'src/shared/components/tooltip';
import { useElementSize } from 'src/shared/hooks/use-element-size';
import { useOutsideClick } from 'src/shared/hooks/use-outside-click';
import { hasValue } from 'src/shared/utils/common';
import { COLUMN_CONTROL } from 'src/store/table/types';

import styles from './resizable-table-title.module.scss';

export interface Props {
  children: ReactChildren;
  minColumnWidth: number;
  columnId: string;
  columnType: COLUMN_CONTROL;
  leftOffset: number;
  className: string;
  pageStore: WellsPageStore;
  isFixed: boolean;
  width: number;
  attrName?: string;
  objectType?: string;
  objectField?: string;
  isSortable?: boolean;
  style?: CSS.Properties;
  isParent?: boolean;
  isChild?: boolean;
  setColumnHeaderHeight?(height: number): void;
}

export const ResizableTableTitle = observer(function ResizableTitle({
  width,
  children,
  minColumnWidth,
  columnId,
  isSortable,
  isFixed,
  leftOffset,
  style,
  isParent,
  className,
  isChild,
  setColumnHeaderHeight,
  columnType,
  pageStore,
  objectType,
  objectField,
  attrName,
  ...rest
}: Props) {
  const [isOpen, setOpen] = useState(false);

  const refRight = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { ref, height } = useElementSize<HTMLTableHeaderCellElement>();

  useOutsideClick(containerRef, () => setOpen(false), [buttonRef]);

  const isResizable = !isChild && columnId !== 'select' && columnId !== 'copy';

  function onSortInAscendingOrder(): void {
    attrName && pageStore.setSorting({ fieldId: columnId, direction: SORTING_DIRECTION.asc, attrName: attrName });
  }

  function onSortInDescendingOrder(): void {
    attrName && pageStore.setSorting({ fieldId: columnId, direction: SORTING_DIRECTION.desc, attrName: attrName });
  }

  function onResetSorting(): void {
    pageStore.setSorting(null);
  }

  function onShownColumnsChange(): void {
    const newColumnsData = pageStore.table.columnsData.map((column) => {
      return column.id === columnId
        ? {
            ...column,
            isShown: !column.isShown,
          }
        : column;
    });

    pageStore.setColumnsSettings(newColumnsData);
  }

  function onFixedColumnsChange(): void {
    const newColumnsData = pageStore.table.columnsData.map((column) => {
      if (column.id === columnId) {
        setOpen(false);

        return {
          ...column,
          fixed: !column.fixed,
          ...(column?.children && {
            children: column.children.map((childColumn) => {
              return { ...childColumn, fixed: !childColumn.fixed };
            }),
          }),
        };
      }

      return column;
    });

    pageStore.setColumnsSettings(newColumnsData);
  }

  function renderTooltipComponent(): React.ReactNode {
    switch (columnType) {
      case COLUMN_CONTROL.ComboBox:
        return (
          <ComplexTooltip
            fieldId={columnId}
            sorting={pageStore.sorting}
            onShownColumnsChange={onShownColumnsChange}
            onFixedColumnsChange={!isChild ? onFixedColumnsChange : undefined}
            onSortInAscendingOrder={!isParent ? onSortInAscendingOrder : undefined}
            onSortInDescendingOrder={!isParent ? onSortInDescendingOrder : undefined}
            onResetSorting={columnId === pageStore.sorting?.fieldId ? onResetSorting : undefined}
            attrName={attrName}
            isFixed={isFixed}
          >
            <ComboBoxFilter
              fieldId={columnId}
              filters={pageStore.filters}
              onFiltersChange={pageStore.setFilters}
              object={pageStore.directories.getObject(objectType)}
              objectField={objectField}
              attrName={attrName}
            />
          </ComplexTooltip>
        );

      case COLUMN_CONTROL.DateOnlyPicker:
        return (
          <ComplexTooltip
            fieldId={columnId}
            sorting={pageStore.sorting}
            onShownColumnsChange={onShownColumnsChange}
            onFixedColumnsChange={!isChild ? onFixedColumnsChange : undefined}
            onSortInAscendingOrder={!isParent ? onSortInAscendingOrder : undefined}
            onSortInDescendingOrder={!isParent ? onSortInDescendingOrder : undefined}
            onResetSorting={columnId === pageStore.sorting?.fieldId ? onResetSorting : undefined}
            attrName={attrName}
            isFixed={isFixed}
          >
            <DateFilter
              fieldId={columnId}
              filters={pageStore.filters}
              onFiltersChange={pageStore.setFilters}
              attrName={attrName}
            />
          </ComplexTooltip>
        );

      case COLUMN_CONTROL.Progress:
        return (
          <TooltipButtonsGroup
            onSortInAscendingOrder={!isParent ? onSortInAscendingOrder : undefined}
            onSortInDescendingOrder={!isParent ? onSortInDescendingOrder : undefined}
            onResetSorting={columnId === pageStore.sorting?.fieldId ? onResetSorting : undefined}
          />
        );

      default: {
        return (
          <TooltipButtonsGroup
            isFixed={isFixed}
            onShownColumnsChange={onShownColumnsChange}
            onFixedColumnsChange={!isChild ? onFixedColumnsChange : undefined}
            onSortInAscendingOrder={!isParent ? onSortInAscendingOrder : undefined}
            onSortInDescendingOrder={!isParent ? onSortInDescendingOrder : undefined}
            onResetSorting={columnId === pageStore.sorting?.fieldId ? onResetSorting : undefined}
          />
        );
      }
    }
  }

  function getButtonStyle(): string {
    const isFiltered = pageStore.filters.find((filter) => filter.fieldId === columnId && filter.attrName === attrName);
    const isSorting = columnId === pageStore.sorting?.fieldId && attrName === pageStore.sorting?.attrName;

    return clsx(styles.optionButton, {
      [styles.optionButtonActive]: isFiltered || isSorting,
      [styles.optionButtonSorting]: isSorting,
      [styles.optionButtonFiltered]: isFiltered,
    });
  }

  useEffect(() => {
    const refRightCurrent = refRight.current;
    if (refRightCurrent) {
      const onMouseDownRightResize = (event: PointerEvent) => {
        if (!event.isPrimary) {
          return;
        }

        pageStore.table.resizeStart(columnId, width, minColumnWidth, event.clientX);

        document.addEventListener('pointermove', onMouseMoveRightResize);
        document.addEventListener('pointerup', onMouseUpRightResize);
      };

      const onMouseMoveRightResize = (event: PointerEvent) => {
        pageStore.table.setXCoordinates(event.clientX);
      };

      const onMouseUpRightResize = () => {
        const columnsData = pageStore.table.resizeEnd();

        document.removeEventListener('pointermove', onMouseMoveRightResize);
        document.removeEventListener('pointerup', onMouseUpRightResize);
        pageStore.updateUserSettings(serializeUserSettingsData(columnsData));
      };

      refRightCurrent.addEventListener('pointerdown', onMouseDownRightResize);

      return () => {
        refRightCurrent?.removeEventListener('pointerdown', onMouseDownRightResize);
      };
    }
  }, [minColumnWidth, columnId, width, pageStore]);

  useEffect(() => {
    if (setColumnHeaderHeight && hasValue(height)) {
      setColumnHeaderHeight(height);
    }
  }, [height, setColumnHeaderHeight]);

  useEffect(() => {
    let prevScrollLeft = 0;

    const handleScroll = (e: Event) => {
      const target = e.target;

      if (!target || !('scrollLeft' in target)) {
        return;
      }

      const { scrollLeft } = target;

      if (prevScrollLeft !== scrollLeft) {
        setOpen(false);
      }

      prevScrollLeft = scrollLeft;
    };

    document.addEventListener('scroll', handleScroll, { capture: true });

    return () => {
      document.removeEventListener('scroll', handleScroll, { capture: true });
    };
  }, []);

  return (
    <th
      {...rest}
      style={{ ...style, ...(hasValue(leftOffset) && { left: leftOffset }) }}
      className={clsx(className, isParent && 'ParentColumn')}
      ref={ref}
    >
      <div
        className={clsx(
          styles.titleContainer,
          isChild && styles.titleContainerChild,
          columnId === 'select' && styles.titleContainerSelect
        )}
      >
        <div className={clsx(styles.title, isChild && styles.titleChild)}>{children}</div>

        {isSortable && (
          <Tooltip
            trigger="click"
            text={<div ref={containerRef}>{renderTooltipComponent()}</div>}
            placement="bottom"
            visible={isOpen}
          >
            <button className={getButtonStyle()} ref={buttonRef} onClick={() => setOpen(true)}>
              <FilterIcon className={styles.filterIcon} />
              <ArrowDownIcon
                className={clsx(
                  styles.arrowIcon,
                  pageStore.sorting?.direction === SORTING_DIRECTION.asc && styles.arrowIconAsc
                )}
              />
              <EllipsisIcon />
            </button>
          </Tooltip>
        )}
      </div>

      {isResizable && hasValue(width) && (
        <div ref={refRight} className={clsx(styles.resizeHandler, isParent && styles.resizeHandlerDouble)} />
      )}
    </th>
  );
});
