import { computed, makeObservable, observable } from 'mobx';

import { Range } from '../../layers/model';
import { Viewport } from '../viewport/viewport';
import { YEAR_DURATION_IN_UNIX } from '../viewport/viewport-calculator';

export class DataView {
  @observable viewport: Viewport;

  constructor(viewport: Viewport) {
    this.viewport = viewport;

    makeObservable(this);
  }

  @computed
  get type() {
    return DataView.getViewType(this.viewport);
  }
}

export namespace DataView {
  export enum DataViewType {
    /** With viewport width of 7 years and more. */
    empty = 'empty',
    /** With viewport width of more than 12 months. */
    compact = 'compact',
    /** With viewport width of less or equal than 12 months. */
    full = 'full',
  }

  /**
   * @param viewRange Horizontal viewport range in milliseconds.
   */
  export const getViewType = (viewRange: Range<number>): DataView.DataViewType => {
    const viewRangeLengthInUnix = viewRange.end - viewRange.start;

    if (viewRangeLengthInUnix <= YEAR_DURATION_IN_UNIX) {
      return DataView.DataViewType.full;
    }

    if (viewRangeLengthInUnix > 7 * YEAR_DURATION_IN_UNIX) {
      return DataView.DataViewType.empty;
    }

    if (viewRangeLengthInUnix > YEAR_DURATION_IN_UNIX) {
      return DataView.DataViewType.compact;
    }

    return DataView.DataViewType.empty;
  };
}
