import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/plus.svg';
import { getMailtoHref } from 'src/shared/utils/get-mailto-href';

import styles from './add-new-value-button.module.scss';

type Props = {
  directoryName?: string;
};

export const AddNewValueButton = observer(function AddNewValueButton({ directoryName }: Props) {
  const { t } = useTranslation();

  if (!directoryName) {
    return null;
  }

  return (
    <a className={styles.addNewValueButton} href={getMailtoHref(directoryName, t)}>
      <PlusIcon />
      <p>{t('common:addNewValue')}</p>
    </a>
  );
});
