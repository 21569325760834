import { useContext } from 'react';

import { DraggableTransform } from '../../shared/entities/draggable-transform';
import { Dnd } from '../../types';
import { DndContext } from '../context/dnd-context';

export function useDraggableGhostShadow({ parentId }: UseDraggableGhostShadow.Args): UseDraggableGhostShadow.Return {
  const { active, containerRect, isDraggingPositionAvailable, getRelativeCoordinates } = useContext(DndContext);

  if (!active || !containerRect || parentId !== active.id) {
    return {
      getRelativeCoordinates,
    };
  }

  return {
    transform: active.transform,
    isPositionAvailable: isDraggingPositionAvailable ?? true,
    getRelativeCoordinates,
  };
}

export namespace UseDraggableGhostShadow {
  export type Args = {
    /** ID of element that handles pointer events (parent). Required to get parent transform object. */
    parentId: string;
  };

  export type Return = {
    getRelativeCoordinates(coordinates: Dnd.Coordinates): Dnd.Coordinates;
    transform?: DraggableTransform;
    isPositionAvailable?: boolean;
  };
}
