import { observer } from 'mobx-react-lite';

import { TableCell } from 'src/features/wells-list/wells-table/table-cell';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ColumnType, TableRow } from 'src/store/table/types';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

import styles from './multi-cell.module.scss';

interface Props {
  row: TableRow;
  childColumns?: Omit<ColumnType, 'width'>[];
  pageStore: WellsPageStore;
  wellFormManager: WellFormManagerWithGeoTasksHistory;
}

export const MultiCell = observer(function MultiCell({ row, childColumns, pageStore, wellFormManager }: Props) {
  return (
    <div className={styles.container}>
      {childColumns?.map((childColumn, index) => (
        <div className={styles.cell} key={index}>
          <TableCell
            row={row}
            rowIndex={index}
            column={childColumn}
            pageStore={pageStore}
            wellFormManager={wellFormManager}
          />
        </div>
      ))}
    </div>
  );
});
