import {
  closestCenter,
  DndContext,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';

import { Pad } from 'src/features/planning/store/pad';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getCellValue } from 'src/store/table/utils';

interface Props {
  pads: Pad[];
  setDraggable: (draggable: boolean) => void;
  children: React.ReactNode;
  onCompute(insert: number[], insertOnPlace: number | null, insertAfter: number | null): void;
}

export const SortableWrapper = observer(function SortableWrapper({ pads, setDraggable, children, onCompute }: Props) {
  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  function handleDragEnd({ active, over }: DragEndEvent): void {
    const wellTripleId = 'GOplan_PlanWellTriple.id';

    if (over && active.id !== over.id) {
      const oldIndex = pads.findIndex((pad) => pad.key === active.id);
      const newIndex = pads.findIndex((pad) => pad.key === over.id);

      const draggablePad = pads[oldIndex];
      const padOnPlace = hasValue(pads[newIndex]) ? pads[newIndex] : null;
      const padAfter = hasValue(pads[newIndex - 1]) ? pads[newIndex] : null;

      const insert = draggablePad.table.tableData.reduce<number[]>((prev, curr) => {
        const insert = getCellValue(curr[wellTripleId]);

        if (typeof insert === 'number') {
          prev.push(insert);
        }

        return prev;
      }, []);

      assert(padOnPlace?.table.tableData.length || padAfter?.table.tableData.length);

      const insertOnPlaceTripleId =
        newIndex + 1 !== pads.length && getCellValue(padOnPlace?.table.tableData[0][wellTripleId]);

      const insertAfterTripleId =
        newIndex + 1 === pads.length && getCellValue(padAfter?.table.tableData.at(-1)?.[wellTripleId]);

      const insertOnPlace = typeof insertOnPlaceTripleId === 'number' ? insertOnPlaceTripleId : null;
      const insertAfter = typeof insertAfterTripleId === 'number' ? insertAfterTripleId : null;

      onCompute(insert, insertOnPlace, insertAfter);
    }

    setDraggable(false);
  }

  function handleDragStart(): void {
    setDraggable(true);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={pads.map((pad) => {
          return { ...pad, id: pad.key.toString() };
        })}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
});
