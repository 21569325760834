import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DeleteWellsModal } from 'src/features/modals/delete-wells-modal/delete-wells-modal';
import { WellsTabs } from 'src/features/wells-list/wells-tabs';
import { WELLS_TYPE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/trash.svg';
import { Button } from 'src/shared/components/button';

import styles from './edit-bar.module.scss';

interface Props {
  pageStore: WellsPageStore;
  makeWellIsPlanned(id: number | string): void;
}

export const EditBar = observer(function EditBar({ pageStore, makeWellIsPlanned }: Props) {
  const { t } = useTranslation('wells');

  const selectedRowsNumber = pageStore.table.selectedRowsNumber;

  function onOpenDeleteWellsModal(): void {
    pageStore.setIsDeleteWellsModalOpened(true);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <WellsTabs className={styles.categorySwitcher} pageStore={pageStore} />

        {pageStore.wellsType === WELLS_TYPE.unplanned && selectedRowsNumber > 0 && (
          <Button
            className={styles.button}
            variant="danger"
            prefixIcon={<TrashIcon />}
            onClick={onOpenDeleteWellsModal}
          >
            {t('common:Buttons.delete')}
          </Button>
        )}
      </div>

      <DeleteWellsModal pageStore={pageStore} />
    </>
  );
});
