import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'src/store';

import styles from './styles.module.scss';

type Props = {
  title: string;
  children?: ReactNode;
};

export const Header: React.FC<Props> = observer(function Header({ title, children }) {
  const { t } = useTranslation('header');
  const { sidebar } = useStore();

  return (
    <header className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <button className={styles.burgerWrapper} aria-label={t('Menu.ariaOpen')} onClick={sidebar.toggle}>
          <div className={styles.burgerStick} />
          <div className={styles.burgerStick} />
          <div className={styles.burgerStick} />
        </button>

        <h2 className={styles.title}>{title}</h2>
      </div>

      {children}
    </header>
  );
});
