import { TextArea } from 'src/shared/entities/control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TTextAreaRaw, TSavedFormValue } from '../types';

type TTextAreaOptions = {
  item: TTextAreaRaw;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapTextArea = ({ item, directories, savedValue }: TTextAreaOptions): TextArea => {
  const fieldProps = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    validate: item.validate,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    validationTags: item.validationTags,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    calculatedValue: item.calculatedValue,
    useInMainProgressBar: !!item?.useInMainProgressBar,
    required: !!item?.required,
    initialValue: typeof savedValue === 'string' ? savedValue : undefined,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    unit: directories.getFieldUnit(item.fieldId) || item?.item || undefined,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    restrictions: {
      required: !!item.required,
    },
  };

  return new TextArea(fieldProps);
};
