import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { EmptyDataHeader } from '../../../components/timeline-chart/components/data/empty-data-header';
import { ComparingChartRig } from '../../../presets/comparing-drilling-rigs-chart/entities';
import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { Viewport } from '../../../shared/viewport/viewport';
import { RigsChartDataModel } from '../../rigs-chart/data/rigs-chart-data-model';

import styles from './data-items.module.scss';

interface Props {
  item: RigsChartDataModel.ViewItem;
  verticalViewport: Viewport;
}

export const ComparingRigsDataBackgroundItem = observer(function ComparingRigsDataBackgroundItem({
  item,
  verticalViewport,
}: Props) {
  if (item instanceof ComparingChartRig) {
    return (
      <EmptyDataHeader
        item={item}
        verticalViewport={verticalViewport}
        className={clsx(styles.row, item.hasChanges && styles.rowComparing)}
      />
    );
  }

  if (item instanceof ChartRig) {
    return <EmptyDataHeader item={item} verticalViewport={verticalViewport} className={styles.row} />;
  }

  if (item instanceof RigsGroup) {
    return <EmptyDataHeader item={item} verticalViewport={verticalViewport} className={styles.rowHeader} />;
  }

  return null;
});
