import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/arrow-drop-icon.svg';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { RigsGroup } from '../../presets/drilling-rigs-chart/entities';
import { useMovableElement } from '../../shared/use-movable-element';
import { Viewport } from '../../shared/viewport/viewport';
import { getOffsetPx, getPositiveOffsetPx } from '../../shared/viewport/viewport-calculator';
import { useSortable } from '../sorting/hooks/use-sortable';
import { SortingContextStore } from '../sorting/sorting-context.store';

import styles from './group-collapse-header.module.scss';

interface Props {
  item: RigsGroup;
  isCollapsed: boolean;
  viewport: Viewport;
  onIsCollapsedToggle: VoidFunction;
  title?: ReactNode;
  withArrow?: boolean;
  className?: string;
}

const draggingOptions: SortingContextStore.DraggingOptions = {
  draggingClassName: styles.sortable,
};

export const DraggingGroupCollapseHeader = observer(function DraggingGroupCollapseHeader({
  item,
  viewport,
  isCollapsed,
  title,
  withArrow = false,
  className,
  onIsCollapsedToggle,
}: Props) {
  const { ref: draggingRef, listeners } = useSortable({
    id: item.getKey('sortable'),
    dataItem: item,
    onClick: onIsCollapsedToggle,
    options: draggingOptions,
  });

  const ref = useMovableElement({
    item,
    verticalViewport: viewport,
    getOffsetY: isCollapsed ? getOffsetPx : getPositiveOffsetPx,
  });

  return (
    <button
      ref={mergeRefs(ref, draggingRef)}
      className={clsx(styles.collapseHeaderWrapper, isCollapsed && styles.collapseHeaderCollapsedWrapper)}
      {...listeners}
    >
      <div className={clsx(className, styles.collapseHeader)}>
        {withArrow && <ArrowIcon className={clsx(styles.arrowIcon, !isCollapsed && styles.arrowIconUp)} />}

        {title}
      </div>
    </button>
  );
});
