import { DefaultOptionType } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';

import { TDictObject } from 'src/api/directories/types';
import { ReactComponent as SuffixIcon } from 'src/shared/assets/icons/suffix-icon.svg';
import { Combobox } from 'src/shared/components/combobox/combobox';
import { tryGetCurrentTitle, getOptionText } from 'src/shared/components/table-cell/combo-box-table-cell/utils';
import { TPlainDictObject } from 'src/store/directories/types';
import { ColumnType } from 'src/store/table/types';

import styles from './combo-box-table-cell.module.scss';

interface Props {
  fieldValue: string | number | null;
  column: ColumnType | Omit<ColumnType, 'width'>;
  object: TDictObject[] | TPlainDictObject[] | null;
  onEditCell: (value: string | number | null) => void;
}

export const ComboBoxTableCell = observer(function ComboBoxTableCell({
  fieldValue,
  onEditCell,
  column,
  object,
}: Props) {
  function handleChange(value: string | number | DefaultOptionType): void {
    if (typeof value === 'string' || typeof value === 'number') {
      onEditCell(value);
    } else {
      onEditCell(value?.value ?? null);
    }
  }

  const options: DefaultOptionType[] = [];
  let archivedValueOption: DefaultOptionType | null = null;

  object?.forEach((item) => {
    if (typeof item.id === 'number' || typeof item.id === 'string') {
      if (item.status === 'ACTIVE') {
        options.push({
          label: getOptionText(column, item),
          value: item.id,
        });
      } else {
        const option = {
          label: getOptionText(column, item),
          value: item.id,
        };

        if (item.id === fieldValue) {
          archivedValueOption = option;
        }
      }
    }
  });

  return (
    <div className={styles.wrapper}>
      <Combobox<string | number | DefaultOptionType>
        onChange={handleChange}
        value={archivedValueOption || tryGetCurrentTitle(fieldValue, column, object)}
        allowClear
        options={options}
        suffixIcon={<SuffixIcon style={{ color: 'black !important' }} />}
      />
    </div>
  );
});
