export function downloadFile(file: File): void {
  const link = document.createElement('a');
  const blobURL = URL.createObjectURL(file);

  link.style.display = 'none';
  link.setAttribute('href', blobURL);
  link.download = `${file.name}.${file.type}`;

  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobURL);
}
