import { observer } from 'mobx-react-lite';

import { DrillingRigConflictsSidebar } from 'src/features/drilling-rig-conflicts-sidebar';
import { ViewPadsSidebar } from 'src/features/drilling-rig-sidebar/view-pads-sidebar';
import { Loader } from 'src/shared/components/loader';
import { useStore } from 'src/store';

import styles from './conflict-resolver.module.scss';

export const ConflictResolver = observer(function ConflictResolver() {
  const {
    drafts: { conflictResolver },
  } = useStore();

  const isLoading = conflictResolver.isConflictsChecking || conflictResolver.isConflictResolving;

  return (
    <>
      <DrillingRigConflictsSidebar />

      <ViewPadsSidebar store={conflictResolver.viewPadsSidebar} />

      {isLoading && (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} />
        </div>
      )}
    </>
  );
});
