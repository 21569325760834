import { MatchedItemType, MatchHeadersType, TTriple } from 'src/pages/import-matching-page/import-matching-page.store';
import { hasValue } from 'src/shared/utils/common';

import { MatchResponseType, TripleType } from './types';

export function mapTripleData(response: TTriple): TripleType {
  return {
    id: response.id,
    planVersionId: response.data.planVersionId,
    rigOperationId: response.data.rigOperationId,
    wellPlacementId: response.data.wellPlacementId,
    geologicalTaskId: response.data.geologicalTaskId,
    $validation: response.data.$validation,
  };
}

export function mapMatchExcelHeaders(response: MatchResponseType): MatchHeadersType {
  return {
    fileId: response.fileId,
    unmatchedAttributes: response.unmatchedFields,
    unmatchedHeaders: response.unmatchedHeaders.map((header) => {
      return {
        columnIndex: header.columnIndex,
        title: header.titles.join('/'),
      };
    }),
    matched: response.matched.map((item) => ({
      name: item.fieldId,
      value: {
        columnIndex: item.columnIndex,
        title: item.titles.join('/'),
      },
    })),
  };
}

export function serializeMatchHeadersData(matchedData: MatchedItemType[]): Record<string, string | number> {
  return matchedData.reduce<Record<string, string | number>>(
    (target, item) => ({
      ...target,
      ...(hasValue(item.value) && hasValue(item.value?.columnIndex) && { [item.name]: item.value?.columnIndex }),
    }),
    {}
  );
}
