import { action, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { IModel, Range } from '../../../layers/model';

import { IHeaderColumn } from './header-data-model.types';
import HeaderDataSource from './header-data-source';

export class HeaderDataModel implements IModel<IHeaderColumn[]> {
  @observable private viewRange: Range<number>;

  @observable data: IHeaderColumn[] | undefined;

  constructor(initialViewRange: Range<number>) {
    this.viewRange = initialViewRange;

    makeObservable(this);
  }

  @action.bound
  setRange(start: number, end: number) {
    this.viewRange = {
      start,
      end,
    };

    this.data = HeaderDataSource.generateColumns(this.viewRange, this.data);
  }

  getData = computedFn(() => {
    return this.data || [];
  });
}
