import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ReactNode } from 'react';

import { FormDatePicker, FormNumberInput } from 'src/shared/components/form-components';

import { DateIntervalField } from '../../../../shared/entities/control-entities/date-interval-field.entity';

import styles from './new-well-date-interval-field.module.scss';

type Props = {
  item: DateIntervalField;
  durationAllowClear?: boolean;
  durationSuffixComponent?: ReactNode;
  isDisabled: boolean;
};

const defaultTime = moment().startOf('date');

export const NewWellIntervalDateField = observer(function NewWellIntervalDateField({
  item,
  durationSuffixComponent,
  durationAllowClear,
  isDisabled,
}: Props) {
  const defaultStartDateTime = item.startDate || item.endDate || item.disabledStartDate || defaultTime;
  const defaultEndDateTime = item.endDate || item.startDate || item.disabledStartDate || defaultTime;

  return (
    <div className={styles.wrapper}>
      <div className={styles.intervalWrapper}>
        <FormDatePicker
          allowClear
          value={item.startDate}
          defaultTime={defaultStartDateTime}
          defaultDate={defaultStartDateTime}
          label={item.labels['startDate']}
          errorText={item.fieldsErrors.startDateError}
          className={styles.input}
          placeholder={item.placeholderMin}
          ghostLabel
          withTime
          disabled={item.isStartDateDisabled || isDisabled}
          disabledDate={item.getDisabledStartDates}
          onChange={item.setStartDate}
          onFocus={item.clearStartDateError}
          onBlur={item.validateStartDate}
        />
        <p className={styles.dash}>—</p>
        <FormDatePicker
          allowClear
          value={item.endDate}
          defaultTime={defaultEndDateTime}
          defaultDate={defaultEndDateTime}
          label={item.labels['endDate']}
          className={styles.input}
          errorText={item.fieldsErrors.endDateError}
          ghostLabel
          withTime
          placeholder={item.placeholderMax}
          disabled={item.isEndDateDisabled || isDisabled}
          disabledDate={item.getDisabledEndDates}
          onChange={item.setEndDate}
          onFocus={item.clearEndDateError}
          onBlur={item.validateEndDate}
        />
      </div>
      <FormNumberInput
        value={item.duration}
        numberOfDecimalPlaces={2}
        allowClear={durationAllowClear}
        placeholder={item.placeholderDuration}
        label={item.labels['duration']}
        unit={item.unit}
        suffixComponent={durationSuffixComponent}
        isDisabled={isDisabled}
        errorText={item.fieldsErrors.durationError}
        onChange={item.setDuration}
        onBlur={item.validateDuration}
        onFocus={item.clearDurationError}
      />
    </div>
  );
});
