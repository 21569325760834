import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';

import { RigsGroup } from './entities';

export function rigGroupsToOrderSettings(rigGroups: RigsGroup[]): ViewSettings.RigsOrderSettingsValues {
  const groupsOrder: ViewSettings.RigsGroup[] = rigGroups.map((group) => ({
    groupId: group.id,
    isCollapsed: group.isCollapsed,
  }));
  const rigsOrder: ViewSettings.Rig[] = [];

  for (const group of rigGroups) {
    for (const rig of group.items) {
      rigsOrder.push({
        groupId: group.id,
        rigId: rig.id,
      });
    }
  }

  return {
    groups: groupsOrder,
    rigs: rigsOrder,
  };
}
