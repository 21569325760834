import { action, makeObservable, observable } from 'mobx';

import { UnplannedRigOperationsSidebar } from 'src/api/unplanned-rig-operations-sidebar';
import { Dnd } from 'src/features/drilling-chart/features/editing/types';
import { Range } from 'src/features/drilling-chart/layers/model';
import { NeighborScheme } from 'src/features/drilling-chart/shared/entities/neighbor-scheme';
import { WithKey } from 'src/features/drilling-chart/types';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';

import { SidebarPadRigOperation } from './pad-rig-operation.entity';

type TSidebarWellOptions = {
  well: Record<string, string | number | boolean>;
  uniqueWellKey: string;
  uniqueCreationKey: string;
  view: UnplannedRigOperationsSidebar.WellsView;
  wellId: number;
  color: string;
  title: string;
  attributes: string[];
  wellTypeIcon?: WellTypeIcon;
  rigOperationIndex: number | null;
};

export class SidebarWellRigOperation
  implements Dnd.Draggable<SidebarWellRigOperation, SidebarPadRigOperation>, WithKey
{
  readonly id: string;
  readonly data: Record<string, string | number | boolean>;
  readonly view: UnplannedRigOperationsSidebar.WellsView;
  readonly title: string;
  readonly rigOperationIndex: number | null;
  readonly color: string;
  readonly wellId: number;
  readonly attributes: string[] = [];
  readonly wellTypeIcon?: WellTypeIcon;
  readonly start: number | null;
  readonly end: number | null;
  readonly x: Range<number> | null;
  private readonly uniqueCreationKey: string;

  parentPad?: SidebarPadRigOperation;
  neighborScheme?: NeighborScheme<SidebarWellRigOperation>;

  @observable y: Range<number> = { start: 0, end: 0 };

  constructor({
    view,
    color,
    title,
    wellId,
    attributes,
    rigOperationIndex,
    uniqueWellKey,
    wellTypeIcon,
    well,
    uniqueCreationKey,
  }: TSidebarWellOptions) {
    this.id = uniqueWellKey;
    this.uniqueCreationKey = uniqueCreationKey;
    this.view = view;
    this.data = well;
    this.color = color;
    this.title = title;
    this.wellId = wellId;
    this.attributes = attributes;
    this.rigOperationIndex = rigOperationIndex;
    this.wellTypeIcon = wellTypeIcon;

    this.start = this.getStart();
    this.end = this.getEnd();
    this.x = this.getX();

    makeObservable(this);
  }

  private getStart(): number | null {
    const startFieldName = addGOplanPrefix('RigOperation.workStartDate');
    const startDate = this.data[startFieldName];

    if (!startDate) {
      return null;
    }

    const startDateNum = Number.parseInt(startDate.toString());

    if (!startDateNum) {
      return null;
    }

    return startDateNum;
  }

  private getEnd(): number | null {
    const endFieldName = addGOplanPrefix('RigOperation.workEndDate');
    const endDate = this.data[endFieldName];

    if (!endDate) {
      return null;
    }

    const endDateNum = Number.parseInt(endDate.toString());

    if (!endDateNum) {
      return null;
    }

    return endDateNum;
  }

  private getX(): Range<number> | null {
    const start = this.start;
    const end = this.end;

    if (!start || !end) {
      return null;
    }

    return {
      start,
      end,
    };
  }

  get length(): number {
    const x = this.getX();

    if (!x) {
      return 0;
    }

    return x.end - x.start;
  }

  setNeighborScheme(scheme: NeighborScheme<SidebarWellRigOperation>): void {
    this.neighborScheme = scheme;
  }

  setParentPad(pad?: SidebarPadRigOperation) {
    this.parentPad = pad;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-sidebar-well-${this.uniqueCreationKey}`;
    }

    return `sidebar-well-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): unknown {
    if (fieldName in this.data) {
      return this.data[fieldName];
    }
  }

  @action.bound
  setY(value: Range<number>): void {
    this.y = value;
  }

  clone(): SidebarWellRigOperation {
    return new SidebarWellRigOperation({
      view: this.view,
      color: this.color,
      title: this.title,
      wellId: this.wellId,
      attributes: this.attributes,
      rigOperationIndex: this.rigOperationIndex,
      uniqueWellKey: this.uniqueCreationKey,
      wellTypeIcon: this.wellTypeIcon,
      well: this.data,
      uniqueCreationKey: this.uniqueCreationKey,
    });
  }

  getShadow(): SidebarPadRigOperation | null {
    if (!this.parentPad) {
      return null;
    }

    return new SidebarPadRigOperation({
      title: this.parentPad.title,
      pad: this.parentPad.pad,
      uniqueCreationKey: this.parentPad.getKey('shadow'),
      wells: [this],
      groupVariant: this.parentPad.groupVariant,
    });
  }
}
