import { ReactComponent as CarpetIcon } from 'src/shared/assets/icons/carpet-nav.svg';
import { ReactComponent as DashboardIcon } from 'src/shared/assets/icons/dashboard-nav.svg';
import { ROUTES } from 'src/shared/constants/routes';

export const navProps = [
  {
    name: 'Nav.carpet',
    to: ROUTES.carpet,
    renderIcon: (className: string) => <CarpetIcon className={className} />,
  },
  {
    name: 'Nav.dashboard',
    to: ROUTES.dashboard,
    renderIcon: (className: string) => <DashboardIcon className={className} />,
  },
];
