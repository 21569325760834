import { observer } from 'mobx-react-lite';
import React from 'react';

import { ResizeParamsType } from 'src/store/table/types';

import styles from './resize-table-line.module.scss';

export interface Props {
  resizeParams: ResizeParamsType;
  action: 'static' | 'dynamic';
}

export const ResizeTableLine = observer(function ResizableTitle({ resizeParams, action }: Props) {
  if (resizeParams.xCoordinates && resizeParams.leftColumnCoordinates && resizeParams.leftOffset) {
    return (
      <span
        className={styles.resizeLine}
        style={{
          display: resizeParams.isResize ? 'block' : 'none',
          left:
            action === 'dynamic'
              ? `${resizeParams.xCoordinates - resizeParams.leftOffset}px`
              : `${resizeParams.leftColumnCoordinates - resizeParams.leftOffset}px`,
        }}
      />
    );
  }

  return null;
});
