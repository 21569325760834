import { Tooltip as AntdTooltip } from 'antd';
import { RenderFunction, TooltipPlacement, TooltipProps } from 'antd/lib/tooltip';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  text: ReactNode | RenderFunction;
  visible?: boolean;
  align?: TooltipProps['align'];
  arrowPointAtCenter?: boolean;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu' | Array<'hover' | 'focus' | 'click' | 'contextMenu'>;
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  placement?: TooltipPlacement;
  destroyTooltipOnHide?: boolean | { keepParent?: boolean };
  className?: string;
  onVisibleChange?(visible: boolean): void;
}

export const Tooltip = observer(function Tooltip({
  width,
  maxWidth,
  minWidth,
  align,
  trigger,
  text,
  placement,
  children,
  visible,
  arrowPointAtCenter,
  className,
  destroyTooltipOnHide,
  onVisibleChange,
}: Props) {
  return (
    <AntdTooltip
      visible={visible}
      onVisibleChange={onVisibleChange}
      trigger={trigger}
      overlayStyle={{ width, maxWidth, minWidth }}
      title={text}
      align={align}
      arrowPointAtCenter={arrowPointAtCenter}
      placement={placement}
      destroyTooltipOnHide={destroyTooltipOnHide}
      className={className}
    >
      {children}
    </AntdTooltip>
  );
});
