import { TDictObject } from 'src/api/directories/types';
import { WellsListViewType, WellType } from 'src/api/wells-list';
import { OBJECT_TYPE } from 'src/api/wells-list/types';
import {
  UserSettingsColumnsGroupType,
  SortingOptionType,
  SortingObjectType,
  FilterType,
  FilterObjectType,
} from 'src/pages/wells-page/types';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { Directories } from 'src/store/directories/directories.store';
import { TRefQuery } from 'src/store/directories/types';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { COLUMN_CONTROL, ColumnType, TableRow, CellValueType } from 'src/store/table/types';

export function getObjectsNames(view: WellsListViewType): string[] {
  return view.tabs.reduce<string[]>((prevTab, currTab) => {
    return prevTab.concat(
      currTab.columns.reduce<string[]>((prevColumn, currColumn) => {
        return prevColumn.concat(
          currColumn.innerColumns.reduce<string[]>((prevChildColumn, currChildColumn) => {
            return currChildColumn.refObjectType
              ? prevChildColumn.concat(currChildColumn.refObjectType)
              : prevChildColumn;
          }, [])
        );
      }, [])
    );
  }, []);
}

export function getJoins(view: WellsListViewType): TRefQuery[] {
  return view.tabs.reduce<TRefQuery[]>((prevTab, currTab) => {
    return prevTab.concat(
      currTab.columns.reduce<TRefQuery[]>((prevColumn, currColumn) => {
        return prevColumn.concat(
          currColumn.innerColumns.reduce<TRefQuery[]>((prevChildColumn, currChildColumn) => {
            return currChildColumn.refQuery ? prevChildColumn.concat(currChildColumn.refQuery) : prevChildColumn;
          }, [])
        );
      }, [])
    );
  }, []);
}

export function mapColumnsData(
  tabFieldId: string | null,
  view: WellsListViewType | null,
  usersSettings: UserSettingsColumnsGroupType[],
  directories: Directories
): ColumnType[] {
  const currentTabView = view ? view.tabs.find((tab) => tab.fieldId === tabFieldId) : null;

  if (currentTabView) {
    return currentTabView.columns
      .sort((a, b) => {
        const firstGroup = usersSettings.findIndex((item) => a.fieldId === item.id);
        const secondGroup = usersSettings.findIndex((item) => b.fieldId === item.id);

        if (firstGroup === -1) return 1;

        if (secondGroup === -1) return secondGroup;

        return firstGroup - secondGroup;
      })

      .reduce<ColumnType[]>((prevColumnGroup, currColumnGroup) => {
        const currentSettingsColumn = usersSettings.find((item) => item.id === currColumnGroup.fieldId);

        const sortedChildColumns = currentSettingsColumn
          ? currColumnGroup.innerColumns.sort((a, b) => {
              const firstColumnIndex = currentSettingsColumn?.innerColumns.findIndex((item) => a.fieldId === item.id);
              const secondColumnIndex = currentSettingsColumn?.innerColumns.findIndex((item) => b.fieldId === item.id);

              if (firstColumnIndex === -1) {
                return 1;
              }

              if (secondColumnIndex === -1) {
                return secondColumnIndex;
              }

              return firstColumnIndex - secondColumnIndex;
            })
          : currColumnGroup.innerColumns;

        const columnsGroupLabel = directories.getFieldLabel(currColumnGroup.fieldId) ?? currColumnGroup.fieldId;

        if (currColumnGroup.wrapped) {
          return prevColumnGroup.concat({
            id: currColumnGroup.fieldId,
            name: currColumnGroup.fieldId,
            label: columnsGroupLabel,
            columnsGroupId: currColumnGroup.fieldId,
            columnsGroupLabel,
            // TODO Убрать хардкод
            control: COLUMN_CONTROL.Field,
            width: currentSettingsColumn?.width
              ? currentSettingsColumn.width
              : currColumnGroup.innerColumns.length * 200,
            isShown: currentSettingsColumn?.isShown !== undefined ? currentSettingsColumn.isShown : true,
            fixed: currentSettingsColumn?.isFixed !== undefined ? currentSettingsColumn.isFixed : false,
            children: sortedChildColumns.map((childColumn) => {
              const currentSettingsChildColumn = currentSettingsColumn?.innerColumns.find(
                (item) => item.id === childColumn.fieldId
              );

              return {
                id: childColumn.fieldId,
                numberOfDecimalPlaces: childColumn.numberOfDecimalPlaces,
                name: childColumn.attrName,
                attrName: childColumn.attrName,
                label: childColumn.label ?? directories.getFieldLabel(childColumn.fieldId) ?? childColumn.fieldId,
                columnsGroupId: currColumnGroup.fieldId,
                control: childColumn.control,
                width: currentSettingsChildColumn?.width ? currentSettingsChildColumn.width : 200,
                isShown: currentSettingsChildColumn?.isShown !== undefined ? currentSettingsChildColumn.isShown : true,
                fixed: currentSettingsChildColumn?.isFixed !== undefined ? currentSettingsChildColumn?.isFixed : false,
                objectType: childColumn.refObjectType,
                refQuery: childColumn.refQuery,
                objectField: childColumn.refObjectAttr,
                isChangable: childColumn.isChangable,
                excludeFromSettings: !!childColumn.excludeFromSettings,
                isMassEditable: childColumn.isMassEditable,
                enableIf: childColumn.enableIf,
                restrictionsType: childColumn.restrictions,
                type: childColumn.type,
                columnsGroupLabel,
              };
            }),
          });
        }

        return prevColumnGroup.concat(
          sortedChildColumns.map((childColumn) => {
            const currentSettingsChildColumn = currentSettingsColumn?.innerColumns.find(
              (item) => item.id === childColumn.fieldId
            );
            return {
              id: childColumn.fieldId,
              name: childColumn.attrName,
              attrName: childColumn.attrName,
              numberOfDecimalPlaces: childColumn.numberOfDecimalPlaces,
              label: childColumn.label ?? directories.getFieldLabel(childColumn.fieldId) ?? childColumn.fieldId,
              columnsGroupId: currColumnGroup.fieldId,
              control: childColumn.control,
              width: currentSettingsChildColumn?.width !== undefined ? currentSettingsChildColumn.width : 200,
              isShown: currentSettingsChildColumn?.isShown !== undefined ? currentSettingsChildColumn.isShown : true,
              fixed: currentSettingsChildColumn?.isFixed !== undefined ? currentSettingsChildColumn.isFixed : false,
              refQuery: childColumn.refQuery,
              objectType: childColumn.refObjectType,
              objectField: childColumn.refObjectAttr,
              isChangable: childColumn.isChangable,
              isMassEditable: childColumn.isMassEditable,
              excludeFromSettings: !!childColumn.excludeFromSettings,
              enableIf: childColumn.enableIf,
              restrictions: childColumn.restrictions,
              type: childColumn.type,
              columnsGroupLabel,
            };
          })
        );
      }, []);
  }
  return [];
}

export function mapTabsData(
  view: WellsListViewType,
  directories: Directories,
  usersSettings: Record<string, UserSettingsColumnsGroupType[]>
): Record<string, ColumnType[]> {
  return view.tabs.reduce<Record<string, ColumnType[]>>((target, tab) => {
    const tabSettings = hasValue(usersSettings[tab.fieldId]) ? usersSettings[tab.fieldId] : [];

    target[tab.fieldId] = mapColumnsData(tab.fieldId, view, tabSettings, directories);

    return target;
  }, {});
}

export function serializeUserSettingsData(columnsData: ColumnType[]): UserSettingsColumnsGroupType[] {
  const userSettings: UserSettingsColumnsGroupType[] = [];

  columnsData.forEach((column) => {
    const currentColumnIndex = userSettings.findIndex((settingsColumn) => settingsColumn.id === column.columnsGroupId);

    if (currentColumnIndex === -1 && column.columnsGroupId) {
      userSettings.push({
        id: column.columnsGroupId,
        isShown: column.isShown,
        isWrapped: hasValue(column.children),
        ...(column.children && { isFixed: column.fixed, width: column.width }),
        innerColumns: column.children
          ? column.children?.map((childColumn) => ({
              id: childColumn.id,
              isFixed: childColumn.fixed,
              isShown: childColumn.isShown,
            }))
          : [{ id: column.id, isFixed: column.fixed, isShown: column.isShown, width: column.width }],
      });
    }

    if (currentColumnIndex !== -1 && column.columnsGroupId) {
      userSettings[currentColumnIndex].innerColumns.push({
        id: column.id,
        isFixed: column.fixed,
        isShown: column.isShown,
        width: column.width,
      });
    }
  });

  return userSettings;
}

export function serializeSortingObject(
  view: WellsListViewType | null,
  sorting: SortingOptionType | null
): SortingObjectType | null {
  if (view) {
    for (const tab of view.tabs) {
      for (const column of tab.columns) {
        for (const childColumn of column.innerColumns) {
          if (childColumn.fieldId === sorting?.fieldId && childColumn.attrName === sorting.attrName) {
            return {
              direction: sorting.direction,
              attrName: childColumn.attrName,
              ...(childColumn.refObjectType && { refObjectType: childColumn.refObjectType }),
              ...(childColumn.refObjectAttr && { refObjectAttr: childColumn.refObjectAttr }),
              ...(childColumn.refQuery && { refQuery: childColumn.refQuery }),
              ...(childColumn.cast && { cast: childColumn.cast }),
            };
          }
        }
      }
    }
  }

  return null;
}

export function serializeFiltersObject(
  view: WellsListViewType | null,
  currentFieldId: string | null,
  filters: FilterType[]
): FilterObjectType[] | null {
  if (!view || !currentFieldId) {
    return null;
  }

  const serializedFilters: FilterObjectType[] = [];

  for (const tab of view.tabs) {
    if (tab.fieldId === currentFieldId) {
      for (const column of tab.columns) {
        for (const childColumn of column.innerColumns) {
          const filter = filters.find(
            (filter) => filter.fieldId === childColumn.fieldId && filter.attrName === childColumn.attrName
          );

          if (filter) {
            serializedFilters.push({
              attrName: childColumn.attrName,
              ...(filter.valueArray && { valueArray: filter.valueArray }),
              ...(filter.valueMin && { valueMin: filter.valueMin }),
              ...(filter.valueMax && { valueMax: filter.valueMax }),
              ...(childColumn.refObjectType && { refObjectType: childColumn.refObjectType }),
              ...(childColumn.refObjectAttr && { refObjectAttr: childColumn.refObjectAttr }),
              ...(childColumn.refQuery && { refQuery: childColumn.refQuery }),
            });
          }
        }
      }

      break;
    }

    continue;
  }

  return serializedFilters.length > 0 ? serializedFilters : null;
}

export function getObjectIdKey(attrName: string): string {
  const objectName = attrName.split('.')[0];
  assert(hasValue(objectName), 'Invalid attrName');

  return `${objectName}.id`;
}

export function getUseInMainProgressBarAttrNames(view: WellsListViewType): string[] {
  const attrNames = [];

  for (const tab of view.tabs) {
    for (const column of tab.columns) {
      for (const childColumn of column.innerColumns) {
        if (childColumn.useInMainProgressBar) {
          attrNames.push(childColumn.attrName);
        }
      }
    }
  }

  return attrNames;
}

export function getRemovableObjectsIdList(selectedRows: TableRow[], objectType: OBJECT_TYPE): number[] {
  // Hardcode-attr
  const objectIdKey = objectType === OBJECT_TYPE.rigOperation ? 'GOplan_RigOperation.id' : 'GOplan_GeologicalTask.id';
  const objectIdList: number[] = [];

  for (const row of selectedRows) {
    const currentIdField = row[objectIdKey];

    if (isObjectWithKeys(currentIdField) && 'value' in currentIdField) {
      const objectId = currentIdField.value;

      if (typeof objectId === 'number') {
        objectIdList.push(objectId);
      }
    }
  }

  return objectIdList;
}

export function showEditNotification(
  notifications: NotificationsStore,
  selectedRowsNumber: number,
  changesRowsNumber: number
): void {
  if (selectedRowsNumber) {
    if (selectedRowsNumber === changesRowsNumber) {
      notifications.showSuccessMessageT('notifications:successEditAllRows', {
        successEditRowsNumber: changesRowsNumber,
      });
    }

    if (selectedRowsNumber !== changesRowsNumber) {
      notifications.showWarningMessageT('notifications:successEditSeveralRows', {
        successEditRowsNumber: changesRowsNumber,
        forbiddenEditRowsNumber: selectedRowsNumber - changesRowsNumber,
      });
    }
  }
}

export function getWellsChanges(
  row: TableRow,
  newValue: CellValueType,
  column: ColumnType | Omit<ColumnType, 'width'>,
  directories: Directories
): WellType | null {
  if (column.enableIf) {
    for (const rule of column.enableIf) {
      if (row[rule.attr]?.toString() === rule.value.toString()) {
        return null;
      }
    }
  }

  const modifiedFormulasChanges = getFormulasChanges(newValue, column, directories);

  if (!modifiedFormulasChanges) {
    return null;
  }

  const objectIdKeys = new Set(Object.keys(modifiedFormulasChanges).map((attrName) => getObjectIdKey(attrName)));

  const objectIds = [...objectIdKeys].reduce<Record<string, number>>((acc, idKey) => {
    const objectIdCell = row[idKey];

    if (isObjectWithKeys(objectIdCell) && 'value' in objectIdCell && typeof objectIdCell.value === 'number') {
      acc[idKey] = objectIdCell.value;
    }

    return acc;
  }, {});

  return { ...objectIds, ...modifiedFormulasChanges };
}

export function getFormulasChanges(
  newValue: CellValueType,
  column: ColumnType | Omit<ColumnType, 'width'>,
  directories: Directories
): WellType | null {
  if (!column.attrName) {
    return null;
  }

  // Hardcode-attr
  if (column.id === 'coreSamplingInterval') {
    const extractionVolume = 'GOplan_GeologicalTask.extractionVolume';
    const coreSamplingDayAmount = 'GOplan_GeologicalTask.coreSamplingDayAmount';

    const refObjectType = column.objectType;

    if (Array.isArray(newValue)) {
      const kernIntervalsDirectoryValues: TDictObject[] = [];

      newValue.forEach((kernIntValue) => {
        const kernIntervalDirectoryValue = directories
          .getObject(refObjectType)
          ?.find((directoryValue) => directoryValue.id === kernIntValue);

        if (kernIntervalDirectoryValue) {
          kernIntervalsDirectoryValues.push(kernIntervalDirectoryValue);
        }
      });

      const extractionVolumeSum = kernIntervalsDirectoryValues.reduce((acc, currValue) => {
        const value = currValue.data['extractionVolume'];

        if (typeof value === 'number') {
          return acc + value;
        }

        return acc;
      }, 0);

      const coreSamplingDayAmountSum = kernIntervalsDirectoryValues.reduce((acc, currValue) => {
        const value = currValue.data['coreSamplingDayAmount'];

        if (typeof value === 'number') {
          return acc + value;
        }

        return acc;
      }, 0);

      return {
        [column.attrName]: newValue,
        [extractionVolume]: extractionVolumeSum,
        [coreSamplingDayAmount]: coreSamplingDayAmountSum,
      };
    }
  }

  // Hardcode-attr
  if (column.id === 'testingFormationWorkAmount') {
    const testingFormationWorkDayAmount = 'GOplan_GeologicalTask.testingFormationWorkDayAmount';
    const kernConst = directories.getObject('Common_GeologicalLoad')?.[0];

    if (hasValue(newValue) && kernConst) {
      const { dayAmountPerIptRun } = kernConst.data;

      // Приведение типов уберу, когда будем делать валидацию в компоненте ячейки
      if (typeof dayAmountPerIptRun === 'number' && !Number.isNaN(Number(newValue))) {
        const iptDayAmount = Number(newValue) * dayAmountPerIptRun;

        return {
          [column.attrName]: newValue,
          [testingFormationWorkDayAmount]: iptDayAmount,
        };
      }
    }
  }

  return {
    [column.attrName]: newValue,
  };
}
