import { observer } from 'mobx-react-lite';

import { ChartWell, WellsGroup } from '../../../presets/drilling-wells-chart/entities';
import { Viewport } from '../../../shared/viewport/viewport';
import { ComparingWellsData } from '../../comparing-wells-chart/data';

import { ComparingWellsDataBackgroundItem } from './comparing-wells-data-background-item';

interface Props {
  data: ComparingWellsData.ViewItem[];
  verticalViewport: Viewport;
}

export const ComparingWellsDataItemsBackground = observer(function ComparingWellsDataItemsBackground({
  data,
  verticalViewport,
}: Props) {
  return (
    <>
      {data.map((item) => {
        if (item instanceof WellsGroup || item instanceof ChartWell) {
          return (
            <ComparingWellsDataBackgroundItem key={item.getKey()} item={item} verticalViewport={verticalViewport} />
          );
        }

        return null;
      })}
    </>
  );
});
