import { dateKeyToRange } from 'src/features/drilling-chart/shared/date-key-to-range';
import { TrendType } from 'src/shared/constants/trend-type';
import { hasValue } from 'src/shared/utils/common';

import { Range } from '../../features/drilling-chart/layers/model';
import { IndicatorColumn } from '../../features/drilling-chart/presets/indicators-view-settings-sidebar/entities';
import { generateKeyFromRange, StorageKeyManager } from '../../features/drilling-chart/shared/storage-key-manager';
import { TimeRangeHelper } from '../../features/drilling-chart/shared/time-range-helper';
import { TimeUnit } from '../../features/drilling-chart/shared/time-unit';

import { Indicators } from './indicators-api';

export class IndicatorsDataAdapter {
  roundValue(value: number): number {
    return Math.floor(value * 10000) / 10000;
  }

  initializeIndicators(
    rawItems: Indicators.RawIndicators,
    timeRange: Range<number>,
    timeUnit: TimeUnit,
    indicatorNames: TrendType[],
    planVersionId: number
  ): { columns: IndicatorColumn[]; total: IndicatorColumn } {
    const rangeKeyManager = new StorageKeyManager(generateKeyFromRange);
    const timeRangeColumns = TimeRangeHelper.getIntermediateDates(timeRange, timeUnit);
    /*
     * Format:
     *
     * Map<'1679990008-1679990008', {
     *   start: 1679990008,
     *   end: 1679990008,
     *   PASSING: 1220.0,
     *   COMMERCIAL_SPEED: 1220.0,
     *   DRILLING_COMPLETE_WELLS_COUNT: 1220.0,
     *   DEVELOPMENT_COMPLETE_WELLS_COUNT: 1220.0,
     * }>
     * */
    const columns: Map<string, Partial<Record<TrendType, number>> & Range<number>> = new Map(
      timeRangeColumns.map((range) => [rangeKeyManager.getKey(range), range])
    );
    const total: Partial<Record<TrendType, number>> & Range<number> = {
      start: timeRange.start,
      end: timeRange.end,
    };

    for (const indicatorName of indicatorNames) {
      const indicator = rawItems[indicatorName];

      if (indicator) {
        total[indicatorName] = indicator.total[planVersionId];

        for (const dateKey in indicator.points) {
          const value = indicator.points[dateKey]?.[planVersionId];
          // Key like "2022-1" --> "1640995200-1643673599"
          const rangeFromDateKey = dateKeyToRange(dateKey, timeUnit);

          if (rangeFromDateKey) {
            const rangeKey = rangeKeyManager.getKey(rangeFromDateKey);
            const column = columns.get(rangeKey);

            if (column) {
              column[indicatorName] = this.roundValue(value);
            }
          }
        }
      }
    }

    for (const [, column] of columns) {
      for (const indicatorName of indicatorNames) {
        if (!hasValue(column[indicatorName])) {
          column[indicatorName] = 0;
        }
      }
    }

    const initializedColumns: IndicatorColumn[] = [];

    for (const columnData of columns.values()) {
      initializedColumns.push(new IndicatorColumn(columnData));
    }

    return { columns: initializedColumns, total: new IndicatorColumn(total) };
  }
}

export default IndicatorsDataAdapter;
