import { observer } from 'mobx-react-lite';

import { ReactComponent as ShowIcon } from 'src/shared/assets/icons/eye.svg';

import styles from './label-button.module.scss';

type ButtonProps = {
  isEnabled: boolean;
  onClick(): void;
  color: string;
  label: string;
};
export const LabelButton = observer(function LabelButton({ isEnabled, onClick, color, label }: ButtonProps) {
  return (
    <button onClick={onClick} className={styles.labelButton} style={{ opacity: isEnabled ? 1 : 0.3 }}>
      <div className={styles.labelIcon} style={{ backgroundColor: color }}>
        <ShowIcon color="#fff" />
      </div>
      <div className={styles.labelText}>{label}</div>
    </button>
  );
});
