import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { DrillingElementCard } from 'src/features/drilling-element-card';
import { ReactComponent as DrillingRigIcon } from 'src/shared/assets/icons/drilling-rigs-icons/drilling-rig-icon.svg';
import { LoadingTitle } from 'src/shared/components/loading-title/loading-title';
import { Skeleton } from 'src/shared/components/skeleton';
import { IndexField } from 'src/shared/utils/index-field';
import { LoadingField } from 'src/shared/utils/loading-field';
import { mergeRefs } from 'src/shared/utils/merge-refs';

import { ChartRig } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { Viewport } from '../../../shared/viewport/viewport';
import { useDragging } from '../../sorting/hooks/use-dragging';
import { useSortable } from '../../sorting/hooks/use-sortable';
import { SortingContextStore } from '../../sorting/sorting-context.store';

import styles from './data-headers.module.scss';

interface Props {
  item: ChartRig;
  verticalViewport: Viewport;
  isCompactView: boolean;
  viewSettingsProvider: RigsViewSettingsProvider;
  onClick?(id: number): void;
}

const draggingOptions: SortingContextStore.DraggingOptions = {
  draggingClassName: styles.sortable,
};

export const Rig = observer(function Rig({
  item,
  isCompactView,
  verticalViewport,
  viewSettingsProvider,
  onClick,
}: Props) {
  const { listeners, ref: sortableRef } = useSortable({
    id: item.getKey('sortable'),
    dataItem: item,
    options: draggingOptions,
    onClick: () => onClick?.(item.id),
  });

  const { ref: draggingRef } = useDragging({
    id: item.getKey('dragging'),
    item,
    options: draggingOptions,
  });

  const ref = useMovableElement({
    item,
    verticalViewport,
  });

  const descriptionAttributes = viewSettingsProvider.getAttributes(item);

  const getDescription = (): ReactNode => {
    if (!descriptionAttributes) {
      return null;
    }

    if (descriptionAttributes instanceof LoadingField) {
      return (
        <div className={styles.descriptionContainer}>
          <Skeleton rowsNumber={2} />
        </div>
      );
    }

    const nodes: string[] = descriptionAttributes.map((attribute) => {
      if (attribute instanceof IndexField) {
        return item.index !== undefined ? (item.index + 1).toString() : '-';
      }

      return attribute;
    });

    const descriptionString = nodes.join(', ');

    return <p title={descriptionString}>{descriptionString}</p>;
  };

  return (
    <div
      ref={mergeRefs(ref, sortableRef, draggingRef)}
      className={clsx(styles.rowHeader, !!onClick && styles.rowHeaderInteractive)}
      {...listeners}
    >
      <DrillingElementCard
        title={<LoadingTitle field={viewSettingsProvider.getTitle(item)} />}
        description={isCompactView ? undefined : getDescription()}
        icon={<DrillingRigIcon className={styles.rigIcon} />}
      />
    </div>
  );
});
