import { action, makeObservable, observable, ObservableSet } from 'mobx';

import { Dnd } from '../editing/types';

import { SortableTransform } from './sortable-transform';
import { SortingContextStore } from './sorting-context.store';

export class SortableActive<TSortableItem, TRelatedItem = unknown> {
  private readonly className?: string;

  readonly id: string;
  readonly element: HTMLElement;
  readonly dataItem: Dnd.Sortable<TSortableItem>;
  readonly transform: SortableTransform;
  readonly initialTransformCoordinates: Dnd.YTransform;

  @observable relatedItems: ObservableSet<SortingContextStore.DraggingEntry<TRelatedItem>> = observable.set();

  constructor(
    id: string,
    element: HTMLElement,
    dataItem: Dnd.Sortable<TSortableItem>,
    initialTransform?: Dnd.YTransform,
    relatedItems?: SortingContextStore.DraggingEntry<TRelatedItem>[],
    className?: string
  ) {
    this.id = id;
    this.element = element;
    this.dataItem = dataItem;
    this.className = className;

    this.initialTransformCoordinates = initialTransform || { y: 0 };
    this.transform = new SortableTransform(this.initialTransformCoordinates.y);

    relatedItems?.forEach((item) => this.relatedItems.add(item));

    makeObservable(this);
  }

  private setDraggingClassName(item: SortingContextStore.DraggingEntry<TRelatedItem>): void {
    const draggingClassName = item.options?.draggingClassName ?? this.className;

    if (!draggingClassName) {
      return;
    }

    item.element.current?.classList.add(draggingClassName);
  }

  private removeDraggingClassName(item: SortingContextStore.DraggingEntry<TRelatedItem>): void {
    const draggingClassName = item.options?.draggingClassName ?? this.className;

    if (!draggingClassName) {
      return;
    }

    item.element.current?.classList.remove(draggingClassName);
  }

  @action.bound
  addRelatedItem(item: SortingContextStore.DraggingEntry<TRelatedItem>): void {
    this.setDraggingClassName(item);
    this.relatedItems?.add(item);
  }

  @action.bound
  removeRelatedItem(item: SortingContextStore.DraggingEntry<TRelatedItem>): void {
    this.removeDraggingClassName(item);
    this.relatedItems?.delete(item);
  }

  addActiveClassName = (): void => {
    const { className } = this;

    if (!className) {
      return;
    }

    this.element.classList.add(className);
    this.relatedItems?.forEach(this.setDraggingClassName.bind(this));
  };

  removeActiveClassName = (): void => {
    const { className } = this;

    if (!className) {
      return;
    }

    this.element.classList.remove(className);
    this.relatedItems?.forEach(this.removeDraggingClassName.bind(this));
  };
}
