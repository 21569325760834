import { observer } from 'mobx-react-lite';

import { LoadingRigOperations, PadRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { Viewport } from '../../../shared/viewport/viewport';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import { RigsDataItemCompact } from './rigs-data-item-compact';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  viewSettingsProvider: RigsViewSettingsProvider;
  onPadClick?(padId: number, rigId: number): void;
}

export const RigsDataItemsCompact = observer(function RigsDataItemsCompact({
  data,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  viewSettingsProvider,
}: Props) {
  return (
    <>
      {data?.map((item) => {
        if (
          item instanceof PadRigOperation ||
          item instanceof LoadingRigOperations ||
          item instanceof SortableGhostItem
        ) {
          return (
            <RigsDataItemCompact
              key={item.getKey('compact')}
              item={item}
              horizontalViewport={horizontalViewport}
              verticalViewport={verticalViewport}
              viewSettingsProvider={viewSettingsProvider}
              onPadClick={onPadClick}
            />
          );
        }

        return null;
      })}
    </>
  );
});
