import { ApproachesTab } from 'src/features/well-form/entities/approach-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TSavedFormItems, TApproachesTabRaw, TSerializedApproach } from '../../types';

export const mapApproachesList = (
  tab: TApproachesTabRaw,
  directories: Directories,
  savedItems?: TSavedFormItems | TSerializedApproach[]
): ApproachesTab => {
  //TODO: улучшить поиск референса подхода после добавления архивных подходов

  return new ApproachesTab({
    formElementRefId: tab.fieldId,
    approachTemplate: tab.approachesList,
    savedApproaches: Array.isArray(savedItems) ? savedItems : undefined,
    directories,
  });
};
