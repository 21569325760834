import moment from 'moment';

import { ILabelResolver } from './types';

export class MonthResolver implements ILabelResolver {
  tryGetLabelByKey(key: string) {
    const dateString = moment(key).format('MM.YY');

    return dateString;
  }
}
