import { observer } from 'mobx-react-lite';

import { ChartRig, RigsGroup } from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { Viewport } from '../../../shared/viewport/viewport';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';

import { RigsDataBackgroundItem } from './rigs-data-background-item';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  verticalViewport: Viewport;
}

export const RigsDataItemsBackground = observer(function RigsDataItemsBackground({ data, verticalViewport }: Props) {
  return (
    <>
      {data?.map((item) => {
        if (item instanceof RigsGroup || item instanceof ChartRig || item instanceof SortableGhostItem) {
          return (
            <RigsDataBackgroundItem key={item.getKey('background')} item={item} verticalViewport={verticalViewport} />
          );
        }

        return null;
      })}
    </>
  );
});
