import { action, computed, makeObservable } from 'mobx';
import { PointerEvent } from 'react';

import { SelectAbilityManager } from 'src/shared/hooks/use-disable-safari-touch-selection-fix';

import { Dnd } from '../editing/types';

import { SortableTransform } from './sortable-transform';
import { SortingContextStore } from './sorting-context.store';

const HOLD_MOVE_DISTANCE_SQUARED = Math.pow(15, 2);

export class SortableStore<TSortableItem, TDraggingItem> {
  private readonly selectAbilityManager = new SelectAbilityManager();
  private readonly dndStore: SortingContextStore<TSortableItem, TDraggingItem>;
  private readonly id: string;
  private readonly dataItem: Dnd.Sortable<TSortableItem>;
  private readonly options?: SortingContextStore.DraggingOptions;
  private readonly handleClick?: VoidFunction;
  private readonly handleMove?: VoidFunction;

  private interactionState: SortableStore.InteractionState | null = null;

  constructor(
    { id, dataItem, options, onClick, onMove }: SortableStore.Args<Dnd.Sortable<TSortableItem>>,
    dndStore: SortingContextStore<TSortableItem, TDraggingItem>
  ) {
    this.dndStore = dndStore;
    this.id = id;
    this.dataItem = dataItem;
    this.options = options;
    this.handleClick = onClick;
    this.handleMove = onMove;

    makeObservable(this);
  }

  @computed
  get transform(): SortableTransform | undefined {
    if (this.dndStore.active && this.dndStore.active?.id === this.id) {
      return this.dndStore.active.transform;
    }

    return undefined;
  }

  @computed
  get isDragging(): boolean {
    return !!this.dndStore.active && this.dndStore.active?.id === this.id;
  }

  cancelDragging = (): void => {
    if (this.interactionState) {
      const { isMoving } = this.interactionState;

      if (isMoving && this.dndStore.active) {
        this.dndStore.active.transform.setValue({ y: 0 });

        this.handleMove?.();
      }
    }

    this.dndStore.removeActive();
    this.selectAbilityManager.returnSelectAbility();

    this.interactionState = null;
  };

  @action.bound
  onPointerDown(e: PointerEvent<HTMLElement>) {
    if (!e.isPrimary) {
      return;
    }

    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);

    this.interactionState = {
      initialClientX: e.clientX,
      initialClientY: e.clientY,
      isMoving: false,
    };
  }

  @action.bound
  onPointerMove(e: PointerEvent<HTMLElement>): void {
    if (!e.isPrimary) {
      return;
    }

    e.stopPropagation();

    if (!this.interactionState) {
      return;
    }

    this.selectAbilityManager.disableSelectAbility();

    const { isMoving, initialClientX, initialClientY } = this.interactionState;

    if (!isMoving) {
      const distance = Math.pow(e.clientX - initialClientX, 2) + Math.pow(e.clientY - initialClientY, 2);

      if (Math.abs(distance) >= HOLD_MOVE_DISTANCE_SQUARED) {
        this.interactionState.isMoving = true;

        if (this.dndStore.getIsSortable(this.dataItem)) {
          this.dndStore.setActive(this.id, e.currentTarget, this.dataItem, this.options)?.addActiveClassName();

          this.handleMove?.();
          return;
        }
      }
    }

    if (this.dndStore.active?.id !== this.id) {
      return;
    }

    if (isMoving) {
      const { prevClientY } = this.interactionState;

      const offsetY = e.clientY - (prevClientY ?? initialClientY);

      const { transform } = this.dndStore.active;

      transform.add({ y: offsetY });

      this.handleMove?.();

      this.interactionState.prevClientY = e.clientY;

      this.dndStore.onDragMove(e.clientY);
    }
  }

  @action.bound
  onPointerCancel() {
    this.cancelDragging();
  }

  @action.bound
  onPointerUp(e: PointerEvent<HTMLElement>) {
    if (!e.isPrimary || !e.currentTarget) {
      return;
    }

    e.stopPropagation();

    const clearActive = this.dndStore.removeActive.bind(this.dndStore, (active) => active.removeActiveClassName());

    if (this.interactionState) {
      const { isMoving } = this.interactionState;

      if (!isMoving) {
        this.handleClick?.();
      }

      clearActive();
    }

    this.selectAbilityManager.returnSelectAbility();

    this.interactionState = null;
  }
}

namespace SortableStore {
  export type InteractionState = {
    isMoving: boolean;
    initialClientX: number;
    initialClientY: number;
    prevClientY?: number;
  };

  export interface Args<TSortableItem> {
    id: string;
    dataItem: TSortableItem;
    options?: SortingContextStore.DraggingOptions;
    onClick?: VoidFunction;
    onMove?: VoidFunction;
  }
}
