import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DownloadIcon } from 'src/shared/assets/icons/download-arrow.svg';
import longArrowImg from 'src/shared/assets/img/longArrowEmptyList.svg';
import { ReactComponent as UploadImg } from 'src/shared/assets/img/uploadData.svg';

import styles from './empty-list.module.scss';
import './upload-img-styles.scss';

export const EmptyList = observer(function EmptyList() {
  const { t } = useTranslation('drillingCarpet');

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.text}>
            {t('EmptyList.first')}
            <DownloadIcon className={styles.icon} />
            {t('EmptyList.second')}
          </p>
        </div>
        <UploadImg className={styles.uploadImg} />
        <img draggable={false} className={styles.arrow} src={longArrowImg} alt="arrow" />
      </div>
    </div>
  );
});
