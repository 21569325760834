import { ComparingWellsData } from '../../features/drilling-chart/features/comparing-wells-chart/data';
import {
  WellRigOperation,
  WellRigOperationsCreator,
} from '../../features/drilling-chart/presets/drilling-wells-chart/entities';

import { ComparingWellsChart } from './comparing-wells-data-api';

export class ComparingWellsDataAdapter {
  initializeRigOperationStages(
    rawWells: ComparingWellsChart.RawWellWithComparingItems[],
    firstPlanVersionId: number,
    secondPlanVersionId: number,
    wellIds: number[]
  ): Map<number, ComparingWellsData.TwoVersionsData<WellRigOperation[]>> {
    // Well Map: { wellId: { first: RigOperation[], second: ComparingRigOperation[] } }
    const initializedWells = new Map<number, ComparingWellsData.TwoVersionsData<WellRigOperation[]>>();
    const emptyDataWellIds = new Set(wellIds);

    for (const rawWell of rawWells) {
      const { id, first, second } = rawWell;

      const firstInitializedRigOperations = first.map((rawRigOperation) =>
        WellRigOperationsCreator.create(id, rawRigOperation, firstPlanVersionId)
      );

      const secondInitializedRigOperations = second?.map((rawRigOperation) =>
        WellRigOperationsCreator.createComparing(id, rawRigOperation, secondPlanVersionId)
      );

      initializedWells.set(id, { first: firstInitializedRigOperations, second: secondInitializedRigOperations });
      emptyDataWellIds.delete(id);
    }

    for (const emptyDataWellId of emptyDataWellIds) {
      initializedWells.set(emptyDataWellId, { first: [], second: [] });
    }

    return initializedWells;
  }
}
