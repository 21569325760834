import { Input } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { ReactComponent as Checkmark } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as Cross } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { ReactComponent as Pencil } from 'src/shared/assets/icons/pencil.svg';
import { Button } from 'src/shared/components/button';
import { TPlanVersion } from 'src/store/comparison/types';

import styles from './versions-list-item.module.scss';

interface Props {
  version: TPlanVersion;
  isRenamingAvailable: boolean;
  onVersionChange(planVersion: TPlanVersion): void;
  onRename(id: number, newName: string): Promise<void>;
  onDeleteVersion?(planVersion: TPlanVersion): void;
}

export const VersionsListItem = observer(function VersionsListItem({
  version,
  isRenamingAvailable,
  onVersionChange,
  onRename,
  onDeleteVersion,
}: Props) {
  const [isRenaming, setIsRenaming] = useState(false);
  const [versionName, setVersionName] = useState<string>(version.data.name);

  const handleCancelRenaming = () => {
    setVersionName(version.data.name);
    setIsRenaming(false);
  };

  const handleApplyRenaming = () => {
    if (versionName !== version.data.name) {
      onRename(version.id, versionName);
    }

    setIsRenaming(false);
  };

  useEffect(() => {
    setVersionName((name) => {
      if (name !== version.data.name) {
        return version.data.name;
      }

      return name;
    });
  }, [version, version.data.name]);

  return (
    <li className={clsx(styles.listItem, !onDeleteVersion && styles.listItem_undeletable)}>
      {!!onDeleteVersion && (
        <button className={styles.listItemButton} onClick={() => onDeleteVersion(version)}>
          <DeleteIcon className={styles.listItemButtonIcon} />
        </button>
      )}

      {!isRenaming && (
        <div className={styles.nameBlock}>
          <p className={styles.listItemText} onClick={() => onVersionChange(version)}>
            {versionName}
          </p>
          {isRenamingAvailable && (
            <Button
              className={styles.renameButton}
              icon={<Pencil width={16} />}
              onClick={() => setIsRenaming((isRenaming) => !isRenaming)}
            />
          )}
        </div>
      )}
      {isRenaming && (
        <div className={styles.renameBlock}>
          <Input.TextArea
            className={styles.textArea}
            value={versionName}
            autoSize={{ minRows: 1 }}
            rows={1}
            allowClear
            onChange={(e) => setVersionName(e.target.value)}
          />
          <Button
            width={24}
            height={24}
            className={styles.renameButton}
            icon={<Cross width={16} />}
            variant="danger"
            onClick={handleCancelRenaming}
          />
          <Button
            width={24}
            height={24}
            className={styles.renameButton}
            icon={<Checkmark width={16} />}
            variant="success"
            disabled={versionName.length < 1}
            onClick={handleApplyRenaming}
          />
        </div>
      )}
    </li>
  );
});
