import { action, flow, makeObservable, observable } from 'mobx';

import { RigApi } from 'src/api/rig-sidebar/rig-api';
import { hasValue } from 'src/shared/utils/common';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PadTypes } from 'src/types/pad-types';
import { RigTypes } from 'src/types/rig-types';

export class RigStore {
  private readonly api = new RigApi();
  private readonly notifications: NotificationsStore;

  @observable isLoading = false;
  @observable padsStorage: PadTypes.RawPadWithItems[] = [];
  @observable pads: PadTypes.RawPadWithItems[] = [];
  @observable rig: RigTypes.RawRig | null = null;

  constructor(notifications: NotificationsStore) {
    this.notifications = notifications;

    makeObservable(this);
  }

  // Нужно для получения БУ со списком кустов для дальнейшего использования в информационном сайдбаре
  get rigWithPads(): Record<string, unknown> | null {
    if (!this.rig) {
      return null;
    }

    return {
      ...this.rig.data,
      pads: this.pads,
    };
  }

  @flow.bound
  async *loadPads(rigId: number, planVersionId: number) {
    if (!hasValue(planVersionId)) {
      this.notifications.showErrorMessageT('drawers:addToChart.failedToLoadPads');

      return;
    }

    this.isLoading = true;

    const pads = await this.api.getRigPads(rigId, planVersionId);
    yield;

    this.pads = pads;

    this.isLoading = false;
  }

  @flow.bound
  async *loadRig(rigId: number, planVersionId?: number) {
    if (!hasValue(planVersionId)) {
      this.notifications.showErrorMessageT('errors:failedToLoadRigData');

      return;
    }

    this.isLoading = true;

    const [rig, pads] = await Promise.all([this.api.getRig(rigId), this.api.getRigPads(rigId, planVersionId)]);
    yield;

    this.rig = rig;
    this.pads = pads;

    this.isLoading = false;
  }

  @action.bound
  clear(): void {
    this.rig = null;
    this.pads = [];
  }
}
