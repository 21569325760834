import { AxiosResponse } from 'axios';

import { TJoinResponse, TRefQuery } from 'src/store/directories/types';

import { agent } from '../agent';
import { throwApiError } from '../utils';

import { TDictObject } from './types';

/** @deprecated */
export async function fetchJoinedDirectoryDeprecated(
  refQuery: TRefQuery
): Promise<Record<string, string | number | boolean>[]> {
  try {
    const response = await agent.post<TRefQuery, AxiosResponse<{ [key: string]: string | number | boolean }[]>>(
      'object/select?flatResponse=true&DELETED=true',
      refQuery
    );
    return response.data;
  } catch (error) {
    throwApiError(error);
  }
}

export async function fetchJoinedDirectory(refQuery: TRefQuery, flatResponse = false): Promise<TJoinResponse[]> {
  try {
    const res = await agent.post<TRefQuery, AxiosResponse<TJoinResponse[]>>('object/select', refQuery, {
      params: {
        flatResponse,
        DELETED: true,
      },
    });
    return res.data;
  } catch (error) {
    throwApiError(error);
  }
}

export async function fetchDirectory(refQuery: TRefQuery): Promise<Record<string, TDictObject>[]> {
  try {
    const response = await agent.post<TRefQuery, AxiosResponse<Record<string, TDictObject>[]>>(
      'object/select?DELETED=true',
      refQuery
    );
    return response.data;
  } catch (error) {
    throwApiError(error);
  }
}
