import { action, computed, makeObservable, observable } from 'mobx';

import { IndicatorSettings } from './indicators-settings';

export class EditingIndicator {
  @observable isShown: boolean;

  private readonly item: IndicatorSettings;

  constructor(indicatorSettings: IndicatorSettings) {
    this.item = indicatorSettings;
    this.isShown = indicatorSettings.isShown;

    makeObservable(this);
  }

  @computed
  get id(): string {
    return this.item.name;
  }

  @computed
  get title(): string {
    return this.item.label;
  }

  @action.bound
  setIsShown(value: boolean): void {
    this.isShown = value;
  }

  @action.bound
  extractIndicator(): IndicatorSettings {
    this.item.setIsShown(this.isShown);

    return this.item;
  }
}
