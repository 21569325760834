export function getProgressLineColor(progressPercent: number): string {
  if (progressPercent < 30) {
    return '#EB5757';
  }

  if (progressPercent >= 30 && progressPercent < 70) {
    return '#F2994A';
  }

  if (progressPercent > 70) {
    return '#219653';
  }

  return '#219653';
}
