import moment from 'moment';

import styles from './timeline-year.module.scss';

type TGetMonthsReturn = {
  startMonth: number;
  endMonth: number;
  monthsCount: number;
};

export const getMonths = (start: number, end: number): TGetMonthsReturn => {
  const startMonth = moment.unix(start).get('month');
  const endMonth = moment.unix(end).get('month');

  const monthsCount = endMonth - startMonth + 1;

  return {
    startMonth,
    endMonth,
    monthsCount,
  };
};

export const getMonthsElements = (start: number, end: number) => {
  const { startMonth, monthsCount } = getMonths(start, end);

  if (monthsCount < 0 || monthsCount > 12) {
    return null;
  }

  return new Array(monthsCount).fill(null).map((_, index) => (
    <div key={index} className={styles.monthWrapper}>
      <p className={styles.month}>{(startMonth + index + 1).toString().padStart(2, '0')}</p>
    </div>
  ));
};
