import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { PadTypes } from 'src/types/pad-types';
import { RigTypes } from 'src/types/rig-types';

export class RigApi {
  async getRig(rigId: number): Promise<RigTypes.RawRig> {
    try {
      const { data } = await agent.get<RigTypes.RawRig>(`/object/Common_Rig/${rigId}`);

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async getRigPads(rigId: number, planVersionId: number): Promise<PadTypes.RawPadWithItems[]> {
    try {
      const { data } = await agent.get<PadTypes.RawPadWithItems[]>('rig/rigOperation/list', {
        params: { rigId, planVersionId },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
