import { toJS } from 'mobx';
import moment from 'moment';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

import { TPlanVersion, PlanVersionType } from '../comparison/types';
import { I18NextStore } from '../i18next/i18next-store';

const processPlan = (plan: TPlanVersion): DefaultOptionType => {
  return {
    value: plan.id,
    title: plan.data.name,
  };
};

export const processActualData = (data: TPlanVersion[], i18: I18NextStore): DefaultOptionType[] => {
  const publicPlans: DefaultOptionType = {
    value: 'actualPublic',
    title: i18.t('dashboard:actualPublic'),
    selectable: false,
    children: [],
  };
  const personalPlans: DefaultOptionType = {
    value: 'actualPersonal',
    title: i18.t('dashboard:actualPersonal'),
    selectable: false,
    children: [],
  };

  data.forEach((plan) => {
    if (PlanVersionType.personal === plan.data.versionType) {
      personalPlans.children?.push(processPlan(plan));
    }

    if ([PlanVersionType.public, PlanVersionType.current].includes(plan.data.versionType)) {
      publicPlans.children?.push(processPlan(plan));
    }
  });

  const options = [];

  if (publicPlans.children?.length) {
    options.push(publicPlans);
  }

  if (personalPlans.children?.length) {
    options.push(personalPlans);
  }

  return options;
};

const processPlanToDate = (plan: TPlanVersion): DefaultOptionType => {
  const dateString = moment(plan.createdAt).format('DD.MM.YYYY');
  const cleanPlan = toJS(plan);

  cleanPlan.data.name = `${plan.data.name} ${dateString}`;

  return {
    isLeaf: true,
    value: cleanPlan.id,
    title: cleanPlan.data.name,
  };
};

export const processArchivedData = (data: TPlanVersion[], i18: I18NextStore): DefaultOptionType[] => {
  const processedData: DefaultOptionType[] = [];

  for (const plan of data) {
    const date = new Date(plan.createdAt);
    const year = date.getFullYear().toString();
    const month = i18.t(`common:Months.month${date.getMonth()}`);

    if (!processedData.some((elem) => elem?.value === year)) {
      processedData.push({
        title: year,
        value: year,
        selectable: false,
        children: [
          {
            title: month,
            value: month,
            selectable: false,
            children: [processPlanToDate(plan)],
          },
        ],
      });
      continue;
    }

    const yearIndex = processedData.findIndex((elem) => elem.value === year);

    if (yearIndex === -1) {
      console.error('Unable to find an element by the specified index');
      continue;
    }

    if (!processedData[yearIndex]?.children?.some((monthElem) => monthElem.value === month)) {
      processedData[yearIndex].children?.push({
        title: month,
        value: month,
        selectable: false,
        children: [processPlanToDate(plan)],
      });
      continue;
    }

    const monthIndex = processedData[yearIndex].children?.findIndex((elem) => elem.value === month);

    if (monthIndex === void 0 || monthIndex === -1) {
      console.error('Unable to find an element by the specified index');
      continue;
    }

    processedData[yearIndex].children?.[monthIndex].children?.push(processPlanToDate(plan));
  }

  return processedData;
};
