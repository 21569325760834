import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Timeline } from 'src/features/drilling-chart/features/timeline/view/timeline/timeline';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { useStore } from 'src/store';

import { Chart } from '../chart/charts';
import { DashboardStore } from '../dashboard/dashboard.store';
import { getTimePeriods } from '../dashboard/utils';

import styles from './charts-container.module.scss';

type Props = {
  dashboardStore: DashboardStore;
};

export const ChartsContainer = observer(function ChartsContainer({ dashboardStore }: Props) {
  const { t } = useTranslation();
  const { theme } = useStore();
  const { timelinePresenter, horizontalViewport, timelineViewport, onPeriodToggle } = dashboardStore.timeline;

  const chartsEntities = dashboardStore.charts;
  const charts = chartsEntities?.map((chart) => <Chart key={chart.id} chartEntity={chart} theme={theme.theme} />);

  useEffect(() => {
    const disposeTimeline = timelinePresenter.init();

    return (): void => {
      disposeTimeline();
    };
  }, [timelinePresenter, timelinePresenter.init]);

  return (
    <div className={styles.container}>
      <section className={styles.chartsContainer}>{charts}</section>
      <section className={styles.timeline}>
        <SwitchButtonGroup
          className={styles.timelinePeriodsToggle}
          buttonClassName={styles.timelinePeriodButton}
          items={getTimePeriods(t)}
          defaultOptionKey={dashboardStore.period}
          onChange={onPeriodToggle}
        />

        <Timeline
          data={timelinePresenter.data}
          viewport={horizontalViewport}
          timelineViewport={timelineViewport}
          onDragDown={timelinePresenter.onDragDown}
          onDragMove={timelinePresenter.onDragMove}
          onRightDragUp={timelinePresenter.onRightDragUp.bind(timelinePresenter)}
          onLeftDragUp={timelinePresenter.onLeftDragUp.bind(timelinePresenter)}
          onDragCancel={timelinePresenter.onRightDragUp.bind(timelinePresenter)}
          onSelectAreaDown={timelinePresenter.onDragDown}
          onSelectAreaMove={timelinePresenter.onSelectAreaMove}
          onSelectAreaUp={timelinePresenter.onSelectAreaUp.bind(timelinePresenter)}
          onSelectAreaCancel={timelinePresenter.onSelectAreaUp.bind(timelinePresenter)}
        />
      </section>
    </div>
  );
});
