import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { SidebarWellRigOperation } from 'src/features/drilling-chart/presets/pads-and-wells-sidebar/entities/well-rig-operation.entity';

import { DrillingElementColoredCard } from '../../../../drilling-element-colored-card';
import { ConflictRigOperation } from '../../../presets/conflict-sidebar/entities/conflict-rig-operation';
import { WellRigOperation } from '../../../presets/drilling-rigs-chart/entities';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { useMovableElement } from '../../../shared/use-movable-element';
import { DraggableGhost } from '../../editing/shared/entities/draggable-ghost';
import { useDraggableGhost } from '../../editing/view/hooks/use-draggable-ghost';

import styles from './data-items-full.module.scss';

interface Props {
  item: DraggableGhost<WellRigOperation | ConflictRigOperation | SidebarWellRigOperation, unknown>;
  viewSettingsProvider: RigsViewSettingsProvider;
}

export const GhostWellRigOperationWrapper = observer(function GhostWellRigOperationWrapper({
  item,
  viewSettingsProvider,
}: Props) {
  const { transform, isPositionAvailable, draggableContainerElement } = useDraggableGhost({
    id: item.activeKey,
  });

  const ref = useMovableElement({
    item: item.ghost,
    transform,
  });

  if (!draggableContainerElement) {
    return null;
  }

  return createPortal(
    <div ref={ref} className={clsx(styles.dataItem, styles.draggableWellGhost)}>
      <DrillingElementColoredCard
        title={viewSettingsProvider.getTitle(item.ghost)}
        attributes={null}
        className={clsx(styles.wellCard, !isPositionAvailable && styles.notAvailable)}
      />
    </div>,
    draggableContainerElement
  );
});
