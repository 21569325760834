import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';

import { getSizeString } from '../../../components/timeline-chart/utils';
import { Viewport } from '../../../shared/viewport/viewport';
import { calculateItemOffsetParams } from '../../../shared/viewport/viewport-calculator';

import styles from './header.module.scss';

interface Props {
  viewport: Viewport;
}

export const CurrentDate = observer(function CurrentDate({ viewport }: Props) {
  const currentDate = moment().unix();

  if (currentDate < viewport.start || currentDate > viewport.end) {
    return null;
  }

  const { offset } = calculateItemOffsetParams(viewport, { start: currentDate, end: currentDate + 1 });

  return (
    <div className={styles.currentDateWrapper} style={{ marginLeft: getSizeString(offset) }}>
      <p className={styles.currentDateLabel}>{moment().format('DD.MM.YYYY')}</p>
    </div>
  );
});
