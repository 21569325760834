import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FormEventHandler, PropsWithChildren } from 'react';

import styles from './form.module.scss';

interface Props {
  className?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

// Use as wrapper to style contained select fields as form select fields.
export const Form = observer(function Form({ className, onSubmit, children }: PropsWithChildren<Props>) {
  return <form className={clsx(className, styles.container)} onSubmit={onSubmit} children={children} />;
});
