import { TDictObject } from 'src/api/directories/types';
import { TLabelValue } from 'src/shared/entities/abstract-control-entities';
import { Label } from 'src/shared/entities/control-entities';
import { Directories } from 'src/store/directories/directories.store';

import { TLabelRaw, TSavedFormValue } from '../types';

function isSavedLabel(obj: TSavedFormValue): obj is TLabelValue {
  if (obj === null || typeof obj !== 'object') return false;
  if ('label' in obj && 'value' in obj) return true;
  return false;
}

type TMapLabelItemOptions = {
  item: TLabelRaw;
  directory: TDictObject[] | null;
  directories: Directories;
  savedValue?: TSavedFormValue;
};

export const mapLabelItem = ({ item, directories, savedValue, directory }: TMapLabelItemOptions): Label => {
  const data = {
    type: item.type,
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    validate: item.validate,
    validationTags: item.validationTags,
    refObjectAttr: item.refObjectAttr,
    refObjectType: item.refObjectType,
    refQuery: item.refQuery,
    updateDictValue: item.updateDictValue,
    checkDictValue: item.checkDictValue,
    computeTags: item.computeTags,
    additionalConditionalAttributes: item.additionalConditionalAttributes,
    calculatedValue: item.calculatedValue,
    directory,
    restrictions: { required: false },
    initialValue: isSavedLabel(savedValue) ? savedValue : undefined,
    numberOfDecimalPlaces: item.numberOfDecimalPlaces,
    label: directories.getFieldLabel(item.fieldId) || undefined,
    placeholder: directories.getFieldPlaceholder(item.fieldId) || undefined,
    nullValue: directories.getFieldNullValue(item.fieldId) || undefined,
  };

  return new Label(data);
};
