import { observer } from 'mobx-react-lite';

import {
  InsertionPoint,
  LoadingRigOperations,
  PadRigOperation,
  TemporaryPadRigOperation,
  TemporaryWellRigOperation,
  WellRigOperation,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { RigsViewSettingsProvider } from '../../../presets/drilling-rigs-chart/rigs-view-settings-provider';
import { RigOperationsDndModule } from '../../../presets/rig-operations-dnd/rig-operations-dnd-module';
import { Viewport } from '../../../shared/viewport/viewport';
import { DraggableGhost } from '../../editing/shared/entities/draggable-ghost';
import { DraggableGhostShadow } from '../../editing/shared/entities/draggable-ghost-shadow';
import { InsertionPointNeighbor } from '../../editing/shared/entities/insertion-point-neighbor';
import { useDndContext } from '../../editing/view/hooks/use-dnd-context';
import { SortableGhostItem } from '../../sorting/sortable-ghost-item';
import { ShadowPadRigOperation } from '../presenter/shadow-pad-rig-operation';
import { ShadowWellRigOperation } from '../presenter/shadow-well-rig-operation';

import { EditingRigsDataItem } from './editing-rigs-data-item';

import styles from './data-items-full.module.scss';

interface Props {
  data: RigsChartStore.ViewItem[] | null;
  rigOperationDndModule: RigOperationsDndModule;
  horizontalViewport: Viewport;
  verticalViewport: Viewport;
  onPadClick(padId: number, rigId: number): void;
  onWellClick(wellId: number): void;
  viewSettingsProvider?: RigsViewSettingsProvider;
}

export const EditingRigsDataItemsFull = observer(function EditingRigsDataItemsFull({
  data,
  rigOperationDndModule,
  horizontalViewport,
  verticalViewport,
  onPadClick,
  onWellClick,
  viewSettingsProvider,
}: Props) {
  const ref = useDndContext({ draggableContainerClassName: styles.draggableContainer });

  if (!viewSettingsProvider) {
    return null;
  }

  return (
    <div ref={ref}>
      <>
        {data?.map((item) => {
          if (
            item instanceof PadRigOperation ||
            item instanceof WellRigOperation ||
            item instanceof LoadingRigOperations ||
            item instanceof InsertionPoint ||
            item instanceof DraggableGhost ||
            item instanceof InsertionPointNeighbor ||
            item instanceof DraggableGhostShadow ||
            item instanceof ShadowPadRigOperation ||
            item instanceof ShadowWellRigOperation ||
            item instanceof SortableGhostItem ||
            item instanceof TemporaryPadRigOperation ||
            item instanceof TemporaryWellRigOperation
          ) {
            return (
              <EditingRigsDataItem
                key={item.getKey('editing-full')}
                item={item}
                horizontalViewport={horizontalViewport}
                verticalViewport={verticalViewport}
                viewSettingsProvider={viewSettingsProvider}
                onPadClick={onPadClick}
                onWellClick={onWellClick}
                onRemoveRigOperation={rigOperationDndModule.removeRigOperation}
                onRemoveRigOperationsList={rigOperationDndModule.removeRigOperationsList}
              />
            );
          }

          return null;
        })}
      </>
    </div>
  );
});
