import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, MouseEventHandler, PointerEventHandler, PropsWithChildren, ReactNode } from 'react';

import styles from './card.module.scss';

interface Props {
  header?: ReactNode;
  icon?: ReactNode;
  className?: string;
  contentClassName?: string;
  isInteractive?: boolean;
  style?: CSSProperties;
  onMouseOver?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onPointerDown?: PointerEventHandler<HTMLDivElement>;
  onPointerUp?: PointerEventHandler<HTMLDivElement>;
  onPointerMove?: PointerEventHandler<HTMLDivElement>;
  onPointerCancel?: PointerEventHandler<HTMLDivElement>;
}

const Card = observer(function Card({
  header,
  icon,
  children,
  className,
  contentClassName,
  onMouseOver,
  onMouseLeave,
  isInteractive,
  onPointerDown,
  onPointerUp,
  onPointerMove,
  onPointerCancel,
  style,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(className, styles.card, isInteractive && styles.cardInteractive)}
      style={style}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onPointerCancel={onPointerCancel}
    >
      <div className={styles.cardHeaderWrapper}>
        {Boolean(icon) && <div className={styles.cardIconWrapper}>{icon}</div>}
        {header}
      </div>
      <div className={clsx(contentClassName, styles.cardContentWrapper)}>{children}</div>
    </div>
  );
});

export default Card;
