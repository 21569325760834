import { Dnd } from '../../types';

export class DraggingInteractive<TDataItem> {
  readonly id: string;
  readonly element: HTMLElement;
  readonly dataItem: Dnd.Interactive<TDataItem>;

  constructor(id: string, element: HTMLElement, dataItem: Dnd.Interactive<TDataItem>) {
    this.id = id;
    this.element = element;
    this.dataItem = dataItem;
  }
}
