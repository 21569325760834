/**
 * Возвращает строку значение + единица изменения - 6921 м
 */
export function formatValueWithUnit(value: string, unit: string | null) {
  if (value === '-') {
    return '-';
  }

  if (!unit) {
    return value;
  }

  return `${value} ${unit}`;
}
