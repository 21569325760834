import { AppSettings } from 'src/api/app/app-settings-api';

export function getWellColor(well: Record<string, unknown>, colorRules?: AppSettings.ColorRule[]): string {
  if (colorRules) {
    for (const colorRule of colorRules) {
      const { color, conditions } = colorRule;

      const checkConditions = (conditions: AppSettings.ColorRuleCondition[]) =>
        conditions.every((condition) => {
          if (well[condition.attr]?.toString() === condition.value?.toString()) {
            return true;
          }

          if (condition.or && checkConditions(condition.or)) {
            return true;
          }

          return false;
        });

      const isSomeConditionMet = checkConditions(conditions);

      if (isSomeConditionMet && AppSettings.isColor(color)) {
        return AppSettings.Colors[color];
      }
    }
  }

  return AppSettings.DEFAULT_COLOR;
}
