import { observer } from 'mobx-react-lite';

import {
  ChartRig,
  RigsGroup,
  TemporaryChartRig,
  TemporaryRigsGroup,
} from '../../../presets/drilling-rigs-chart/entities';
import { RigsChartStore } from '../../../presets/drilling-rigs-chart/rigs-chart.store';
import { Viewport } from '../../../shared/viewport/viewport';
import { RigsSorting } from '../../rigs-sorting/rigs-sorting';

import { EditingRigRow } from './editing-rig-row';
import { EditingRigsGroupHeader } from './editing-rigs-group-header';
import { EditingTemporaryRigRow } from './editing-temporary-rig-row';
import { GhostRow } from './ghost-row';

import styles from './data-items.module.scss';

interface Props {
  item: RigsChartStore.ViewItem;
  verticalViewport: Viewport;
  onWellAdd(): void;
  onRigAdd(groupId: number): void;
}

export const EditingRigsDataBackgroundItem = observer(function EditingRigsDataBackgroundItem({
  item,
  verticalViewport,
  onWellAdd,
  onRigAdd,
}: Props) {
  if (item instanceof ChartRig) {
    return <EditingRigRow item={item} verticalViewport={verticalViewport} onWellAdd={onWellAdd} />;
  }

  if (item instanceof TemporaryChartRig) {
    return <EditingTemporaryRigRow item={item} verticalViewport={verticalViewport} onWellAdd={onWellAdd} />;
  }

  if (item instanceof RigsGroup || item instanceof TemporaryRigsGroup) {
    return <EditingRigsGroupHeader item={item} verticalViewport={verticalViewport} onRigAdd={onRigAdd} />;
  }

  if (RigsSorting.isRigGhost(item)) {
    return <GhostRow item={item} verticalViewport={verticalViewport} className={styles.row} />;
  }

  if (RigsSorting.isRigsGroupGhost(item)) {
    return <GhostRow item={item} verticalViewport={verticalViewport} className={styles.rowHeader} />;
  }

  return null;
});
