import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewValueToDirectoryModal } from 'src/features/modals/add-new-value-to-directory-modal';
import { NewWellMulticombobox } from 'src/features/well-form/form-controls';
import { TreeMulticombobox } from 'src/features/well-form/form-controls/treemulticombobox/treemulticombobox';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button';
import { MultiCombobox, TreeMultiCombobox } from 'src/shared/entities/control-entities';

import { DashboardStore } from '../dashboard/dashboard.store';

import styles from './filter.module.scss';

type Props = {
  dashbaordStore: DashboardStore;
};

export const Filter = observer(function Filter({ dashbaordStore }: Props) {
  const { t } = useTranslation('dashboard');

  const form = dashbaordStore.filterForm;

  useEffect(() => form?.effect(), [form]);

  if (!form) {
    return null;
  }

  const { fieldsArray: fields, resetForm, getLabel } = form;

  return (
    <aside className={styles.filter}>
      <div className={styles.controlsWrapper}>
        <div className={styles.filterTitle}>{t('filters')}</div>
        {fields.map((item) => {
          let control: null | ReactElement = null;

          if (item instanceof MultiCombobox) {
            control = (
              <Fragment key={item.fieldId}>
                <NewWellMulticombobox
                  className={styles.combobox}
                  item={item}
                  label={getLabel(item) ?? ''}
                  validationOptions={item.settings}
                />
                <AddNewValueToDirectoryModal modalController={item.addNewValueToDirectoryModalController} />
              </Fragment>
            );
          } else if (item instanceof TreeMultiCombobox) {
            control = (
              <TreeMulticombobox
                key={item.fieldId}
                className={styles.combobox}
                item={item}
                label={getLabel(item) ?? ''}
                validationOptions={item.settings}
              />
            );
          }

          return control;
        })}
      </div>
      <Button className={styles.resetButton} variant="danger" onClick={resetForm} prefixIcon={<CloseIcon />}>
        {t('resetButton')}
      </Button>
    </aside>
  );
});
