import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { Table as AntdTable } from 'antd';
import { ColumnType as AntdColumnType } from 'antd/es/table';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Pad } from 'src/features/planning/store/pad';
import { MIN_COLUMN_WIDTH } from 'src/features/planning/views/plan-table/constants';
import { SortableBody } from 'src/features/planning/views/plan-table/sortable-body';
import { SortableRow } from 'src/features/planning/views/plan-table/sortable-row';
import { SortableWrapper } from 'src/features/planning/views/plan-table/sortable-wrapper';
import { TableCollapse } from 'src/features/planning/views/plan-table/table-collapse';
import { TableTitle } from 'src/features/planning/views/plan-table/table-title';
import {
  getTableData,
  getTableWidth,
  isFunctionalColumn,
  getColumns,
} from 'src/features/planning/views/plan-table/utils';
import { SortingOptionType, FilterType, GROUP_TYPE } from 'src/pages/plan-page/types';
import { ResizeTableLine } from 'src/shared/components/resize-table-line';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { CHANGED_VARIANT, ColumnType, TableRow } from 'src/store/table/types';
import { getCellValue } from 'src/store/table/utils';

import styles from './plan-table.module.scss';

interface Props {
  id: string;
  pad: Pad;
  draggable: boolean;
  isEditing: boolean;
  directories: Directories;
  notifications: NotificationsStore;
  onSortTableData(sortingOption: SortingOptionType | null): void;
  onFiltersChange(filters: FilterType[]): void;
  setTableView(tableView: ColumnType[]): void;
  onWellClick(wellId: number): void;
  onOpenAddPadSidebar(padName?: string): void;
  onComputeRigOperations(insert: number, insertOnPlace: number | null, insertAfter: number | null): void;
  sorting: SortingOptionType | null;
  filters: FilterType[];
  onOpenPad(padKey: string): void;
  onClosePad(padKey: string): void;
  onRemovePad(): void;
  isPadOpened: boolean;
  rowIdKey: string | null;
  isImportOccurred: boolean;
  groupType: GROUP_TYPE;
}

export const PlanTable = observer(function PlanTable({
  id,
  draggable,
  isEditing,
  onSortTableData,
  setTableView,
  directories,
  onWellClick,
  sorting,
  filters,
  onFiltersChange,
  pad,
  onOpenAddPadSidebar,
  onOpenPad,
  onClosePad,
  isPadOpened,
  rowIdKey,
  onComputeRigOperations,
  isImportOccurred,
  groupType,
  onRemovePad,
  notifications,
}: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const { t } = useTranslation();

  const attributeNames = getPadAttributeIcons(pad.dataSource);

  const [tableElement, setTableElement] = useState<Element | null>(null);

  const table = pad.table;

  const tableStyle = clsx(styles.wrapper, {
    [styles.wrapperResizable]: table.resizeParams.isResize,
    [styles.wrapperEditing]: isEditing,
  });

  const columns = getColumns(table.columnsData, isEditing, groupType);

  const dataSource = getTableData(
    table.columnsData,
    table.tableData,
    directories,
    table.resizeParams.isResize,
    onWellClick,
    rowIdKey,
    t,
    isImportOccurred
  );

  const mergeColumns = columns.map((antdColumn) => ({
    ...antdColumn,
    onHeaderCell: (column: AntdColumnType<TableRow>) => ({
      columnId: column.key,
      width: column.width,
      minColumnWidth: MIN_COLUMN_WIDTH,
      onResizeStart: table.resizeStart,
      onResizeStop: resizeEnd,
      setXCoordinates: table.setXCoordinates,
      columnType: antdColumn.control,
      isResizable: isFunctionalColumn(column.key as string),
      isSortable: isFunctionalColumn(column.key as string) && !isEditing,
      objectField: antdColumn.objectField,
      object: directories.getObject(antdColumn.objectType),
      excludeFromSettings: antdColumn.excludeFromSettings,
      onSortTableData,
      onFiltersChange,
      sorting,
      filters,
    }),
  }));

  function resizeEnd(): void {
    const newTableView = table.resizeEnd();
    setTableView(newTableView);
  }

  function handleDragEnd({ active, over }: DragEndEvent): void {
    // Hardcode-attr
    const wellIdKey = 'GOplan_WellPlacement.wellId';
    const startDateKey = 'GOplan_RigOperation.startDate';
    const wellTripleId = 'GOplan_PlanWellTriple.id';

    // TODO Придумать текст ошибки либо удалить если функционал не актуален
    const errorMessage = 'Сообщение об ошибке';

    if (over && active.id !== over.id) {
      const oldIndex = dataSource.findIndex((item) => item.key === active.id);
      const newIndex = dataSource.findIndex((item) => item.key === over.id);

      const draggableRigOperation = table.tableData[oldIndex];
      const draggableRigOperationsStartDate = getCellValue(draggableRigOperation[startDateKey]);
      const draggableRigOperationWellId = getCellValue(draggableRigOperation[wellIdKey]);

      const newTableData = arrayMove(table.tableData, oldIndex, newIndex);

      // Цикл валидирует на кейс, когда в рамках одной скважины, подход с более поздней датой начала нельзя поставить раньше подхода с более ранней датой начала
      for (let index = 0; index < newTableData.length; index++) {
        const currentRigOperationsStartDate = getCellValue(newTableData[index][startDateKey]);
        const currentRigOperationsWellId = getCellValue(newTableData[index][wellIdKey]);

        assert(currentRigOperationsStartDate && currentRigOperationsWellId && draggableRigOperationsStartDate);

        if (index < newIndex) {
          if (currentRigOperationsWellId === draggableRigOperationWellId) {
            if (currentRigOperationsStartDate > draggableRigOperationsStartDate) {
              notifications.showErrorMessage(errorMessage);
              return;
            }
          }
        }

        if (index > newIndex) {
          if (currentRigOperationsWellId === draggableRigOperationWellId) {
            if (currentRigOperationsStartDate < draggableRigOperationsStartDate) {
              notifications.showErrorMessage(errorMessage);
              return;
            }
          }
        }
      }

      const rowOnPlace = hasValue(table.tableData[newIndex]) ? table.tableData[newIndex] : null;
      const rowAfter = hasValue(table.tableData[newIndex - 1]) ? table.tableData[newIndex - 1] : null;

      // PlanWellTriple(Тройки) участвующие в смене очередности (вставленная тройка, тройка после которой вставить, тройка на место которой вставить)
      const insert = getCellValue(draggableRigOperation[wellTripleId]);
      const insertAfter = rowAfter && getCellValue(rowAfter[wellTripleId]);
      const insertOnPlace = rowOnPlace && getCellValue(rowOnPlace[wellTripleId]);

      assert(typeof insertOnPlace === 'number' && typeof insertAfter === 'number');

      if (hasValue(insert) && typeof insert === 'number') {
        onComputeRigOperations(insert, insertOnPlace, insertAfter);
      }
    }
  }

  useEffect(() => {
    if (tableElement) {
      table.setLeftOffset(tableElement.getBoundingClientRect().x);
    }
  }, [tableElement, table]);

  return (
    <div
      className={styles.container}
      style={{ transform: `translateY(${transform?.y || 0}px)`, transition }}
      ref={setNodeRef}
      {...attributes}
    >
      <TableCollapse
        editing={isEditing}
        dragListeners={listeners}
        draggable={draggable}
        attributeNames={attributeNames}
        pad={pad}
        onOpenAddPadSidebar={onOpenAddPadSidebar}
        onOpenPad={onOpenPad}
        onClosePad={onClosePad}
        isPadOpened={isPadOpened}
        isDraggable={groupType === GROUP_TYPE.rig}
        onRemovePad={onRemovePad}
      >
        <div ref={setTableElement} className={tableStyle}>
          <ResizeTableLine resizeParams={table.resizeParams} action="static" />
          <SortableWrapper onDragEnd={handleDragEnd}>
            <AntdTable
              style={{ width: getTableWidth(columns, tableElement?.clientWidth) }}
              columns={mergeColumns}
              pagination={false}
              dataSource={dataSource as TableRow[]}
              scroll={{ x: '100%' }}
              loading={table.loading}
              components={{
                body: { wrapper: SortableBody, row: SortableRow },
                header: { cell: TableTitle },
              }}
              //  Хак, чтобы внутри компонента строки таблицы antd получить состояние rowChanged
              //@ts-ignore
              onRow={(row) => {
                return {
                  rowChanged: row.rowChanged === CHANGED_VARIANT.added || row.rowChanged === CHANGED_VARIANT.deleted,
                };
              }}
            />
          </SortableWrapper>
          <ResizeTableLine resizeParams={table.resizeParams} action="dynamic" />
        </div>
      </TableCollapse>
    </div>
  );
});
