import { TRefQuery, TRefRest } from 'src/store/directories/types';

import { REF_QUERY_REG_EXP } from '../constants/ref-query-reg-exp';

export const isDynamicJoin = (refQuery: TRefQuery | TRefRest): boolean => {
  const stringifiedRefQuery = JSON.stringify(refQuery);

  const isDynamic = !!stringifiedRefQuery.match(REF_QUERY_REG_EXP);

  return isDynamic;
};
