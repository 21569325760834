import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SaveIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as UploadIcon } from 'src/shared/assets/icons/cloud.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/cross.svg';
import { ReactComponent as FullIcon } from 'src/shared/assets/icons/full-icon.svg';
import { ReactComponent as SettingsIcon } from 'src/shared/assets/icons/gear.svg';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/pencil.svg';
import { ReactComponent as Retry } from 'src/shared/assets/icons/retry-arrows.svg';
import { Button } from 'src/shared/components/button';
import { ButtonWithTooltip } from 'src/shared/components/button-with-tooltip';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';

import { HeaderStore } from '../../pages/drilling-plan-chart/header.store';
import { PlanType } from '../drilling-chart/shared/plan-type';

import styles from './drilling-plan-header.module.scss';

interface Props {
  store: HeaderStore;
  onHistoryShow: VoidFunction;
  onSettingsOpen: VoidFunction;
  onFullScreen: VoidFunction;
  onRecalculateParameters: VoidFunction;
  isDisabled?: boolean;
  /** Disable any changes than can update state. For ex., editing/saving/publishing. */
  isChangesDisabled?: boolean;
}

export const DrillingPlanHeader = observer(function DrillingPlanHeader({
  store,
  onHistoryShow,
  onSettingsOpen,
  onFullScreen,
  onRecalculateParameters,
  isDisabled,
  isChangesDisabled,
}: Props) {
  const { t } = useTranslation();

  const {
    init,
    isEditing,
    displayChartType,
    onDisplayChartTypeChange,
    isLoading,
    onCancel,
    onSave,
    onPublish,
    isEditModeLoading,
    editing,
    jobListStore,
    onEdit,
  } = store;

  useEffect(init, [init]);

  return (
    <div className={styles.container}>
      <SwitchButtonGroup
        items={[
          { key: PlanType.wells, title: t('drillingCarpet:Header.wellPad'), disabled: isEditing },
          { key: PlanType.rigs, title: t('drillingCarpet:Header.drillingRig') },
        ]}
        defaultOptionKey={displayChartType}
        className={styles.switchContainer}
        buttonClassName={styles.switchButton}
        onChange={onDisplayChartTypeChange}
        disabled={isDisabled}
      />

      <div className={styles.controlsContainer}>
        {isEditing ? (
          <div className={styles.editingControls}>
            <Button
              variant="text"
              prefixIcon={<Retry className={styles.retryIcon} />}
              isLoading={isLoading}
              onClick={onRecalculateParameters}
            >
              <p className={styles.retryText}>{t('drillingCarpet:Header.recalculateParameters')}</p>
            </Button>
            <Button
              className={styles.editingControlsButton}
              variant="primary"
              icon={<UploadIcon />}
              onClick={onPublish}
              disabled={isDisabled || isChangesDisabled || isLoading}
              isLoading={isLoading}
            >
              {t('Buttons.toPublish')}
            </Button>

            <Button
              className={styles.editingControlsButton}
              variant="danger"
              icon={<CloseIcon />}
              onClick={onCancel}
              disabled={isDisabled || isChangesDisabled || isLoading}
              isLoading={isLoading}
            >
              {t('Buttons.cancel')}
            </Button>

            <Button
              className={styles.editingControlsButton}
              variant="success"
              icon={<SaveIcon />}
              onClick={onSave}
              disabled={isDisabled || isChangesDisabled || isLoading}
              isLoading={isLoading}
            >
              {t('Buttons.save')}
            </Button>

            <ButtonWithTooltip
              onClick={onSettingsOpen}
              icon={<SettingsIcon className={styles.controlsButtonIcon} />}
              className={styles.controlsButton}
              tooltipText={t('displaySettings')}
              isDisabled={isDisabled}
            />
          </div>
        ) : (
          <>
            {editing.isCanEditing && (
              <ButtonWithTooltip
                onClick={onEdit}
                icon={<EditIcon className={styles.controlsButtonIcon} />}
                className={clsx(styles.controlsButton, styles.controlsButtonEdit)}
                tooltipText={t('edit')}
                isDisabled={isDisabled || isChangesDisabled || isEditModeLoading || jobListStore.hasActiveImportJob}
                isLoading={isEditModeLoading}
              />
            )}
            {/* Временно отключенный функционал */}
            {/* <ButtonWithTooltip
              onClick={onHistoryShow}
              icon={<HistoryIcon className={styles.controlsButtonIcon} />}
              className={styles.controlsButton}
              tooltipText={t('historyOfChange')}
              isDisabled={isDisabled}
            /> */}

            <ButtonWithTooltip
              onClick={onSettingsOpen}
              icon={<SettingsIcon className={styles.controlsButtonIcon} />}
              className={styles.controlsButton}
              tooltipText={t('displaySettings')}
              isDisabled={isDisabled}
            />
          </>
        )}

        <ButtonWithTooltip
          onClick={onFullScreen}
          icon={<FullIcon className={styles.controlsButtonIcon} />}
          className={styles.controlsButton}
          tooltipText={t('fullscreen')}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
});
