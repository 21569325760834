import { observer } from 'mobx-react-lite';

import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/dots.svg';

import styles from './table-collapse-handlers.module.scss';

interface Props {
  dragListeners?: import('@dnd-kit/core/dist/hooks/utilities').SyntheticListenerMap;
  isDraggable: boolean;
  onRemove(): void;
}

export const TableCollapseHandlers = observer(function TableCollapseHandlers({
  dragListeners,
  isDraggable,
  onRemove,
}: Props) {
  return (
    <div className={styles.container}>
      {isDraggable && <MoveIcon className={styles.dragHandler} {...dragListeners} />}
      <DeleteIcon className={styles.deleteHandler} onClick={onRemove} />
    </div>
  );
});
