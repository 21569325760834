import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FormEventHandler, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/shared/components/button';
import { Form } from 'src/shared/components/form';
import { Loader } from 'src/shared/components/loader';
import { Modal } from 'src/shared/components/modal/modal';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { hasValue } from 'src/shared/utils/common';

import { FiltersFormStore } from '../../shared/filters-form.store';

import { FiltersItem } from './filters-item';

import styles from './filters-modal.module.scss';

interface Props {
  formStore: FiltersFormStore;
  className?: string;
}

export const FiltersModal = observer(function FiltersModal({ formStore, className }: Props) {
  const {
    isOpen,
    form,
    groupingOptions,
    groupingValue,
    groupingLabel,
    onGroupingChange,
    onSubmit,
    isLoading,
    isBackgroundLoading,
    filteringDataLength,
    onFiltersReset,
    onCancel,
  } = formStore;

  const { t } = useTranslation('drillingCarpet', { keyPrefix: 'Filters' });

  useEffect(() => {
    if (formStore) {
      const disposeFilters = formStore.init();

      return () => {
        disposeFilters();
      };
    }
  }, [formStore]);

  useEffect(() => {
    const dispose = form?.effect();

    return () => {
      dispose?.();
    };
  }, [form]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  // Need to define scrollable container to display shown select options correctly.
  const fieldsContainer = useRef<HTMLDivElement>(null);

  const header = (
    <div className={styles.header}>
      <p className={styles.headerTitle}>{t('title')}</p>
    </div>
  );

  const hasGrouping = !!groupingOptions?.length && hasValue(groupingValue) && !!onGroupingChange;

  const _groupingLabel = groupingLabel ?? t('grouping');

  return (
    <Modal
      isOpened={isOpen}
      handleClose={onCancel}
      title={header}
      maxWidth={930}
      className={clsx(className, styles.modal)}
      loading={isLoading}
    >
      <Form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.fields}>
          <div ref={fieldsContainer} className={styles.fieldsInner}>
            {hasGrouping && (
              <div className={styles.switch}>
                <label className={styles.fieldLabel}>{_groupingLabel}</label>

                <SwitchButtonGroup
                  items={groupingOptions}
                  defaultOptionKey={groupingValue}
                  value={groupingValue}
                  className={styles.switchField}
                  buttonClassName={styles.switchButton}
                  onChange={onGroupingChange}
                />
              </div>
            )}

            {form?.fieldsArray?.map((field) => (
              <FiltersItem
                key={field.fieldId}
                item={field}
                label={form?.getLabel(field) ?? undefined}
                container={fieldsContainer.current}
              />
            ))}
          </div>
        </div>

        <div className={styles.footer}>
          <Button variant="secondary" className={styles.footerButton} onClick={onFiltersReset} disabled={isLoading}>
            {t('clearFilters')}
          </Button>
          <Button variant="primary" className={styles.footerButton} onClick={onCancel} disabled={isLoading}>
            {t('cancel')}
          </Button>
          <Button
            variant="success"
            className={styles.footerButton}
            type="submit"
            disabled={isLoading || isBackgroundLoading}
          >
            {t('show')}{' '}
            {isBackgroundLoading ? (
              <div className={styles.buttonLoader}>
                <Loader size="small" />
              </div>
            ) : (
              filteringDataLength
            )}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});
