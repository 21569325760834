import { Summary } from 'src/api/chart/summary-data-api';

export interface IRigSummary {
  rigId: number;
}

export class RigSummary implements IRigSummary {
  private readonly rows: Partial<Summary.Rows>;

  readonly rigId: number;

  constructor(parentId: number, rows: Partial<Summary.Rows>) {
    this.rows = rows;
    this.rigId = parentId;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-summary-row-${this.rigId}`;
    }

    return `summary-row-${this.rigId}`;
  }

  getValue(rowName: string, columnName: string): string | number | null {
    if (!Summary.isIndicator(rowName) || !Summary.isColumn(columnName)) {
      return null;
    }

    const row = this.rows[rowName];

    return row?.[columnName] ?? null;
  }
}
