import { Item } from 'src/shared/entities/abstract-control-entities';
import { MultiCombobox, RegularComboBox } from 'src/shared/entities/control-entities';
import { getRandomNumber } from 'src/shared/utils/get-random-number';

import { FormStore } from '../entities/form.entity';
import { TOption } from '../types';

import { FormPlugin } from './abstract-form-plugin.entity';

export class ArchivedDirectoryValuesInUnplannedGeotaskPlugin extends FormPlugin<FormStore> {
  connect(form: FormStore): void | VoidFunction {
    this.form = form;

    const isGeotaskPlanned = !!form.approachesTab?.approachesList.approaches.length;

    if (!isGeotaskPlanned) {
      form.validationManager.addExtraFieldValidation({
        id: getRandomNumber(),
        validate: (item: Item) => this.validateCombobox(item),
      });

      form.processFormFields((item: Item) => this.validateCombobox(item));
    }
  }

  private validateCombobox(item: Item): void {
    if (item instanceof RegularComboBox) {
      const isCurrentValueIsArchived = !!item.archivedOptions.find((option) => option.value === item.value);

      if (isCurrentValueIsArchived) {
        item.setError(this.rootStore.i18.t('errors:NewWellForm.restrictedArchivedValueInRegularCombobox'));
      }
    }
    if (item instanceof MultiCombobox) {
      const archivedValues: TOption[] = [];

      item.value.forEach((value) => {
        const archivedOption = item.archivedOptions.find((option) => option.value === value);

        if (archivedOption) {
          archivedValues.push(archivedOption);
        }
      });

      if (!!archivedValues.length) {
        item.setError(
          this.rootStore.i18.t('errors:NewWellForm.restrictedArchivedValueInMultiCombobox', {
            archivedValues: archivedValues.map((value) => value.label).join(', '),
          })
        );
      }
    }
  }
}
