import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { MouseEventHandler, PointerEventHandler, ReactNode, useRef } from 'react';

import { ReactComponent as ExpandIcon } from 'src/shared/assets/icons/expand-down.svg';
import { checkIsNode } from 'src/shared/utils/check-is-node';

import styles from './colored-card.module.scss';

interface Props {
  title: React.ReactNode;
  headerNextExtraComponent?: ReactNode;
  text?: string;
  icons?: ReactNode;
  extra?: ReactNode;
  showExpandButton?: boolean;
  cardColor?: string;
  className?: string;
  isInteractive?: boolean;
  onPointerDown?: PointerEventHandler;
  onPointerUp?: PointerEventHandler;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onShowInfo?(): void;
}

export const ColoredCard = observer(function ColoredCard({
  cardColor,
  title,
  text,
  icons,
  extra,
  headerNextExtraComponent,
  showExpandButton,
  className,
  isInteractive,
  onPointerDown,
  onPointerUp,
  onShowInfo,
  onClick,
}: Props) {
  const nextComponentsContainerRef = useRef<HTMLDivElement>(null);

  const handlePointerDown: PointerEventHandler = (...args) => {
    const [e] = args;

    if (!onPointerDown || (checkIsNode(e.target) && nextComponentsContainerRef.current?.contains(e.target))) {
      return;
    }

    onPointerDown(...args);
  };

  const handlePointerUp: PointerEventHandler = (...args) => {
    const [e] = args;

    if (!onPointerUp || (checkIsNode(e.target) && nextComponentsContainerRef.current?.contains(e.target))) {
      return;
    }

    onPointerUp(...args);
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (...args) => {
    const [e] = args;

    if (!onClick || (checkIsNode(e.target) && nextComponentsContainerRef.current?.contains(e.target))) {
      return;
    }

    onClick(...args);
  };

  return (
    <div
      className={clsx(className, styles.card, isInteractive && styles.cardInteractive)}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onClick={handleClick}
    >
      <div className={styles.cardBorder} style={{ backgroundColor: cardColor }} />

      <div className={styles.cardHeader}>
        {extra}
        <h3 className={styles.cardHeaderTitle}>{title}</h3>

        <div className={styles.cardHeaderRightPart}>
          <div ref={nextComponentsContainerRef}>{headerNextExtraComponent}</div>
          <div className={styles.cardHeaderIcons}>{icons}</div>
        </div>
      </div>

      <div className={styles.cardBody}>
        <p className={styles.cardBodyText}>{text}</p>
        {showExpandButton && (
          <button className={styles.cardBodyButton} onClick={onShowInfo}>
            <ExpandIcon />
          </button>
        )}
      </div>
    </div>
  );
});
