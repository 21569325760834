import moment from 'moment';
import { Moment } from 'moment/moment';

import { Range } from '../layers/model';

import { TimeUnit } from './time-unit';

export namespace TimeRangeHelper {
  /**
   * Returns start and end of current year by default.
   * */
  export const expandRange = (range: Range<Moment>, timeUnit?: TimeUnit): Range<Moment> => {
    const { start, end } = range;

    switch (timeUnit) {
      case TimeUnit.month:
        return {
          start: moment(start).startOf('month'),
          end: moment(end).endOf('month'),
        };

      case TimeUnit.year:
        return {
          start: moment(start).startOf('year'),
          end: moment(end).endOf('year'),
        };

      case TimeUnit.quarter:
        return {
          start: moment(start).startOf('quarter'),
          end: moment(end).endOf('quarter'),
        };

      default:
        return {
          start: moment().startOf('year'),
          end: moment().endOf('year'),
        };
    }
  };

  export const expandRangeInUnix = (range: Range<number>, timeUnit?: TimeUnit): Range<number> => {
    const { start, end } = range;

    switch (timeUnit) {
      case TimeUnit.month:
        return {
          start: moment.unix(start).startOf('month').unix(),
          end: moment.unix(end).endOf('month').unix(),
        };

      case TimeUnit.year:
        return {
          start: moment.unix(start).startOf('year').unix(),
          end: moment.unix(end).endOf('year').unix(),
        };

      case TimeUnit.quarter:
        return {
          start: moment.unix(start).startOf('quarter').unix(),
          end: moment.unix(end).endOf('quarter').unix(),
        };

      default:
        return {
          start: moment().startOf('year').unix(),
          end: moment().endOf('year').unix(),
        };
    }
  };

  /**
   * @return {number[]} Dates between start and end of range in unix.
   * */
  export const getIntermediateDates = (range: Range<number>, timeUnit: TimeUnit): Range<number>[] => {
    const start = moment.unix(range.start);
    const end = moment.unix(range.end);

    switch (timeUnit) {
      case TimeUnit.month:
        const monthColumns: Range<number>[] = [];
        const lastMonthUnix = end.startOf('month').unix();

        let monthCount = 0;
        let currentMonth = start;

        do {
          currentMonth = moment(start).add({ month: monthCount });

          monthColumns.push({
            start: currentMonth.startOf('month').unix(),
            end: currentMonth.endOf('month').unix(),
          });

          monthCount++;
        } while (moment(currentMonth).startOf('month').unix() < lastMonthUnix);

        return monthColumns;
      case TimeUnit.year:
        const startYear = start.get('year');
        const endYear = end.get('year');

        const yearColumns: Range<number>[] = [];

        for (let year = startYear; year <= endYear; year++) {
          const currentYear = moment().set('year', year);

          yearColumns.push({
            start: currentYear.startOf('year').unix(),
            end: currentYear.endOf('year').unix(),
          });
        }

        return yearColumns;
      case TimeUnit.quarter:
        const quarterColumns: Range<number>[] = [];
        const lastQuarterUnix = end.startOf('month').unix();

        let quarterCount = 0;
        let currentQuarter = start;

        do {
          currentQuarter = moment(start).add({ quarter: quarterCount });

          quarterColumns.push({
            start: currentQuarter.startOf('quarter').unix(),
            end: currentQuarter.endOf('quarter').unix(),
          });

          quarterCount++;
        } while (moment(currentQuarter).startOf('month').unix() < lastQuarterUnix);

        return quarterColumns;
      default:
        return [];
    }
  };
}
