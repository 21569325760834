import {
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragEndEvent,
  pointerWithin,
} from '@dnd-kit/core';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NewValueConflictModal } from 'src/features/modals/new-value-conflict-modal/new-value-conflict-modal';
import { UnmountWithTransition } from 'src/shared/components/core/unmount-with-transition/unmount-with-transition';
import { useStore } from 'src/store';

import { DragOverlayItem } from './components/drag-overlay';
import { ImportMatchingPageSubheader } from './components/import-matching-page-subheader';
import { MatchingImportStore } from './import-matching-page.store';
import { InvalidValuesColumn } from './invalid-values-column';
import { MatchingColumn } from './matching-column';

import styles from './import-matching-page.module.scss';

export const ImportMatchingPage = observer(function ImportMatchingPage() {
  const store = useStore();

  const [params] = useSearchParams();

  const fileId = params.get('fileId');
  const sheetName = params.get('sheetName');
  const targetPlanVersionId = params.get('targetPlanVersionId');

  const [matchingStore] = useState(() => {
    if (!fileId || !sheetName || !targetPlanVersionId) {
      return null;
    }

    return new MatchingImportStore(store, Number(fileId), sheetName, targetPlanVersionId);
  });

  useEffect(() => {
    if (!matchingStore) return;

    matchingStore.effect();
  }, [matchingStore]);

  const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

  function onDragEnd(event: DragEndEvent) {
    if (!matchingStore) return;

    const fromAttr = event.active.data.current?.from;
    const toAttr = event.over?.id;

    const fromValue = event.active.data.current?.header;
    const toValue = event.over?.data.current?.header;

    if (!toAttr || fromValue === toValue) {
      return;
    }

    if (!fromAttr && toAttr && fromValue && toValue) {
      matchingStore.addUnmatchedHeader(toValue);
      matchingStore.addDataItem(toAttr.toString(), fromValue);
    }

    if (fromAttr && toAttr === 'heap') {
      matchingStore.removeDataItem(fromAttr);
      matchingStore.addUnmatchedHeader(fromValue);
    }

    if (fromAttr && toAttr !== 'heap' && !toValue) {
      matchingStore.removeDataItem(fromAttr);
      matchingStore.addDataItem(toAttr.toString(), fromValue);
    }

    if (fromAttr && toAttr !== 'heap' && toValue) {
      matchingStore.addDataItem(fromAttr.toString(), toValue);
      matchingStore.addDataItem(toAttr.toString(), fromValue);
    }

    if (!fromAttr && toAttr !== 'heap') {
      matchingStore.addDataItem(toAttr.toString(), fromValue);
      matchingStore.removeUnmatchedHeader(fromValue);
    }
  }

  if (!matchingStore) return null;

  return (
    <div className={styles.pageWrapper}>
      <ImportMatchingPageSubheader handleSave={matchingStore.parseExcelData} onCancel={matchingStore.cancelImport} />
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragEnd={onDragEnd}>
            <MatchingColumn matchingImport={matchingStore} />
            <InvalidValuesColumn matchingImport={matchingStore} />
            <DragOverlayItem />
          </DndContext>
        </div>
      </div>
      <UnmountWithTransition
        className={styles.preloaderCont}
        isShown={matchingStore.isLoading}
        mountStylesObj={{ opacity: 1 }}
        unmountStylesObj={{ opacity: 0 }}
      >
        <Spin size="large" />
      </UnmountWithTransition>
      <NewValueConflictModal matchingImport={matchingStore} />
    </div>
  );
});
