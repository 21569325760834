import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/shared/components/button/button';
import { Modal } from 'src/shared/components/modal/modal';

import styles from './lose-changes-modal.module.scss';

type Props = {
  isOpened: boolean;
  text?: string;
  confirmCallback: () => void;
  handleClose: () => void;
};

export const LoseChangesModal = observer(function LoseChangesModal({
  isOpened,
  text,
  confirmCallback,
  handleClose,
}: Props) {
  const { t } = useTranslation('common');

  function onConfirm(): void {
    confirmCallback();
    handleClose();
  }

  return (
    <Modal width={456} top={128} title={t('attention')} closeOnClickOutside={false} isOpened={isOpened}>
      <p className={styles.text}>{text || t('loseChangesDescription')}</p>
      <div className={styles.buttonsWrapper}>
        <Button variant="secondary" width={192} onClick={handleClose}>
          {t('Buttons.no')}
        </Button>
        <Button width={192} variant="primary" onClick={onConfirm}>
          {t('Buttons.yes')}
        </Button>
      </div>
    </Modal>
  );
});
