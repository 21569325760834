import { observer } from 'mobx-react-lite';

import { Pad as PadEntity } from '../../entities/tab-with-pads-entities/pad.entity';

import { PadComponent } from './pad/pad-component';

import styles from './pads.module.scss';

interface Props {
  pads: PadEntity[];
  isEditing: boolean;
  onWellClick?(wellId: number): void;
  onPadClick?(padId: number): void;
  onWellAdd?: VoidFunction;
  onEditingWellClick?(wellId: number): void;
}

export const Pads = observer(function Pads({
  pads,
  isEditing,
  onWellClick,
  onPadClick,
  onWellAdd,
  onEditingWellClick,
}: Props) {
  function handleWellClick(wellId: number): void {
    if (isEditing) {
      onEditingWellClick?.(wellId);
    } else {
      onWellClick?.(wellId);
    }
  }

  return (
    <div className={styles.container}>
      {pads.map((pad) => (
        <PadComponent
          key={pad.id}
          isEditing={isEditing}
          pad={pad}
          onWellClick={handleWellClick}
          onPadClick={onPadClick}
          onWellAdd={onWellAdd}
        />
      ))}
    </div>
  );
});
