import { observer } from 'mobx-react-lite';

import { PadAttributeIcon } from 'src/features/pad-attribute-icon';
import { getPadAttributeIcons } from 'src/shared/utils/get-pad-attribute-icons';

import { RigOperation } from '../entities/rig-operation';

import { Well } from './well/well';

import styles from './pad.module.scss';

interface Props {
  title: string | null;
  rigOperations: RigOperation[];
}

export const Pad = observer(function Pad({ rigOperations, title }: Props) {
  const attributeIcons = getPadAttributeIcons({});

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>{title}</p>

        <div className={styles.attributes}>
          {attributeIcons?.map((attributeName) => (
            <PadAttributeIcon key={attributeName} iconName={attributeName} className={styles.attribute} />
          ))}
        </div>
      </div>
      {rigOperations.map((rigOperation) => (
        <Well className={styles.well} item={rigOperation} key={rigOperation.uniqueCreationKey} />
      ))}
    </div>
  );
});
