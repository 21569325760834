import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { observer } from 'mobx-react-lite';

import { CheckTableCell } from 'src/shared/components/table-cell/check-table-cell';
import { TableRow } from 'src/store/table/types';

interface Props {
  row: TableRow;
  handleSelectRow: (event?: CheckboxChangeEvent) => void;
}

export const SelectRowTableCell = observer(function SelectRowTableCell({ row, handleSelectRow }: Props) {
  return <CheckTableCell onChange={handleSelectRow} isChecked={row.selected} />;
});
