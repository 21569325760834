import { action, flow, makeObservable, observable, reaction } from 'mobx';

import { RigsChartConflictSidebarViewTypes } from 'src/api/chart/rigs-chart-api';
import { assert } from 'src/shared/utils/assert';
import { hasValue } from 'src/shared/utils/common';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';
import { RootStore } from 'src/store';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { ViewsStore } from 'src/store/views';

import { Timer } from '../../shared/timer';
import { RawWell } from '../../types';
import { RigOperationsDnd } from '../rig-operations-dnd/rig-operations-dnd-module';

import { ConflictRigOperation } from './entities/conflict-rig-operation';
import { getRigOperationIndex, getUniqueWellKey, getWellAttributes, getWellTitle } from './utils';

export class ConflictSidebarStore {
  private readonly dndContext: RigOperationsDnd.DndContext;
  private readonly autoCloseTimer = new Timer();

  readonly directories: Directories;
  readonly appSettings: AppSettingsStore;
  readonly views: ViewsStore;

  @observable private view: RigsChartConflictSidebarViewTypes.RigsChartConflictSidebarView | null = null;
  @observable private isPointerOutOfSidebar: boolean = false;

  /** Controls whether sidebar node is present in the DOM. */
  @observable isOpen: boolean = false;
  /** Whether sidebar is hidden when dragging elements from sidebar. */
  @observable isHidden: boolean = false;
  @observable conflictRigOperation: ConflictRigOperation | null = null;
  @observable isLoading: boolean = false;

  constructor(dndContext: RigOperationsDnd.DndContext, rootStore: RootStore) {
    this.dndContext = dndContext;
    this.appSettings = rootStore.appSettings;
    this.directories = rootStore.directories;
    this.views = rootStore.views;

    makeObservable(this);
  }

  @flow.bound
  private async *loadView() {
    this.isLoading = true;

    try {
      const view = await this.views.rigsChartConflictSidebarView.loadView();
      yield;
      this.view = view;
    } catch (e) {
      yield;
      console.error(e);
    } finally {
      this.isLoading = true;
    }
  }

  init(): VoidFunction {
    this.loadView();

    const disposeSidebarAutoClosing = reaction(
      () => ({ active: this.dndContext.active, isPointerOutOfSidebar: this.isPointerOutOfSidebar }),
      ({ active, isPointerOutOfSidebar }) => {
        if (active && isPointerOutOfSidebar && this.isOpen) {
          this.autoCloseTimer.startTimer(() => this.setIsSidebarHidden(true), 500);
        } else if (this.isOpen) {
          this.autoCloseTimer.cancelTimer();

          this.setIsSidebarHidden(false);
        }
      }
    );

    return () => {
      disposeSidebarAutoClosing();

      this.autoCloseTimer.cancelTimer();
    };
  }

  @action.bound
  setConflictRigOperation(conflictRigOperationData: RawWell | null): void {
    if (!conflictRigOperationData) {
      this.conflictRigOperation = conflictRigOperationData;
      return;
    }

    assert(hasValue(this.view), 'Invalid conflict sidebar view.');

    const wellColor = getWellColor(conflictRigOperationData, this.appSettings.wellColorRules);
    const wellTitle = getWellTitle(this.directories, this.view.conflictWell, conflictRigOperationData);
    const wellAttributes = getWellAttributes(this.view.conflictWell, conflictRigOperationData);
    const rigOperationIndex = getRigOperationIndex(this.view.conflictWell, conflictRigOperationData);
    const uniqueCreationKey = getUniqueWellKey(conflictRigOperationData);
    const wellTypeIcon = this.appSettings.getWellIcon(
      conflictRigOperationData,
      addGOplanPrefix('GeologicalTask.wellTypeId')
    );

    this.conflictRigOperation = new ConflictRigOperation(
      uniqueCreationKey,
      conflictRigOperationData,
      wellTitle,
      // TODO Добавить настоящее название буровой, когда бэкэнд добавит в ответ информацию по буровой
      'Название БУ',
      rigOperationIndex,
      wellAttributes,
      wellColor,
      wellTypeIcon
    );
  }

  @action.bound
  onOpen(conflictRigOperationData: RawWell): void {
    this.setConflictRigOperation(conflictRigOperationData);
    this.isOpen = true;
    this.isHidden = false;
  }

  @action.bound
  onClose(): void {
    this.isOpen = false;
    this.isHidden = false;
    this.setConflictRigOperation(null);
  }

  @action.bound
  setIsSidebarHidden(isHidden: boolean): void {
    this.isHidden = isHidden;
  }

  @action.bound
  onPointerOutOfSidebar(): void {
    this.isPointerOutOfSidebar = true;
  }

  @action.bound
  onPointerInSidebar(): void {
    this.isPointerOutOfSidebar = false;
  }
}
