import { ComparingSummary } from 'src/api/chart/comparing-summary-data-api';

export function getChange(changesResult?: ComparingSummary.ResultEntry): 'up' | 'down' | null {
  if (changesResult?.code === ComparingSummary.ResultCode.noChanges || !changesResult?.change) {
    return null;
  }

  if (changesResult.change > 0) {
    return 'up';
  }

  if (changesResult.change < 0) {
    return 'down';
  }

  return null;
}
