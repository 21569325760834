import { action, flow, makeObservable, observable, reaction } from 'mobx';

import { getDirectoryValues } from 'src/api/new-well/requests';
import { TDirectoryValueWithMsg } from 'src/api/new-well/types';
import { BaseApiError } from 'src/errors';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { hasValue } from 'src/shared/utils/common';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { RequestsQueueController } from 'src/shared/utils/requests-queue-controller';
import { RootStore } from 'src/store';

import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class DirectoryValuesPlugin extends FormPlugin<FormStore> {
  private fieldIdsWithDiredtoryValues = new Set<string>();
  private requestController = new RequestsQueueController();
  @observable blockingMessages: Record<string, string | null> = {};

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this);
  }
  connect(form: FormStore): VoidFunction {
    this.form = form;
    this.getInitialDirectoriesValues();
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.checkDictValue) {
        item.checkDictValue.forEach((fieldId) => this.fieldIdsWithDiredtoryValues.add(fieldId));

        const disposer = reaction(
          () => item.value,
          async () => {
            const form = this.form;
            if (!form) {
              return;
            }
            const dirValues = await this.getValuesFromDirectories(item.checkDictValue);

            if (dirValues) {
              for (const fieldId in dirValues) {
                const control = form.fields[fieldId];

                if (control && hasValue(dirValues[fieldId])) {
                  control.setValueFromDirectory(dirValues[fieldId]);
                }
              }
            }
          }
        );

        disposers.push(disposer);
      }
      if (item.updateDictValue) {
        item.updateDictValue.forEach((fieldId) => this.fieldIdsWithDiredtoryValues.add(fieldId));

        const disposer = reaction(
          () => item.value,
          async () => {
            const form = this.form;
            if (!form) {
              return;
            }

            const dirValues = await this.getValuesFromDirectories(item.updateDictValue);

            if (dirValues) {
              for (const fieldId in dirValues) {
                const control = form.fields[fieldId];

                if (control && hasValue(dirValues[fieldId]) && dirValues[fieldId] !== control.value) {
                  control.tryToSetRawValue(dirValues[fieldId]);
                }
                control.setValueFromDirectory(dirValues[fieldId]);
              }
            }
          }
        );

        disposers.push(disposer);
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
      this.requestController.killController();
    };
  }

  @action.bound
  private getMessagesAndValues(rawDirValues: Record<string, TDirectoryValueWithMsg>): Record<string, unknown> {
    const dirValues: Record<string, unknown> = {};

    for (const fieldId in rawDirValues) {
      dirValues[fieldId] = rawDirValues[fieldId].value;
      const msg = rawDirValues[fieldId].msg;
      this.blockingMessages[fieldId] = msg || null;
    }

    return dirValues;
  }

  private async getValuesFromDirectories(fieldIds?: string[]): Promise<Record<string, unknown> | void> {
    const form = this.form;
    if (!form) {
      return;
    }

    const pendingEventId = getRandomNumber();
    form.addPendingEvent(pendingEventId);

    try {
      const dirValuesRaw = await this.requestController.makeRequest(
        async () => await getDirectoryValues(form.tabs, fieldIds)
      );
      const dirValues = this.getMessagesAndValues(dirValuesRaw);

      return dirValues;
    } catch (e) {
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.rootStore.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.rootStore.notifications.showErrorMessageT('errors:NewWellForm.failedToReceiveValuesFromDirectory');
    } finally {
      form.removePendingEvent(pendingEventId);
    }
  }

  @flow.bound
  private async *getInitialDirectoriesValues(): Promise<void> {
    const form = this.form;
    if (!form) {
      return;
    }

    const dirValues = await this.getValuesFromDirectories();

    if (dirValues) {
      for (const fieldId in dirValues) {
        const control = form.fields[fieldId];

        if (control) {
          control.setValueFromDirectory(dirValues[fieldId]);
        }
      }
    }
    yield;
  }

  getBlockingMessage(item: Item): string | null {
    return (
      this.blockingMessages[item.fieldId] ||
      (item.value === item.valueFromDirectory &&
        this.rootStore.i18.t('newWellForm:Tooltips.equalDirectoryAndItemValues')) ||
      null
    );
  }

  @flow.bound
  async *getAndSetValueFromDirectory(item: Item) {
    const form = this.form;
    if (!form) {
      return;
    }

    const dirValues = await this.getValuesFromDirectories([item.fieldId]);

    if (dirValues) {
      const dirValue = dirValues[item.fieldId];
      item.setValueFromDirectory(dirValue ?? null);
      item.tryToSetRawValue(dirValue);
    }
    yield;
  }

  checkIsItemWithDirectoryValues(item: Item): boolean {
    return this.fieldIdsWithDiredtoryValues.has(item.fieldId);
  }
}
