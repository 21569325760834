import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Arrow } from 'src/shared/assets/icons/arrow-thin.svg';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';

import styles from './history-sidebar.module.scss';

type Props = {
  isOpened: boolean;
  onClose: () => void;
};

export const HistorySidebar = observer(function HistorySidebar({ isOpened, onClose }: Props) {
  const { t } = useTranslation('drawers');

  const fakeChanges = [
    {
      id: '1',
      date: '10.06.2022',
      name: 'Иванов И. И.',
      changes: ['Внес изменения в характеристики скважины Vt_338', 'Изменил значение "Проходка": с “2459” на “2600” '],
    },
    {
      id: '2',
      date: '09.06.2022',
      name: 'Растишкин Д. Т.',
      changes: ['Внес изменения в характеристики скважины Vt_337', 'Изменил значение "Уходка": с “2459” на “2600” '],
    },
    {
      id: '3',
      date: '08.06.2022',
      name: 'Скелетонов К. К.',
      changes: ['Внес изменения в характеристики скважины Vt_336', 'Изменил значение "Приходка": с “2459” на “2600” '],
    },
  ];

  return (
    <Sidebar
      title={t('changesHistory.title')}
      description={t('changesHistory.description')}
      size="l"
      isOpened={isOpened}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <Collapse expandIcon={() => <Arrow />} bordered>
          {fakeChanges.map((change) => (
            <Panel
              key={change.id}
              header={
                <div className={styles.panelHeader}>
                  <p className={styles.panelHeaderText}>{change.date}</p>
                  <p className={styles.panelHeaderText}>{change.name}</p>
                </div>
              }
            >
              <div className={styles.changesWrapper}>
                {change.changes.map((userChange) => (
                  <p key={userChange} className={styles.changeRow}>
                    {userChange}
                  </p>
                ))}
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </Sidebar>
  );
});
