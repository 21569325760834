import { action, computed, flow, makeObservable, observable } from 'mobx';

import { SwitchButtonGroupItem } from 'src/shared/components/switch-button-group/switch-button-group';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { InfoSidebarTab } from './entities/info-sidebar-tab.entity';
import { TSidebarInfoView } from './graph-info-sidebar';
import { flatDirectoryValue } from './utils/flat-directory-value';
import { mapTabs } from './utils/map-tabs';

export class GraphInfoSidebarViewProvider {
  protected readonly view: TSidebarInfoView;
  protected readonly appSettings: AppSettingsStore;
  protected readonly notifications: NotificationsStore;
  readonly directories: Directories;

  @observable tabs: InfoSidebarTab[] = [];

  constructor(
    view: TSidebarInfoView,
    directories: Directories,
    appSettings: AppSettingsStore,
    notifications: NotificationsStore
  ) {
    this.view = view;
    this.directories = directories;
    this.appSettings = appSettings;
    this.notifications = notifications;

    this.initializeTabs();

    makeObservable(this);
  }

  @flow.bound
  async *setTabValues(tab: InfoSidebarTab, data: Record<string, unknown>) {
    try {
      await tab.setValues(data);
      yield;
    } catch (e) {
      console.error(e);
      this.notifications.showErrorMessageT('error:failedToLoadData');
    }
  }

  @action.bound
  initializeTabs() {
    this.tabs = mapTabs(this.view.tabs, this.directories, this.appSettings, this.notifications);
  }

  @computed
  get tabsSwitchButtonsGroup(): SwitchButtonGroupItem<string>[] {
    return this.view.tabs.map((rawTab) => {
      const tabTitle = this.directories.getFieldLabel(rawTab.fieldId) || '';

      return { key: rawTab.fieldId, title: tabTitle };
    });
  }

  @flow.bound
  async *setValues(data: Record<string, unknown> | null | undefined) {
    if (!data) {
      return;
    }

    const processedData = flatDirectoryValue(data);

    if (!processedData) {
      return;
    }

    await Promise.all(this.tabs.map((tab) => this.setTabValues(tab, processedData)));
    yield;
  }
}
