import { PointerEventHandler, PointerEvent, useRef, useMemo } from 'react';

namespace UseOnClick {
  export const HOLD_MOVE_DISTANCE_SQUARED = Math.pow(15, 2);

  export type InteractionState = {
    isMoving: boolean;
    initialClientX: number;
    initialClientY: number;
  };
}

export function useOnClick(onClick: VoidFunction) {
  const interactionRef = useRef<UseOnClick.InteractionState | null>();

  return useMemo(() => {
    const onPointerDown: PointerEventHandler<HTMLElement> = (e) => {
      if (!e.isPrimary || !e.currentTarget) {
        return;
      }

      e.stopPropagation();
      e.currentTarget.setPointerCapture(e.pointerId);

      interactionRef.current = {
        initialClientX: e.clientX,
        initialClientY: e.clientY,
        isMoving: false,
      };
    };

    const onPointerMove: PointerEventHandler<HTMLElement> = (e) => {
      if (!e.isPrimary || !e.currentTarget) {
        return;
      }

      e.stopPropagation();
      e.currentTarget.setPointerCapture(e.pointerId);

      const info = interactionRef.current;
      if (!info) {
        return;
      }

      if (!info.isMoving) {
        const distance = Math.pow(e.clientX - info.initialClientX, 2) + Math.pow(e.clientY - info.initialClientY, 2);

        if (distance > UseOnClick.HOLD_MOVE_DISTANCE_SQUARED) {
          info.isMoving = true;
        }
      }
    };

    function onPointerCancel(e: PointerEvent) {
      if (!e.isPrimary || !e.currentTarget) {
        return;
      }

      e.stopPropagation();

      e.currentTarget.releasePointerCapture(e.pointerId);
      interactionRef.current = null;
    }

    function onPointerUp(e: PointerEvent) {
      if (!e.isPrimary || !e.currentTarget) {
        return;
      }

      e.stopPropagation();

      const info = interactionRef.current;

      if (!info?.isMoving) {
        onClick?.();
      }

      e.currentTarget.releasePointerCapture(e.pointerId);
      interactionRef.current = null;
    }

    return {
      onPointerDown,
      onPointerMove,
      onPointerCancel,
      onPointerUp,
    };
  }, [onClick]);
}
