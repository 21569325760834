import { observer } from 'mobx-react-lite';

import { RigsChartDataModel } from '../../features/rigs-chart/data/rigs-chart-data-model';
import { SummaryData } from '../../features/summary-data';
import { SummaryViewProvider } from '../../shared/summary-view-provider';
import { Viewport } from '../../shared/viewport/viewport';

import { IRigSummary } from './entities/rig-summary';
import { SummaryDataSidebarWrapper } from './summary-data-sidebar-wrapper';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  data: (IRigSummary | null)[] | null;
  backgroundItems: RigsChartDataModel.ViewItem[] | null;
  viewport: Viewport;
  viewProvider: SummaryViewProvider;
  selectedYear: number;
  onYearChange(year: number): void;
  onScroll(offset: number): void;
  className?: string;
}

export const SummaryDataSidebar = observer(function SummaryDataSidebar({
  isOpen,
  className,
  backgroundItems,
  viewport,
  onClose,
  onScroll,
  data,
  viewProvider,
  selectedYear,
  onYearChange,
}: Props) {
  if (!isOpen) {
    return null;
  }

  return (
    <SummaryDataSidebarWrapper
      isOpen={isOpen}
      onClose={onClose}
      selectedYear={selectedYear}
      onYearChange={onYearChange}
      className={className}
    >
      <SummaryData
        data={data}
        backgroundItems={backgroundItems}
        viewProvider={viewProvider}
        onScroll={onScroll}
        viewport={viewport}
      />
    </SummaryDataSidebarWrapper>
  );
});
