import { RigsChart } from './rigs-chart-api';

export class RigsChartAdapter {
  receiveRigGroupIds(rawGroups: RigsChart.RawGroup[]): number[] {
    const groupIds = new Set<number>();

    for (const rawGroup of rawGroups) {
      for (const [, groupData] of Object.entries(rawGroup)) {
        groupIds.add(groupData.id);
      }
    }

    return [...groupIds];
  }
}
