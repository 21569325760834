import { action, autorun, makeObservable } from 'mobx';

import { fetchPlanVersionsByView } from 'src/api/dashboard/dashboard-api';
import { getKeyReplacer } from 'src/api/dashboard/utils';
import { DynamicJoinPlugin } from 'src/features/well-form/plugins/dynamic-join.plugin';
import { Item } from 'src/shared/entities/abstract-control-entities';
import { MultiCombobox } from 'src/shared/entities/control-entities';
import { TreeMultiCombobox } from 'src/shared/entities/control-entities/treemulticombobox.entity';
import { getDynamicJoinDependencies } from 'src/shared/utils/get-dynamic-join-dependencies';
import { isDynamicJoin } from 'src/shared/utils/is-dynamic-join';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { Directories } from '../directories/directories.store';
import { GlobalVariables } from '../global-variables/global-variables';
import { I18NextStore } from '../i18next/i18next-store';
import { RootStore } from '../root-store';

import { processActualData, processArchivedData } from './utils';

export class FilterForm {
  readonly directories: Directories;
  readonly globalVariables: GlobalVariables;
  readonly fields: Record<string, Item>;
  readonly fieldsArray: Item[];
  readonly defaultDependencies?: Record<string, string | number | boolean>;
  readonly i18: I18NextStore;
  readonly notifications: NotificationsStore;
  private readonly rootStore: RootStore;

  constructor(fields: Item[], rootStore: RootStore, defaultDependencies?: Record<string, string | number | boolean>) {
    this.fields = this.getFieldsDict(fields);
    this.fieldsArray = fields;
    this.rootStore = rootStore;
    this.directories = rootStore.directories;
    this.globalVariables = rootStore.globalVariables;
    this.defaultDependencies = defaultDependencies;
    this.i18 = rootStore.i18;
    this.notifications = rootStore.notifications;

    makeObservable(this);
  }

  private getFieldsDict(fieldsArr: Item[]): Record<string, Item> {
    const fields: Record<string, Item> = {};

    fieldsArr.forEach((field) => {
      fields[field.fieldId] = field;
      if (field.formElementRefId) {
        fields[field.formElementRefId] = field;
      }
    });

    return fields;
  }

  private connectPlugins(): VoidFunction {
    const plugins = [new DynamicJoinPlugin(this.rootStore)];

    const disposers = plugins.map((plugin) => plugin.connect(this));

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }

  private initializeItems(): VoidFunction {
    const disposersFromPrevStep: VoidFunction[] = [];
    for (const field of this.fieldsArray) {
      const fieldDisposer = autorun(() => this.initializeItem(field));

      disposersFromPrevStep.push(fieldDisposer);
    }

    return () => {
      disposersFromPrevStep.forEach((disposerFromPrevStep) => disposerFromPrevStep());
    };
  }

  private async initializeItem(field: Item): Promise<void> {
    if (field instanceof MultiCombobox && field.refQuery) {
      if (field.refQuery && !isDynamicJoin(field.refQuery)) {
        const joinRes = await this.directories.fetchJoinedDirectory(field.refQuery);
        if (joinRes) {
          field.setDirectory(joinRes);
          field.filterInvalidValues();
        }
      }
    } else if (field instanceof TreeMultiCombobox && field.refRest) {
      if (isDynamicJoin(field.refRest)) {
        const dependencies = getDynamicJoinDependencies(field.refRest);
        const dependenciesObject: Record<string, string | number | boolean> = {};

        for (const dep of dependencies.outer) {
          const key = dep.slice(1);
          const value = this.globalVariables.variables[key];

          if (value) {
            dependenciesObject[dep] = value;
          }
        }

        const joinedDependencies = this.defaultDependencies
          ? { ...this.defaultDependencies, ...dependenciesObject }
          : dependenciesObject;
        const formattedRefRestUrl = field.refRest.url.replace(
          /[$%@]{([0-9A-Za-z.-_]+)}/g,
          getKeyReplacer(joinedDependencies, true)
        );

        const data = await fetchPlanVersionsByView(formattedRefRestUrl);

        const actualVersions = data
          .filter((plan) => !plan.data.isArchive)
          .sort(({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => secondCreatedAt - firstCreatedAt);
        const archivedVersions = data
          .filter((plan) => plan.data.isArchive)
          .sort(({ createdAt: firstCreatedAt }, { createdAt: secondCreatedAt }) => secondCreatedAt - firstCreatedAt);

        const actualData = processActualData(actualVersions, this.i18);
        const archivedData = processArchivedData(archivedVersions, this.i18);

        const archiveTitle = this.i18.t('dashboard:archive');

        const treeData = [...actualData];

        if (archivedData.length) {
          treeData.push({
            value: archiveTitle,
            title: archiveTitle,
            selectable: false,
            children: archivedData,
          });
        }

        field.setTreeData(treeData);
      }
    }
  }

  processFormFields = (callback: (item: Item) => void): void => {
    for (const field of this.fieldsArray) {
      callback(field);
    }
  };

  @action.bound
  resetForm(): void {
    for (const field in this.fields) {
      this.fields[field]?.clearItem();
    }
  }

  getLabel = (field: Item): string | null => {
    if (!field) {
      return null;
    }

    if (field.label) {
      return field.label;
    }

    if (field.fieldId) {
      return this.directories.getFieldLabel(field.fieldId);
    }

    return null;
  };

  effect = (): VoidFunction => {
    const disposer = this.initializeItems();
    const pluginsDisposer = this.connectPlugins();

    return () => {
      disposer();
      pluginsDisposer();
    };
  };

  @action.bound
  setValues(values: Record<string, unknown>): void {
    for (const fieldId in this.fields) {
      const field = this.fields[fieldId];

      if (
        field?.formElementRefId &&
        Object.hasOwn(values, field.formElementRefId) &&
        field.value !== values[field.formElementRefId]
      ) {
        field.setValue(values[field.formElementRefId]);
      }
    }
  }
}
