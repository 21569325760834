import { observer } from 'mobx-react-lite';
import { useState, ReactElement, useEffect } from 'react';

import {
  FormMultiSelect,
  ValidationOptions,
} from 'src/shared/components/form-components/form-multiselect/form-multiselect';

import { MultiCombobox } from '../../../../shared/entities/control-entities';
import { TOption } from '../../types';
import { ComboboxDropdown } from '../combobox-dropdown/combobox-dropdown';

type Props = {
  className?: string;
  label?: string;
  item: MultiCombobox;
  isDisabled?: boolean;
  onBlur?: VoidFunction;
  onChange?: VoidFunction;
  validationOptions?: ValidationOptions;
};

export const NewWellMulticombobox = observer(function NewWellMulticombobox({
  className,
  label,
  item,
  isDisabled,
  onBlur,
  onChange,
  validationOptions,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);
  const { init } = item;

  const renderDropdown = (origin: ReactElement): ReactElement => {
    return <ComboboxDropdown fieldId={item.fieldId}>{origin}</ComboboxDropdown>;
  };

  const handleChange = (value: (string | number)[] | TOption[]): void => {
    item.tryToSetRawValue(value);
    item.clearError();
    onChange?.();
  };

  const handleBlur = () => {
    onBlur?.();
  };

  const onDropdownVisibleChange = (open: boolean): void => {
    setIsOpened(open);
  };

  useEffect(init, [init]);

  return (
    <FormMultiSelect<TOption>
      placeholder={item.placeholder}
      open={isOpened}
      value={item.labeledValues}
      label={label}
      className={className}
      errorText={item.errorText}
      options={item.options}
      isDisabled={isDisabled}
      dropdownStyle={{
        zIndex: 3,
      }}
      dataTestId={`combobox-${item.fieldId}`}
      validationOptions={validationOptions}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={renderDropdown}
      onBlur={handleBlur}
      onClear={item.clearItem}
      onChange={handleChange}
    />
  );
});
