import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { isValidElement, ReactNode } from 'react';

import Card from 'src/shared/components/card/card';

import styles from './drilling-element-card.module.scss';

interface Props {
  title: ReactNode;
  icon?: ReactNode;
  description?: ReactNode;
  className?: string;
}

export const DrillingElementCard = observer(function DrillingElementCard({
  title,
  icon,
  description,
  className,
}: Props) {
  return (
    <Card
      icon={icon}
      header={<h3 className={styles.cardHeader}>{title}</h3>}
      className={classNames(className, styles.card)}
    >
      {isValidElement(description) && <div className={styles.cardDescription}>{description}</div>}
    </Card>
  );
});
