import { PointerEventHandler, useState } from 'react';

/** Use instead of pointer click handler. */
export const useClickHandler = (onClick?: VoidFunction) => {
  const [isFocused, setIsFocused] = useState(false);

  const onPointerDown: PointerEventHandler = (e) => {
    e.currentTarget.setPointerCapture(e.pointerId);
    e.preventDefault();

    setIsFocused(true);
  };

  const onPointerUp: PointerEventHandler = (e) => {
    e.currentTarget.releasePointerCapture(e.pointerId);
    e.preventDefault();

    if (isFocused) {
      onClick?.();
      setIsFocused(false);
    }
  };

  return { onPointerDown: onClick ? onPointerDown : undefined, onPointerUp: onClick ? onPointerUp : undefined };
};
