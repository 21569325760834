import { observer } from 'mobx-react-lite';

import { ReactComponent as BookIcon } from 'src/shared/assets/icons/book-light.svg';
import { Button } from 'src/shared/components/button';

type Props = {
  onClick: VoidFunction;
};

export const GetDirectoryValuesButton = observer(function GetDirectoryValuesButton({ onClick }: Props) {
  return (
    <Button width={24} height={24} variant="default" icon={<BookIcon fill="none" stroke="none" />} onClick={onClick} />
  );
});
