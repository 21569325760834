import { autorun, computed, makeObservable } from 'mobx';

import { ComparingIndicators } from 'src/api/chart/comparing-indicators-api';

import { Viewport } from '../../../shared/viewport/viewport';
import { moveViewport, zoomViewport } from '../../../shared/viewport/viewport-calculator';
import { ComparingIndicatorsDataModel } from '../data';

export class ComparingIndicatorsPresenter {
  private isMoveMode = false;

  private readonly viewport: Viewport;
  private readonly dataModel: ComparingIndicatorsDataModel;

  constructor(viewport: Viewport, dataModel: ComparingIndicatorsDataModel) {
    this.viewport = viewport;
    this.dataModel = dataModel;

    this.dataModel.setRange(this.viewport.start, this.viewport.end);

    makeObservable(this);
  }

  init(): VoidFunction {
    const disposeRangeSetter = autorun(() => {
      const { start, end } = this.viewport;
      this.dataModel.setRange(start, end);
    });

    return () => {
      disposeRangeSetter();
    };
  }

  @computed
  get data(): ComparingIndicators.ViewItems | null {
    return this.dataModel.getData();
  }

  onPointerDown = (): void => {
    this.isMoveMode = true;
  };

  onPointerMove = (offsetX: number) => {
    if (!this.isMoveMode) {
      return;
    }

    const { movedStart, movedEnd } = moveViewport(this.viewport, offsetX);
    this.viewport.setRange(movedStart, movedEnd);
  };

  onPointerUp = () => {
    this.isMoveMode = false;
  };

  onZoom = (offset: number, centerPosition: number): void => {
    const { zoomedStart, zoomedEnd } = zoomViewport(this.viewport, offset, centerPosition);

    this.viewport.setRange(zoomedStart, zoomedEnd);
  };
}
