import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ComparisonSidebar } from 'src/features/comparison-sidebar/comparison-sidebar';
import { PlanVersionSidebar } from 'src/features/plan-version-sidebar';
import { ReactComponent as CompareIcon } from 'src/shared/assets/icons/compare-arrows.svg';
import { useModal } from 'src/shared/hooks/use-modal';
import { useStore } from 'src/store/use-store';

import { ButtonWithTooltip } from '../../../../../shared/components/button-with-tooltip';

import { CompareButton } from './compare-button/compare-button';
import { ExportButton } from './export-button';
import { UploadButton } from './upload-button';

import styles from './compare-and-import-export.module.scss';

export const CompareAndImportExport = observer(function CompareAndImportExport() {
  const { comparison, planVersion, editing } = useStore();
  const { t } = useTranslation('drillingCarpet');

  const {
    isOpen: isPlanVersionSidebarOpen,
    openModal: openPlanVersionSidebar,
    closeModal: closePlanVersionSidebar,
  } = useModal();

  return (
    <>
      <div className={styles.wrapper}>
        {comparison.isComparing && (
          <p className={clsx(styles.comparingText, comparison.isComparing && styles.hidden)}>
            {t('Compare.comparison')}
          </p>
        )}

        <CompareButton
          currentPlan={comparison.isComparing ? comparison.firstTargetPlan : planVersion.version || null}
          placeholder={t('Compare.chooseTarget')}
          handleOpenSidebar={comparison.isComparing ? comparison.openSidebar : openPlanVersionSidebar}
          isDisabled={editing.isEditing}
        />

        {comparison.isComparing && <p className={styles.comparingText}>{t('Compare.with')}</p>}
        {comparison.isComparing && (
          <CompareButton
            currentPlan={comparison.secondTargetPlan}
            placeholder={t('Compare.chooseDraft')}
            handleOpenSidebar={comparison.openSidebar}
          />
        )}

        <div className={styles.iconsWrapper}>
          <ButtonWithTooltip
            onClick={comparison.openSidebar}
            icon={<CompareIcon className={styles.icon} />}
            isDisabled={comparison.isComparing || editing.isEditing}
            className={styles.iconButton}
            tooltipText={t('common:compare')}
          />

          <ExportButton />
          <UploadButton />
        </div>
      </div>

      <PlanVersionSidebar isOpen={isPlanVersionSidebarOpen} onClose={closePlanVersionSidebar} />

      <ComparisonSidebar />
    </>
  );
});
