import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { ViewSettings } from 'src/api/chart/drilling-plan-charts-api';
import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { SortableList } from 'src/shared/components/sortable-list/sortable-list';

import { DEFAULT_VIEW_SETTINGS_OPTIONS } from '../../drilling-chart.constants';
import { EditingSettingsItem } from '../../shared/editing-settings-item';

type Props = {
  groups: ViewSettings.GroupedSettingsControlsGroupData[];
  onSortEnd(items: EditingSettingsItem[], group: ViewSettings.GroupedSettingsControlsGroupData): void;
  onError?(error?: Error): void;
};

export const GroupedViewSettings = observer(function GroupedViewSettings({ groups, onError, onSortEnd }: Props) {
  const [errorItem, setErrorItem] = useState<EditingSettingsItem | null>(null);

  const onIsShownChange = useCallback((value: boolean, item: EditingSettingsItem): void => {
    item.setIsShown(value);
  }, []);

  const validate = useCallback(
    (items: EditingSettingsItem[], item?: EditingSettingsItem): void => {
      if (onError && groups) {
        let shownValuesCount = 0;
        let error: Error | undefined = undefined;

        itemGroups: for (const itemsGroup of groups) {
          for (const settingsItem of itemsGroup.items) {
            if (settingsItem.isShown) {
              shownValuesCount++;
            }

            if (shownValuesCount > DEFAULT_VIEW_SETTINGS_OPTIONS.maxChecked) {
              error = new Error(`Max checked items count is ${DEFAULT_VIEW_SETTINGS_OPTIONS.maxChecked}`);

              if (item) {
                setErrorItem(item);
              }

              break itemGroups;
            }
          }
        }

        onError(error);
      }
    },
    [groups, onError]
  );

  useEffect(() => {
    if (errorItem) {
      const timer = setTimeout(() => {
        errorItem.setIsShown(false);
        setErrorItem(null);
        onError?.();
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [errorItem, onError]);

  if (!groups.length) {
    return null;
  }

  return (
    <Collapse defaultActiveKey={groups.map((group) => group.fieldId)} bordered>
      {groups.map((settingsGroup, index) => (
        <Panel key={settingsGroup.fieldId} header={settingsGroup.title}>
          <SortableList
            items={settingsGroup.items}
            onSortEnd={(items) => onSortEnd(items, settingsGroup)}
            onIsShownChange={onIsShownChange}
            onError={onError}
            customValidateFn={validate}
          />
        </Panel>
      ))}
    </Collapse>
  );
});
