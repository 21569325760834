import { useSortable } from '@dnd-kit/sortable';
import { Switch } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as HideIcon } from 'src/shared/assets/icons/closed-eye.svg';
import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/dots.svg';
import { ReactComponent as ShowIcon } from 'src/shared/assets/icons/eye.svg';
import { ReactComponent as PinIcon } from 'src/shared/assets/icons/pin.svg';

import styles from './setting-field.module.scss';

interface Props {
  label: string;
  columnName: string;
  id: string;
  isShown: boolean;
  isFixed: boolean;
  groupId: string;
  setIsShown(groupId: string, columnId: string, isShown: boolean): void;
  setIsFixed(groupId: string, columnId: string): void;
  isChildColumn?: boolean;
}

export const SettingField = observer(function SettingField({
  label,
  id,
  isShown,
  setIsShown,
  setIsFixed,
  columnName,
  isFixed,
  groupId,
  isChildColumn,
}: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
    data: { groupId, isGroup: false },
  });

  function handleFixColumn(): void {
    setIsFixed(groupId, columnName);
  }

  function handleDisplayColumn(): void {
    setIsShown(groupId, columnName, !isShown);
  }

  return (
    <div
      className={styles.wrapper}
      ref={setNodeRef}
      style={{ transform: `translateY(${transform?.y || 0}px)`, transition }}
      {...attributes}
    >
      <MoveIcon className={styles.moveIcon} {...listeners} />
      <Switch
        checkedChildren={<ShowIcon />}
        unCheckedChildren={<HideIcon />}
        checked={isShown}
        onChange={handleDisplayColumn}
      />
      <p className={styles.columnName}>{label}</p>
      {!isChildColumn && (
        <button className={clsx(styles.fixButton, isFixed && styles.fixButtonFixed)} onClick={handleFixColumn}>
          <PinIcon className={styles.icon} />
        </button>
      )}
    </div>
  );
});
