import { Summary } from 'src/api/chart/summary-data-api';

import { ComparingRigSummaryResult } from './comparing-rig-summary-result';
import { RigSummary } from './rig-summary';

export class ComparingRigSummary extends RigSummary {
  readonly hasChanges: boolean;
  readonly comparingResult: ComparingRigSummaryResult;

  constructor(
    parentId: number,
    rows: Partial<Summary.Rows>,
    hasChanges: boolean,
    comparingResult: ComparingRigSummaryResult
  ) {
    super(parentId, rows);

    this.hasChanges = hasChanges;
    this.comparingResult = comparingResult;
  }
}

export class MainComparingRigSummary extends RigSummary {
  readonly comparingPair: ComparingRigSummary;

  constructor(parentId: number, rows: Partial<Summary.Rows>, comparingPair: ComparingRigSummary) {
    super(parentId, rows);

    this.comparingPair = comparingPair;
  }
}
