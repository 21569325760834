import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import { ApproachesTab } from './entities/approach-entities';
import { ColumnTab } from './entities/column-tab.entity';
import { FormStore } from './entities/form.entity';
import { TSplitApproachOptions } from './types';
import { WellFormApproachesTab } from './well-form-approaches-tab';
import { WellFormColumn } from './well-form-column';

import styles from './well-form-component.module.scss';

type Props = {
  formStore: FormStore;
  onAddWellToChart: () => void;
  onSplitApproach?({ sectionId, stage, approach }: TSplitApproachOptions): void;
  onAddNewApproach?(): void;
};

export const WellFormComponent = observer(function WellFormComponent({
  formStore,
  onAddWellToChart,
  onSplitApproach,
  onAddNewApproach,
}: Props) {
  const { directories } = useStore();

  if (directories.isLoading || !formStore.isPluginsInitialized) return null;

  if (formStore.activeTab instanceof ApproachesTab) {
    return (
      <WellFormApproachesTab
        formStore={formStore}
        onAddWellToChart={onAddWellToChart}
        onSplitApproach={onSplitApproach}
        onAddNewApproach={onAddNewApproach}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.columnsWrapper}>
        {formStore.activeTab instanceof ColumnTab &&
          formStore.activeTab.columns.map((column) => (
            <WellFormColumn key={column.id} items={column.items} formStore={formStore} />
          ))}
      </div>
    </div>
  );
});
