import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'src/shared/constants/routes';
import { useStore } from 'src/store';

import { ImportProgress } from '../import-progress/import-progress';

import { CompareAndImportExport } from './compare-and-import-export/compare-and-import-export';
import { DrillingCarpetNavigation } from './drilling-carpet-navigation/drilling-carpet-navigation';

import styles from './styles.module.scss';

const PAGES_WHERE_HEADER_IS_NOT_SHOWNG = [ROUTES.matching, ROUTES.newWell, ROUTES.conflicts];

export const DrillingCarpetHeader = observer(function DrillingCarpetHeader() {
  const location = useLocation();
  const { jobsStore } = useStore();

  if (PAGES_WHERE_HEADER_IS_NOT_SHOWNG.some((page) => location.pathname.includes(page))) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.navWrapper}>
        <DrillingCarpetNavigation />

        {!!jobsStore.activeImportJob && <ImportProgress job={jobsStore.activeImportJob} />}
      </div>

      <CompareAndImportExport />
    </div>
  );
});
