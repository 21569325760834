import { makeObservable, observable } from 'mobx';

import { Range } from '../../../layers/model';

export class LoadingRigOperations {
  readonly id: number;
  readonly x: Range<number>;

  isInLoading: boolean = false;

  @observable y?: Range<number>;

  constructor(id: number, x: Range<number>) {
    this.id = id;
    this.x = x;

    makeObservable(this);
  }

  setY(value: Range<number>) {
    this.y = value;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-empty-pad-${this.id}`;
    }

    return `empty-pad-${this.id}`;
  }
}
