import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'src/shared/assets/icons/info-i.svg';
import { Button } from 'src/shared/components/button';
import { FormSelect } from 'src/shared/components/form-components';
import { Modal } from 'src/shared/components/modal/modal';
import { Tooltip } from 'src/shared/components/tooltip';

import styles from './select-page-modal.module.scss';

type Props = {
  sheets: string[];
  onSelectSheet: (sheet: string) => void;
  onClose: () => void;
  isOpen: boolean;
};

export const SelectPageModal = observer(function SelectPageModal({ sheets, onSelectSheet, onClose, isOpen }: Props) {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState<null | string>(null);

  function onSelectPage(value: string | number): void {
    /* Проверка на та string из за типа входного значения функции onChange компонента FormSelect
    Типы компонента FormSelect не изменялись для обратной совместимости */
    if (typeof value === 'string') {
      setSelectedPage(value);
    }
  }

  function onSavePage(): void {
    if (!selectedPage) return;

    onSelectSheet(selectedPage);
  }

  return (
    <Modal title={t('drillingCarpet:SelectPageModal.title')} isOpened={isOpen} className={styles.modal}>
      <p className={styles.description}>{t('drillingCarpet:SelectPageModal.description')}</p>

      <div className={styles.select}>
        <label className={styles.selectLabel}>Номер страницы</label>
        <FormSelect
          onChange={onSelectPage}
          options={sheets.map((item) => {
            return { label: item, value: item };
          })}
        />
      </div>

      <div className={styles.buttonsGroup}>
        <Button onClick={onClose} variant="danger" className={styles.button}>
          {t('common:Buttons.cancel')}
        </Button>
        <Button onClick={onSavePage} variant="success" className={styles.button} disabled={selectedPage === null}>
          {t('common:Buttons.save')}
        </Button>

        {/* TODO Заменить на настоящий текст тултипа */}
        <Tooltip text="Текст текст текст текст" trigger="click">
          <button className={styles.iconButton}>
            <InfoIcon className={styles.icon} />
          </button>
        </Tooltip>
      </div>
    </Modal>
  );
});
