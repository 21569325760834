import { IWell } from 'src/api/draft/types';
import { WellTypes } from 'src/types/well-types';

export class Well implements IWell {
  private readonly item: WellTypes.RawRigOperation;

  readonly id: number;

  constructor(item: WellTypes.RawRigOperation, id: number) {
    this.item = item;
    this.id = id;
  }

  getFieldValue(fieldName: string): string | number | boolean | WellTypes.RawRigOperationStage[] | undefined {
    return this.item[fieldName];
  }
}
