import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { hasValue } from 'src/shared/utils/common';

import { DateHelper } from '../drilling-chart/shared/date-helper';
import { Section } from '../graph-info-sidebar/entities/tab-with-stages-entities/section.entity';

import styles from './well-info-stage-card.module.scss';

interface Props {
  sections: Section[];
}

export const DrillingStageSections = observer(function DrillingStageSections({ sections }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.body}>
      {sections.map((section) => (
        <div key={section.id} className={styles.subRow}>
          <p className={styles.subRowTitle}>{section.title}</p>

          <p className={styles.text}>
            <span>{!!section.start ? DateHelper.unixToDateFormat(section.start) : null}</span>&nbsp;
            <span className={styles.subText}>
              {!!section.start ? DateHelper.unixToTimeFormat(section.start) : null}
            </span>
            {' - '}
            <span>{!!section.end ? DateHelper.unixToDateFormat(section.end) : null}</span>&nbsp;
            <span className={styles.subText}>{!!section.end ? DateHelper.unixToTimeFormat(section.end) : null}</span>
          </p>

          {hasValue(section.duration) && (
            <p className={styles.text}>
              {section.duration} {t('form:days')}
            </p>
          )}
        </div>
      ))}
    </div>
  );
});
