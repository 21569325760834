export function hasValue<T>(value: T | null | undefined): value is T {
  if (Array.isArray(value)) {
    return value.length !== 0;
  }
  return value !== null && value !== undefined && value !== 'null';
}

export function omit<T, K extends keyof T>(obj: T, key?: K): Omit<T, K> {
  if (key) {
    const { [key]: omitted, ...rest } = obj;
    return rest;
  }
  return obj;
}

export function firstLetterToUppercase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
