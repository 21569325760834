import { autorun } from 'mobx';
import { useEffect, useState } from 'react';

import { Range } from 'src/features/drilling-chart/layers/model';
import { Viewport } from 'src/features/drilling-chart/shared/viewport/viewport';

type TItemWithRange = {
  x: Range<number> | null;
};

export const useIsOutOfViewport = (item: TItemWithRange, horizontalViewport: Viewport) => {
  const [isOutOfViewport, setIsOutOfViewport] = useState(
    () => (item.x?.start ?? 0) + ((item.x?.end ?? 0) - (item.x?.start ?? 0)) / 2 < horizontalViewport.start
  );

  useEffect(() => {
    return autorun(() => {
      setIsOutOfViewport(
        (item.x?.start ?? 0) + ((item.x?.end ?? 0) - (item.x?.start ?? 0)) / 2 < horizontalViewport.start
      );
    });
  }, [item, horizontalViewport]);

  return isOutOfViewport;
};
